/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import PopupLayout from '../pages/main/PopupLayout';
import { Box, Grid } from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { messageAlert } from '../Shared';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import CalenderLocal from '../Sections/shared/CalenderLocal';
import { priorityOptions } from '../constants/reports';

const PopupProcedure = ({
  open,
  onClose,
  isRTL,
  words,
  addProc,
  theme,
  saving,
  setSaving,
  data,
  user,
  operation,
  procitems,
  usrs,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [procvalue, setProcvalue] = useState<any>(null);
  const [end, setEnd] = useState(null);
  const [desc, setDesc] = useState(null);
  const [usrvalue, setUsrvalue] = useState(null);
  const [priority, setPriority] = useState(null);

  const onHandleSubmit = async () => {
    if (!procvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب اختيار الاجراء' : 'Procedure Required'
      );
      return;
    }
    setSaving(true);

    const prevprocId =
      data?.length > 0 ? data?.[data?.length - 1]?._id : undefined;
    const outbox = user?._id;
    const outboxName = user?.name ? user?.name : user?.username;
    const inboxName = usrvalue
      ? usrvalue?.name
        ? usrvalue?.name
        : usrvalue?.username
      : undefined;
    const inbox = usrvalue ? usrvalue?._id : undefined;

    const variables = {
      indx: data?.length,
      prevprocId,
      outbox,
      outboxName,
      inbox,
      inboxName,
      end: end ? end : undefined,
      desc,
      priority: priority ? priority?.value : 1,
      pitemId: procvalue?._id,
      pitemName: procvalue?.name,
      pitemNameAr: procvalue?.nameAr,
      pcategoryId: procvalue?.pcategoryId,
      pcategoryName: procvalue?.pcategoryName,
      pcategoryNameAr: procvalue?.pcategoryNameAr,
      opId: operation?._id,
      opType: operation?.opType,
      opTime: operation?.time,
      opDocNo: operation?.docNo,
    };

    try {
      await addProc({ variables });
      reset();
      onClose();
    } catch (error) {
      console.log('error', error);
      setSaving(false);
    }
  };

  const reset = () => {
    setProcvalue(null);
    setEnd(null);
    setDesc('');
    setUsrvalue(null);
    setPriority(null);
    setSaving(false);
  };

  const onCloseForem = () => {
    reset();
    onClose();
  };

  const title = isRTL ? 'اضافة اجراء' : 'New Procedure';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForem}
      title={title}
      saving={saving}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      mb={20}
    >
      <Grid container spacing={2} style={{ padding: 10 }}>
        <Grid item xs={8}>
          <AutoFieldLocal
            name="procitem"
            title={isRTL ? 'موجه الى' : 'To'}
            words={words}
            options={usrs?.filter((us: any) => us?._id !== user?._id)}
            value={usrvalue}
            setSelectValue={setUsrvalue}
            isRTL={isRTL}
            fullWidth
            mb={0}
          ></AutoFieldLocal>
        </Grid>

        <Grid item xs={8}>
          <AutoFieldLocal
            Required
            name="procitem"
            title={isRTL ? 'اسم الاجراء' : 'Procedure'}
            words={words}
            options={procitems}
            value={procvalue}
            setSelectValue={setProcvalue}
            isRTL={isRTL}
            fullWidth
            mb={0}
          ></AutoFieldLocal>
        </Grid>
        <Grid item xs={4}>
          <AutoFieldLocal
            name="procitem"
            title={isRTL ? 'الاهمية' : 'Priority'}
            words={words}
            options={priorityOptions}
            value={priority}
            setSelectValue={setPriority}
            isRTL={isRTL}
            fullWidth
            mb={0}
          ></AutoFieldLocal>
        </Grid>

        <Grid item xs={12}>
          <TextFieldLocal
            name="desc"
            label={isRTL ? 'التص' : 'Body'}
            value={desc}
            onChange={(e: any) => {
              const value = e.target.value;
              setDesc(value);
            }}
            multiline
            rowsMax={6}
            rows={6}
            fullWidth
            mb={0}
          />
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Box style={{ marginTop: 30 }}>
            <CalenderLocal
              isRTL={isRTL}
              label={isRTL ? 'وقت الانتهاء' : 'Due Date'}
              value={end}
              onChange={(date: any) => setEnd(date)}
              time
              fullWidth
              minHeight={34}
            ></CalenderLocal>
          </Box>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupProcedure;
