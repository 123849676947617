/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { useFormDocs, useTemplate } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade, Typography } from '@material-ui/core';

import useCompany from '../../hooks/useCompany';
import PopupEditing from '../components/PopupEditing';
import { Command } from '../components/Command';
import { errorAlert, errorDeleteAlert, getRowId } from '../common/funcs';
import { userroles } from '../constants';
import SearchTable from '../components/SearchTable';
import AlertLocal from '../components/AlertLocal';
import { TableComponent } from '../components/TableComponent';
import { booleanFormatter } from '../common/formater';
import PageLayout from '../shared/PageLayout';
import PopupFormDoc from './PopupFormDoc';
import TabsListFree from '../components/TabsListFree';
import useDCategories from '../../hooks/useDCategories';
import PopupDCategory from './PopupDCategory';
import AutoFieldLocal from '../shared/AutoFieldLocal';
import _ from 'lodash';

const list = [
  { id: 1, name: 'Forms', nameAr: 'النماذج' },
  { id: 2, name: 'Forms Category', nameAr: 'تصنيف النماذج' },
];

export default function FormDocs({ isRTL, words, theme, menuitem }: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [selected, setSelected] = useState(list[0]);
  const [catvalue, setCatvalue] = useState(null);

  const isform = selected?.id === 1;

  const { height, width } = useWindowDimensions();
  const { tempoptions } = useTemplate();
  const { company } = useCompany();
  const columns = isform
    ? [
        { name: 'opType', title: words.no },
        { name: 'nameAr', title: words.name },
        { name: 'name', title: words.nameEn },
        {
          name: isRTL ? 'dcategoryNameAr' : 'dcategoryName',
          title: words.category,
        },
        { name: 'autoNoType', title: words.ref },
        { name: 'autoNoPrefix', title: isRTL ? 'لاحقة' : 'Prefix' },
        {
          name: 'showOnCal',
          title: isRTL ? 'اظهار التقويم' : 'Show Calendat',
        },
        { name: 'order', title: isRTL ? 'ترتيب' : 'Order' },
        { name: 'disabled', title: isRTL ? 'متوقف' : 'disabled' },
      ]
    : [
        { name: 'nameAr', title: words.name },
        { name: 'name', title: words.nameEn },
        { name: 'ref', title: words.ref },
        { name: 'perfix', title: isRTL ? 'لاحقة' : 'Prefix' },
        { name: 'disabled', title: isRTL ? 'متوقف' : 'disabled' },
      ];
  const [tableColumnExtensions]: any = useState([
    { columnName: 'opType', width: 100 },
    { columnName: 'nameAr', width: 150 },
    { columnName: 'name', width: 150 },
    { columnName: isRTL ? 'dcategoryNameAr' : 'dcategoryName', width: 150 },
    { columnName: 'autoNoType', width: 150 },
    { columnName: 'autoNoPrefix', width: 150 },
    { columnName: 'ref', width: 150 },
    { columnName: 'perfix', width: 150 },
    { columnName: 'showOnCal', width: 120 },
    { columnName: 'order', width: 120 },
    { columnName: 'disabled', width: 120 },
  ]);

  const {
    allformdocs,
    addFormDoc,
    editFormDoc,
    removeFormDoc,
    refreshfromdocs,
    loading,
  } = useFormDocs();

  const { alldcategories, addDCategory, editDCategory, removeDCategory } =
    useDCategories();
  const commitChanges = async ({ deleted }) => {
    if (isform) {
      if (deleted) {
        const _id = deleted[0];
        const res = await removeFormDoc({ variables: { _id } });
        if (res?.data?.deleteFormDoc?.ok === false) {
          if (res?.data?.deleteFormDoc?.error.includes('related')) {
            await errorDeleteAlert(setAlrt, isRTL);
          } else {
            await errorAlert(setAlrt, isRTL);
          }
        }
      }
    } else {
      if (deleted) {
        const _id = deleted[0];
        const res = await removeDCategory({ variables: { _id } });
        if (res?.data?.deleteDCategory?.ok === false) {
          if (res?.data?.deleteDCategory?.error.includes('related')) {
            await errorDeleteAlert(setAlrt, isRTL);
          } else {
            await errorAlert(setAlrt, isRTL);
          }
        }
      }
    }
  };

  const bgcolor = '#EFFAF100';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refreshfromdocs}
      bgcolor={bgcolor}
      loading={loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: bgcolor,
        }}
      >
        <Paper
          elevation={5}
          style={{
            marginTop: 10,
            marginLeft: 40,
            marginRight: 40,
            marginBottom: 20,
            overflow: 'auto',
            width: width - 320,
            borderRadius: 10,
          }}
        >
          <TabsListFree
            selected={selected}
            setSelected={setSelected}
            isRTL={isRTL}
            list={_.orderBy(list, 'order')}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            theme={theme}
          ></TabsListFree>
          <Box style={{ position: 'absolute', top: 0, left: 150 }}>
            <AutoFieldLocal
              name="customer"
              title={isRTL ? 'التصنيف' : 'Category'}
              words={words}
              options={alldcategories}
              value={catvalue}
              setSelectValue={setCatvalue}
              isRTL={isRTL}
              width={200}
              mb={0}
            ></AutoFieldLocal>
          </Box>
          <Grid
            rows={
              isform
                ? catvalue
                  ? allformdocs.filter((fd: any) => fd.gref === catvalue?.ref)
                  : allformdocs
                : alldcategories
            }
            columns={columns}
            getRowId={getRowId}
          >
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SearchState />
            <PagingState defaultCurrentPage={0} defaultPageSize={13} />

            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <DragDropProvider />
            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 50 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnReordering
              defaultOrder={[
                'opType',
                'nameAr',
                'name',
                isRTL ? 'dcategoryNameAr' : 'dcategoryName',
                'autoNoType',
                'autoNoPrefix',
                'showOnCal',
                'ref',
                'perfix',
                'order',
                'disabled',
              ]}
            />
            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnVisibility
              columnExtensions={[]}
              defaultHiddenColumnNames={[]}
            />
            <DataTypeProvider
              for={['showOnCal', 'disabled']}
              formatterComponent={booleanFormatter}
            ></DataTypeProvider>
            <TableEditColumn
              showEditCommand={userroles?.formdocs?.edit}
              showDeleteCommand={userroles?.formdocs?.delete}
              showAddCommand={userroles?.formdocs?.add}
              commandComponent={Command}
            ></TableEditColumn>
            <Toolbar />
            <ColumnChooser />
            <PagingPanel
              pageSizes={
                tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
              }
            />
            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />

            {isform && (
              <PopupEditing
                theme={theme}
                addAction={addFormDoc}
                editAction={editFormDoc}
                company={company}
              >
                <PopupFormDoc></PopupFormDoc>
              </PopupEditing>
            )}
            {!isform && (
              <PopupEditing
                theme={theme}
                addAction={addDCategory}
                editAction={editDCategory}
                company={company}
              >
                <PopupDCategory></PopupDCategory>
              </PopupEditing>
            )}
          </Grid>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
      </Box>
    </PageLayout>
  );
}
