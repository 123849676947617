import { gql } from '@apollo/client';

export default gql`
  mutation deleteMultiCustomers($ids: [String]) {
    deleteMultiCustomers(ids: $ids) {
      ok
      message
      data
      error
    }
  }
`;
