/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  GroupingState,
  SummaryState,
  IntegratedGrouping,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableGroupRow,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, updateOpDocRefNumbers, userroles } from '../../common';
import {
  covertToTimeDateDigit,
  createdAtPrintFormatter,
  currencyFormatter,
  generalStatusFormatter,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  fade,
  FormControlLabel,
  Grid as MUIGrid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import { groupList } from '../../constants/reports';
import {
  useCategories,
  useCustomers,
  useServices,
  useTemplate,
} from '../../hooks';
import getReportServices from '../../graphql/query/getReportServices';
import ServicesReportContext from '../../contexts/servicesReport';
import useEmployees from '../../hooks/useEmployees';
import { SearchTable } from '../../components';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';
import useTasks from '../../hooks/useTasks';
import { ReportGroupBySwitcher } from '../components/ReportGroupBySwitcher';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);
export const TableComponent2 = withStyles(
  {},
  { name: 'TableComponent' }
)(TableComponentBase);

export default function EventsItemsReport({
  isRTL,
  words,
  theme,
  start,
  end,
  drawerWidth,
}: any) {
  const [rows, setRows] = useState([]);
  const [isdone, setIsdone] = useState(false);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const [columns] = useState(
    [
      col.opTime,
      col.item,
      col.opDocNo,
      col.customer,
      col.category,
      tempoptions?.contract && col.contract,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      tempoptions?.retype && col.retype,
      tempoptions?.retype2 && col.retype2,
      col.status,
      { name: 'qty', title: words.qty },
      userroles?.eventsitemsreports?.view && {
        name: 'itemPrice',
        title: words.theprice,
      },
      userroles?.eventsitemsreports?.view && {
        name: 'total',
        title: words.total,
      },
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: col.opTime.name, width: 100 },
    { columnName: col.item.name, width: 180 },
    { columnName: col.opDocNo.name, width: 100 },
    { columnName: col.customer.name, width: 180 },
    { columnName: col.category.name, width: 100 },
    { columnName: col.contract.name, width: 180 },
    { columnName: col.employee.name, width: 180 },
    { columnName: col.department.name, width: 180 },
    { columnName: col.retype.name, width: 100 },
    { columnName: col.retype2.name, width: 100 },
    { columnName: col.status.name, width: 100 },
    { columnName: 'qty', width: 70 },
    { columnName: 'itemPrice', width: 100 },
    { columnName: 'total', width: 100 },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getReportServices, {
    fetchPolicy: 'cache-and-network',
  });
  // const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { services } = useServices();
  const { categories } = useCategories();
  const { tasks } = useTasks();
  const { customers } = useCustomers();

  const {
    state: {
      servicevalue,
      departvalue,
      emplvalue,
      custvalue,
      taskvalue,
      catvalue,
      retypevalue,
      retype2value,
      group,
      groupby,
      sumcolumn,
      status,
      sort,
    },
    dispatch,
  } = useContext(ServicesReportContext);

  const setCustvalueDispatch = (value: any) => {
    dispatch({ type: 'setCustvalue', payload: value });
  };

  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setCatvalueDispatch = (value: any) => {
    dispatch({ type: 'setCatvalue', payload: value });
  };

  const setServicevalueDispatch = (value: any) => {
    dispatch({ type: 'setServicevalue', payload: value });
  };

  const setGroupbyDispatch = (value: any) => {
    dispatch({ type: 'setGroupby', payload: value });
  };
  const setGroupDispatch = (value: any) => {
    dispatch({ type: 'setGroup', payload: value });
  };
  const setSumcolumnDispatch = (value: any) => {
    dispatch({ type: 'setSumcolumn', payload: value });
  };
  const setTaskvalueDispatch = (value: any) => {
    dispatch({ type: 'setTaskvalue', payload: value });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getReportServices']?.data || [];
    const ops = summaryData?.data?.['getReportServices']?.message || null;
    let rdata = slsData;

    if (ops) {
      const events = JSON.parse(ops);
      if (events && events.length > 0) {
        const doneEvents = events.filter((ev: any) => ev.status === 10);
        const usedevents = isdone ? doneEvents : events;
        rdata = slsData.map((item: any) => {
          const itemev = usedevents.filter(
            (evt: any) => evt._id === item.opId
          )?.[0];
          if (itemev) {
            return {
              ...item,
              status: itemev.status,
              // bonos: itemev.bonos,
              // overtime: itemev.overtime,
              retypeId: itemev.retypeId,
              retypeName: itemev.retypeName,
              retypeNameAr: itemev.retypeNameAr,
              retype2Id: itemev.retype2Id,
              retype2Name: itemev.retype2Name,
              retype2NameAr: itemev.retype2NameAr,
            };
          } else {
            return item;
          }
        });
      }
    }
    let semedata = isdone
      ? rdata?.filter((rd: any) => rd.status === 10)
      : rdata;
    if (retypevalue && retypevalue.length > 0) {
      const rtids = retypevalue.map((r: any) => r._id);
      semedata = semedata.filter((ed: any) => rtids.includes(ed.retypeId));
    }
    if (retype2value && retype2value.length > 0) {
      const rtids = retype2value.map((r: any) => r._id);
      semedata = semedata.filter((ed: any) => rtids.includes(ed.retype2Id));
    }

    const rdata2 = updateOpDocRefNumbers(semedata);
    setRows(rdata2);
  }, [summaryData, retypevalue, retype2value, isdone]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;

  const fetchData = () => {
    const variables = {
      types: [80],
      serviceIds: getIds(servicevalue),
      categoryIds: getIds(catvalue),
      departmentIds: getIds(departvalue),
      employeeIds: getIds(emplvalue),
      customerIds: getIds(custvalue),
      contractIds: getIds(taskvalue),
      itemtypes: [1, 2],
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
      status: status ? status.id : undefined,
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    group,
    groupby,
    sumcolumn,
    status,
    departvalue,
    emplvalue,
    custvalue,
    taskvalue,
    servicevalue,
    catvalue,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `events-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const onSwitcherChange = (e: any) => {
    if (e.target.value === 'none') {
      setGroupDispatch(false);
      setGroupbyDispatch('none');
    } else {
      setGroupDispatch(true);
      setGroupbyDispatch(e.target.value);
      setSumcolumnDispatch(col[e.target.value].name);
    }
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'qty', type: 'sum' },
    { columnName: 'total', type: 'sum' },
  ];

  const grouping = [{ columnName: sumcolumn }];
  const groupSummaryItems = [
    {
      columnName: 'qty',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: 'total',
      type: 'sum',
      alignByColumn: true,
    },
  ];

  const projres = groupList(isRTL).filter((item: any) =>
    !tempoptions?.project && !tempoptions?.contract
      ? item.id !== 10 && item.id !== 11 && item.id !== 8
      : !tempoptions?.project && tempoptions?.resourse
      ? item.id !== 10
      : tempoptions?.project && !tempoptions?.resourse
      ? item.id !== 11
      : true
  );

  const groupOptions = projres.filter(
    (item: any) =>
      item.id !== 7 &&
      item.id !== 9 &&
      item.id !== 5.5 &&
      item.id !== 10 &&
      item.id !== 11
  );

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 48,
          left: 400,
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              style={{ padding: 7 }}
              checked={isdone}
              onChange={() => setIsdone(!isdone)}
              color="primary"
            />
          }
          label={
            <Typography style={{ color: '#333' }} variant="subtitle2">
              {isRTL ? 'منجز' : 'Done'}
            </Typography>
          }
          style={{ fontSize: 14, marginLeft: 20, marginRight: 20 }}
        />
        <FilterSelectMulti
          options={categories.filter((dep: any) => dep.catType === 2)}
          value={catvalue}
          setValue={setCatvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="category"
          label={isRTL ? 'التصنيف' : 'Category'}
          width={200}
          fullwidth
        ></FilterSelectMulti>
        <Box style={{ width: 10 }}></Box>
        <FilterSelectMulti
          options={services}
          value={servicevalue}
          setValue={setServicevalueDispatch}
          words={words}
          isRTL={isRTL}
          name="product"
          label={isRTL ? 'البنود' : 'Items'}
          width={200}
          fullwidth
        ></FilterSelectMulti>
        <Box style={{ width: 10 }}></Box>
        <Box
          style={{
            marginBottom: 3,
            width: 200,
          }}
        >
          <ReportGroupBySwitcher
            options={groupOptions}
            value={groupby}
            onChange={onSwitcherChange}
            isRTL={isRTL}
          ></ReportGroupBySwitcher>
        </Box>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'flex-end',
          top: 5,
          left: 420,
          width: '50%',
        }}
      >
        <MUIGrid container spacing={1}>
          {tempoptions?.contract && (
            <MUIGrid item xs={4}>
              <FilterSelectMulti
                options={tasks}
                value={taskvalue}
                setValue={setTaskvalueDispatch}
                words={words}
                isRTL={isRTL}
                name="task"
                fullwidth
              ></FilterSelectMulti>
            </MUIGrid>
          )}
          <MUIGrid item xs={4}>
            <FilterSelectMulti
              options={employees}
              value={emplvalue}
              setValue={setEmplvalueDispatch}
              words={words}
              isRTL={isRTL}
              name="employee"
              fullwidth
            ></FilterSelectMulti>
          </MUIGrid>
          <MUIGrid item xs={4}>
            <FilterSelectMulti
              options={customers}
              value={custvalue}
              setValue={setCustvalueDispatch}
              words={words}
              isRTL={isRTL}
              name="customer"
              fullwidth
            ></FilterSelectMulti>
          </MUIGrid>
        </MUIGrid>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        {group && <GroupingState grouping={grouping} />}
        {group && (
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
        )}
        {group && <IntegratedGrouping />}
        {group && <IntegratedSummary />}
        <IntegratedSorting />
        <SearchState />
        <IntegratedFiltering />
        <VirtualTable
          tableComponent={!group ? TableComponent : TableComponent2}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={30}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          onHiddenColumnNamesChange={(hcs: string[]) => {
            const all = [...columns];
            const newcol = all.filter((a: any) => !hcs.includes(a.name));
            newcol.sort((a: any, b: any) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );
          }}
          defaultHiddenColumnNames={[
            col.opDocNo.name,
            col.resourse.name,
            col.customer.name,
            col.contract.name,
            col.opType.name,
          ]}
        />
        <DataTypeProvider
          for={['opTime']}
          formatterComponent={createdAtPrintFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['status']}
          formatterComponent={generalStatusFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['opType']}
          formatterComponent={opTypeFormatter}
        ></DataTypeProvider>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        {group && (
          <TableGroupRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
              sumOf: isRTL ? 'المجموع' : 'Total',
              countOf: isRTL ? 'العدد' : 'Count',
            }}
            showColumnsWhenGrouped
          />
        )}
        {group && (
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </>
  );
}
