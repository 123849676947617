/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { Box, Fab, Grid, TextField } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { itemClasses } from '../themes/classes';
import { PopupDialog } from '../Shared';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const PopupItem = ({ open, onClose, row, editAction, isRTL, words }: any) => {
  const classes = itemClasses();
  const [name, setName] = useState<any>(null);
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(0);

  const itemRef: any = React.useRef();

  useEffect(() => {
    if (row && row.name) {
      setName(row.name);
      setPrice(row.price);
      setQty(row.qty);
    }
  }, [open]);

  const resetAll = () => {
    setPrice(0);
    setQty(1);
    setName(null);
  };

  const editLocalItem = () => {
    if (!name) {
      itemRef.current.focus();
      return;
    }
    const itemdata = {
      ...row,
      name,
      qty,
      price,
      total: qty * price,
      index: row?.index,
    };
    editAction(itemdata);
    onFormClose();
  };
  const onFormClose = () => {
    onClose();
    resetAll();
  };

  return (
    <PopupDialog
      open={open}
      onClose={onFormClose}
      maxWidth={'lg'}
      classes={classes}
    >
      <Box
        display="flex"
        style={{
          flex: 1,
          margin: 20,
          direction: isRTL ? 'rtl' : 'ltr',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              onChange={(e: any) => setName(e.target.value)}
              value={name}
              label={words.item}
              variant="outlined"
              margin="dense"
              fullWidth
              // style={{ marginTop: 0 }}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              name="qty"
              onChange={(e: any) => setQty(Number(e.target.value))}
              value={qty}
              label={words.qty}
              variant="outlined"
              type="number"
              fullWidth
              margin="dense"
              onFocus={(e) => e.target.select()}
              inputProps={{
                style: { textAlign: 'right', fontSize: 13, height: 13 },
                inputMode: 'decimal',
              }}
            />
          </Grid>
          <Grid item xs={5} md={3}>
            <TextField
              name="price"
              onChange={(e: any) => setPrice(Number(e.target.value))}
              value={price}
              label={words.theprice}
              variant="outlined"
              type="number"
              fullWidth
              margin="dense"
              onFocus={(e) => e.target.select()}
              inputProps={{
                style: { textAlign: 'right', fontSize: 13, height: 13 },
                inputMode: 'decimal',
              }}
            />
          </Grid>
          <Grid item xs={3} md={1}>
            <Fab
              style={{ marginLeft: 10, marginRight: 10, marginTop: 5 }}
              color="primary"
              size="small"
              onClick={editLocalItem}
              title="Create new row"
            >
              <SaveOutlinedIcon />
            </Fab>
          </Grid>
        </Grid>
      </Box>
    </PopupDialog>
  );
};

export default PopupItem;
