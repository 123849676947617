import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useState } from 'react';
import { userroles } from '../../../constants';
import { createExpenses, getLandingChartData } from '../../../../graphql';
import {
  useDepartments,
  useEmployees,
  useExpenseItems,
} from '../../../../hooks';
import useResourses from '../../../../hooks/useResourses';
import PopupExpensesDoc from '../../../../pubups/PopupExpensesDoc';
import IconView from '../IconView';

function ExpensesIcon({ isRTL, theme, color, height, company }) {
  const [openExpen, setOpenExpen] = useState(false);
  const { resourses } = useResourses();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { expenseItems } = useExpenseItems();

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };

  const [addExpenses] = useMutation(createExpenses, refresQuery);

  return (
    <Box>
      {userroles?.expenses?.add && (
        <>
          <IconView
            color={color ? color : theme.palette.primary.main}
            icon="expenses"
            title={isRTL ? 'انشاء مصروف' : 'Add Expenses'}
            height={height}
            onOpen={() => setOpenExpen(true)}
          ></IconView>

          <PopupExpensesDoc
            open={openExpen}
            onClose={() => setOpenExpen(false)}
            isNew={true}
            addAction={addExpenses}
            editAction={() => null}
            resourses={resourses}
            employees={employees}
            departments={departments}
            servicesproducts={expenseItems}
            theme={theme}
            company={company}
          ></PopupExpensesDoc>
        </>
      )}
    </Box>
  );
}

export default ExpensesIcon;
