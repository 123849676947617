/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useState } from 'react';
import {
  Badge,
  Box,
  fade,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
import { applyRole } from '../../common/roles';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  useDepartments,
  useEmployees,
  useExpenseItems,
  useServices,
  useTemplate,
} from '../../hooks';
import { sortMenu } from '../../constants/menu';
import MyIcon from '../../Shared/MyIcon';
import FastMenu from '../../Shared/FastMenu';
import { drawerWidth } from '../../constants';
import { userroles } from '../../common';
import PopupAppointment from '../../pubups/PopupAppointment';
import useResourses from '../../hooks/useResourses';
import useTasks from '../../hooks/useTasks';
import {
  createEvent,
  createExpenses,
  createFinance,
  createInvoice,
} from '../../graphql';
import { useMutation } from '@apollo/client';
import { GlobalContext } from '../../contexts';
import useCompany from '../../hooks/useCompany';
import { PopupInvoice } from '../../pubups';
import PopupReceipt from '../../pubups/PopupReceipt';
import PopupExpensesDoc from '../../pubups/PopupExpensesDoc';
import PopupFinance from '../../pubups/PopupFinance';

const Menu = ({
  isRTL,
  menuitem,
  setMenuitem,
  classes,
  theme,
  user,
  menu,
  setMobileOpen,
  logout,
  client,
  navigate,
  innerDrawer,
  open,
  newin,
}: any) => {
  const [openAppoint, setOpenAppoint] = useState(false);
  const [openInv, setOpenInv] = useState(false);
  const [openRece, setOpenRece] = useState(false);
  const [openExpen, setOpenExpen] = useState(false);
  const [openFins, setOpenFins] = useState(false);

  const { sortOrder, tempwords } = useTemplate();
  const rmenu = sortMenu({ menu, sortOrder });

  const { resourses } = useResourses();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { services } = useServices();
  const { tasks } = useTasks();
  const { expenseItems } = useExpenseItems();
  const { company } = useCompany();
  const { height } = useWindowDimensions();

  const {
    store: { calendar },
  } = useContext(GlobalContext);

  const [addEvent] = useMutation(createEvent);
  const [addInvoice] = useMutation(createInvoice);
  const [addFinance] = useMutation(createFinance);
  const [addExpenses] = useMutation(createExpenses);

  const addNewAppointment = () => setOpenAppoint(true);
  const addNewInvoice = () => setOpenInv(true);
  const addNewReceipt = () => setOpenRece(true);
  const addNewExpenses = () => setOpenExpen(true);
  const addNewFinance = () => setOpenFins(true);

  return (
    <Box
      style={{
        marginTop: 50,
        height: height - 100,
        overflow: 'auto',
      }}
    >
      <List>
        {rmenu.map((item: any, i: any) => {
          const submenu = item.subMenu;
          const isSelected = item.name === menuitem.name;
          const role = item.role ? applyRole(item.role, user) : true;
          if (!role) {
            return <div key={item.id}></div>;
          }
          const color = isSelected
            ? fade(theme.palette.secondary.main, 0.8)
            : '#eee';
          const title = isRTL ? item.titleAr : item.titleEn;
          return (
            <div key={item.id}>
              {!submenu && (
                <Link
                  style={{
                    textDecoration: 'none',
                    color: isSelected ? '#333' : '#eee',
                  }}
                  to={`${item.uri}`}
                  title={title}
                  onClick={async () => {
                    if (item.id === 99) {
                      logout();
                      await client.resetStore();
                      navigate('/');
                    } else {
                      setMenuitem(item);
                      if (setMobileOpen) {
                        setMobileOpen(false);
                      }
                    }
                    if (item.uri === '/pos') {
                      document.body.requestFullscreen();
                    }
                  }}
                >
                  <ListItem
                    dir={isRTL ? 'rtl' : undefined}
                    className={classes.child}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: isSelected
                        ? theme.palette.primary.dark
                        : undefined,
                      margin: 0,
                      padding: !open ? 12 : 7,
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: open ? 'flex-start' : 'center',
                        width: open ? 40 : 45,
                        height: open ? 35 : 45,
                      }}
                    >
                      {item?.name === 'mail' && newin > 0 ? (
                        <Badge
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          badgeContent={newin}
                          color="error"
                        >
                          <MyIcon
                            icon={item.icon}
                            color={color}
                            size={open ? 28 : 34}
                          ></MyIcon>
                        </Badge>
                      ) : (
                        <MyIcon
                          icon={item.icon}
                          color={color}
                          size={open ? 28 : 34}
                        ></MyIcon>
                      )}
                      {!open && (
                        <Box>
                          <Typography
                            style={{
                              fontSize: 10,
                              color: isSelected
                                ? theme.palette.secondary.main
                                : undefined,
                            }}
                          >
                            {title}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {open && (
                      <ListItemText
                        primary={isRTL ? item.titleAr : item.titleEn}
                        className={isRTL ? classes.txtrtl : classes.txtltr}
                        style={{
                          fontSize: 16,
                          marginLeft: 5,
                          marginRight: 5,
                          marginBottom: isRTL ? 7 : undefined,
                          color: isSelected
                            ? theme.palette.secondary.main
                            : undefined,
                        }}
                      />
                    )}
                  </ListItem>
                </Link>
              )}
              {open && submenu && (
                <MenuItem
                  item={item}
                  classes={classes}
                  isSelected={isSelected}
                  isRTL={isRTL}
                  theme={theme}
                  submenu={submenu}
                  i={i}
                  setMenuitem={setMenuitem}
                  menuitem={menuitem}
                  user={user}
                  setMobileOpen={setMobileOpen}
                  innerDrawer={innerDrawer}
                ></MenuItem>
              )}
            </div>
          );
        })}
      </List>
      <Box
        style={{
          position: 'absolute',
          bottom: 60,
          height: 1,
          width: '100%',
          backgroundColor: '#777',
        }}
      ></Box>
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          height: 60,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <FastMenu
          isRTL={isRTL}
          primary={theme.palette.primary.main}
          width={drawerWidth}
          navigate={navigate}
          tempwords={tempwords}
          userroles={userroles}
          addNewAppointment={addNewAppointment}
          addNewInvoice={addNewInvoice}
          addNewReceipt={addNewReceipt}
          addNewExpenses={addNewExpenses}
          addNewFinance={addNewFinance}
        ></FastMenu>
      </Box>
      <PopupAppointment
        open={openAppoint}
        onClose={() => setOpenAppoint(false)}
        addAction={addEvent}
        editAction={() => null}
        employees={employees}
        resourses={resourses}
        departments={departments}
        company={company}
        servicesproducts={services}
        theme={theme}
        tasks={tasks}
        isNew={true}
        calendar={calendar}
      ></PopupAppointment>
      <PopupInvoice
        open={openInv}
        onClose={() => setOpenInv(false)}
        addAction={addInvoice}
        editAction={() => null}
        resourses={resourses}
        employees={employees}
        departments={departments}
        company={company}
        servicesproducts={services}
        tasks={tasks}
        isNew={true}
        theme={theme}
      ></PopupInvoice>
      <PopupReceipt
        open={openRece}
        onClose={() => setOpenRece(false)}
        isNew={true}
        addAction={addFinance}
        editAction={() => null}
        theme={theme}
        company={company}
      ></PopupReceipt>
      <PopupExpensesDoc
        open={openExpen}
        onClose={() => setOpenExpen(false)}
        isNew={true}
        addAction={addExpenses}
        editAction={() => null}
        resourses={resourses}
        employees={employees}
        departments={departments}
        servicesproducts={expenseItems}
        theme={theme}
        company={company}
      ></PopupExpensesDoc>
      <PopupFinance
        open={openFins}
        onClose={() => setOpenFins(false)}
        isNew={true}
        addAction={addFinance}
        editAction={() => null}
        theme={theme}
      ></PopupFinance>
    </Box>
  );
};

export default Menu;
