/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box, fade, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { userroles } from '../../common';
import useCompany from '../../hooks/useCompany';
import UserBox from '../../Shared/UserBox';

export default function PageLayout(props: any) {
  const {
    children,
    menuitem,
    isRTL,
    refresh,
    theme,
    bgcolor,
    bgimage,
    loading,
    width,
    headerColor = fade(theme.palette.primary.main, 0.1),
    notifisub,
  } = props;

  const { company } = useCompany();
  const paperstyle = bgimage
    ? {
        backgroundImage: `url(${bgimage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width,
      }
    : { width };
  return (
    <div style={paperstyle}>
      <div style={{ backgroundColor: bgcolor }}>
        <Hidden xsDown implementation="css">
          <div
            style={{
              paddingLeft: 25,
              paddingRight: 25,
              height: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: headerColor,
            }}
          >
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {isRTL ? menuitem.titleAr : menuitem.titleEn}
            </Typography>
            {userroles.isBranchsAdmin && (
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  fontSize: 18,
                }}
              >
                {isRTL ? company?.nameAr : company?.name}
              </Typography>
            )}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <UserBox
                refresh={refresh}
                loading={loading}
                isRTL={isRTL}
                theme={theme}
                notifisub={notifisub}
              ></UserBox>
            </Box>
          </div>
        </Hidden>
        {React.cloneElement(children, { ...props })}
      </div>
    </div>
  );
}
