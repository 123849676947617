import { Box } from '@material-ui/core';
import { useEmployees } from '../../../../hooks';
import useResourses from '../../../../hooks/useResourses';
import RemindersOutBox from '../../../../Shared/RemindersOutBox';

function RemindBox({ isRTL, words, theme, height }) {
  const { resourses } = useResourses();
  const { employees } = useEmployees();
  return (
    <Box>
      <RemindersOutBox
        isRTL={isRTL}
        words={words}
        height={height}
        theme={theme}
        resourses={resourses}
        employees={employees}
      ></RemindersOutBox>
    </Box>
  );
}

export default RemindBox;
