/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTemplate, useWindowDimensions } from '../../hooks';
import useSections from '../../hooks/useSections';
import Header from './Header';
import Invoice from './invoice/Invoice';
import ProductsView from './products/ProductsView';

const HEADER_HIGHT = 80;
const SECTION_WIDTH = 80;
export const POS_BACKGROUND = '#f5f5f5';
export const POS_FORGROUND = '#333';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
      no: index + 1,
    };
  });
};

export default function MainPOS({
  menuitem,
  isRTL,
  words,
  theme,
  user,
  company,
}: any) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selected, setSelected] = useState(null);
  // const [itemsList, setItemsList] = useState<any>([]);
  // const [discount, setDiscount] = useState(0);
  // const [total, setTotal] = useState<any>({});

  const { tempoptions } = useTemplate();
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);
  const { height, width } = useWindowDimensions();
  const { sections } = useSections();

  useEffect(() => {
    if (!selected) {
      setSelected(sections?.[0]);
    }
  }, [sections]);

  // const addItemToList = (item: any) => {
  //   const newArray = [...itemsList, { ...item, userId: user._id }];
  //   const listwithindex = indexTheList(newArray);
  //   setItemsList(listwithindex);
  // };

  // const changeQuantity = (item: any, couny: any) => {
  //   const newArray = [...itemsList, { ...item, userId: user._id }];
  // };
  // const removeItemFromList = (index: any) => {
  //   const newList = [...itemsList];
  //   newList.splice(index, 1);
  //   const listwithindex = indexTheList(newList);
  //   setItemsList(listwithindex);
  // };

  const areawidth = width - SECTION_WIDTH;
  const invwidth = areawidth / 3;
  const prodwidth = (2 * areawidth) / 3;
  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        height,
        backgroundColor: POS_FORGROUND,
      }}
    >
      <Box
        style={{
          width: SECTION_WIDTH,
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            transform: 'rotate(90deg)',
          }}
        >
          {sections.map((section: any) => {
            const name = isRTL ? section?.nameAr : section?.name;
            const active = selected?._id === section?._id;
            return (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 120,
                  backgroundColor: active ? POS_BACKGROUND : POS_FORGROUND,
                  marginTop: 15,
                  height: 55,
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                  marginLeft: 10,
                  marginRight: 10,
                  cursor: 'pointer',
                }}
                onClick={() => setSelected(section)}
              >
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    color: active ? POS_FORGROUND : POS_BACKGROUND,
                    letterSpacing: 0.8,
                  }}
                >
                  {name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        style={{
          width: width - SECTION_WIDTH,
        }}
      >
        <Grid
          container
          spacing={0}
          style={{
            backgroundColor: '#f5f5f5',
            borderRadius: 25,
          }}
        >
          <Grid item xs={12}>
            <Header
              height={HEADER_HIGHT}
              isRTL={isRTL}
              isFullscreen={isFullscreen}
              theme={theme}
            ></Header>
          </Grid>
          <Grid item xs={8}>
            <ProductsView
              width={prodwidth}
              tempoptions={tempoptions}
              height={height - HEADER_HIGHT}
              isRTL={isRTL}
              theme={theme}
              selected={selected}
            ></ProductsView>
          </Grid>
          <Grid item xs={4}>
            <Invoice
              isRTL={isRTL}
              width={invwidth}
              height={height - HEADER_HIGHT}
            ></Invoice>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
