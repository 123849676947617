import { Grid, Typography } from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { renderCheckbox } from './TemplateOptions';
const renderWord = (title: any, word: any, setWord: any) => {
  return (
    <TextFieldLocal
      name="chequeDate"
      label={title}
      value={word}
      onChange={(e: any) => setWord(title, e.target.value)}
      fullWidth
      mb={0}
    />
  );
};

const WordItem = ({ value, setValue }: any) => {
  if (!value) return <div></div>;

  const setItem = (name: any, v: any) => {
    setValue({ ...value, [name]: v });
  };

  return (
    <Grid item xs>
      {renderWord('main', value.main, setItem)}
      {renderWord('operation', value.operation, setItem)}
      {renderWord('operations', value.operations, setItem)}
      {renderWord('appointment', value.appointment, setItem)}
      {renderWord('appointments', value.appointments, setItem)}
      {renderWord('eventsitems', value.eventsitems, setItem)}
      {renderWord('invoicesitems', value.invoicesitems, setItem)}
      {renderWord('task', value.task, setItem)}
      {renderWord('tasks', value.tasks, setItem)}
      {renderWord('department', value.department, setItem)}
      {renderWord('departments', value.departments, setItem)}
      {renderWord('customer', value.customer, setItem)}
      {renderWord('customers', value.customers, setItem)}
      {renderWord('suppliers', value.suppliers, setItem)}
      {renderWord('employee', value.employee, setItem)}
      {renderWord('employees', value.employees, setItem)}
      {renderWord('employee2', value.employee2, setItem)}
      {renderWord('employees2', value.employees2, setItem)}
      {renderWord('resourse', value.resourse, setItem)}
      {renderWord('resourses', value.resourses, setItem)}
      {renderWord('project', value.project, setItem)}
      {renderWord('projects', value.projects, setItem)}
      {renderWord('service', value.service, setItem)}
      {renderWord('services', value.services, setItem)}
      {renderWord('expenseitems', value.expenseitems, setItem)}
      {renderWord('retype', value.retype, setItem)}
      {renderWord('evretype', value.evretype, setItem)}
      {renderWord('evretype2', value.evretype2, setItem)}
    </Grid>
  );
};

const TemplateOptions = ({ temp, setTemp }) => {
  if (!temp) return <div></div>;
  const {
    words,
    tabsemployee,
    tabsdepartment,
    tabsresourse,
    tabscustomer,
    tabssupplier,
    tabsproject,
    tabstask,
  } = temp;

  const setArwords = (wds: any) => {
    const newar = { ...temp.words.ar, ...wds };
    const newwords = {
      en: words.en,
      ar: newar,
    };
    const newtemp = {
      ...temp,
      words: newwords,
    };
    setTemp(newtemp);
  };
  const setEnwords = (wds: any) => {
    const newen = { ...temp.words.en, ...wds };
    const newwords = {
      ar: words.ar,
      en: newen,
    };
    const newtemp = {
      ...temp,
      words: newwords,
    };
    setTemp(newtemp);
  };

  const setTabsEmployee = (name: any) => {
    const newtemp = {
      ...temp,
      tabsemployee: {
        ...temp.tabsemployee,
        [name]: !tabsemployee[name],
      },
    };
    setTemp(newtemp);
  };
  const setTabsDepartment = (name: any) => {
    const newtemp = {
      ...temp,
      tabsdepartment: {
        ...temp.tabsdepartment,
        [name]: !tabsdepartment[name],
      },
    };
    setTemp(newtemp);
  };
  const setTabsResourse = (name: any) => {
    const newtemp = {
      ...temp,
      tabsresourse: {
        ...temp.tabsresourse,
        [name]: !tabsresourse[name],
      },
    };
    setTemp(newtemp);
  };
  const setTabsCustomer = (name: any) => {
    const newtemp = {
      ...temp,
      tabscustomer: {
        ...temp.tabscustomer,
        [name]: !tabscustomer[name],
      },
    };
    setTemp(newtemp);
  };
  const setTabsSupplier = (name: any) => {
    const newtemp = {
      ...temp,
      tabssupplier: {
        ...temp.tabssupplier,
        [name]: !tabssupplier[name],
      },
    };
    setTemp(newtemp);
  };
  const setTabsProject = (name: any) => {
    const newtemp = {
      ...temp,
      tabsproject: {
        ...temp.tabsproject,
        [name]: !tabsproject[name],
      },
    };
    setTemp(newtemp);
  };
  const setTabsTask = (name: any) => {
    const newtemp = {
      ...temp,
      tabstask: {
        ...temp.tabstask,
        [name]: !tabstask[name],
      },
    };
    setTemp(newtemp);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <WordItem value={words.ar} setValue={setArwords}></WordItem>
      </Grid>
      <Grid item xs={6}>
        <WordItem value={words.en} setValue={setEnwords}></WordItem>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Employee Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabsemployee?.main, setTabsEmployee)}
        {renderCheckbox('projects', tabsemployee?.projects, setTabsEmployee)}
        {renderCheckbox('tasks', tabsemployee?.tasks, setTabsEmployee)}
        {renderCheckbox('events', tabsemployee?.events, setTabsEmployee)}
        {renderCheckbox(
          'eventsitems',
          tabsemployee?.eventsitems,
          setTabsEmployee
        )}
        {renderCheckbox('invoices', tabsemployee?.invoices, setTabsEmployee)}
        {renderCheckbox(
          'invoicesitems',
          tabsemployee?.invoicesitems,
          setTabsEmployee
        )}
        {renderCheckbox('receipts', tabsemployee?.receipts, setTabsEmployee)}
        {renderCheckbox('purchases', tabsemployee?.purchases, setTabsEmployee)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('payments', tabsemployee?.payments, setTabsEmployee)}
        {renderCheckbox('expenses', tabsemployee?.expenses, setTabsEmployee)}
        {renderCheckbox(
          'expensesprod',
          tabsemployee?.expensesprod,
          setTabsEmployee
        )}
        {renderCheckbox('advanced', tabsemployee?.advanced, setTabsEmployee)}
        {renderCheckbox('custody', tabsemployee?.custody, setTabsEmployee)}
        {renderCheckbox('entries', tabsemployee?.entries, setTabsEmployee)}
        {renderCheckbox('reminders', tabsemployee?.reminders, setTabsEmployee)}
        {renderCheckbox(
          'expensesitems',
          tabsemployee?.expensesitems,
          setTabsEmployee
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Department Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabsdepartment?.main, setTabsDepartment)}
        {renderCheckbox(
          'projects',
          tabsdepartment?.projects,
          setTabsDepartment
        )}
        {renderCheckbox('tasks', tabsdepartment?.tasks, setTabsDepartment)}
        {renderCheckbox('events', tabsdepartment?.events, setTabsDepartment)}
        {renderCheckbox(
          'eventsitems',
          tabsdepartment?.eventsitems,
          setTabsDepartment
        )}
        {renderCheckbox(
          'invoices',
          tabsdepartment?.invoices,
          setTabsDepartment
        )}
        {renderCheckbox(
          'invoicesitems',
          tabsdepartment?.invoicesitems,
          setTabsDepartment
        )}
        {renderCheckbox(
          'receipts',
          tabsdepartment?.receipts,
          setTabsDepartment
        )}
        {renderCheckbox(
          'expensesitems',
          tabsdepartment?.expensesitems,
          setTabsDepartment
        )}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox(
          'purchases',
          tabsdepartment?.purchases,
          setTabsDepartment
        )}
        {renderCheckbox(
          'payments',
          tabsdepartment?.payments,
          setTabsDepartment
        )}
        {renderCheckbox(
          'expenses',
          tabsdepartment?.expenses,
          setTabsDepartment
        )}
        {renderCheckbox(
          'expensesprod',
          tabsdepartment?.expensesprod,
          setTabsDepartment
        )}
        {renderCheckbox('entries', tabsdepartment?.entries, setTabsDepartment)}
        {renderCheckbox(
          'reminders',
          tabsdepartment?.reminders,
          setTabsDepartment
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Resourse Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabsresourse?.main, setTabsResourse)}
        {renderCheckbox('projects', tabsresourse?.projects, setTabsResourse)}
        {renderCheckbox('tasks', tabsresourse?.tasks, setTabsResourse)}
        {renderCheckbox('events', tabsresourse?.events, setTabsResourse)}
        {renderCheckbox(
          'eventsitems',
          tabsresourse?.eventsitems,
          setTabsResourse
        )}
        {renderCheckbox('invoices', tabsresourse?.invoices, setTabsResourse)}
        {renderCheckbox(
          'invoicesitems',
          tabsresourse?.invoicesitems,
          setTabsResourse
        )}
        {renderCheckbox('receipts', tabsresourse?.receipts, setTabsResourse)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('purchases', tabsresourse?.purchases, setTabsResourse)}
        {renderCheckbox('payments', tabsresourse?.payments, setTabsResourse)}
        {renderCheckbox('expenses', tabsresourse?.expenses, setTabsResourse)}
        {renderCheckbox(
          'expensesprod',
          tabsresourse?.expensesprod,
          setTabsResourse
        )}
        {renderCheckbox('entries', tabsresourse?.entries, setTabsResourse)}
        {renderCheckbox('reminders', tabsresourse?.reminders, setTabsResourse)}
        {renderCheckbox(
          'expensesitems',
          tabsresourse?.expensesitems,
          setTabsResourse
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Customer Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabscustomer?.main, setTabsCustomer)}
        {renderCheckbox('projects', tabscustomer?.projects, setTabsCustomer)}
        {renderCheckbox('tasks', tabscustomer?.tasks, setTabsCustomer)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('events', tabscustomer?.events, setTabsCustomer)}
        {renderCheckbox(
          'eventsitems',
          tabscustomer?.eventsitems,
          setTabsCustomer
        )}
        {renderCheckbox('invoices', tabscustomer?.invoices, setTabsCustomer)}
        {renderCheckbox(
          'invoicesitems',
          tabscustomer?.invoicesitems,
          setTabsCustomer
        )}
        {renderCheckbox('receipts', tabscustomer?.receipts, setTabsCustomer)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Supplier Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabssupplier?.main, setTabsSupplier)}
        {renderCheckbox('purchases', tabssupplier?.purchases, setTabsSupplier)}
        {renderCheckbox(
          'expensesitems',
          tabssupplier?.expensesitems,
          setTabsSupplier
        )}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('payments', tabssupplier?.payments, setTabsSupplier)}
        {renderCheckbox('expenses', tabssupplier?.expenses, setTabsSupplier)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Project Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabsproject?.main, setTabsProject)}
        {renderCheckbox('tasks', tabsproject?.tasks, setTabsProject)}
        {renderCheckbox('events', tabsproject?.events, setTabsProject)}
        {renderCheckbox(
          'eventsitems',
          tabsproject?.eventsitems,
          setTabsProject
        )}
        {renderCheckbox('invoices', tabsproject?.invoices, setTabsProject)}
        {renderCheckbox(
          'invoicesitems',
          tabsproject?.invoicesitems,
          setTabsProject
        )}
        {renderCheckbox('receipts', tabsproject?.receipts, setTabsProject)}
        {renderCheckbox('purchases', tabsproject?.purchases, setTabsProject)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('payments', tabsproject?.payments, setTabsProject)}
        {renderCheckbox('expenses', tabsproject?.expenses, setTabsProject)}
        {renderCheckbox(
          'expensesprod',
          tabsproject?.expensesprod,
          setTabsProject
        )}
        {renderCheckbox('entries', tabsproject?.entries, setTabsProject)}
        {renderCheckbox('reminders', tabsproject?.reminders, setTabsProject)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Task Tabs</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('main', tabstask?.main, setTabsTask)}
        {renderCheckbox('events', tabstask?.events, setTabsTask)}
        {renderCheckbox('eventsitems', tabstask?.eventsitems, setTabsTask)}
        {renderCheckbox('invoices', tabstask?.invoices, setTabsTask)}
        {renderCheckbox('invoicesitems', tabstask?.invoicesitems, setTabsTask)}
        {renderCheckbox('receipts', tabstask?.receipts, setTabsTask)}
        {renderCheckbox('purchases', tabstask?.purchases, setTabsTask)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('payments', tabstask?.payments, setTabsTask)}
        {renderCheckbox('expenses', tabstask?.expenses, setTabsTask)}
        {renderCheckbox('expensesprod', tabstask?.expensesprod, setTabsTask)}
        {renderCheckbox('entries', tabstask?.entries, setTabsTask)}
        {renderCheckbox('reminders', tabstask?.reminders, setTabsTask)}
        {renderCheckbox('expensesitems', tabstask?.expensesitems, setTabsTask)}
      </Grid>
    </Grid>
  );
};

export default TemplateOptions;
