import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { getPresignedURL } from '../graphql/mutation';
import { nanoFilename } from './helpers';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import imageCompression from 'browser-image-compression';
import { imageUploadOptions } from '../constants/sizes';
import { DropzoneArea } from 'material-ui-dropzone';
import '../global.css';
const baseurl = 'https://jadwal.ams3.digitaloceanspaces.com/';

const SpacesUpload = ({ branch, isRTL }: any) => {
  const [files, setFiles] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const day = new Date().toLocaleDateString('en-GB')?.replaceAll('/', '-');

  const path = `jadwalweb/${branch}/${day}/`;

  const [getURL] = useMutation(getPresignedURL);

  console.log('files', files);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      for (const f of files) {
        await uploadFile(f);
      }
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
      alert('There was an error uploading your file.');
      throw err;
    }
    setIsSubmitting(false);
    alert('Upload success. Check out your Space.');
  };

  const getSignedUrl = async (f: any) => {
    let upfile = f;
    if (f?.type?.startsWith('image')) {
      if (f?.size > 1048576) {
        upfile = await imageCompression(f, imageUploadOptions);
      }
    } else {
      if (f?.size > 5000000) {
        return { file: null, err: 'over 5 MB', signedUrl: null };
      }
    }
    try {
      const filename = `${nanoFilename()}_${upfile.name}`;
      const url = `${baseurl}${path}${filename}`;
      console.log('url', url);
      const variables = {
        filename: `${path}${filename}`,
        filetype: upfile.type,
      };
      const res = await getURL({ variables });
      return {
        file: upfile,
        signedUrl: res.data?.getPresignedURL?.data,
        err: null,
      };
    } catch (error) {
      console.log('error', error);
      return { file: null, err: error, signedUrl: null };
    }
  };

  const uploadFile = async (f: any) => {
    const { file, signedUrl, err } = await getSignedUrl(f);
    if (err !== null) {
      console.log('err', err);
      return err;
    }
    const res = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    });
    console.log('res', res);
    return res;
  };

  const handleChange = (fls: any) => {
    // console.log('fls', fls);
    setFiles(fls);
  };

  return (
    <>
      <Box>
        <DropzoneArea
          showPreviewsInDropzone={false}
          dropzoneClass="drop-zone-area"
          showAlerts={false}
          filesLimit={20}
          maxFileSize={5000000}
          onChange={handleChange}
          dropzoneText={
            isRTL ? 'حمل الملفات من هنا' : 'Drag and drop a file here or click'
          }
        />

        <Box style={{ width: 150, height: 40, margin: 25 }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            style={{ height: 40 }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              {isRTL ? 'رفع الصور' : 'Upload Photos'}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box style={{ backgroundColor: 'red', padding: 25 }}>
        {files &&
          files.map((row: any, index: any) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={4}>
                  {row?.name}
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </>
  );
};

export default SpacesUpload;
