import React, { useEffect, useReducer, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import { createThem } from './themes';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getStoreItem, setStoreItem } from './store';
import { Layout } from './pages/main';
import { initStore, storeReducer } from './store';
import { GlobalContext } from './contexts';
import { graphqlURI, wsURI } from './constants';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { initTrans } from './constants/branch';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const languagesState = getStoreItem('languages');
  const [languages, setLanguages] = useState(languagesState);

  const storeState = getStoreItem('store', initStore);
  const [store, dispatch] = useReducer(
    storeReducer,
    storeState ? storeState : initStore
  );
  const theme = createThem({ lang: store.lang, themeId: store.themeId });
  useEffect(() => {
    setStoreItem('store', store);
  }, [store]);

  useEffect(() => {
    setStoreItem('languages', languages);
  }, [languages]);

  const token = store ? store.token : null;

  const httpLink = new HttpLink({ uri: graphqlURI });
  const wsLink = new GraphQLWsLink(createClient({ url: wsURI }));
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Jadwal ${token}` : '',
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    try {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message }) => {
          console.log('message', message);
          if (message === 'auth token error') {
            dispatch({ type: 'logout' });
          }
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    } catch (error) {
      console.log(error);
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(splitLink),
    cache: new InMemoryCache(),
  });

  const translate = languages?.ar?.words
    ? store.lang === 'ar'
      ? languages?.ar
      : languages?.en
    : initTrans;
  return (
    <ApolloProvider client={client}>
      <GlobalContext.Provider value={{ store, dispatch, translate }}>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>
            <Layout
              lang={store.lang}
              languages={languages}
              setLanguages={setLanguages}
              user={store?.user}
            ></Layout>
          </ThemeProvider>
        </StylesProvider>
      </GlobalContext.Provider>
    </ApolloProvider>
  );
}

export default App;
