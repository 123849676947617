import { gql } from '@apollo/client';

export default gql`
  mutation listSpacesFiles($perfix: String) {
    listSpacesFiles(perfix: $perfix) {
      ok
      data
    }
  }
`;
