/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Dialog,
  DialogContent,
  fade,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../contexts';
function PaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function PopupLayoutBox(props: any) {
  const {
    children,
    isRTL,
    theme,
    visible,
    onHide,
    title,
    width,
    height,
    mt = 30,
    mb = 70,
    setEvenetOpened,
    removeTempEvent,
    setTemp,
    tempoptions,
  } = props;
  const {
    store: { fullscreen },
  }: any = useContext(GlobalContext);

  useEffect(() => {
    if (setEvenetOpened) setEvenetOpened(visible);
  }, [visible]);

  const close = () => {
    if (tempoptions?.tempevent) {
      removeTempEvent({});
      setTemp(true);
    }
    onHide();
  };

  return (
    <Dialog
      open={visible}
      onClose={close}
      PaperComponent={PaperComponent}
      aria-describedby="scroll-dialog-description"
      aria-labelledby="draggable-dialog-title"
      maxWidth="lg"
      fullScreen={fullscreen}
    >
      <Box
        id="draggable-dialog-title"
        style={{
          direction: isRTL ? 'rtl' : 'ltr',
          backgroundColor: theme
            ? fade(theme.palette.primary.main, 0.15)
            : '#f5f5f5',
          cursor: 'move',
          maxHeight: 50,
          minHeight: 50,
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          style={{
            fontSize: 20,
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          {title}
        </Typography>
      </Box>
      <IconButton
        style={{ position: 'absolute', right: isRTL ? undefined : 0 }}
        onClick={close}
      >
        <CloseOutlinedIcon
          style={{ fontSize: 26, color: '#777' }}
        ></CloseOutlinedIcon>
      </IconButton>
      <DialogContent
        style={{
          width: width,
          height: height,
          direction: isRTL ? 'rtl' : 'ltr',
          paddingTop: mt,
          paddingBottom: mb,
        }}
        dividers={true}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}
