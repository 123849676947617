import { RRule, RRuleSet } from 'rrule';

export default function getRruleData({
  freq = RRule.WEEKLY,
  dtstart,
  until,
  interval,
  byweekday,
  bymonthday,
  count,
  isCustom,
}: any) {
  if (!isNaN(dtstart?.getTime())) {
    if (isCustom) {
      const all = [dtstart, until];
      const str = `${dtstart}, ${until}`;
      const txt = `Custom ${dtstart}, ${until}`;
      return { all, str, txt };
    } else {
      if (bymonthday?.includes(30)) {
        const ruleall = new RRule({
          freq,
          interval,
          dtstart,
          bymonthday,
          count,
        });

        const febRule = new RRule({
          freq,
          interval,
          dtstart,
          bymonth: [2],
          bymonthday: [28, 29],
          bysetpos: -1,
          count,
        });

        const ruleSet = new RRuleSet();
        ruleSet.rrule(ruleall);
        ruleSet.rrule(febRule);

        const all = ruleSet.all().slice(0, count);
        const str = ruleSet.toString();
        const txt = ruleSet.toText();
        return { all, str, txt };
      } else {
        const rule = new RRule({
          freq,
          interval,
          byweekday,
          bymonthday,
          dtstart,
          until,
          count: count
            ? byweekday?.[0] || bymonthday?.[0]
              ? count
              : count + 1
            : null,
        });
        const all = rule.all();
        const str = rule.toString();
        const txt = rule.toText();
        return { all, str, txt };
      }
    }
  }
}

export const getReminderRruleData = ({
  freq = RRule.WEEKLY,
  dtstart,
  until,
  interval = 1,
  byweekday,
  bymonthday,
  count = 1,
}: any) => {
  const rule = new RRule({
    freq,
    dtstart,
    until,
    interval,
    byweekday,
    bymonthday,
    count,
  });
  const all = rule.all();
  const str = rule.toString();
  const txt = rule.toText();
  return { all, str, txt };
};

export const isToday = (someDate: any) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};
