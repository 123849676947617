/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

export const ccyFormat = (num: number) => {
  if (num > 0) {
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  return '';
};

const OptionProductData = ({
  item,
  isRTL,
  price = true,
  isPurchase,
  minstock,
}: any) => {
  const name = isRTL
    ? item?.nameAr?.substring(0, 60)
    : item?.name?.substring(0, 60);
  const belowmin =
    item?.itemType === 1 && minstock && minstock > 0
      ? item?.quantity < minstock && item?.quantity > 0
      : false;
  const belowzero = item?.itemType === 1 && item?.quantity <= 0;
  return (
    <Grid container spacing={1} style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: belowzero
            ? '#F6685D33'
            : belowmin
            ? '#ff9a0033'
            : undefined,
        }}
      >
        <Typography
          style={{
            textAlign: isRTL ? 'right' : 'left',
          }}
        >
          {name || (isRTL ? item.nameAr : item.name)}
        </Typography>

        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: isRTL ? 15 : undefined,
            right: isRTL ? undefined : 15,
          }}
        >
          <Typography style={{ color: '#0070f7', width: 100, fontSize: 11 }}>
            {item.quantity || ''}
          </Typography>
        </Box>
        {price && price > 0 && (
          <Box
            style={{
              position: 'absolute',
              top: 12,
              left: isRTL ? 15 : undefined,
              right: isRTL ? undefined : 15,
            }}
          >
            <Typography
              style={{ color: '#00B77C', width: 100, fontSize: 11 }}
              variant={isRTL ? 'subtitle1' : 'caption'}
            >
              {isPurchase
                ? item.cost
                  ? ccyFormat(item.cost)
                  : '0.00'
                : item.price
                ? ccyFormat(item.price)
                : '0.00'}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default OptionProductData;
