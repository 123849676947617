import { Box } from '@material-ui/core';
import useLandingChart from '../../../../hooks/useLandingChart';
import { userroles } from '../../../constants';
import { moneyFormat, getColor } from '../../common/helpers';
import InfoBox from '../InfoBox';

function TodayTotalSales({
  isRTL,
  theme,
  dark,
  color,
  systemcolor,
  height,
  employeeId = null,
  isEmployee = false,
}) {
  const { salesTodayTotal } = useLandingChart(employeeId);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {(isEmployee || userroles?.invoices?.admin) && (
        <InfoBox
          title={isRTL ? 'مبيعات اليوم' : 'Today Sales'}
          desc={''}
          value={moneyFormat(salesTodayTotal)}
          icon="sales"
          color={clr}
          dark={dark}
          height={height}
        ></InfoBox>
      )}
    </Box>
  );
}

export default TodayTotalSales;
