import { gql } from '@apollo/client';

export default gql`
  mutation updateNotifiRead($_id: String) {
    updateNotifiRead(_id: $_id) {
      ok
      message
      data
      error
    }
  }
`;
