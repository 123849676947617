import { gql } from '@apollo/client';

export default gql`
  subscription mailAdded {
    mailAdded {
      data
      userId
    }
  }
`;
