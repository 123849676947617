/* eslint-disable import/no-anonymous-default-export */
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useEffect } from 'react';
import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from '../graphql';
import { createMultiCategories } from '../graphql/mutation';

export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getCategories);

  const [addCategory] = useMutation(createCategory, {
    refetchQueries: [{ query: getCategories }],
  });
  const [addMultiCategory] = useMutation(createMultiCategories, {
    refetchQueries: [{ query: getCategories }],
  });
  const [editCategory] = useMutation(updateCategory, {
    refetchQueries: [{ query: getCategories }],
  });
  const [removeCategory] = useMutation(deleteCategory, {
    refetchQueries: [{ query: getCategories }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const categories = catpData?.data?.['getCategories']?.data || [];
  const refreshcategory = () => catpData?.refetch();
  const prodcats = categories?.filter(
    (cat: any) => cat?.catType === 1 && cat?.ispos
  );
  const servcats = categories?.filter(
    (cat: any) => cat?.catType === 2 && cat?.ispos
  );
  return {
    categories,
    prodcats: _.orderBy(prodcats, 'order'),
    servcats: _.orderBy(servcats, 'order'),
    addCategory,
    addMultiCategory,
    editCategory,
    removeCategory,
    refreshcategory,
    loading: catpData?.loading,
  };
};
