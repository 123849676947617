import { gql } from '@apollo/client';

export default gql`
  query getSections($isRTL: Boolean) {
    getSections(isRTL: $isRTL) {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        disabled
        additionId
        name
        nameAr
        avatar
        color
        ispos
        order

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
