import { Box } from '@material-ui/core';

export default function Logo() {
  return (
    <Box
      display="flex"
      style={{
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99,
        }}
      >
        <img
          src={
            'https://res.cloudinary.com/jadwalio/image/upload/v1681603390/logo_ov15nv.png'
          }
          alt={'JADWAL'}
          height={75}
        />
      </Box>
    </Box>
  );
}
