import {
  CurrentTimeIndicator,
  DayView,
  GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import { createStyles, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { weekdaysNNo } from '../constants/datatypes';
import { GlobalContext } from '../contexts';

export const DayScaleCell = (props: any) => {
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const { today, startDate, groupingInfo, employees } = props;
  const employeeId = groupingInfo?.[0]?.id;
  let isDayOff: any;
  if (employeeId && startDate) {
    const employee = employees?.filter((em: any) => em._id === employeeId)?.[0];
    const day = weekdaysNNo?.[new Date(startDate)?.getDay()];
    if (employee) {
      const daysoff = employee?.daysoff;
      if (daysoff) {
        const days = daysoff ? JSON.parse(daysoff) : null;
        isDayOff = days && day ? days[day] : false;
      }
    }
  }
  const layoutClasses = makeStyles(() =>
    createStyles({
      dayStyle: {
        '& div p': {
          color: calendar?.dayColor,
          fontSize: calendar?.daySize,
        },
        '& div div': {
          fontSize: calendar?.daySize
            ? calendar?.daySize * 2
            : calendar?.daySize,
          color: calendar?.dayColor,
        },
      },
      dayStyleToday: {
        '& div p': {
          fontSize: calendar?.todaySize,
          color: calendar?.todayColor,
        },
        '& div div': {
          fontSize: calendar?.todaySize
            ? calendar?.todaySize * 2
            : calendar?.todaySize,
          color: calendar?.todayColor,
        },
      },
      timeStyle: {
        '& span': {
          fontWeight: 'bold',
          fontSize: calendar?.timeSize,
          color: calendar?.timeColor,
        },
      },
    })
  );
  const classes = layoutClasses();
  return (
    <DayView.DayScaleCell
      {...props}
      className={today ? classes?.dayStyleToday : classes.dayStyle}
      style={{ backgroundColor: isDayOff ? '#ff000044' : undefined }}
    />
  );
};
export const TimeScaleLabel = (props: any) => {
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const layoutClasses = makeStyles(() =>
    createStyles({
      dayStyle: {
        '& div p': {
          color: calendar?.dayColor,
          fontSize: calendar?.daySize,
        },
        '& div div': {
          fontSize: calendar?.daySize
            ? calendar?.daySize * 2
            : calendar?.daySize,
          color: calendar?.dayColor,
        },
      },
      dayStyleToday: {
        '& div p': {
          fontSize: calendar?.todaySize,
          color: calendar?.todayColor,
        },
        '& div div': {
          fontSize: calendar?.todaySize
            ? calendar?.todaySize * 2
            : calendar?.todaySize,
          color: calendar?.todayColor,
        },
      },
      timeStyle: {
        '& span': {
          fontWeight: 'bold',
          fontSize: calendar?.timeSize,
          color: calendar?.timeColor,
        },
      },
    })
  );
  const classes = layoutClasses();

  return <DayView.TimeScaleLabel {...props} className={classes.timeStyle} />;
};
export const IndecatorColor = (props: any) => {
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const layoutClasses = makeStyles(() =>
    createStyles({
      indicator: {
        '& div': {
          backgroundColor: calendar?.indicatorColor,
        },
      },
    })
  );
  const classes = layoutClasses();

  return (
    <CurrentTimeIndicator.Indicator {...props} className={classes.indicator} />
  );
};

export const GroupHeadCell = (props: any) => {
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const layoutClasses = makeStyles(() =>
    createStyles({
      nameStyle: {
        '& div': {
          color: calendar?.nameColor,
          fontSize: calendar?.nameSize,
        },
      },
    })
  );
  const classes = layoutClasses();
  return <GroupingPanel.Cell {...props} className={classes.nameStyle} />;
};
