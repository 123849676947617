/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { userroles } from '../common';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import NewRolesView from '../common/NewRolesView';

export default function UserRolesEmail({
  branch,
  isRTL,
  words,
  isAccountant,
  isBranchAdmin,
  isBranchsAdmin,
  isEmployee,
  emplvalue,
  setisAccountant,
  setisBranchAdmin,
  setisBranchsAdmin,
  setisEmployee,
  setEmplvalue,
  employees,
  isNew,
  newroles,
  setItemValue,
}: any) {
  const generalmanager = isRTL ? 'مدير عام' : 'Branch Manager';
  const manager = isRTL ? 'مدير الشركة' : 'Branch Manager';
  const empl = isRTL ? 'موظف متخصص' : 'Employee';
  const accountant = isRTL ? 'محاسب' : 'Accountant';

  if ((isBranchAdmin || isBranchsAdmin) && !userroles?.isSuperAdmin) {
    return <div></div>;
  }

  return (
    <Box
      style={{
        padding: 10,
        borderRadius: 5,
        minWidth: 350,
        marginTop: 10,
      }}
    >
      <Grid container spacing={2}>
        {!isEmployee && userroles?.isSuperAdmin && (
          <Grid item xs={3}>
            <Box style={{ padding: 10, backgroundColor: '#eee' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBranchsAdmin}
                    onChange={() => {
                      setisBranchsAdmin(!isBranchsAdmin);
                    }}
                    name={branch}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {generalmanager}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        )}
        {!isEmployee && userroles?.isSuperAdmin && (
          <Grid item xs={3}>
            <Box style={{ padding: 10, backgroundColor: '#eee' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBranchAdmin}
                    onChange={() => {
                      setisBranchAdmin(!isBranchAdmin);
                    }}
                    name={branch}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {manager}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        )}
        {!isEmployee && userroles?.isSuperAdmin && (
          <Grid item xs={3}>
            <Box style={{ padding: 10, backgroundColor: '#eee' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAccountant}
                    onChange={() => {
                      setisAccountant(!isAccountant);
                    }}
                    name={branch}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {accountant}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        )}
        {isNew && !isBranchAdmin && !isBranchsAdmin && (
          <Grid item xs={3}>
            <Box style={{ padding: 10, backgroundColor: '#eee' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEmployee}
                    onChange={() => {
                      setisEmployee(!isEmployee);
                      if (!isEmployee) {
                        setisBranchAdmin(null);
                      }
                    }}
                    name={branch}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {empl}
                  </Typography>
                }
              />
            </Box>
            {isEmployee && isNew && (
              <Box style={{ height: 160 }}>
                <AutoFieldLocal
                  name="employee"
                  title={words.employee}
                  words={words}
                  options={employees.filter((emp: any) => !emp.disabled)}
                  value={emplvalue}
                  setSelectValue={setEmplvalue}
                  noPlus
                  isRTL={isRTL}
                  fullWidth
                  disabled={!isNew}
                ></AutoFieldLocal>
              </Box>
            )}
          </Grid>
        )}
        <Divider></Divider>
        {!isEmployee && (
          <Grid item xs={12}>
            {newroles && !isBranchAdmin && !isBranchsAdmin && (
              <NewRolesView
                newroles={newroles}
                isRTL={isRTL}
                setItemValue={setItemValue}
                isSuperAdmin={userroles?.isSuperAdmin}
              ></NewRolesView>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
