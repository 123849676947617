/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { LandingFooter, userroles } from '../../common';
import PageLayout from './PageLayout';
import { useBranches } from '../../hooks';
import BranchesBox from '../../Shared/BranchesBox';
import useBranchsLandingChart from '../../hooks/useBranchsLandingChart';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function AdminLanding(props: any) {
  const { words, isRTL, user, theme, menuitem, company, dispatch } = props;

  const location = useLocation();
  const { chartData, branchesData } = useBranchsLandingChart();
  const { branches } = useBranches();
  const { height } = useWindowDimensions();

  const refresh = () => {
    if (chartData?.refetch) {
      chartData.refetch();
    }
  };
  useLayoutEffect(() => {
    refresh();
  }, [location]);

  let data: any;
  if (
    branches &&
    branches?.length > 0 &&
    branchesData &&
    branchesData?.length > 0
  ) {
    const nbrans = branches.map((bran: any) => {
      const extra: any = {};
      const branddata = branchesData.filter(
        (bd: any) => bd.branch === bran?.basename
      );

      for (const bd of branddata) {
        if ('accounts' in bd) extra.accounts = bd?.accounts;
        if ('accountstoday' in bd) extra.accountstoday = bd?.accountstoday;
        if ('salesMonth' in bd) {
          extra.salesMonth = bd?.salesMonth;
          extra.salesMonthTotal = bd?.salesMonthTotal;
          extra.salesMonths = bd?.salesMonths;
        }
      }
      return {
        ...bran,
        ...extra,
      };
    });
    data = nbrans;
  }
  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      user={user}
      theme={theme}
      refresh={refresh}
      loading={chartData?.loading}
    >
      <Box
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          height: height - 50,
          overflow: 'auto',
        }}
      >
        {userroles?.isBranchsAdmin && (
          <BranchesBox
            basename={company?.basename}
            branches={data}
            dispatch={dispatch}
            isRTL={isRTL}
          ></BranchesBox>
        )}
        <LandingFooter></LandingFooter>
      </Box>
    </PageLayout>
  );
}
