/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { GContextTypes } from '../../types';
import { GlobalContext } from '../../contexts';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import PopupFormTemplate from './PopupFormTemplate';
import useDCategories from '../../hooks/useDCategories';
import { dublicateAlert, errorAlert, successAlert } from '../common/funcs';
import PopupLayout from '../shared/PopupLayout';
import TextFieldLocal from '../shared/TextFieldLocal';
import AutoFieldLocal from '../shared/AutoFieldLocal';
import FieldsTable from '../shared/FieldsTable';
import PopupPrintTemplate from './PopupPrintTemplate';

const PopupFormDoc = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
  company,
}: any) => {
  const [openForm, setOpenForm] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showOnCal, setShowOnCal] = useState(false);
  const [catvalue, setCatvalue] = useState<any>(null);

  const [fields, setFields] = useState([]);
  const [formTempDoc, setFormTempDoc] = useState([]);
  const [printTempDoc, setPrintTempDoc] = useState([]);
  const [formTempValues, setFormTempValues] = useState({});
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { register, handleSubmit, errors, reset } = useForm();
  const {
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);

  const { dcategories } = useDCategories();

  useEffect(() => {
    if (!isNew && row?._id) {
      if (row?.showOnCal === true || row?.showOnCal === false) {
        setShowOnCal(row?.showOnCal);
      }
      if (row?.disabled === true || row?.disabled === false) {
        setDisabled(row?.disabled);
      }
      if (row?.formTempDoc) {
        setFormTempDoc(JSON.parse(row?.formTempDoc));
      }
      if (row?.printTempDoc) {
        setPrintTempDoc(JSON.parse(row?.printTempDoc));
      }
      if (row?.formTempValues) {
        setFormTempValues(JSON.parse(row?.formTempValues));
      }
      if (row?.fields) {
        setFields(JSON.parse(row?.fields));
      }
      if (row?.dcategoryId) {
        const depart = dcategories.filter(
          (dep: any) => dep._id === row.dcategoryId
        )[0];
        setCatvalue(depart);
      }
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data.name;
    const nameAr = data.nameAr;
    const pname = data.pname;
    const pnameAr = data.pnameAr;
    const autoNoType = data.autoNoType;
    const autoNoPrefix = data.autoNoPrefix;
    const order = data.order ? Number(data.order) : undefined;
    const variables: any = {
      _id: row._id ? row._id : undefined, // is it new or edit
      name,
      nameAr,
      pname,
      pnameAr,
      autoNoType,
      autoNoPrefix,
      order,
      showOnCal,
      disabled,
      dcategoryId: catvalue ? catvalue?._id : undefined,
      dcategoryName: catvalue ? catvalue?.name : undefined,
      dcategoryNameAr: catvalue ? catvalue?.nameAr : undefined,
      gref: catvalue?.ref,
      fields: JSON.stringify(fields),
      formTempDoc: JSON.stringify(formTempDoc),
      printTempDoc: JSON.stringify(printTempDoc),
      formTempValues: JSON.stringify(formTempValues),
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createFormDoc' : 'updateFormDoc';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      closeModal();
      await successAlert(setAlrt, isRTL);
    } catch (error) {
      onError(error);
    }
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const closeModal = () => {
    onClose();
    setSaving(false);
    setShowOnCal(false);
    setDisabled(false);
    setCatvalue(null);
    setFields([]);
    setFormTempDoc([]);
    setPrintTempDoc([]);
    reset();
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const title = isRTL
    ? isNew
      ? 'اضافة نموذج'
      : 'تعديل نموذج'
    : isNew
    ? 'New Form'
    : 'Edit Form';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      maxWidth={'md'}
      saving={saving}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextFieldLocal
                autoFocus
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                required
                name="nameAr"
                label={words.nameAr}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                autoFocus
                name="pname"
                label={isRTL ? 'الاسم جمع' : 'Plural'}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                name="pnameAr"
                label={isRTL ? 'الاسم عربي جمع' : 'Plural'}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                required
                name="autoNoType"
                label={isRTL ? 'رمز النموذج' : ' Form code'}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                disabled={!isNew && !row?.dynamic}
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                required
                name="autoNoPrefix"
                label={isRTL ? 'لاحقة' : 'Prefix'}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                disabled={!isNew && !row?.dynamic}
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoFieldLocal
                name="category"
                title={words?.category}
                words={words}
                options={dcategories}
                value={catvalue}
                setSelectValue={setCatvalue}
                register={register}
                isRTL={isRTL}
                fullWidth
                mb={0}
              ></AutoFieldLocal>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={6}>
                <TextFieldLocal
                  name="order"
                  label={isRTL ? 'ترتيب' : 'Order'}
                  register={register}
                  errors={errors}
                  type="number"
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnCal}
                    onChange={() => setShowOnCal(!showOnCal)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={isRTL ? 'اظهار في التقويم' : 'Show on Calendar'}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disabled}
                    onChange={() => setDisabled(!disabled)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={isRTL ? 'متوقف' : 'Disabled'}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldsTable
                rows={fields}
                setFields={setFields}
                isRTL={isRTL}
                words={words}
                theme={theme}
              ></FieldsTable>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}></Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenForm(true)}
              >
                <Typography>
                  {isRTL ? 'تصميم النموذج' : 'Form Design'}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenPrint(true)}
              >
                <Typography>
                  {isRTL ? 'تصميم الطباعة' : 'Print Design'}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}></Grid>
        <PopupFormTemplate
          open={openForm}
          onClose={() => setOpenForm(false)}
          temp={formTempDoc}
          setTemp={setFormTempDoc}
          variables={formTempValues}
          setVariables={setFormTempValues}
          company={company}
          isRTL={isRTL}
          fields={fields}
          words={words}
        ></PopupFormTemplate>
        <PopupPrintTemplate
          open={openPrint}
          onClose={() => setOpenPrint(false)}
          temp={printTempDoc}
          setTemp={setPrintTempDoc}
          company={company}
          isRTL={isRTL}
          fields={fields}
          words={words}
        ></PopupPrintTemplate>
      </Grid>
    </PopupLayout>
  );
};

export default PopupFormDoc;
