import { Grid, Paper, Typography } from '@material-ui/core';
import { useTemplate } from '../../../../hooks';
import { moneyFormat } from '../../common/helpers';
import PercentChartTask from '../charts/PercentChartTask';

export default function AppointmentsSumBx({ row, theme, isRTL, height }) {
  const progress = row?.evQty
    ? Math.round((row?.evDone / row?.evQty) * 100) / 100
    : 0;
  const { taskoptions } = useTemplate();

  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <PercentChartTask
            pricolor={theme.palette.primary.main}
            seccolor={theme.palette.secondary.main}
            progress={progress}
            height={height}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginTop: 35 }}></Grid>
            {taskoptions?.items && (
              <>
                <Grid item xs={7}>
                  <Typography
                    style={{ fontWeight: 'bold' }}
                    variant="subtitle1"
                  >
                    {isRTL ? 'قيمة التعاقد' : 'Contarct Amount'}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    style={{ fontWeight: 'bold' }}
                    variant="subtitle1"
                  >
                    {moneyFormat(row?.coAmount)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={7}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {isRTL ? 'المواعيد' : 'Appointments'}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {moneyFormat(row?.evAmount)}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {isRTL ? 'العدد' : 'Quantity'}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {row?.evQty || 0}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {isRTL ? 'المنجزة' : 'Done'}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {row?.evDone || 0}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {isRTL ? 'قيمة المنجزة' : 'Done Amount'}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {row?.evDoneAmount || 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
