/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  GroupingState,
  SummaryState,
  IntegratedGrouping,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableGroupRow,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import {
  covertToTimeDateDigit,
  currencyFormatter,
  eventStatusFormatter,
  moneyFormat,
  opTypeFormatter,
  moneyFormatSimple,
  createdAtPrintFormatter,
} from '../../Shared/colorFormat';
import { Box, fade, Typography, withStyles } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import _ from 'lodash';
import { documentTypes, groupList } from '../../constants/reports';
import {
  useExpenseItems,
  useProducts,
  useServices,
  useTemplate,
} from '../../hooks';
import useTasks from '../../hooks/useTasks';
import getReportServices from '../../graphql/query/getReportServices';
import ServicesReportContext from '../../contexts/servicesReport';
// import useDepartments from '../../hooks/useDepartments';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { SearchTable } from '../../components';
import { itemTypes } from '../../constants/datatypes';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';
import { ReportGroupBySwitcher } from '../components/ReportGroupBySwitcher';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);
export const TableComponent2 = withStyles(
  {},
  { name: 'TableComponent' }
)(TableComponentBase);

export default function ServicesReport({
  isRTL,
  words,
  start,
  end,
  company,
  theme,
  drawerWidth,
}: any) {
  const [items, setItems] = React.useState([]);

  const [rows, setRows] = useState([]);
  const [total, setTotal]: any = useState(null);

  const [count, setCount] = useState<any>(0);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const [columns] = useState(
    [
      col.opTime,
      col.item,
      col.opType,
      col.opDocNo,
      col.customer,
      tempoptions?.contract && col.contract,
      tempoptions?.employee && col.employee,
      tempoptions?.resourse && col.resourse,
      tempoptions?.department && col.department,
      { name: 'qty', title: words.qty },
      { name: 'itemPrice', title: words.theprice },
      { name: 'total', title: words.total },
      tempoptions?.itemdescription && col.note,
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
    // { columnName: col.amount.name, togglingEnabled: false },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: col.opTime.name, width: 100 },
    { columnName: col.item.name, width: 200 },
    { columnName: col.opType.name, width: 150 },
    { columnName: col.opDocNo.name, width: 100 },
    { columnName: col.customer.name, width: 200 },
    { columnName: col.contract.name, width: 200 },
    { columnName: col.employee.name, width: 200 },
    { columnName: col.resourse.name, width: 200 },
    { columnName: col.department.name, width: 200 },
    { columnName: 'qty', width: 100 },
    { columnName: 'itemPrice', width: 100 },
    { columnName: 'total', width: 120 },
    { columnName: 'note', width: 200 },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getReportServices, {
    fetchPolicy: 'cache-and-network',
  });
  // const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { services } = useServices();
  const { products } = useProducts();
  const { expenseItems } = useExpenseItems();

  const {
    state: {
      optypevalue,
      servicevalue,
      departvalue,
      projvalue,
      resovalue,
      emplvalue,
      custvalue,
      taskvalue,
      itemtypes,
      group,
      groupby,
      sumcolumn,
      status,
      sort,
    },
    dispatch,
  } = useContext(ServicesReportContext);
  const { tasks } = useTasks();
  const { height } = useWindowDimensions();

  useEffect(() => {
    let itms = [];
    const itty = itemtypes.map((a: any) => a.value);
    if (itty && itty.length > 0) {
      if (itty.includes(1)) itms = [...itms, ...products];
      if (itty.includes(2)) itms = [...itms, ...services];
      if (itty.includes(10)) itms = [...itms, ...expenseItems];
    }
    setItems(itms);
  }, [itemtypes]);

  // const setDepartvalueDispatch = (value: any) => {
  //   dispatch({ type: 'setDepartvalue', payload: value });
  // };
  const setResovalueDispatch = (value: any) => {
    dispatch({ type: 'setResovalue', payload: value });
  };
  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  // const setCustvalueDispatch = (value: any) => {
  //   dispatch({ type: 'setCustvalue', payload: value });
  // };
  const setTaskvalueDispatch = (value: any) => {
    dispatch({ type: 'setTaskvalue', payload: value });
  };
  const setItemtypesDispatch = (value: any) => {
    dispatch({ type: 'setItemtypes', payload: value });
  };
  const setServicevalueDispatch = (value: any) => {
    dispatch({ type: 'setServicevalue', payload: value });
  };
  const setOptypevalueDispatch = (value: any) => {
    dispatch({ type: 'setOptypevalue', payload: value });
  };
  const setGroupbyDispatch = (value: any) => {
    dispatch({ type: 'setGroupby', payload: value });
  };
  const setGroupDispatch = (value: any) => {
    dispatch({ type: 'setGroup', payload: value });
  };
  const setSumcolumnDispatch = (value: any) => {
    dispatch({ type: 'setSumcolumn', payload: value });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getReportServices']?.data || [];

    setRows(slsData);
    setTotal(_.sumBy(slsData, 'total'));
    setCount(_.sumBy(slsData, 'qty'));
  }, [summaryData]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;
  const getTaskIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv.id) : undefined;
  const getValues = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv.value) : undefined;
  const fetchData = () => {
    const variables = {
      types: getValues(optypevalue),
      serviceIds: getIds(servicevalue),
      departmentIds: getIds(departvalue),
      projectIds: getIds(projvalue),
      resourseIds: getIds(resovalue),
      employeeIds: getIds(emplvalue),
      customerIds: getIds(custvalue),
      contractIds: getIds(taskvalue),
      itemtypes: getTaskIds(itemtypes),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
      status: status ? status.id : undefined,
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    group,
    groupby,
    sumcolumn,
    status,
    departvalue,
    projvalue,
    resovalue,
    emplvalue,
    custvalue,
    taskvalue,
    itemtypes,
    servicevalue,
    optypevalue,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `events-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const onSwitcherChange = (e: any) => {
    if (e.target.value === 'none') {
      setGroupDispatch(false);
      setGroupbyDispatch('none');
    } else {
      setGroupDispatch(true);
      setGroupbyDispatch(e.target.value);
      setSumcolumnDispatch(col[e.target.value].name);
    }
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'qty', type: 'sum' },
    { columnName: 'total', type: 'sum' },
  ];

  const grouping = [{ columnName: sumcolumn }];
  const groupSummaryItems = [
    {
      columnName: 'qty',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: 'total',
      type: 'sum',
      alignByColumn: true,
    },
  ];

  const projres = groupList(isRTL).filter((item: any) =>
    !tempoptions?.project && !tempoptions?.contract
      ? item.id !== 10 && item.id !== 11 && item.id !== 8
      : !tempoptions?.project && tempoptions?.resourse
      ? item.id !== 10
      : tempoptions?.project && !tempoptions?.resourse
      ? item.id !== 11
      : true
  );

  const groupOptions = projres.filter(
    (item: any) =>
      item.id !== 6 && item.id !== 7 && item.id !== 9 && item.id !== 5.5
  );

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 108,
          left: 420,
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FilterSelectMulti
          options={documentTypes}
          value={optypevalue}
          setValue={setOptypevalueDispatch}
          words={words}
          isRTL={isRTL}
          name="documenttype"
          label={isRTL ? 'نوع المستند' : 'Document Types'}
          width={200}
          fullwidth
        ></FilterSelectMulti>
        <FilterSelectMulti
          options={itemTypes}
          value={itemtypes}
          setValue={setItemtypesDispatch}
          words={words}
          isRTL={isRTL}
          name="itemtype"
          label={isRTL ? 'نوع البند' : 'Item Type'}
          width={200}
          fullwidth
        ></FilterSelectMulti>
        <FilterSelectMulti
          options={items}
          value={servicevalue}
          setValue={setServicevalueDispatch}
          words={words}
          isRTL={isRTL}
          name="product"
          label={isRTL ? 'البنود' : 'Items'}
          width={250}
          fullwidth
        ></FilterSelectMulti>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          top: 50,
          left: 100,
        }}
      >
        <FilterSelectMulti
          options={tasks}
          value={taskvalue}
          setValue={setTaskvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="task"
          width={250}
          fullwidth
        ></FilterSelectMulti>
        <FilterSelectMulti
          options={employees}
          value={emplvalue}
          setValue={setEmplvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="employee"
          width={250}
          fullwidth
        ></FilterSelectMulti>
        <FilterSelectMulti
          options={resourses}
          value={resovalue}
          setValue={setResovalueDispatch}
          words={words}
          isRTL={isRTL}
          name="resourse"
          width={250}
          fullwidth
        ></FilterSelectMulti>
        <Box
          style={{
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 3,
            width: 200,
          }}
        >
          <ReportGroupBySwitcher
            options={groupOptions}
            value={groupby}
            onChange={onSwitcherChange}
            isRTL={isRTL}
          ></ReportGroupBySwitcher>
        </Box>
      </Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 225,
          right: isRTL ? drawerWidth + 225 : undefined,
          bottom: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        display="flex"
      >
        <Typography
          style={{
            fontWeight: 'bold',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {isRTL ? 'العدد' : 'Quantity'}: {moneyFormatSimple(count)}
        </Typography>
        <Typography
          style={{
            fontWeight: 'bold',
          }}
        >
          {isRTL ? 'المجموع' : 'Total'}: {moneyFormat(total)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        {group && <GroupingState grouping={grouping} />}
        {group && (
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
        )}
        {group && <IntegratedGrouping />}
        {group && <IntegratedSummary />}
        <IntegratedSorting />
        <SearchState />
        <IntegratedFiltering />
        <VirtualTable
          height={height - 100}
          tableComponent={!group ? TableComponent : TableComponent2}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={30}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[
            col.opDocNo.name,
            col.resourse.name,
            col.department.name,
          ]}
        />
        <DataTypeProvider
          for={['opTime']}
          formatterComponent={createdAtPrintFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['status']}
          formatterComponent={eventStatusFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['opType']}
          formatterComponent={opTypeFormatter}
        ></DataTypeProvider>
        <Toolbar />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        {group && (
          <TableGroupRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
              sumOf: isRTL ? 'المجموع' : 'Total',
              countOf: isRTL ? 'العدد' : 'Count',
            }}
            showColumnsWhenGrouped
          />
        )}
        {group && (
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </>
  );
}
