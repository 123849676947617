import { gql } from '@apollo/client';

export default gql`
  mutation updateCategory(
    $_id: String
    $branch: String
    $catType: Int
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $avatar: String
    $color: String
    $ispos: Boolean
    $order: Int
    $sectionId: String
    $sectionName: String
    $sectionNameAr: String
  ) {
    updateCategory(
      _id: $_id
      branch: $branch
      catType: $catType
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      avatar: $avatar
      color: $color
      ispos: $ispos
      order: $order
      sectionId: $sectionId
      sectionName: $sectionName
      sectionNameAr: $sectionNameAr
    ) {
      ok
      message
      data
      error
    }
  }
`;
