/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from '@material-ui/core';

import PopupLayout from '../pages/main/PopupLayout';
import {
  Grid,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableGroupRow,
  TableHeaderRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { TableComponent } from '../Shared/ItemsTable';
import {
  DataTypeProvider,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  IntegratedSummary,
  SortingState,
  SummaryState,
} from '@devexpress/dx-react-grid';
import { getColumns } from '../common/columns';
import { userroles } from '../common';
import {
  createdAtFormatter,
  currencyFormatter,
  moneyFormat,
  removeItem,
} from '../Shared/colorFormat';
import _ from 'lodash';
import { successAlert } from '../Shared';

export const getRowId = (row: any) => row._id;

const PopupGroupAppointments = ({
  open,
  onClose,
  theme,
  isRTL,
  words,
  data,
  tempoptions,
  name,
  addInvoicesEvents,
  statusEvents,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [saving, setSaving] = useState(false);
  const [rows, setRows] = useState([]);
  const [sum, setSum] = useState(0);
  const [group, setGroup] = useState(false);
  const col = getColumns({ isRTL, words });

  const [columns] = useState([
    col.startDate,
    col.docNo,
    col.customer,
    col.customerPhone,
    tempoptions?.contract && col.contract,
    tempoptions?.employee && col.employee,
    tempoptions?.department && col.department,
    userroles?.appointments?.add && col.amount,
    { name: 'delete', title: ' ' },
  ]);
  const [tableColumnExtensions]: any = useState([
    { columnName: 'startDate', width: 120 },
    { columnName: 'docNo', width: 120 },
    { columnName: col.customer.name, width: 180 },
    { columnName: col.customerPhone.name, width: 120, align: 'center' },
    { columnName: col.contract.name, width: 150 },
    { columnName: col.employee.name, width: 150, align: 'center' },
    { columnName: col.department.name, width: 120 },
    { columnName: 'amount', width: 150 },
    { columnName: 'delete', width: 50 },
  ]);

  // const totalSummaryItems = [{ columnName: 'amount', type: 'sum' }];
  const grouping = [{ columnName: isRTL ? 'customerNameAr' : 'customerName' }];

  const groupSummaryItems = [
    {
      columnName: 'customerPhone',
      type: 'count',
      alignByColumn: true,
    },
    {
      columnName: 'amount',
      type: 'sum',
      alignByColumn: true,
    },
  ];
  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);
  useEffect(() => {
    const sumamount = _.sumBy(rows, 'amount');
    setSum(sumamount);
  }, [rows]);

  const removeRow = (id: any) => {
    const newrows = rows.filter((r: any) => r?._id !== id);
    setRows(newrows);
  };

  const onSubmit = async () => {
    try {
      setSaving(true);
      const eventIds = rows?.map((r: any) => r._id);
      if (addInvoicesEvents && name === 'addInvoicesEvents') {
        await addInvoicesEvents({ variables: { eventIds, group } });
      }
      if (statusEvents && name === 'statusEvents') {
        const status = 10;
        await statusEvents({ variables: { eventIds, status } });
      }
      setSaving(false);
      await successAlert(setAlrt, isRTL);
      onFormClose();
    } catch (error) {
      setSaving(false);
      console.log('error', error);
    }
  };

  const resetAll = () => {
    setRows([]);
    setSum(0);
    setGroup(false);
  };

  const onFormClose = () => {
    resetAll();
    onClose();
  };

  const len = rows?.length;
  const tname = isRTL ? 'ما تم انجازه' : 'For Invoices';
  const title = `${tname}  (${len})`;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onFormClose}
      title={title}
      onSubmit={onSubmit}
      saving={saving}
      savetitle={
        name === 'statusEvents'
          ? isRTL
            ? 'انجاز'
            : 'Procced'
          : isRTL
          ? 'انشاء'
          : 'Create'
      }
      canceltitle={isRTL ? 'اغلاق' : 'Close'}
      alrt={alrt}
      theme={theme}
      maxWidth="lg"
      mt={0}
      mb={0}
    >
      <Box
        style={{
          height: 'auto',
          overflow: 'auto',
          backgroundColor: '#fff',
        }}
      >
        {rows?.length > 0 && (
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: 7 }}
                checked={group}
                onChange={() => setGroup(!group)}
                color="primary"
              />
            }
            label={
              <Typography
                style={{ color: theme.palette.primary.main }}
                variant="subtitle2"
              >
                {isRTL ? 'مجمع' : 'Group'}
              </Typography>
            }
            style={{ fontSize: 14 }}
          />
        )}
        <Box
          style={{
            position: 'absolute',
            zIndex: 111,
            right: isRTL ? undefined : 100,
            left: isRTL ? 100 : undefined,
            top: 58,
          }}
        >
          <Typography style={{ fontWeight: 'bold', color: '#333' }}>
            {isRTL ? ' المجموع' : ' Total Amount '}: {moneyFormat(sum)}
          </Typography>
        </Box>

        <Paper elevation={3} style={{ height: 600, overflow: 'auto' }}>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState />

            {group && <GroupingState grouping={grouping} />}
            {group && (
              <SummaryState
                // totalItems={totalSummaryItems}
                groupItems={groupSummaryItems}
              />
            )}
            {group && <IntegratedGrouping />}
            {group && <IntegratedSummary />}
            <IntegratedSorting />
            <IntegratedFiltering />
            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 40 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnReordering
              defaultOrder={[
                col.startDate.name,
                col.docNo.name,
                col.customer.name,
                col.customerPhone.name,
                col.contract.name,
                col.employee.name,
                col.department.name,
                col.amount.name,
                'delete',
              ]}
            />

            <DataTypeProvider
              for={['amount']}
              formatterComponent={currencyFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['startDate']}
              formatterComponent={createdAtFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['delete']}
              formatterComponent={(props: any) =>
                removeItem({ ...props, removeRow })
              }
            ></DataTypeProvider>
            {group && (
              <TableGroupRow
                messages={{
                  sum: isRTL ? 'المجموع' : 'Total',
                  count: isRTL ? 'العدد' : 'Count',
                  sumOf: isRTL ? 'المجموع' : 'Total',
                  countOf: isRTL ? 'العدد' : 'Count',
                }}
                showColumnsWhenGrouped
              />
            )}
            {group && (
              <TableSummaryRow
                messages={{
                  sum: isRTL ? 'المجموع' : 'Total',
                  count: isRTL ? 'العدد' : 'Count',
                }}
              ></TableSummaryRow>
            )}
          </Grid>
        </Paper>
      </Box>
    </PopupLayout>
  );
};
export default PopupGroupAppointments;
