import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { rid } from '../Sections/generator/print/renders';

export default function ExtraList({ list, setList, isRTL }: any) {
  const [open, setOpen]: any = useState(false);
  const [item, setItem]: any = useState(null);
  const [title, setTitle]: any = useState('');
  const [subtitle, setSubTitle]: any = useState('');

  const addTitle = () => {
    const nlist = [...list];
    nlist.push({
      id: rid(),
      title,
      subitems: [],
    });
    setList(nlist);
    setTitle('');
  };
  const removeTitle = (aitem: any) => {
    const nlist = list.filter((it: any) => it?.id !== aitem?.id);
    setList(nlist);
  };
  const addSubTitle = () => {
    if (!item) return;
    const sitem = list.filter((it: any) => it?.id === item?.id)?.[0];
    if (sitem) {
      sitem.subitems.push({
        id: rid(),
        title: subtitle,
      });
    }
    const nlist = list?.map((li: any) => {
      if (li?.id === item?.id) {
        return sitem;
      } else {
        return li;
      }
    });
    setList(nlist);
    setSubTitle('');
  };
  const removeSubTitle = (aitem: any, sub: any) => {
    const sitem = list.filter((it: any) => it?.id === aitem?.id)?.[0];
    if (sitem) {
      const nsubs = sitem?.subitems.filter((it: any) => it?.id !== sub?.id);
      sitem.subitems = nsubs;
    }
    const nlist = list?.map((li: any) => {
      if (li?.id === aitem?.id) {
        return sitem;
      } else {
        return li;
      }
    });
    setList(nlist);
  };

  const onSubmit = () => {
    addSubTitle();
  };
  const handleClose = () => {
    setItem(null);
    setOpen(false);
  };

  return (
    <Paper
      style={{
        height: 400,
        overflow: 'auto',
        padding: 20,
      }}
      elevation={3}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}
          >
            {isRTL ? 'فقرات اضافية' : 'Extra Paragraphs'}
          </Typography>
          <Divider></Divider>
        </Grid>

        <Grid xs={10}>
          <TextField
            name="title"
            label={isRTL ? 'اسم الفقرة' : 'Title'}
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid xs={2}>
          <IconButton
            disableFocusRipple
            onClick={addTitle}
            style={{
              backgroundColor: '#f4f4f4',
              width: 70,
              height: 30,
              position: 'relative',
              top: 12,
              marginLeft: 15,
              marginRight: 15,
            }}
          >
            <Typography style={{ color: 'skyblue' }}>
              {isRTL ? 'اضافة' : 'Add'}
            </Typography>
          </IconButton>
        </Grid>
        <Grid xs={12}>
          <Box>
            {list?.map((item: any) => {
              return (
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                      {item?.title}
                    </Typography>
                    <Box style={{ display: 'flex' }}>
                      <IconButton
                        disableFocusRipple
                        onClick={() => {
                          setItem(item);
                          setOpen(true);
                        }}
                        style={{
                          backgroundColor: '#f4f4f4',
                          width: 30,
                          height: 30,
                          marginRight: 5,
                          marginLeft: 5,
                        }}
                      >
                        <AddIcon style={{ color: 'skyblue' }}></AddIcon>
                      </IconButton>
                      <IconButton
                        disableFocusRipple
                        onClick={() => {
                          removeTitle(item);
                        }}
                        style={{
                          backgroundColor: '#f4f4f4',
                          width: 30,
                          height: 30,
                          marginRight: 5,
                          marginLeft: 5,
                        }}
                      >
                        <DeleteIcon style={{ color: 'tomato' }} />
                      </IconButton>
                    </Box>
                  </Box>
                  {item?.subitems?.length > 0 && (
                    <ul>
                      {item?.subitems?.map((it: any) => {
                        return (
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <li>
                              <Typography>{it?.title}</Typography>
                            </li>
                            <IconButton
                              disableFocusRipple
                              onClick={() => {
                                removeSubTitle(item, it);
                              }}
                              style={{
                                backgroundColor: '#f4f4f4',
                                width: 30,
                                height: 30,
                                marginRight: 5,
                                marginLeft: 5,
                              }}
                            >
                              <DeleteIcon style={{ color: 'tomato' }} />
                            </IconButton>
                          </Box>
                        );
                      })}
                    </ul>
                  )}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent
          style={{ width: 600, height: 250, direction: isRTL ? 'rtl' : 'ltr' }}
        >
          <Box>
            <TextField
              name="title"
              label={isRTL ? 'الاسم' : 'Name'}
              value={subtitle}
              onChange={(e: any) => setSubTitle(e.target.value)}
              fullWidth
              style={{ marginBottom: 0 }}
              margin="dense"
            />
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            direction: isRTL ? 'rtl' : 'ltr',
            backgroundColor: '#f8f8f8',
            height: 60,
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button
            style={{ width: 100, height: 36 }}
            variant="contained"
            onClick={onSubmit}
            color="primary"
          >
            {isRTL ? 'اضافة' : 'Add'}
          </Button>
          <Button
            style={{ width: 100, height: 36 }}
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            {isRTL ? 'اغلاق' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
