/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, fade, Paper, Typography, withStyles } from '@material-ui/core';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import { CommandSmall } from './CommandSmall';
import { Getter } from '@devexpress/dx-react-core';
import PopupEditing from './PopupEditing';
import PopupItem from '../pubups/PopupItem';
import { currencyFormatter, quantityFormatter } from './colorFormat';
import useWindowDimensions from '../hooks/useWindowDimensions';

export const getRowId = (row: any) => row.index;

const IndexProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <Typography variant="subtitle2">{Number(value) + 1}</Typography>
    )}
    {...props}
  />
);

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function ItemsTable({
  rows,
  removeItem,
  editItem,
  isRTL,
  words,
  user,
  height = 200,
  products,
  itemrelations,
}: any) {
  const { isMobile } = useWindowDimensions();
  const [columns] = useState(
    [
      { name: 'index', title: words.no },
      { name: 'name', title: words.item },
      { name: 'qty', title: words.qty },
      { name: 'price', title: words.theprice },
      { name: 'total', title: words.total },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'index', width: 50 },
    { columnName: 'name', width: 150 },
    { columnName: 'qty', width: 70 },
    { columnName: 'price', width: 120 },
    { columnName: 'total', width: 150 },
  ]);

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const index = deleted[0];
      removeItem(index);
    }
  };

  const renderEmpty = () => {
    return <Box display="flex"></Box>;
  };

  const addAction = () => {
    //
  };

  return (
    <Paper
      style={{
        overflow: 'auto',
        margin: 10,
        height,
      }}
    >
      {rows && (
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />

          <Table
            noDataCellComponent={renderEmpty}
            columnExtensions={tableColumnExtensions}
            tableComponent={TableComponent}
          />
          <TableHeaderRow
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <IndexProvider for={['index']} />
          <DataTypeProvider
            for={['itemtotal', 'itemprice']}
            formatterComponent={currencyFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['itemqty']}
            formatterComponent={quantityFormatter}
          ></DataTypeProvider>
          <TableEditColumn
            showDeleteCommand
            showEditCommand
            commandComponent={CommandSmall}
          ></TableEditColumn>
          <PopupEditing addAction={addAction} editAction={editItem}>
            <PopupItem isRTL={isRTL} words={words}></PopupItem>
          </PopupEditing>
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                {
                  key: 'editCommand',
                  type: TableEditColumn.COLUMN_TYPE,
                  width: isMobile ? 100 : 120,
                },
                ...tableColumns.filter(
                  (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
              ];
              return result;
            }}
          />
        </Grid>
      )}
    </Paper>
  );
}
