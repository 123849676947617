/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, LinkPhone, updateDocNumbers } from '../../common';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  amountFormatter,
  moneyFormat,
  paidFormatter,
  timeFormatter,
  userFormatter,
} from '../../Shared/colorFormat';
import { SearchTable } from '../../components';
import { getColumns } from '../../common/columns';
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { useTemplate, useUsers } from '../../hooks';
import _ from 'lodash';
import {
  billJadwalCustomers,
  getJadwalInvoices,
  updateAllBranchsStatus,
} from '../../graphql/query';
import Whatsapp from '../../common/Whatsapp';

import { TableComponent } from '../components/TableComponent';
import ButtonPayInvoice from '../../common/ButtonPayInvoice';
import { sleep } from '../common/funcs';
import payInvoices from '../../graphql/mutation/payInvoices';
import CheckFilter from '../../Shared/CheckFilter';

const JadwalInvoices = forwardRef((props: any, ref: any) => {
  const { isRTL, words, start, end, drawerWidth } = props;
  const [selected, setSelected] = useState(null);
  const [due, setDue] = useState(false);

  const col = getColumns({ isRTL, words });
  const { tempoptions } = useTemplate();

  const [columns] = useState([
    { name: 'time', title: words?.time },
    { name: 'autoNo', title: words?.no },
    col.customer,
    { name: 'customerPhone', title: words?.phoneNumber },
    { name: 'amount', title: words?.amount },
    { name: 'isPaid', title: isRTL ? 'مدفوع' : 'Paid' },
    { name: 'title', title: words?.notes },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 100 },
    { columnName: 'autoNo', width: 120 },
    { columnName: col.customer.name, width: 200 },
    { columnName: 'customerPhone', width: 200, align: 'center' },
    { columnName: 'amount', width: 120 },
    { columnName: 'isPaid', width: 150, align: 'center' },
    { columnName: 'title', width: 400 },
  ]);

  const [rows, setRows] = useState([]);
  const [sums, setSums] = useState(null);

  const { users } = useUsers();

  const isMobile = useMediaQuery('(max-width:600px)');

  const [loadInvoices, opData]: any = useLazyQuery(getJadwalInvoices, {
    fetchPolicy: 'cache-and-network',
  });
  const [billCustomers]: any = useLazyQuery(billJadwalCustomers);
  const [updateBranches]: any = useLazyQuery(updateAllBranchsStatus);

  const [payBills] = useMutation(payInvoices, {
    refetchQueries: [
      {
        query: getJadwalInvoices,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
          due,
        },
      },
    ],
  });

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
      due,
    };
    if (start && end) {
      loadInvoices({
        variables,
      });
    }
  }, [start, end, due]);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (opData?.refetch) {
        opData.refetch();
      }
    },
  }));
  useEffect(() => {
    if (opData?.data?.getJadwalInvoices?.data) {
      const { data } = opData.data.getJadwalInvoices;
      const rdata = updateDocNumbers(data);
      const sumamount = _.sumBy(rdata, 'amount');
      setSums({ sumamount });
      setRows(rdata);
    }
  }, [opData]);

  const payInvoice = async (id: any) => {
    setSelected(id);
    try {
      await payBills({ variables: { invoiceIds: [id], paymentType: 'cash' } });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
    }
    await sleep(3);
    setSelected(null);
  };
  const billAll = async () => {
    try {
      await billCustomers({});
      const variables = {
        start: start ? start.setHours(0, 0, 0, 0) : undefined,
        end: end ? end.setHours(23, 59, 59, 999) : undefined,
        due,
      };
      if (start && end) {
        loadInvoices({
          variables,
        });
      }
    } catch (error) {
      console.log('error', error);
      setSelected(null);
    }
  };
  const updateAll = async () => {
    try {
      await updateBranches({});
    } catch (error) {
      console.log('error', error);
      setSelected(null);
    }
  };

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 60,
          left: isRTL ? 330 : undefined,
          right: isRTL ? undefined : 350,
          display: 'flex',
          flexGrow: 1,
          zIndex: 111,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={billAll}
          style={{
            marginLeft: 20,
            marginRight: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <Typography>
            {isRTL ? 'انشاء فواتير الشهر' : 'Create Month Invoices'}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={updateAll}
          style={{
            marginLeft: 20,
            marginRight: 20,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <Typography>
            {isRTL ? 'تحديث بيانات الشركات' : 'Update Status'}
          </Typography>
        </Button>
        <CheckFilter
          value={due}
          setVlaue={setDue}
          title={isRTL ? `المتأخرة` : `Due`}
        ></CheckFilter>
      </Box>
      {!isMobile && (
        <Box
          style={{
            position: 'absolute',
            zIndex: 111,
            left: isRTL ? undefined : drawerWidth + 50,
            right: isRTL ? drawerWidth + 50 : undefined,
            bottom: 50,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' المجموع' : ' Total Amount '}:{' '}
            {moneyFormat(sums?.sumamount)}
          </Typography>
        </Box>
      )}

      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'time',
            'autoNo',
            col.customer.name,
            'customerPhone',
            'amount',
            'isPaid',
            'title',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={[]}
          hiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['customerPhone']}
          formatterComponent={({ value }) => (
            <Box display={'flex'}>
              {value && <Whatsapp phone={value}></Whatsapp>}
              {value && <LinkPhone value={value}></LinkPhone>}
            </Box>
          )}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['time']}
          formatterComponent={timeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['userId']}
          formatterComponent={(props: any) =>
            userFormatter({ ...props, users })
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={amountFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['isPaid']}
          formatterComponent={paidFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['isPaid']}
          formatterComponent={(props: any) => (
            <ButtonPayInvoice
              {...props}
              payInvoice={payInvoice}
              isRTL={isRTL}
              selected={selected}
            ></ButtonPayInvoice>
          )}
        ></DataTypeProvider>

        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
      </Grid>
    </>
  );
});

export default JadwalInvoices;
