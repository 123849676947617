/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  yup,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { useTemplate } from '../hooks';
import usePCategories from '../hooks/usePCategories';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import FilterSelectMulti from '../Shared/FilterSelectMulti';
import MyIcon from '../Shared/MyIcon';
import useStepItems from '../hooks/useStepItems';

const PopupProcItem = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  editAction,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [usefirststep, setUsefirststep] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [catvalue, setCatvalue] = useState<any>(null);
  const [sitemsvalue, setSitemsvalue] = useState<any>([]);

  const { register, handleSubmit, errors, reset } = useForm(yup.brandResolver);
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const { tempoptions } = useTemplate();
  const { pcategories } = usePCategories();
  const { stepitems } = useStepItems();
  useEffect(() => {
    if (row?._id) {
      if (row?.pcategoryId) {
        const depart = pcategories.filter(
          (dep: any) => dep._id === row.pcategoryId
        )[0];
        setCatvalue(depart);
      }
      if (row?.stepitems) {
        const stepItemsIds = JSON.parse(row?.steps);
        const steps = stepItemsIds.map((si: any) => {
          const item = stepitems.filter((s: any) => s._id === si.id)?.[0];
          return item;
        });
        setSitemsvalue(steps);
      }
      if (row?.usefirststep === true || row?.usefirststep === false) {
        setUsefirststep(row?.usefirststep);
      }
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data.name.trim();
    const nameAr = !tempoptions?.nameonelang ? data.nameAr.trim() : name;
    const additionId = data.additionId;

    const sitems =
      sitemsvalue && sitemsvalue.length > 0
        ? sitemsvalue.map((si: any, i: any) => {
            return {
              order: i,
              id: si._id,
            };
          })
        : [];
    const variables: any = {
      _id: row._id ? row._id : undefined, // is it new or edit
      additionId: data?.additionId?.length > 0 ? additionId : undefined,
      name,
      nameAr,
      pcategoryId: catvalue?._id ? catvalue?._id : undefined,
      pcategoryName: catvalue?.name ? catvalue?.name : undefined,
      pcategoryNameAr: catvalue?.nameAr ? catvalue?.nameAr : undefined,
      steps: JSON.stringify(sitems),
      usefirststep,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createProcItem' : 'updateProcItem';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem);
      closeModal();
      await successAlert(setAlrt, isRTL);
    } catch (error) {
      onError(error);
    }
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const closeModal = () => {
    onClose();
    setSaving(false);
    setUsefirststep(false);
    setCatvalue(null);
    setSitemsvalue([]);
    reset();
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const moveUp = (item: any, index: any) => {
    if (index === 0) return;
    const newitems = sitemsvalue?.filter((si: any) => si._id !== item._id);
    newitems.splice(index - 1, 0, item);
    setSitemsvalue(newitems);
  };

  const title = isRTL
    ? isNew
      ? 'اضافة بند خطوة'
      : 'تعديل بند خطوة'
    : isNew
    ? 'New Procedure Item'
    : 'Edit Procedure Item';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            {tempoptions?.additionId && (
              <Grid item xs={12}>
                <TextFieldLocal
                  autoFocus={tempoptions?.additionId}
                  name="additionId"
                  label={words.no}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldLocal
                autoFocus={!tempoptions?.additionId}
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            {!tempoptions?.nameonelang && (
              <Grid item xs={12}>
                <TextFieldLocal
                  required
                  name="nameAr"
                  label={words.nameAr}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <AutoFieldLocal
                name="pcategory"
                title={words?.category}
                words={words}
                options={pcategories}
                value={catvalue}
                setSelectValue={setCatvalue}
                register={register}
                isRTL={isRTL}
                fullWidth
                mb={0}
              ></AutoFieldLocal>
            </Grid>
            <Grid item xs={12}>
              <FilterSelectMulti
                options={stepitems}
                value={sitemsvalue}
                setValue={setSitemsvalue}
                words={words}
                isRTL={isRTL}
                name="stepitems"
                label={isRTL ? 'الخطوات' : 'Steps'}
                fullWidth
                limitTags={3}
                disabledoptions
              ></FilterSelectMulti>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 15 }}>
              <Typography style={{ fontWeight: 'bold' }}>
                {isRTL ? 'ترتيب الخطوات' : 'List Order'}
              </Typography>
              <List dense={true}>
                {sitemsvalue?.map((sitem: any, i: any) => {
                  return (
                    <ListItem
                      key={sitem._id}
                      style={{
                        margin: 5,
                        backgroundColor:
                          usefirststep && i === 0 ? '#c6e2ff' : '#eee',
                      }}
                    >
                      <ListItemIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => moveUp(sitem, i)}
                      >
                        {i !== 0 && <MyIcon icon="up" />}
                      </ListItemIcon>
                      <ListItemText
                        primary={isRTL ? sitem?.nameAr : sitem?.name}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                style={{ marginTop: 50 }}
                control={
                  <Checkbox
                    checked={usefirststep}
                    onChange={() => setUsefirststep(!usefirststep)}
                    name="bold"
                    color="primary"
                  />
                }
                label={isRTL ? 'اتمتة الخطوة الاولى' : 'Auto First Step'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupProcItem;
