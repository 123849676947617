import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';

export default function StepsGraph({
  steps,
  isRTL,
  selected,
  selectedStepId,
}: any) {
  if (steps?.length === 0) return <></>;
  const flatsteps = _.flatten(steps);
  const list = _(flatsteps)
    .groupBy('stepId')
    .map((array: any, key: any) => ({
      stepId: key,
      count: array?.length,
      name: isRTL ? array?.[0]?.stepNameAr : array?.[0]?.stepName,
      color: array?.[0]?.stepColor,
    }))
    .value();
  return (
    <Box display={'flex'}>
      {list?.map((item: any) => {
        const isActive = selected === Number(item?.stepId);
        return (
          <Box display={'flex'} style={{ marginLeft: 20, marginRight: 20 }}>
            <Box
              style={{
                padding: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: isActive
                  ? `${item?.color}66`
                  : `${item?.color}33`,
                borderRadius: 5,
                minWidth: 120,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (isActive) {
                  selectedStepId(null);
                } else {
                  selectedStepId(Number(item?.stepId));
                }
              }}
            >
              <Typography style={{ fontWeight: isActive ? 'bold' : undefined }}>
                {item?.name}
              </Typography>
              <div style={{ width: 10 }}></div>
              <Typography style={{ fontWeight: isActive ? 'bold' : undefined }}>
                {item?.count}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
