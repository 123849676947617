import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createFormDoc,
  updateFormDoc,
  deleteFormDoc,
} from '../graphql/mutation';

import { getFormDocs } from '../graphql/query';

const useFormDocs = () => {
  const [getForms, formData]: any = useLazyQuery(getFormDocs);

  const [addFormDoc] = useMutation(createFormDoc, {
    refetchQueries: [{ query: getFormDocs }],
  });
  const [editFormDoc] = useMutation(updateFormDoc, {
    refetchQueries: [{ query: getFormDocs }],
  });
  const [removeFormDoc] = useMutation(deleteFormDoc, {
    refetchQueries: [{ query: getFormDocs }],
  });

  useEffect(() => {
    getForms();
  }, [getForms]);

  const formdocs = formData?.data?.['getFormDocs']?.data || [];
  const refreshfromdocs = () => formData?.refetch();
  return {
    formdocs: formdocs.filter((fd: any) => !fd.disabled),
    allformdocs: formdocs,
    addFormDoc,
    editFormDoc,
    removeFormDoc,
    refreshfromdocs,
    loading: formData?.loading,
  };
};

export default useFormDocs;
