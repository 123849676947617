/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { invoiceClasses } from '../themes';
import { useCustomers, useProducts, useTemplate } from '../hooks';
import { dublicateAlert, errorAlert, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';

import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import ItemsTable from '../Shared/ItemsTable';
import { PriceTotal } from '../Shared/TotalPrice';
import { operationTypes } from '../constants';
import { useMutation } from '@apollo/client';
import { createInvoice, getInvoices, getProducts } from '../graphql';
import { accountCode, parents } from '../constants/kaid';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal } from '../components';
import PopupCustomer from './PopupCustomer';
import { weekdaysNNo } from '../constants/datatypes';
import useTasks from '../hooks/useTasks';
import { useReactToPrint } from 'react-to-print';
import useCompany from '../hooks/useCompany';
import useEmployees from '../hooks/useEmployees';
import useDepartments from '../hooks/useDepartments';
import useResourses from '../hooks/useResourses';
import ServiceItemForm from '../Shared/ServiceItemForm';
import { successAlert } from '../Shared/helpers';
import useAccounts from '../hooks/useAccounts';
import PaymentSelect from '../pages/options/PaymentSelect';
import usePrintTemplates from '../hooks/usePrintTemplates';
import { getDefaultTemplate, tafkeet } from '../common/helpers';
import useWindowDimensions from '../hooks/useWindowDimensions';
import PrintDownload from '../Shared/PrintDownload';
import { userroles } from '../common';
import { moneyFormat } from '../Shared/colorFormat';
import { indexTheList } from './PopupInvoice';

const PopupAppointInvoice = ({
  open,
  onClose,
  appoint,
  services,
  editEvent,
  isNew = true,
  theme,
  items,
  onCloseAppoint = () => null,
}: any) => {
  const classes = invoiceClasses();
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [saving, setSaving] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [invNo, setInvNo] = useState<any>('');

  const [itemsList, setItemsList] = useState<any>([]);
  const [accounts, setAccounts] = useState<any>([]);

  const [discount, setDiscount] = useState(0);
  const [totals, setTotals] = useState<any>({});

  const [custvalue, setCustvalue] = useState<any>(null);

  const [departvalue, setDepartvalue] = useState<any>(null);
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();

  const [resovalue, setResovalue] = useState<any>(null);
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [taskvalue, setTaskvalue] = useState<any>(null);

  const { tempwords, tempoptions } = useTemplate();
  const [isCash, setIsCash] = useState(tempoptions?.cashinvoice);

  const [paid, setPaid] = useState<any>(0);
  const [debitAcc, setDebitAcc]: any = React.useState(null);
  const [paid2, setPaid2] = useState<any>(0);
  const [debitAcc2, setDebitAcc2]: any = React.useState(null);
  const [openCust, setOpenCust] = useState(false);
  const [newtext, setNewtext] = useState('');
  const [percent, setPercent] = useState<any>(0);
  const [ispercent, setIspercent] = useState<any>(0);

  const { customers, addCustomer, editCustomer } = useCustomers();
  const { accounts: mainAccounts } = useAccounts();
  const { isMobile } = useWindowDimensions();

  const { tasks } = useTasks();
  const { employees } = useEmployees();
  const { departments } = useDepartments();
  const { resourses } = useResourses();
  const { company } = useCompany();
  const { products } = useProducts();
  const { printtemplates } = usePrintTemplates();

  const { reset } = useForm({});
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const refresQuery = {
    refetchQueries: [
      {
        query: getInvoices,
        variables: {
          start: new Date().setHours(0, 0, 0, 0),
          end: new Date().setHours(23, 59, 59, 999),
        },
      },
      { query: getProducts },
    ],
  };

  const [addInvoice] = useMutation(createInvoice, refresQuery);

  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };
  const onNewFieldChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };

  const cashaccounts = mainAccounts.filter(
    (acc: any) => acc.parentcode === parents.CASH && acc.code < 10499
  );
  useEffect(() => {
    if (isCash) {
      setDebitAcc(cashaccounts?.[0]);
      setDebitAcc2(cashaccounts?.[1]);
    }
  }, [open, isCash]);

  const resetAllForms = () => {
    reset();
    setItemsList([]);
    setDiscount(0);
    setTotals({});
    setCustvalue(null);
    setInvNo('');
    setAccounts([]);
    setIsCash(tempoptions?.cashinvoice);
    setSaving(false);
    setSelectedDate(new Date());
    setDepartvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setPaid(0);
    setPaid2(0);
    setDebitAcc(null);
    setDebitAcc2(null);
  };

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };
  const handleDateChange = (date: any) => {
    if (date) {
      const d = new Date(date);
      // d?.setHours(8, 0, 0);
      setSelectedDate(d);
    }
  };

  useEffect(() => {
    getOverallTotal();
  }, [itemsList, discount, isCash, paid, paid2, debitAcc, debitAcc2]);

  // useEffect(() => {
  //   if (isCash && (paid === 0 || paid2 === 0)) {
  //     const amount = simpleAmount();
  //     setPaid(amount || 0);
  //   }
  //   if (!isCash) {
  //     setPaid(0);
  //     setPaid2(0);
  //   }
  // }, [itemsList, discount, isCash]);

  // const simpleAmount = () => {
  //   const totalsin = itemsList.map((litem: any) => litem.itemtotal);
  //   const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
  //   const amount = sum - discount;
  //   return amount;
  // };

  useEffect(() => {
    if (appoint && appoint.startDate) {
      const _id = appoint.customerId;
      const cust = customers.filter((it: any) => it._id === _id)[0];
      setCustvalue(cust);
    }
    const depId = appoint.departmentId;
    const empId = appoint.employeeId;
    const resId = appoint.resourseId;
    const da = appoint.debitAcc;

    if (depId) {
      const depart = departments.filter((dep: any) => dep._id === depId)[0];
      setDepartvalue(depart);
    }
    if (empId) {
      const empl = employees.filter((emp: any) => emp._id === empId)[0];
      setEmplvalue(empl);
    }
    if (resId) {
      const empl = resourses.filter((emp: any) => emp._id === resId)[0];
      setResovalue(empl);
    }
    if (appoint.contractId) {
      const empl = tasks.filter(
        (emp: any) => emp._id === appoint.contractId
      )[0];
      setTaskvalue(empl);
    }
    if (items) {
      setItemsList(items);
    }
    if (da) {
      const debit = mainAccounts.filter((acc: any) => acc.code === da)[0];
      setDebitAcc(debit);
    }
    setDiscount(appoint.discount ? appoint.discount : 0);
    setInvNo(appoint.docNo);
  }, [appoint, services, customers, items]);

  useEffect(() => {
    if (isNew) {
      const start = new Date();
      // start.setHours(8, 0, 0);
      setSelectedDate(start);
    }
  }, [open]);

  useEffect(() => {
    if (isNew && ispercent && percent > 0) {
      const newdisc = (totals.total * percent) / 100;
      setDiscount(newdisc);
    }
  }, [ispercent, percent, totals.total]);

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);

    const costtotals = itemsList.map(
      (litem: any) => litem.cost * litem.itemqty
    );
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum - discount;
    const profit = sum - discount - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
    const accs = [
      {
        debitAcc: accountCode.accounts_receivable,
        creditAcc: accountCode.sales_income,
        amount: sum,
        type: operationTypes.salesInvoice,
      },
      {
        debitAcc: accountCode.sales_income,
        creditAcc: accountCode.accounts_receivable,
        amount: discount,
        type: operationTypes.customerDiscount,
      },
      {
        debitAcc: 1000,
        creditAcc: accountCode.accounts_receivable,
        amount: isCash ? paid : 0,
        type: operationTypes.customerReceipt,
      },
      {
        debitAcc: 1010,
        creditAcc: accountCode.accounts_receivable,
        amount: isCash ? paid2 : 0,
        type: operationTypes.customerReceipt,
      },
      {
        debitAcc: accountCode.cost_of_sales,
        creditAcc: accountCode.inventory,
        amount: costsum,
        type: operationTypes.salesDelivery,
      },
    ];
    setAccounts(accs);
  };
  const onSubmit = async (print: any) => {
    if (!custvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة عميل للفاتورة' : 'Please add Customer'
      );
      return;
    }
    if (!itemsList || itemsList.length === 0) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يجب اضافة عنصر (خدمة او منتج) واحد للفاتورة على الأقل`
          : `You should add min one service to invoice`
      );
      return;
    }
    setSaving(true);

    const { amount, costAmount, profit, total } = totals;

    const newList =
      !tempoptions?.itemrelations && !tempoptions?.invoiceitemrelations
        ? itemsList?.map((il: any) => {
            return {
              ...il,
              contractId: taskvalue?._id,
              contractName: taskvalue?.name,
              contractNameAr: taskvalue?.nameAr,
              departmentId: departvalue?._id,
              departmentName: departvalue?.name,
              departmentNameAr: departvalue?.nameAr,
              departmentColor: departvalue?.color,
              employeeId: emplvalue?._id,
              employeeName: emplvalue?.name,
              employeeNameAr: emplvalue?.nameAr,
              employeeColor: emplvalue?.color,
              employeePhone: emplvalue?.phone,
              resourseId: resovalue?._id,
              resourseName: resovalue?.name,
              resourseNameAr: resovalue?.nameAr,
              resourseColor: resovalue?.color,
            };
          })
        : itemsList;
    const items = JSON.stringify(newList);

    const variables: any = {
      docNo: invNo ? invNo.toString() : undefined,
      time: selectedDate,
      customer: {
        customerId: custvalue._id,
        customerName: custvalue.name,
        customerNameAr: custvalue.nameAr,
        customerPhone: custvalue.phone,
      },
      department: departvalue
        ? {
            departmentId: departvalue._id,
            departmentName: departvalue.name,
            departmentNameAr: departvalue.nameAr,
            departmentColor: departvalue.color,
          }
        : {
            departmentId: undefined,
            departmentName: undefined,
            departmentNameAr: undefined,
            departmentColor: undefined,
          },
      employee: emplvalue
        ? {
            employeeId: emplvalue._id,
            employeeName: emplvalue.name,
            employeeNameAr: emplvalue.nameAr,
            employeeColor: emplvalue.color,
            employeePhone: emplvalue.phone,
          }
        : {
            employeeId: undefined,
            employeeName: undefined,
            employeeNameAr: undefined,
            employeeColor: undefined,
            employeePhone: undefined,
          },
      resourse: resovalue
        ? {
            resourseId: resovalue._id,
            resourseName: resovalue.name,
            resourseNameAr: resovalue.nameAr,
            resourseColor: resovalue.color,
          }
        : {
            resourseId: undefined,
            resourseName: undefined,
            resourseNameAr: undefined,
            resourseColor: undefined,
          },
      contract: taskvalue
        ? {
            contractId: taskvalue._id,
            contractName: taskvalue.name,
            contractNameAr: taskvalue.nameAr,
          }
        : {
            contractId: undefined,
            contractName: undefined,
            contractNameAr: undefined,
          },
      project: taskvalue
        ? {
            projectId: taskvalue.projectId,
            projectName: taskvalue.projectName,
            projectNameAr: taskvalue.projectNameAr,
          }
        : {
            projectId: undefined,
            projectName: undefined,
            projectNameAr: undefined,
          },
      items,
      costAmount,
      total,
      discount,
      amount,
      profit,
      isPaid: isCash,
      isCash,
      debitAcc: debitAcc ? debitAcc.code : null,
      debitAcc2: debitAcc2 ? debitAcc2.code : null,
      amountPaid: isCash ? paid : 0,
      amountPaid2: isCash ? paid2 : 0,
      accounts,
      userId: user._id,
      eventId: appoint.id,
      eventIds: [appoint.id],
      eventNo: appoint.docNo,
    };
    apply(addInvoice, variables, print);
  };

  const apply = async (mutate: any, variables: any, print: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.createInvoice?.ok) {
        setInvNo(res?.data?.createInvoice?.message);
      }
      if (print) {
        handleReactPrint();
      }
      editEvent({
        variables: {
          id: appoint.id,
          status: 10,
        },
      });
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      onCloseForm();
      onCloseAppoint();
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
    setSaving(false);
  };

  const onHandleSubmit = (print: any) => {
    onSubmit(print);
  };

  const template = getDefaultTemplate(printtemplates, 'invoice');

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice #${invNo}`,
    removeAfterPrint: true,
  });
  const printData = {
    invoiceNo: invNo,
    time: selectedDate,
    docNo: invNo,
    customerName: custvalue?.name,
    customerPhone: custvalue?.phone,
    customerNameAr: custvalue?.nameAr,
    amountPaid: paid,
    amountPaid2: paid2,
    total: totals.total,
    percent,
    discount,
    amount: totals.amount,
    items: itemsList,
    user: user?.name,
    tafkeet: tafkeet(totals.amount, isRTL),
  };

  const date = appoint?.startDate ? new Date(appoint?.startDate) : new Date();
  const day = weekdaysNNo?.[date.getDay()];

  const title = isRTL ? 'فاتورة بيع' : 'Sales Invoice';
  const bl = totals?.amount - (paid + paid2);
  const pa = `${paid + paid2} /  ${totals?.amount}`;
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      print={handleReactPrint}
      saving={saving}
      maxWidth="lg"
      mt={0}
      mb={50}
      saveprint={template?.size === 'POS'}
    >
      <Grid container spacing={0}>
        {isMobile && (
          <Grid item md={12}>
            <PrintDownload
              company={company}
              printData={printData}
              template={template}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              generatePDF={() => null}
              isRTL={isRTL}
              no={''}
              element="invoice"
              isNew={true}
            ></PrintDownload>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <CalenderLocal
            isRTL={isRTL}
            label={words?.time}
            value={selectedDate}
            onChange={handleDateChange}
          ></CalenderLocal>
          {!isNew && (
            <Box
              display="flex"
              style={{
                flex: 1,
                flexDirection: isRTL ? 'row-reverse' : 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {isNew && (
                <Typography style={{ color: '#777' }}>{words.no}</Typography>
              )}
              <TextField
                name="invNo"
                disabled
                value={invNo}
                variant="outlined"
                style={{ width: isNew ? 70 : 100, marginLeft: 20 }}
                margin="dense"
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: 14,
                    height: 13,
                  },
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            {!userroles?.isEmployee && (
              <Grid item xs={12}>
                <AutoFieldLocal
                  name="customer"
                  title={words.customer}
                  words={words}
                  options={customers}
                  value={custvalue}
                  setSelectValue={setCustvalue}
                  isRTL={isRTL}
                  fullWidth
                  openAdd={openCustomer}
                  showphone
                ></AutoFieldLocal>
              </Grid>
            )}
            {tempoptions?.docrelations &&
              !tempoptions?.invoiceitemrelations && (
                <>
                  {tempoptions?.contract && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="task"
                        title={tempwords?.task}
                        words={words}
                        options={tasks}
                        value={taskvalue}
                        setSelectValue={setTaskvalue}
                        isRTL={isRTL}
                        fullWidth
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {!userroles?.isEmployee && tempoptions?.employee && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="employee"
                        title={tempwords?.employee}
                        words={words}
                        options={employees.filter((emp: any) => !emp.disabled)}
                        value={emplvalue}
                        setSelectValue={setEmplvalue}
                        setSelectError={setEmplError}
                        selectError={emplError}
                        refernce={emplRef}
                        noPlus
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.department && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="department"
                        title={tempwords?.department}
                        words={words}
                        options={departments.filter(
                          (d: any) => d.depType === 1
                        )}
                        value={departvalue}
                        setSelectValue={setDepartvalue}
                        setSelectError={setDepartError}
                        selectError={departError}
                        refernce={departRef}
                        noPlus
                        isRTL={isRTL}
                        fullWidth
                      ></AutoFieldLocal>
                    </Grid>
                  )}

                  {tempoptions?.resourse && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="resourse"
                        title={tempwords?.resourse}
                        words={words}
                        options={resourses}
                        value={resovalue}
                        setSelectValue={setResovalue}
                        setSelectError={setResoError}
                        selectError={resoError}
                        refernce={resoRef}
                        noPlus
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                </>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: '#f4f4f4',
              padding: 10,
              marginTop: 15,
              marginBottom: 15,
              borderRadius: 10,
            }}
          >
            <Box display="flex" style={{ paddingLeft: 10, paddingRight: 10 }}>
              <ServiceItemForm
                services={services}
                products={products}
                addItem={addItemToList}
                words={words}
                classes={classes}
                user={user}
                isRTL={isRTL}
                setAlrt={setAlrt}
                taskv={taskvalue}
                itemrelations={
                  tempoptions?.invoiceitemrelations ||
                  tempoptions?.itemrelations
                }
                day={day}
              ></ServiceItemForm>
            </Box>
            {(isNew || itemsList.length > 0) && (
              <Box style={{ marginBottom: 20 }}>
                <ItemsTable
                  products={[...services, ...products]}
                  rows={itemsList}
                  editItem={editItemInList}
                  removeItem={removeItemFromList}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  itemrelations={
                    tempoptions?.invoiceitemrelations ||
                    tempoptions?.itemrelations
                  }
                ></ItemsTable>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            {isNew && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: 7 }}
                    checked={ispercent}
                    onChange={() => {
                      const nv = !ispercent;
                      if (!nv) {
                        setPercent(0);
                        setDiscount(0);
                      }
                      setIspercent(nv);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle2">
                    {isRTL ? 'نسبة' : 'Percentage'}
                  </Typography>
                }
                style={{ fontSize: 14 }}
              />
            )}
            {(isNew || percent > 0) && (
              <TextField
                name="percent"
                label={'%'}
                value={percent}
                onChange={(e: any) => setPercent(Number(e.target.value))}
                variant="outlined"
                disabled={!isNew}
                style={{ width: 100 }}
                margin="dense"
                type="number"
              />
            )}
            <TextField
              name="discount"
              label={words.discount}
              value={discount}
              onChange={(e: any) => setDiscount(Number(e.target.value))}
              variant="outlined"
              style={{ width: 200 }}
              margin="dense"
              type="number"
              onFocus={(e) => e.target.select()}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          {!isMobile && (
            <PrintDownload
              company={company}
              printData={printData}
              template={template}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              generatePDF={() => null}
              isRTL={isRTL}
              no={''}
              element="invoice"
              isNew={true}
            ></PrintDownload>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <PriceTotal
            amount={totals?.amount}
            total={totals?.total}
            discount={discount}
            words={words}
            end={false}
          ></PriceTotal>
        </Grid>
        <Grid item xs={12} md={8}>
          <PaymentSelect
            words={words}
            isCash={isCash}
            setIsCash={setIsCash}
            paid={paid}
            setPaid={setPaid}
            paid2={paid2}
            setPaid2={setPaid2}
            isRTL={isRTL}
            debitaccounts={cashaccounts}
            debitAcc={debitAcc}
            setDebitAcc={setDebitAcc}
            debitAcc2={debitAcc2}
            setDebitAcc2={setDebitAcc2}
            isNew={isNew}
          ></PaymentSelect>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
                color: 'tomato',
              }}
            >
              {moneyFormat(bl)} {isRTL ? 'المتبقي' : 'Balance'}
            </Typography>
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
              }}
            >
              ( {pa} ) {isRTL ? 'مدفوع' : 'Paid'}
            </Typography>
          </Box>
        </Grid>
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewFieldChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
      </Grid>
    </PopupLayout>
  );
};

export default PopupAppointInvoice;
