import { Box, Button, Typography } from '@material-ui/core';
import MyIcon from '../Shared/MyIcon';

export default function LinkLocation({ isRTL, value }: any) {
  if (!value) return <div></div>;
  const openLocation = (url: any) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 8,
      }}
    >
      <Button
        style={{ borderRadius: 5, backgroundColor: '#eee' }}
        onClick={() => openLocation(value)}
      >
        <Typography
          style={{
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          {isRTL ? 'الموقع ' : 'Location'}
        </Typography>
        <MyIcon icon="location" size={24}></MyIcon>
      </Button>
    </Box>
  );
}
