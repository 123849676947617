import { Button, Grid } from '@material-ui/core';
import UploadJson, { downloadJSONFile } from '../common/UploadJson';
import { TextFieldLocal } from '../components';

const renderWord = (title: any, word: any, setWord: any) => {
  return (
    <TextFieldLocal
      name="chequeDate"
      label={title}
      value={word}
      onChange={(e: any) => setWord(title, e.target.value)}
      fullWidth
      mb={0}
    />
  );
};

const WordItem = ({ value, setValue }: any) => {
  if (!value) return <div></div>;

  const setItem = (name: any, v: any) => {
    setValue({ ...value, [name]: v });
  };
  const keyslist = value ? Object.keys(value) : [];
  return (
    <Grid item xs>
      {keyslist?.map((key: any) => {
        return <div key={key}>{renderWord([key], value?.[key], setItem)}</div>;
      })}
    </Grid>
  );
};

const TemplateWordsTrans = ({ en, setEn, ar, setAr }) => {
  if (!en || !ar) return <div></div>;

  const setArwords = (wds: any) => {
    const nwords = { ...ar.words, ...wds };
    const newAr = {
      ...ar,
      words: nwords,
    };
    setAr(newAr);
  };
  const setEnwords = (wds: any) => {
    const nwords = { ...en.words, ...wds };
    const newEn = {
      ...en,
      words: nwords,
    };
    setEn(newEn);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => downloadJSONFile(ar, 'ar')}
        >
          Download Arabic
        </Button>
      </Grid>
      <Grid item xs={2}>
        <UploadJson setValue={setAr}></UploadJson>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => downloadJSONFile(en, 'en')}
        >
          Download English
        </Button>
      </Grid>
      <Grid item xs={2}>
        <UploadJson setValue={setEn}></UploadJson>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={6}>
        <WordItem value={ar.words} setValue={setArwords}></WordItem>
      </Grid>
      <Grid item xs={6}>
        <WordItem value={en.words} setValue={setEnwords}></WordItem>
      </Grid>
    </Grid>
  );
};

export default TemplateWordsTrans;
