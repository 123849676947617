import { gql } from '@apollo/client';

export default gql`
  mutation createStepItem(
    $branch: String
    $id: Int
    $name: String
    $nameAr: String
    $disabled: Boolean
    $additionId: String
    $color: String
    $auto: Boolean
  ) {
    createStepItem(
      branch: $branch
      id: $id
      name: $name
      nameAr: $nameAr
      disabled: $disabled
      additionId: $additionId
      color: $color
      auto: $auto
    ) {
      ok
      message
      data
      error
    }
  }
`;
