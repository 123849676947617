import { gql } from '@apollo/client';

export default gql`
  mutation deletePrintTemplate($_id: String) {
    deletePrintTemplate(_id: $_id) {
      ok
      message
      error
    }
  }
`;
