import { gql } from '@apollo/client';

export default gql`
  mutation inprogressDocUpload($docIds: [String]) {
    inprogressDocUpload(docIds: $docIds) {
      ok
      message
      data
      error
    }
  }
`;
