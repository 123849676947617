import { gql } from '@apollo/client';

export default gql`
  mutation statusMultiEvents($eventIds: [String], $status: Int) {
    statusMultiEvents(eventIds: $eventIds, status: $status) {
      ok
      message
      data
      error
    }
  }
`;
