import { Box, colors, Divider, Grid, Paper } from '@material-ui/core';
import { moneyFormat, rCellMain } from '../../common/helpers';

export default function RaseedDataBx({ row, theme, isRTL, height = 200 }) {
  const scolor = colors.blue[400];
  const gcolor = colors.green[500];
  const ecolor = colors.red[500];
  const totalinvoiced = row?.totalinvoiced || 0;
  const totalDiscount = row?.totalDiscount || 0;
  const totalpaid = row?.totalpaid || 0;
  const toatlExpenses = row?.toatlExpenses || 0;
  const toatlProdExpenses = row?.toatlProdExpenses || 0;
  const totalExpPetty = row?.totalExpPetty || 0;
  const toatlExpPayable = row?.toatlExpPayable || 0;

  const sales = totalinvoiced - totalDiscount;
  const paid = totalpaid;
  const expenses =
    toatlExpenses + toatlProdExpenses + totalExpPetty + toatlExpPayable;
  const balance = sales - paid - expenses;
  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              {rCellMain(isRTL ? 'المبيعات' : 'Net Sales', scolor)}
            </Grid>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              {rCellMain(moneyFormat(sales), scolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'المدفوعات' : 'Paid', gcolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(paid), gcolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'المصروفات' : 'Expenses', ecolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(expenses), ecolor)}
            </Grid>
            <Divider></Divider>
            <Grid item xs={6}>
              <Box style={{ marginTop: 20 }}>
                {rCellMain(isRTL ? 'الرصيد' : 'Balance', '#333')}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: 20 }}>
                {rCellMain(moneyFormat(balance), '#333')}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Paper>
  );
}
