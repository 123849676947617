import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import { useTemplate } from '../../../../hooks';
import useLandingChart from '../../../../hooks/useLandingChart';
import WorkStatus from '../../../../Shared/WorkStatus';

function WorkStatusBox({ isRTL, theme, words, dark, color, height }) {
  const { tempwords, tempoptions } = useTemplate();
  const { eventsMonth } = useLandingChart(null);
  return (
    <Box>
      {userroles?.appointments?.admin && (
        <WorkStatus
          isRTL={isRTL}
          words={words}
          theme={theme}
          tempwords={tempwords}
          eventsMonth={eventsMonth}
          height={height}
          tempoptions={tempoptions}
        ></WorkStatus>
      )}
    </Box>
  );
}

export default WorkStatusBox;
