import { gql } from '@apollo/client';

export default gql`
  mutation createSupplier(
    $branch: String
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $phone: String
    $mobile: String
    $email: String
    $address: String
    $avatar: String
    $logo: String
    $headphoto: String
    $employee: EmployeeInput
    $openingbalance: Float
  ) {
    createSupplier(
      branch: $branch
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      phone: $phone
      mobile: $mobile
      email: $email
      address: $address
      avatar: $avatar
      logo: $logo
      headphoto: $headphoto
      employee: $employee
      openingbalance: $openingbalance
    ) {
      ok
      message
      data
      error
    }
  }
`;
