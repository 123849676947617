import { gql } from '@apollo/client';

export default gql`
  mutation createMultiDepartments($data: String) {
    createMultiDepartments(data: $data) {
      ok
      message
      error
    }
  }
`;
