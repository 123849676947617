/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@material-ui/core';
import { TempPrintView } from '../generator/print/TempPrintView';

export default function DocumentEmailPreview({
  row,
  isRTL,
  height,
  formdoc,
  company,
  left,
  right,
}: any) {
  const template = { template: formdoc?.printTempDoc };
  return (
    <Box
      style={{
        height: height - 85,
        overflow: 'hidden',
        marginLeft: left ? 15 : undefined,
        marginRight: right ? 15 : undefined,
      }}
    >
      <Paper
        style={{
          overflow: 'auto',
          height: height - 85,
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
        }}
        elevation={5}
      >
        {row && (
          <Box style={{ marginTop: 40, height: '170mm' }}>
            <Paper
              elevation={5}
              id={'letter'}
              style={{
                direction: 'ltr',
                width: '210mm',
                height: '297mm',
                zoom: 0.55,
                backgroundColor: '#fff',
              }}
            >
              <TempPrintView
                company={company}
                data={row}
                template={template}
                ref={null}
                isRTL={isRTL}
              ></TempPrintView>
            </Paper>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
