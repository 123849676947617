/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { dublicateAlert, errorAlert, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Box, Button, Fab, Typography } from '@material-ui/core';
import useAccounts from '../hooks/useAccounts';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import { CalenderLocal, TextFieldLocal } from '../components';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/client';
import {
  useCustomers,
  useDepartments,
  useEmployees,
  useSuppliers,
  useTemplate,
} from '../hooks';
import KaidsSingleTable from '../Shared/KaidsSingleTable';
import { successAlert } from '../Shared/helpers';
import { docTypesExtend } from '../constants/reports';
import PopupCustomer from './PopupCustomer';
import useResourses from '../hooks/useResourses';
import useTasks from '../hooks/useTasks';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import PopupResourses from './PopupResourses';
import { operationTypes } from '../constants';
import PopupSupplier from './PopupSupplier';
import PopupAccount from './PopupAccount';
import { getAccounts, getEmployees } from '../graphql';
import { createNewEmployeeAccount } from '../graphql/mutation';
import PopupTask from './PopupTask';
import AutoFieldSimple from '../components/fields/AutoFieldSimple';
import UploadDocView from '../components/viewer/UploadDocView';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

export const kaidlist = (list: any) => {
  return list.map((item: any) => {
    return {
      ...item,
      amountDebit: item.debit,
      amountCredit: item.credit,
      acc: item.accCode,
    };
  });
};

const PopupDocKaid = ({
  open,
  onClose,
  row,
  addAction,
  updateUploadStatus,
  theme,
  company,
}: any) => {
  const [saving, setSaving] = useState(false);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [kaidsums, setKaidsums]: any = React.useState();
  const [desc, setDesc]: any = React.useState('');

  const [custvalueMain, setCustvalueMain] = useState(null);
  const [suppvalueMain, setSuppvalueMain] = useState<any>(null);

  const [mainaccDebit, setMainaccDebit]: any = React.useState(null);
  const [accDebit, setAccDebit]: any = React.useState(null);
  const [amountDebit, setAmountDebit]: any = React.useState(0);

  const [emplvalueDebit, setEmplvalueDebit] = useState(null);
  const [resovalueDebit, setResovalueDebit] = useState(null);
  const [departvalueDebit, setDepartvalueDebit] = useState(null);
  const [taskvalueDebit, setTaskvalueDebit] = useState(null);
  const [custvalueDebit, setCustvalueDebit] = useState(null);
  const [suppvalueDebit, setSuppvalueDebit] = useState<any>(null);

  const [mainaccCredit, setMainaccCredit]: any = React.useState(null);
  const [accCredit, setAccCredit]: any = React.useState(null);
  const [amountCredit, setAmountCredit]: any = React.useState(0);

  const [emplvalueCredit, setEmplvalueCredit] = useState(null);
  const [resovalueCredit, setResovalueCredit] = useState(null);
  const [departvalueCredit, setDepartvalueCredit] = useState(null);
  const [taskvalueCredit, setTaskvalueCredit] = useState(null);
  const [custvalueCredit, setCustvalueCredit] = useState(null);
  const [suppvalueCredit, setSuppvalueCredit] = useState<any>(null);

  const [itemsList, setItemsList] = useState([]);
  const [newtext, setNewtext] = useState('');
  const [newtextMain, setNewtextMain] = useState(false);
  const [newtextDebit, setNewtextDebit] = useState(false);
  const [newtextCredit, setNewtextCredit] = useState(false);

  const [opType, setOptype] = useState(null);
  const [maindesc, setMaindesc] = useState('');
  const [openCust, setOpenCust] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openTsk, setOpenTsk] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const [openSupp, setOpenSupp] = useState(false);
  const [openAcc, setOpenAcc] = useState(false);

  const [backdesc, setBackdesc] = useState('');

  const [attachments, setAttachments] = useState([]);

  const { departments, addDepartment, editDepartment } = useDepartments();
  const { resourses, addResourse, editResourse } = useResourses();
  const { tasks } = useTasks();
  const { tempwords, tempoptions } = useTemplate();
  const {
    employeesfull: employees,
    addEmployee,
    editEmployee,
  } = useEmployees();
  const { customers, addCustomer, editCustomer } = useCustomers();
  const { suppliers, addSupplier, editSupplier } = useSuppliers();
  const { addAccount, editAccount } = useAccounts();
  const { accounts } = useAccounts();
  const { addTask } = useTasks();

  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const [addNewEmployeeAccount] = useMutation(createNewEmployeeAccount, {
    refetchQueries: [{ query: getAccounts }, { query: getEmployees }],
  });

  const onOperationChange = (opt: any) => {
    if (opt?.debitmainacc) {
      setMainaccDebit(opt?.debitmainacc);
    } else {
      setMainaccDebit(null);
    }
    if (opt?.creditmainacc) {
      setMainaccCredit(opt?.creditmainacc);
    } else {
      setMainaccCredit(null);
    }
    if (opt?.debitacc) {
      const dama = accounts.filter((ac: any) => ac.code === opt?.debitacc)?.[0];
      setAccDebit(dama);
    } else {
      setAccDebit(null);
    }
    if (opt?.creditacc) {
      const cama = accounts.filter(
        (ac: any) => ac.code === opt?.creditacc
      )?.[0];
      setAccCredit(cama);
    } else {
      setAccCredit(null);
    }
    setOptype(opt);
  };

  const openCustomerMain = () => {
    setOpenCust(true);
    setNewtextMain(true);
  };
  const openSupplierMain = () => {
    setOpenSupp(true);
    setNewtextMain(true);
  };

  const openDepartmentDebit = () => {
    setOpenDep(true);
    setNewtextDebit(true);
  };
  const openTaskDebit = () => {
    setOpenTsk(true);
    setNewtextDebit(true);
  };
  const openEmployeeDebit = () => {
    setOpenEmp(true);
    setNewtextDebit(true);
  };
  const openResourseDebit = () => {
    setOpenRes(true);
    setNewtextDebit(true);
  };
  const openCustomerDebit = () => {
    setOpenCust(true);
    setNewtextDebit(true);
  };
  const openSupplierDebit = () => {
    setOpenSupp(true);
    setNewtextDebit(true);
  };
  const openAccountDebit = () => {
    setOpenAcc(true);
    setNewtextDebit(true);
  };
  const openDepartmentCredit = () => {
    setOpenDep(true);
    setNewtextCredit(true);
  };
  const openTaskCredit = () => {
    setOpenTsk(true);
    setNewtextCredit(true);
  };
  const openEmployeeCredit = () => {
    setOpenEmp(true);
    setNewtextCredit(true);
  };
  const openResourseCredit = () => {
    setOpenRes(true);
    setNewtextCredit(true);
  };
  const openCustomerCredit = () => {
    setOpenCust(true);
    setNewtextCredit(true);
  };
  const openSupplierCredit = () => {
    setOpenSupp(true);
    setNewtextCredit(true);
  };
  const openAccountCredit = () => {
    setOpenAcc(true);
    setNewtextCredit(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
  };
  const onCloseTask = () => {
    setOpenTsk(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
    setNewtextMain(false);
  };
  const onCloseSupplier = () => {
    setOpenSupp(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
    setNewtextMain(false);
  };
  const onCloseAccount = () => {
    setOpenAcc(false);
    setNewtext('');
    setNewtextDebit(false);
    setNewtextCredit(false);
  };
  const onNewCustChange = (nextValue: any) => {
    if (newtextDebit) {
      setCustvalueDebit(nextValue);
    }
    if (newtextCredit) {
      setCustvalueCredit(nextValue);
    }
    if (newtextMain) {
      setCustvalueMain(nextValue);
    }
  };
  const onNewSuppChange = (nextValue: any) => {
    if (newtextDebit) {
      setSuppvalueDebit(nextValue);
    }
    if (newtextCredit) {
      setSuppvalueCredit(nextValue);
    }
    if (newtextMain) {
      setSuppvalueMain(nextValue);
    }
  };
  const onNewDepartChange = (nextValue: any) => {
    if (newtextDebit) {
      setDepartvalueDebit(nextValue);
    }
    if (newtextCredit) {
      setDepartvalueCredit(nextValue);
    }
  };
  const onNewTaskChange = (nextValue: any) => {
    if (newtextDebit) {
      setTaskvalueDebit(nextValue);
    }
    if (newtextCredit) {
      setTaskvalueCredit(nextValue);
    }
  };
  const onNewEmplChange = (nextValue: any) => {
    if (newtextDebit) {
      setEmplvalueDebit(nextValue);
    }
    if (newtextCredit) {
      setEmplvalueCredit(nextValue);
    }
  };
  const onNewResoChange = (nextValue: any) => {
    if (newtextDebit) {
      setResovalueDebit(nextValue);
    }
    if (newtextCredit) {
      setResovalueCredit(nextValue);
    }
  };
  const onNewAccChange = (nextValue: any) => {
    if (newtextDebit) {
      setAccDebit(nextValue);
    }
    if (newtextCredit) {
      setAccCredit(nextValue);
    }
  };

  useEffect(() => {
    if (row && row._id) {
      setMaindesc(row.desc);
      if (row.attachments) {
        const phs = JSON.parse(row.attachments);
        if (phs && phs.length > 0) {
          setAttachments(phs);
        }
      }
      const dType = docTypesExtend.filter(
        (dt: any) => dt.value === row?.opType
      )?.[0];
      if (!!dType) {
        onOperationChange(dType);
        // setOptype(dType)
      }
    }
  }, [row, open]);

  const addItemToList = async () => {
    if (!accCredit && !accDebit) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تحديد الحساب' : 'You have to select account'
      );
      return;
    }
    if (amountDebit === 0 && amountCredit === 0) {
      await messageAlert(
        setAlrt,
        isRTL ? 'قيمة القيد مطلوبة' : 'Amount required'
      );
      return;
    }
    const items = [];
    if (!!accDebit && amountDebit > 0) {
      const item = {
        time: selectedDate,
        acc: accDebit.code,
        amountCredit: 0,
        amountDebit,
        desc,
        supplierId: suppvalueDebit ? suppvalueDebit._id : undefined,
        supplierName: suppvalueDebit ? suppvalueDebit.name : undefined,
        supplierNameAr: suppvalueDebit ? suppvalueDebit.nameAr : undefined,
        customerId: custvalueDebit ? custvalueDebit._id : undefined,
        customerName: custvalueDebit ? custvalueDebit.name : undefined,
        customerNameAr: custvalueDebit ? custvalueDebit.nameAr : undefined,
        departmentId: departvalueDebit ? departvalueDebit._id : undefined,
        departmentName: departvalueDebit ? departvalueDebit.name : undefined,
        departmentNameAr: departvalueDebit
          ? departvalueDebit.nameAr
          : undefined,
        employeeId: emplvalueDebit ? emplvalueDebit._id : undefined,
        employeeName: emplvalueDebit ? emplvalueDebit.name : undefined,
        employeeNameAr: emplvalueDebit ? emplvalueDebit.nameAr : undefined,
        resourseId: resovalueDebit ? resovalueDebit._id : undefined,
        resourseName: resovalueDebit ? resovalueDebit.name : undefined,
        resourseNameAr: resovalueDebit ? resovalueDebit.nameAr : undefined,
        contractId: taskvalueDebit ? taskvalueDebit._id : undefined,
        contractName: taskvalueDebit ? taskvalueDebit.name : undefined,
        contractNameAr: taskvalueDebit ? taskvalueDebit.nameAr : undefined,
        userId: user._id,
      };
      items.push({ ...item, userId: user._id });
    }
    if (!!accCredit && amountCredit > 0) {
      const item = {
        time: selectedDate,
        acc: accCredit.code,
        amountCredit,
        amountDebit: 0,
        desc,
        supplierId: suppvalueCredit ? suppvalueCredit._id : undefined,
        supplierName: suppvalueCredit ? suppvalueCredit.name : undefined,
        supplierNameAr: suppvalueCredit ? suppvalueCredit.nameAr : undefined,
        customerId: custvalueCredit ? custvalueCredit._id : undefined,
        customerName: custvalueCredit ? custvalueCredit.name : undefined,
        customerNameAr: custvalueCredit ? custvalueCredit.nameAr : undefined,
        departmentId: departvalueCredit ? departvalueCredit._id : undefined,
        departmentName: departvalueCredit ? departvalueCredit.name : undefined,
        departmentNameAr: departvalueCredit
          ? departvalueCredit.nameAr
          : undefined,
        employeeId: emplvalueCredit ? emplvalueCredit._id : undefined,
        employeeName: emplvalueCredit ? emplvalueCredit.name : undefined,
        employeeNameAr: emplvalueCredit ? emplvalueCredit.nameAr : undefined,
        resourseId: resovalueCredit ? resovalueCredit._id : undefined,
        resourseName: resovalueCredit ? resovalueCredit.name : undefined,
        resourseNameAr: resovalueCredit ? resovalueCredit.nameAr : undefined,
        contractId: taskvalueCredit ? taskvalueCredit._id : undefined,
        contractName: taskvalueCredit ? taskvalueCredit.name : undefined,
        contractNameAr: taskvalueCredit ? taskvalueCredit.nameAr : undefined,
        userId: user._id,
      };
      items.push({ ...item, userId: user._id });
    }

    const newArray = [...itemsList, ...items];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
    resetForm();
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };
  useEffect(() => {
    getOverallSums();
  }, [itemsList]);

  const getOverallSums = () => {
    const creditlist = itemsList.map((litem: any) => litem.amountCredit);
    const credit = creditlist.reduce((psum: any, a: any) => psum + a, 0);
    const debitlist = itemsList.map((litem: any) => litem.amountDebit);
    const debit = debitlist.reduce((psum: any, a: any) => psum + a, 0);
    setKaidsums({ credit, debit });
  };

  const onSubmit = async () => {
    if (selectedDate > new Date()) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    if (!itemsList || itemsList.length === 0) {
      await messageAlert(
        setAlrt,
        isRTL ? 'لا يوجد قيود لحفظها' : 'No Items to be saved'
      );
      return;
    }
    if (!kaidsums?.credit || kaidsums?.credit !== kaidsums?.debit) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب ان يكون الطرفين متساويين' : 'The Document must be balanced'
      );
      return;
    }
    if (!opType?.value) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب اختيار نوع المستند ' : 'Document Type Required'
      );
      return;
    }
    setSaving(true);
    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      branch: company?.basename,
      opType: opType?.value,
      time: selectedDate,
      supplier: suppvalueMain
        ? {
            supplierId: suppvalueMain._id,
            supplierName: suppvalueMain.name,
            supplierNameAr: suppvalueMain.nameAr,
          }
        : {
            supplierId: undefined,
            supplierName: undefined,
            supplierNameAr: undefined,
          },
      customer: custvalueMain
        ? {
            customerId: custvalueMain._id,
            customerName: custvalueMain.name,
            customerNameAr: custvalueMain.nameAr,
          }
        : {
            customerId: undefined,
            customerName: undefined,
            customerNameAr: undefined,
          },
      items: JSON.stringify(itemsList),
      attachments: row?.attachments,
      uploadId: row?._id,
      desc: maindesc,
      amount: kaidsums?.credit,
    };
    const mutate = addAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.createGeneralDoc?.ok === true) {
        const variables = {
          docId: row?._id,
          stepId: 10,
          desc: 'Document Created',
        };
        await updateUploadStatus({ variables });
      }
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      resetAllForm();
      closeModal();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const resetForm = () => {
    setAmountCredit(0);
    setAmountDebit(0);
    setDesc('');
    setSaving(false);
    setEmplvalueDebit(null);
    setResovalueDebit(null);
    setDepartvalueDebit(null);
    setTaskvalueDebit(null);
    setCustvalueDebit(null);
    setSuppvalueDebit(null);
    setEmplvalueCredit(null);
    setResovalueCredit(null);
    setDepartvalueCredit(null);
    setTaskvalueCredit(null);
    setCustvalueCredit(null);
    setSuppvalueCredit(null);
  };
  const resetAllForm = () => {
    setAmountCredit(0);
    setAmountDebit(0);
    setDesc('');
    setSaving(false);
    setEmplvalueDebit(null);
    setResovalueDebit(null);
    setDepartvalueDebit(null);
    setTaskvalueDebit(null);
    setCustvalueDebit(null);
    setSuppvalueDebit(null);
    setEmplvalueCredit(null);
    setResovalueCredit(null);
    setDepartvalueCredit(null);
    setTaskvalueCredit(null);
    setCustvalueCredit(null);
    setSuppvalueCredit(null);
    setItemsList([]);
    setMaindesc('');
    setAttachments([]);
    setCustvalueMain(null);
    setSuppvalueMain(null);
  };
  const resetAll = () => {
    resetForm();
    setOptype(null);
    setAccCredit(null);
    setAccDebit(null);
    setItemsList([]);
    setMaindesc('');
    setAttachments([]);
    setCustvalueMain(null);
    setSuppvalueMain(null);
    // setAttachments([]);
  };
  const closeModal = () => {
    resetAll();
    onClose();
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const acontract = tempoptions?.contract;
  const acustomer = [
    operationTypes.salesInvoice,
    operationTypes.salesInvoiceCard,
    operationTypes.salesInvoiceCredit,
    operationTypes.customerReceipt,
    operationTypes.customerReceiptCheque,
    operationTypes.kaid,
  ].includes(opType?.value);
  const asupplier = [
    operationTypes.purchaseInvoice,
    operationTypes.purchaseInvoiceCredit,
    operationTypes.purchaseInvoiceStock,
    operationTypes.purchaseInvoiceCreditStock,
    operationTypes.supplierPayemnt,
    operationTypes.supplierPayemntCheque,
    operationTypes.kaid,
  ].includes(opType?.value);
  const aresourse = tempoptions?.resourse;
  const aemployee = tempoptions?.employee;
  const adepartment = tempoptions?.department;

  const emplusers = accounts.filter((fu: any) => fu?.employeeId);
  const emplIds = emplusers.map((eu: any) => eu?.employeeId);
  const emplnoaccount =
    emplIds && emplIds?.length > 0
      ? employees?.filter((emp: any) => !emplIds.includes(emp?._id))
      : employees;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={words.generalkaid}
      onSubmit={onSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      mt={10}
      // maxWidth="xl"
      fs
    >
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Grid
            style={{
              backgroundColor: '#f4f4f4',
              padding: 15,
              borderRadius: 10,
            }}
            container
            spacing={1}
          >
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2} style={{ marginTop: -22 }}>
                  <CalenderLocal
                    isRTL={isRTL}
                    label={words?.time}
                    value={selectedDate}
                    onChange={handleDateChange}
                  ></CalenderLocal>
                </Grid>
                <Grid item xs={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} style={{ marginTop: 10 }}>
                      <AutoFieldSimple
                        name="documenttype"
                        title={isRTL ? 'نوع المستند' : 'Document Types'}
                        setSelectValue={onOperationChange}
                        isRTL={isRTL}
                        fullWidth
                        options={docTypesExtend}
                        value={opType}
                        words={words}
                        fullwidth
                        mb={0}
                      ></AutoFieldSimple>
                    </Grid>
                    {acustomer && (
                      <Grid item xs={4} style={{ marginTop: 10 }}>
                        <AutoFieldLocal
                          name="customer"
                          title={tempwords?.customer}
                          words={words}
                          options={customers}
                          value={custvalueMain}
                          setSelectValue={setCustvalueMain}
                          setNewtext={setNewtext}
                          isRTL={isRTL}
                          fullWidth
                          openAdd={openCustomerMain}
                          // showphone
                          mb={0}
                        ></AutoFieldLocal>
                      </Grid>
                    )}
                    {asupplier && (
                      <Grid item xs={4} style={{ marginTop: 10 }}>
                        <AutoFieldLocal
                          name="supplier"
                          title={words.supplier}
                          words={words}
                          options={suppliers}
                          value={suppvalueMain}
                          setSelectValue={setSuppvalueMain}
                          setNewtext={setNewtext}
                          isRTL={isRTL}
                          fullWidth
                          openAdd={openSupplierMain}
                          // showphone
                          mb={0}
                        ></AutoFieldLocal>
                      </Grid>
                    )}
                    <Grid item xs={5} style={{ marginTop: 10 }}>
                      <TextFieldLocal
                        name="maindesc"
                        label={isRTL ? 'العنوان' : 'Title'}
                        value={maindesc}
                        onChange={(e: any) => setMaindesc(e.target.value)}
                        row={row}
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    style={{ padding: 10, margin: 10, backgroundColor: '#eee' }}
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={7}>
                          <AutoFieldLocal
                            name="accDebit"
                            title={isRTL ? 'حساب مدين' : 'Debit Account'}
                            words={words}
                            options={
                              mainaccDebit
                                ? accounts?.filter(
                                    (acc: any) =>
                                      acc.parentcode === mainaccDebit
                                  )
                                : accounts
                            }
                            value={accDebit}
                            setSelectValue={setAccDebit}
                            isRTL={isRTL}
                            openAdd={openAccountDebit}
                            fullwidth
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                        <Grid item xs={3}>
                          <TextFieldLocal
                            required
                            name="amountDebit"
                            label={words.amountDebit}
                            value={amountDebit}
                            onChange={(e: any) => {
                              setAmountDebit(Number(e.target.value));
                            }}
                            row={row}
                            type="number"
                            fullWidth
                            mb={0}
                          />
                        </Grid>
                        {acustomer && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="customer"
                              title={tempwords?.customer}
                              words={words}
                              options={customers}
                              value={custvalueDebit}
                              setSelectValue={setCustvalueDebit}
                              setNewtext={setNewtext}
                              isRTL={isRTL}
                              fullWidth
                              openAdd={openCustomerDebit}
                              // showphone
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {asupplier && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="supplier"
                              title={words.supplier}
                              words={words}
                              options={suppliers}
                              value={suppvalueDebit}
                              setSelectValue={setSuppvalueDebit}
                              setNewtext={setNewtext}
                              isRTL={isRTL}
                              fullWidth
                              openAdd={openSupplierDebit}
                              // showphone
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {acontract && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="task"
                              title={tempwords?.task}
                              words={words}
                              options={tasks}
                              value={taskvalueDebit}
                              setSelectValue={setTaskvalueDebit}
                              isRTL={isRTL}
                              fullWidth
                              openAdd={openTaskDebit}
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {aresourse && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="resourse"
                              title={tempwords?.resourse}
                              words={words}
                              options={resourses}
                              value={resovalueDebit}
                              setSelectValue={setResovalueDebit}
                              isRTL={isRTL}
                              openAdd={openResourseDebit}
                              fullWidth
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {aemployee && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="employee"
                              title={tempwords?.employee}
                              words={words}
                              options={employees.filter(
                                (emp: any) => !emp.disabled
                              )}
                              value={emplvalueDebit}
                              setSelectValue={setEmplvalueDebit}
                              isRTL={isRTL}
                              openAdd={openEmployeeDebit}
                              fullWidth
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {adepartment && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="department"
                              title={tempwords?.department}
                              words={words}
                              options={departments.filter(
                                (d: any) => d.depType === 1
                              )}
                              value={departvalueDebit}
                              setSelectValue={setDepartvalueDebit}
                              openAdd={openDepartmentDebit}
                              isRTL={isRTL}
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={7}>
                          <AutoFieldLocal
                            name="accCredit"
                            title={isRTL ? 'حساب دائن' : 'Credit Account'}
                            words={words}
                            options={
                              mainaccCredit
                                ? accounts?.filter(
                                    (acc: any) =>
                                      acc.parentcode === mainaccCredit
                                  )
                                : accounts
                            }
                            value={accCredit}
                            setSelectValue={setAccCredit}
                            isRTL={isRTL}
                            openAdd={openAccountCredit}
                            fullwidth
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                        <Grid item xs={3}>
                          <TextFieldLocal
                            required
                            name="amountCredit"
                            label={words.amountCredit}
                            value={amountCredit}
                            onChange={(e: any) => {
                              setAmountCredit(Number(e.target.value));
                            }}
                            row={row}
                            type="number"
                            fullWidth
                            mb={0}
                          />
                        </Grid>
                        {acustomer && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="customer"
                              title={tempwords?.customer}
                              words={words}
                              options={customers}
                              value={custvalueCredit}
                              setSelectValue={setCustvalueCredit}
                              setNewtext={setNewtext}
                              isRTL={isRTL}
                              fullWidth
                              openAdd={openCustomerCredit}
                              // showphone
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {asupplier && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="supplier"
                              title={words.supplier}
                              words={words}
                              options={suppliers}
                              value={suppvalueCredit}
                              setSelectValue={setSuppvalueCredit}
                              setNewtext={setNewtext}
                              isRTL={isRTL}
                              fullWidth
                              openAdd={openSupplierCredit}
                              // showphone
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {acontract && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="task"
                              title={tempwords?.task}
                              words={words}
                              options={tasks}
                              value={taskvalueCredit}
                              setSelectValue={setTaskvalueCredit}
                              isRTL={isRTL}
                              fullWidth
                              openAdd={openTaskCredit}
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {aresourse && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="resourse"
                              title={tempwords?.resourse}
                              words={words}
                              options={resourses}
                              value={resovalueCredit}
                              setSelectValue={setResovalueCredit}
                              isRTL={isRTL}
                              openAdd={openResourseCredit}
                              fullWidth
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {aemployee && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="employee"
                              title={tempwords?.employee}
                              words={words}
                              options={employees.filter(
                                (emp: any) => !emp.disabled
                              )}
                              value={emplvalueCredit}
                              setSelectValue={setEmplvalueCredit}
                              isRTL={isRTL}
                              openAdd={openEmployeeCredit}
                              fullWidth
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                        {adepartment && (
                          <Grid item xs={5}>
                            <AutoFieldLocal
                              name="department"
                              title={tempwords?.department}
                              words={words}
                              options={departments.filter(
                                (d: any) => d.depType === 1
                              )}
                              value={departvalueCredit}
                              setSelectValue={setDepartvalueCredit}
                              openAdd={openDepartmentCredit}
                              isRTL={isRTL}
                              mb={0}
                            ></AutoFieldLocal>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={11}>
                      <TextFieldLocal
                        name="desc"
                        label={words.description}
                        value={desc}
                        onChange={(e: any) => setDesc(e.target.value)}
                        row={row}
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Fab
                        style={{ marginLeft: 10, marginTop: 7 }}
                        color="primary"
                        size="small"
                        onClick={addItemToList}
                        title="Create new row"
                      >
                        <AddIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <KaidsSingleTable
                rows={itemsList}
                editItem={editItemInList}
                removeItem={removeItemFromList}
                isRTL={isRTL}
                words={words}
                user={user}
                accounts={accounts}
                acontract={acontract}
                acustomer={acustomer}
                asupplier={asupplier}
                aresourse={aresourse}
                aemployee={aemployee}
                adepartment={adepartment}
              ></KaidsSingleTable>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Box>
                {words.amountDebit}: {kaidsums?.debit}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                {words.amountCredit}: {kaidsums?.credit}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>{kaidsums?.debit - kaidsums?.credit}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <UploadDocView images={attachments} doc={row}></UploadDocView>

          {row?.stepId !== 7 && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mt={1}
            >
              <TextFieldLocal
                name="desc"
                label={words.note}
                value={backdesc}
                onChange={(e: any) => setBackdesc(e.target.value)}
                fullWidth
                mb={0}
              />
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: 150,
                  height: 36,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 7,
                }}
                onClick={async () => {
                  const variables = {
                    docId: row?._id,
                    stepId: 7,
                    desc: backdesc,
                  };
                  await updateUploadStatus({ variables });
                  resetAllForm();
                  closeModal();
                }}
              >
                <Typography>{isRTL ? 'اعادة للتصحيح' : 'Send Back'}</Typography>
              </Button>
            </Box>
          )}
        </Grid>
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupSupplier
          newtext={newtext}
          open={openSupp}
          onClose={onCloseSupplier}
          isNew={true}
          setNewValue={onNewSuppChange}
          row={null}
          addAction={addSupplier}
          editAction={editSupplier}
        ></PopupSupplier>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupTask
          newtext={newtext}
          open={openTsk}
          onClose={onCloseTask}
          isNew={true}
          setNewValue={onNewTaskChange}
          row={null}
          addAction={addTask}
        ></PopupTask>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
        <PopupAccount
          open={openAcc}
          onClose={onCloseAccount}
          isNew={true}
          setNewValue={onNewAccChange}
          row={null}
          accounts={accounts}
          emplnoaccount={emplnoaccount}
          addNewEmployeeAccount={addNewEmployeeAccount}
          addAction={addAccount}
          editAction={editAccount}
        ></PopupAccount>
      </Grid>
    </PopupLayout>
  );
};

export default PopupDocKaid;
