import { gql } from '@apollo/client';

export default gql`
  subscription notifiAdded {
    notifiAdded {
      data
      userId
    }
  }
`;
