import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box } from '@material-ui/core';

export default function SelectSimpleNumber({ value, setValue, titel, items }) {
  const handleChange = (e: any) => {
    const v = Number(e.target.value);
    setValue(v);
  };

  return (
    <Box>
      <FormControl size="small" fullWidth>
        <InputLabel>{titel}</InputLabel>
        <Select
          labelId="simple-select-label"
          value={value}
          label={titel}
          onChange={handleChange}
          variant="filled"
        >
          {items.map((name: any) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
