import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useLayoutEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '../../hooks';
import { opTypeFormater } from '../../Shared/colorFormat';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

function ImageView({ images, doc }: any) {
  const [width, setWidth] = useState(0);
  const { height } = useWindowDimensions();
  const ref = useRef(null);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold' }}>
              {opTypeFormater(doc?.opType)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold' }}>
              {doc?.stepName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{doc?.desc}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{doc?.stepDesc}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper style={{ height: height - 250, overflow: 'auto' }} elevation={5}>
        <TransformWrapper>
          <TransformComponent>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {images.map((src: any, index: any) => (
                <Box>
                  <img
                    src={src}
                    key={index}
                    style={{
                      overflow: 'hidden',
                      objectFit: 'cover',
                      width: width - 10,
                    }}
                    alt=""
                  />
                </Box>
              ))}
            </div>
          </TransformComponent>
        </TransformWrapper>
      </Paper>
    </div>
  );
}

export default ImageView;
