import { createContext } from 'react';

export const ContractsReportContext = createContext<any>({});

export const initContractsReportContext = {
  currentViewName: 'Month',
  currentDate: new Date(),
  endDate: new Date(),
  sort: [{ columnName: 'time', direction: 'desc' }],
  hiddenColumnNames: [],

  departvalue: [],
  resovalue: [],
  emplvalue: [],
  custvalue: [],
  groupby: 'none',
  group: false,
  sumcolumn: 'none',
  status: null,
  createtime: false,
};

export const contractsReportReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setCurrentViewName':
      return { ...state, currentViewName: action.payload };
    case 'setCurrentDate':
      return { ...state, currentDate: action.payload };
    case 'setEndDate':
      return { ...state, endDate: action.payload };
    case 'setSort':
      return { ...state, sort: action.payload };
    case 'setCreatetime':
      return { ...state, createtime: action.payload };
    case 'setHiddenColumnNames':
      return { ...state, hiddenColumnNames: action.payload };
    case 'setDepartvalue':
      return { ...state, departvalue: action.payload };
    case 'setEmplvalue':
      return { ...state, emplvalue: action.payload };
    case 'setResovalue':
      return { ...state, resovalue: action.payload };
    case 'setCustvalue':
      return { ...state, custvalue: action.payload };
    case 'setGroupby':
      return { ...state, groupby: action.payload };
    case 'setGroup':
      return { ...state, group: action.payload };
    case 'setSumcolumn':
      return { ...state, sumcolumn: action.payload };
    case 'setStatus':
      return { ...state, status: action.payload };
    default:
      throw new Error('Unexpected action');
  }
};
