/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import {
  downloadCSVFile,
  getRowId,
  LinkPhone,
  updateDocNumbers,
  userroles,
} from '../../common';
import { PopupInvoice } from '../../pubups';
import {
  createInvoice,
  deleteInvoice,
  getInvoices,
  getProducts,
  updateInvoice,
} from '../../graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  amountFormatter,
  currencyFormatter,
  linkFormatter,
  moneyFormat,
  paidFormatter,
  timeFormatter,
  userFormatter,
} from '../../Shared/colorFormat';
import { SearchTable } from '../../components';
import { SalesContext } from '../../contexts';
import { getColumns } from '../../common/columns';
import useTasks from '../../hooks/useTasks';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import useResourses from '../../hooks/useResourses';
import useDepartments from '../../hooks/useDepartments';
import useEmployees from '../../hooks/useEmployees';
import { useCustomers, useServices, useTemplate, useUsers } from '../../hooks';
import _ from 'lodash';
import { getTasks } from '../../graphql/query';
import Whatsapp from '../../common/Whatsapp';
import ExportBtn from '../../common/ExportBtn';
import AutoFieldLocal from '../../components/fields/AutoFieldLocal';
import { createInvoiceLink } from '../../graphql/mutation';
import ButtonShort from '../../common/ButtonShort';
import { TableComponent } from '../components/TableComponent';

const Invoices = forwardRef((props: any, ref: any) => {
  const {
    isRTL,
    words,
    start,
    end,
    theme,
    company,
    isEmployee,
    employeeId,
    drawerWidth,
  } = props;

  const col = getColumns({ isRTL, words });
  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      { name: 'time', title: words?.time },
      { name: 'docNo', title: words?.no },
      col.eventNo,
      col.customer,
      { name: 'customerPhone', title: words?.phoneNumber },
      tempoptions?.contract && col.contract,
      tempoptions?.employee && col.employee,
      tempoptions?.resourse && col.resourse,
      { name: 'total', title: words.total },
      { name: 'discount', title: words?.discount },
      { name: 'amount', title: words?.amount },
      { name: 'paid', title: isRTL ? 'مدفوع' : 'Paid' },
      { name: 'amountPaid', title: words?.cash },
      { name: 'amountPaid2', title: words?.card },
      tempoptions?.invoicetip && {
        name: 'tip',
        title: isRTL ? 'بقشيش' : 'Tip',
      },
      tempoptions?.shortinvoice && {
        name: 'link',
        title: isRTL ? 'رابط' : 'Link',
      },
      { name: 'balance', title: words?.balance },
      col.userId,
      tempoptions?.docdescription && { name: 'desc', title: words?.notes },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 100 },
    { columnName: 'docNo', width: 120 },
    { columnName: col.eventNo.name, width: 120 },
    { columnName: col.contract.name, width: 200 },
    { columnName: col.customer.name, width: 200 },
    { columnName: col.employee.name, width: 200, align: 'center' },
    { columnName: col.resourse.name, width: 150 },
    { columnName: 'customerPhone', width: 200, align: 'center' },
    { columnName: 'total', width: 120 },
    { columnName: 'discount', width: 120 },
    { columnName: 'amount', width: 120 },
    { columnName: 'paid', width: 100 },
    { columnName: 'tip', width: 100 },
    { columnName: 'link', width: 100, align: 'center' },
    { columnName: 'amountPaid', width: 120 },
    { columnName: 'amountPaid2', width: 120 },
    { columnName: 'balance', width: 120 },
    { columnName: 'userId', width: 120, align: 'center' },
    { columnName: 'desc', width: 200 },
  ]);

  const [rows, setRows] = useState([]);
  const [sums, setSums] = useState(null);
  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [custvalue, setCustvalue] = useState<any>(null);
  const [adding, setAdding] = useState(false);
  const [selected, setSelected] = useState(null);

  const { tasks } = useTasks();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { customers } = useCustomers();
  const { resourses } = useResourses();
  const { services } = useServices();
  const { users } = useUsers();

  const isMobile = useMediaQuery('(max-width:600px)');

  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(SalesContext);

  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const [loadInvoices, opData]: any = useLazyQuery(getInvoices, {
    fetchPolicy: 'cache-and-network',
  });

  const refresQuery = {
    refetchQueries: [
      {
        query: getInvoices,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
          employeeId: isEmployee
            ? employeeId
            : emplvalue
            ? emplvalue?._id
            : undefined,
          customerId: custvalue ? custvalue?._id : undefined,
        },
      },
      { query: getProducts },
      { query: getTasks },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
      employeeId: isEmployee
        ? employeeId
        : emplvalue
        ? emplvalue?._id
        : undefined,
      customerId: custvalue ? custvalue?._id : undefined,
    };
    if (start && end) {
      loadInvoices({
        variables,
      });
    }
  }, [start, end, emplvalue, custvalue]);

  const [addInvoice] = useMutation(createInvoice, refresQuery);
  const [editInvoice] = useMutation(updateInvoice, refresQuery);
  const [removeInvoice] = useMutation(deleteInvoice, refresQuery);
  const [addInvoiceLink] = useMutation(createInvoiceLink, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (opData?.refetch) {
        opData.refetch();
      }
    },
  }));

  const addLink = async (id: any) => {
    setAdding(true);
    setSelected(id);
    try {
      await addInvoiceLink({ variables: { id } });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
      setAdding(false);
    }
    setSelected(null);
    setAdding(false);
  };

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeInvoice({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };
  useEffect(() => {
    if (opData?.data?.getInvoices?.data) {
      const { data } = opData.data.getInvoices;
      const rdata = updateDocNumbers(data);

      const sumdiscount = _.sumBy(rdata, 'discount');
      const sumamount = _.sumBy(rdata, 'amount');
      const sumamountPaid = _.sumBy(rdata, 'amountPaid');
      const sumamountPaid2 = _.sumBy(rdata, 'amountPaid2');
      const sumbalance = _.sumBy(rdata, 'balance');
      const sumnetamount = _.sumBy(rdata, 'netamount');
      const sumtip = tempoptions?.invoicetip ? _.sumBy(rdata, 'tip') : 0;

      setSums({
        sumdiscount,
        sumamount,
        sumamountPaid,
        sumamountPaid2,
        sumbalance,
        sumnetamount,
        sumtip,
      });
      setRows(rdata);
    }
  }, [opData]);

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'invoices', colnames);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 55,
          left: isRTL ? 330 : undefined,
          right: isRTL ? undefined : 350,
          display: 'flex',
          flexGrow: 1,
          zIndex: 111,
        }}
      >
        {!isEmployee && (
          <Box display={'flex'}>
            <AutoFieldLocal
              name="customer"
              title={words?.customer}
              words={words}
              options={customers.filter((emp: any) => !emp.disabled)}
              value={custvalue}
              setSelectValue={setCustvalue}
              isRTL={isRTL}
              width={300}
              mb={0}
            ></AutoFieldLocal>
            <Box width={20}></Box>
            <AutoFieldLocal
              name="employee"
              title={words?.employee}
              words={words}
              options={employees.filter((emp: any) => !emp.disabled)}
              value={emplvalue}
              setSelectValue={setEmplvalue}
              isRTL={isRTL}
              width={300}
              mb={0}
            ></AutoFieldLocal>
          </Box>
        )}
      </Box>
      {!isMobile && (
        <Box
          style={{
            position: 'absolute',
            zIndex: 111,
            left: isRTL ? undefined : drawerWidth + 50,
            right: isRTL ? drawerWidth + 50 : undefined,
            bottom: 50,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' المجموع' : ' Total Amount '}:{' '}
            {moneyFormat(sums?.sumamount)}
          </Typography>
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' النقدي ' : ' Cash '}: {moneyFormat(sums?.sumamountPaid)}
          </Typography>
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' البطاقة ' : ' Card '}:{' '}
            {moneyFormat(sums?.sumamountPaid2)}
          </Typography>
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' الخصومات ' : ' Total '}: {moneyFormat(sums?.sumdiscount)}
          </Typography>
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' الرصيد ' : ' Balance '}: {moneyFormat(sums?.sumbalance)}
          </Typography>
          <Typography
            style={{
              color: '#333',
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            {isRTL ? ' الصافي ' : 'Net Amount '}:{' '}
            {moneyFormat(sums?.sumnetamount)}
          </Typography>
          {tempoptions?.invoicetip && (
            <Typography
              style={{
                color: '#333',
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              {isRTL ? ' البقشيش ' : 'Tips '}: {moneyFormat(sums?.sumtip)}
            </Typography>
          )}
        </Box>
      )}

      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'time',
            'docNo',
            col.eventNo.name,
            col.customer.name,
            'customerPhone',
            col.employee.name,
            col.resourse.name,
            col.contract.name,
            'total',
            'discount',
            'amount',
            'paid',
            'amountPaid',
            'amountPaid2',
            'balance',
            col.userId.name,
            'tip',
            'link',
            'desc',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <DataTypeProvider
          for={['customerPhone']}
          formatterComponent={({ value }) => (
            <Box display={'flex'}>
              {value && <Whatsapp phone={value}></Whatsapp>}
              {value && <LinkPhone value={value}></LinkPhone>}
            </Box>
          )}
        ></DataTypeProvider>
        {userroles?.invoices?.admin ? (
          <DataTypeProvider
            for={['link']}
            formatterComponent={(props: any) => (
              <ButtonShort
                {...props}
                addLink={addLink}
                isRTL={isRTL}
                loading={adding}
                selected={selected}
              ></ButtonShort>
            )}
          ></DataTypeProvider>
        ) : (
          <DataTypeProvider
            for={['link']}
            formatterComponent={linkFormatter}
          ></DataTypeProvider>
        )}
        <DataTypeProvider
          for={['time']}
          formatterComponent={timeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['userId']}
          formatterComponent={(props: any) =>
            userFormatter({ ...props, users })
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={amountFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['paid']}
          formatterComponent={paidFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={[
            'total',
            'discount',
            'amountPaid',
            'amountPaid2',
            'balance',
            'tip',
          ]}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.invoices?.edit}
          showDeleteCommand={
            !userroles?.isEmployee && userroles?.invoices?.delete
          }
          showAddCommand={userroles?.invoices?.add}
          commandComponent={Command}
        ></TableEditColumn>

        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing addAction={addInvoice} editAction={editInvoice}>
          <PopupInvoice
            resourses={resourses}
            employees={employees}
            departments={departments}
            company={company}
            servicesproducts={services}
            tasks={tasks}
            theme={theme}
          ></PopupInvoice>
        </PopupEditing>
      </Grid>
    </>
  );
});

export default Invoices;
