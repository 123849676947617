import { gql } from '@apollo/client';

export default gql`
  mutation createMultiEmployees($data: String) {
    createMultiEmployees(data: $data) {
      ok
      message
      error
    }
  }
`;
