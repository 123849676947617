/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  GroupingState,
  SummaryState,
  IntegratedGrouping,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableGroupRow,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, updateOpDocRefNumbers, userroles } from '../../common';
import {
  calculateAmount,
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatter,
  moneyFormat,
  moneyFormatSimple,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import {
  Box,
  fade,
  Typography,
  withStyles,
  Grid as MUIGrid,
  colors,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { getMonthlyReport } from '../../graphql';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import PageLayout from '../main/PageLayout';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { ExpensesReportContext } from '../../contexts';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  useCategories,
  useCustomers,
  useDepartments,
  useEmployees,
  useExpenseItems,
  useTemplate,
} from '../../hooks';
import { SearchTable } from '../../components';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';
import { ReportGroupBySwitcher } from '../calendar/common/ReportGroupBySwitcher';
import { groupList } from '../../constants/reports';
import _ from 'lodash';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function ExpProductReport({
  isRTL,
  words,
  menuitem,
  accounts,
  theme,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [sanad, setSanad] = useState<any>(false);
  const [expense, setExpense] = useState<any>(true);

  const [rows, setRows] = useState([]);
  const [total, setTotal]: any = useState(null);
  const [count, setCount] = useState<any>(0);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const { expenseItems } = useExpenseItems();
  const { categories } = useCategories();
  const { customers } = useCustomers();
  const { departments } = useDepartments();
  const { employees } = useEmployees();

  const [columns] = useState(
    [
      col.opTime,
      col.opType,
      col.opDocNo,
      col.acc,
      col.opAcc,
      col.customer,
      col.supplier,
      tempoptions?.contract && col.contract,
      tempoptions?.employee && col.employee,
      tempoptions?.resourse && col.resourse,
      tempoptions?.department && col.department,
      tempoptions?.project && col.project,
      col.desc,
      col.service,
      { name: 'qty', title: words.qty },
      userroles?.expproductreport?.view && {
        name: 'amount',
        title: words.amount,
      },
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
    { columnName: col.amount.name, togglingEnabled: false },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getMonthlyReport, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    state: {
      currentDate,
      currentViewName,
      endDate,
      servicevalue,
      departvalue,
      resovalue,
      emplvalue,
      custvalue,
      taskvalue,
      catvalue,
      accvalue,
      group,
      groupby,
      sumcolumn,
      sort,
    },
    dispatch,
  } = useContext(ExpensesReportContext);
  const { height } = useWindowDimensions();

  const currentViewNameChange = (e: any) => {
    dispatch({ type: 'setCurrentViewName', payload: e.target.value });
  };
  const currentDateChange = (curDate: any) => {
    dispatch({ type: 'setCurrentDate', payload: curDate });
  };
  const endDateChange = (curDate: any) => {
    dispatch({ type: 'setEndDate', payload: curDate });
  };
  const setServicevalueDispatch = (value: any) => {
    dispatch({ type: 'setServicevalue', payload: value });
  };
  const setDepartvalueDispatch = (value: any) => {
    dispatch({ type: 'setDepartvalue', payload: value });
  };
  const setCatvalueDispatch = (value: any) => {
    dispatch({ type: 'setCatvalue', payload: value });
  };
  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setCustvalueDispatch = (value: any) => {
    dispatch({ type: 'setCustvalue', payload: value });
  };
  const setGroupbyDispatch = (value: any) => {
    dispatch({ type: 'setGroupby', payload: value });
  };
  const setGroupDispatch = (value: any) => {
    dispatch({ type: 'setGroup', payload: value });
  };
  const setSumcolumnDispatch = (value: any) => {
    dispatch({ type: 'setSumcolumn', payload: value });
  };
  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getMonthlyReport']?.data || [];
    const updatedRows =
      slsData?.length > 0
        ? slsData.map((item: any) => {
            return {
              ...item,
              amount: calculateAmount(item),
            };
          })
        : [];
    const rdata = updateOpDocRefNumbers(updatedRows);

    setRows(rdata);
    setTotal(_.sumBy(rdata, 'amount'));
    setCount(_.sumBy(rdata, 'qty'));
  }, [summaryData]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;
  const fetchData = () => {
    const opTypes = [];
    if (sanad) opTypes.push(12);
    if (expense) opTypes.push(61);
    const variables = {
      accPCode: 14,
      opTypes,
      accountIds: getIds(accvalue),
      serviceIds: getIds(servicevalue),
      categoryIds: getIds(catvalue),
      departmentIds: getIds(departvalue),
      employeeIds: getIds(emplvalue),
      resourseIds: getIds(resovalue),
      customerIds: getIds(custvalue),
      contractIds: getIds(taskvalue),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    group,
    groupby,
    accvalue,
    sumcolumn,
    departvalue,
    resovalue,
    emplvalue,
    custvalue,
    taskvalue,
    servicevalue,
    catvalue,
    sanad,
    expense,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `finance-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const onSwitcherChange = (e: any) => {
    if (e.target.value === 'none') {
      setGroupDispatch(false);
      setGroupbyDispatch('none');
    } else {
      setGroupDispatch(true);
      setGroupbyDispatch(e.target.value);
      setSumcolumnDispatch(col[e.target.value].name);
    }
  };

  const refresh = () => {
    summaryData?.refetch();
  };

  const totalSummaryItems = [
    { columnName: sumcolumn, type: 'count' },
    { columnName: col.category.name, type: 'count' },
    { columnName: 'amount', type: 'sum' },
  ];

  const grouping = [{ columnName: sumcolumn }];

  const groupSummaryItems = [
    {
      columnName: col.category.name,
      type: 'count',
      alignByColumn: true,
    },
    {
      columnName: 'amount',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: col.category.name,
      type: 'count',
      showInGroupFooter: true,
    },
    {
      columnName: 'amount',
      type: 'sum',
      showInGroupFooter: true,
    },
  ];

  const disgroup = [];
  if (!tempoptions?.project) disgroup.push(10);
  if (!tempoptions?.contract) disgroup.push(8);
  if (!tempoptions?.resourse) disgroup.push(11);
  if (!tempoptions?.department) disgroup.push(3);
  disgroup.push(6, 7, 12, 13);
  const groupOptions = groupList(isRTL).filter(
    (item: any) => !disgroup.includes(item.id)
  );

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      loading={summaryData?.loading}
      height={99}
    >
      <Box
        style={{
          height: height - 100,
          overflow: 'auto',
          backgroundColor: '#fff',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <Box
          display="flex"
          style={{
            position: 'absolute',
            top: 97,
            zIndex: 111,
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
          <FilterSelectMulti
            options={categories.filter((dep: any) => dep.catType === 10)}
            value={catvalue}
            setValue={setCatvalueDispatch}
            words={words}
            isRTL={isRTL}
            name="category"
            label={isRTL ? 'التصنيف' : 'Category'}
            width={240}
            fullwidth
          ></FilterSelectMulti>
          <FilterSelectMulti
            options={expenseItems}
            value={servicevalue}
            setValue={setServicevalueDispatch}
            words={words}
            isRTL={isRTL}
            name="expenseitem"
            label={isRTL ? 'البنود' : 'Items'}
            width={240}
            fullwidth
          ></FilterSelectMulti>
          <Box
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 3,
              width: 200,
            }}
          >
            <ReportGroupBySwitcher
              options={groupOptions}
              value={groupby}
              onChange={onSwitcherChange}
              isRTL={isRTL}
            ></ReportGroupBySwitcher>
          </Box>
        </Box>
        <Box
          display="flex"
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '70%',
            paddingLeft: isRTL ? 20 : 240,
            paddingRight: isRTL ? 240 : 20,
            marginTop: -95,
          }}
        >
          <MUIGrid container spacing={1}>
            <MUIGrid item xs={4}>
              <FilterSelectMulti
                options={customers}
                value={custvalue}
                setValue={setCustvalueDispatch}
                words={words}
                isRTL={isRTL}
                name="customer"
                fullwidth
              ></FilterSelectMulti>
            </MUIGrid>
            <MUIGrid item xs={4}>
              <FilterSelectMulti
                options={departments}
                value={departvalue}
                setValue={setDepartvalueDispatch}
                words={words}
                isRTL={isRTL}
                name="department"
                fullwidth
              ></FilterSelectMulti>
            </MUIGrid>
            <MUIGrid item xs={3}>
              <FilterSelectMulti
                options={employees}
                value={emplvalue}
                setValue={setEmplvalueDispatch}
                words={words}
                isRTL={isRTL}
                name="employee"
                fullwidth
              ></FilterSelectMulti>
            </MUIGrid>
            <MUIGrid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={expense}
                    onChange={(e: any) => setExpense(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={isRTL ? 'استهلاك منتجات' : 'Product Expenses'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sanad}
                    onChange={(e: any) => setSanad(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={isRTL ? 'تسليم منتجات' : 'Sale Products'}
              />
            </MUIGrid>
          </MUIGrid>
        </Box>
        {userroles?.expproductreport?.view && (
          <Box
            style={{
              position: 'absolute',
              zIndex: 111,
              right: isRTL ? undefined : 100,
              left: isRTL ? 100 : undefined,
              top: 15,
            }}
            display="flex"
          >
            <Typography
              style={{
                fontWeight: 'bold',
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              {isRTL ? 'العدد' : 'Quantity'}: {moneyFormatSimple(count)}
            </Typography>
            <Typography
              style={{
                fontWeight: 'bold',
                color: total >= 0 ? colors.blue[800] : colors.red[800],
              }}
            >
              {isRTL ? 'المجموع' : 'Total'}: {moneyFormat(total)}
            </Typography>
          </Box>
        )}
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            defaultSorting={sort}
            onSortingChange={(srt: any) => setSortDispatch(srt)}
          />
          {group && <GroupingState grouping={grouping} />}
          {group && (
            <SummaryState
              totalItems={totalSummaryItems}
              groupItems={groupSummaryItems}
            />
          )}
          {group && <IntegratedGrouping />}
          {group && <IntegratedSummary />}
          <IntegratedSorting />
          <SearchState />
          <IntegratedFiltering />
          <VirtualTable
            height={height - 150}
            tableComponent={TableComponent}
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            estimatedRowHeight={40}
          />
          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableColumnVisibility
            columnExtensions={tableColumnVisibilityColumnExtensions}
            onHiddenColumnNamesChange={(hcs: string[]) => {
              const all = [...columns];
              const newcol = all.filter((a: any) => !hcs.includes(a.name));
              newcol.sort((a: any, b: any) =>
                a.id > b.id ? 1 : b.id > a.id ? -1 : 0
              );
            }}
            defaultHiddenColumnNames={[
              col.opDocNo.name,
              col.employee.name,
              col.resourse.name,
              col.department.name,
              col.project.name,
              col.rased.name,
            ]}
          />
          <DataTypeProvider
            for={['opTime']}
            formatterComponent={createdAtFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['amount']}
            formatterComponent={currencyFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['opType']}
            formatterComponent={opTypeFormatter}
          ></DataTypeProvider>
          <Toolbar />
          <ColumnChooser />
          <SearchPanel
            inputComponent={(props: any) => {
              return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
            }}
          />
          <ExportPanel startExport={startExport} />
          {group && <TableGroupRow showColumnsWhenGrouped />}
          {group && (
            <TableGroupRow
              messages={{
                sum: isRTL ? 'المجموع' : 'Total',
                count: isRTL ? 'العدد' : 'Count',
                sumOf: isRTL ? 'المجموع' : 'Total',
                countOf: isRTL ? 'العدد' : 'Count',
              }}
              showColumnsWhenGrouped
            />
          )}
          {group && (
            <TableSummaryRow
              messages={{
                sum: isRTL ? 'المجموع' : 'Total',
                count: isRTL ? 'العدد' : 'Count',
              }}
            ></TableSummaryRow>
          )}
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
        />
      </Box>
    </PageLayout>
  );
}
