import { gql } from '@apollo/client';

export default gql`
  mutation createProcItem(
    $branch: String
    $name: String
    $nameAr: String
    $disabled: Boolean
    $additionId: String
    $pcategoryId: String
    $pcategoryName: String
    $pcategoryNameAr: String
  ) {
    createProcItem(
      branch: $branch
      name: $name
      nameAr: $nameAr
      disabled: $disabled
      additionId: $additionId
      pcategoryId: $pcategoryId
      pcategoryName: $pcategoryName
      pcategoryNameAr: $pcategoryNameAr
    ) {
      ok
      message
      data
      error
    }
  }
`;
