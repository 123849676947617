/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dublicateAlert, errorAlert, yup, PopupTextField } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Box, Button, Typography } from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import PopupPassword from './PopupPassword';
import { errorAlertMsg } from '../Shared/helpers';
import UserRolesEmail from '../Shared/UserRolesEmail';
import { useLazyQuery } from '@apollo/client';
import checkUsername from '../graphql/query/checkUsername';
import { isValidEmail } from '../common/helpers';
import { TextFieldLocal } from '../components';
import _ from 'lodash';
import { badmintemplate, newrolestemplate } from '../constants/roles';
import { userroles } from '../common';

const search = _.debounce(({ checkUser, username }) => {
  checkUser({ variables: { username } });
}, 300);

const PopupUserEmail = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
  editPassword,
  block,
  company,
  employees,
  emplnoaccount,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [blockUser, setBlockUser] = useState(false);

  const [openPass, setOpenPass] = useState(false);

  const [newroles, setNewroles] = useState(newrolestemplate);

  const [isAccountant, setisAccountant] = useState(false);
  const [isBranchAdmin, setisBranchAdmin] = useState(false);
  const [isBranchsAdmin, setisBranchsAdmin] = useState(false);
  const [isEmployee, setisEmployee] = useState(false);
  const [emplvalue, setEmplvalue] = useState<any>(null);

  const [username, setUsername] = useState(null);
  const [valid, setValid] = useState(null);
  const [checkUser, userData] = useLazyQuery(checkUsername);
  const { register, handleSubmit, errors, reset } = useForm(
    isNew ? yup.addUserResolver : undefined
  );
  const {
    translate: { words, isRTL },
    store: { user, themeId },
  }: GContextTypes = useContext(GlobalContext);

  const onUsernameChange = async (e: any) => {
    setUsername(e.target.value);
  };
  useEffect(() => {
    search({ checkUser, username });
  }, [username]);

  useEffect(() => {
    if (isNew) {
      if (userData?.data?.checkUsername?.ok && isValidEmail(username)) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (row && row._id) {
      setBlockUser(row.block);
      setisAccountant(row.isAccountant);
      setisBranchAdmin(row.isBranchAdmin);
      setisBranchsAdmin(row.isBranchsAdmin);
      setisEmployee(row.isEmployee);
      if (row?.employeeId) {
        const dept = employees.filter(
          (dep: any) => dep._id === row?.employeeId
        )?.[0];
        setEmplvalue(dept);
      }
      if (row?.newroles) {
        const parsenewroles = JSON.parse(row?.newroles);
        const nnewroles = newroles.map((role: any) => {
          const samerole = parsenewroles.filter(
            (r: any) => r.name === role.name
          )?.[0];
          if (samerole) {
            return { ...samerole };
          } else {
            return { ...role };
          }
        });
        setNewroles(nnewroles);
      }
    }
  }, [open]);

  const setItemValue = ({ item, name, value }: any) => {
    const sroles = newroles;
    const nsroles = sroles.map((it: any) => {
      if (it?.name === item?.name) {
        return {
          ...it,
          [name]: value,
        };
      } else {
        return it;
      }
    });
    setNewroles(nsroles);
  };
  const setGroupValue = (name: any) => {
    const item = newroles.filter((nr: any) => nr.ref === name);
    const isTrue = item?.[0]?.view === true;
    const sroles = newroles;
    const reftemplate = isTrue ? newrolestemplate : badmintemplate;
    const nsroles = sroles.map((it: any) => {
      if (it?.ref === name) {
        const samerole = reftemplate.filter(
          (r: any) => r.name === it.name
        )?.[0];
        if (samerole) {
          return samerole;
        } else {
          return it;
        }
      } else {
        return it;
      }
    });
    setNewroles(nsroles);
  };

  const onClosePass = () => setOpenPass(false);
  const onSubmit = async (data: any) => {
    if (isNew && !valid) {
      await errorAlertMsg(setAlrt, 'valid email require');
      return;
    }
    if (user.isSuperAdmin !== true) {
      if (row.isSuperAdmin === true || row.isSuperAdmin === 'true') {
        await errorAlertMsg(setAlrt, "You can't change this account");
        return;
      }
    }
    if (isEmployee && !emplvalue) {
      await errorAlertMsg(setAlrt, 'You have to choose an Employee');
      return;
    }
    setSaving(true);
    const name = data?.name;
    const phone = data?.phone;
    const password = data?.password;
    const title = data?.title;
    const fname = emplvalue && isEmployee ? emplvalue?.name : name;
    const fphone = emplvalue && isEmployee ? emplvalue?.phone : phone;
    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      username,
      name: fname,
      phone: fphone,
      password: isNew ? password : undefined,
      newroles: newroles ? JSON.stringify(newroles) : undefined,
      title,
      isBranchsAdmin,
      isBranchAdmin,
      isAccountant,
      isEmployee,
      employee:
        emplvalue && isEmployee
          ? {
              employeeId: emplvalue._id,
              employeeName: emplvalue.name,
              employeeNameAr: emplvalue.nameAr,
              employeeColor: emplvalue.color,
              employeePhone: emplvalue.phone,
            }
          : {
              employeeId: undefined,
              employeeName: undefined,
              employeeNameAr: undefined,
              employeeColor: undefined,
              employeePhone: undefined,
            },
      branch: company?.basename,
      foundationId: company?.foundationId,
      themeId,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      onFormClose();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onBlockUser = () => {
    const variables = {
      _id: row._id,
      block: !blockUser,
    };
    block({ variables });
  };

  const onFormClose = () => {
    reset();
    setisBranchAdmin(false);
    setisAccountant(false);
    setisEmployee(false);
    setEmplvalue(null);
    setUsername(null);
    setValid(null);
    setSaving(false);
    setNewroles(newrolestemplate);
    onClose();
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const renderButton = (ref: any, title: any) => {
    return (
      <Button
        onClick={() => setGroupValue(ref)}
        style={{ minWidth: 100, marginTop: 10 }}
        variant="outlined"
      >
        <Typography>{title}</Typography>
      </Button>
    );
  };

  const title = isRTL
    ? isNew
      ? 'اضافة مستخدم'
      : 'تعديل بيانات مستخدم'
    : isNew
    ? 'New User'
    : 'Edit User';
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onFormClose}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      mb={50}
      saving={saving}
      maxWidth="lg"
    >
      <Grid container spacing={0}>
        <Grid item xs={1}>
          {(userroles?.isBranchAdmin ||
            userroles?.isSuperAdmin ||
            userroles?.isBranchsAdmin) &&
            !isEmployee &&
            !isBranchAdmin && (
              <Grid item xs={1}>
                <Box
                  style={{
                    marginTop: 250,
                  }}
                >
                  {renderButton('opr', isRTL ? 'العمليات' : 'Operations')}
                  {renderButton('sal', isRTL ? 'المبيعات' : 'Sales')}
                  {renderButton('pur', isRTL ? 'المشتريات' : 'Purchase')}
                  {renderButton('exp', isRTL ? 'المصروفات' : 'Expenses')}
                  {renderButton('fin', isRTL ? 'الحسابات' : 'Finances')}
                </Box>
              </Grid>
            )}
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex" style={{ flexDirection: 'row' }}>
                <TextFieldLocal
                  autoFocus
                  required
                  name="username"
                  label={words.email}
                  value={username}
                  onChange={onUsernameChange}
                  row={row}
                  disabled={!isNew}
                  fullWidth
                  mb={0}
                />
                {isNew && (
                  <Box
                    style={{
                      marginTop: 15,
                      marginLeft: 10,
                      marginRight: 10,
                      width: 28,
                      height: 28,
                      borderRadius: 14,
                      backgroundColor: valid ? '#b6fcd5' : '#ffc0cb',
                    }}
                  ></Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              {isNew && (
                <PopupTextField
                  required
                  name="password"
                  label={words.password}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  type="password"
                />
              )}
            </Grid>
            {!isEmployee && (
              <Grid item xs={4}>
                <PopupTextField
                  required
                  name="name"
                  label={words.name}
                  register={register}
                  errors={errors}
                  fullWidth
                  row={row}
                />
              </Grid>
            )}
            {!isEmployee && (
              <Grid item xs={4}>
                <PopupTextField
                  name="phone"
                  label={words.phoneNumber}
                  register={register}
                  errors={errors}
                  fullWidth
                  row={row}
                />
              </Grid>
            )}
            {!isEmployee && (
              <Grid item xs={4}>
                <PopupTextField
                  name="title"
                  label={isRTL ? 'نوع الحساب' : 'Account Type'}
                  register={register}
                  errors={errors}
                  fullWidth
                  row={row}
                />
              </Grid>
            )}

            {user &&
              (userroles?.isBranchAdmin ||
                userroles?.isBranchsAdmin ||
                userroles?.isSuperAdmin) && (
                <Grid item xs={12}>
                  <UserRolesEmail
                    isRTL={isRTL}
                    branch={user?.branch}
                    words={words}
                    newroles={newroles}
                    setItemValue={setItemValue}
                    isAccountant={isAccountant}
                    isBranchAdmin={isBranchAdmin}
                    isBranchsAdmin={isBranchsAdmin}
                    isEmployee={isEmployee}
                    emplvalue={emplvalue}
                    setisBranchAdmin={setisBranchAdmin}
                    setisAccountant={setisAccountant}
                    setisBranchsAdmin={setisBranchsAdmin}
                    setisEmployee={setisEmployee}
                    setEmplvalue={setEmplvalue}
                    employees={emplnoaccount}
                    isNew={isNew}
                  ></UserRolesEmail>
                </Grid>
              )}
          </Grid>

          <Box
            display="flex"
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          ></Box>
          <Box
            display="flex"
            style={{ alignItems: 'center', justifyContent: 'flex-end' }}
          >
            {!isNew && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenPass(true)}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                {isRTL ? 'تغيير كلمة المرور' : 'Change Password'}
              </Button>
            )}
            {!row.isBranchAdmin &&
              !row.isSuperAdmin &&
              !row.isBranchsAdmin &&
              !isNew && (
                <Button
                  color={blockUser ? 'secondary' : 'primary'}
                  onClick={onBlockUser}
                  variant="outlined"
                >
                  {blockUser
                    ? isRTL
                      ? 'تفعيل الحساب'
                      : 'Unblock user'
                    : isRTL
                    ? 'تعطيل الحساب'
                    : 'Block user'}
                </Button>
              )}
          </Box>
        </Grid>
        <PopupPassword
          open={openPass}
          onClose={onClosePass}
          row={row}
          editPassword={editPassword}
          theme={theme}
        ></PopupPassword>
      </Grid>
    </PopupLayout>
  );
};

export default PopupUserEmail;
