import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useState } from 'react';
import { userroles } from '../../../constants';
import { createFinance, getLandingChartData } from '../../../../graphql';
import PopupPaymentAdvance from '../../../../pubups/PopupPaymentAdvance';
import IconView from '../IconView';

function PayAdvIcon({ isRTL, theme, color, height, company }) {
  const [openPayAd, setOpenPayAd] = useState(false);

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };

  const [addFinance] = useMutation(createFinance, refresQuery);

  return (
    <Box>
      {userroles?.paymentsadvance?.add && (
        <>
          <IconView
            color={color ? color : theme.palette.primary.main}
            icon="balance"
            title={isRTL ? 'دفع سلفة' : 'Advanced Pay'}
            height={height}
            onOpen={() => setOpenPayAd(true)}
          ></IconView>

          <PopupPaymentAdvance
            open={openPayAd}
            onClose={() => setOpenPayAd(false)}
            isNew={true}
            addAction={addFinance}
            editAction={() => null}
            theme={theme}
            company={company}
          ></PopupPaymentAdvance>
        </>
      )}
    </Box>
  );
}

export default PayAdvIcon;
