import { Grid } from '@material-ui/core';
import { useClassnames } from './clasnames';
import { renderCell } from './renders';

function PrintPaperForm({ template, data, isRTL }) {
  const classes = useClassnames();
  if (!template || !data) return;
  return (
    <div>
      {template?.map((listitem: any) => {
        return (
          <Grid container spacing={0}>
            {listitem?.map((item: any) => {
              const { size, skip, type } = item;
              const args = {
                ...item,
                classes,
                data,
                item,
                isRTL,
              };
              return (
                <>
                  {skip > 0 && <Grid item xs={skip}></Grid>}
                  <Grid item xs={size}>
                    {renderCell(type, args, classes, true)}
                  </Grid>
                </>
              );
            })}
          </Grid>
        );
      })}
    </div>
  );
}

export default PrintPaperForm;
