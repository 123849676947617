/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
  Table,
  TableColumnResizing,
  DragDropProvider,
  TableColumnReordering,
} from '@devexpress/dx-react-grid-material-ui';
import { PopupEditing } from '../../Shared';
import {
  updateDocNumbers,
  getRowId,
  LinkPhone,
  LinkLocation,
} from '../../common';
import {
  createEvent,
  createMultiEvents,
  deleteEventById,
  getEmployees,
  getEvents,
  updateEvent,
} from '../../graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  absentFormatter,
  createdAtFormatter,
  currencyFormatter,
  eventStatusFormatter,
  eventTimeFormatter,
  fromToFormatter,
  moneyFormat,
  nameLinkFormat,
  userFormatter,
} from '../../Shared/colorFormat';
import { SearchTable } from '../../components';
import { getColumns } from '../../common/columns';
import PopupAppointment from '../../pubups/PopupAppointment';
import { EventsContext, GlobalContext } from '../../contexts';
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import useTasks from '../../hooks/useTasks';
import { Getter } from '@devexpress/dx-react-core';
import { TableComponent } from '../../Shared/TableComponent';
import { useCustomers, useServices, useTemplate, useUsers } from '../../hooks';
import PopupDepartmentView from '../../pubups/PopupDepartmentView';
import PopupEmployeeView from '../../pubups/PopupEmployeeView';
import PopupTaskView from '../../pubups/PopupTaskView';
import PopupCustomerView from '../../pubups/PopupCustomerView';
import useResourses from '../../hooks/useResourses';
import useEmployees from '../../hooks/useEmployees';
import useDepartments from '../../hooks/useDepartments';
import useRetypes from '../../hooks/useRetypes';
import PopupResoursesView from '../../pubups/PopupResoursesView';
import AutoFieldLocal from '../../components/fields/AutoFieldLocal';
import { CommandEvent } from '../../Shared/CommandEvent';
import Whatsapp from '../../common/Whatsapp';
import { userroles } from '../../common/check';
import PopupGroupAppointments from '../../pubups/PopupGroupAppointments';
import PopupAppointmentMulti from '../../pubups/PopupAppointmentMulti';
import {
  createInvoicesFromEvents,
  statusMultiEvents,
} from '../../graphql/mutation';
import _ from 'lodash';
import ButtonDone from '../../common/ButtonDone';
import ButtonInvoice from '../../common/ButtonInvoice';
import CheckFilter from '../../Shared/CheckFilter';
import { sleep } from '../../Shared/helpers';
import { qtyFormat } from '../../Sections/generator/common/helpers';

const Appointments = forwardRef((props: any, ref: any) => {
  const {
    isRTL,
    words,
    theme,
    company,
    isEmployee,
    employeeId,
    drawerWidth,
    start,
    end,
  } = props;

  const [due, setDue] = useState(false);
  const [duetoday, setDuetoday] = useState(false);
  const [single, setSingle] = useState(false);
  const [noinvoice, setNoinvoice] = useState(false);

  const [total, setTotal] = useState(null);

  const col = getColumns({ isRTL, words });

  const { tempoptions, tempwords, eventoptions } = useTemplate();
  const [columns] = useState(
    [
      col.createdAt,
      col.startDate,
      col.docNo,
      eventoptions?.retype && tempoptions?.retype && col.retype,
      eventoptions?.retype2 && tempoptions?.retype2 && col.retype2,
      eventoptions?.title && col.title,
      col.customer,
      col.customerPhone,
      tempoptions?.resourse && col.resourse,
      tempoptions?.contract && col.contract,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      eventoptions?.bonos && col.bonos,
      eventoptions?.overtime && col.overtime,
      eventoptions?.absent && col.absent,
      eventoptions?.location && col.locationLink,
      eventoptions?.location && col.address,
      eventoptions?.location && col.houseno,
      eventoptions?.noinvoicecheck && col.invoiced,
      eventoptions?.noinvoicecheck && col.invoiceId,
      col.cashin,
      col.userId,
      col.done,
      userroles?.appointments?.add && col.amount,
      tempoptions?.docdescription && { name: 'desc', title: words?.notes },
    ].filter((x) => x)
  );
  const isMobile = useMediaQuery('(max-width:600px)');

  const [tableColumnExtensions]: any = useState([
    { columnName: 'createdAt', width: 100 },
    { columnName: 'startDate', width: 200 },
    { columnName: 'docNo', width: 120 },
    { columnName: col.title.name, width: 180 },
    { columnName: col.customer.name, width: 180 },
    { columnName: col.customerPhone.name, width: 200, align: 'center' },
    { columnName: col.contract.name, width: 180 },
    { columnName: col.resourse.name, width: 180 },
    { columnName: col.employee.name, width: 180, align: 'center' },
    { columnName: col.department.name, width: 150 },
    { columnName: col.retype.name, width: 150 },
    { columnName: col.retype2.name, width: 150 },
    { columnName: 'bonos', width: 100 },
    { columnName: 'overtime', width: 100 },
    { columnName: 'absent', width: 100 },
    { columnName: 'locationLink', width: 100, align: 'center' },
    { columnName: 'address', width: 120, align: 'center' },
    { columnName: 'houseno', width: 100 },
    { columnName: 'userId', width: 120, align: 'center' },
    { columnName: 'done', width: 100, align: 'center' },
    { columnName: 'invoiced', width: 100, align: 'center' },
    { columnName: 'invoiceId', width: 100 },
    { columnName: 'amount', width: 100 },
    { columnName: 'cashin', width: 100 },
    { columnName: 'desc', width: 200 },
  ]);

  const {
    store: { calendar },
  } = useContext(GlobalContext);

  const [rows, setRows] = useState([]);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selected, setSelected] = useState(null);
  const [rtypvalue, setRtypvalue] = useState<any>(null);
  const [rtype2value, setRtype2value] = useState<any>(null);
  const [custvalue, setCustvalue] = useState<any>(null);
  const [emplvalue, setEmplvalue] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [name, setName] = useState(null);
  const [openMulti, setOpenMulti] = useState(false);
  const [openTaskItem, setOpenTaskItem] = useState(false);
  const [openCustomerItem, setOpenCustomerItem] = useState(false);
  const [openEmployeeItem, setOpenEmployeeItem] = useState(false);
  const [openResourseItem, setOpenResourseItem] = useState(false);
  const [openDepartmentItem, setOpenDepartmentItem] = useState(false);
  const [openForInvoice, setOpenForInvoice] = useState(false);
  const [openForDone, setOpenForDone] = useState(false);
  const { retypes } = useRetypes();

  const { tasks } = useTasks();
  const { customers } = useCustomers();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { services } = useServices();
  const { users } = useUsers();
  const onCloseTaskItem = () => {
    setOpenTaskItem(false);
    setItem(null);
    setName(null);
  };

  const onCloseCustomerItem = () => {
    setOpenCustomerItem(false);
    setItem(null);
    setName(null);
  };
  const onCloseEmployeeItem = () => {
    setOpenEmployeeItem(false);
    setItem(null);
    setName(null);
  };
  const onCloseResourseItem = () => {
    setOpenResourseItem(false);
    setItem(null);
    setName(null);
  };
  const onCloseDepartmentItem = () => {
    setOpenDepartmentItem(false);
    setItem(null);
    setName(null);
  };

  const setTaskItem = (data: any) => {
    const cont = tasks.filter((co: any) => co._id === data.contractId)?.[0];
    if (cont) {
      setItem(cont);
      setName('contract');
    }
  };
  const setEmployeeItem = (data: any) => {
    const empl = employees.filter((em: any) => em._id === data.employeeId)?.[0];
    if (empl) {
      setItem(empl);
      setName('employee');
    }
  };
  const setResourseItem = (data: any) => {
    const empl = resourses.filter((em: any) => em._id === data.resourseId)?.[0];
    if (empl) {
      setItem(empl);
      setName('resourse');
    }
  };
  const setDepartmentItem = (data: any) => {
    const empl = departments.filter(
      (em: any) => em._id === data.departmentId
    )?.[0];
    if (empl) {
      setItem(empl);
      setName('department');
    }
  };
  const setCustomerItem = (data: any) => {
    const empl = customers.filter((em: any) => em._id === data.customerId)?.[0];
    if (empl) {
      setItem(empl);
      setName('customer');
    }
  };

  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(EventsContext);

  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const [loadEvents, eventsData]: any = useLazyQuery(getEvents, {
    fetchPolicy: 'cache-and-network',
  });
  const refresQuery = {
    refetchQueries: [
      {
        query: getEvents,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
          due,
          // noinvoice,
          employeeId: isEmployee ? employeeId : undefined,
        },
      },
      { query: getEmployees },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
      due,
      // noinvoice,
      employeeId: isEmployee ? employeeId : undefined,
    };
    if (start && end) {
      loadEvents({
        variables,
      });
    }
  }, [start, end, due /*noinvoice*/]);

  const [addEvent] = useMutation(createEvent, refresQuery);
  const [addMultiEvents] = useMutation(createMultiEvents, refresQuery);
  const [editEvent] = useMutation(updateEvent, refresQuery);
  const [removeEventById] = useMutation(deleteEventById, refresQuery);
  const [addInvoicesEvents] = useMutation(
    createInvoicesFromEvents,
    refresQuery
  );
  const [statusEvents] = useMutation(statusMultiEvents, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (eventsData?.refetch) {
        eventsData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeEventById({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };
  useEffect(() => {
    if (eventsData?.data?.getEvents?.data) {
      const { data } = eventsData.data.getEvents;
      const rdata = updateDocNumbers(data);
      let fdata = rdata;
      if (single) {
        fdata = fdata.filter((r: any) => !r.groupId);
      }
      if (rtypvalue) {
        fdata = fdata.filter((da: any) => da?.retypeId === rtypvalue?._id);
      }
      if (rtype2value) {
        fdata = fdata.filter((da: any) => da?.retype2Id === rtype2value?._id);
      }
      if (emplvalue) {
        fdata = fdata.filter((da: any) => da?.employeeId === emplvalue?._id);
      }
      if (custvalue) {
        fdata = fdata.filter((da: any) => da?.customerId === custvalue?._id);
      }
      if (noinvoice) {
        fdata = fdata.filter(
          (r: any) => r?.invoiced !== true && r?.status === 10
        );
      }
      if (duetoday) {
        fdata = fdata.filter((r: any) => r?.status !== 10);
      }
      setRows(fdata);
      const sumamount = _.sumBy(fdata, 'amount');
      setTotal(sumamount);
    }
  }, [
    eventsData,
    rtypvalue,
    rtype2value,
    emplvalue,
    custvalue,
    noinvoice,
    duetoday,
    single,
  ]);

  useEffect(() => {
    if (name === 'department') {
      if (departments && departments.length > 0) {
        const opened = departments.filter(
          (ts: any) => ts._id === item._id
        )?.[0];
        setItem(opened);
      }
    }
    if (name === 'employee') {
      if (employees && employees.length > 0) {
        const opened = employees.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
    if (name === 'resourse') {
      if (resourses && resourses.length > 0) {
        const opened = resourses.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
    if (name === 'customer') {
      if (customers && customers.length > 0) {
        const opened = customers.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
  }, [departments, employees, customers]);

  const doneEvent = async (id: any) => {
    setEditing(true);
    setSelected(id);
    try {
      const variables = { id, status: 10 };
      await editEvent({ variables });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
      setEditing(false);
    }
    await sleep(3);
    setSelected(null);
    setEditing(false);
  };
  const crtInvoice = async (id: any) => {
    setCreating(true);
    setSelected(id);
    try {
      await addInvoicesEvents({ variables: { eventIds: [id], group: false } });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
      setCreating(false);
    }
    await sleep(3);
    setSelected(null);
    setCreating(false);
  };

  const canViewManage = userroles?.appointments?.admin;

  return (
    <>
      <Box
        style={{
          display: 'fle',
          position: 'absolute',
          zIndex: 120,
          right: isRTL ? undefined : 400,
          left: isRTL ? 400 : undefined,
          top: 7,
        }}
      >
        {!isMobile && eventoptions?.multievents && (
          <Button
            variant="contained"
            color="primary"
            style={{ width: 120, height: 32, marginLeft: 30, marginRight: 30 }}
            onClick={() => setOpenMulti(true)}
          >
            <Typography
              style={{
                fontSize: isRTL ? 14 : 12,
                fontWeight: isRTL ? 'bold' : undefined,
              }}
            >
              {isRTL ? 'موعد متعدد' : 'Multi Events'}
            </Typography>
          </Button>
        )}
        {eventoptions?.duetoday && (
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 120, height: 32, marginLeft: 15 }}
            onClick={() => setOpenForDone(true)}
          >
            <Typography
              style={{
                fontSize: isRTL ? 14 : 12,
                fontWeight: isRTL ? 'bold' : undefined,
              }}
            >
              {isRTL ? 'المتابعة للإنجاز' : 'Processing'}
            </Typography>
          </Button>
        )}
        {tempoptions?.groupinvoice && (
          <Button
            variant="outlined"
            color="primary"
            style={{ width: 120, height: 32, marginLeft: 15 }}
            onClick={() => setOpenForInvoice(true)}
          >
            <Typography
              style={{
                fontSize: isRTL ? 14 : 12,
                fontWeight: isRTL ? 'bold' : undefined,
              }}
            >
              {isRTL ? 'اصدار فواتير' : 'Invoices'}
            </Typography>
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 55,
          left: isRTL ? 330 : undefined,
          right: isRTL ? undefined : 350,
          display: 'flex',
          flexGrow: 1,
          zIndex: 111,
        }}
      >
        {!isMobile && eventoptions?.dueeventscheck && (
          <CheckFilter
            value={due}
            setVlaue={setDue}
            title={
              isRTL
                ? `${tempwords?.appointments} المتأخرة`
                : `Due ${tempwords?.appointments}`
            }
          ></CheckFilter>
        )}
        {!isMobile && eventoptions?.duetoday && (
          <CheckFilter
            value={duetoday}
            setVlaue={setDuetoday}
            title={isRTL ? 'غير منجز' : 'Due Today'}
          ></CheckFilter>
        )}

        {!isMobile && eventoptions?.noinvoicecheck && (
          <CheckFilter
            value={noinvoice}
            setVlaue={setNoinvoice}
            title={isRTL ? 'بدون فواتير' : 'Not Invoiced'}
          ></CheckFilter>
        )}
        {eventoptions?.noinvoicecheck && (
          <CheckFilter
            value={single}
            setVlaue={setSingle}
            title={isRTL ? `${tempwords?.appointments} فردية` : `Single`}
          ></CheckFilter>
        )}
        {!isMobile && (
          <Box
            display="flex"
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {tempoptions?.retype && eventoptions?.retypefilter && (
              <AutoFieldLocal
                name="retype"
                title={tempwords?.evretype}
                options={retypes.filter((d: any) => d.reType === 4)}
                words={words}
                value={rtypvalue}
                setSelectValue={setRtypvalue}
                isRTL={isRTL}
                width={180}
                mb={7}
              ></AutoFieldLocal>
            )}
            <Box style={{ width: 10 }}></Box>
            {tempoptions?.retype && eventoptions?.retype2filter && (
              <AutoFieldLocal
                name="retype2"
                title={tempwords?.evretype2}
                words={words}
                options={retypes.filter((dep: any) => dep.reType === 5)}
                value={rtype2value}
                setSelectValue={setRtype2value}
                isRTL={isRTL}
                width={180}
                mb={7}
              ></AutoFieldLocal>
            )}
            <Box style={{ width: 10 }}></Box>
            {eventoptions?.customerfilter && (
              <AutoFieldLocal
                name="customer"
                title={tempwords?.customer}
                words={words}
                options={customers}
                value={custvalue}
                setSelectValue={setCustvalue}
                isRTL={isRTL}
                width={180}
                mb={7}
              ></AutoFieldLocal>
            )}
            <Box style={{ width: 10 }}></Box>
            {eventoptions?.employeefilter && (
              <AutoFieldLocal
                name="employee"
                title={tempwords?.employee}
                words={words}
                options={employees}
                value={emplvalue}
                setSelectValue={setEmplvalue}
                isRTL={isRTL}
                width={180}
                mb={7}
              ></AutoFieldLocal>
            )}
          </Box>
        )}
      </Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 50,
          right: isRTL ? drawerWidth + 50 : undefined,
          bottom: 50,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography style={{ color: '#333' }}>
          {isRTL ? ' العدد' : ' Qty '}: {qtyFormat(rows?.length)}
        </Typography>
        <Typography
          style={{
            color: '#333',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {isRTL ? ' المجموع' : ' Total '}: {moneyFormat(total)}
        </Typography>
      </Box>
      <>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState />
          <EditingState onCommitChanges={commitChanges} />
          <SearchState />
          <PagingState defaultCurrentPage={0} defaultPageSize={20} />
          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <DragDropProvider />

          <Table
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            tableComponent={TableComponent}
            rowComponent={(props: any) => (
              <Table.Row {...props} style={{ height: 50 }}></Table.Row>
            )}
            columnExtensions={tableColumnExtensions}
          />
          <TableColumnReordering
            defaultOrder={[
              col.createdAt.name,
              col.startDate.name,
              col.fromto.name,
              col.docNo.name,
              col.title.name,
              col.customer.name,
              col.customerPhone.name,
              col.contract.name,
              col.employee.name,
              col.department.name,
              col.retype.name,
              col.retype2.name,
              col.resourse.name,
              col.bonos.name,
              col.overtime.name,
              col.absent.name,
              col.amount.name,
              col.cashin.name,
              col.done.name,
              col.invoiced.name,
              col.locationLink.name,
              col.address.name,
              col.houseno.name,
              col.invoiceId.name,
              col.userId.name,
              'desc',
            ]}
          />
          <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableColumnVisibility
            defaultHiddenColumnNames={hiddenColumnNames}
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <DataTypeProvider
            for={['customerPhone']}
            formatterComponent={({ value }) => (
              <Box display={'flex'}>
                {value && <Whatsapp phone={value}></Whatsapp>}
                {value && <LinkPhone value={value}></LinkPhone>}
              </Box>
            )}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['fromto']}
            formatterComponent={fromToFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['userId']}
            formatterComponent={(props: any) =>
              userFormatter({ ...props, users })
            }
          ></DataTypeProvider>
          <DataTypeProvider
            for={['locationLink']}
            formatterComponent={({ value }) => {
              return <LinkLocation value={value} isRTL={isRTL}></LinkLocation>;
            }}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['createdAt']}
            formatterComponent={createdAtFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['startDate']}
            formatterComponent={eventTimeFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['status']}
            formatterComponent={eventStatusFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['amount', 'bonos', 'cashin']}
            formatterComponent={currencyFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['absent']}
            formatterComponent={absentFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['done']}
            formatterComponent={(props: any) => (
              <ButtonDone
                {...props}
                doneEvent={doneEvent}
                isRTL={isRTL}
                loading={editing}
                selected={selected}
              ></ButtonDone>
            )}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['invoiced']}
            formatterComponent={(props: any) => (
              <ButtonInvoice
                {...props}
                isRTL={isRTL}
                crtInvoice={crtInvoice}
                loading={creating}
                selected={selected}
              ></ButtonInvoice>
            )}
          ></DataTypeProvider>
          {canViewManage && (
            <DataTypeProvider
              for={['contractNameAr', 'contractName']}
              formatterComponent={(props: any) =>
                nameLinkFormat({
                  ...props,
                  setItem: setTaskItem,
                  setOpenItem: setOpenTaskItem,
                })
              }
            ></DataTypeProvider>
          )}
          {canViewManage && (
            <DataTypeProvider
              for={['employeeNameAr', 'employeeName']}
              formatterComponent={(props: any) =>
                nameLinkFormat({
                  ...props,
                  setItem: setEmployeeItem,
                  setOpenItem: setOpenEmployeeItem,
                })
              }
            ></DataTypeProvider>
          )}
          {canViewManage && (
            <DataTypeProvider
              for={['resourseNameAr', 'resourseName']}
              formatterComponent={(props: any) =>
                nameLinkFormat({
                  ...props,
                  setItem: setResourseItem,
                  setOpenItem: setOpenResourseItem,
                })
              }
            ></DataTypeProvider>
          )}
          {canViewManage && (
            <DataTypeProvider
              for={['departmentNameAr', 'departmentName']}
              formatterComponent={(props: any) =>
                nameLinkFormat({
                  ...props,
                  setItem: setDepartmentItem,
                  setOpenItem: setOpenDepartmentItem,
                })
              }
            ></DataTypeProvider>
          )}
          {canViewManage && (
            <DataTypeProvider
              for={['customerNameAr', 'customerName']}
              formatterComponent={(props: any) =>
                nameLinkFormat({
                  ...props,
                  setItem: setCustomerItem,
                  setOpenItem: setOpenCustomerItem,
                })
              }
            ></DataTypeProvider>
          )}
          <TableColumnVisibility
            defaultHiddenColumnNames={hiddenColumnNames}
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <TableEditColumn
            showEditCommand={userroles?.appointments?.edit}
            showDeleteCommand={userroles?.appointments?.delete}
            showAddCommand={userroles?.appointments?.add}
            commandComponent={CommandEvent}
          ></TableEditColumn>

          <Toolbar
            rootComponent={(props: any) => (
              <Toolbar.Root
                style={{
                  position: 'absolute',
                  left: isRTL ? 40 : undefined,
                  right: isRTL ? undefined : 40,
                  top: 62,
                  zIndex: 115,
                }}
                {...props}
              ></Toolbar.Root>
            )}
          />
          <ColumnChooser />
          <PagingPanel
            pageSizes={
              tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
            }
          />

          <SearchPanel
            inputComponent={(props: any) => {
              return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
            }}
          />

          <PopupEditing addAction={addEvent} editAction={editEvent}>
            <PopupAppointment
              employees={employees}
              resourses={resourses}
              departments={departments}
              company={company}
              servicesproducts={services}
              theme={theme}
              tasks={tasks}
              calendar={calendar}
            ></PopupAppointment>
          </PopupEditing>
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                {
                  key: 'editCommand',
                  type: TableEditColumn.COLUMN_TYPE,
                  width: 120,
                },
                ...tableColumns.filter(
                  (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
              ];
              return result;
            }}
          />
        </Grid>
      </>
      <PopupAppointmentMulti
        open={openMulti}
        onClose={() => setOpenMulti(false)}
        addAction={addMultiEvents}
        employees={employees}
        resourses={resourses}
        departments={departments}
        company={company}
        servicesproducts={services}
        theme={theme}
        tasks={tasks}
        calendar={calendar}
      ></PopupAppointmentMulti>
      <PopupGroupAppointments
        open={openForInvoice}
        onClose={setOpenForInvoice}
        theme={theme}
        isRTL={isRTL}
        words={words}
        data={rows.filter((r: any) => r?.invoiced !== true && r?.status === 10)}
        tempoptions={tempoptions}
        eventoptions={eventoptions}
        name="addInvoicesEvents"
        addInvoicesEvents={addInvoicesEvents}
      ></PopupGroupAppointments>
      <PopupGroupAppointments
        open={openForDone}
        onClose={setOpenForDone}
        theme={theme}
        isRTL={isRTL}
        words={words}
        data={rows.filter((r: any) => r?.status !== 10)}
        tempoptions={tempoptions}
        eventoptions={eventoptions}
        name="statusEvents"
        statusEvents={statusEvents}
      ></PopupGroupAppointments>
      <PopupDepartmentView
        open={openDepartmentItem}
        onClose={onCloseDepartmentItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupDepartmentView>
      <PopupEmployeeView
        open={openEmployeeItem}
        onClose={onCloseEmployeeItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupEmployeeView>
      <PopupResoursesView
        open={openResourseItem}
        onClose={onCloseResourseItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupResoursesView>
      <PopupTaskView
        open={openTaskItem}
        onClose={onCloseTaskItem}
        item={item}
        tasks={tasks}
        isNew={false}
        theme={theme}
      ></PopupTaskView>
      <PopupCustomerView
        open={openCustomerItem}
        onClose={onCloseCustomerItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupCustomerView>
    </>
  );
});

export default Appointments;
