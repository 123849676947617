/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from 'react';

import { GContextTypes } from '../../types';
import { GlobalContext } from '../../contexts';
import { Grid } from '@material-ui/core';
import {
  useCustomers,
  useEmployees,
  useProducts,
  useServices,
} from '../../hooks';
import useContacts from '../../hooks/useContacts';
import { useLazyQuery } from '@apollo/client';
import { getOperationItems } from '../../graphql';
import _ from 'lodash';
import {
  dublicateAlert,
  errorAlert,
  indexTheList,
  successAlert,
} from '../common/funcs';
import PopupLayout from './PopupLayout';
import FormPaper from '../generator/form/FormPaper';
import { useReactToPrint } from 'react-to-print';
import PrintDownloadForm from './PrintDownloadForm';

const PopupFormOperation = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
  formdoc,
  words,
  formTempValues,
  company,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [submitdata, setSubmitdata]: any = useState({});
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [totals, setTotals] = useState<any>({});
  const [itemsList, setItemsList] = useState<any>([]);

  const {
    translate: { isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const { customers } = useCustomers();
  const { contacts } = useContacts();
  const { products } = useProducts();
  const { services } = useServices();
  const { employees } = useEmployees();

  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });

  if (formTempValues && !_.isEmpty(formTempValues) && _.isEmpty(submitdata)) {
    const time = new Date();
    setSubmitdata({ time, ...formTempValues });
  }

  useEffect(() => {
    if (row && row?._id) {
      getItems({ variables: { opId: row?._id } });

      const time = row?.time;
      const title = row?.title;
      const desc = row?.desc;
      const signatureName = row?.signatureName;
      const signatureImage = row?.signatureImage;
      let customer: any;
      let employee: any;
      let contact: any;
      const custId = row.customerId;
      const emplId = row.employeeId;
      const contId = row.contactId;
      if (custId) {
        const cust = customers.filter((it: any) => it._id === custId)[0];
        customer = cust;
        setSubmitdata({ ...submitdata, customer: cust });
      }
      if (emplId) {
        const empl = employees.filter((it: any) => it._id === emplId)[0];
        employee = empl;
        setSubmitdata({ ...submitdata, customer: empl });
      }
      if (contId) {
        const cont = contacts.filter((it: any) => it._id === contId)[0];
        contact = cont;
      }
      setSubmitdata({
        ...submitdata,
        time,
        title,
        desc,
        signatureName,
        signatureImage,
        customer,
        employee,
        contact,
      });
    } else {
      setSubmitdata({});
    }
  }, [row, customers, contacts, formTempValues]);

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum;
    const profit = sum - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
  };

  useEffect(() => {
    getOverallTotal();
  }, [itemsList]);

  useEffect(() => {
    if (row && row._id) {
      const items = itemsData?.data?.['getOperationItems']?.data || [];

      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...services, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );
        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            projectId,
            projectName,
            projectNameAr,
            note,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            projectId,
            projectName,
            projectNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            note,
            // itemtotalcost: item.qty * serv.cost,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        setItemsList(itemsWqtyprice);
      }
    }
  }, [itemsData]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const onSubmit = async () => {
    setSaving(true);
    const contvalue = submitdata?.contact;
    const emplvalue = submitdata?.employee;
    const contact = contvalue
      ? {
          contactId: contvalue._id,
          contactName: contvalue.name,
          contactNameAr: contvalue.nameAr,
        }
      : {
          contactId: undefined,
          contactName: undefined,
          contactNameAr: undefined,
        };
    const employee = emplvalue
      ? {
          employeeId: emplvalue._id,
          employeeName: emplvalue.name,
          employeeNameAr: emplvalue.nameAr,
        }
      : {
          employeeId: undefined,
          employeeName: undefined,
          employeeNameAr: undefined,
        };
    const items = itemsList?.length > 0 ? JSON.stringify(itemsList) : undefined;

    const variables: any = {
      _id: row?._id ? row._id : undefined, // is it new or edit
      time: submitdata?.time ? submitdata?.time : undefined,
      contact,
      employee,
      title: submitdata?.title ? submitdata?.title : undefined,
      desc: submitdata?.desc ? submitdata?.desc : undefined,
      signatureName: submitdata?.signatureName
        ? submitdata?.signatureName
        : undefined,
      signatureImage: submitdata?.signatureImage
        ? submitdata?.signatureImage
        : undefined,
      opType: formdoc?.opType,
      autoNoType: formdoc?.autoNoType,
      autoNoPrefix: formdoc?.autoNoPrefix,
      dcategoryId: formdoc?.dcategoryId,
      dcategoryName: formdoc?.dcategoryName,
      dcategoryNameAr: formdoc?.dcategoryNameAr,
      gref: formdoc?.gref,
      items,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createFormOperation' : 'updateFormOperation';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      closeModal();
      await successAlert(setAlrt, isRTL);
    } catch (error) {
      onError(error);
    }
  };

  const closeModal = () => {
    setSaving(false);
    setSubmitdata({});
    setTotals({});
    setItemsList([]);
    onClose();
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${formdoc?.autoNoType || document} #${
      formdoc?.docNo || ''
    }`,
    removeAfterPrint: true,
  });
  const printData = row;
  const template = formdoc?.printTempDoc
    ? JSON.parse(formdoc?.printTempDoc)
    : [];

  const title = isRTL
    ? isNew
      ? `اضافة ${formdoc?.nameAr}`
      : `تعديل ${formdoc?.nameAr}`
    : isNew
    ? `New ${formdoc?.name}`
    : `Edit ${formdoc?.name}`;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      maxWidth={'md'}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormPaper
            template={formdoc?.formTempDoc}
            isRTL={isRTL}
            words={words}
            variables={submitdata}
            setVariables={setSubmitdata}
            user={user}
            setAlrt={setAlrt}
            itemsList={itemsList}
            totals={totals}
            addItemToList={addItemToList}
            editItemInList={editItemInList}
            removeItemFromList={removeItemFromList}
          ></FormPaper>
        </Grid>
        {row && row?._id && (
          <Grid item md={12}>
            <PrintDownloadForm
              company={company}
              printData={printData}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              isRTL={isRTL}
              template={template}
              no={row?.docNo}
              element="formdoc"
              isNew={isNew}
            ></PrintDownloadForm>
          </Grid>
        )}
      </Grid>
    </PopupLayout>
  );
};

export default PopupFormOperation;
