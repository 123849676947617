import { gql } from '@apollo/client';

export default gql`
  mutation updateProc(
    $_id: String
    $indx: Int
    $prevprocId: String
    $inbox: String
    $outbox: String
    $outboxName: String
    $start: Date
    $end: Date
    $title: String
    $desc: String
    $priority: Int
    $pitemId: String
    $pitemName: String
    $pitemNameAr: String
    $pcategoryId: String
    $pcategoryName: String
    $pcategoryNameAr: String
    $opId: String
    $opType: Int
    $opTime: Date
    $opDocNo: String
    $opDoc: String
    $attachments: String
    $isDone: Boolean
    $department: DepartmentInput
    $employee: EmployeeInput
    $resourse: ResourseInput
    $contract: ContractInput
    $customer: CustomerInput
    $supplier: SupplierInput
    $contact: ContactInput
    $note: String
  ) {
    updateProc(
      _id: $_id
      indx: $indx
      prevprocId: $prevprocId
      inbox: $inbox
      outbox: $outbox
      outboxName: $outboxName
      start: $start
      end: $end
      title: $title
      desc: $desc
      priority: $priority
      pitemId: $pitemId
      pitemName: $pitemName
      pitemNameAr: $pitemNameAr
      pcategoryId: $pcategoryId
      pcategoryName: $pcategoryName
      pcategoryNameAr: $pcategoryNameAr
      opId: $opId
      opType: $opType
      opTime: $opTime
      opDocNo: $opDocNo
      opDoc: $opDoc
      attachments: $attachments
      isDone: $isDone
      department: $department
      employee: $employee
      resourse: $resourse
      contract: $contract
      customer: $customer
      supplier: $supplier
      contact: $contact
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
