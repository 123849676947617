/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import {
  Box,
  Button,
  Checkbox,
  colors,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import PopupTaskInvoice from './PopupTaskInvoice';
import { taskManamentTabs } from '../constants/rrule';
import EventsCustomer from '../Shared/EventsCustomer';
import InvoicesCustomer from '../Shared/InvoicesCustomer';
import ReceiptCustomer from '../Shared/ReceiptCustomer';
import {
  createEvent,
  createExpenses,
  createFinance,
  getExpenses,
  getOperationItems,
  getProjects,
} from '../graphql';
import getTasks from '../graphql/query/getTasks';
import { useReactToPrint } from 'react-to-print';
import KaidsCustomer from '../Shared/KaidsCustomer';
import {
  useCustomers,
  useExpenseItems,
  useServices,
  useTemplate,
} from '../hooks';
import PopupCloseDate from './PopupCloseDate';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import { TabPanel, useStyles, a11yProps } from '../Shared/TabPanel';
import ReminderCustomer from '../Shared/ReminderCustomer';
import MainCustomer from '../Shared/MainCustomer';
import PopupReceipt from './PopupReceipt';
import getReceipts from '../graphql/query/getReceipts';
import ExpensesCustomer from '../Shared/ExpensesCustomer';
import PopupExpensesDoc from './PopupExpensesDoc';
import DateNavigatorReports from '../components/filters/DateNavigatorReports';
import InvoicesSupplier from '../Shared/InvoicesSupplier';
import PaymentSupplier from '../Shared/PaymentSupplier';
import ExpensesProdCustomer from '../Shared/ExpensesProdCustomer';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import { SVContractPrint } from '../print/SVContractPrint';
import { ContractPrint } from '../print';
import ExpensesItemsCustomer from '../Shared/ExpensesItemsCustomer';
import { userroles } from '../common';
import { getNextStartEnd, getReadyEventData } from '../common/helpers';
import getObjectEvents from '../graphql/query/getObjectEvents';
import PopupExtendDate from './PopupExtendDate';
import EventsItemsCustomer from '../Shared/EventsItemsCustomer';
import InvoicesItemsCustomer from '../Shared/InvoicesItemsCustomer';

const PopupTaskView = ({
  open,
  onClose,
  item,
  tasks,
  isNew,
  theme,
  company,
  stopTask,
  startTask,
  mstart,
  mend,
}: any) => {
  const classes = useStyles();
  const [row, setRow] = useState(item);
  const [value, setValue] = React.useState(0);
  const [del, setDel] = React.useState(true);
  const [event, setEvent] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);

  const [custvalue, setCustvalue] = useState(null);
  const [resovalue, setResovalue] = useState(null);
  const [closeloading, setCloseloading] = useState<any>(null);

  const [openCloseDate, setOpenCloseDate] = useState<any>(false);
  const [openExtendDate, setOpenExtendDate] = useState<any>(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [openExpenses, setOpenExpenses] = useState(false);
  const [calcs, setCalcs] = useState<any>(null);
  const [start, setStart]: any = useState(null);
  const [end, setEnd]: any = useState(null);
  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isTime, setIsTime] = useState(false);
  const [evtime, setEvtime]: any = useState();

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };
  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { services } = useServices();
  const { customers } = useCustomers();
  const { expenseItems } = useExpenseItems();

  const { width, height } = useWindowDimensions();
  const { tempoptions, tempwords } = useTemplate();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (item?._id && tasks && tasks.length > 0) {
      const opened = tasks.filter((ts: any) => ts._id === item._id)?.[0];
      setRow(opened);
    }
  }, [tasks]);

  useEffect(() => {
    if (row && row._id) {
      const custId = row.customerId;
      const resId = row.resourseId;
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }
      if (custId) {
        const cust = customers.filter((cu: any) => cu._id === custId)[0];
        setCustvalue(cust);
      }
    }
  }, [row, customers, resourses]);
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const refresReceiptQuery = {
    refetchQueries: [
      {
        query: getReceipts,
        variables: {
          contractId: row?._id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getGereralCalculation,
        variables: {
          contractId: row?._id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getTasks,
        variables: {
          contractId: row?._id,
          start: mstart ? new Date(mstart).setHours(0, 0, 0, 0) : undefined,
          end: mend ? new Date(mend).setHours(23, 59, 59, 999) : undefined,
        },
      },
      { query: getTasks },
      { query: getProjects },
    ],
  };
  const refresEventQuery = {
    refetchQueries: [
      {
        query: getGereralCalculation,
        variables: {
          contractId: row?._id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getTasks,
        variables: {
          contractId: row?._id,
          start: mstart ? new Date(mstart).setHours(0, 0, 0, 0) : undefined,
          end: mend ? new Date(mend).setHours(23, 59, 59, 999) : undefined,
        },
      },
      { query: getTasks },
      { query: getProjects },
    ],
  };
  const refresExpensesQuery = {
    refetchQueries: [
      {
        query: getExpenses,
        variables: {
          contractId: row?._id,
          opType: 60,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getGereralCalculation,
        variables: {
          contractId: row?._id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getTasks,
        variables: {
          contractId: row?._id,
          start: mstart ? new Date(mstart).setHours(0, 0, 0, 0) : undefined,
          end: mend ? new Date(mend).setHours(23, 59, 59, 999) : undefined,
        },
      },

      { query: getTasks },
      { query: getProjects },
    ],
  };

  const [addFinance] = useMutation(createFinance, refresReceiptQuery);

  const [addExpenses] = useMutation(createExpenses, refresExpensesQuery);
  const [addEvent] = useMutation(createEvent, refresEventQuery);

  const [getEvents, eventsData]: any = useLazyQuery(getObjectEvents, {
    fetchPolicy: 'cache-and-network',
  });

  const [getEventItems, eventItemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const variables = { taskId: row?.id };
    getEvents({ variables });
  }, [row?.id]);

  useEffect(() => {
    const data = eventsData?.data?.['getObjectEvents']?.data;
    const events = data || [];
    if (events?.length > 0) {
      const ev = events[events.length - 1];
      getEventItems({ variables: { opId: ev._id } });
      setEvent(ev);
    }
  }, [eventsData, item]);
  useEffect(() => {
    if (event && row) {
      const { startDate } = getNextStartEnd({ contract: row, event });
      setEvtime(startDate);
    }
  }, [row, event]);

  const addNewEvent = async () => {
    if (!event) return;
    setLoading(true);
    const variables = getReadyEventData(
      event,
      row,
      eventItemsData,
      services,
      evtime
    );
    if (!variables) {
      setLoading(false);
      return;
    }
    await addEvent({ variables });
    await getEvents({ variables: { taskId: row?.id } });
    setLoading(false);
  };

  const toCloseTask = async (time: any) => {
    setCloseloading(true);

    await stopTask({
      variables: {
        _id: row._id,
        time,
        del,
      },
    });
    setCloseloading(false);
  };
  const toStartTask = async () => {
    setCloseloading(true);
    await startTask({
      variables: {
        _id: row._id,
      },
    });
    setCloseloading(false);
  };

  const printData = {
    ...row,
    calcs,
    no: row?.docNo,
    start,
    end,
    resovalue,
    custvalue,
    // info,
    isRTL: isRTL,
  };

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Contract #${row?.docNo}`,
    removeAfterPrint: true,
  });

  const resetAllForms = () => {
    setEvent(null);
    setValue(0);
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
  };
  const title = `${tempwords?.task} : ${row?.title}`;
  const isSmartVision =
    company?.publicKey ===
    'BJFzgQWBP8m5YThe_JwVoS6g5EEKypJWy-8ZkW5yI5b2rJQu1uJ5dBnio86IgoSRWq7AsVif2WGtYmaKvPB6QJ0';

  const renderMain = () => {
    return (
      <MainCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        value={row}
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        setCalcs={setCalcs}
      ></MainCustomer>
    );
  };
  const renderEvent = () => {
    return (
      <EventsCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        id={row?._id}
        name="contractId"
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
        tempoptions={tempoptions}
      ></EventsCustomer>
    );
  };
  const renderEventItems = () => {
    return (
      <EventsItemsCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        tempoptions={tempoptions}
      ></EventsItemsCustomer>
    );
  };
  const renderInvoice = () => {
    return (
      <InvoicesCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
        tempoptions={tempoptions}
      ></InvoicesCustomer>
    );
  };
  const renderInvoicesItems = () => {
    return (
      <InvoicesItemsCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        tempoptions={tempoptions}
      ></InvoicesItemsCustomer>
    );
  };
  const renderReceipt = () => {
    return (
      <ReceiptCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
      ></ReceiptCustomer>
    );
  };

  const renderPurchase = () => {
    return (
      <InvoicesSupplier
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
        tempoptions={tempoptions}
      ></InvoicesSupplier>
    );
  };
  const renderPayment = () => {
    return (
      <PaymentSupplier
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
      ></PaymentSupplier>
    );
  };
  const renderExpense = () => {
    return (
      <ExpensesCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
        tempoptions={tempoptions}
        tempwords={tempwords}
      ></ExpensesCustomer>
    );
  };
  const renderExpenseItems = () => {
    return (
      <ExpensesItemsCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        tempoptions={tempoptions}
      ></ExpensesItemsCustomer>
    );
  };
  const renderExpenseProd = () => {
    return (
      <ExpensesProdCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        value={row}
        company={company}
        tempoptions={tempoptions}
        tempwords={tempwords}
      ></ExpensesProdCustomer>
    );
  };
  const renderKaids = () => {
    return (
      <KaidsCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        tempoptions={tempoptions}
      ></KaidsCustomer>
    );
  };
  const renderReminder = () => {
    return (
      <ReminderCustomer
        isRTL={isRTL}
        words={words}
        theme={theme}
        name="contractId"
        value={row}
        id={row?._id}
        width={width}
        height={height}
        start={isTime ? start : null}
        end={isTime ? end : null}
        mstart={mstart}
        mend={mend}
        tempoptions={tempoptions}
      ></ReminderCustomer>
    );
  };
  const filterTabs = taskManamentTabs.filter((item: any) => !item.hide);

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={() => null}
      theme={theme}
      alrt={{}}
      maxWidth={'xl'}
      print={
        !isNew && [4, 5, 7, 8].includes(company?.tempId)
          ? handleReactPrint
          : undefined
      }
      mb={0}
      mt={0}
      onlyclose
      preventclose
      canceltitle={isRTL ? 'اغلاق' : 'close'}
    >
      <Box>
        <Box display="flex" style={{ backgroundColor: '#fff', height: 50 }}>
          <Box display="flex" style={{ padding: 7 }}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 7 }}
                  checked={isTime}
                  onChange={() => {
                    setIsTime(!isTime);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  {isRTL ? 'تفعيل التاريخ' : 'Activate Date'}
                </Typography>
              }
              style={{ fontSize: 14 }}
            />
          </Box>
          <Box
            style={{
              opacity: !isTime ? 0.5 : undefined,
              pointerEvents: !isTime ? 'none' : undefined,
            }}
          >
            <DateNavigatorReports
              setStart={setStart}
              setEnd={setEnd}
              currentDate={currentDate}
              currentDateChange={currentDateChange}
              currentViewName={currentViewName}
              currentViewNameChange={currentViewNameChange}
              endDate={endDate}
              endDateChange={endDateChange}
              views={[1, 7, 30, 365, 1000]}
              isRTL={isRTL}
              words={words}
              theme={theme}
            ></DateNavigatorReports>
          </Box>
        </Box>
        <Box style={{ display: 'flex', marginTop: 0 }}>
          <Grid container spacing={0} style={{ width: width - 300 }}>
            <Grid item xs={12}>
              <Box
                style={{
                  backgroundColor: '#f5f5f5',
                }}
              >
                <Box display="flex" style={{}}></Box>
                {row && (
                  <Paper
                    elevation={0}
                    style={{ marginBottom: 10, backgroundColor: '#f5f5f5' }}
                  >
                    {filterTabs.map((item: any, index: any) => {
                      return (
                        <TabPanel value={value} index={index}>
                          {item.ref === 'main' && renderMain()}
                          {item.ref === 'events' && renderEvent()}
                          {item.ref === 'eventsitems' && renderEventItems()}
                          {item.ref === 'invoices' && renderInvoice()}
                          {item.ref === 'invoicesitems' &&
                            renderInvoicesItems()}
                          {item.ref === 'receipts' && renderReceipt()}
                          {item.ref === 'purchases' && renderPurchase()}
                          {item.ref === 'payments' && renderPayment()}
                          {item.ref === 'expenses' && renderExpense()}
                          {item.ref === 'expensesitems' && renderExpenseItems()}
                          {item.ref === 'expensesprod' && renderExpenseProd()}
                          {item.ref === 'entries' && renderKaids()}
                          {item.ref === 'reminders' && renderReminder()}
                        </TabPanel>
                      );
                    })}
                  </Paper>
                )}
              </Box>
            </Grid>
          </Grid>
          {row && (
            <Box style={{ width: 200 }}>
              <Box>
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="items"
                  className={classes.tabs}
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { width: 3 } }}
                  textColor="primary"
                  centered
                >
                  {filterTabs.map((item: any) => {
                    if (item.hide) {
                      return <div></div>;
                    }
                    return (
                      <Tab
                        style={{
                          backgroundColor:
                            value === item.id ? '#f5f5f5' : undefined,
                        }}
                        label={
                          <Typography
                            style={{ fontWeight: 'bold', fontSize: 13 }}
                          >
                            {isRTL ? item.nameAr : item.name}
                          </Typography>
                        }
                        {...a11yProps(item.id)}
                      />
                    );
                  })}
                </Tabs>
              </Box>

              <Box style={{ paddingLeft: 10, paddingRight: 10, marginTop: 30 }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => setOpenInvoice(true)}
                  style={{ backgroundColor: colors.blue[500] }}
                >
                  <Typography style={{ fontSize: 14 }}>
                    {words.newInvoice}
                  </Typography>
                </Button>
              </Box>
              <Box style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10 }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{ backgroundColor: colors.green[500] }}
                  onClick={() => setOpenReceipt(true)}
                >
                  <Typography style={{ fontSize: 14 }}>
                    {isRTL ? 'اضافة سند قبض' : 'New Receipt'}
                  </Typography>
                </Button>
              </Box>
              <Box style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10 }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => setOpenExpenses(true)}
                  style={{ backgroundColor: colors.red[500] }}
                >
                  <Typography style={{ fontSize: 14 }}>
                    {isRTL ? 'اضافة مصروف' : 'New Expenses'}
                  </Typography>
                </Button>
              </Box>
              {userroles?.tasks?.edit && (
                <Box style={{ padding: 10 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={loading || row.isClosed}
                    onClick={() => setOpenExtendDate(true)}
                  >
                    <Typography style={{ fontSize: 14 }}>
                      {words.newPeriod}
                    </Typography>
                  </Button>
                </Box>
              )}
              {userroles?.tasks?.edit && (
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: 10,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      closeloading ||
                      row?.isClosed ||
                      row?.status === 'لم يبدأ بعد' ||
                      row?.status === 'Not Started'
                    }
                    onClick={() => setOpenCloseDate(true)}
                    style={{ height: 30 }}
                  >
                    <Typography style={{ fontSize: 14 }}>
                      {isRTL ? 'اقفال' : 'Disactivate'}
                    </Typography>
                  </Button>
                  {userroles?.tasks?.admin && (
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      disabled={closeloading || !row?.isClosed}
                      onClick={toStartTask}
                      style={{ height: 30 }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        {isRTL ? 'تفعيل' : 'Activate'}
                      </Typography>
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          )}
          {row && (
            <PopupTaskInvoice
              open={openInvoice}
              onClose={() => setOpenInvoice(false)}
              task={row}
              customers={customers}
              services={services}
              resourses={resourses}
              employees={employees}
              departments={departments}
              company={company}
              theme={theme}
              mstart={start}
              mend={end}
            ></PopupTaskInvoice>
          )}
          {row && (
            <PopupReceipt
              open={openReceipt}
              onClose={() => setOpenReceipt(false)}
              task={row}
              isNew={true}
              addAction={addFinance}
              editAction={() => null}
              theme={theme}
              company={company}
              name="customerId"
              value={custvalue}
            ></PopupReceipt>
          )}
          {row && (
            <PopupExpensesDoc
              open={openExpenses}
              onClose={() => setOpenExpenses(false)}
              task={row}
              isNew={true}
              addAction={addExpenses}
              editAction={() => null}
              resourses={resourses}
              employees={employees}
              departments={departments}
              servicesproducts={expenseItems}
              theme={theme}
              company={company}
              name="contractId"
              value={row}
            ></PopupExpensesDoc>
          )}
          {row && (
            <PopupCloseDate
              open={openCloseDate}
              onClose={() => setOpenCloseDate(false)}
              toCloseTask={toCloseTask}
              theme={theme}
              isRTL={isRTL}
              title={`${words.shutdown} ${tempwords?.task}`}
              minDate={new Date(row.start)}
              maxDate={new Date()}
              del={del}
              setDel={setDel}
              tasktype={row?.tasktype}
            ></PopupCloseDate>
          )}
          {row && (
            <PopupExtendDate
              open={openExtendDate}
              onClose={() => setOpenExtendDate(false)}
              addNewEvent={addNewEvent}
              theme={theme}
              isRTL={isRTL}
              title={words.newPeriod}
              evtime={evtime}
              setEvtime={setEvtime}
            ></PopupExtendDate>
          )}
          <Box>
            <div style={{ display: 'none' }}>
              {isSmartVision ? (
                <SVContractPrint
                  company={company}
                  user={user}
                  printData={printData}
                  ref={componentRef}
                />
              ) : (
                <ContractPrint
                  company={company}
                  user={user}
                  printData={printData}
                  ref={componentRef}
                />
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </PopupLayout>
  );
};

export default PopupTaskView;
