import { gql } from '@apollo/client';

export default gql`
  query getReportContracts(
    $departmentIds: [String]
    $employeeIds: [String]
    $projectIds: [String]
    $resourseIds: [String]
    $customerIds: [String]
    $createtime: Boolean
    $status: Int
    $start: Date
    $end: Date
  ) {
    getReportContracts(
      departmentIds: $departmentIds
      employeeIds: $employeeIds
      projectIds: $projectIds
      resourseIds: $resourseIds
      customerIds: $customerIds
      createtime: $createtime
      status: $status
      start: $start
      end: $end
    ) {
      ok
      error
      message
      count
      data {
        _id
        branch
        autoNo
        docNo
        title
        desc

        id
        name
        nameAr
        type
        freq
        count
        interval
        weekdays
        monthdays
        many
        start
        end
        progress
        isDisabled
        tasktype
        typefree
        periodType
        periodCost
        dayCost
        location {
          lat
          lng
        }
        priority
        amount
        evQty
        evAmount
        evDoneAmount
        evDone

        periodfrom

        status
        totalinvoiced
        totalDiscount
        totalPurchaseInvoiced
        totalPurchasePaid
        totalPurchaseDiscount
        toatlProdExpenses
        totalpaid
        toatlExpenses
        totalkaidsdebit
        totalKaidscredit
        balance

        customerId
        customerName
        customerNameAr
        customerPhone

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone

        projectId
        projectName
        projectNameAr
        projectColor
        resourseId
        resourseName
        resourseNameAr
        resourseColor

        isClosed

        userId
        note
        info

        createdAt
        updatedAt
      }
    }
  }
`;
