import { Box, colors, Grid, Paper, Typography } from '@material-ui/core';
import {
  daysoffFormatter,
  nameToColor,
  renderTag,
} from '../../../common/helpers';

export default function EmployeeDataBx({ row, height, isRTL }) {
  const name = row?.name;
  const nameAr = row?.nameAr;
  const phone = row?.phone;
  const email = row?.email;
  const daysoff = row?.daysoff;
  const retypeId = row?.retypeId;
  const retypeNameAr = row?.retypeNameAr;
  const retypeName = row?.retypeName;
  const telHome = row?.telHome;
  const licenseNo = row?.licenseNo;
  const licenseDate = row?.licenseDate;
  const national = row?.national;
  const nationalNo = row?.nationalNo;
  const nationalDate = row?.nationalDate;
  const workId = row?.workId;
  const avatar = row?.avatar;
  const bgcolor = name ? nameToColor(`${name}Jadwal2!@`) : '';
  const letter = name?.substring(0, 1)?.toUpperCase() || '';
  const avatarh = 100;
  return (
    <Paper style={{ height, padding: 20 }}>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          {avatar && (
            <Paper
              elevation={2}
              style={{
                overflow: 'hidden',
                borderRadius: 10,
                backgroundColor: '#fff',
                width: avatarh,
                height: avatarh,
              }}
            >
              <img
                style={{
                  overflow: 'hidden',
                  borderRadius: 10,
                  objectFit: 'cover',
                }}
                alt={name}
                width={avatarh}
                height={avatarh}
                src={avatar}
              />
            </Paper>
          )}
          {!avatar && (
            <Box
              style={{
                overflow: 'hidden',
                borderRadius: 10,
                backgroundColor: bgcolor,
                width: avatarh,
                height: avatarh,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                style={{ color: '#eee', fontWeight: 'bold' }}
                variant="h4"
              >
                {letter}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{isRTL ? nameAr : name}</Typography>
            </Grid>
            {workId && (
              <Grid item xs={6}>
                {renderTag({
                  value: workId,
                  color: '#333',
                  bgcolor: colors.blueGrey[50],
                  width: 100,
                })}
              </Grid>
            )}
            {retypeId && (
              <Grid item xs={6}>
                {renderTag({
                  value: isRTL ? retypeNameAr : retypeName,
                  color: '#333',
                  bgcolor: colors.blueGrey[50],
                  width: 100,
                })}
              </Grid>
            )}
            {daysoff && (
              <Grid item xs={12}>
                <Typography variant="caption">
                  <Typography variant="caption">
                    {isRTL ? 'يوم العطلة' : 'Day Off'}:{' '}
                    {daysoffFormatter({ value: row.daysoff, isRTL })}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        {phone && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'الهاتف : ' : 'Phone : '} {phone}
            </Typography>
          </Grid>
        )}
        {telHome && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'هاتف دولي : ' : 'Interntional : '} {telHome}
            </Typography>
          </Grid>
        )}
        {licenseDate && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {licenseDate}
            </Typography>
          </Grid>
        )}
        {licenseNo && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'الرخصة : ' : 'License : '} {licenseNo}
            </Typography>
          </Grid>
        )}

        {nationalNo && (
          <Grid item xs={4}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {nationalNo}
            </Typography>
          </Grid>
        )}
        {nationalDate && (
          <Grid item xs={4}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {nationalDate}
            </Typography>
          </Grid>
        )}
        {national && (
          <Grid item xs={4}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'الجنسية : ' : 'Nationality : '} {national}
            </Typography>
          </Grid>
        )}
        {email && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'البريد : ' : 'Email : '} {email}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
