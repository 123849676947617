// 1 general
// 2 salon
// 3 education
// 4 rentcar
// 5 cleaning
// 6 contracting
// 7 realestate
// 8 services
// 9 delivery

export const templates = [
  {
    id: 1,
    title: 'general',
    name: 'General Template',
    nameAr: 'النموذج العام',
    options: {
      project: false,
      contract: false,
      resourse: false,
      employee: true,
      department: false,
      retype: false,
      retype2: false,
      calendar: false,
      emplcalendar: false,
      expenses: true,
      purchase: false,
      sms: false,
      reminder: false,
      itemdepartempl: false,
      docrelations: true,
      itemrelations: false,
      itemdescription: false,
      invoiceitemrelations: false,
      expensesitemrelations: false,
      purchaseitemrelations: false,
      proexpensesitemrelations: false,
      cashinvoice: false,
      additionId: false,
      barcode: false,
      events: false,
      brand: false,
      category: true,
      section: false,
      accountant: true,
      notification: true,
      contracting: false,
      products: false,
      collectiveitem: false,
      rentitem: false,
      productsdef: false,
      docdescription: true,
      invoicetip: false,
      tirms: false,
      emailnotification: false,
      groupinvoice: false,
      shortinvoice: false,
      resoursesavailable: false,
      customersmenu: false,
      employeesmenu: false,
      resoursesmenu: false,
      departmentsmenu: false,
      dublicatename: true,
      dublicatephone: true,
      shortemployee: false,
      shortcustomer: false,
      nameonelang: false,
      taskendlastevent: false,
      livedata: false,
      tempevent: false,
      pageall: false,
      pinvoiceattach: false,
      expenseattach: false,
      receiptattach: false,
      eventattach: false,
      formdocs: false,
      procedures: false,
      calendarclock: false,
      dochirtory: false,
      letters: false,
      presales: false,
      reports: true,
      operations: true,
      sales: true,
      finance: true,
      mail: false,
      pos: false,
      posprod: false,
      posserv: false,
      contractsalescalc: false,
    },
    calendaroptions: {
      emplfilter: true,
      departfilter: true,
      statusfilter: true,
    },
    taskoptions: {
      oneday: false,
      info: false,
      dayCost: false,
      note: true,
      endrequire: false,
      notefyonend: false,
      events: false,
      items: true,
      itemsrequire: false,
      customer: true,
      customerrequire: true,
      employee: true,
      employeerequire: false,
      department: true,
      resourse: true,
      resourserequire: false,
      desc: true,
      printdate: false,
      printtitle: false,
      address: false,
      extra: true,
      deposit: false,
      ismemorized: false,
      retype: false,
      retype2: false,
      tasktype: false,
      time: false,
      tasktitle: true,
      taskfree: true,
      duration: false,
      photos: false,
    },
    eventoptions: {
      bonos: false,
      overtime: false,
      absent: false,
      location: true,
      retype: false,
      retype2: false,
      status: true,
      newinvoice: true,
      note: true,
      title: true,
      sms: true,
      retypefilter: false,
      retype2filter: false,
      customerfilter: false,
      employeefilter: false,
      dueeventscheck: false,
      noinvoicecheck: false,
      multievents: false,
      duetoday: false,
      driverbox: false,
    },
    resorseoptions: {
      additionId: false,
      workId: true,
      target: true,
      city: false,
      address: false,
      color: true,
      status: true,
      carstatus: false,
      garage: false,
      brand: false,
      model: false,
      plate: false,
      cost: true,
      purtime: true,
      insurance: false,
      licenseDate: false,
      photos: true,
      location: true,
      department: true,
      retype: true,
      info: false,
      disabled: true,
      note: true,
    },
    employeeoptions: {
      additionId: false,
      target: true,
      workId: true,
      telHome: false,
      national: false,
      nationalNo: true,
      nationalDate: true,
      licenseNo: false,
      licenseDate: false,
      startWorkDate: false,
      basicSalary: true,
      extraSalary: true,
      daysoff: true,
      speciality: true,
      color: true,
      tel: false,
      fax: false,
      mobile: true,
      email: true,
      city: false,
      address: true,
      pId: false,
      pIdDate: false,
      photos: true,
      location: false,
      comPercent: true,
      department: false,
      retype: true,
      info: false,
      disabled: true,
      note: true,
      salsales: false,
      salevents: false,
      salexpenses: false,
      salbonos: false,
      salovertime: false,
      salabsent: false,
      birthday: false,
      salworkpercent: false,
      salsalespercent: false,
      salextra: false, // بدلات
    },
    departmentoptions: {
      additionId: true,
      target: true,
      color: true,
      desc: true,
      retype: true,
      disabled: true,
      note: true,
    },
    customeroptions: {
      additionId: false,
      tel: true,
      fax: true,
      mobile: true,
      email: true,
      city: true,
      address: true,
      balance: true,
      area: true,
      driver: false,
      licenseNo: false,
      licenseDate: false,
      national: false,
      nationalNo: false,
      nationalDate: false,
      parentName: false,
      parentPhone: false,
      grade: false,
      location: true,
      disabled: true,
      birthday: false,
      openingbalance: false,
      note: true,
      employee: false,
    },
    supplieroptions: {
      additionId: true,
      tel: true,
      fax: true,
      mobile: true,
      email: true,
      city: true,
      address: true,
      balance: true,
      disabled: true,
      openingbalance: false,
      note: true,
    },
    tabsemployee: {
      main: true,
      projects: true,
      tasks: true,
      events: true,
      eventsitems: true,
      invoices: true,
      invoicesitems: true,
      receipts: true,
      purchases: true,
      payments: true,
      expenses: true,
      expensesprod: true,
      advanced: true,
      custody: true,
      entries: true,
      reminders: true,
      expensesitems: true,
    },
    tabsdepartment: {
      main: true,
      projects: true,
      tasks: true,
      events: true,
      eventsitems: true,
      invoices: true,
      invoicesitems: true,
      receipts: true,
      purchases: true,
      payments: true,
      expenses: true,
      expensesprod: true,
      entries: true,
      reminders: true,
      expensesitems: true,
    },
    tabsresourse: {
      main: true,
      projects: true,
      tasks: true,
      events: true,
      eventsitems: true,
      invoices: true,
      invoicesitems: true,
      receipts: true,
      purchases: true,
      payments: true,
      expenses: true,
      expensesprod: true,
      entries: true,
      reminders: true,
      expensesitems: true,
    },
    tabscustomer: {
      main: true,
      projects: true,
      tasks: true,
      events: true,
      eventsitems: true,
      invoices: true,
      invoicesitems: true,
      receipts: true,
    },
    tabssupplier: {
      main: true,
      purchases: true,
      payments: true,
      expenses: true,
      expensesitems: true,
    },
    tabsproject: {
      main: true,
      tasks: true,
      events: true,
      eventsitems: true,
      invoices: true,
      invoicesitems: true,
      receipts: true,
      purchases: true,
      payments: true,
      expenses: true,
      expensesprod: true,
      entries: true,
      reminders: true,
    },
    tabstask: {
      main: true,
      events: true,
      eventsitems: true,
      invoices: true,
      invoicesitems: true,
      receipts: true,
      purchases: true,
      payments: true,
      expenses: true,
      expensesprod: true,
      entries: true,
      reminders: true,
      expensesitems: true,
    },
    mpagemain: {
      appointment: true,
      days: true,
      sales: true,
      purchase: true,
      expenses: true,
      kaids: true,
      income: true,
      raseed: true,
      salary: true,
    },
    hiddencolumns: {
      task: [],
      appointment: [],
      resourse: [],
      employee: [],
      department: [],
      invoice: [],
      receipt: [],
      customer: [],
      pruchaseinvoice: [],
      payment: [],
      supplier: [],
      expenses: [],
      prodexpenses: [],
      advancedpay: [],
      advancedrec: [],
      finance: [],
      kaid: [],
      type: [],
      service: [],
      product: [],
      expenseitem: [],
      reminder: [],
      user: [],
      notification: [],
    },
    mainPage: [],
    taskExtra: [
      {
        id: 1,
        name: 'Kilometer out',
        nameAr: 'العداد وقت الخروج',
        type: 'text',
        value: '',
      },
      {
        id: 2,
        name: 'Kilometer in',
        nameAr: 'العداد وقت العودة',
        type: 'text',
        value: '',
      },
      {
        id: 3,
        name: 'Notes',
        nameAr: 'ملاحظات',
        type: 'text',
        multiline: true,
        value: '',
      },
    ],
    emplExtra: [],
    resoExtra: [],
    custExtra: [],
    sortOrder: [0, 0.5, 0.55, 0.56, 0.6, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    words: {
      ar: {
        main: 'الرئيسية',
        operation: 'العملية',
        operations: 'العمليات',
        appointment: 'الموعد',
        appointments: 'المواعيد',
        eventsitems: 'بنود المواعيد',
        invoicesitems: 'بنود الفواتير',
        task: 'العقد',
        tasks: 'الغقود',
        department: 'القسم',
        departments: 'الأقسام',
        customer: 'العميل',
        customers: 'العملاء',
        suppliers: 'الموردين',
        employee: 'الموظف',
        employees: 'الموظفين',
        employee2: 'الموظف',
        employees2: 'الموظفين',
        resourse: 'المورد',
        resourses: 'الموارد',
        project: 'المشروع',
        projects: 'المشاريع',
        service: 'الخدمة',
        services: 'الخدمات',
        expenseitems: 'المصروف',
        retype: 'نوع',
        evretype: 'نوع الموعد',
        evretype2: 'نوع الموعد 2',
      },
      en: {
        main: 'Main',
        operation: 'Operation',
        operations: 'Operations',
        appointment: 'Appointment',
        appointments: 'Appointments',
        eventsitems: 'Appoint Items',
        invoicesitems: 'Invoice Items',
        task: 'Contract',
        tasks: 'Contracts',
        department: 'Department',
        departments: 'Departments',
        customer: 'Customer',
        suppliers: 'Suppliers',
        customers: 'Customers',
        employee: 'Employee',
        employees: 'Employees',
        employee2: 'Employee',
        employees2: 'Employees',
        resourse: 'Resourse',
        resourses: 'Resourses',
        project: 'Project',
        projects: 'Projects',
        service: 'Service',
        services: 'Services',
        expenseitems: 'Expenses Items',
        retype: 'Type',
        evretype: 'ُEType1',
        evretype2: 'Etype2',
      },
    },
  },
];
