/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import {
  covertToTimeDateDigit,
  createdAtPrintFormatter,
  currencyFormatter,
  moneyFormat,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  fade,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import { getReportContracts } from '../../graphql';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import { useCustomers, useTemplate } from '../../hooks';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { SearchTable } from '../../components';
import { ContractsReportContext } from '../../contexts/managment/contractsreport';
import { getTaskStatus } from '../../common/helpers';
import _ from 'lodash';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);
export const TableComponent2 = withStyles(
  {},
  { name: 'TableComponent' }
)(TableComponentBase);

export default function ContractsReport({
  isRTL,
  words,
  theme,
  start,
  end,
  drawerWidth,
}: any) {
  const [total, setTotal] = useState<any>(0);
  const [createtime, setCreatetime] = useState<any>(false);

  const [rows, setRows] = useState([]);
  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const [columns] = useState([
    col.createdAt,
    col.start,
    col.end,
    col.docNo,
    col.title,
    col.status,
    col.resourse,
    col.customer,
    col.employee,
    col.amount,
    col.totalinvoiced,
    col.totalpaid,
    col.balance,
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.title, togglingEnabled: false },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: col.createdAt.name, width: 100 },
    { columnName: col.start.name, width: 100 },
    { columnName: col.end.name, width: 100 },
    { columnName: col.docNo.name, width: 100 },
    { columnName: col.title.name, width: 200 },
    { columnName: col.status.name, width: 70 },
    { columnName: col.resourse.name, width: 150 },
    { columnName: col.customer.name, width: 180 },
    { columnName: col.type.name, width: 100 },
    { columnName: col.employee.name, width: 150 },
    { columnName: col.amount.name, width: 100 },
    { columnName: col.totalinvoiced.name, width: 100 },
    { columnName: col.totalpaid.name, width: 100 },
    { columnName: col.balance.name, width: 100 },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getReportContracts, {
    fetchPolicy: 'cache-and-network',
  });
  const { customers } = useCustomers();
  const { employees } = useEmployees();
  const { resourses } = useResourses();

  const {
    state: {
      departvalue,
      resovalue,
      emplvalue,
      custvalue,
      group,
      groupby,
      sumcolumn,
      sort,
    },
    dispatch,
  } = useContext(ContractsReportContext);
  const { height } = useWindowDimensions();

  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setResovalueDispatch = (value: any) => {
    dispatch({ type: 'setResovalue', payload: value });
  };
  const setCustvalueDispatch = (value: any) => {
    dispatch({ type: 'setCustvalue', payload: value });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getReportContracts']?.data || [];
    const taskswithstatus = getTaskStatus(slsData, isRTL);
    setRows(taskswithstatus);
    const tot = _.sumBy(taskswithstatus, 'amount');
    setTotal(tot);
  }, [summaryData]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;
  const fetchData = () => {
    const variables = {
      departmentIds: getIds(departvalue),
      employeeIds: getIds(emplvalue),
      resourseIds: getIds(resovalue),
      customerIds: getIds(custvalue),
      createtime,
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    group,
    groupby,
    sumcolumn,
    departvalue,
    resovalue,
    emplvalue,
    custvalue,
    createtime,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `events-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'amount', type: 'sum' },
    { columnName: 'totalinvoiced', type: 'sum' },
    { columnName: 'totalpaid', type: 'sum' },
    { columnName: 'balance', type: 'sum' },
  ];

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 48,
          left: 420,
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box>
          <FormControlLabel
            style={{ padding: 10, fontSize: 14 }}
            control={
              <Checkbox
                style={{ padding: 5, marginTop: 2 }}
                checked={createtime}
                onChange={() => {
                  setCreatetime(!createtime);
                }}
                color="primary"
              />
            }
            label={
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: 'bold',
                }}
              >
                {isRTL ? 'بحسب تاريخ الانشاء' : 'By Created Date'}
              </Typography>
            }
          />
        </Box>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'flex-end',
          top: 5,
          left: 420,
          width: '50%',
        }}
      >
        {tempoptions?.resourse && (
          <FilterSelectMulti
            options={resourses}
            value={resovalue}
            setValue={setResovalueDispatch}
            words={words}
            isRTL={isRTL}
            name="resourse"
            width={250}
            fullwidth
          ></FilterSelectMulti>
        )}
        <Box style={{ width: 10 }}></Box>
        <FilterSelectMulti
          options={employees}
          value={emplvalue}
          setValue={setEmplvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="employee"
          width={250}
          fullwidth
        ></FilterSelectMulti>
        <Box style={{ width: 10 }}></Box>

        <FilterSelectMulti
          options={customers}
          value={custvalue}
          setValue={setCustvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="customer"
          width={250}
          fullwidth
        ></FilterSelectMulti>
      </Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 225,
          right: isRTL ? drawerWidth + 225 : undefined,
          bottom: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
          }}
        >
          {isRTL ? 'المجموع' : 'Total'}: {moneyFormat(total)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        <SearchState />
        <SummaryState totalItems={totalSummaryItems} />
        <IntegratedSummary />
        <IntegratedSorting />
        <IntegratedFiltering />
        <VirtualTable
          height={height - 100}
          tableComponent={TableComponent}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={30}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          onHiddenColumnNamesChange={(hcs: string[]) => {
            const all = [...columns];
            const newcol = all.filter((a: any) => !hcs.includes(a.name));
            newcol.sort((a: any, b: any) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );
            // setActivecolumns(newcol);
          }}
        />
        <DataTypeProvider
          for={['start', 'end', 'createdAt']}
          formatterComponent={createdAtPrintFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={[
            'amount',
            'totalinvoiced',
            'totalDiscount',
            'totalpaid',
            'toatlExpenses',
            'balance',
          ]}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>{' '}
        <Toolbar />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        <TableSummaryRow
          messages={{
            sum: isRTL ? 'المجموع' : 'Total',
            count: isRTL ? 'العدد' : 'Count',
          }}
        ></TableSummaryRow>
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </>
  );
}
