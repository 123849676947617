export const QAFlag = ({ width = "640px", height = "480px" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 75 18"
      preserveAspectRatio="none"
    >
      <path d="M0,0H75V18H0" fill="#8a1538" />
      <path
        d="M22,18H0V0H22l6,1-6,1 6,1-6,1 6,1-6,1 6,1-6,1 6,1-6,1 6,1-6,1 6,1-6,1 6,1-6,1 6,1z"
        fill="#fff"
      />
    </svg>
  );
};
