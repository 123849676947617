import { useState, useEffect } from 'react';
import PopupLayout from '../../shared/PopupLayout';
import LandingBuilder from './LandingBuilder';

const PopupLandingBuilder = ({
  open,
  onClose,
  template,
  setTemplate,
  isRTL,
  theme,
  title,
  company,
  words,
  options,
  landing,
}: any) => {
  const [temp, setTemp] = useState([]);

  useEffect(() => {
    if (template) {
      setTemp(template);
    }
  }, [template]);

  const onSubmit = () => {
    setTemplate(temp);
    onClose();
  };

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onClose}
      title={title}
      onSubmit={onSubmit}
      theme={theme}
      maxWidth="xl"
      alrt={{ show: false, msg: '', type: undefined }}
      mb={0}
    >
      <LandingBuilder
        landing={landing}
        rows={temp}
        setRows={setTemp}
        company={company}
        isRTL={isRTL}
        theme={theme}
        words={words}
        options={options}
      ></LandingBuilder>
    </PopupLayout>
  );
};

export default PopupLandingBuilder;
