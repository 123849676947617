/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
  TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import {
  calculateAmount,
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatterEmpty,
  moneyFormat,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  fade,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import { getCustMonthlyReport } from '../../graphql';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import { CustomerReportContext } from '../../contexts';
import FilterSelectCkeckBox from '../../Shared/FilterSelectCkeckBox';
import { useCustomers, useTemplate } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useReactToPrint } from 'react-to-print';
import { SearchTable } from '../../components';
import { opTypesNames } from '../../constants/datatypes';
import usePrintTemplates from '../../hooks/usePrintTemplates';
import { getDefaultTemplate } from '../../common/helpers';
import PrintDownload from '../../Shared/PrintDownload';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function CustomerReport({
  isRTL,
  words,
  start,
  end,
  company,
  theme,
  drawerWidth,
}: any) {
  const [rows, setRows] = useState([]);
  const [isRaseed, setIsRaseed] = useState(true);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      col.opTime,
      col.customer,
      col.opType,
      { name: 'desc', title: words.description },
      tempoptions?.project && col.project,
      tempoptions?.contract && col.contract,
      col.opDocNo,
      // col.opAcc,
      col.amountdebit,
      col.amountcredit,
      // col.rased,
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
    { columnName: col.amount.name, togglingEnabled: false },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getCustMonthlyReport, {
    fetchPolicy: 'cache-and-network',
  });
  const componentRef: any = useRef();

  const { customers } = useCustomers();
  const { height } = useWindowDimensions();
  const { printtemplates } = usePrintTemplates();

  const {
    state: {
      servicevalue,
      departvalue,
      projvalue,
      resovalue,
      emplvalue,
      custvalue,
      catvalue,
      accvalue,
      group,
      groupby,
      sumcolumn,
      sort,
    },
    dispatch,
  } = useContext(CustomerReportContext);

  const setCustvalueDispatch = (value: any) => {
    dispatch({ type: 'setCustvalue', payload: value ? [value] : [] });
  };

  useEffect(() => {
    if (custvalue?.length > 0) {
      const slsData = summaryData?.data?.['getCustMonthlyReport']?.data || [];
      const balance =
        summaryData?.data?.['getCustMonthlyReport']?.message || null;
      const updatedRows = slsData.map((x: any) => x);

      const amount = isRaseed && balance ? JSON.parse(balance) : null;

      if (amount !== null) {
        const { credit, debit } = amount;

        if (credit || debit) {
          const am = debit - credit;
          updatedRows.unshift({
            _id: Date.now(),
            opTime: start,
            opType: 94,
            debit: am > 0 ? am : 0,
            credit: am < 0 ? am : 0,
            amount: am,
          });
        }
      }
      let rased = 0;
      const updatedRows2 =
        updatedRows?.length > 0
          ? updatedRows.map((item: any) => {
              const rowRased = item.debit ? item.debit : -item.credit;
              const value = item.opType;
              const operation = isRTL
                ? opTypesNames?.[value]?.nameAr
                : opTypesNames?.[value]?.name;
              const desc =
                item?.desc && item?.desc?.length > 0
                  ? item?.desc
                  : isRTL
                  ? item.itemNameAr
                  : item.itemName;
              rased = rased + rowRased;
              return {
                ...item,
                amount: calculateAmount(item),
                desc,
                rased,
                operation,
              };
            })
          : [];

      setRows(updatedRows2);
    } else {
      setRows([]);
    }
  }, [summaryData, isRaseed, custvalue]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;

  const fetchData = () => {
    const variables = {
      accPCode: 2,
      accountIds: getIds(accvalue),
      serviceIds: getIds(servicevalue),
      categoryIds: getIds(catvalue),
      departmentIds: getIds(departvalue),
      projectIds: getIds(projvalue),
      resourseIds: getIds(resovalue),
      employeeIds: getIds(emplvalue),
      customerIds: getIds(custvalue),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };
  useEffect(() => {
    if (start && end && custvalue?.length > 0) {
      fetchData();
    }
  }, [start, end, group, groupby, sumcolumn, custvalue]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `finance-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'credit', type: 'sum' },
    { columnName: 'debit', type: 'sum' },
  ];

  const grouping = [{ columnName: sumcolumn }];

  const groupSummaryItems = [
    {
      columnName: col.opDocNo.name,
      type: 'count',
      alignByColumn: true,
    },
    {
      columnName: 'credit',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: 'debit',
      type: 'sum',
      alignByColumn: true,
    },
  ];

  const template = getDefaultTemplate(printtemplates, 'customerreport');

  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Customer Report`,
    removeAfterPrint: true,
  });

  const printData = {
    start,
    end,
    items: rows,
    rased: rows?.[rows?.length - 1]?.rased,
    customerName: isRTL ? custvalue?.[0]?.nameAr : custvalue?.name,
    customerArea: custvalue?.[0]?.area,
    customerAddress: custvalue?.[0]?.address,
    customerPhone: custvalue?.[0]?.phone,
    customer: custvalue?.[0],
    isRTL,
    date: new Date(),
    company,
  };

  return (
    <>
      {custvalue?.length > 0 && (
        <Box
          style={{
            position: 'absolute',
            left: isRTL ? 400 : undefined,
            right: isRTL ? undefined : 400,
            top: -8,
            zIndex: 112,
          }}
        >
          <PrintDownload
            company={company}
            printData={printData}
            componentRef={componentRef}
            handleReactPrint={handleReactPrint}
            isRTL={isRTL}
            template={template}
            no={custvalue[0]?.name}
            element="customerreport"
          ></PrintDownload>
        </Box>
      )}

      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 60,
          left: 450,
          zIndex: 112,
        }}
      >
        <Box style={{ marginLeft: 10, marginRight: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: 7 }}
                checked={isRaseed}
                onChange={() => setIsRaseed(!isRaseed)}
                color="primary"
              />
            }
            label={
              <Typography
                style={{
                  color:
                    custvalue?.length === 0
                      ? '#aaa'
                      : theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {isRTL ? 'رصيد افتتاحي' : 'Opening Balance'}
              </Typography>
            }
            style={{ fontSize: 14, width: 150 }}
          />
        </Box>
        <FilterSelectCkeckBox
          options={customers}
          value={custvalue?.[0]}
          setValue={setCustvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="customer"
          nomulti
          width={250}
        ></FilterSelectCkeckBox>
      </Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 225,
          right: isRTL ? drawerWidth + 225 : undefined,
          bottom: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: '#403795' }}>
          {isRTL ? 'الرصيد' : 'Balance'}:{' '}
          {moneyFormat(rows?.[rows?.length - 1]?.rased)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        {group && <GroupingState grouping={grouping} />}
        {group && (
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
        )}
        {group && <IntegratedGrouping />}
        {group && <IntegratedSummary />}
        <IntegratedSorting />
        <SearchState />
        <IntegratedFiltering />
        <VirtualTable
          height={height - 100}
          tableComponent={TableComponent}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={40}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[col.rased.name]}
          onHiddenColumnNamesChange={(hcs: string[]) => {
            const all = [...columns];
            const newcol = all.filter((a: any) => !hcs.includes(a.name));
            newcol.sort((a: any, b: any) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );
          }}
        />
        <DataTypeProvider
          for={['opTime']}
          formatterComponent={createdAtFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['credit', 'debit', 'rased']}
          formatterComponent={currencyFormatterEmpty}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['opType']}
          formatterComponent={opTypeFormatter}
        ></DataTypeProvider>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        {group && (
          <TableGroupRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
              sumOf: isRTL ? 'المجموع' : 'Total',
              countOf: isRTL ? 'العدد' : 'Count',
            }}
            showColumnsWhenGrouped
          />
        )}
        {group && (
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </>
  );
}
