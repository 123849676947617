/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { useTemplate } from '../../hooks';
import { getRowId, userroles } from '../../common';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, Typography } from '@material-ui/core';
import PageLayout from '../main/PageLayout';
import { TableComponent } from '../../Shared/TableComponent';
import usePCategories from '../../hooks/usePCategories';
import PopupPCategory from '../../pubups/PopupPCategory';

export default function PCategories({ isRTL, words, theme, menuitem }: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const { height, width } = useWindowDimensions();
  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      { name: isRTL ? 'nameAr' : 'name', title: words.name },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'additionId', width: 150 },
    { columnName: isRTL ? 'nameAr' : 'name', width: 250 },
  ]);

  const {
    pcategories,
    addPCategory,
    editPCategory,
    removePCategory,
    refreshpcategories,
    loading,
  } = usePCategories();

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removePCategory({ variables: { _id } });
      if (res?.data?.deletePCategory?.ok === false) {
        if (res?.data?.deletePCategory?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const bgcolor = '#EFFAF100';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refreshpcategories}
      bgcolor={bgcolor}
      loading={loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: bgcolor,
        }}
      >
        <Paper
          elevation={5}
          style={{
            marginTop: 10,
            marginLeft: 40,
            marginRight: 40,
            marginBottom: 20,
            overflow: 'auto',
            width: width - 320,
            borderRadius: 10,
          }}
        >
          <Grid rows={pcategories} columns={columns} getRowId={getRowId}>
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SearchState />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />

            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <DragDropProvider />
            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 68 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnReordering
              defaultOrder={['additionId', isRTL ? 'nameAr' : 'name']}
            />
            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnVisibility
              columnExtensions={[]}
              defaultHiddenColumnNames={[]}
            />
            <TableEditColumn
              showEditCommand={userroles?.brands?.edit}
              showDeleteCommand={userroles?.brands?.delete}
              showAddCommand={userroles?.brands?.add}
              commandComponent={Command}
            ></TableEditColumn>
            <Toolbar />
            <ColumnChooser />
            <PagingPanel
              pageSizes={
                tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
              }
            />
            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />

            <PopupEditing
              theme={theme}
              addAction={addPCategory}
              editAction={editPCategory}
            >
              <PopupPCategory></PopupPCategory>
            </PopupEditing>
          </Grid>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
      </Box>
    </PageLayout>
  );
}
