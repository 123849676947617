/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import ProcedureBox from './ProcedureBox';
import getOperationById from '../../graphql/query/getOperationById';
import { useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import MyIcon from '../components/MyIcon';
import DocumentPreview from './DocumentPreview';

const OperationView = ({
  formdoc,
  theme,
  isRTL,
  words,
  user,
  width,
  height,
  company,
  users,
}: any) => {
  const [doc, setDoc] = React.useState(null);
  const { opId } = useParams();
  const navigate = useNavigate();

  const [getDoc, docData]: any = useLazyQuery(getOperationById);

  useEffect(() => {
    if (opId) {
      getDoc({ variables: { opId } });
    }
  }, [opId]);

  useEffect(() => {
    const data = docData?.data?.getOperationById?.data;
    if (data) {
      setDoc(data);
    }
  }, [docData]);

  return (
    <Box
      style={{
        width,
        height: height - 70,
        margin: 10,
        backgroundColor: '#fff',
      }}
    >
      <Box
        style={{
          position: 'absolute',
          zIndex: 112,
          left: 33,
          marginTop: -57,
          alignItems: 'center',
          justifyContent: 'space-between',
          // backgroundColor: '#aaaaaa55',
        }}
      >
        <Box style={{ display: 'flex' }}>
          <IconButton
            style={{ color: '#333' }}
            onClick={() => navigate(-1)}
            title="Edit row"
          >
            <MyIcon icon="left" />
            <Typography></Typography>
          </IconButton>
        </Box>
      </Box>
      <Box
        style={{
          width: width - 20,
          height: height - 70,
          overflow: 'auto',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={5} style={{ paddingBottom: 15 }}>
            <DocumentPreview
              formdoc={formdoc}
              isRTL={isRTL}
              theme={theme}
              width={width}
              height={height}
              company={company}
              row={doc}
              left
            ></DocumentPreview>
          </Grid>
          <Grid item xs={7} style={{ paddingBottom: 15 }}>
            <ProcedureBox
              row={doc}
              user={user}
              words={words}
              isRTL={isRTL}
              theme={theme}
              width={width}
              height={height}
              users={users}
            ></ProcedureBox>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OperationView;
