import CalenderLocalClock from '../components/CalenderLocalClock';
import CalenderLocalList from '../components/CalenderLocalList';
import { useTemplate } from '../../hooks';

const CalenderLocal = (props: any) => {
  const { tempoptions } = useTemplate();
  if (tempoptions?.calendarclock) {
    return <CalenderLocalClock {...props}></CalenderLocalClock>;
  }
  return <CalenderLocalList {...props}></CalenderLocalList>;
};

export default CalenderLocal;
