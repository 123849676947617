/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  ColumnChooser,
  PagingPanel,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, updateDocNumbers, userroles } from '../../common';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createExpenses,
  deleteExpenses,
  getExpenses,
  updateExpenses,
} from '../../graphql';
import {
  accountFormatter,
  currencyFormatter,
  opTypeFormatter,
  samllFormatter,
  timeFormatter,
} from '../../Shared/colorFormat';
import useAccounts from '../../hooks/useAccounts';
import { SearchTable } from '../../components';
import { ExpensesContext } from '../../contexts';
import useTasks from '../../hooks/useTasks';
import { getColumns } from '../../common/columns';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import PopupExpensesDoc from '../../pubups/PopupExpensesDoc';
import useDepartments from '../../hooks/useDepartments';
import useResourses from '../../hooks/useResourses';
import { useExpenseItems, useTemplate } from '../../hooks';
import { moneyFormat } from '../common/formater';
import _ from 'lodash';

const ExpensesDoc = forwardRef((props: any, ref: any) => {
  const { isRTL, words, start, end, theme, company, drawerWidth } = props;

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      { name: 'time', title: words?.time },
      col.docNo,
      { name: 'debitAcc', title: isRTL ? 'حساب المصروف' : 'Expenses Acc' },
      { name: 'opType', title: words?.type },
      { name: 'creditAcc', title: isRTL ? 'حساب الدفع' : 'Payment Acc' },
      tempoptions?.resourse && col.resourse,
      tempoptions?.contract && col.contract,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      col.supplier,
      { name: 'desc', title: words?.description },
      { name: 'amount', title: words?.amount },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 100 },
    { columnName: col.docNo.name, width: 120 },
    { columnName: 'debitAcc', width: 150 },
    { columnName: 'opType', width: 150 },
    { columnName: 'creditAcc', width: 150 },
    { columnName: col.employee.name, width: 180 },
    { columnName: col.supplier.name, width: 180 },
    { columnName: col.contract.name, width: 180 },
    { columnName: col.department.name, width: 180 },
    { columnName: col.resourse.name, width: 180 },
    { columnName: 'desc', width: 250 },
    { columnName: 'amount', width: 120 },
  ]);

  const [rows, setRows] = useState([]);
  const [sum, setSum] = useState(0);

  const { tasks } = useTasks();
  const { departments } = useDepartments();
  const { resourses } = useResourses();
  const { expenseItems } = useExpenseItems();

  const {
    state: { hiddenColumnNames, pageSize },
    dispatch,
  } = useContext(ExpensesContext);

  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };
  const setPageSize = (pageSize: any) => {
    dispatch({ type: 'setPageSize', payload: pageSize });
  };

  const [loadExpenses, expensesData]: any = useLazyQuery(getExpenses, {
    fetchPolicy: 'cache-and-network',
  });
  const { accounts } = useAccounts();
  const refresQuery = {
    refetchQueries: [
      {
        query: getExpenses,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    if (start && end) {
      loadExpenses({
        variables,
      });
    }
  }, [start, end]);

  const [addExpenses] = useMutation(createExpenses, refresQuery);
  const [editExpenses] = useMutation(updateExpenses, refresQuery);
  const [removeExpenses] = useMutation(deleteExpenses, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (expensesData?.refetch) {
        expensesData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeExpenses({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };

  useEffect(() => {
    if (expensesData?.data?.getExpenses?.data) {
      const { data } = expensesData.data.getExpenses;
      const rdata = updateDocNumbers(data);
      const samount = _.sumBy(rdata, 'amount');
      setSum(samount);
      setRows(rdata);
    }
  }, [expensesData]);

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 50,
          right: isRTL ? drawerWidth + 50 : undefined,
          bottom: 50,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          style={{
            color: '#333',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {isRTL ? ' المجموع ' : ' Total '}: {moneyFormat(sum)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          defaultCurrentPage={0}
          defaultPageSize={10}
        />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'time',
            'docNo',
            'debitAcc',
            'opType',
            'creditAcc',
            col.employee.name,
            col.resourse.name,
            col.contract.name,
            col.department.name,
            col.supplier.name,
            'desc',
            'amount',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <DataTypeProvider
          for={['time']}
          formatterComponent={timeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['docNo', 'refNo']}
          formatterComponent={samllFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['opType']}
          formatterComponent={opTypeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['creditAcc']}
          formatterComponent={(props) =>
            accountFormatter(props, accounts, isRTL)
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['debitAcc']}
          formatterComponent={(props) =>
            accountFormatter(props, accounts, isRTL)
          }
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.expenses?.edit}
          showDeleteCommand={userroles?.expenses?.delete}
          showAddCommand={userroles?.expenses?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          theme={theme}
          addAction={addExpenses}
          editAction={editExpenses}
        >
          <PopupExpensesDoc
            resourses={resourses}
            departments={departments}
            company={company}
            servicesproducts={expenseItems}
            tasks={tasks}
          ></PopupExpensesDoc>
        </PopupEditing>
      </Grid>
    </>
  );
});

export default ExpensesDoc;
