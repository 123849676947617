import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import DaysEvents from '../DaysEvents';

function DaysEventStatus({ isRTL, theme, systemcolor, color, height }) {
  const { nextEventDays } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {nextEventDays && userroles?.appointments?.edit && (
        <DaysEvents
          dataKey="count"
          theme={theme}
          isRTL={isRTL}
          data={nextEventDays}
          height={height}
          color={clr}
        ></DaysEvents>
      )}
    </Box>
  );
}

export default DaysEventStatus;
