/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import {
  DataTypeProvider,
  EditingState,
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { useBranches } from '../../hooks';
import {
  currencyFormatter,
  foundationIdFormatter,
  logoFormatter,
  subStatusFormatter,
  templateFormatter,
} from '../../Shared/colorFormat';
import { SearchTable } from '../../components';
import { Box, Typography } from '@material-ui/core';
import { userroles } from '../../common';
import useFoundations from '../../hooks/useFoundations';
import { TableComponent } from '../components/TableComponent';
import PopupSubscribtion from '../../pubups/PopupSubscribtion';

export const getRowId = (row: { _id: any }) => row._id;

export default function Subscribtions({ isRTL, theme, words }: any) {
  const [columns] = useState([
    { name: 'logo', title: ' ' },
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'foundationId', title: isRTL ? 'الشركة' : 'Company' },
    { name: 'packName', title: isRTL ? 'الاشتراك' : 'Subscribtion' },
    { name: 'packCost', title: isRTL ? 'القيمة' : 'Amount' },
    { name: 'packDiscount', title: isRTL ? 'الخصم' : 'Discount' },
    { name: 'status', title: isRTL ? 'الحالة' : 'Status' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'logo', width: 100 },
    { columnName: isRTL ? 'nameAr' : 'name', width: 300 },
    { columnName: 'foundationId', width: 300 },
    { columnName: 'packName', width: 150 },
    { columnName: 'packCost', width: 150 },
    { columnName: 'packDiscount', width: 150 },
    { columnName: 'status', width: 150 },
  ]);

  const { branches, editBranch } = useBranches();
  const { foundations } = useFoundations();
  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 60,
          left: isRTL ? 330 : undefined,
          right: isRTL ? undefined : 350,
          display: 'flex',
          flexGrow: 1,
          zIndex: 111,
        }}
      ></Box>
      <Grid rows={branches} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={() => null} />
        <SearchState />

        <IntegratedSorting />
        <IntegratedFiltering />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <DataTypeProvider
          for={['packCost', 'packDiscount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['logo']}
          formatterComponent={logoFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['status']}
          formatterComponent={subStatusFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['template']}
          formatterComponent={templateFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['foundationId']}
          formatterComponent={(props: any) =>
            foundationIdFormatter({ ...props, isRTL, foundations })
          }
        ></DataTypeProvider>

        <TableEditColumn
          showEditCommand={userroles?.isSuperAdmin}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing editAction={editBranch}>
          <PopupSubscribtion
            isRTL={isRTL}
            theme={theme}
            words={words}
            branches={branches}
          ></PopupSubscribtion>
        </PopupEditing>
      </Grid>
    </>
  );
}
