/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { PopupCustomer, PopupDeprtment, PopupEmployee } from '../../../pubups';
import { GContextTypes } from '../../../types';
import { GlobalContext } from '../../../contexts';
import { Grid } from '@material-ui/core';
import { AlertLocal, CalenderLocal, TextFieldLocal } from '../../../components';
import AutoFieldLocal from '../../../components/fields/AutoFieldLocal';
import { eventStatus, weekdaysNNo } from '../../../constants/datatypes';
import { setRowFromAppointment } from '../../../common/calendar';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createTempEvent,
  getActions,
  getOperationItems,
} from '../../../graphql';
import ItemsTable from '../../../Shared/ItemsTable';
import { invoiceClasses } from '../../../themes';
import {
  actionTypeFormatter,
  getDateDayTimeFormat,
  moneyFormat,
} from '../../../Shared/colorFormat';
import {
  useCustomers,
  useProducts,
  useServices,
  useTemplate,
} from '../../../hooks';
import PopupResourses from '../../../pubups/PopupResourses';
import useDepartments from '../../../hooks/useDepartments';
import useEmployees from '../../../hooks/useEmployees';
import useResourses from '../../../hooks/useResourses';
import useTasks from '../../../hooks/useTasks';
import ServiceItemForm from '../../../Shared/ServiceItemForm';
import useRetypes from '../../../hooks/useRetypes';
import useProjects from '../../../hooks/useProjects';
import { LinkLocation } from '../../../common';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PopupAction from '../../../pubups/PopupAction';
import { getEventLengthInM } from '../../../common/helpers';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

export const AppointForm = (props: any) => {
  const {
    onFieldChange,
    appointmentData,
    theme,
    calendar,
    isEmployee,
    evenetOpened,
    setTemp,
    temp,
  } = props;
  const employeeId = isEmployee
    ? props?.employeeId
    : appointmentData?.employeeId;
  const row = setRowFromAppointment(appointmentData);
  const classes = invoiceClasses();
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [rtypevalue, setRtypevalue] = useState<any>(null);
  const [rtype2value, setRtype2value] = useState<any>(null);

  const [startDate, setStartDate]: any = useState(row?.startDate);
  const [endDate, setEndDate]: any = useState(row?.endDate);

  const [tminutes, setTMinutes]: any = useState(null);
  const [thours, setTHours]: any = useState(null);
  const [duration, setDuration]: any = useState(null);

  // const [eventLength, setEventLength]: any = useState(null);

  const [status, setStatus] = useState(null);

  const [totals, setTotals] = useState<any>({});
  const [itemsList, setItemsList] = useState<any>([]);
  const [isItems, setIsItems] = useState(true);

  const [custvalue, setCustvalue] = useState<any>(null);
  const [taskvalue, setTaskvalue] = useState<any>(null);
  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [departvalue, setDepartvalue] = useState<any>(null);
  const [resovalue, setResovalue] = useState<any>(null);

  const [tasktitle, setTasktitle]: any = useState();

  const [locationLink, setLocationLink] = useState(null);
  const [address, setAddress] = useState(null);
  const [desc, setDesc] = useState(null);
  const [houseno, setHouseno] = useState(null);

  const [newtext, setNewtext] = useState('');

  const [openCust, setOpenCust] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);

  const [openAction, setOpenAction] = useState(false);
  const [actionslist, setActionslist] = useState([]);
  const [selected, setSelected] = useState(null);

  const { retypes } = useRetypes();
  const { customers, addCustomer, editCustomer } = useCustomers();
  const { tempwords, tempoptions, eventoptions } = useTemplate();
  const { departments, addDepartment, editDepartment } = useDepartments();
  const { employees, addEmployee, editEmployee } = useEmployees();
  const { resourses, addResourse, editResourse } = useResourses();
  const { projects } = useProjects();
  const { tasks } = useTasks();
  const { services } = useServices();
  const { products } = useProducts();

  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadActions, actionsData]: any = useLazyQuery(getActions, {
    fetchPolicy: 'cache-and-network',
  });
  const [addTempEvent] = useMutation(createTempEvent);

  const openDepartment = () => {
    setOpenDep(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };
  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };

  useEffect(() => {
    if (row && row._id) {
      const items = itemsData?.data?.['getOperationItems']?.data || [];
      const actions = actionsData?.data?.['getActions']?.data || [];

      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...services, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );

        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            note,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            note,
            // itemtotalcost: item.qty * serv.cost,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        setItemsList(itemsWqtyprice);
        setIsItems(true);
        const listwithindex = indexTheList(actions);
        setActionslist(listwithindex);
      }
    }
  }, [itemsData, actionsData]);

  useEffect(() => {
    getOverallTotal();
  }, [itemsList]);
  useEffect(() => {
    // !row?._id === isNew
    if (
      evenetOpened === true &&
      !row?._id &&
      temp === true &&
      tempoptions?.tempevent
    ) {
      if (appointmentData?.employeeId) {
        if (employees?.length > 0) {
          const employeev = employees.filter(
            (em: any) => em._id === appointmentData?.employeeId
          )?.[0];
          const variables = {
            startDate,
            endDate,
            employee: employeev
              ? {
                  employeeId: employeev._id,
                  employeeName: employeev.name,
                  employeeNameAr: employeev.nameAr,
                  employeeColor: employeev.color,
                  employeePhone: employeev.phone,
                }
              : undefined,
          };
          addTempEvent({ variables });
          setTemp(false);
        }
      } else {
        const variables = {
          startDate,
          endDate,
        };
        addTempEvent({ variables });
        setTemp(false);
      }
    }
  }, [evenetOpened, employees]);

  useEffect(() => {
    if (row && row._id) {
      getItems({ variables: { opId: row._id } });
      loadActions({ variables: { eventId: row.id, type: 1 } });
      if (row.locationLink) {
        setLocationLink(row?.locationLink);
      }
      if (row.address) {
        setAddress(row?.address);
      }
      if (row.houseno) {
        setHouseno(row?.houseno);
      }
      if (row.desc) {
        setDesc(row?.desc);
      }
    }
  }, []);
  useEffect(() => {
    if (custvalue) {
      setLocationLink(
        custvalue.locationLink ? custvalue.locationLink : locationLink
      );
      setHouseno(custvalue.houseno ? custvalue.houseno : houseno);
      setAddress(custvalue.address ? custvalue.address : address);
    }
  }, [custvalue]);

  useEffect(() => {
    if (row && row._id) {
      if (row.contract && tasks.length > 0 && !taskvalue) {
        const tks = tasks.filter((t: any) => t._id === row.contract?._id)?.[0];
        setTaskvalue(tks);
      }
      if (row.employee && employees.length > 0 && !emplvalue) {
        const empl = employees.filter(
          (emp: any) => emp._id === row?.employee?._id
        )?.[0];
        setEmplvalue(empl);
      }

      if (row.resourse && resourses.length > 0 && !resovalue) {
        const empl = resourses.filter(
          (emp: any) => emp._id === row?.resourse?._id
        )?.[0];
        setResovalue(empl);
      }
      if (row.customer && customers.length > 0 && !custvalue) {
        const empl = customers.filter(
          (emp: any) => emp._id === row?.customer?._id
        )?.[0];
        setCustvalue(empl);
      }
      if (row.department && departments.length > 0 && !departvalue) {
        const empl = departments.filter(
          (emp: any) => emp._id === row?.department?._id
        )?.[0];
        setDepartvalue(empl);
      }
      if (row?.status) {
        const stat = eventStatus.filter(
          (es: any) => es.id === row?.status
        )?.[0];
        if (stat) {
          setStatus(stat);
        }
      }
      if (row?.retypeId) {
        const depart = retypes.filter(
          (dep: any) => dep._id === row.retypeId
        )[0];
        setRtypevalue(depart);
      }
      if (row?.retype2Id) {
        const depart = retypes.filter(
          (dep: any) => dep._id === row.retype2Id
        )[0];
        setRtype2value(depart);
      }
    }
  }, [tasks, employees, resourses, departments, customers, retypes]);

  useEffect(() => {
    if (employeeId && employees.length > 0 && !emplvalue) {
      const empl = employees.filter((emp: any) => emp._id === employeeId)?.[0];
      setEmplvalue(empl);
      onNewFieldChange(empl, 'employee');
    }
  }, [employees]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  useEffect(() => {
    onFieldChange({ actions: JSON.stringify(actionslist) });
  }, [actionslist]);

  const addActionToList = (item: any) => {
    const newArray = [...actionslist, item];
    const listwithindex = indexTheList(newArray);
    setActionslist(listwithindex);
  };
  const editActionInList = (item: any) => {
    const newArray = actionslist.map((it: any) => {
      if (it._id === item._id) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setActionslist(listwithindex);
  };

  const removeActionFromList = (item: any) => {
    const newlist = actionslist.filter((il: any) => il.index !== item.index);
    const listwithindex = indexTheList(newlist);
    setActionslist(listwithindex);
  };

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum;
    const profit = sum - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
    onFieldChange({ amount });
    const newList = !tempoptions?.itemrelations
      ? itemsList?.map((il: any) => {
          return {
            ...il,
            contractId: taskvalue?._id,
            contractName: taskvalue?.name,
            contractNameAr: taskvalue?.nameAr,
            departmentId: departvalue?._id,
            departmentName: departvalue?.name,
            departmentNameAr: departvalue?.nameAr,
            departmentColor: departvalue?.color,
            employeeId: emplvalue?._id,
            employeeName: emplvalue?.name,
            employeeNameAr: emplvalue?.nameAr,
            employeeColor: emplvalue?.color,
            employeePhone: emplvalue?.phone,
            resourseId: resovalue?._id,
            resourseName: resovalue?.name,
            resourseNameAr: resovalue?.nameAr,
            resourseColor: resovalue?.color,
          };
        })
      : itemsList;
    const items = JSON.stringify(newList);
    onFieldChange({ items });
  };

  const onNewFieldChange = (nextValue: any, name: any) => {
    onFieldChange({ [name]: nextValue });
  };

  useEffect(() => {
    if (!row.status) {
      onNewFieldChange(2, 'status');
    }
  }, [row.status]);

  useEffect(() => {
    const title = row.title
      ? row.title
      : tasktitle
      ? tasktitle
      : isRTL
      ? itemsList?.[0]?.nameAr
      : itemsList?.[0]?.name;
    setTasktitle(title);
    onNewFieldChange(title, 'title');
  }, [row.title, itemsList]);

  const selectCustomer = (value: any) => {
    let newValue = value;
    if (!value) {
      newValue = {
        customerId: undefined,
        customerName: undefined,
        customerNameAr: undefined,
        customerPhone: undefined,
      };
      onNewFieldChange(value, 'customerId');
      onNewFieldChange(value, 'customerName');
      onNewFieldChange(value, 'customerNameAr');
      onNewFieldChange(value, 'customerPhone');
    }
    onNewFieldChange(newValue, 'customer');
    setCustvalue(newValue);
    if (newValue?.locationLink) {
      onNewFieldChange(newValue?.locationLink, 'locationLink');
    } else {
      onNewFieldChange(null, 'locationLink');
    }
    if (newValue?.houseno) {
      onNewFieldChange(newValue?.houseno, 'houseno');
    } else {
      onNewFieldChange(null, 'houseno');
    }
    if (newValue?.address) {
      onNewFieldChange(newValue?.address, 'address');
    } else {
      onNewFieldChange(null, 'address');
    }
  };
  const selectRetype = (value: any) => {
    let newValue = value;
    if (!value) {
      newValue = {
        retypeId: undefined,
        retypeName: undefined,
        retypeNameAr: undefined,
      };
      onNewFieldChange(value, 'retypeId');
      onNewFieldChange(value, 'retypeName');
      onNewFieldChange(value, 'retypeNameAr');
    }
    onNewFieldChange(newValue, 'retype');
    setRtypevalue(newValue);
  };
  const selectRetype2 = (value: any) => {
    let newValue = value;
    if (!value) {
      newValue = {
        retype2Id: undefined,
        retype2Name: undefined,
        retype2NameAr: undefined,
      };
      onNewFieldChange(value, 'retype2Id');
      onNewFieldChange(value, 'retype2Name');
      onNewFieldChange(value, 'retype2NameAr');
    }
    onNewFieldChange(newValue, 'retype2');
    setRtype2value(newValue);
  };
  const selectDepartment = (value: any) => {
    let newValue = value;
    if (!value) {
      newValue = {
        departmentId: undefined,
        departmentName: undefined,
        departmentNameAr: undefined,
        departmentColor: undefined,
      };
      onNewFieldChange(value, 'departmentId');
      onNewFieldChange(value, 'departmentName');
      onNewFieldChange(value, 'departmentNameAr');
      onNewFieldChange(value, 'departmentColor');
    }
    onNewFieldChange(newValue, 'department');
    setDepartvalue(newValue);
  };
  const selectEmployee = (value: any) => {
    let newValue = value;
    if (!value) {
      newValue = {
        employeeId: undefined,
        employeeName: undefined,
        employeeNameAr: undefined,
        employeeColor: undefined,
      };
      onNewFieldChange(value, 'employeeId');
      onNewFieldChange(value, 'employeeName');
      onNewFieldChange(value, 'employeeNameAr');
      onNewFieldChange(value, 'employeeColor');
    }
    onNewFieldChange(newValue, 'employee');
    setEmplvalue(newValue);
  };
  const selectResourse = (value: any) => {
    let newValue = value;
    if (!value) {
      newValue = {
        resourseId: undefined,
        resourseName: undefined,
        resourseNameAr: undefined,
        resourseColor: undefined,
      };
      onNewFieldChange(value, 'resourseId');
      onNewFieldChange(value, 'resourseName');
      onNewFieldChange(value, 'resourseNameAr');
      onNewFieldChange(value, 'resourseColor');
    }
    onNewFieldChange(newValue, 'resourse');
    setResovalue(newValue);
  };
  const selectTask = (value: any) => {
    setTaskvalue(value);
    onNewFieldChange(value, 'contract');
    if (value?.employeeId) {
      const empl = employees.filter(
        (em: any) => em._id === value?.employeeId
      )?.[0];
      onNewFieldChange(empl, 'employee');
      setEmplvalue(empl);
    }
    if (value?.departmentId) {
      const dept = departments.filter(
        (dep: any) => dep._id === value?.departmentId
      )?.[0];
      onNewFieldChange(dept, 'department');
      setDepartvalue(dept);
    }

    if (value?.resourseId) {
      const empl = resourses.filter(
        (em: any) => em._id === value?.resourseId
      )?.[0];
      onNewFieldChange(empl, 'resourse');
      setResovalue(empl);
    }
    if (value?.customerId) {
      const cust = customers.filter(
        (ct: any) => ct._id === value?.customerId
      )?.[0];
      onNewFieldChange(cust, 'customer');
      setCustvalue(cust);
    }
    if (value?.projectId) {
      const proj = projects.filter(
        (ct: any) => ct._id === value?.projectId
      )?.[0];
      onNewFieldChange(proj, 'project');
      setCustvalue(proj);
    }
  };

  useEffect(() => {
    if (!row._id) {
      if (taskvalue?.employeeId) {
        const empl = employees.filter(
          (em: any) => em._id === taskvalue?.employeeId
        )?.[0];
        onNewFieldChange(empl, 'employee');
        setEmplvalue(empl);
      }
      if (taskvalue?.resourseId) {
        const empl = resourses.filter(
          (em: any) => em._id === taskvalue?.resourseId
        )?.[0];
        onNewFieldChange(empl, 'resourse');
        setResovalue(empl);
      }
      if (taskvalue) {
        if (taskvalue?.departmentId) {
          const dept = departments.filter(
            (dep: any) => dep._id === taskvalue?.departmentId
          )?.[0];
          onNewFieldChange(dept, 'department');
        }
      }
    }
  }, [taskvalue]);

  useEffect(() => {
    if (!row._id) {
      const mins = calendar?.eventlen || 60;
      setDuration(mins);
      setTHours(Math.floor(mins / 60));
      setTMinutes(mins % 60);
      const end = row?.startDate
        ? new Date(row?.startDate).getTime() + mins * 60 * 1000
        : null;
      if (end) {
        setEndDate(new Date(end));
        onNewFieldChange(new Date(end), 'endDate');
      }
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate && !duration) {
      const mins = getEventLengthInM(startDate, endDate);
      setDuration(mins);
      setTHours(Math.floor(mins / 60));
      setTMinutes(mins % 60);
    }
  }, [startDate, endDate, duration]);

  const day = weekdaysNNo?.[startDate?.getDay()];

  return (
    <Box p={1}>
      <Box style={{ position: 'absolute', top: 50, left: 30 }}>
        <Typography
          style={{ fontWeight: 'bold', marginTop: 15 }}
          variant="body2"
        >
          {row?.docNo}
        </Typography>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <CalenderLocal
                    isRTL={isRTL}
                    label={words.start}
                    value={startDate}
                    onChange={(d: any) => {
                      setStartDate(d);
                      onNewFieldChange(d, 'startDate');
                      const end = duration
                        ? new Date(d).getTime() + duration * 60 * 1000
                        : null;
                      if (end) {
                        setEndDate(new Date(end));
                        onNewFieldChange(new Date(end), 'endDate');
                      }
                    }}
                    format="dd/MM/yyyy - hh:mm"
                    time
                  ></CalenderLocal>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box style={{ marginTop: 5 }}>
                    <Box style={{ marginTop: 30, display: 'flex' }}>
                      <TextField
                        name="minutes"
                        onChange={(e: any) => {
                          const value = Number(e.target?.value);
                          if (value < 0) return;
                          setTMinutes(value);
                          const end = startDate
                            ? new Date(startDate).getTime() +
                              (value * 60 * 1000 || 0) +
                              (thours * 60 * 60 * 1000 || 0)
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                            onNewFieldChange(new Date(end), 'endDate');
                          }
                          setDuration((value || 0) + (thours * 60 || 0));
                        }}
                        value={tminutes}
                        label={`${words.duration} ${
                          isRTL ? 'دقيقة' : 'Minutes'
                        }`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                      />
                      <TextField
                        name="hours"
                        onChange={(e: any) => {
                          const value = Number(e.target?.value);
                          if (value < 0) return;
                          setTHours(value);
                          const end = startDate
                            ? new Date(startDate).getTime() +
                              (value * 60 * 60 * 1000 || 0) +
                              (tminutes * 60 * 1000 || 0)
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                            onNewFieldChange(new Date(end), 'endDate');
                          }
                          setDuration((value * 60 || 0) + (tminutes || 0));
                        }}
                        value={thours}
                        label={`${words.duration} ${isRTL ? 'ساعة' : 'Hours'}`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div style={{ pointerEvents: 'none', opacity: 0.5 }}>
                    <CalenderLocal
                      isRTL={isRTL}
                      label={words.end}
                      value={endDate}
                      onChange={(d: any) => setEndDate(d)}
                      format="dd/MM/yyyy - hh:mm"
                      time
                    ></CalenderLocal>
                  </div>
                </Grid>
                {eventoptions?.title && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      name="tasktitle"
                      label={words.title}
                      value={tasktitle}
                      onChange={(e: any) => {
                        setTasktitle(e.target.value);
                        onNewFieldChange(e.target.value, 'title');
                      }}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="customer"
                    title={tempwords?.customer}
                    words={words}
                    options={customers}
                    value={custvalue}
                    setSelectValue={selectCustomer}
                    setNewtext={setNewtext}
                    isRTL={isRTL}
                    openAdd={openCustomer}
                    fullWidth
                    showphone
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
                {tempoptions?.docrelations && (
                  <>
                    {tempoptions?.contract && (
                      <Grid item xs={12} md={6} style={{ marginTop: 5 }}>
                        <AutoFieldLocal
                          name="task"
                          title={tempwords?.task}
                          words={words}
                          options={tasks}
                          value={taskvalue}
                          setSelectValue={selectTask}
                          isRTL={isRTL}
                          fullWidth
                          mb={0}
                        ></AutoFieldLocal>
                      </Grid>
                    )}
                    {tempoptions?.employee && (
                      <Grid item xs={12} md={6}>
                        <AutoFieldLocal
                          name="employee"
                          title={tempwords?.employee}
                          words={words}
                          options={employees}
                          disabled={isEmployee}
                          value={emplvalue}
                          setSelectValue={selectEmployee}
                          openAdd={openEmployee}
                          isRTL={isRTL}
                          fullWidth
                          day={day}
                          datelist={[{ startDate, endDate }]}
                          mb={0}
                        ></AutoFieldLocal>
                      </Grid>
                    )}
                    {tempoptions?.department && (
                      <Grid item xs={12} md={6}>
                        <AutoFieldLocal
                          name="department"
                          title={tempwords?.department}
                          words={words}
                          options={departments.filter(
                            (d: any) => d.depType === 1
                          )}
                          value={departvalue}
                          setSelectValue={selectDepartment}
                          openAdd={openDepartment}
                          isRTL={isRTL}
                          fullWidth
                          mb={0}
                        ></AutoFieldLocal>
                      </Grid>
                    )}

                    {tempoptions?.resourse && (
                      <Grid item xs={12} md={6}>
                        <AutoFieldLocal
                          name="resourse"
                          title={tempwords?.resourse}
                          words={words}
                          options={resourses}
                          value={resovalue}
                          setSelectValue={selectResourse}
                          openAdd={openResourse}
                          isRTL={isRTL}
                          fullWidth
                          day={day}
                          mb={0}
                        ></AutoFieldLocal>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1}>
                {eventoptions?.status && (
                  <Grid item xs={12} style={{ marginTop: 5 }}>
                    <AutoFieldLocal
                      name="status"
                      title={words.status}
                      words={words}
                      options={eventStatus}
                      value={status}
                      setSelectValue={setStatus}
                      onNewFieldChange={onNewFieldChange}
                      noPlus
                      isRTL={isRTL}
                      fullWidth
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.retype && eventoptions?.retype && (
                  <Grid item xs={12}>
                    <AutoFieldLocal
                      name="retype"
                      title={tempwords?.retype}
                      words={words}
                      options={retypes.filter((dep: any) => dep.reType === 4)}
                      value={rtypevalue}
                      setSelectValue={selectRetype}
                      isRTL={isRTL}
                      fullWidth
                      mb={0}
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {!isEmployee &&
                  tempoptions?.retype2 &&
                  eventoptions?.retype2 && (
                    <Grid item xs={12}>
                      <AutoFieldLocal
                        name="retype"
                        title={tempwords?.retype2}
                        words={words}
                        options={retypes.filter((dep: any) => dep.reType === 5)}
                        value={rtype2value}
                        setSelectValue={selectRetype2}
                        isRTL={isRTL}
                        fullWidth
                        mb={0}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                {tempoptions?.sms && eventoptions?.sms && custvalue && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 5,
                      backgroundColor: '#eee',
                      borderRadius: 5,
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        marginBottom: 5,
                        fontSize: 14,
                        minWidth: 80,
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                      onClick={() => {
                        setSelected(null);
                        setOpenAction(true);
                      }}
                    >
                      {isRTL ? 'اضافة رسالة' : 'Add SMS'}
                    </Button>
                    <Paper style={{ height: 65, overflow: 'auto' }}>
                      {actionslist.map((act: any) => {
                        return (
                          <ListItem>
                            <ListItemText
                              primary={actionTypeFormatter({ row: act })}
                              secondary={getDateDayTimeFormat(
                                act.sendtime,
                                isRTL
                              )}
                            />
                            <IconButton
                              onClick={() => removeActionFromList(act)}
                              title="Delete row"
                              style={{ padding: 5 }}
                            >
                              <DeleteOutlinedIcon
                                style={{ fontSize: 22, color: '#a76f9a' }}
                              />
                            </IconButton>
                            <IconButton
                              style={{ padding: 5 }}
                              onClick={() => {
                                setSelected(act);
                                setOpenAction(true);
                              }}
                              title="Edit row"
                            >
                              <EditOutlinedIcon
                                style={{ fontSize: 22, color: '#729aaf' }}
                              />
                            </IconButton>
                          </ListItem>
                        );
                      })}
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {(isItems || itemsList?.length > 0) && (
            <Box
              style={{
                backgroundColor: '#f4f4f4',
                padding: 10,
                marginTop: 15,
                borderRadius: 10,
              }}
            >
              <Box display="flex">
                <ServiceItemForm
                  services={services}
                  products={products}
                  addItem={addItemToList}
                  words={words}
                  classes={classes}
                  user={user}
                  isRTL={isRTL}
                  taskv={taskvalue}
                  setAlrt={setAlrt}
                  itemrelations={tempoptions?.itemrelations}
                  datelist={[{ startDate, endDate }]}
                  day={day}
                ></ServiceItemForm>
              </Box>
              <Box>
                <ItemsTable
                  products={[...services, ...products]}
                  rows={itemsList}
                  editItem={editItemInList}
                  removeItem={removeItemFromList}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  itemrelations={tempoptions?.itemrelations}
                ></ItemsTable>
              </Box>
              {(isItems || itemsList?.length > 0) && (
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {words.total} : {moneyFormat(totals.amount)}
                </Typography>
              )}
            </Box>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {eventoptions?.location && (
                <>
                  <Grid item xs={12} md={4}>
                    <TextFieldLocal
                      name="address"
                      label={isRTL ? 'العنوان ' : 'Address'}
                      value={address}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        setAddress(value);
                        onNewFieldChange(
                          value && value.length > 0 ? value : null,
                          'address'
                        );
                      }}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFieldLocal
                      name="locationLink"
                      label={isRTL ? 'الموقع ' : 'Location'}
                      value={locationLink}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        setLocationLink(value);
                        onNewFieldChange(
                          value && value.length > 0 ? value : null,
                          'locationLink'
                        );
                      }}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {locationLink && (
                      <Box style={{ marginTop: 10 }}>
                        <LinkLocation
                          isRTL={isRTL}
                          value={locationLink}
                        ></LinkLocation>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextFieldLocal
                      name="houseno"
                      label={isRTL ? 'رقم البيت' : 'House No'}
                      value={houseno}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        setHouseno(value);
                        onNewFieldChange(
                          value && value.length > 0 ? value : null,
                          'houseno'
                        );
                      }}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextFieldLocal
                  name="desc"
                  label={isRTL ? 'الوصف ' : 'Description'}
                  value={desc}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    setDesc(value);
                    onNewFieldChange(value, 'desc');
                  }}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={selectCustomer}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={selectDepartment}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={selectEmployee}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={selectResourse}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
        <PopupAction
          open={openAction}
          onClose={() => {
            setOpenAction(false);
            setSelected(null);
          }}
          row={selected}
          type={1}
          isNew={selected ? false : true}
          customer={custvalue}
          addAction={addActionToList}
          editAction={editActionInList}
          theme={theme}
          event={{ ...row, startDate, endDate }}
        ></PopupAction>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
          ></AlertLocal>
        )}
      </Grid>
    </Box>
  );
};
