import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { editorToolbarMini } from '../common/helpers';

const DraftEditor = ({ value, setValue }) => {
  const [editorState, setEditorState] = useState(
    value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: any) => {
    setValue(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };
  console.log('value', value);
  return (
    <div>
      <Editor
        // toolbarOnFocus
        // toolbarHidden
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={editorToolbarMini}
      />
    </div>
  );
};

export default DraftEditor;
