/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, updateDocNumbers, userroles } from '../../common';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createFinance, deleteFinance, updateFinance } from '../../graphql';
import {
  accountFormatter,
  currencyFormatter,
  samllFormatter,
  timeFormatter,
} from '../../Shared/colorFormat';
import useAccounts from '../../hooks/useAccounts';
import { SearchTable } from '../../components';
import { ReceiptContext } from '../../contexts';
import getReceipts from '../../graphql/query/getReceipts';
import PopupReceipt from '../../pubups/PopupReceipt';
import useTasks from '../../hooks/useTasks';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import { getColumns } from '../../common/columns';
import { useTemplate } from '../../hooks';
import _ from 'lodash';
import { moneyFormat } from '../common/formater';

const Receipt = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme, company, start, end, drawerWidth } = props;

  const col = getColumns({ isRTL, words });

  const [columns] = useState([
    { name: 'time', title: words?.time },
    { name: 'docNo', title: words?.no },
    { name: 'refNo', title: words?.ref },
    { name: 'creditAcc', title: isRTL ? 'حساب الدفع' : 'Credit Acc' },
    { name: 'debitAcc', title: isRTL ? 'حساب القبض' : 'Receipt Acc' },
    col.customer,
    { name: 'desc', title: words?.description },
    { name: 'amount', title: words?.amount },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 120 },
    { columnName: 'docNo', width: 120 },
    { columnName: 'refNo', width: 120 },
    { columnName: 'creditAcc', width: 200 },
    { columnName: 'debitAcc', width: 200 },
    { columnName: col.customer.name, width: 200 },
    { columnName: 'desc', width: 200 },
    { columnName: 'amount', width: 120 },
  ]);

  const [rows, setRows] = useState([]);
  const [sum, setSum] = useState(0);

  const { tempoptions } = useTemplate();

  const { tasks } = useTasks();
  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(ReceiptContext);

  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const [loadFinances, financeData]: any = useLazyQuery(getReceipts, {
    fetchPolicy: 'cache-and-network',
  });
  const { accounts } = useAccounts();
  const refresQuery = {
    refetchQueries: [
      {
        query: getReceipts,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    if (start && end) {
      loadFinances({
        variables,
      });
    }
  }, [start, end]);

  const [addFinance] = useMutation(createFinance, refresQuery);
  const [editFinance] = useMutation(updateFinance, refresQuery);
  const [removeFinance] = useMutation(deleteFinance, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (financeData?.refetch) {
        financeData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeFinance({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };
  useEffect(() => {
    if (financeData?.data?.getReceipts?.data) {
      const { data } = financeData.data.getReceipts;
      const rdata = updateDocNumbers(data);
      const samount = _.sumBy(rdata, 'amount');
      setSum(samount);
      setRows(rdata);
    }
  }, [financeData]);

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 50,
          right: isRTL ? drawerWidth + 50 : undefined,
          bottom: 50,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          style={{
            color: '#333',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {isRTL ? ' المجموع ' : ' Total '}: {moneyFormat(sum)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'time',
            'docNo',
            'refNo',
            'creditAcc',
            'debitAcc',
            col.customer.name,
            'desc',
            'amount',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <DataTypeProvider
          for={['time']}
          formatterComponent={timeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['docNo', 'refNo']}
          formatterComponent={samllFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['creditAcc']}
          formatterComponent={(props) =>
            accountFormatter(props, accounts, isRTL)
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['debitAcc']}
          formatterComponent={(props) =>
            accountFormatter(props, accounts, isRTL)
          }
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.receipts?.edit}
          showDeleteCommand={userroles?.receipts?.delete}
          showAddCommand={userroles?.receipts?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing
          theme={theme}
          addAction={addFinance}
          editAction={editFinance}
        >
          <PopupReceipt company={company} tasks={tasks}></PopupReceipt>
        </PopupEditing>
      </Grid>
    </>
  );
});

export default Receipt;
