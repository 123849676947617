/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid, TextField } from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { errorAlertMsg } from '../Shared/helpers';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { packages } from '../constants';

const PopupSubscribtion = ({
  open,
  onClose,
  row,
  isNew,
  editAction,
  isRTL,
  theme,
  words,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { register, handleSubmit, errors, reset } = useForm();
  const [pack, setPack] = useState(null);
  const [discount, setDiscount] = useState(null);

  useEffect(() => {
    if (row && row._id) {
      if (row?.packId) {
        const pa = packages.filter((pi: any) => pi.id === row?.packId)?.[0];
        if (pa) {
          setPack(pa);
        }
      }
      if (row?.packDiscount) {
        setDiscount(row?.packDiscount);
      }
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    if (isNew) {
      await errorAlertMsg(setAlrt, 'valid email require');
      return;
    }
    const { name, nameAr, tel1 } = data;
    const variables = {
      _id: row._id,
      name,
      nameAr,
      tel1,
      packId: pack.id,
      packName: pack.name,
      packCost: pack.cost,
      packDiscount: discount,
    };
    apply(editAction, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      closeForm();
    } catch (error) {
      onError(error);
    }
  };
  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
    } else {
      reset();
      console.log(error);
    }
  };

  const resetAll = () => {
    setPack(null);
    setDiscount(null);
    reset();
  };

  const closeForm = () => {
    resetAll();
    onClose();
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const title = isRTL ? 'تعديل الشركة' : 'Edit Company';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      preventclose
      maxWidth="lg"
    >
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextFieldLocal
              required
              name="nameAr"
              label={words.name}
              register={register}
              errors={errors}
              row={row}
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLocal
              required
              name="name"
              ltr
              label={words.nameEn}
              register={register}
              errors={errors}
              row={row}
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLocal
              name="tel1"
              label={words.phoneNumber}
              register={register}
              errors={errors}
              row={row}
              fullWidth
              margin={20}
              mb={0}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <AutoFieldLocal
              name="subscribtion"
              title={isRTL ? 'Subscribtion' : 'الاشتراك'}
              words={words}
              options={packages}
              value={pack}
              setSelectValue={setPack}
              isRTL={isRTL}
              fullWidth
              mb={0}
            ></AutoFieldLocal>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              name="discount"
              label={words.discount}
              value={discount}
              onChange={(e: any) => setDiscount(Number(e.target.value))}
              variant="outlined"
              margin="dense"
              type="number"
              onFocus={(e) => e.target.select()}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </div>
    </PopupLayout>
  );
};

export default PopupSubscribtion;
