import { Box, Paper } from '@material-ui/core';
import { useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

function ImagesBx({ row, height }: any) {
  const images = row?.photos ? JSON.parse(row?.photos) : [];
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const refsize = 120;
  if (!images || images.length === 0) {
    return <Paper style={{ height, overflow: 'auto' }}></Paper>;
  }
  return (
    <Paper style={{ height, overflow: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginRight: 8,
        }}
      >
        {images.map((src: any, index: any) => (
          <Box>
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              width={refsize}
              height={refsize}
              key={index}
              style={{
                padding: 10,
                overflow: 'hidden',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              alt=""
            />
          </Box>
        ))}

        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.7)',
              zIndex: 150,
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </Paper>
  );
}

export default ImagesBx;
