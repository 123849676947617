import { gql } from '@apollo/client';

export default gql`
  mutation createDCategory(
    $branch: String
    $name: String
    $nameAr: String
    $disabled: Boolean
    $additionId: String
    $note: String
    $ref: String
    $perfix: String
  ) {
    createDCategory(
      branch: $branch
      name: $name
      nameAr: $nameAr
      disabled: $disabled
      additionId: $additionId
      note: $note
      ref: $ref
      perfix: $perfix
    ) {
      ok
      message
      data
      error
    }
  }
`;
