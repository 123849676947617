/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dublicateAlert, errorAlert, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal, TextFieldLocal } from '../components';
import { eventStatus, weekdaysNNo } from '../constants/datatypes';
import ItemsTable from '../Shared/ItemsTable';
import { useLazyQuery } from '@apollo/client';
import { getActions, getOperationItems } from '../graphql';
import { invoiceClasses } from '../themes/classes';
import {
  actionTypeFormatter,
  getDateDayTimeFormat,
  moneyFormat,
} from '../Shared/colorFormat';
import PopupAddMultiEvents from './PopupAddMultiEvents';
import PopupAppointInvoice from './PopupAppointInvoice';
import { getPopupTitle } from '../constants/menu';
import { useCustomers, useProducts, useTemplate } from '../hooks';
import PopupCustomer from './PopupCustomer';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import PopupResourses from './PopupResourses';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import { LinkLocation, userroles } from '../common';
import ServiceItemForm from '../Shared/ServiceItemForm';
import { successAlert } from '../Shared/helpers';
import useRetypes from '../hooks/useRetypes';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PopupAction from './PopupAction';
import { getEventLengthInM } from '../common/helpers';
import { uploadMultiPhotoOnline } from '../common/AvatarUpload';
import { ImageView } from '../components/viewer';
import { UploadPhotos } from '../common/UploadPhotos';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const PopupAppointmentCustomer = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  resourses,
  employees,
  departments,
  customers,
  servicesproducts,
  theme,
  company,
  tasks,
  name,
  value,
  calendar,
}: any) => {
  const classes = invoiceClasses();
  const [saving, setSaving] = useState(false);
  const [rtypevalue, setRtypevalue] = useState<any>(null);
  const [rtype2value, setRtype2value] = useState<any>(null);
  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);

  const [tminutes, setTMinutes]: any = useState(null);
  const [thours, setTHours]: any = useState(null);
  const [duration, setDuration]: any = useState(null);

  // const [eventLength, setEventLength]: any = useState(null);

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();
  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [custvalue, setCustvalue] = useState<any>(
    name === 'customerId' ? value : null
  );
  const [custError, setCustError] = useState<any>(false);
  const custRef: any = React.useRef();

  const [status, setStatus]: any = useState(null);

  const [totals, setTotals] = useState<any>({});
  const [itemsList, setItemsList] = useState<any>([]);

  const [rrule, setRrule] = useState<any>(null);

  const [openMulti, setOpenMulti] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [taskvalue, setTaskvalue] = useState<any>(
    name === 'contractId' ? value : null
  );
  const [locationLink, setLocationLink] = useState(null);
  const [address, setAddress] = useState(null);
  const [houseno, setHouseno] = useState(null);
  const [desc, setDesc] = useState(null);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [tasktitle, setTasktitle]: any = useState(null);

  const [newtext, setNewtext] = useState('');

  const [openCust, setOpenCust] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);

  const [actionslist, setActionslist] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [selected, setSelected] = useState(null);

  const [urls, setUrls] = useState([]);
  const [photosimages, setPhotosimages] = useState([]);
  const [photosurls, setPhotosurls] = useState([]);

  const { retypes } = useRetypes();
  const { addCustomer, editCustomer } = useCustomers();
  const { addDepartment, editDepartment } = useDepartments();
  const { addEmployee, editEmployee } = useEmployees();
  const { addResourse, editResourse } = useResourses();
  const { tempwords, tempoptions, eventoptions } = useTemplate();
  const { products } = useProducts();

  const { register, handleSubmit } = useForm({});
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const isemployee = user?.isEmployee && user?.employeeId;

  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadActions, actionsData]: any = useLazyQuery(getActions, {
    fetchPolicy: 'cache-and-network',
  });
  const openDepartment = () => {
    setOpenDep(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };
  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };

  const onNewCustChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };
  const onNewDepartChange = (nextValue: any) => {
    setDepartvalue(nextValue);
  };
  const onNewEmplChange = (nextValue: any) => {
    setEmplvalue(nextValue);
  };
  const onNewResoChange = (nextValue: any) => {
    setResovalue(nextValue);
  };

  useEffect(() => {
    let locals = [];
    let online = [];
    if (photosimages && photosimages.length > 0) {
      for (const img of photosimages) {
        const localimage = URL.createObjectURL(img);
        locals.push(localimage);
      }
    } else {
      locals = [];
    }
    if (photosurls && photosurls.length > 0) {
      online = photosurls;
    } else {
      online = [];
    }
    setUrls([...online, ...locals]);
  }, [photosimages, photosurls]);

  const addToPhotos = (photos: any) => {
    const lphotos = [...photosimages];
    const li = photosimages.length;
    const oi = photosurls.length;
    const n = 10 - (oi + li);
    if (n < 1 || !photos || photos.length === 0) {
      return;
    } else {
      const newphotos = [...lphotos, ...photos];
      const fnewphotos = newphotos.slice(0, n);
      setPhotosimages(fnewphotos);
    }
  };
  const removePhoto = (src: any, index: any) => {
    if (src.startsWith('http')) {
      const newphotosurls = [...photosurls];
      const newlist = newphotosurls.filter((nu: any) => nu !== src);
      setPhotosurls(newlist);
    } else {
      const newList = [...photosimages];
      const newindex = index - photosurls.length;
      newList.splice(newindex, 1);
      setPhotosimages(newList);
    }
  };

  useEffect(() => {
    if (name === 'contractId' && value) {
      setTaskvalue(value);
    }
  }, [open, value]);

  useEffect(() => {
    if (isemployee) {
      const emp = employees.filter(
        (em: any) => em._id === user.employeeId
      )?.[0];
      setEmplvalue(emp);
    }
  }, [user, employees]);

  useEffect(() => {
    if (isNew) {
      const mins = calendar?.eventlen || 60;
      const start = new Date();
      const end = new Date();
      start.setMinutes(0);
      end.setMinutes(end.getMinutes() + mins);
      setStartDate(start);
      setEndDate(end);
      setStatus(eventStatus.filter((es: any) => es.id === 2)?.[0]);
    }
  }, [open]);

  useEffect(() => {
    if (isNew) {
      const mins = calendar?.eventlen || 60;
      setDuration(mins);
      setTHours(Math.floor(mins / 60));
      setTMinutes(mins % 60);
    } else {
      if (startDate && endDate && !duration) {
        const mins = getEventLengthInM(startDate, endDate);
        setDuration(mins);
        setTHours(Math.floor(mins / 60));
        setTMinutes(mins % 60);
      }
    }
  }, [startDate, endDate, duration, open]);

  useEffect(() => {
    if (taskvalue) {
      if (taskvalue?.departmentId && name !== 'departmentId') {
        const dept = departments.filter(
          (dep: any) => dep._id === taskvalue?.departmentId
        )?.[0];
        setDepartvalue(dept);
      }
      if (taskvalue?.employeeId && name !== 'employeeId') {
        const dept = employees.filter(
          (dep: any) => dep._id === taskvalue?.employeeId
        )?.[0];
        setEmplvalue(dept);
      }
      if (taskvalue?.resourseId && name !== 'resourseId') {
        const dept = resourses.filter(
          (dep: any) => dep._id === taskvalue?.resourseId
        )?.[0];
        setResovalue(dept);
      }
      if (taskvalue?.customerId && name !== 'customerId') {
        const cust = customers.filter(
          (dep: any) => dep._id === taskvalue?.customerId
        )?.[0];
        setCustvalue(cust);
      }
      if (taskvalue?.retypeId) {
        const depart = retypes.filter(
          (dep: any) => dep._id === taskvalue.retypeId
        )[0];
        setRtypevalue(depart);
      }
      if (taskvalue?.retype2Id) {
        const depart = retypes.filter(
          (dep: any) => dep._id === taskvalue.retype2Id
        )[0];
        setRtype2value(depart);
      }
    }
  }, [taskvalue, open]);

  useEffect(() => {
    if (custvalue) {
      setLocationLink(
        custvalue.locationLink ? custvalue.locationLink : locationLink
      );
      setHouseno(custvalue.houseno ? custvalue.houseno : houseno);
      setAddress(custvalue.address ? custvalue.address : houseno);
    }
  }, [custvalue]);

  useEffect(() => {
    if (row && row._id) {
      const items = itemsData?.data?.['getOperationItems']?.data || [];
      const actions = actionsData?.data?.['getActions']?.data || [];

      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...servicesproducts, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );

        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            note,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            note,
            // itemtotalcost: item.qty * serv.cost,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        setItemsList(itemsWqtyprice);
        const listwithindex = indexTheList(actions);
        setActionslist(listwithindex);
      }
    }
  }, [itemsData, actionsData]);

  useEffect(() => {
    getOverallTotal();
  }, [itemsList]);

  useEffect(() => {
    if (row && row._id) {
      getItems({ variables: { opId: row._id } });
      loadActions({ variables: { eventId: row.id, type: 1 } });
      setTasktitle(row?.title);
      setDesc(row?.desc);
      const depId = row.departmentId;
      const empId = row.employeeId;
      const resId = row.resourseId;
      const custId = row.customerId;
      const contractId = row.contractId;
      const statNo = row.status;

      setStartDate(row?.startDate);
      setEndDate(row?.endDate);
      setRrule(row?.rRule);
      if (row?.retypeId) {
        const depart = retypes.filter(
          (dep: any) => dep._id === row.retypeId
        )[0];
        setRtypevalue(depart);
      }

      if (row?.retype2Id) {
        const depart = retypes.filter(
          (dep: any) => dep._id === row.retype2Id
        )[0];
        setRtype2value(depart);
      }
      if (row.attachments) {
        const phs = JSON.parse(row.attachments);
        if (phs && phs.length > 0) {
          setPhotosurls(phs);
        }
      }

      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }
      if (custId) {
        const cust = customers.filter((cu: any) => cu._id === custId)[0];
        setCustvalue(cust);
      }
      if (statNo) {
        const stat = eventStatus.filter((es: any) => es.id === statNo)[0];
        setStatus(stat);
      }
      if (contractId) {
        const tsk = tasks.filter((ts: any) => ts._id === contractId)[0];
        setTaskvalue(tsk);
      }
    }
  }, [row]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const addActionToList = (item: any) => {
    const newArray = [...actionslist, item];
    const listwithindex = indexTheList(newArray);
    setActionslist(listwithindex);
  };
  const editActionInList = (item: any) => {
    const newArray = actionslist.map((it: any) => {
      if (item._id) {
        if (it._id === item._id) {
          return item;
        } else {
          return it;
        }
      } else {
        if (it.index === item.index) {
          return item;
        } else {
          return it;
        }
      }
    });
    const listwithindex = indexTheList(newArray);
    setActionslist(listwithindex);
  };
  const removeActionFromList = (item: any) => {
    const newlist = actionslist.filter((il: any) => il.index !== item.index);
    const listwithindex = indexTheList(newlist);
    setActionslist(listwithindex);
  };

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum;
    const profit = sum - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
  };

  const resetAllForms = () => {
    setStartDate(null);
    setEndDate(null);
    setCustvalue(name === 'customerId' ? value : null);
    setDepartvalue(name === 'departmentId' ? value : null);
    setEmplvalue(name === 'employeeId' ? value : null);
    setResovalue(name === 'resourseId' ? value : null);
    setTaskvalue(name === 'contractId' ? value : null);
    setStatus(null);
    setRrule(null);
    setItemsList([]);
    setTotals({});
    setSaving(false);
    setTasktitle(null);
    setDuration(null);
    setTHours(null);
    setTMinutes(null);
    setRtypevalue(null);
    setRtype2value(null);
    setActionslist([]);
    setSelected(null);
    setPhotosimages([]);
    setUrls([]);
    setPhotosurls([]);
    setDesc(null);
  };

  const onSubmit = async () => {
    if (startDate > endDate) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    if (new Date(startDate).getDate() !== new Date(endDate).getDate()) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }

    setSaving(true);
    const rRule = rrule?.str ? rrule?.str : undefined;
    const title = tasktitle
      ? tasktitle
      : isRTL
      ? itemsList?.[0]?.nameAr
      : itemsList?.[0]?.name;

    const newList = !tempoptions?.itemrelations
      ? itemsList?.map((il: any) => {
          return {
            ...il,
            contractId: taskvalue?._id,
            contractName: taskvalue?.name,
            contractNameAr: taskvalue?.nameAr,
            departmentId: departvalue?._id,
            departmentName: departvalue?.name,
            departmentNameAr: departvalue?.nameAr,
            departmentColor: departvalue?.color,
            employeeId: emplvalue?._id,
            employeeName: emplvalue?.name,
            employeeNameAr: emplvalue?.nameAr,
            employeeColor: emplvalue?.color,
            employeePhone: emplvalue?.phone,
            resourseId: resovalue?._id,
            resourseName: resovalue?.name,
            resourseNameAr: resovalue?.nameAr,
            resourseColor: resovalue?.color,
          };
        })
      : itemsList;
    const items = JSON.stringify(newList);

    let localphotos = [];
    if (photosimages) {
      const photosurl = await uploadMultiPhotoOnline(photosimages);
      if (photosurl && photosurl.length > 0) {
        const rphotos = photosurl.map((photo: any) =>
          photo.replace('http://', 'https://')
        );
        localphotos = rphotos;
      }
    }

    const fphotos = [...photosurls, ...localphotos];
    const photos = JSON.stringify(fphotos);

    const variables: any = {
      id: row && row.id ? row.id : undefined, // is it new or edit
      title,
      startDate,
      endDate,
      locationLink: locationLink?.length > 0 ? locationLink : null,
      address,
      houseno: houseno?.length > 0 ? houseno : null,
      amount: totals.amount,
      status: status ? status.id : 2,
      items,
      attachments: fphotos && fphotos.length > 0 ? photos : null,
      actions: JSON.stringify(actionslist),
      rRule,
      showOnCal: true,
      desc,
      customer: custvalue
        ? {
            customerId: custvalue._id,
            customerName: custvalue.name,
            customerNameAr: custvalue.nameAr,
            customerPhone: custvalue.phone,
          }
        : {
            customerId: undefined,
            customerName: undefined,
            customerNameAr: undefined,
            customerPhone: undefined,
          },

      department: departvalue
        ? {
            departmentId: departvalue._id,
            departmentName: departvalue.name,
            departmentNameAr: departvalue.nameAr,
            departmentColor: departvalue.color,
          }
        : {
            departmentId: undefined,
            departmentName: undefined,
            departmentNameAr: undefined,
            departmentColor: undefined,
          },
      employee: emplvalue
        ? {
            employeeId: emplvalue._id,
            employeeName: emplvalue.name,
            employeeNameAr: emplvalue.nameAr,
            employeeColor: emplvalue.color,
            employeePhone: emplvalue.phone,
          }
        : {
            employeeId: undefined,
            employeeName: undefined,
            employeeNameAr: undefined,
            employeeColor: undefined,
            employeePhone: undefined,
          },
      resourse: resovalue
        ? {
            resourseId: resovalue._id,
            resourseName: resovalue.name,
            resourseNameAr: resovalue.nameAr,
            resourseColor: resovalue.color,
          }
        : {
            resourseId: undefined,
            resourseName: undefined,
            resourseNameAr: undefined,
            resourseColor: undefined,
          },
      contract: taskvalue
        ? {
            contractId: taskvalue._id,
            contractName: taskvalue.name,
            contractNameAr: taskvalue.nameAr,
          }
        : {
            contractId: undefined,
            contractName: undefined,
            contractNameAr: undefined,
          },
      project: taskvalue
        ? {
            projectId: taskvalue.projectId,
            projectName: taskvalue.projectName,
            projectNameAr: taskvalue.projectNameAr,
          }
        : {
            projectId: undefined,
            projectName: undefined,
            projectNameAr: undefined,
          },
      retype: rtypevalue
        ? {
            retypeId: rtypevalue._id,
            retypeName: rtypevalue.name,
            retypeNameAr: rtypevalue.nameAr,
            retypeColor: rtypevalue.color,
          }
        : {
            retypeId: undefined,
            retypeName: undefined,
            retypeNameAr: undefined,
            retypeColor: undefined,
          },
      retype2: rtype2value
        ? {
            retype2Id: rtype2value._id,
            retype2Name: rtype2value.name,
            retype2NameAr: rtype2value.nameAr,
            retype2Color: rtype2value.color,
          }
        : {
            retype2Id: undefined,
            retype2Name: undefined,
            retype2NameAr: undefined,
            retype2Color: undefined,
          },
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
    setSaving(false);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const date = row?.startDate ? new Date(row?.startDate) : new Date();
  const day = weekdaysNNo?.[date.getDay()];
  const title = getPopupTitle('appointment', isNew);

  const desabledSave = row.status === 10 || !userroles?.appointments?.add;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      mt={10}
      maxWidth="lg"
      mb={20}
    >
      <>
        <Box display="flex">
          <Typography style={{ fontWeight: 'bold' }} variant="body2">
            {row?.docNo}
          </Typography>
        </Box>

        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <CalenderLocal
                      isRTL={isRTL}
                      label={words.start}
                      value={startDate}
                      onChange={(d: any) => {
                        setStartDate(d);
                        const end = duration
                          ? new Date(d).getTime() + duration * 60 * 1000
                          : null;
                        if (end) {
                          setEndDate(new Date(end));
                        }
                      }}
                      format="dd/MM/yyyy - hh:mm"
                      time
                    ></CalenderLocal>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Box style={{ marginTop: 30, display: 'flex' }}>
                      <TextField
                        name="minutes"
                        onChange={(e: any) => {
                          const value = Number(e.target?.value);
                          if (value < 0) return;
                          setTMinutes(value);
                          const end = startDate
                            ? new Date(startDate).getTime() +
                              (value * 60 * 1000 || 0) +
                              (thours * 60 * 60 * 1000 || 0)
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                          }
                          setDuration((value || 0) + (thours * 60 || 0));
                        }}
                        value={tminutes}
                        label={`${words.duration} ${
                          isRTL ? 'دقيقة' : 'Minutes'
                        }`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                      />
                      <TextField
                        name="hours"
                        onChange={(e: any) => {
                          const value = Number(e.target?.value);
                          if (value < 0) return;
                          setTHours(value);
                          const end = startDate
                            ? new Date(startDate).getTime() +
                              (value * 60 * 60 * 1000 || 0) +
                              (tminutes * 60 * 1000 || 0)
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                          }
                          setDuration((value * 60 || 0) + (tminutes || 0));
                        }}
                        value={thours}
                        label={`${words.duration} ${isRTL ? 'ساعة' : 'Hours'}`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div style={{ pointerEvents: 'none', opacity: 0.5 }}>
                      <CalenderLocal
                        isRTL={isRTL}
                        label={words.end}
                        value={endDate}
                        onChange={(d: any) => setEndDate(d)}
                        format="dd/MM/yyyy - hh:mm"
                        time
                      ></CalenderLocal>
                    </div>
                  </Grid>
                  {eventoptions?.title && (
                    <Grid item xs={12}>
                      <TextFieldLocal
                        autoFocus={true}
                        name="tasktitle"
                        label={words.title}
                        value={tasktitle}
                        onChange={(e: any) => setTasktitle(e.target.value)}
                        row={row}
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <AutoFieldLocal
                      name="customer"
                      title={tempwords?.customer}
                      words={words}
                      options={customers}
                      value={custvalue}
                      setSelectValue={setCustvalue}
                      setSelectError={setCustError}
                      selectError={custError}
                      refernce={custRef}
                      register={register}
                      isRTL={isRTL}
                      openAdd={openCustomer}
                      fullWidth
                      showphone
                      disabled={name === 'customerId'}
                      mb={0}
                    ></AutoFieldLocal>
                  </Grid>
                  {tempoptions?.docrelations && (
                    <>
                      {tempoptions?.contract && (
                        <Grid item xs={6}>
                          <AutoFieldLocal
                            name="task"
                            title={tempwords?.task}
                            words={words}
                            options={tasks}
                            value={taskvalue}
                            setSelectValue={setTaskvalue}
                            register={register}
                            isRTL={isRTL}
                            fullWidth
                            disabled={name === 'contractId'}
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}
                      {tempoptions?.employee && (
                        <Grid item xs={6}>
                          <AutoFieldLocal
                            name="employee"
                            title={tempwords?.employee}
                            words={words}
                            options={employees.filter(
                              (emp: any) => !emp.disabled
                            )}
                            value={emplvalue}
                            setSelectValue={setEmplvalue}
                            setSelectError={setEmplError}
                            selectError={emplError}
                            refernce={emplRef}
                            register={register}
                            disabled={isemployee || name === 'employeeId'}
                            openAdd={openEmployee}
                            isRTL={isRTL}
                            fullWidth
                            day={day}
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}
                      {tempoptions?.department && (
                        <Grid item xs={6}>
                          <AutoFieldLocal
                            name="department"
                            title={tempwords?.department}
                            words={words}
                            options={departments.filter(
                              (d: any) => d.depType === 1
                            )}
                            value={departvalue}
                            setSelectValue={setDepartvalue}
                            setSelectError={setDepartError}
                            selectError={departError}
                            refernce={departRef}
                            register={register}
                            openAdd={openDepartment}
                            isRTL={isRTL}
                            fullWidth
                            disabled={name === 'departmentId'}
                          ></AutoFieldLocal>
                        </Grid>
                      )}

                      {tempoptions?.resourse && (
                        <Grid item xs={6}>
                          <AutoFieldLocal
                            name="resourse"
                            title={tempwords?.resourse}
                            words={words}
                            disabled={name === 'resourseId'}
                            options={resourses}
                            value={resovalue}
                            setSelectValue={setResovalue}
                            setSelectError={setResoError}
                            selectError={resoError}
                            refernce={resoRef}
                            register={register}
                            openAdd={openResourse}
                            isRTL={isRTL}
                            fullWidth
                            day={day}
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  {!isNew &&
                    itemsList?.length > 0 &&
                    eventoptions?.newinvoice && (
                      <Grid item xs={12} md={6}>
                        <Box
                          display="flex"
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <Button
                            size="medium"
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenInvoice(true)}
                            disabled={desabledSave}
                          >
                            <Typography>{words.addInvoice}</Typography>
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  {eventoptions?.status && (
                    <Grid item xs={12}>
                      <AutoFieldLocal
                        name="status"
                        title={words.status}
                        words={words}
                        options={eventStatus}
                        value={status}
                        setSelectValue={setStatus}
                        noPlus
                        isRTL={isRTL}
                        fullWidth
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.retype && eventoptions?.retype && (
                    <Grid item xs={12}>
                      <AutoFieldLocal
                        name="retype"
                        title={tempwords?.evretype}
                        words={words}
                        options={retypes.filter((dep: any) => dep.reType === 4)}
                        value={rtypevalue}
                        setSelectValue={setRtypevalue}
                        register={register}
                        isRTL={isRTL}
                        fullWidth
                        mb={0}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.retype2 && eventoptions?.retype2 && (
                    <Grid item xs={12}>
                      <AutoFieldLocal
                        name="retype2"
                        title={tempwords?.evretype2}
                        words={words}
                        options={retypes.filter((dep: any) => dep.reType === 5)}
                        value={rtype2value}
                        setSelectValue={setRtype2value}
                        register={register}
                        isRTL={isRTL}
                        fullWidth
                        mb={0}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.sms && eventoptions?.sms && custvalue && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: 5,
                        backgroundColor: '#eee',
                        borderRadius: 5,
                      }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          marginBottom: 5,
                          fontSize: 14,
                          minWidth: 80,
                          marginRight: 10,
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          setSelected(null);
                          setOpenAction(true);
                        }}
                      >
                        {isRTL ? 'اضافة رسالة' : 'Add SMS'}
                      </Button>
                      <Paper style={{ height: 65, overflow: 'auto' }}>
                        {actionslist.map((act: any) => {
                          return (
                            <ListItem>
                              <ListItemText
                                primary={actionTypeFormatter({ row: act })}
                                secondary={getDateDayTimeFormat(
                                  act.sendtime,
                                  isRTL
                                )}
                              />
                              <IconButton
                                onClick={() => removeActionFromList(act)}
                                title="Delete row"
                                style={{ padding: 5 }}
                              >
                                <DeleteOutlinedIcon
                                  style={{ fontSize: 22, color: '#a76f9a' }}
                                />
                              </IconButton>
                              <IconButton
                                style={{ padding: 5 }}
                                onClick={() => {
                                  setSelected(act);
                                  setOpenAction(true);
                                }}
                                title="Edit row"
                              >
                                <EditOutlinedIcon
                                  style={{ fontSize: 22, color: '#729aaf' }}
                                />
                              </IconButton>
                            </ListItem>
                          );
                        })}
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            style={{
              backgroundColor: '#f4f4f4',
              padding: 10,
              marginTop: 15,
              marginBottom: 15,
              borderRadius: 10,
            }}
          >
            <Box display="flex">
              <ServiceItemForm
                services={servicesproducts}
                products={products}
                addItem={addItemToList}
                words={words}
                classes={classes}
                user={user}
                isRTL={isRTL}
                setAlrt={() => null}
                taskv={taskvalue}
                itemrelations={tempoptions?.itemrelations}
                day={day}
              ></ServiceItemForm>
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <ItemsTable
                products={[...servicesproducts, ...products]}
                rows={itemsList}
                editItem={editItemInList}
                removeItem={removeItemFromList}
                isRTL={isRTL}
                words={words}
                user={user}
                itemrelations={tempoptions?.itemrelations}
              ></ItemsTable>
            </Box>
            {itemsList?.length > 0 && (
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {words.total} : {moneyFormat(totals.amount)}
              </Typography>
            )}
          </Box>

          {eventoptions?.location && (
            <>
              <Grid item xs={12} md={4}>
                <TextFieldLocal
                  name="address"
                  label={isRTL ? 'العنوان ' : 'Address'}
                  value={address}
                  onChange={(e: any) => setAddress(e.target.value)}
                  fullWidth
                  mb={0}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLocal
                  name="locationLink"
                  label={isRTL ? 'الموقع ' : 'Location'}
                  value={locationLink}
                  onChange={(e: any) => setLocationLink(e.target.value)}
                  fullWidth
                  mb={0}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextFieldLocal
                  name="houseno"
                  label={isRTL ? 'رقم البيت' : 'House No'}
                  value={houseno}
                  onChange={(e: any) => setHouseno(e.target.value)}
                  fullWidth
                  mb={0}
                />
              </Grid>
              {locationLink && (
                <Grid item xs={12} md={2}>
                  {locationLink && (
                    <LinkLocation
                      isRTL={isRTL}
                      value={locationLink}
                    ></LinkLocation>
                  )}
                </Grid>
              )}
            </>
          )}
          {tempoptions?.docdescription && (
            <>
              <Grid item xs={10}>
                <TextField
                  name="desc"
                  onChange={(e: any) => setDesc(e.target.value)}
                  value={desc}
                  label={words.notes}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  multiline
                  rowsMax={2}
                  rows={2}
                  style={{ marginTop: 0 }}
                />
              </Grid>
            </>
          )}
          {tempoptions?.eventattach && (
            <Grid item xs={12} md={4}>
              <Paper
                style={{
                  height: 100,
                  margin: 5,
                }}
              >
                <ImageView
                  images={urls}
                  removePhoto={removePhoto}
                  height={100}
                  small
                ></ImageView>
              </Paper>
              <Box
                style={{
                  display: 'flex',
                  flex: 1,
                  height: 40,
                  width: 200,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 5,
                }}
              >
                <UploadPhotos
                  setImages={addToPhotos}
                  isRTL={isRTL}
                ></UploadPhotos>
              </Box>
            </Grid>
          )}
        </Grid>
        <PopupAddMultiEvents
          open={openMulti}
          onClose={() => setOpenMulti(false)}
          onSubmit={setRrule}
          theme={theme}
          isRTL={isRTL}
          words={words}
        ></PopupAddMultiEvents>
        <PopupAppointInvoice
          open={openInvoice}
          onClose={() => setOpenInvoice(false)}
          onCloseAppoint={onCloseForm}
          appoint={row}
          services={[...servicesproducts, ...products]}
          editEvent={editAction}
          company={company}
          theme={theme}
          items={itemsList}
        ></PopupAppointInvoice>
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
        <PopupAction
          open={openAction}
          onClose={() => {
            setOpenAction(false);
            setSelected(null);
          }}
          row={selected}
          type={1}
          isNew={selected ? false : true}
          customer={custvalue}
          addAction={addActionToList}
          editAction={editActionInList}
          theme={theme}
          event={{ ...row, startDate, endDate }}
        ></PopupAction>
      </>
    </PopupLayout>
  );
};

export default PopupAppointmentCustomer;
