/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export const SelectLocal = ({
  options,
  value,
  onChange,
  isRTL,
  width = 150,
  height = 40,
  disabled = false,
  fullWidth,
  label,
}: any) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        style={{
          marginTop: isRTL ? -8 : undefined,
          paddingRight: isRTL ? 10 : undefined,
        }}
        id="simple-select-label"
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        value={value}
        disabled={disabled}
        onChange={onChange}
        variant="outlined"
        fullWidth={fullWidth}
        labelId="simple-select-label"
        style={{
          height,
          width: fullWidth ? undefined : width,
          alignSelf: 'flex-end',
          fontWeight: 'bold',
          color: disabled ? '#bbb' : '#555',
        }}
      >
        {options.map((item: any) => {
          return (
            <MenuItem
              key={item.id}
              style={{ justifyContent: isRTL ? 'flex-end' : 'flex-start' }}
              value={item.value}
            >
              {isRTL ? item.nameAr : item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
