/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  PagingPanel,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, userroles } from '../../common';
import { PopupMSGTemplate } from '../../pubups';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import PageLayout from '../main/PageLayout';
import { Box, Paper, Typography } from '@material-ui/core';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { TableComponent } from '../../Shared/TableComponent';
import useMSGTemplates from '../../hooks/useMSGTemplates';
import { msgTypeFormat } from '../../Shared/colorFormat';
import { useTemplate } from '../../hooks';

export default function MSGTemplates(props: any) {
  const { isRTL, words, menuitem, theme } = props;
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const {
    msgtemplates,
    refreshmsgtemplates,
    addMSGTemplate,
    editMSGTemplate,
    removeMSGTemplate,
    loading,
  } = useMSGTemplates();

  const [columns] = useState([
    { name: 'name', title: words.name },
    { name: 'msgtype', title: words.type },
    { name: 'subject', title: words.subject },
    { name: 'body', title: words.body },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'name', width: 250 },
    { columnName: 'msgtype', width: 150 },
    { columnName: 'subject', width: 200 },
    { columnName: 'body', width: 300 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'name', togglingEnabled: false },
  ]);
  const { width, height } = useWindowDimensions();
  const { tempoptions } = useTemplate();

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeMSGTemplate({ variables: { _id } });
      if (res?.data?.deleteMSGTemplate?.ok === false) {
        if (res?.data?.deleteMSGTemplate?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refreshmsgtemplates}
      loading={loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <Paper
          elevation={5}
          style={{
            margin: 40,
            marginTop: 80,
            overflow: 'auto',
            width: width - 330,
            // height: height - 200,
            borderRadius: 10,
          }}
        >
          <Grid rows={msgtemplates} columns={columns} getRowId={getRowId}>
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SearchState />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <DragDropProvider />
            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 60 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnReordering
              defaultOrder={['name', 'msgtype', 'subject', 'body']}
            />
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnVisibility
              columnExtensions={tableColumnVisibilityColumnExtensions}
              defaultHiddenColumnNames={[]}
            />
            <TableColumnVisibility />
            <TableEditColumn
              showEditCommand={userroles?.msgtemplates?.edit}
              showDeleteCommand={userroles?.msgtemplates?.delete}
              showAddCommand={userroles?.msgtemplates?.add}
              commandComponent={Command}
            ></TableEditColumn>
            <DataTypeProvider
              for={['msgtype']}
              formatterComponent={(props: any) =>
                msgTypeFormat({ ...props, isRTL })
              }
            ></DataTypeProvider>
            <Toolbar />
            <ColumnChooser />
            <PagingPanel
              pageSizes={
                tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
              }
            />
            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />
            <PopupEditing
              theme={theme}
              addAction={addMSGTemplate}
              editAction={editMSGTemplate}
            >
              <PopupMSGTemplate></PopupMSGTemplate>
            </PopupEditing>
          </Grid>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
      </Box>
    </PageLayout>
  );
}
