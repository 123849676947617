import { gql } from '@apollo/client';

export default gql`
  mutation payInvoices($invoiceIds: [String], $paymentType: String) {
    payInvoices(invoiceIds: $invoiceIds, paymentType: $paymentType) {
      ok
      message
      data
      error
    }
  }
`;
