import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import MonthsChart from '../MonthsChart';
import { useTemplate } from '../../../../hooks';

function MonthsEvents({ isRTL, theme, systemcolor, color, height }) {
  const { eventMonths } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });
  const { tempwords } = useTemplate();

  return (
    <Box>
      {eventMonths && userroles?.appointments?.admin && (
        <MonthsChart
          data={eventMonths}
          isRTL={isRTL}
          color={clr}
          dataKey="count"
          title={
            isRTL
              ? `${tempwords?.appointments} بحسب الشهور`
              : `Months ${tempwords?.appointments}`
          }
          height={height}
        ></MonthsChart>
      )}
    </Box>
  );
}

export default MonthsEvents;
