/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { PopupTextField } from '../../Shared';

const CalendarOptionsPopup = ({
  words,
  calendar,
  setCalendar,
  isRTL,
  isEmployee,
}: any) => {
  const setKeyValue = (key: any, value: any) => {
    setCalendar({
      ...calendar,
      [key]: Number(value),
    });
  };
  const setGroup = (value: any) => {
    setCalendar({
      ...calendar,
      group: value,
    });
  };
  return (
    <Box padding={3}>
      <Typography variant="h6">
        {isRTL ? 'تعديل التقويم' : 'Edit Calendar'}
      </Typography>
      <Box
        style={{
          marginTop: 20,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PopupTextField
              required
              name="start"
              label={words?.start}
              value={calendar?.start}
              onChange={(e: any) => setKeyValue('start', e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PopupTextField
              required
              name="end"
              label={words?.end}
              value={calendar?.end}
              onChange={(e: any) => setKeyValue('end', e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PopupTextField
              required
              name="duration"
              label={words?.duration}
              value={calendar?.duration}
              onChange={(e: any) => setKeyValue('duration', e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PopupTextField
              required
              name="eventlen"
              label={isRTL ? 'مدة الموعد' : 'Event Length'}
              value={calendar?.eventlen}
              onChange={(e: any) => setKeyValue('eventlen', e.target.value)}
              type="number"
            />
          </Grid>
          {!isEmployee && (
            <Grid item xs={12} md={6} style={{ marginTop: 5 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={calendar?.group}
                    onChange={() => setGroup(!calendar?.group)}
                    name="bold"
                    color="primary"
                  />
                }
                label={isRTL ? 'استعراض مجمع' : 'Grouped View'}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default CalendarOptionsPopup;
