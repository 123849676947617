/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import React from 'react';
import MyIcon from './MyIcon';

export default function FastMenu({
  isRTL,
  primary,
  navigate,
  tempwords,
  userroles,
  addNewAppointment,
  addNewInvoice,
  addNewReceipt,
  addNewExpenses,
  addNewFinance,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: any) => {
    if (navigate) {
      navigate(path);
    }
    setAnchorEl(null);
  };
  const handleFunc = (func: any) => {
    func();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-fastmenu' : undefined;

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        aria-describedby={id}
        style={{
          height: 36,
          width: 36,
          marginLeft: 22,
          marginRight: 22,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ccc',
        }}
      >
        <MyIcon icon="menu" size={22} color={primary}></MyIcon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ style: { borderRadius: 15, padding: 10 } }}
      >
        <Grid container spacing={2}>
          <Grid xs={6}>
            <List
              style={{
                minWidth: 210,
                padding: 10,
                direction: isRTL ? 'rtl' : 'ltr',
              }}
            >
              {userroles?.purchase?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/supbalnce')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL
                        ? `ارصدة ${tempwords?.suppliers}`
                        : `${tempwords?.suppliers} Balance`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.purchase?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/suppliers')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL
                        ? `تقرير ${tempwords?.suppliers}`
                        : `${tempwords?.suppliers} Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.purchase?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/purchase')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL ? `تقرير المشتريات` : `Purchase Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.expenses?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/expenses')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL ? `تقرير المصروفات` : `Expenses Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              <Divider></Divider>
              {userroles?.finance?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/finance')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL ? 'تقرير الحسابات' : `Accountant Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.finance?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/profit')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL ? 'تقرير الربحية' : `Profit Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              <Divider></Divider>
              {userroles?.finance?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/budget')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL ? 'الميزانية' : `Budget Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid xs={6}>
            <List
              style={{
                minWidth: 210,
                padding: 10,
                direction: isRTL ? 'rtl' : 'ltr',
              }}
            >
              {userroles?.sales?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/sales')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL ? `تقرير المبيعات` : `Salse Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.customers?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/cusbalnce')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL
                        ? `ارصدة ${tempwords?.customers}`
                        : `${tempwords?.customers} Balance`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.customers?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/customers')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL
                        ? `تقرير ${tempwords?.customers}`
                        : `${tempwords?.customers} Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.appointments?.admin && (
                <ListItem
                  button
                  onClick={() => handleNavigation('/reports/tasks')}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemText>
                    <Typography>
                      {isRTL
                        ? `تقرير ${tempwords?.tasks}`
                        : `${tempwords?.tasks} Report`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              <Divider></Divider>
              {userroles?.finance?.add && (
                <ListItem
                  button
                  onClick={() => handleFunc(addNewFinance)}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemIcon style={{ marginLeft: -20 }}>
                    <MyIcon icon="cash" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {isRTL ? 'انشاء حركة مالية' : 'Add Finance'}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.expenses?.add && (
                <ListItem
                  button
                  onClick={() => handleFunc(addNewExpenses)}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemIcon style={{ marginLeft: -20 }}>
                    <MyIcon icon="expenses" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {isRTL ? 'انشاء مصروف' : 'Add Expenses'}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              <Divider></Divider>
              {userroles?.finance?.add && (
                <ListItem
                  button
                  onClick={() => handleFunc(addNewReceipt)}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemIcon style={{ marginLeft: -20 }}>
                    <MyIcon icon="card" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {isRTL ? 'انشاء سند قبض' : 'Add Receipt'}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
              {userroles?.sales?.add && (
                <ListItem
                  button
                  onClick={() => handleFunc(addNewInvoice)}
                  style={{ margin: 5, borderRadius: 7 }}
                >
                  <ListItemIcon style={{ marginLeft: -20 }}>
                    <MyIcon icon="sales" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {isRTL ? `انشاء فاتورة` : `Add Invoice`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
}
