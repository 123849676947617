/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper, useMediaQuery } from '@material-ui/core';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import RefetchBox from './RefetchBox';
import getSalaryCalculation from '../graphql/query/getSalaryCalculation';
import { getEndOfMonth, getStartOfMonth } from './helpers';
import { useTemplate } from '../hooks';
import SalaryBoxFree from './SalaryBoxFree';
import { renderCell } from '../Sections/generator/page/renders';

export default function MainEmployee({
  isRTL,
  words,
  theme,
  name,
  id,
  value: row,
  width,
  height,
  start,
  end,
  sStart,
  setSStart,
  sEnd,
  setSEnd,
  company,
  user,
  isCont,
}: any) {
  const [data, setData] = useState<any>(null);
  const [sdata, setSdata] = useState<any>(null);

  const [salary, setSalary] = useState<any>(0);
  const [custody, setCustody] = useState<any>(0);
  const [advanced, setAdvanced] = useState<any>(0);

  const { tempwords, employeeoptions, eventoptions } = useTemplate();

  const template = company?.emptemp ? JSON.parse(company?.emptemp) : [];

  const [loadCalcss, calcsData]: any = useLazyQuery(getGereralCalculation, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadSladata, calcsSaldata]: any = useLazyQuery(getSalaryCalculation, {
    fetchPolicy: 'cache-and-network',
  });

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const variables = {
      [name]: id,
      start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
      end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
    };
    loadCalcss({
      variables,
    });
  }, [id, start, end]);

  useEffect(() => {
    if (!sStart) {
      const somn = getStartOfMonth(null);
      setSStart(somn);
    }
    if (!sEnd) {
      const eom = getEndOfMonth(null);
      setSEnd(eom);
    }
  }, []);

  useEffect(() => {
    const variables = {
      [name]: id,
      start: sStart ? new Date(sStart).setHours(0, 0, 0, 0) : undefined,
      end: sEnd ? new Date(sEnd).setHours(23, 59, 59, 999) : undefined,
    };
    loadSladata({
      variables,
    });
  }, [sStart, sEnd]);

  useEffect(() => {
    const res = calcsData?.data?.getGereralCalculation?.data;
    if (res) {
      const data = JSON.parse(res);
      setData(data);
    }
  }, [calcsData, start, end]);

  useEffect(() => {
    const res = calcsSaldata?.data?.getSalaryCalculation?.data;
    if (res) {
      const data = JSON.parse(res);
      setSdata(data);
    }
  }, [calcsSaldata, sStart, sEnd]);

  // eslint-disable-next-line no-sequences
  const refresh = () => (calcsData?.refetch(), calcsSaldata?.refetch());

  const loading = calcsData.loading;
  return (
    <Box
      style={{
        height: height - 250,
        width: width - 300,
        margin: 10,
      }}
    >
      <Box
        style={{
          position: 'absolute',
          width: 50,
          height: 50,
          left: isRTL ? 220 : undefined,
          right: isRTL ? undefined : 220,
          zIndex: 111,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 55,
        }}
      >
        <RefetchBox
          isRTL={isRTL}
          theme={theme}
          refresh={refresh}
          loading={loading}
        ></RefetchBox>
      </Box>
      <Paper
        style={{
          height: height - 260,
          width: width - 320,
          overflow: 'auto',
          padding: 10,
          backgroundColor: '#f5f5f5',
        }}
      >
        {template?.map((listitem: any) => {
          return (
            <Grid container spacing={2}>
              {listitem?.map((item: any) => {
                const { size, type } = item;
                const args = {
                  ...item,
                  isRTL,
                  theme,
                  words,
                  company,
                  name,
                  id,
                  row: { ...row, ...data },
                };
                return (
                  <Grid item xs={12} md={size} style={{ marginBottom: 10 }}>
                    {renderCell(type, args, isMobile)}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
        {sdata && (
          <Grid item xs={6}>
            <SalaryBoxFree
              data={sdata}
              isRTL={isRTL}
              height={height - 290}
              start={sStart}
              setStart={setSStart}
              end={sEnd}
              setEnd={setSEnd}
              words={words}
              salary={salary}
              setSalary={setSalary}
              custody={custody}
              setCustody={setCustody}
              advanced={advanced}
              setAdvanced={setAdvanced}
              company={company}
              user={user}
              row={row}
              isCont={isCont}
              tempwords={tempwords}
              employeeoptions={employeeoptions}
              eventoptions={eventoptions}
            ></SalaryBoxFree>
          </Grid>
        )}
      </Paper>
    </Box>
  );
}
