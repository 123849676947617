export const colorsList = [
  '#00ff00',
  '#008000',
  '#065535',
  '#008080',
  '#088da5',
  '#20b2aa',
  '#00ced1',
  '#00ffff',
  '#40e0d0',
  '#5ac18e',
  '#66cdaa',
  '#66cccc',
  '#81d8d0',
  '#7fe5f0',
  '#7fffd4',
  '#6897bb',
  '#4ca3dd',
  '#468499',
  '#407294',
  '#576675',
  '#133337',
  '#0e2f44',
  '#003366',
  '#000080',
  '#0000ff',
  '#420420',
  '#660066',
  '#800080',
  '#8a2be2',
  '#ff00ff',
  '#f6546a',
  '#f7347a',
  '#ff80ed',
  '#ffb6c1',
  '#ffc0cb',
  '#e6e6fa',
  '#f0f8ff',
  '#c6e2ff',
  '#c0d6e4',
  '#b0e0e6',
  '#afeeee',
  '#b6fcd5',
  '#b4eeb4',
  '#d3ffce',
  '#dcedc1',
  '#cbcba9',
  '#cbbeb5',
  '#c39797',
  '#c0c0c0',
  '#cccccc',
  '#dddddd',
  '#ffe4e1',
  '#f5f5f5',
  '#f5f5f5',
  '#ffffff',
  '#ccff00',
  '#bada55',
  '#daa520',
  '#ffa500',
  '#ffd700',
  '#ffff00',
  '#ffff66',
  '#fff68f',
  '#f5f5dc',
  '#faebd7',
  '#ffdab9',
  '#ffc3a0',
  '#ff7f50',
  '#f08080',
  '#fa8072',
  '#ff7373',
  '#ff6666',
  '#ff4040',
  '#ff0000',
  '#990000',
  '#8b0000',
  '#800000',
  '#696966',
  '#808080',
  '#696969',
  '#666666',
  '#333333',
  '#101010',
  '#000000',
];

export const colors = [
  '#008000',
  '#065535',
  '#008080',
  '#20b2aa',
  '#00ced1',
  '#7fffd4',
  '#25CED1',
  '#407294',
  '#044660',
  '#003366',
  '#0b1f33',
  '#20A4F3',
  '#0070f7',
  '#3843d2',
  '#3454D1',
  '#0000ff',
  '#151060',
  '#000080',
  '#7678ed',
  '#40008d',
  '#8a2be2',
  '#800080',
  '#f7347a',
  '#ff80ed',
  '#ff00ff',
  '#ffc0cb',
  '#f6546a',
  '#ff6666',
  '#ff4040',
  '#ff0000',
  '#990000',
  '#800000',
  '#420420',
  '#58221c',
  '#844257',
  '#a7967e',
  '#ffa500',
  '#ffd700',
  '#ffff66',
  '#ccff00',
  '#00ff00',
  '#7b7b7d',
  '#333333',
  '#101010',
  '#000000',
  '#e6e6fa',
  '#f0f8ff',
  '#c6e2ff',
  '#c0d6e4',
  '#b0e0e6',
  '#afeeee',
  '#b6fcd5',
  '#b4eeb4',
  '#d3ffce',
  '#dcedc1',
  '#cbcba9',
  '#cbbeb5',
  '#c39797',
  '#c0c0c0',
  '#cccccc',
  '#dddddd',
  '#ffe4e1',
  '#f5f5f5',
  '#f5f5f5',
  '#ffffff',
];

export const colorsList2 = [
  '#000000',
  '#241f20',
  '#2c2c2c',
  '#4c4c4c',
  '#7b7b7d',
  '#a7967e',
  '#340100',
  '#58221c',
  '#844257',
  '#853b92',
  '#9b59b6',
  '#e65415',
  '#ff9a00',
  '#ffdc00',
  '#FFFD77',
  '#eac87c',
  '#f5998e',
  '#fcb69a',
  '#495b53',
  '#016748',
  '#79963c',
  '#6ead50',
  '#4faa6d',
  '#08b3ab',
  '#3097bf',
  '#667fb5',
  '#25CED1',
  '#20A4F3',
  '#7678ed',
  '#0070f7',
  '#3843d2',
  '#3454D1',
  '#2c3996',
  '#044660',
  '#40008d',
  '#151060',
  '#0b1f33',
  '#987284',
  '#754668',
  '#C1CFDA',
  '#B7AD99',
  '#8963BA',
  '#54428E',
  '#5A2B8D',
  '#F6685D',
  '#FF4365',
  '#B0228C',
  '#c0392b',
  '#fff',
];
