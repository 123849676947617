import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CalenderLocal, TextFieldLocal } from '../components';
import { SalaryPrintFree } from '../print/SalaryPrintFree';

import { moneyFormat } from './colorFormat';

const renderLine = ({ title, amount, qty = '', min = false }: any) => {
  return (
    <>
      {min === false && (
        <>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>{qty}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography style={{ fontWeight: 'bold', textAlign: 'right' }}>
              {moneyFormat(amount)}
            </Typography>
          </Grid>
        </>
      )}
      {min === true && (
        <>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <Typography
              style={{ fontWeight: 'bold', color: min ? 'tomato' : undefined }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                fontWeight: 'bold',
                textAlign: 'right',
                color: min ? 'tomato' : undefined,
              }}
            >
              {moneyFormat(amount)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{qty}</Typography>
          </Grid>
        </>
      )}
    </>
  );
};

const renderSalarydata = ({
  isRTL,
  basicSalary,
  extraSalary,
  absentCharge,
  overtimecharge,
  bonos,
  percentageSale,
  percentageWork,
  salary,
  employeeoptions,
  handleContReactPrint,
  plus,
  minus,
  totalplus,
  totalminus,
}: any) => {
  return (
    <Grid container spacing={1} style={{ marginTop: 15 }}>
      <Grid item xs={12}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" style={{ marginLeft: 20, marginRight: 20 }}>
            {isRTL ? 'حساب الراتب' : 'Salary Calculator'}
          </Typography>
          <Button
            onClick={handleContReactPrint}
            variant="contained"
            color="primary"
            style={{ minWidth: 100, marginLeft: 20, marginRight: 20 }}
          >
            <Typography>{isRTL ? 'طباعة' : 'Print'}</Typography>
          </Button>
        </Box>
        <Divider style={{ margin: 10 }}></Divider>
      </Grid>

      {renderLine({
        title: isRTL ? 'الراتب الاساسي' : 'Basic Salary',
        amount: basicSalary,
      })}
      {employeeoptions?.salextra &&
        renderLine({
          title: isRTL ? 'البدلات' : 'Allowances',
          amount: extraSalary,
        })}
      {employeeoptions?.salworkpercent &&
        renderLine({
          title: isRTL ? 'نسبة على الانجاز' : 'Work Percentage',
          amount: percentageWork,
        })}
      {employeeoptions?.salsalespercent &&
        renderLine({
          title: isRTL ? 'نسبة على الدخل' : 'Income Percentage',
          amount: percentageSale,
        })}
      {employeeoptions?.salovertime &&
        renderLine({
          title: isRTL ? 'ساعات اضافي' : 'Overtime',
          amount: overtimecharge,
        })}
      {employeeoptions?.salbonos &&
        renderLine({
          title: isRTL ? 'المكافأت' : 'Bonos',
          amount: bonos,
        })}
      {employeeoptions?.salabsent &&
        renderLine({
          title: isRTL ? 'غياب' : 'Absent',
          amount: absentCharge,
        })}
      {plus?.value > 0 &&
        renderLine({
          title: plus?.title,
          amount: plus?.value,
        })}
      {minus?.value > 0 &&
        renderLine({
          title: minus?.title,
          amount: minus?.value,
          min: true,
        })}
      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={6}>
        <Typography style={{ fontWeight: 'bold' }}>
          {isRTL ? 'المجموع' : 'Total'}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          style={{
            fontWeight: 'bold',
            textAlign: 'right',
          }}
        >
          {moneyFormat(totalminus)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          style={{
            fontWeight: 'bold',
            textAlign: 'right',
          }}
        >
          {moneyFormat(totalplus)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider style={{ margin: 10 }}></Divider>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={8}>
        <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
          {isRTL ? 'صافي الراتب' : 'Net Salary'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'right',
          }}
        >
          {moneyFormat(salary)}
        </Typography>
      </Grid>
    </Grid>
  );
};

function SalaryBoxFree({
  data,
  isRTL,
  words,
  start,
  setStart,
  end,
  setEnd,
  row,
  tempwords,
  employeeoptions,
  eventoptions,
  company,
  user,
}: any) {
  const [plus, setPlus] = useState({ title: '', value: 0 });
  const [minus, setMinus] = useState({ title: '', value: 0 });
  const { income, work, events } = data;
  const absent = events.filter((ev: any) => ev.absent)?.length;
  const fevents = events.filter((ev: any) => ev.status === 10);
  const overtime = _.sumBy(fevents, 'overtime');

  const { basicSalary, extraSalary, absentcost, overtimecost, comPercent } =
    row;
  const absentCharge = eventoptions?.absent
    ? absent
      ? absent * (absentcost ? absentcost : 50)
      : 0
    : 0;
  const overtimecharge = eventoptions?.overtime
    ? overtime
      ? overtime * (overtimecost ? overtimecost : basicSalary / 160)
      : 0
    : 0;
  const bsalary = basicSalary || 0;
  const xsalary = extraSalary || 0;
  const bonos = employeeoptions?.salbonos ? _.sumBy(fevents, 'bonos') : 0;

  const totalwork = _.sumBy(work, 'total');
  const totalsales = _.sumBy(income, 'credit');
  const totalevents = _.sumBy(events, 'amount');
  let percentageSale = 0;
  let percentageWork = 0;
  let precentage = 0;
  if (comPercent && employeeoptions?.salsalespercent) {
    percentageSale = (totalsales * comPercent) / 100;
  }
  if (comPercent && employeeoptions?.salworkpercent) {
    percentageWork = (totalwork * comPercent) / 100;
  }
  if (employeeoptions?.salworkpercent) {
    precentage = precentage + percentageWork;
  }
  if (employeeoptions?.salsalespercent) {
    precentage = precentage + percentageSale;
  }

  const plusvalue = plus?.value || 0;
  const minusvalue = minus?.value || 0;

  const totalplus =
    bsalary + xsalary + overtimecharge + bonos + precentage + plusvalue;
  const totalminus = absentCharge + minusvalue;

  const sal = totalplus - totalminus;
  const allevents = events.length;
  const doneevents = fevents.length;

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Salary - ${isRTL ? row?.nameAr : row?.name}`,
    removeAfterPrint: true,
  });
  const printData = {
    row,
    basicSalary,
    extraSalary,
    absentCharge,
    overtimecharge,
    bonos,
    totalwork,
    totalsales,
    percentageSale,
    percentageWork,
    plus,
    minus,
    isRTL,
    salary: sal,
    totalplus,
    totalminus,
  };

  return (
    <Paper
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff',
        overflow: 'auto',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Box style={{ marginTop: 20 }}></Box>
      <Paper elevation={5} style={{ margin: 10, paddingBottom: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <CalenderLocal
              isRTL={isRTL}
              label={words.start}
              value={start}
              onChange={(value: any) => setStart(value)}
            ></CalenderLocal>
          </Grid>
          <Grid item xs={5}>
            <CalenderLocal
              isRTL={isRTL}
              label={words.end}
              value={end}
              onChange={(value: any) => setEnd(value)}
            ></CalenderLocal>
          </Grid>
          <Grid item xs={1}></Grid>
          <Divider></Divider>
          {employeeoptions?.salevents &&
            renderLine({
              amount: totalevents,
              title: `${isRTL ? 'اجمالي' : 'Total'} ${tempwords.appointments}`,
              qty: allevents,
            })}
          {employeeoptions?.salevents &&
            renderLine({
              amount: totalwork,
              title: `${isRTL ? 'اجمالي المنجز' : 'Total Process1'} ${
                tempwords.appointments
              }`,
              qty: doneevents,
            })}
          {employeeoptions?.salsales &&
            renderLine({
              amount: totalsales,
              title: `${isRTL ? 'اجمالي الدخل' : 'Total Income'}`,
            })}
          <Grid item xs={1}></Grid>
          <Grid item xs={7}>
            <TextFieldLocal
              name="plus"
              label={isRTL ? 'مكاقئة' : 'Bonos'}
              value={plus?.title}
              onChange={(e: any) =>
                setPlus({ value: plus?.value, title: e.target.value })
              }
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldLocal
              name="plus"
              label={isRTL ? 'القيمة' : 'Amount'}
              value={plus.value}
              onChange={(e: any) =>
                setPlus({ title: plus.title, value: Number(e.target.value) })
              }
              type="number"
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={7} style={{ backgroundColor: '#ffc0cb55' }}>
            <TextFieldLocal
              name="minus"
              label={isRTL ? 'استقطاع' : 'Deduction'}
              value={minus?.title}
              onChange={(e: any) =>
                setMinus({ value: minus?.value, title: e.target.value })
              }
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={3} style={{ backgroundColor: '#ffc0cb55' }}>
            <TextFieldLocal
              name="minus"
              label={isRTL ? 'القيمة' : 'Amount'}
              value={minus.value}
              onChange={(e: any) =>
                setMinus({ title: minus.title, value: Number(e.target.value) })
              }
              type="number"
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={8}></Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Paper>
      <Paper elevation={5} style={{ margin: 10, paddingBottom: 20 }}>
        {renderSalarydata({
          employeeoptions,
          basicSalary,
          extraSalary,
          absentCharge,
          overtimecharge,
          bonos,
          salary: sal,
          percentageSale,
          percentageWork,
          isRTL,
          handleContReactPrint: handleReactPrint,
          plus,
          minus,
          totalplus,
          totalminus,
        })}
      </Paper>
      <div style={{ display: 'none' }}>
        <SalaryPrintFree
          company={company}
          user={user}
          printData={printData}
          ref={componentRef}
        />
      </div>
    </Paper>
  );
}

export default SalaryBoxFree;
