/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import { Box, IconButton, Popover } from '@material-ui/core';
import React from 'react';
import PopupOptions from '../pages/options/PopupOptions';
import MyIcon from './MyIcon';

export default function OptionsSwitch({
  isRTL,
  words,
  store,
  dispatch,
  addUserTheme,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-options' : undefined;

  return (
    <Box>
      <IconButton
        style={{
          padding: 10,
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        <MyIcon icon="options" size={25} color="#555"></MyIcon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { borderRadius: 15, marginTop: 5 } }}
      >
        <Box style={{ width: 400 }}>
          <PopupOptions
            addUserTheme={addUserTheme}
            store={store}
            dispatch={dispatch}
            isRTL={isRTL}
            words={words}
          ></PopupOptions>
        </Box>
      </Popover>
    </Box>
  );
}
