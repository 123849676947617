/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
} from '@material-ui/core';
import { onetwelve, zeroeleven } from './renders';
import { colors } from '../../constants/colors';
import SelectKeys from '../components/SelectKeys';
import SelectCN from '../components/SelectCN';

const renderInput = (
  name: any,
  value: any,
  setValue: any,
  index: any,
  type?: any
) => {
  return (
    <TextField
      name={name}
      label={name}
      value={value}
      type={type}
      onChange={(e: any) => setValue(index, name, e.target.value)}
      fullWidth
      variant="outlined"
      style={{ direction: 'ltr' }}
    />
  );
};

const renderCheckbox = (name: any, value: any, setValue: any, index: any) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(e: any) => setValue(index, name, !value)}
          name="bold"
          color="primary"
        />
      }
      label={name}
    />
  );
};

function ItemStyle({
  row,
  setRow,
  words,
  data,
  addTableCol,
  removeTableCol,
}: any) {
  const keyslist = data ? Object.keys(data) : [];
  const itemskeyslist = data?.items?.[0] ? Object.keys(data?.items?.[0]) : [];

  const setRowalue = (name: any, value: any) => {
    const nrow = {
      ...row,
      [name]: value,
    };
    setRow(nrow);
  };
  const setColRowalue = (index: any, name: any, value: any) => {
    const ntableitems = [...row?.tableitems];
    const cell = ntableitems[index];
    const ncell = { ...cell, [name]: value };
    ntableitems[index] = ncell;
    const nrow = {
      ...row,
      tableitems: ntableitems,
    };
    setRow(nrow);
  };

  return (
    <Box style={{ direction: 'ltr' }}>
      <Grid
        container
        spacing={1}
        style={{ padding: 15, backgroundColor: '#efefef' }}
      >
        <Grid item xs={6}>
          <SelectKeys
            name="type"
            options={[
              'text',
              'temptext',
              'html',
              'editor',
              'date',
              'time',
              'money',
              'qty',
              'image',
              'checkbox',
              'table',
              'paragraphs',
              'carcustomer',
              'pb',
            ]}
            disabled={!row}
            value={row?.type}
            setValue={(v: any) => {
              setRowalue('type', v);
            }}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="name"
            options={keyslist}
            disabled={!row}
            setValue={(v: any) => setRowalue('name', v)}
            value={row?.name}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="size"
            options={onetwelve}
            disabled={!row}
            value={row?.size}
            setValue={(v: any) => setRowalue('size', Number(v))}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="skip"
            options={zeroeleven}
            disabled={!row}
            value={row?.skip}
            setValue={(v: any) => setRowalue('skip', Number(v))}
          ></SelectKeys>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="value"
            label="Value"
            value={row?.value}
            onChange={(e: any) => setRowalue('value', e.target.value)}
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            style={{ direction: 'ltr' }}
            disabled={!row}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={row?.border === true}
                onChange={() => {
                  setRowalue('border', row?.border === true ? false : true);
                }}
                name="border"
                color="primary"
              />
            }
            label="Border"
          />
        </Grid>
        {row?.type === 'table' && (
          <>
            <Grid item xs={6}>
              <SelectKeys
                name="total"
                options={keyslist}
                disabled={!row}
                setValue={(v: any) => setRowalue('total', v)}
                value={row?.total}
              ></SelectKeys>
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="discount"
                options={keyslist}
                disabled={!row}
                setValue={(v: any) => setRowalue('discount', v)}
                value={row?.discount}
              ></SelectKeys>
            </Grid>
            <Grid item xs={12}>
              <SelectKeys
                name="amount"
                options={keyslist}
                disabled={!row}
                setValue={(v: any) => setRowalue('amount', v)}
                value={row?.amount}
              ></SelectKeys>
            </Grid>
          </>
        )}

        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, width: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="width"
                  label="width"
                  value={row?.style?.width || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      width: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, height: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="height"
                  label="height"
                  value={row?.style?.height || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      height: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, fontSize: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="fontSize"
                  label="Font Size"
                  value={row?.style?.fontSize || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      fontSize: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.style?.fontWeight === 'bold'}
                    onChange={() => {
                      const stl = {
                        ...row?.style,
                        fontWeight:
                          row?.style?.fontWeight === 'bold'
                            ? undefined
                            : 'bold',
                      };
                      setRowalue('style', stl);
                    }}
                    name="bold"
                    color="primary"
                  />
                }
                label="Bold"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.style?.textAlign === 'right'}
                    onChange={() => {
                      const stl = {
                        ...row?.style,
                        textAlign:
                          row?.style?.textAlign === 'right'
                            ? undefined
                            : 'right',
                      };
                      setRowalue('style', stl);
                    }}
                    name="bold"
                    color="primary"
                  />
                }
                label="right"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.style?.position === 'relative'}
                    onChange={() => {
                      const stl = {
                        ...row?.style,
                        position:
                          row?.style?.position === 'relative'
                            ? undefined
                            : 'relative',
                      };
                      setRowalue('style', stl);
                    }}
                    name="bold"
                    color="primary"
                  />
                }
                label="absl"
              />
            </Grid>
            <Grid item xs={4}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, top: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="fontSize"
                  label="Top"
                  value={row?.style?.top || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      top: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 100 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, left: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="fontSize"
                  label="Left"
                  value={row?.style?.left || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      left: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 100 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SelectCN
                disabled={!row}
                setRowalue={setRowalue}
                value={row?.classnames}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="color"
                options={colors}
                disabled={!row}
                value={row?.style?.color}
                setValue={(v: any) => {
                  const stl = {
                    ...row?.style,
                    color: v,
                  };
                  setRowalue('style', stl);
                }}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="background"
                options={colors}
                disabled={!row}
                value={row?.style?.backgroundColor}
                setValue={(v: any) => {
                  const stl = {
                    ...row?.style,
                    backgroundColor: v,
                  };
                  setRowalue('style', stl);
                }}
                color
              ></SelectKeys>
            </Grid>
            {row?.type === 'table' && (
              <Grid item xs={12} style={{ backgroundColor: '#fffaaa55' }}>
                {row?.tableitems?.map((titem: any, index: any) => {
                  return (
                    <>
                      <Box
                        onClick={() => {
                          removeTableCol(titem);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        X
                      </Box>
                      <Grid
                        container
                        spacing={1}
                        style={{ backgroundColor: '#fff' }}
                      >
                        <Grid item xs={6}>
                          <SelectKeys
                            name="name"
                            options={itemskeyslist}
                            disabled={!row?.tableitems}
                            setValue={(v: any) => {
                              setColRowalue(index, 'name', v);
                            }}
                            value={titem?.name}
                          ></SelectKeys>
                        </Grid>
                        <Grid item xs={6}>
                          {renderInput(
                            'value',
                            titem?.value,
                            setColRowalue,
                            index
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderInput(
                            'width',
                            titem?.width,
                            setColRowalue,
                            index,
                            'number'
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderInput(
                            'height',
                            titem?.height,
                            setColRowalue,
                            index,
                            'number'
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderInput(
                            'itemheight',
                            titem?.itemheight,
                            setColRowalue,
                            index,
                            'number'
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderInput(
                            'size',
                            titem?.size,
                            setColRowalue,
                            index,
                            'number'
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderInput(
                            'itemsize',
                            titem?.itemsize,
                            setColRowalue,
                            index,
                            'number'
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <SelectKeys
                            name="align"
                            options={['left', 'center', 'right']}
                            value={titem?.align}
                            setValue={(v: any) =>
                              setColRowalue(index, 'align', v)
                            }
                            disabled={false}
                          ></SelectKeys>
                        </Grid>
                        <Grid item xs={6}>
                          {renderCheckbox(
                            'bold',
                            titem?.bold,
                            setColRowalue,
                            index
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderCheckbox(
                            'money',
                            titem?.money,
                            setColRowalue,
                            index
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {renderCheckbox(
                            'qty',
                            titem?.qty,
                            setColRowalue,
                            index
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <SelectKeys
                            name="color"
                            options={colors}
                            value={titem?.color}
                            setValue={(v: any) =>
                              setColRowalue(index, 'color', v)
                            }
                            color
                            disabled={false}
                          ></SelectKeys>
                        </Grid>
                      </Grid>
                      <Divider
                        style={{ marginTop: 10, marginBottom: 10 }}
                      ></Divider>
                    </>
                  );
                })}
                <Button variant="outlined" onClick={addTableCol}>
                  add column
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ItemStyle;
