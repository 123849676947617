import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';

const GeneralOptionsPopup = ({
  fullscreen,
  setFullscreen,
  scrollbar,
  setScrollbar,
  isRTL,
}: any) => {
  return (
    <Box>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={fullscreen}
              onChange={() => setFullscreen(!fullscreen)}
              name="bold"
              color="primary"
            />
          }
          label={isRTL ? 'نوافذ كامل الشاشة' : 'Fullscreen Modal'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={scrollbar}
              onChange={() => setScrollbar(!scrollbar)}
              name="bold"
              color="primary"
            />
          }
          label={isRTL ? 'مسطرة افتراضية' : 'Default Scrollbar'}
        />
      </Grid>
    </Box>
  );
};

export default GeneralOptionsPopup;
