/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PrintPaperForm from './PrintPaperForm';

export class DocumentTempPrintForm extends React.PureComponent<any, any> {
  renderHeader = (company: any) => (
    <img
      src={company?.header}
      alt={company?.name}
      height="auto"
      width="100%"
      style={{
        objectFit: 'contain',
      }}
    />
  );
  renderFooter = (company: any) => (
    <img
      src={company?.footer}
      alt={company?.name}
      height="auto"
      width="100%"
      style={{
        objectFit: 'contain',
      }}
    />
  );

  render() {
    const { company, template, data, isRTL } = this.props;
    console.log('data', data);
    console.log('template', template);
    console.log('company', company);
    return (
      <table className="report-container">
        <thead className="report-header">
          <tr>
            <th className="report-header-cell">
              <div className="header-info">{this.renderHeader(company)}</div>
            </th>
          </tr>
        </thead>
        <tbody className="report-content">
          <tr>
            <td className="report-content-cell">
              <div className="main">
                <PrintPaperForm
                  isRTL={isRTL}
                  template={template}
                  data={data}
                ></PrintPaperForm>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot className="report-footer">
          <tr>
            <td className="report-footer-cell">
              <div className="footer-info">
                <div className={'page-footer'}>
                  {this.renderFooter(company)}
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}
