/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';

import { GContextTypes } from '../../types';
import { GlobalContext } from '../../contexts';
import { Box, Grid } from '@material-ui/core';
import PopupLayout from './PopupLayout';
import SpacesUpload from '../../common/SpacesUpload';

const PopupUploadDocs = ({
  open,
  onClose,
  theme,
  words,
  company,
  width,
  height,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [setSubmitdata]: any = useState({});
  const [alrt] = useState({ show: false, msg: '', type: undefined });
  const [setTotals] = useState<any>({});
  const [itemsList, setItemsList] = useState<any>([]);

  const {
    translate: { isRTL },
  }: GContextTypes = useContext(GlobalContext);

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum;
    const profit = sum - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
  };

  useEffect(() => {
    getOverallTotal();
  }, [itemsList]);

  const onSubmit = async () => {
    setSaving(true);
  };

  const closeModal = () => {
    setSaving(false);
    setSubmitdata({});
    setTotals({});
    setItemsList([]);
    onClose();
  };

  const title = isRTL ? `اضافة مستندات` : `Add Documents`;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onSubmit}
      preventclose={true}
      theme={theme}
      alrt={alrt}
      saving={saving}
      maxWidth={'xl'}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={{ width: width - 100, height: height - 270 }}>
            <SpacesUpload
              branch={company?.basename}
              isRTL={isRTL}
            ></SpacesUpload>
          </Box>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupUploadDocs;
