/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createStepItem,
  deleteStepItem,
  updateStepItem,
} from '../graphql/mutation';
import { getStepItems } from '../graphql/query';

export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getStepItems);

  const [addStepItem] = useMutation(createStepItem, {
    refetchQueries: [{ query: getStepItems }],
  });
  const [editStepItem] = useMutation(updateStepItem, {
    refetchQueries: [{ query: getStepItems }],
  });
  const [removeStepItem] = useMutation(deleteStepItem, {
    refetchQueries: [{ query: getStepItems }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const allstepitems = catpData?.data?.['getStepItems']?.data || [];
  const stepitems = allstepitems.filter(
    (si: any) => !(si?.auto || si?.disabled)
  );
  const refreshstepitems = () => catpData?.refetch();
  return {
    allstepitems,
    stepitems,
    addStepItem,
    editStepItem,
    removeStepItem,
    refreshstepitems,
    loading: catpData?.loading,
  };
};
