import { gql } from '@apollo/client';

export default gql`
  mutation createCustomer(
    $branch: String
    $disabled: Boolean
    $addlink: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $phone: String
    $mobile: String
    $email: String
    $address: String
    $avatar: String
    $logo: String
    $headphoto: String
    $employee: EmployeeInput
    $driver: String
    $licenseNo: String
    $licenseDate: String
    $national: String
    $nationalNo: String
    $nationalDate: String
    $area: String
    $parentName: String
    $parentPhone: String
    $grade: String
    $location: LocationInput
    $locationLink: String
    $houseno: String
    $birthday: Date
    $msgId: String
    $openingbalance: Float
    $reminder: Boolean
    $breminder: Boolean
    $sreminder: Boolean
    $nreminder: Boolean
  ) {
    createCustomer(
      branch: $branch
      disabled: $disabled
      addlink: $addlink
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      phone: $phone
      mobile: $mobile
      email: $email
      address: $address
      avatar: $avatar
      logo: $logo
      headphoto: $headphoto
      employee: $employee
      driver: $driver
      licenseNo: $licenseNo
      licenseDate: $licenseDate
      national: $national
      nationalNo: $nationalNo
      nationalDate: $nationalDate
      area: $area
      parentName: $parentName
      parentPhone: $parentPhone
      grade: $grade
      location: $location
      locationLink: $locationLink
      houseno: $houseno
      birthday: $birthday
      msgId: $msgId
      openingbalance: $openingbalance
      reminder: $reminder
      breminder: $breminder
      sreminder: $sreminder
      nreminder: $nreminder
    ) {
      ok
      message
      data
      error
    }
  }
`;
