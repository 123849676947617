/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import { SearchTable } from '../../components';
import {
  inboxAvatarFormatter,
  createdAtEmailFormatter,
  textEmailFormatter,
  emailPriorityFormatter,
  titleEmailFormatter,
  emailStatusFormatter,
  favInOutboxFormatter,
  isinboxEmailFormatter,
} from '../../Shared/colorFormat';
import { useTemplate } from '../../hooks';
import { useLazyQuery, useMutation } from '@apollo/client';
import updateProcRead from '../../graphql/mutation/updateProcRead';
import { TableComponentEM } from '../components/TableComponent';
import { useNavigate } from 'react-router-dom';
import updateProStar from '../../graphql/mutation/updateProStar';
import getMyStarProcs from '../../graphql/query/getMyStarProcs';

const Starred = forwardRef((props: any, ref: any) => {
  const { isRTL, userId, stepitems } = props;
  const [rows, setRows] = useState([]);
  const [columns] = useState([
    { name: 'instar', title: 'Fav' },
    { name: 'outboxName', title: 'Sender' },
    { name: 'title', title: 'title' },
    { name: 'desc', title: 'desciption' },
    { name: 'priority', title: 'Priority' },
    { name: 'stepId', title: 'Status' },
    { name: 'createdAt', title: 'Time' },
    { name: 'isinbox', title: 'Inbox' },
  ]);
  const [tableColumnExtensions]: any = useState([
    { columnName: 'instar', width: 70, align: 'center' },
    { columnName: 'outboxName', width: 220 },
    { columnName: 'title', width: 220 },
    { columnName: 'desc', width: 'auto' },
    { columnName: 'priority', width: 120 },
    { columnName: 'stepId', width: 120 },
    { columnName: 'createdAt', width: 180, align: 'center' },
    { columnName: 'isinbox', width: 70, align: 'center' },
  ]);

  const { tempoptions } = useTemplate();
  const navigate = useNavigate();

  const [loadInbox, inboxData]: any = useLazyQuery(getMyStarProcs, {
    fetchPolicy: 'cache-and-network',
  });
  const refresQuery = {
    refetchQueries: [
      {
        query: getMyStarProcs,
      },
    ],
  };

  const [read] = useMutation(updateProcRead, refresQuery);
  const [star] = useMutation(updateProStar, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (inboxData?.refetch) {
        inboxData.refetch();
      }
    },
  }));

  useEffect(() => {
    const d = inboxData?.data?.getMyStarProcs?.data;
    if (d) {
      const rd = d.map((item: any) => {
        const isinbox = item?.inbox === userId ? true : false;
        const read = isinbox ? item?.read : true;
        return {
          ...item,
          isinbox,
          read,
        };
      });
      setRows(rd);
    }
  }, [inboxData]);

  useEffect(() => {
    loadInbox({ skip: 0 });
  }, []);
  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <SortingState />
      <SearchState />
      <PagingState defaultCurrentPage={0} defaultPageSize={20} />
      <IntegratedSorting />
      <IntegratedFiltering />
      <IntegratedPaging />
      <DragDropProvider />
      <Table
        messages={{
          noData: isRTL ? 'لا يوجد رسائل' : 'no mail',
        }}
        tableComponent={TableComponentEM}
        rowComponent={(props: any) => {
          const row = props?.row;
          const isread = row?.read;
          return (
            <Table.Row
              {...props}
              onClick={() => {
                if (!isread) {
                  read({ variables: { procId: row?._id } });
                }
                if (navigate) {
                  navigate(props?.row._id);
                }
              }}
              style={{
                cursor: 'pointer',
                height: 50,
                backgroundColor: isread ? '#f7f7f7' : '#fff',
                fontSize: '20px !Important',
              }}
            ></Table.Row>
          );
        }}
        columnExtensions={tableColumnExtensions}
      />

      <TableColumnReordering
        defaultOrder={[
          'instar',
          'outboxName',
          'title',
          'desc',
          'stepId',
          'priority',
          'createdAt',
          'isinbox',
        ]}
      />
      <DataTypeProvider
        for={['instar']}
        formatterComponent={(props: any) =>
          favInOutboxFormatter({ ...props, star })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['outboxName']}
        formatterComponent={inboxAvatarFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['title']}
        formatterComponent={(props: any) =>
          titleEmailFormatter({ ...props, isRTL })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['desc']}
        formatterComponent={textEmailFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['isinbox']}
        formatterComponent={isinboxEmailFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['priority']}
        formatterComponent={(props: any) =>
          emailPriorityFormatter({ ...props, isRTL })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['stepId']}
        formatterComponent={(props: any) =>
          emailStatusFormatter({ ...props, stepitems, isRTL })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['createdAt']}
        formatterComponent={createdAtEmailFormatter}
      ></DataTypeProvider>
      <Toolbar />
      <PagingPanel
        pageSizes={tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]}
      />
      <SearchPanel
        inputComponent={(props: any) => {
          return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
        }}
      />
    </Grid>
  );
});
export default Starred;
