import { gql } from '@apollo/client';

export default gql`
  mutation getPresignedURL($filename: String, $filetype: String) {
    getPresignedURL(filename: $filename, filetype: $filetype) {
      ok
      data
    }
  }
`;
