import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { exitfullscreen, fullscreen } from '../common/funcs';
import MyIcon from '../components/MyIcon';
import { styled } from '@material-ui/core/styles';
import UserBox from '../shared/UserBox';

const SearchBox = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '10px',
    borderColor: '#fff',
  },
}));
export default function Header({ isFullscreen, isRTL, height, theme }) {
  const navigate = useNavigate();
  const keyPress = (e: any) => {
    console.log('e.target.value', e.target.value);
  };
  return (
    <Box
      style={{
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          justifyContent: isRTL ? 'flex-end' : 'flex-start',
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Box
          style={{
            width: 700,
            marginLeft: 20,
            marginRight: 20,
            borderRadius: 10,
          }}
          boxShadow={1}
        >
          <SearchBox
            label={isRTL ? 'البحث' : 'Search'}
            name="search"
            onKeyDown={keyPress}
            variant="outlined"
            size="medium"
            autoComplete="off"
            fullWidth
            inputProps={{
              style: {
                height: 46,
                fontSize: 16,
                margin: 0,
                padding: 0,
                paddingLeft: 20,
                paddingRight: 20,
              },
            }}
            style={{
              backgroundColor: '#fff',
              borderRadius: 10,
              height: 46,
              margin: 0,
              padding: 0,
            }}
          />
        </Box>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: 'bold',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          JADWAL POS
        </Typography>
        {isFullscreen && (
          <Tooltip title="Exit Fullscreen">
            <IconButton onClick={exitfullscreen}>
              <MyIcon icon={'exitfull'} />
            </IconButton>
          </Tooltip>
        )}
        {!isFullscreen && (
          <Tooltip title="Fullscreen">
            <IconButton onClick={fullscreen}>
              <MyIcon icon={'full'} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Back to Portal">
          <IconButton
            onClick={() => {
              if (isFullscreen) document.exitFullscreen();
              navigate('/');
            }}
          >
            <MyIcon icon={isRTL ? 'right' : 'left'} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isRTL ? 'flex-start' : 'flex-end',
          marginLeft: 35,
          marginRight: 35,
        }}
      >
        <UserBox
          refresh={() => null}
          loading={false}
          isRTL={isRTL}
          theme={theme}
        ></UserBox>
      </Box>
    </Box>
  );
}
