/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useReducer, useRef, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box } from '@material-ui/core';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import CalendarEmp from '../../pages/calendar/CalendarEmp';
import { CalendarContext, calendarReducer, initCalendar } from '../../contexts';

export default function CalendarOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  drawerWidth,
  calendar,
  dispatch,
  company,
  tempId,
  evenetOpened,
  setEvenetOpened,
  sub,
}: any) {
  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);
  const ref: any = useRef();

  const { isMobile, height, width } = useWindowDimensions();

  const [calendarStore, calendarDispatch] = useReducer(
    calendarReducer,
    initCalendar
  );

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      bgimage={bgimage}
      width={width - drawerWidth}
      refresh={() => ref?.current?.refresh()}
    >
      <>
        <Box
          style={{
            marginRight: 25,
            marginLeft: 25,
            width: width - drawerWidth - 50,
            height: height - 50,
          }}
        >
          <CalendarContext.Provider
            value={{ state: calendarStore, dispatch: calendarDispatch }}
          >
            <CalendarEmp
              ref={ref}
              menuitem={menuitem}
              isMobile={isMobile}
              isRTL={isRTL}
              words={words}
              theme={theme}
              calendar={calendar}
              gDispatch={dispatch}
              company={company}
              tempId={tempId}
              user={user}
              evenetOpened={evenetOpened}
              setEvenetOpened={setEvenetOpened}
              sub={sub}
              drawerWidth={drawerWidth}
            ></CalendarEmp>
          </CalendarContext.Provider>
        </Box>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </>
    </PageLayout>
  );
}
