import { gql } from '@apollo/client';

export default gql`
  query getTasks(
    $departmentId: String
    $employeeId: String
    $projectId: String
    $resourseId: String
    $customerId: String
    $status: Int
    $start: Date
    $end: Date
  ) {
    getTasks(
      departmentId: $departmentId
      employeeId: $employeeId
      projectId: $projectId
      resourseId: $resourseId
      customerId: $customerId
      status: $status
      start: $start
      end: $end
    ) {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        disabled
        additionId
        title

        desc
        printdate
        printtitle
        address
        extra
        photos

        items {
          index
          name
          qty
          price
          total
        }

        id
        name
        nameAr
        type
        freq
        count
        interval
        weekdays
        monthdays
        many
        start
        end
        progress
        isDisabled
        tasktype
        typefree
        periodType
        periodCost
        dayCost
        location {
          lat
          lng
        }
        priority
        total
        discount
        amount
        deposit
        evQty
        evAmount
        evDoneAmount
        evDone

        periodfrom

        status
        totalinvoiced
        totalDiscount
        totalPurchaseInvoiced
        totalPurchasePaid
        totalPurchaseDiscount
        toatlProdExpenses
        totalpaid
        toatlExpenses
        totalkaidsdebit
        totalKaidscredit
        balance

        customerId
        customerName
        customerNameAr
        customerPhone

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone

        projectId
        projectName
        projectNameAr
        projectColor
        resourseId
        resourseName
        resourseNameAr
        resourseColor

        retypeId
        retypeName
        retypeNameAr
        retypeColor

        retype2Id
        retype2Name
        retype2NameAr
        retype2Color

        ismemorized
        isatStart
        isNotEditable
        isClosed

        userId
        note
        info

        createdAt
        updatedAt
      }
    }
  }
`;
