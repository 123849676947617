/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-anonymous-default-export */

import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { getCompany } from '../graphql';

export default () => {
  const [getComp, compData]: any = useLazyQuery(getCompany);

  useEffect(() => {
    getComp();
  }, []);

  let sen: any;
  let sar: any;
  let updatedAt: any;
  const comp1 = compData?.data?.['getCompany']?.data || null;
  if (comp1) {
    const company = JSON.parse(comp1);
    if (company?.en) sen = JSON.parse(company?.en);
    if (company?.en) sar = JSON.parse(company?.ar);
    updatedAt = company?.updatedAt;
  }
  return { en: sen, ar: sar, updatedAt };
};
