/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { getFoundations, createFoundation, updateFoundation } from '../graphql';

export default () => {
  const [getbras, foundationData] = useLazyQuery(getFoundations, {
    fetchPolicy: 'cache-and-network',
  });

  const [addFoundation] = useMutation(createFoundation, {
    refetchQueries: [{ query: getFoundations }],
  });
  const [editFoundation] = useMutation(updateFoundation, {
    refetchQueries: [{ query: getFoundations }],
  });

  useEffect(() => {
    getbras();
  }, [getbras]);

  const foundations = foundationData?.data?.['getFoundations']?.data || [];

  return { foundations, addFoundation, editFoundation };
};
