/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { useCategories, useTemplate } from '../../hooks';
import { downloadCSVFile, getRowId, userroles } from '../../common';
import { PopupCategory } from '../../pubups';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, Button, Typography } from '@material-ui/core';
import PageLayout from '../main/PageLayout';
import { itemTypes } from '../../constants/datatypes';
import { TableComponent } from '../../Shared/TableComponent';
import { categoryTypeFormat } from '../../Shared/colorFormat';
import PopupCategoryImport from '../../pubups/PopupCategoryImport';
import ImportBtn from '../../common/ImportBtn';
import ExportBtn from '../../common/ExportBtn';

export default function Categories({ isRTL, words, theme, menuitem }: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [catType, setCatType] = useState(itemTypes?.[1]);
  const [openImport, setOpenImport] = useState(false);

  const { height, width } = useWindowDimensions();
  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      { name: isRTL ? 'nameAr' : 'name', title: words.name },
      { name: 'catType', title: isRTL ? 'النوع' : 'Type' },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'additionId', width: 150 },
    { columnName: isRTL ? 'nameAr' : 'name', width: 250 },
    { columnName: 'catType', width: 250 },
  ]);

  const {
    categories,
    addCategory,
    addMultiCategory,
    editCategory,
    removeCategory,
    refreshcategory,
    loading,
  } = useCategories();

  const rows = catType
    ? categories.filter((rt: any) => rt.catType === catType.id)
    : categories;

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removeCategory({ variables: { _id } });
      if (res?.data?.deleteCategory?.ok === false) {
        if (res?.data?.deleteCategory?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'categories', colnames);
  };

  const bgcolor = '#EFFAF100';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refreshcategory}
      bgcolor={bgcolor}
      loading={loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: bgcolor,
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
        <Box
          style={{
            display: 'flex',
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {itemTypes?.map((rt: any) => {
            return (
              <Button
                key={rt.id}
                color="primary"
                variant={rt === catType ? 'contained' : 'outlined'}
                onClick={() => setCatType(rt)}
                style={{ margin: 10, padding: 5, minWidth: 150 }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  {isRTL ? rt?.nameAr : rt?.name}
                </Typography>
              </Button>
            );
          })}
        </Box>

        <Paper
          elevation={5}
          style={{
            marginTop: 10,
            marginLeft: 40,
            marginRight: 40,
            marginBottom: 20,
            overflow: 'auto',
            width: width - 320,
            borderRadius: 10,
          }}
        >
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SearchState />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />

            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <DragDropProvider />
            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 68 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnReordering
              defaultOrder={['avatar', isRTL ? 'nameAr' : 'name', 'catType']}
            />
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnVisibility
              columnExtensions={[]}
              defaultHiddenColumnNames={[]}
            />
            <DataTypeProvider
              for={['catType']}
              formatterComponent={(props: any) =>
                categoryTypeFormat({ ...props, isRTL })
              }
            ></DataTypeProvider>
            <TableEditColumn
              showEditCommand={userroles?.categories?.edit}
              showDeleteCommand={userroles?.categories?.delete}
              showAddCommand={userroles?.categories?.add}
              commandComponent={Command}
            ></TableEditColumn>
            <Toolbar />
            <ColumnChooser />
            <PagingPanel
              pageSizes={
                tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
              }
            />

            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />

            <PopupEditing
              theme={theme}
              addAction={addCategory}
              editAction={editCategory}
            >
              <PopupCategory catType={catType}></PopupCategory>
            </PopupEditing>
          </Grid>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
        <PopupCategoryImport
          open={openImport}
          onClose={() => setOpenImport(false)}
          addMultiItems={addMultiCategory}
          isRTL={isRTL}
          theme={theme}
          words={words}
          catType={catType?.id}
        ></PopupCategoryImport>
      </Box>
    </PageLayout>
  );
}
