/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import getOperationById from '../../graphql/query/getOperationById';
import { useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import MyIcon from '../components/MyIcon';
import ProcedureSingleBox from './ProcedureSingleBox';
import getProcedureById from '../../graphql/query/getProcedureById';
import DocumentEmailPreview from './DocumentEmailPreview';
import { useFormDocs } from '../../hooks';

const EmailView = ({
  formdoc,
  theme,
  isRTL,
  words,
  user,
  width,
  height,
  company,
}: any) => {
  const [doc, setDoc] = React.useState(null);
  const [proc, setProc] = React.useState(null);
  const { procId } = useParams();
  const navigate = useNavigate();
  const { formdocs } = useFormDocs();
  const [getDoc, docData]: any = useLazyQuery(getOperationById);
  const [getProc, procData]: any = useLazyQuery(getProcedureById);
  useEffect(() => {
    if (proc?.opId) {
      getDoc({ variables: { opId: proc?.opId } });
    }
    if (procId) {
      getProc({ variables: { procId } });
    }
  }, [proc]);

  useEffect(() => {
    const ddata = docData?.data?.getOperationById?.data;
    const pdata = procData?.data?.getProcedureById?.data;
    if (ddata) {
      setDoc(ddata);
    }
    if (pdata) {
      setProc(pdata);
    }
  }, [docData, procData]);
  return (
    <Box
      style={{
        width: width - 200,
        height: height,
      }}
    >
      <Box
        style={{
          display: 'flex',
          flex: 1,
          height: 50,
          paddingLeft: 10,
          paddingRight: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#aaaaaa55',
        }}
      >
        <Box>
          <Typography style={{ fontWeight: 'bold', color: '#333' }}>
            {doc?.title}
          </Typography>
        </Box>
        <Box>
          <IconButton
            style={{ color: '#333' }}
            onClick={() => navigate(-1)}
            title="Edit row"
          >
            <MyIcon icon="left" />
          </IconButton>
        </Box>
      </Box>
      <Box
        style={{
          width: width - 220,
          height: height - 30,
          margin: 10,

          // overflow: 'auto',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={8} style={{ paddingBottom: 10 }}>
            <ProcedureSingleBox
              row={proc}
              user={user}
              words={words}
              isRTL={isRTL}
              theme={theme}
              width={width}
              height={height + 50}
            ></ProcedureSingleBox>
          </Grid>
          <Grid item xs={4} style={{ paddingBottom: 10 }}>
            {doc && (
              <DocumentEmailPreview
                formdoc={
                  formdocs?.filter((fd: any) => fd.gref === doc.gref)?.[0]
                }
                isRTL={isRTL}
                theme={theme}
                width={width}
                height={height + 50}
                company={company}
                row={doc}
                right
              ></DocumentEmailPreview>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EmailView;
