/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  colors,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import DateNavigatorReports from '../components/filters/DateNavigatorReports';
import _ from 'lodash';
import getReportServices from '../graphql/query/getReportServices';
import SimpleTable from './SimpleTable';
import { moneyFormat, qtyFormat } from './colorFormat';
import { userroles } from '../common';
import { GlobalContext } from '../contexts';

const getStatisticsFromData = ({ data, isRTL }) => {
  const servs = data.filter((item: any) => item.itemType === 2);
  const prods = data.filter((item: any) => item.itemType === 1);
  const efname = isRTL ? 'employeeNameAr' : 'employeeName';
  const dfname = isRTL ? 'departmentNameAr' : 'departmentName';
  const cfname = isRTL ? 'categoryNameAr' : 'categoryName';
  const ifname = isRTL ? 'itemNameAr' : 'itemName';
  const contfname = isRTL ? 'contractNameAr' : 'contractName';

  const departments = _(data)
    .groupBy(dfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const categories = _(data)
    .groupBy(cfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const services = _(servs)
    .groupBy(ifname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const products = _(prods)
    .groupBy(ifname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const employees = _(data)
    .groupBy(efname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const contracts = _(data)
    .groupBy(contfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();

  return {
    departments,
    employees,
    categories,
    products,
    services,
    contracts,
  };
};

function SalesStatus({
  isRTL,
  words,
  theme,
  tempwords,
  tempoptions,
  salesMonth,
  height,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [total, setTotal]: any = useState(null);

  const [row, setRow] = useState(null);

  const [currentViewName, setCurrentViewName] = useState('Day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const {
    store: { daysales },
  } = useContext(GlobalContext);

  const [sday, setSday] = useState<any>(null);

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };
  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const [getSummary, summaryData]: any = useLazyQuery(getReportServices);

  useEffect(() => {
    const slsData = summaryData?.data?.['getReportServices']?.data || [];

    const message = summaryData?.data?.['getReportServices']?.message || '[]';
    const operations = JSON.parse(message);
    const sumdiscount = _.sumBy(operations, 'discount') || 0;
    const sumamount = _.sumBy(operations, 'amount') || 0;
    const sumnetamount = sumamount - sumdiscount;
    const qty = operations?.length || 0;

    let rdata = slsData;
    const statics = getStatisticsFromData({ data: rdata, isRTL });
    setRow(statics);
    setTotal({ sumamount, sumdiscount, sumnetamount, qty });
    const days =
      currentViewName === 'Day' ? 1 : currentViewName === 'Week' ? 7 : 30;
    setSday(days * daysales || 0);
  }, [summaryData]);

  const fetchData = () => {
    const variables = {
      types: [10],
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [currentViewName, start, end, salesMonth]);
  return (
    <Paper
      elevation={2}
      style={{
        height: height ? height : 700,
      }}
    >
      <Box
        display={'flex'}
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
          height: 50,
          paddingTop: 10,
        }}
      >
        <DateNavigatorReports
          setStart={setStart}
          setEnd={setEnd}
          currentDate={currentDate}
          currentDateChange={currentDateChange}
          currentViewName={currentViewName}
          currentViewNameChange={currentViewNameChange}
          endDate={endDate}
          endDateChange={endDateChange}
          views={[1, 7, 30]}
          isRTL={isRTL}
          words={words}
          theme={theme}
        ></DateNavigatorReports>
        {userroles?.invoices?.admin && (
          <Box display={'flex'}>
            <Typography
              variant="subtitle1"
              style={{
                color: colors.blue[400],
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold',
              }}
            >
              {`${isRTL ? 'عدد الفواتير' : 'Invoices'} : ${qtyFormat(
                total?.qty
              )}`}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: colors.blue[400],
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold',
              }}
            >
              {`${isRTL ? 'المجموع' : 'Total Amount'} : ${moneyFormat(
                total?.sumamount
              )}`}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: colors.orange[400],
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold',
              }}
            >
              {`${isRTL ? 'الخصم' : 'Total Discount'} : ${moneyFormat(
                total?.sumdiscount
              )}`}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: colors.green[400],
                paddingLeft: 40,
                paddingRight: 40,
                fontWeight: 'bold',
              }}
            >
              {`${isRTL ? 'الصافي' : 'Total Discount'} : ${moneyFormat(
                total?.sumnetamount
              )}`}
            </Typography>
          </Box>
        )}
        {sday && sday > 0 && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 200,
              height: 40,
              borderRadius: 5,
              backgroundColor: colors.purple[50],
            }}
          >
            <Typography
              style={{
                color: colors.purple[800],
                fontSize: 18,
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 'bold',
              }}
            >
              {`${isRTL ? 'المتوقع' : 'Expected'} : ${moneyFormat(sday)}`}
            </Typography>
          </Box>
        )}
        <Box style={{ marginLeft: 15, marginRight: 15 }}>
          <Typography
            color="primary"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#555' }}
          >
            {isRTL ? 'المبيعات' : 'Sales'}
          </Typography>
        </Box>
      </Box>
      <Paper
        elevation={0}
        style={{
          overflow: 'auto',
          height: height - 90,
          marginLeft: 15,
          marginRight: 15,
          marginTop: 20,
          backgroundColor: '#f5f5f5',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Divider style={{ margin: 10 }}></Divider>
          </Grid>
          <Grid item xs={3}>
            {tempoptions?.department && (
              <>
                <Typography
                  variant="h6"
                  style={{ paddingLeft: 15, paddingRight: 15 }}
                >
                  {isRTL
                    ? `بحسب ${tempwords?.department}`
                    : `By ${tempwords?.department}`}
                </Typography>
                <SimpleTable
                  data={row?.departments}
                  isRTL={isRTL}
                ></SimpleTable>
              </>
            )}
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              {isRTL ? `بحسب ${words.employee}` : `By ${words.employee}`}
            </Typography>
            <SimpleTable data={row?.employees} isRTL={isRTL}></SimpleTable>
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              {isRTL ? `بحسب ${words.category}` : `By ${words.category}`}
            </Typography>
            <SimpleTable data={row?.categories} isRTL={isRTL}></SimpleTable>

            {tempoptions?.contract && (
              <>
                <Typography
                  variant="h6"
                  style={{ paddingLeft: 15, paddingRight: 15 }}
                >
                  {isRTL ? `بحسب ${tempwords?.task}` : `By ${tempwords?.task}`}
                </Typography>
                <SimpleTable data={row?.contracts} isRTL={isRTL}></SimpleTable>
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              {isRTL ? `بحسب ${words.services}` : `By ${words.services}`}
            </Typography>
            <SimpleTable data={row?.services} isRTL={isRTL}></SimpleTable>
          </Grid>
          {tempoptions?.products && (
            <Grid item xs={3}>
              <Typography
                variant="h6"
                style={{ paddingLeft: 15, paddingRight: 15 }}
              >
                {isRTL ? `بحسب ${words.products}` : `By ${words.products}`}
              </Typography>
              <SimpleTable data={row?.products} isRTL={isRTL}></SimpleTable>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Paper>
  );
}

export default SalesStatus;
