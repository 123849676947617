/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createMSGTemplate,
  deleteMSGTemplate,
  getMSGTemplates,
  updateMSGTemplate,
} from '../graphql';
export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getMSGTemplates);

  const [addMSGTemplate] = useMutation(createMSGTemplate, {
    refetchQueries: [{ query: getMSGTemplates }],
  });
  const [editMSGTemplate] = useMutation(updateMSGTemplate, {
    refetchQueries: [{ query: getMSGTemplates }],
  });
  const [removeMSGTemplate] = useMutation(deleteMSGTemplate, {
    refetchQueries: [{ query: getMSGTemplates }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const msgtemplates = catpData?.data?.['getMSGTemplates']?.data || [];
  const refreshmsgtemplates = () => catpData?.refetch();
  return {
    msgtemplates,
    addMSGTemplate,
    editMSGTemplate,
    removeMSGTemplate,
    refreshmsgtemplates,
    loading: catpData?.loading,
  };
};
