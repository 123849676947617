/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useImperativeHandle } from 'react';
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DayView,
  CurrentTimeIndicator,
  DragDropProvider,
  Resources,
  WeekView,
  GroupingPanel,
  MonthView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useContext, useEffect, useState } from 'react';
import { AppointForm } from './common/AppointForm';
import { commitAppointmentChanges, userroles } from '../../common';
import { RenderToolTip } from './common/AppointTooltip';
import { AppointmentContent } from './common';
import { CommandAppointment } from '../../Shared';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createEvent,
  deleteEvent,
  deleteTempEvent,
  getEmployees,
  getEvents,
  updateEvent,
} from '../../graphql';
import { Box, Checkbox, FormControlLabel, Paper } from '@material-ui/core';
import { getStartEndEventView } from '../../common/time';
import { CalendarContext } from '../../contexts';
import PopupLayoutBox from '../main/PopupLayoutBox';
import { getPopupGeneralTitle } from '../../constants/menu';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useEmployees, useServices, useTemplate } from '../../hooks';
import PopupAppointInvoice from '../../pubups/PopupAppointInvoice';
import _ from 'lodash';
import {
  DayScaleCell,
  GroupHeadCell,
  IndecatorColor,
  TimeScaleLabel,
} from '../../common/calendarColor';
import DateNavigatorReports from '../../Sections/shared/DateNavigatorReports';
import EventsCalFilter from '../../Shared/EventsCalFilter';

const CalendarEmp = forwardRef((props: any, ref: any) => {
  const [open, setOpen] = useState(false);
  const [itemsList, setItemsList] = useState<any>([]);
  const [appointmentData, setAppointmentData] = useState<any>([]);

  const [resourseData, setResourseData]: any = useState([]);
  const [mainResourceName, setMainResourceName]: any = useState(null);
  const [visible, setVisible] = useState(false);
  const [temp, setTemp] = useState(true);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [rows, setRows] = useState([]);
  // const [time, setTime] = useState(null);
  const {
    calendar,
    gDispatch,
    isRTL,
    words,
    company,
    theme,
    isEmployee,
    employeeId,
    // sub,
    evenetOpened,
    setEvenetOpened,
    isMobile,
    drawerWidth,
  } = props;
  const [group, setGroup] = useState(
    calendar && !isMobile ? calendar?.group : false
  );

  const {
    state: { currentDate, currentViewName, departvalue, emplvalue, status },
    dispatch,
  } = useContext(CalendarContext);

  const { height, width } = useWindowDimensions();
  const { employees, wemployees } = useEmployees();
  const { services } = useServices();
  const { tempoptions } = useTemplate();

  const refresQuery = {
    refetchQueries: [
      {
        query: getEvents,
        variables: {
          start,
          end,
          employeeId: isEmployee ? employeeId : undefined,
        },
      },
      { query: getEmployees },
    ],
  };

  const [addEvent] = useMutation(createEvent, refresQuery);
  const [editEvent] = useMutation(updateEvent, refresQuery);
  const [removeEvent] = useMutation(deleteEvent, refresQuery);
  const [removeTempEvent] = useMutation(deleteTempEvent, refresQuery);

  const [getCalEvents, evnData]: any = useLazyQuery(getEvents, {
    fetchPolicy: 'cache-and-network',
  });

  // const location = useLocation();

  const femployees = employees?.filter((em: any) => em.isCalendar);
  const semployees = _.sortBy(femployees, isRTL ? 'nameAr' : 'name');

  const instances = semployees?.map((res: any) => {
    return {
      text: isRTL ? res?.nameAr : res.name,
      id: res._id ? res?._id : res.id,
      color: res?.color,
    };
  });
  const resourses = [
    {
      fieldName: 'employeeId',
      title: 'Data',
      instances,
    },
  ];
  const grouping = [
    {
      resourceName: 'employeeId',
    },
  ];

  const usegroup = instances?.length > 0 && calendar.group && !isEmployee;
  useEffect(() => {
    const eventsData = evnData?.data?.['getEvents']?.data || [];
    const events =
      eventsData.length > 0
        ? eventsData.map((ap: any) => {
            const end = new Date(ap.endDate);
            if (ap.startDate === ap.endDate) {
              end.setHours(end.getHours() + 1);
            }
            const workers = wemployees.filter((emp: any) =>
              ap.workersIds?.includes(emp?._id)
            );

            return {
              ...ap,
              startDate: new Date(ap.startDate),
              endDate: end,
              start: ap.startDate,
              end: ap.endDate,
              workers,
            };
          })
        : [];

    setRows(events);
  }, [evnData]);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (evnData?.refetch) {
        evnData.refetch();
      }
    },
  }));

  const refresh = () => {
    if (evnData?.refetch) {
      evnData.refetch();
    }
  };

  // useLayoutEffect(() => {
  //   refresh();
  // }, [location]);

  const setDepartvalueDispatch = (value: any) => {
    dispatch({ type: 'setDepartvalue', payload: value });
  };
  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setStatusDispatch = (value: any) => {
    dispatch({ type: 'setStatus', payload: value });
  };

  const setDay = () => dispatch({ type: 'setCurrentViewName', payload: 'Day' });
  const setWeek = () =>
    dispatch({ type: 'setCurrentViewName', payload: 'Week' });

  useEffect(() => {
    if (!isMobile) {
      setWeek();
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     !open &&
  //     !evenetOpened &&
  //     sub?.data?.eventChange?.time &&
  //     sub?.data?.eventChange?.time !== time
  //   ) {
  //     setTime(sub?.data?.eventChange?.time);
  //     refresh();
  //   }
  // }, [sub]);
  useEffect(() => {
    const startend: any = getStartEndEventView({
      time: currentDate,
      view: currentViewName,
    });
    setStart(startend.start);
    setEnd(startend.end);
    const variables = {
      departmentId: departvalue ? departvalue._id : undefined,
      employeeId: isEmployee
        ? employeeId
        : emplvalue
        ? emplvalue._id
        : undefined,
      status: status ? status.id : undefined,
      start: startend.start,
      end: startend.end,
    };
    getCalEvents({ variables });
  }, [
    currentDate,
    currentViewName,
    departvalue,
    emplvalue,
    getCalEvents,
    status,
  ]);

  const currentViewNameChange = (e: any) => {
    dispatch({ type: 'setCurrentViewName', payload: e.target.value });
  };
  const currentDateChange = (curDate: any) => {
    dispatch({ type: 'setCurrentDate', payload: curDate });
  };
  const commitChanges = async ({ added, changed, deleted }: any) => {
    if (changed && !userroles?.appointments.edit) return;
    commitAppointmentChanges({
      added,
      changed,
      deleted,
      addEvent,
      editEvent,
      removeEvent,
      isRTL,
      employees,
    });
    if (tempoptions?.tempevent) {
      removeTempEvent({});
    }
  };

  const openInvoice = () => {
    setVisible(false);
    setOpen(true);
  };
  const closeInvoice = () => {
    setOpen(false);
    setItemsList([]);
    setAppointmentData([]);
  };
  const title = getPopupGeneralTitle('appointment');

  return (
    <Box
      style={{
        height: height - 50,
        marginTop: isMobile ? 50 : undefined,
        width,
      }}
    >
      {isMobile && (
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 15,
            zoom: isMobile ? 0.85 : undefined,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            views={calendar.group || isEmployee ? [1, 3, 7] : [1, 3, 7, 30]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
      )}
      {!isMobile && (
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 15,
            zoom: isMobile ? 0.85 : undefined,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            views={calendar.group || isEmployee ? [1, 3, 7] : [1, 3, 7, 30]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
          <div style={{ width: 20 }}></div>
          <FormControlLabel
            style={{
              backgroundColor: group ? '#aaa' : '#ddd',
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 10,
              height: 36,
            }}
            control={
              <Checkbox
                checked={group}
                onChange={() => {
                  const nv = !group;
                  if (nv) {
                    setMainResourceName('employeeId');
                    setResourseData(resourses);
                    setDay();
                  } else {
                    setWeek();
                  }
                  setGroup(nv);
                  gDispatch({
                    type: 'setCalendar',
                    payload: { ...calendar, group: nv },
                  });
                }}
                name="bold"
                color="primary"
              />
            }
            label={isRTL ? 'جدول الموظفين' : 'Employees View'}
          />
          {!group && (
            <Box
              style={{
                position: 'absolute',
                zIndex: 110,
                top: -3,
                display: 'flex',
                left: isRTL ? 400 : undefined,
                right: isRTL ? undefined : 400,
                flexGrow: 1,
                width: '50%',
              }}
            >
              <EventsCalFilter
                count={rows.length}
                departvalue={departvalue}
                setDepartvalue={setDepartvalueDispatch}
                emplvalue={emplvalue}
                setEmplvalue={setEmplvalueDispatch}
                status={status}
                setStatus={setStatusDispatch}
                setMainResourceName={setMainResourceName}
                mainResourceName={mainResourceName}
                setResourseData={setResourseData}
                isRTL={isRTL}
                words={words}
                theme={theme}
                refresh={refresh}
                loading={evnData?.loading}
              ></EventsCalFilter>
            </Box>
          )}
        </Box>
      )}
      <Paper
        style={{
          display: 'flex',
          height: height - (isMobile ? 100 : 125),
          overflow: 'auto',
          borderRadius: 25,
          width: !isMobile ? width - drawerWidth - 50 : undefined,
        }}
      >
        <Scheduler
          data={rows}
          height={currentViewName === 'Month' ? 'auto' : height - 135}
          firstDayOfWeek={6}
          locale={isRTL ? 'ar' : 'en'}
        >
          {userroles?.appointments?.add && (
            <EditingState onCommitChanges={commitChanges} />
          )}
          {usegroup && (
            <GroupingState
              grouping={grouping}
              // groupOrientation={() => 'Vertical'}
            />
          )}
          <ViewState
            currentViewName={currentViewName}
            onCurrentViewNameChange={currentViewNameChange}
            currentDate={currentDate}
            onCurrentDateChange={currentDateChange}
          />
          <DayView
            name="Day"
            displayName="Day"
            cellDuration={calendar ? calendar?.duration : 30}
            startDayHour={calendar ? calendar?.start : 8.5}
            endDayHour={calendar ? calendar?.end : 21.5}
            dayScaleCellComponent={(props: any) => (
              <DayScaleCell employees={employees} {...props}></DayScaleCell>
            )}
            timeScaleLabelComponent={TimeScaleLabel}
          />
          <DayView
            name="3Days"
            displayName="3 Days"
            intervalCount={3}
            cellDuration={calendar ? calendar?.duration : 30}
            startDayHour={calendar ? calendar?.start : 8.5}
            endDayHour={calendar ? calendar?.end : 21.5}
            dayScaleCellComponent={(props: any) => (
              <DayScaleCell employees={employees} {...props}></DayScaleCell>
            )}
            timeScaleLabelComponent={TimeScaleLabel}
          />
          <WeekView
            name="Week"
            displayName="Week"
            cellDuration={calendar ? calendar?.duration : 30}
            startDayHour={calendar ? calendar?.start : 8.5}
            endDayHour={calendar ? calendar?.end : 21.5}
            dayScaleCellComponent={(props: any) => (
              <DayScaleCell employees={employees} {...props}></DayScaleCell>
            )}
            timeScaleLabelComponent={TimeScaleLabel}
          />
          <MonthView intervalCount={1} />

          <Appointments
            appointmentContentComponent={(props: any) => (
              <AppointmentContent
                {...props}
                calendar={calendar}
              ></AppointmentContent>
            )}
            appointmentComponent={(props: any) => {
              return (
                <Appointments.Appointment
                  {...props}
                  style={{
                    backgroundColor:
                      props?.data?.temp && tempoptions?.tempevent
                        ? '#ff000055'
                        : undefined,
                  }}
                ></Appointments.Appointment>
              );
            }}
          />
          <Resources
            data={usegroup ? resourses : resourseData}
            mainResourceName={usegroup ? 'employeeId' : mainResourceName}
          />
          {userroles?.appointments?.add && <IntegratedEditing />}
          {usegroup && <IntegratedGrouping />}
          <AppointmentTooltip
            showCloseButton
            showOpenButton={userroles?.appointments?.edit}
            showDeleteButton={userroles?.appointments?.delete}
            visible={visible}
            onVisibilityChange={() => {
              setItemsList([]);
              setVisible(!visible);
            }}
            contentComponent={({ appointmentData }) => (
              <RenderToolTip
                appointmentData={appointmentData}
                setAppointmentData={setAppointmentData}
                itemsList={itemsList}
                setItemsList={setItemsList}
                openInvoice={openInvoice}
                editEvent={editEvent}
                setVisible={setVisible}
              ></RenderToolTip>
            )}
          />
          {userroles?.appointments?.add && (
            <AppointmentForm
              overlayComponent={(args: any) => (
                <PopupLayoutBox
                  isRTL={isRTL}
                  theme={theme}
                  alrt={{}}
                  title={title}
                  mt={0}
                  mb={50}
                  {...args}
                  setEvenetOpened={setEvenetOpened}
                  removeTempEvent={removeTempEvent}
                  setTemp={setTemp}
                  isMobile={isMobile}
                  tempoptions={tempoptions}
                ></PopupLayoutBox>
              )}
              basicLayoutComponent={(pr: any) => (
                <AppointForm
                  theme={theme}
                  isMobile={isMobile}
                  calendar={calendar}
                  isEmployee={isEmployee}
                  employeeId={employeeId}
                  evenetOpened={evenetOpened}
                  setTemp={setTemp}
                  temp={temp}
                  {...pr}
                ></AppointForm>
              )}
              commandButtonComponent={CommandAppointment}
            />
          )}
          {usegroup && <GroupingPanel cellComponent={GroupHeadCell} />}
          {userroles?.appointments?.edit && <DragDropProvider />}
          <CurrentTimeIndicator
            // shadePreviousAppointments
            shadePreviousCells
            indicatorComponent={IndecatorColor}
          ></CurrentTimeIndicator>
        </Scheduler>
        <PopupAppointInvoice
          open={open}
          onClose={closeInvoice}
          appoint={appointmentData}
          services={services}
          editEvent={editEvent}
          company={company}
          theme={theme}
          items={itemsList}
        ></PopupAppointInvoice>
      </Paper>
    </Box>
  );
});

export default CalendarEmp;
