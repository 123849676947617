import { gql } from '@apollo/client';

export default gql`
  query getFoundations {
    getFoundations {
      ok
      error
      data {
        _id

        name
        nameAr
        logo
        header
        footer
        color

        tel1
        tel2
        fax
        mob
        email
        website
        address
        poBox
        city
        country

        userId
        note

        createdAt
        updatedAt
      }
    }
  }
`;
