/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import useProcItems from '../../hooks/useProcItems';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getDocumentProcs } from '../../graphql/query';
import { createProc } from '../../graphql/mutation';
import PopupProcedure from '../../pubups/PopupProcedure';
import TimelineProc from './TimelineProc';
import updateProcStatus from '../../graphql/mutation/updateProcStatus';
import PopupStep from '../../pubups/PopupStep';

export default function ProcedureBox({
  row,
  user,
  words,
  isRTL,
  theme,
  height,
  users,
}: any) {
  const [open, setOpen] = useState<any>(false);
  const [openstep, setOpenstep] = useState<any>(false);
  const [selected, setSelected] = useState<any>(null);
  const [saving, setSaving] = useState<any>(false);
  const [data, setData] = useState([]);

  const { procitems } = useProcItems();

  const [loadProcs, procsData]: any = useLazyQuery(getDocumentProcs, {
    fetchPolicy: 'cache-and-network',
  });
  const refresQuery = {
    refetchQueries: [
      {
        query: getDocumentProcs,
        variables: { opId: row?._id },
      },
    ],
  };
  const [addProc] = useMutation(createProc, refresQuery);
  const [addStep] = useMutation(updateProcStatus, refresQuery);

  useEffect(() => {
    const variables = { opId: row?._id };
    if (row?._id) {
      loadProcs({ variables });
    }
  }, [row]);

  useEffect(() => {
    const d = procsData?.data?.getDocumentProcs?.data;
    if (d) {
      setData(d);
    }
  }, [procsData]);

  return (
    <Box style={{ height: height - 85, overflow: 'hidden' }}>
      <Paper
        style={{
          overflow: 'auto',
          height: height - 85,
          backgroundColor: '#f5f5f5',
        }}
        elevation={5}
      >
        <Box>
          <TimelineProc
            add={() => setOpen(true)}
            data={data}
            isRTL={isRTL}
            setSelected={setSelected}
            setOpenstep={setOpenstep}
          ></TimelineProc>
        </Box>
      </Paper>

      <PopupProcedure
        open={open}
        onClose={() => setOpen(false)}
        isRTL={isRTL}
        words={words}
        addProc={addProc}
        theme={theme}
        saving={saving}
        setSaving={setSaving}
        data={data}
        user={user}
        operation={row}
        procitems={procitems}
        usrs={users}
      ></PopupProcedure>
      <PopupStep
        open={openstep}
        onClose={() => {
          setOpenstep(false);
          setSelected(null);
        }}
        isRTL={isRTL}
        words={words}
        addStep={addStep}
        theme={theme}
        saving={saving}
        setSaving={setSaving}
        procId={selected?._id}
      ></PopupStep>
    </Box>
  );
}
