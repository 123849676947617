/* eslint-disable no-new-func */
import {
  moneyFormat,
  qtyFormat,
  simpleDateFormatter,
  simpleTimeFormatter,
} from '../../../Shared/colorFormat';
import { getClassNames } from './clasnames';
import PrintTable from './PrintTable';
import { nanoid } from 'nanoid';
import { Box, Checkbox } from '@material-ui/core';
import PrintParagraphs from './PrintParagraphs';
import ReactHtmlParser from 'react-html-parser';
import Editor from '../components/Editor';

const renderText = ({ value, classnames, classes, style, namevalue }: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div
      className={cn}
      style={{
        ...style,
        wordWrap: 'normal',
        letterSpacing: 'normal',
        flexWrap: 'wrap',
      }}
    >
      {value ? value : namevalue}
    </div>
  );
};

const renderEditor = ({ value, style, namevalue }: any) => {
  if (!namevalue || namevalue === '-') return <div>-</div>;
  return (
    <div style={{ margin: 10 }}>
      <Editor value={namevalue} setValue={() => null} readOnly />
    </div>
  );
};

const getReadyText = ({ body, data }: any) => {
  const holder = /\{(\w+?)}/g;
  return body.replace(holder, (_: any, name: any) => data[name]);
};
const renderTempText = ({ value, data, classnames, classes, style }: any) => {
  const cn = getClassNames({ classnames, classes });
  const rvalue = getReadyText({ body: value, data });
  const lines = rvalue.split('||');
  return (
    <>
      {lines?.map((val: any) => {
        return (
          <div
            className={cn}
            style={{
              ...style,
              wordWrap: 'normal',
              letterSpacing: 'normal',
              flexWrap: 'wrap',
            }}
          >
            {val}
          </div>
        );
      })}
    </>
  );
};
const renderHtmlText = ({ value, classnames, classes, style }: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div
      className={cn}
      style={{
        ...style,
        wordWrap: 'normal',
        letterSpacing: 'normal',
        flexWrap: 'wrap',
      }}
    >
      {ReactHtmlParser(value)}
    </div>
  );
};
const renderPageBreak = ({ style }: any) => {
  return (
    <div>
      <div style={{ clear: 'both' }}></div>
      <div style={{ pageBreakAfter: 'always', ...style }}></div>
      <div style={{ clear: 'both' }}></div>
    </div>
  );
};
const renderMoney = ({
  name,
  value,
  classnames,
  style,
  classes,
  namevalue,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  const isinf = !isFinite(namevalue);
  return (
    <div className={cn} style={{ ...style }}>
      {moneyFormat(value ? Number(value) : isinf ? null : namevalue)}
    </div>
  );
};
const renderQty = ({
  name,
  value,
  classnames,
  style,
  classes,
  namevalue,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div className={cn} style={{ ...style }}>
      {qtyFormat(value ? Number(value) : namevalue)}
    </div>
  );
};

const renderDate = ({
  name,
  value,
  classnames,
  style,
  classes,
  namevalue,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div className={cn} style={{ ...style }}>
      {simpleDateFormatter(value ? value : namevalue)}
    </div>
  );
};
const renderTime = ({
  name,
  value,
  classnames,
  style,
  classes,
  namevalue,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div className={cn} style={{ ...style }}>
      {simpleTimeFormatter(value ? value : namevalue)}
    </div>
  );
};

const renderImage = ({ name, value, style, namevalue }: any) => {
  return <img style={style} alt="" src={value ? value : namevalue} />;
};
const renderCheckbox = ({ name, style, namevalue }: any) => {
  const checked = namevalue ? true : false;
  return <Checkbox style={{ ...style, color: '#333' }} checked={checked} />;
};
const renderTable = ({
  name,
  value,
  classnames,
  style,
  data,
  item,
  isPOS,
  isRTL,
}: any) => {
  return (
    <PrintTable
      data={data}
      name={value ? value : data?.[name]}
      classnames={classnames}
      style={style}
      rows={data?.items}
      columns={item?.tableitems}
      addTotal={item?.total}
      isPOS={isPOS}
      isRTL={isRTL}
    />
  );
};
const renderParagraphs = ({
  name,
  value,
  classnames,
  style,
  data,
  item,
  isPOS,
  isRTL,
}: any) => {
  return (
    <PrintParagraphs
      data={data}
      name={value ? value : data?.[name]}
      classnames={classnames}
      style={style}
      rows={data?.paragraphs}
      columns={item?.tableitems}
      addTotal={item?.total}
      isPOS={isPOS}
      isRTL={isRTL}
    />
  );
};

export const basicitem = {
  name: '',
  value: '-',
  type: 'text',
  skip: 0,
  size: 1,
  classnames: ['center'],
  style: {},
  tableitems: [],
  border: false,
};

export const basictableitem = {
  name: '',
  value: '',
  width: 200,
  height: 50,
  itemheight: 40,
  size: 18,
  itemsize: 16,
  bold: true,
  align: 'left',
  money: false,
  qty: false,
  color: undefined,
};

export const zeroeleven = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
];
export const onetwelve = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const renderCell = (
  type: any,
  args: any,
  classes: any,
  view = false
) => {
  const { data, name, ...rest } = args;
  const namevalue = data?.[name];
  const borderBottom = args?.classnames?.includes('bb') ? 1 : 0;
  return (
    <Box
      border={args?.border === true ? 1 : 0}
      borderBottom={borderBottom}
      borderColor="grey.400"
      className={view ? '' : classes.hvr}
    >
      {type === 'text' && renderText({ namevalue, ...rest })}
      {type === 'editor' && renderEditor({ namevalue, ...rest })}
      {type === 'temptext' && renderTempText({ data, ...rest })}
      {type === 'html' && renderHtmlText({ ...rest })}
      {type === 'date' && renderDate({ name, namevalue, ...rest })}
      {type === 'time' && renderTime({ name, namevalue, ...rest })}
      {type === 'money' && renderMoney({ name, namevalue, ...rest })}
      {type === 'qty' && renderQty({ name, namevalue, ...rest })}
      {type === 'image' && renderImage({ name, namevalue, ...rest })}
      {type === 'table' && renderTable({ name, data, ...rest })}
      {type === 'paragraphs' && renderParagraphs({ name, data, ...rest })}
      {type === 'checkbox' && renderCheckbox({ name, namevalue, ...rest })}
      {type === 'pb' && renderPageBreak({ name, namevalue, ...rest })}
    </Box>
  );
};

export const rid = () => {
  return nanoid(4);
};
