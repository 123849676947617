import { Link, Typography } from '@material-ui/core';
import MyIcon from '../Shared/MyIcon';

export default function LinkPhone({ value }: any) {
  var localphone =
    value && value.length > 7 ? value?.substr(value?.length - 8) : value;

  return (
    <Link
      style={{
        display: 'flex',
        backgroundColor: '#eee',
        padding: 4,
        borderRadius: 5,
        textDecoration: 'none',
      }}
      href={`tel:${localphone}`}
    >
      <Typography style={{ marginLeft: 5, marginRight: 5 }}>
        {localphone}
      </Typography>
      <MyIcon
        style={{ marginLeft: 10, marginRight: 10 }}
        icon="call"
        size={24}
      ></MyIcon>
    </Link>
  );
}
