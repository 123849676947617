import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import { useEmployees, useTemplate } from '../../../../hooks';
import useCompany from '../../../../hooks/useCompany';
import useRetypes from '../../../../hooks/useRetypes';
import { getColor } from '../../common/helpers';
import EmployeesStatus from '../EmployeesStatus';

function EmployeesBox({
  isRTL,
  theme,
  dark,
  words,
  systemcolor,
  color,
  height,
}) {
  const { tempwords } = useTemplate();
  const { employees } = useEmployees();
  const { retypes } = useRetypes();
  const { company } = useCompany();

  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {userroles?.manageemployees?.edit && employees && (
        <EmployeesStatus
          title={tempwords?.employees}
          data={employees}
          retypes={retypes}
          height={height}
          isRTL={isRTL}
          color={clr}
          photos={company?.photos}
        ></EmployeesStatus>
      )}
    </Box>
  );
}

export default EmployeesBox;
