/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  yup,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { ImageOnlineUpload, uploadPhotoOnline } from '../common/upload';
import { useCategories, useProducts, useTemplate } from '../hooks';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { invoiceClasses } from '../themes';
import CollectiveItemForm from '../Shared/CollectiveItemForm';
import CollectiveItemTable from '../Shared/CollectiveItemTable';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const PopupCollectiveitem = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  editAction,
  newtext,
  theme,
  services,
  rentitems,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [catvalue, setCatvalue] = useState<any>(null);

  const [itemsList, setItemsList] = useState<any>([]);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [logoimage, setLogoimage] = useState(null);
  const [logourl, setLogourl] = useState(null);
  const { categories } = useCategories();
  const classes = invoiceClasses();

  const { register, handleSubmit, errors, reset } = useForm(yup.itmResolver);
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const { tempoptions } = useTemplate();
  const { products } = useProducts();
  useEffect(() => {
    if (row?._id) {
      if (row?.photo) {
        setLogourl(row?.photo);
      }
      if (row?.categoryId) {
        const depart = categories.filter(
          (dep: any) => dep._id === row.categoryId
        )[0];
        setCatvalue(depart);
      }
      if (row?.subitems) {
        const sublist = JSON.parse(row?.subitems);
        const listwithindex = indexTheList(sublist);
        setItemsList(listwithindex);
      }
    }
  }, [row]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };
  const resetAll = () => {
    reset();
    setLogoimage(null);
    setCatvalue(null);
    setLogourl(null);
  };

  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data.name.trim();
    const nameAr = !tempoptions?.nameonelang ? data.nameAr.trim() : name;
    const { price, unit, desc, barcode } = data;
    let photo: any;

    if (logoimage) {
      photo = await uploadPhotoOnline(logoimage);
      photo = photo.replace('http://', 'https://');
    }
    const category = catvalue
      ? {
          categoryId: catvalue._id,
          categoryName: catvalue.name,
          categoryNameAr: catvalue.nameAr,
        }
      : {
          categoryId: undefined,
          categoryName: undefined,
          categoryNameAr: undefined,
        };

    const subitemslist =
      itemsList?.length > 0
        ? itemsList.map((itm: any) => {
            return {
              _id: itm._id,
              itemType: itm.itemType,
              name: itm.name,
              nameAr: itm.nameAr,
              itemqty: itm.itemqty,
              price: itm.price,
              cost: itm.cost,
            };
          })
        : undefined;

    const subitems = subitemslist ? JSON.stringify(subitemslist) : undefined;
    const variables: any = {
      _id: row && row._id ? row._id : undefined,
      itemType: 5,
      barcode: barcode?.length > 0 ? barcode : undefined,
      name,
      nameAr,
      category,
      price: Number(price),
      unit,
      desc,
      photo,
      userId: user._id,
      subitems,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createItem' : 'updateItem';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem, 'item');

      await successAlert(setAlrt, isRTL);
      closeModal();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      resetAll();
      console.log(error);
    }
  };

  const closeModal = () => {
    onClose();
    resetAll();
    setSaving(false);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const title = isRTL
    ? isNew
      ? 'خدمة جديدة'
      : 'تعديل خدمة'
    : isNew
    ? 'New Service'
    : 'Edit Service';
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ImageOnlineUpload
                url={logourl}
                setUrl={setLogourl}
                image={logoimage}
                setImage={setLogoimage}
                width={150}
                height={150}
                size="400x400"
              ></ImageOnlineUpload>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {tempoptions?.barcode && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      autoFocus={tempoptions?.barcode}
                      name="barcode"
                      label={words.code}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextFieldLocal
                    autoFocus={!tempoptions?.barcode}
                    required
                    name="name"
                    label={words.name}
                    register={register}
                    errors={errors}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                {!tempoptions?.nameonelang && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      required
                      name="nameAr"
                      label={words.nameAr}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                <Grid item xs={8}>
                  <TextFieldLocal
                    required
                    name="price"
                    label={words.price}
                    register={register}
                    errors={errors}
                    type="number"
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldLocal
                    name="unit"
                    label={words.unit}
                    register={register}
                    errors={errors}
                    row={row}
                    newtext={newtext}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="category"
                    title={words?.category}
                    words={words}
                    options={categories.filter((dep: any) => dep.catType === 5)}
                    value={catvalue}
                    setSelectValue={setCatvalue}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CollectiveItemForm
                services={services}
                products={products}
                rentitems={rentitems}
                addItem={addItemToList}
                words={words}
                user={user}
                isRTL={isRTL}
                setAlrt={setAlrt}
                classes={classes}
                issale={true}
                itemrelations={false}
              ></CollectiveItemForm>
              <CollectiveItemTable
                products={[...rentitems, ...services, ...rentitems]}
                rows={itemsList}
                editItem={editItemInList}
                removeItem={removeItemFromList}
                isRTL={isRTL}
                words={words}
                user={user}
                itemrelations={false}
              ></CollectiveItemTable>
            </Grid>
            <Grid item xs={12}>
              <TextFieldLocal
                name="desc"
                label={words.description}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                multiline
                rowsMax={4}
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupCollectiveitem;
