import { gql } from '@apollo/client';

export default gql`
  mutation createTask(
    $branch: String
    $title: String
    $printdate: Date
    $printtitle: String
    $address: String
    $desc: String
    $extra: String
    $photos: String
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $type: String
    $start: Date
    $end: Date
    $progress: Float
    $freq: Int
    $count: Int
    $interval: Int
    $weekdays: String
    $monthdays: String
    $many: Boolean
    $tasktype: Int
    $typefree: Int
    $periodType: Int
    $periodCost: Float
    $dayCost: Float
    $status: Int
    $evQty: Int
    $evDone: Int
    $events: String
    $items: String
    $location: LocationInput
    $total: Float
    $discount: Float
    $amount: Float
    $deposit: Float
    $priority: Int
    $customer: CustomerInput
    $department: DepartmentInput
    $employee: EmployeeInput
    $project: ProjectInput
    $resourse: ResourseInput
    $retype: RetypeInput
    $retype2: Retype2Input
    $ismemorized: Boolean
    $isatStart: Boolean
    $memId: String
    $info: String
  ) {
    createTask(
      branch: $branch
      title: $title
      printdate: $printdate
      printtitle: $printtitle
      address: $address
      desc: $desc
      extra: $extra
      photos: $photos
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      type: $type
      start: $start
      end: $end
      progress: $progress
      freq: $freq
      count: $count
      interval: $interval
      weekdays: $weekdays
      monthdays: $monthdays
      many: $many
      tasktype: $tasktype
      typefree: $typefree
      periodType: $periodType
      periodCost: $periodCost
      dayCost: $dayCost
      status: $status
      evQty: $evQty
      evDone: $evDone
      events: $events
      items: $items
      location: $location
      total: $total
      discount: $discount
      amount: $amount
      deposit: $deposit
      priority: $priority
      customer: $customer
      department: $department
      employee: $employee
      project: $project
      resourse: $resourse
      retype: $retype
      retype2: $retype2
      ismemorized: $ismemorized
      isatStart: $isatStart
      memId: $memId
      info: $info
    ) {
      ok
      message
      data
      error
    }
  }
`;
