import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { moneyFormat, getColor } from '../../common/helpers';
import InfoBox from '../InfoBox';

function CardBoxToday({
  isRTL,
  theme,
  words,
  dark,
  color,
  systemcolor,
  qty,
  money,
  height,
}) {
  const { raseedstoday } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {userroles?.invoices?.admin && (
        <InfoBox
          title={isRTL ? 'البطاقة اليوم' : 'Card Today'}
          value={moneyFormat(raseedstoday?.card)}
          icon="cash"
          color={clr}
          dark={dark}
          height={height}
        ></InfoBox>
      )}
    </Box>
  );
}

export default CardBoxToday;
