/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { invoiceClasses } from '../themes';
import { useServices, useSuppliers, useTemplate } from '../hooks';
import {
  dublicateAlert,
  errorAlert,
  messageAlert,
  moneyFormat,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Box, TextField, Typography } from '@material-ui/core';
import ItemsTable from '../Shared/ItemsTable';
import { PriceTotal } from '../Shared/TotalPrice';
import { operationTypes } from '../constants';
import { accountCode, parents } from '../constants/kaid';
import { useLazyQuery } from '@apollo/client';
import { getOperationItems } from '../graphql';
import LoadingInline from '../Shared/LoadingInline';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal } from '../components';
import PopupSupplier from './PopupSupplier';
import _ from 'lodash';
import useTasks from '../hooks/useTasks';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import PopupResourses from './PopupResourses';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import { weekdaysNNo } from '../constants/datatypes';
import { useReactToPrint } from 'react-to-print';
import { successAlert } from '../Shared/helpers';
import ServiceItemForm from '../Shared/ServiceItemForm';
import PopupIlisttemsImport from './PopupIlisttemsImport';
import ImportBtn from '../common/ImportBtn';
import { getDefaultTemplate, tafkeet } from '../common/helpers';
import usePrintTemplates from '../hooks/usePrintTemplates';
import PrintDownload from '../Shared/PrintDownload';
import useCompany from '../hooks/useCompany';
import { userroles } from '../common';
import { uploadMultiPhotoOnline } from '../common/AvatarUpload';
import { ImageView } from '../components/viewer';
import { UploadPhotos } from '../common/UploadPhotos';
import PaymentSelect from '../pages/options/PaymentSelect';
import useAccounts from '../hooks/useAccounts';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const PopupPurchaseInvoice = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  resourses,
  employees,
  departments,
  servicesproducts,
  theme,
  task,
  value,
  name,
}: any) => {
  const classes = invoiceClasses();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { tasks } = useTasks();

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [invNo, setInvNo] = useState<any>('');

  const [itemsList, setItemsList] = useState<any>([]);
  const [accounts, setAccounts] = useState<any>([]);

  const [isCash, setIsCash] = useState(false);
  const [paid, setPaid] = useState<any>(0);
  const [payAcc, setPayAcc]: any = React.useState(null);
  const [desc, setDesc] = useState(null);

  const [discount, setDiscount] = useState(0);
  const [totals, setTotals] = useState<any>({});

  const [urls, setUrls] = useState([]);
  const [photosimages, setPhotosimages] = useState([]);
  const [photosurls, setPhotosurls] = useState([]);

  const [openImport, setOpenImport] = useState(false);

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();

  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [suppvalue, setSuppvalue] = useState<any>(
    name === 'supplierId' ? value : null
  );

  const [taskvalue, setTaskvalue] = useState<any>(
    name === 'contractId' ? value : null
  );
  const [newtext, setNewtext] = useState('');

  const [openSupp, setOpenSupp] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const { services } = useServices();
  const { accounts: mainAccounts } = useAccounts();

  const { suppliers, addSupplier, editSupplier } = useSuppliers();
  const { addDepartment, editDepartment } = useDepartments();
  const { addEmployee, editEmployee } = useEmployees();
  const { addResourse, editResourse } = useResourses();
  const { tempwords, tempoptions } = useTemplate();
  const { printtemplates } = usePrintTemplates();
  const { company } = useCompany();

  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const isemployee = user?.isEmployee && user?.employeeId;

  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });

  const openDepartment = () => {
    setOpenDep(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };

  const openSupplier = () => {
    setOpenSupp(true);
  };
  const onCloseSupplier = () => {
    setOpenSupp(false);
    setNewtext('');
  };
  const onNewSuppChange = (nextValue: any) => {
    setSuppvalue(nextValue);
  };
  const onNewDepartChange = (nextValue: any) => {
    setDepartvalue(nextValue);
  };
  const onNewEmplChange = (nextValue: any) => {
    setEmplvalue(nextValue);
  };
  const onNewResoChange = (nextValue: any) => {
    setResovalue(nextValue);
  };

  useEffect(() => {
    if (isemployee) {
      const emp = employees.filter(
        (em: any) => em._id === user.employeeId
      )?.[0];
      setEmplvalue(emp);
    }
  }, [user, employees]);

  useEffect(() => {
    if (isNew && !isemployee) {
      if (taskvalue) {
        if (taskvalue?.departmentId && name !== 'departmentId') {
          const dept = departments.filter(
            (dep: any) => dep._id === taskvalue?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (taskvalue?.employeeId && name !== 'employeeId') {
          const emp = employees.filter(
            (dep: any) => dep._id === taskvalue?.employeeId
          )?.[0];
          setEmplvalue(emp);
        }
        if (taskvalue?.resourseId && name !== 'resourseId') {
          const res = resourses.filter(
            (dep: any) => dep._id === taskvalue?.resourseId
          )?.[0];
          setResovalue(res);
        }
        if (taskvalue?.supplierId && name !== 'supplierId') {
          const cust = suppliers.filter(
            (dep: any) => dep._id === taskvalue?.supplierId
          )?.[0];
          setSuppvalue(cust);
        }
      }
    }
  }, [taskvalue]);

  useEffect(() => {
    const items = itemsData?.data?.['getOperationItems']?.data || [];

    if (items && items.length > 0) {
      const ids = items.map((it: any) => it.itemId);
      const servlist = [...servicesproducts, ...services].filter((ser: any) =>
        ids.includes(ser._id)
      );

      const itemsWqtyprice = items.map((item: any, index: any) => {
        const {
          categoryId,
          categoryName,
          categoryNameAr,
          departmentId,
          departmentName,
          departmentNameAr,
          departmentColor,
          employeeId,
          employeeName,
          employeeNameAr,
          employeeColor,
          resourseId,
          resourseName,
          resourseNameAr,
          resourseColor,
          contractId,
          contractName,
          contractNameAr,
        } = item;
        const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
        return {
          ...serv,
          categoryId,
          categoryName,
          categoryNameAr,
          departmentId,
          departmentName,
          departmentNameAr,
          departmentColor,
          employeeId,
          employeeName,
          employeeNameAr,
          employeeColor,
          resourseId,
          resourseName,
          resourseNameAr,
          resourseColor,
          contractId,
          contractName,
          contractNameAr,
          index,
          itemprice: item.itemPrice,
          itemqty: item.qty,
          itemtotal: item.total,
          itemtotalcost: item.qty * serv.cost,
        };
      });
      itemsWqtyprice.sort((a: any, b: any) =>
        a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
      );
      setItemsList(itemsWqtyprice);
      setLoading(false);
    } else {
      setItemsList([]);
    }
  }, [itemsData]);

  const { handleSubmit, reset } = useForm({});

  const resetAllForms = () => {
    reset();
    setItemsList([]);
    setDiscount(0);
    setTotals({});
    setInvNo('');
    setAccounts([]);
    setIsCash(false);
    setSelectedDate(new Date());
    setSuppvalue(name === 'supplierId' ? value : null);
    setDepartvalue(name === 'departmentId' ? value : null);
    setEmplvalue(name === 'employeeId' ? value : null);
    setResovalue(name === 'resourseId' ? value : null);
    setTaskvalue(name === 'contractId' ? value : null);
    setPaid(0);
    setPayAcc(null);
    setDesc(null);
  };
  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const handleDateChange = (date: any) => {
    if (date) {
      const d = new Date(date);
      d?.setHours(8, 0, 0);
      setSelectedDate(d);
    }
  };

  useEffect(() => {
    if (isNew) {
      if (name === 'contractId') {
        if (value?.departmentId) {
          const dept = departments.filter(
            (dep: any) => dep._id === value?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (value?.employeeId) {
          const dept = employees.filter(
            (dep: any) => dep._id === value?.employeeId
          )?.[0];
          setEmplvalue(dept);
        }
        if (value?.resourseId) {
          const dept = resourses.filter(
            (dep: any) => dep._id === value?.resourseId
          )?.[0];
          setResovalue(dept);
        }
        if (value?.supplierId) {
          const dept = suppliers.filter(
            (dep: any) => dep._id === value?.supplierId
          )?.[0];
          setSuppvalue(dept);
        }
      }
    }
  }, [open]);

  useEffect(() => {
    getOverallTotal();
  }, [itemsList, discount, isCash, paid, payAcc]);

  useEffect(() => {
    if (row && row._id) {
      setLoading(true);
      const variables = { opId: row._id };
      getItems({
        variables,
      });
      const _id = row.supplierId;
      const supp = suppliers.filter((it: any) => it._id === _id)[0];

      const depId = row.departmentId;
      const empId = row.employeeId;
      const resId = row.resourseId;
      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }

      if (!task && row.contractId) {
        const tsk = tasks.filter((ts: any) => ts._id === row.contractId)[0];
        setTaskvalue(tsk);
      }
      if (row.attachments) {
        const phs = JSON.parse(row.attachments);
        if (phs && phs.length > 0) {
          setPhotosurls(phs);
        }
      }

      if (row?.amountPaid) {
        setPaid(row?.amountPaid);
      }
      if (row?.payAcc) {
        const pay = mainAccounts.filter(
          (acc: any) => acc.code === row?.payAcc
        )?.[0];
        setPayAcc(pay);
      }

      setSuppvalue(supp);
      setIsCash(row.isCash);
      setDiscount(row.discount);
      setInvNo(row.docNo);
      handleDateChange(row.time);
      setDesc(row?.desc);
    }
  }, [row]);

  useEffect(() => {
    let locals = [];
    let online = [];
    if (photosimages && photosimages.length > 0) {
      for (const img of photosimages) {
        const localimage = URL.createObjectURL(img);
        locals.push(localimage);
      }
    } else {
      locals = [];
    }
    if (photosurls && photosurls.length > 0) {
      online = photosurls;
    } else {
      online = [];
    }
    setUrls([...online, ...locals]);
  }, [photosimages, photosurls]);

  useEffect(() => {
    if (isNew) {
      const start = new Date();
      start.setHours(8, 0, 0);
      setSelectedDate(start);
    }
  }, [open]);

  const addToPhotos = (photos: any) => {
    const lphotos = [...photosimages];
    const li = photosimages.length;
    const oi = photosurls.length;
    const n = 10 - (oi + li);
    if (n < 1 || !photos || photos.length === 0) {
      return;
    } else {
      const newphotos = [...lphotos, ...photos];
      const fnewphotos = newphotos.slice(0, n);
      setPhotosimages(fnewphotos);
    }
  };
  const removePhoto = (src: any, index: any) => {
    if (src.startsWith('http')) {
      const newphotosurls = [...photosurls];
      const newlist = newphotosurls.filter((nu: any) => nu !== src);
      setPhotosurls(newlist);
    } else {
      const newList = [...photosimages];
      const newindex = index - photosurls.length;
      newList.splice(newindex, 1);
      setPhotosimages(newList);
    }
  };

  const addMultiProducts = (data: any) => {
    const newliastitems = data.map((da: any) => {
      const item = servicesproducts.find(
        (prod: any) => prod?.barcode?.toString() === da?.barcode?.toString()
      );
      if (item) {
        return {
          ...item,
          itemqty: da?.qty,
          itemprice: da?.price,
          itemtotal: da?.qty * da?.price,
          userId: user?._id,
        };
      } else return null;
    });
    const rdata = newliastitems.filter((x: any) => x);
    const listwithindex = indexTheList(rdata);

    setItemsList(listwithindex);
  };

  const getOverallTotal = () => {
    const products = itemsList.filter((li: any) => li.itemType === 1);
    const nonstock = itemsList.filter((li: any) => li.itemType !== 1);

    const sumProducts =
      products?.length > 0 ? _(products).sumBy('itemtotal') : 0;
    const sumNonstock =
      nonstock?.length > 0 ? _(nonstock).sumBy('itemtotal') : 0;
    const sum = _(itemsList).sumBy('itemtotal');

    const amount = sum - discount;
    const tots = {
      itemsSum: amount,
      total: sum,
      amount,
      costAmount: sumProducts,
    };
    setTotals(tots);

    const accs = [
      {
        debitAcc: accountCode.cost_of_sales,
        creditAcc: accountCode.accounts_payable,
        // amount: sum, // sum nunstockitem
        amount: sumNonstock, // non stock items
        type: operationTypes.purchaseInvoice,
      },
      {
        debitAcc: accountCode.accounts_payable,
        creditAcc: accountCode.cost_of_sales,
        amount: discount,
        type: operationTypes.supplierDiscount,
      },
      {
        debitAcc: accountCode.accounts_payable,
        creditAcc: payAcc ? payAcc.code : accountCode.cash_on_hand,
        amount: isCash ? paid : 0,
        type: operationTypes.supplierPayemnt,
      },
      {
        debitAcc: accountCode.inventory,
        creditAcc: accountCode.accounts_payable,
        amount: sumProducts, // stock items
        type: operationTypes.purchaseDelivery,
      },
    ];
    setAccounts(accs);
  };

  const onSubmit = async () => {
    if (!suppvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة مورد للفاتورة' : 'Please add Supplier'
      );
      return;
    }
    if (!itemsList || itemsList.length === 0) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يجب اضافة عنصر (خدمة او منتج) واحد للفاتورة على الأقل`
          : `You should add min one service to invoice`
      );
      return;
    }
    setSaving(true);
    const { amount, total, costAmount } = totals;

    const newList =
      !tempoptions?.itemrelations && !tempoptions?.purchaseitemrelations
        ? itemsList?.map((il: any) => {
            return {
              ...il,
              supplierId: suppvalue?._id,
              supplierName: suppvalue?.name,
              supplierNameAr: suppvalue?.nameAr,
              supplierPhone: suppvalue?.phone,
              contractId: taskvalue?._id,
              contractName: taskvalue?.name,
              contractNameAr: taskvalue?.nameAr,
              departmentId: departvalue?._id,
              departmentName: departvalue?.name,
              departmentNameAr: departvalue?.nameAr,
              departmentColor: departvalue?.color,
              employeeId: emplvalue?._id,
              employeeName: emplvalue?.name,
              employeeNameAr: emplvalue?.nameAr,
              employeeColor: emplvalue?.color,
              employeePhone: emplvalue?.phone,
              resourseId: resovalue?._id,
              resourseName: resovalue?.name,
              resourseNameAr: resovalue?.nameAr,
              resourseColor: resovalue?.color,
            };
          })
        : itemsList;
    const items = JSON.stringify(newList);

    let localphotos = [];

    if (photosimages) {
      const photosurl = await uploadMultiPhotoOnline(photosimages);
      if (photosurl && photosurl.length > 0) {
        const rphotos = photosurl.map((photo: any) =>
          photo.replace('http://', 'https://')
        );
        localphotos = rphotos;
      }
    }

    const fphotos = [...photosurls, ...localphotos];
    const photos = JSON.stringify(fphotos);

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      time: selectedDate,
      supplier: {
        supplierId: suppvalue._id,
        supplierName: suppvalue.name,
        supplierNameAr: suppvalue.nameAr,
        supplierPhone: suppvalue.phone,
      },
      department: departvalue
        ? {
            departmentId: departvalue._id,
            departmentName: departvalue.name,
            departmentNameAr: departvalue.nameAr,
            departmentColor: departvalue.color,
          }
        : {
            departmentId: undefined,
            departmentName: undefined,
            departmentNameAr: undefined,
            departmentColor: undefined,
          },
      employee: emplvalue
        ? {
            employeeId: emplvalue._id,
            employeeName: emplvalue.name,
            employeeNameAr: emplvalue.nameAr,
            employeeColor: emplvalue.color,
            employeePhone: emplvalue.phone,
          }
        : {
            employeeId: undefined,
            employeeName: undefined,
            employeeNameAr: undefined,
            employeeColor: undefined,
            employeePhone: undefined,
          },
      resourse: resovalue
        ? {
            resourseId: resovalue._id,
            resourseName: resovalue.name,
            resourseNameAr: resovalue.nameAr,
            resourseColor: resovalue.color,
          }
        : {
            resourseId: undefined,
            resourseName: undefined,
            resourseNameAr: undefined,
            resourseColor: undefined,
          },
      contract: taskvalue
        ? {
            contractId: taskvalue._id,
            contractName: taskvalue.name,
            contractNameAr: taskvalue.nameAr,
          }
        : {
            contractId: undefined,
            contractName: undefined,
            contractNameAr: undefined,
          },
      project: taskvalue
        ? {
            projectId: taskvalue.projectId,
            projectName: taskvalue.projectName,
            projectNameAr: taskvalue.projectNameAr,
          }
        : {
            projectId: undefined,
            projectName: undefined,
            projectNameAr: undefined,
          },
      items,
      attachments: fphotos && fphotos.length > 0 ? photos : null,
      costAmount,
      total,
      discount,
      amount,
      isPaid: isCash,
      isCash,
      amountPaid: isCash ? paid : 0,
      payAcc: isCash && payAcc ? payAcc.code : undefined,
      accounts,
      desc,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    setPhotosimages([]);
    setUrls([]);
    setPhotosurls([]);
    onClose();
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice #${row?.docNo}`,
    removeAfterPrint: true,
  });

  const printData = {
    invoiceNo: invNo,
    time: selectedDate,
    docNo: invNo,
    supplierName: suppvalue?.name,
    supplierPhone: suppvalue?.phone,
    supplierNameAr: suppvalue?.nameAr,
    total: totals.total,
    discount,
    amount: totals.amount,
    items: itemsList,
    user: user?.name,
    tafkeet: tafkeet(totals.amount, isRTL),
  };

  const template = getDefaultTemplate(printtemplates, 'purchase');
  const cashaccounts = mainAccounts.filter(
    (acc: any) => acc.parentcode === parents.CASH
  );
  const date = row?.startDate ? new Date(row?.startDate) : new Date();
  const day = weekdaysNNo?.[date.getDay()];

  const title = isRTL ? 'فاتورة شراء' : 'Purchase Invoice';

  const bl = totals?.amount - paid;
  const pa = `${paid} /  ${totals?.amount}`;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      saving={saving}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      // print={!isNew ? handleReactPrint : undefined}
      onlyclose={!isNew && !userroles?.purchase?.edit}
      maxWidth="lg"
      mt={0}
      mb={40}
    >
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box display={'flex'}>
            <CalenderLocal
              isRTL={isRTL}
              label={words?.time}
              value={selectedDate}
              onChange={handleDateChange}
            ></CalenderLocal>
            {!isNew && (
              <Box
                display="flex"
                style={{
                  flex: 1,
                  flexDirection: isRTL ? 'row-reverse' : 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginTop: 32,
                }}
              >
                <TextField
                  name="invNo"
                  disabled
                  value={invNo}
                  variant="outlined"
                  style={{
                    width: isNew ? 70 : 100,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                  margin="dense"
                  inputProps={{
                    style: {
                      textAlign: 'center',
                      fontSize: 14,
                      height: 13,
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <PrintDownload
            company={company}
            printData={printData}
            template={template}
            componentRef={componentRef}
            handleReactPrint={handleReactPrint}
            no={row?.docNo}
            element="invoice"
            isRTL={isRTL}
            isNew={isNew}
          ></PrintDownload>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AutoFieldLocal
                name="supplier"
                title={words.supplier}
                words={words}
                options={suppliers}
                value={suppvalue}
                setSelectValue={setSuppvalue}
                isRTL={isRTL}
                fullWidth
                openAdd={openSupplier}
                showphone
                disabled={name === 'supplierId'}
              ></AutoFieldLocal>
            </Grid>
            {tempoptions?.docrelations &&
              !tempoptions?.purchaseitemrelations && (
                <>
                  {tempoptions?.contract && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="task"
                        title={tempwords?.task}
                        words={words}
                        options={tasks}
                        value={taskvalue}
                        setSelectValue={setTaskvalue}
                        isRTL={isRTL}
                        fullWidth
                        disabled={name === 'contractId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.employee && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="employee"
                        title={tempwords?.employee}
                        words={words}
                        options={employees.filter((emp: any) => !emp.disabled)}
                        value={emplvalue}
                        disabled={isemployee || name === 'employeeId'}
                        setSelectValue={setEmplvalue}
                        setSelectError={setEmplError}
                        selectError={emplError}
                        refernce={emplRef}
                        openAdd={openEmployee}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.department && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="department"
                        title={tempwords?.department}
                        words={words}
                        options={departments.filter(
                          (d: any) => d.depType === 1
                        )}
                        value={departvalue}
                        setSelectValue={setDepartvalue}
                        setSelectError={setDepartError}
                        selectError={departError}
                        refernce={departRef}
                        openAdd={openDepartment}
                        isRTL={isRTL}
                        fullWidth
                        disabled={name === 'departmentId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}

                  {tempoptions?.resourse && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="resourse"
                        title={tempwords?.resourse}
                        words={words}
                        options={resourses}
                        value={resovalue}
                        disabled={name === 'resourseId'}
                        setSelectValue={setResovalue}
                        setSelectError={setResoError}
                        selectError={resoError}
                        refernce={resoRef}
                        openAdd={openResourse}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                </>
              )}
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ marginTop: 5 }}>
          {tempoptions?.docdescription && (
            <TextField
              name="desc"
              onChange={(e: any) => setDesc(e.target.value)}
              value={desc}
              label={words.notes}
              variant="outlined"
              margin="dense"
              fullWidth
              multiline
              rowsMax={5}
              rows={5}
              style={{ marginTop: 0 }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: '#f4f4f4',
              padding: 10,
              marginTop: 15,
              marginBottom: 15,
              borderRadius: 10,
            }}
          >
            <Box display="flex">
              <ServiceItemForm
                services={services}
                products={servicesproducts}
                addItem={addItemToList}
                words={words}
                classes={classes}
                user={user}
                isRTL={isRTL}
                setAlrt={setAlrt}
                itemtype={1}
                isPurchase={true}
                taskv={task}
                itemrelations={
                  tempoptions?.purchaseitemrelations ||
                  tempoptions?.itemrelations
                }
              ></ServiceItemForm>
            </Box>
            <ImportBtn
              open={() => setOpenImport(true)}
              isRTL={isRTL}
            ></ImportBtn>
            {(isNew || itemsList.length > 0) && (
              <Box style={{ marginBottom: 10 }}>
                <ItemsTable
                  rows={itemsList}
                  products={[...servicesproducts, ...services]}
                  editItem={editItemInList}
                  removeItem={removeItemFromList}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  itemrelations={
                    tempoptions?.purchaseitemrelations ||
                    tempoptions?.itemrelations
                  }
                ></ItemsTable>
              </Box>
            )}
            {loading && <LoadingInline></LoadingInline>}
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            name="discount"
            label={words.discount}
            value={discount.toString()}
            onChange={(e: any) => setDiscount(Number(e.target.value))}
            variant="outlined"
            style={{ width: 200 }}
            margin="dense"
            type="number"
            onFocus={(e) => e.target.select()}
          />
          <Box
            display="flex"
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <PriceTotal
              amount={totals?.amount}
              total={totals?.total}
              discount={discount}
              words={words}
            ></PriceTotal>
          </Box>
        </Grid>

        <Grid item xs={12} md={7}>
          <PaymentSelect
            words={words}
            isCash={isCash}
            setIsCash={setIsCash}
            paid={paid}
            setPaid={setPaid}
            isRTL={isRTL}
            payAcc={payAcc}
            setPayAcc={setPayAcc}
            payaccounts={cashaccounts}
            isNew={isNew}
            selectpay={true}
          ></PaymentSelect>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
                color: 'tomato',
              }}
            >
              {moneyFormat(bl)} {isRTL ? 'المتبقي' : 'Balance'}
            </Typography>
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
              }}
            >
              ( {pa} ) {isRTL ? 'مدفوع' : 'Paid'}
            </Typography>
          </Box>
          {tempoptions?.pinvoiceattach && (
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  style={{
                    height: 150,
                    width: 400,
                    margin: 20,
                  }}
                >
                  <ImageView
                    images={urls}
                    removePhoto={removePhoto}
                    width={400}
                    height={150}
                    small
                  ></ImageView>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    position: 'relative',
                    top: -40,
                    left: 140,
                    zIndex: 11,
                    flex: 1,
                    height: 40,
                    width: 200,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 15,
                  }}
                >
                  <UploadPhotos
                    setImages={addToPhotos}
                    isRTL={isRTL}
                  ></UploadPhotos>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>

        <PopupSupplier
          newtext={newtext}
          open={openSupp}
          onClose={onCloseSupplier}
          isNew={true}
          setNewValue={onNewSuppChange}
          row={null}
          addAction={addSupplier}
          editAction={editSupplier}
        ></PopupSupplier>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
        <PopupIlisttemsImport
          open={openImport}
          onClose={() => setOpenImport(false)}
          addMultiItems={addMultiProducts}
          isRTL={isRTL}
          words={words}
          filename="items"
        ></PopupIlisttemsImport>
      </Grid>
    </PopupLayout>
  );
};

export default PopupPurchaseInvoice;
