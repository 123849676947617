import { gql } from '@apollo/client';

export default gql`
  mutation createRetype(
    $branch: String
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $reType: Int
    $hidefromlist: Boolean
    $desc: String
    $color: String
  ) {
    createRetype(
      branch: $branch
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      reType: $reType
      hidefromlist: $hidefromlist
      desc: $desc
      color: $color
    ) {
      ok
      message
      data
      error
    }
  }
`;
