/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box, Divider, Grid } from '@material-ui/core';
import GenThemePopup from './GenThemePopup';
import CalendarOptionsPopup from './CalendarOptionsPopup';
import GeneralOptionsPopup from './GeneralOptionsPopup';

const initcalendar = {
  duration: 30,
  start: 8.5,
  end: 21.5,
};

const PopupOptions = ({ isRTL, words, store, dispatch, addUserTheme }: any) => {
  const calendar = store?.calendar;
  const fullscreen = store?.fullscreen;
  const scrollbar = store?.scrollbar;
  const themeId = store?.themeId;

  const setThemeId = (id: any) => {
    dispatch({ type: 'setThemeId', payload: id });
    addUserTheme({ variables: { themeId: id } });
  };

  const setCalendar = (data: any) => {
    dispatch({ type: 'setCalendar', payload: data });
  };
  const setFullscreen = (data: any) => {
    dispatch({ type: 'setFullscreen', payload: data });
  };
  const setScrollbar = (data: any) => {
    dispatch({ type: 'setScrollbar', payload: data });
  };

  if (!calendar) {
    setCalendar(initcalendar);
  }
  return (
    <Box
      style={{
        margin: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <GenThemePopup
                themeId={themeId}
                setThemeId={setThemeId}
              ></GenThemePopup>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <GeneralOptionsPopup
                isRTL={isRTL}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                scrollbar={scrollbar}
                setScrollbar={setScrollbar}
              ></GeneralOptionsPopup>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <CalendarOptionsPopup
                calendar={calendar}
                words={words}
                setCalendar={setCalendar}
                isRTL={isRTL}
              ></CalendarOptionsPopup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PopupOptions;
