import { gql } from '@apollo/client';

export default gql`
  mutation createEmployee(
    $branch: String
    $disabled: Boolean
    $addlink: Boolean
    $isCalendar: Boolean
    $additionId: String
    $vacationInfo: String
    $vacationStart: Date
    $vacationEnd: Date
    $isAdminstaff: Boolean
    $isWorkstaff: Boolean
    $isTeam: Boolean
    $teamMembers: [String]
    $worktimes: String
    $available: Int
    $hourCost: Float
    $dayCost: Float
    $xhourCost: Float
    $xdayCost: Float
    $target: Float
    $startWorkDate: Date
    $basicSalary: Float
    $extraSalary: Float
    $fullSalary: Float
    $emailnotify: Boolean
    $name: String
    $nameAr: String
    $color: String
    $resKind: Int
    $resType: Int
    $department: DepartmentInput
    $retype: RetypeInput
    $comPercent: Float
    $phone: String
    $email: String
    $daysoff: String
    $speciality: String
    $pId: String
    $pIdDate: String
    $workId: String
    $telHome: String
    $licenseNo: String
    $licenseDate: String
    $national: String
    $nationalNo: String
    $nationalDate: String
    $photos: String
    $avatar: String
    $info: String
    $documentNo: String
    $startDate: Date
    $endDate: Date
    $cost: Float
    $model: String
    $type: String
    $birthday: Date
  ) {
    createEmployee(
      branch: $branch
      disabled: $disabled
      addlink: $addlink
      isCalendar: $isCalendar
      additionId: $additionId
      vacationInfo: $vacationInfo
      vacationStart: $vacationStart
      vacationEnd: $vacationEnd
      isAdminstaff: $isAdminstaff
      isWorkstaff: $isWorkstaff
      isTeam: $isTeam
      teamMembers: $teamMembers
      worktimes: $worktimes
      available: $available
      hourCost: $hourCost
      dayCost: $dayCost
      xhourCost: $xhourCost
      xdayCost: $xdayCost
      target: $target
      startWorkDate: $startWorkDate
      basicSalary: $basicSalary
      extraSalary: $extraSalary
      fullSalary: $fullSalary
      emailnotify: $emailnotify
      name: $name
      nameAr: $nameAr
      color: $color
      resKind: $resKind
      resType: $resType
      department: $department
      retype: $retype
      comPercent: $comPercent
      phone: $phone
      email: $email
      daysoff: $daysoff
      speciality: $speciality
      pId: $pId
      pIdDate: $pIdDate
      workId: $workId
      telHome: $telHome
      licenseNo: $licenseNo
      licenseDate: $licenseDate
      national: $national
      nationalNo: $nationalNo
      nationalDate: $nationalDate
      avatar: $avatar
      photos: $photos
      info: $info
      documentNo: $documentNo
      startDate: $startDate
      endDate: $endDate
      cost: $cost
      model: $model
      type: $type
      birthday: $birthday
    ) {
      ok
      message
      data
      error
    }
  }
`;
