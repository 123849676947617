import { gql } from '@apollo/client';

export default gql`
  mutation deleteProcItem($_id: String) {
    deleteProcItem(_id: $_id) {
      ok
      message
      error
    }
  }
`;
