/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { bgimage } from '../constants';
import AlertLocal from '../components/AlertLocal';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import TabsList from '../components/TabsList';
import Tasks from './Tasks';
import useCompany from '../../hooks/useCompany';
import { getStoreItem, setStoreItem } from '../../store';
import {
  ContractContext,
  contractReducer,
  CustomerContext,
  customerReducer,
  initContractContext,
  initCustomerContext,
} from '../../contexts/managment';
import {
  EventsContext,
  eventsReducer,
  initEventsContext,
} from '../../contexts';
import Appointments from './Appointments';
import Customers from '../shared/Customers';

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export default function OperationOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const taskref: any = useRef();
  const eventref: any = useRef();
  const custref: any = useRef();

  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { height, width } = useWindowDimensions();
  const { company } = useCompany();
  const contractStoreState = getStoreItem('contractStore', initContractContext);
  const [contractStore, contractDispatch] = useReducer(
    contractReducer,
    contractStoreState ? contractStoreState : initContractContext
  );
  useEffect(() => {
    setStoreItem('contractStore', contractStore);
  }, [contractStore]);

  const eventsStoreState = getStoreItem('eventsStore', initEventsContext);
  const [eventsStore, eventsDispatch] = useReducer(
    eventsReducer,
    eventsStoreState ? eventsStoreState : initEventsContext
  );
  useEffect(() => {
    setStoreItem('eventsStore', eventsStore);
  }, [eventsStore]);

  const customerStoreState = getStoreItem('customerStore', initCustomerContext);
  const [customerStore, customerDispatch] = useReducer(
    customerReducer,
    customerStoreState ? customerStoreState : initCustomerContext
  );
  useEffect(() => {
    setStoreItem('customerStore', customerStore);
  }, [customerStore]);

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };

  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const refresh = () => {
    if (pathname?.includes('tasks')) {
      taskref?.current?.refresh();
    }
    if (pathname?.includes('events')) {
      eventref?.current?.refresh();
    }
    if (pathname?.includes('customers')) {
      custref?.current?.refresh();
    }
  };

  const bgcolor = '#4086F408';
  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
            marginTop: 5,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Paper
          elevation={5}
          style={{
            borderRadius: 25,
            margin: 25,
            marginTop: 5,
            width: width - drawerWidth - 50,
            height: height - 135,
          }}
        >
          <TabsList
            handleSelect={handleSelect}
            isRTL={isRTL}
            list={formgroup}
            theme={theme}
            width={width - drawerWidth - 50}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            pathname={pathname}
          ></TabsList>
          <Paper
            style={{
              display: 'flex',
              height: height - 185,
              overflow: 'auto',
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            <Routes>
              <Route
                index
                element={<Navigate to={formgroup?.[0]?.path} replace />}
              />

              <Route
                path={'tasks'}
                element={
                  <ContractContext.Provider
                    value={{ state: contractStore, dispatch: contractDispatch }}
                  >
                    <Tasks
                      ref={taskref}
                      isRTL={isRTL}
                      words={words}
                      start={start}
                      end={end}
                      setAlrt={setAlrt}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></Tasks>
                  </ContractContext.Provider>
                }
              />
              <Route
                path={'events'}
                element={
                  <EventsContext.Provider
                    value={{ state: eventsStore, dispatch: eventsDispatch }}
                  >
                    <Appointments
                      ref={eventref}
                      isRTL={isRTL}
                      start={start}
                      end={end}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></Appointments>
                  </EventsContext.Provider>
                }
              />
              <Route
                path={'customers'}
                element={
                  <CustomerContext.Provider
                    value={{ state: customerStore, dispatch: customerDispatch }}
                  >
                    <Customers
                      ref={custref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></Customers>
                  </CustomerContext.Provider>
                }
              />
            </Routes>
          </Paper>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </Box>
    </PageLayout>
  );
}
