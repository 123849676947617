import { gql } from '@apollo/client';
export default gql`
  query getDocUploads($start: Date, $end: Date) {
    getDocUploads(start: $start, end: $end) {
      ok
      error
      data {
        _id
        branch
        opType
        opId
        opNo
        time
        title
        desc
        stepId
        stepTime
        stepName
        stepNameAr
        stepDesc
        stepUser
        stepUserName
        stepColor
        steps

        contractId
        contractName
        contractNameAr

        customerId
        customerName
        customerNameAr

        supplierId
        supplierName
        supplierNameAr

        departmentId
        departmentName
        departmentNameAr

        employeeId
        employeeName
        employeeNameAr
        employeeColor

        resourseId
        resourseName
        resourseNameAr
        resourseColor

        amount

        attachments

        userId
        note

        createdAt
        updatedAt
      }
    }
  }
`;
