import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { userroles } from '../../../constants';
import IconView from '../IconView';

function ProgressReportIcon({ isRTL, theme, color, height }) {
  const navigate = useNavigate();

  return (
    <Box>
      {userroles?.appointments?.admin && (
        <IconView
          color={color ? color : theme.palette.primary.main}
          icon="report"
          title={isRTL ? `تقرير الانجاز` : `Performance Report`}
          height={height}
          onOpen={() => navigate('/eventsitemsreports')}
        ></IconView>
      )}
    </Box>
  );
}

export default ProgressReportIcon;
