import { gql } from '@apollo/client';
export default gql`
  query getJadwalReceipts($start: Date, $end: Date) {
    getJadwalReceipts(start: $start, end: $end) {
      ok
      error
      count
      data {
        _id
        autoNo
        time
        title
        desc
        invoiceId
        customerId
        customerName
        customerNameAr
        customerPhone
        amount
        paymentType
        userId
        note
        createdAt
        updatedAt
      }
    }
  }
`;
