/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Divider, fade, Grid, Typography } from '@material-ui/core';
import { InfoAmount, InfoIcon, InfoTitle } from './common';

export default function InfoBox({
  title,
  value,
  icon,
  color,
  desc,
  dark,
  height = 116,
}: any) {
  const clr = dark ? '#fafafa' : color;
  const bgclr = dark ? fade(color, 0.7) : '#fafafa';

  return (
    <Paper style={{ height, background: bgclr }}>
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Box
              display="flex"
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {icon === 'cash' ? (
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: clr,
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  QR
                </Typography>
              ) : (
                <InfoIcon color={clr} icon={icon}></InfoIcon>
              )}
              <InfoTitle color={clr} title={title}></InfoTitle>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <InfoAmount color={clr} value={value}></InfoAmount>
            </Box>
            {desc && (
              <React.Fragment>
                <Divider></Divider>
                <Box display="flex" style={{ marginLeft: 10, marginRight: 10 }}>
                  <Typography
                    style={{ alignSelf: 'center', color: clr }}
                    variant="caption"
                  >
                    {desc}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
