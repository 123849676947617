import { operationTypes } from './datatypes';
import { getStoreItem } from '../../store';
import _ from 'lodash';

const store = getStoreItem('store');
const template = store?.template;
const lang = store?.lang;
const words = template?.words?.[lang];
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const groupList = (isRTL: any, product = false) => [
  {
    id: 1,
    value: 'none',
    name: isRTL ? 'بدون تجميع' : 'No Grouping',
  },
  {
    id: 2,
    value: 'employee',
    name: isRTL ? `تجميع بحسب ${words.employee}` : `Group By ${words.employee}`,
  },
  {
    id: 3,
    value: 'department',
    name: isRTL
      ? `تجميع بحسب ${words.department}`
      : `Group By ${words.department}`,
  },
  {
    id: 4,
    value: 'service',
    name: isRTL ? 'تجميع بحسب البند' : 'Group By Item',
  },
  {
    id: 5,
    value: 'customer',
    name: isRTL ? `تجميع بحسب ${words.customer}` : `Group By ${words.customer}`,
  },
  {
    id: 5.5,
    value: 'supplier',
    name: isRTL ? `تجميع بحسب المورد` : `Group By Supplier`,
  },
  {
    id: 6,
    value: 'category',
    name: isRTL ? 'تجميع بحسب التصنيف' : 'Group By Category',
  },
  {
    id: 7,
    value: 'status',
    name: isRTL ? 'تجميع بحسب الحالة' : 'Group By status',
  },
  {
    id: 8,
    value: 'contract',
    name: isRTL ? `تجميع بحسب ${words.task}` : `Group By  ${words.task}`,
  },
  {
    id: 9,
    value: 'opType',
    name: isRTL ? 'تجميع بحسب الوثيقة' : 'Group By Document',
  },
  {
    id: 10,
    value: 'project',
    name: isRTL ? `تجميع بحسب ${words.project}` : `Group By ${words.project}`,
  },
  {
    id: 11,
    value: 'resourse',
    name: isRTL ? `تجميع بحسب ${words.resourse}` : `Group By ${words.resourse}`,
  },
  {
    id: 12,
    value: 'retype',
    name: isRTL ? `تجميع بحسب ${words.evretype}` : `Group By ${words.retype}`,
  },
  {
    id: 13,
    value: 'retype2',
    name: isRTL ? `تجميع بحسب ${words.evretype2}` : `Group By ${words.retype2}`,
  },
];

export const documentTypes = [
  {
    id: 1,
    value: 'none',
    name: 'All',
    nameAr: 'الكل',
  },
  {
    id: 2,
    value: operationTypes.event,
    name: 'Appointment',
    nameAr: 'المواعيد',
  },
  {
    id: 3,
    value: operationTypes.salesInvoice,
    name: 'Sales Invoices',
    nameAr: 'فواتير البيع',
  },
  {
    id: 4,
    value: operationTypes.customerReceipt,
    name: 'Receipts',
    nameAr: 'سندات القبض',
  },
  {
    id: 5,
    value: operationTypes.salesDelivery,
    name: 'Sales Delivery',
    nameAr: 'تسليم بضاعة',
  },
  {
    id: 6,
    value: operationTypes.customerDiscount,
    name: 'customerDiscount',
    nameAr: 'خصم مبيعات',
  },
  {
    id: 7,
    value: operationTypes.purchaseInvoice,
    name: 'Purchase Invoices',
    nameAr: 'فواتير الشراء',
  },
  {
    id: 8,
    value: operationTypes.supplierPayemnt,
    name: 'Payemnts',
    nameAr: 'سندات الدفع',
  },
  {
    id: 9,
    value: operationTypes.purchaseDelivery,
    name: 'Purchase Delivery',
    nameAr: 'استلام بضاعة',
  },
  {
    id: 10,
    value: operationTypes.supplierDiscount,
    name: 'supplierDiscount',
    nameAr: 'خصم مشتريات',
  },
  {
    id: 11,
    value: operationTypes.expenses,
    name: 'Expenses',
    nameAr: 'المصروفات',
  },
  {
    id: 12,
    value: operationTypes.expproducts,
    name: 'Products Expenses',
    nameAr: 'استهلاك منتجات',
  },
  {
    id: 13,
    value: operationTypes.exppettycash,
    name: 'Petty Cash Expenses',
    nameAr: 'مصروف عهدة',
  },
  {
    id: 14,
    value: operationTypes.exppayable,
    name: 'Payable Expenses',
    nameAr: 'مصروف موردين',
  },
  {
    id: 15,
    value: operationTypes.cashDeposet,
    name: 'Cash Deposet',
    nameAr: 'ايداع في البنك',
  },
  {
    id: 16,
    value: operationTypes.cashDraw,
    name: 'Cash Draw',
    nameAr: 'سحب من البنك',
  },
  {
    id: 17,
    value: operationTypes.pettyCashPay,
    name: 'Petty Cash Payment',
    nameAr: 'دفع عهدة',
  },
  {
    id: 18,
    value: operationTypes.pettyCashRec,
    name: 'Petty Cash Receipt',
    nameAr: 'قبض عهدة',
  },
  {
    id: 19,
    value: operationTypes.kaid,
    name: 'Jornal Vaucher',
    nameAr: 'قيود اليومية',
  },
  {
    id: 20,
    value: operationTypes.advanceReceipt,
    name: 'Advance Receipt',
    nameAr: 'سند قبض سلفة',
  },
  {
    id: 21,
    value: operationTypes.advancePayemnt,
    name: 'Advance Payemnt',
    nameAr: 'سند دفع سلفة',
  },
];
export const fieldsNames = [
  'time', //Date
  'title', //String
  'desc', //String
  'showOnCal', //Boolean
  'id', //Int
  'startDate', //Date
  'endDate', //Date
  'priority', //Int
  'status', //Int
  'locationLink', //String
  'address', //String
  'houseno', //String
  'periodfrom', //Date
  'periodto', //Date
  'item', //Item
  'customer', //Customer
  'supplier', //supplier
  'contact', //contact
  'department', //department
  'employee', //employee
  'project', //project
  'contract', //contract
  'resourse', //resourse
  'retype', //retype
  'retype2', //retype2
  'bonos', //Float
  'overtime', //Float
  'absent', //Boolean
  'discount', //Float
  'amount', //Float
  'chequeBank', //String
  'chequeNo', //String
  'chequeDate', //String
  'note', //String
  'signatureName', //String
  'signatureImage', //String
  'items',
];

export const sectionTypes = [
  {
    id: 1,
    value: 1,
    name: 'Managment',
    nameAr: 'اداري',
  },
  {
    id: 2,
    value: 2,
    name: 'Operational',
    nameAr: 'فني',
  },
];

export const priorityOptions = [
  {
    id: 1,
    nameAr: 'منخفض الاهمية',
    name: 'Low Priority',
    color: '#1e90ff',
    value: 1,
  },
  {
    id: 2,
    nameAr: 'متوسط الاهمية',
    name: 'Medium Priority',
    color: '#008000',
    value: 2,
  },
  {
    id: 3,
    nameAr: 'عالي الاهمية',
    name: 'High Priority',
    color: '#ff9747',
    value: 3,
  },
];

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getSummaryViee = (current: any) => {
  let reptype;
  if (current === 'item') {
    reptype = { item: true };
  } else if (current === 'category') {
    reptype = { category: true };
  } else if (current === 'department') {
    reptype = { department: true };
  } else if (current === 'employee') {
    reptype = { employee: true };
  } else if (current === 'customer') {
    reptype = { customer: true };
  }
  return reptype;
};

export const getReportData = ({ list, refdata }: any) => {
  const data = refdata
    ? list.map((cs: any) => {
        const emp = refdata.filter(
          (de: any) => de._id === cs._id.employee
        )?.[0];
        const employeeName = emp?.name;
        const employeeNameAr = emp?.nameAr;
        const employeeColor = emp?.color;
        return {
          ...cs,
          amount: cs.credit - cs.debit,
          employeeName: employeeName ? employeeName : 'Not available',
          employeeNameAr: employeeNameAr ? employeeNameAr : 'غير متوفر',
          employeeColor,
        };
      })
    : null;
  return data;
};

export const groupSumCount = ({ list, name }: any) => {
  const items = _(list)
    .groupBy(name)
    .map((array, key) => ({
      name: key,
      count: _.uniqBy(array, '_id').length,
      total: _.sumBy(array, 'amount'),
    }))
    .value();
  const count = list.length;
  const total = _.sumBy(list, 'amount');
  return {
    items,
    count,
    total,
  };
};

export const getDateMonthFormat = (time: any, isRTL: any) => {
  if (time) {
    const date = new Date(time);
    return date.toLocaleString(isRTL ? 'ar-QA' : 'en-GB', {
      month: 'long',
      year: 'numeric',
    });
  } else {
    return '';
  }
};

export const formatKeyToMonth = (k: any, isRTL: any) => {
  if (k) {
    const a = k.split('-');
    const date = new Date(Number(a[1]), Number(a[0] - 1), 1);
    return getDateMonthFormat(date, isRTL);
  }
  return null;
};
