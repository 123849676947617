import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

export default function InvoiceHeader({ height }) {
  return (
    <Box
      style={{
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 20,
        marginRight: 20,
      }}
      borderBottom={1}
      borderColor="#ddd"
    >
      <Button style={{ padding: 10, borderRadius: 15 }}>
        <Typography>Clear All</Typography>
      </Button>
      <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>
        Order No: 1225
      </Typography>
    </Box>
  );
}
