import { Box } from '@material-ui/core';
import { useTemplate } from '../../../../hooks';
import useResourses from '../../../../hooks/useResourses';
import useRetypes from '../../../../hooks/useRetypes';
import { getColor } from '../../common/helpers';
import useCompany from '../../../../hooks/useCompany';
import { userroles } from '../../../constants';
import ResoursesStatus from '../ResoursesStatus';

function ResoursesBox({
  isRTL,
  theme,
  dark,
  words,
  color,
  systemcolor,
  height,
}) {
  const { tempwords, templateId } = useTemplate();
  const { resourses } = useResourses();
  const { retypes } = useRetypes();
  const { company } = useCompany();
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {userroles?.manageresourses?.edit && resourses && (
        <ResoursesStatus
          title={
            isRTL
              ? `انشغال ${tempwords?.resourses}`
              : `${tempwords?.resourses} Availability`
          }
          data={resourses}
          retypes={retypes}
          height={height}
          isRTL={isRTL}
          color={clr}
          templateId={templateId}
          photos={company?.photos}
        ></ResoursesStatus>
      )}
    </Box>
  );
}

export default ResoursesBox;
