import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generatePDF = ({ no, template, element }) => {
  const imgWidth =
    template?.size === 'POS' ? 80 : template?.size === 'A5' ? 148 : 210;
  const pageHeight = 297;

  const input = document.getElementById(element);
  html2canvas(input, {
    logging: true,
    useCORS: true,
  }).then((canvas) => {
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft -= pageHeight;
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save(`invoice-${no}.pdf`);
    // const imgData = canvas.toDataURL('image/jpeg', 0.8);
    // const pdf = new jsPDF('p', 'mm');
    // pdf.addImage(imgData, 'jpg', 0, 0, imgWidth, imgHeight);
    // pdf.save(`invoice-${row?.docNo}.pdf`);
  });
};
