import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useState } from 'react';
import { userroles } from '../../../constants';
import { createFinance, getLandingChartData } from '../../../../graphql';
import PopupFinance from '../../../../pubups/PopupFinance';
import IconView from '../IconView';

function ReceiptIcon({ isRTL, theme, color, height }) {
  const [openFins, setOpenFins] = useState(false);

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };

  const [addFinance] = useMutation(createFinance, refresQuery);

  return (
    <Box>
      {userroles?.cash?.add && (
        <>
          <IconView
            color={color ? color : theme.palette.primary.main}
            icon="account"
            title={isRTL ? 'انشاء حركة مالية' : 'Add Finance'}
            height={height}
            onOpen={() => setOpenFins(true)}
          ></IconView>

          <PopupFinance
            open={openFins}
            onClose={() => setOpenFins(false)}
            isNew={true}
            addAction={addFinance}
            editAction={() => null}
            theme={theme}
          ></PopupFinance>
        </>
      )}
    </Box>
  );
}

export default ReceiptIcon;
