import { Box } from '@material-ui/core';
import { useDepartments, useEmployees, useServices } from '../../../../hooks';
import useResourses from '../../../../hooks/useResourses';
import useTasks from '../../../../hooks/useTasks';
import AppointmentsOutBox from '../../../../Shared/AppointmentsOutBox';

function AppointmentsBox({ isRTL, theme, words, height, company }) {
  const { resourses } = useResourses();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { services } = useServices();
  const { tasks } = useTasks();

  return (
    <Box>
      <AppointmentsOutBox
        isRTL={isRTL}
        words={words}
        height={height}
        theme={theme}
        resourses={resourses}
        employees={employees}
        departments={departments}
        company={company}
        services={services}
        tasks={tasks}
      ></AppointmentsOutBox>
    </Box>
  );
}

export default AppointmentsBox;
