import { gql } from '@apollo/client';

export default gql`
  mutation createInvoicesFromEvents($eventIds: [String], $group: Boolean) {
    createInvoicesFromEvents(eventIds: $eventIds, group: $group) {
      ok
      message
      data
      error
    }
  }
`;
