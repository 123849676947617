import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export default function SelectKeys({
  name,
  options,
  value,
  setValue,
  disabled,
  color,
}: any) {
  return (
    <Box>
      <Autocomplete
        value={`${value}`}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue);
        }}
        id="controllable-states-demo"
        options={options}
        renderOption={
          color
            ? (option) => (
                <Box
                  style={{ backgroundColor: option, height: 25 }}
                  display="flex"
                  flex={1}
                ></Box>
              )
            : undefined
        }
        disabled={disabled}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={name}
            variant="outlined"
            style={{ backgroundColor: color ? value : undefined }}
          />
        )}
      />
    </Box>
  );
}
