import { getStoreItem } from '../../../store';

const store = getStoreItem('store');
const template = store?.template;
const lang = store?.lang;
const tempwords = template?.words?.[lang];
const options = template?.options;

const languages = getStoreItem('languages');
const isRTL = lang === 'ar';
const words = isRTL ? languages?.ar?.words : languages?.en?.words;

// TODO: to be removed
export const roles = [
  {
    branchName: { type: String },
    isBranchAdmin: { type: Boolean },
    systems: [
      {
        systemName: { type: String },
        isSystemAdmin: { type: Boolean },
        levels: {
          view: { type: Boolean },
          add: { type: Boolean },
          edit: { type: Boolean },
          delete: { type: Boolean },
          report: { type: Boolean },
        },
      },
    ],
  },
];
// TODO: to be removed
export const rolesExample = {
  branchs: [
    {
      name: 'branch1',
      isAdmin: true,
      systems: [
        {
          name: 'system1',
          isAdmin: true,
          levels: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            report: true,
          },
        },
      ],
    },
  ],
};
// TODO: to be checked

export const newrolestemplate = [
  {
    name: 'home',
    top: true,
    ref: 'opr',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    view: false,
  },
  {
    name: 'calendar',
    top: true,
    ref: 'opr',
    titleAr: 'التقويم',
    titleEn: 'Calendar',
    hide: !options?.calendar,
    view: false,
  },
  {
    name: 'tasks',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.tasks,
    titleEn: tempwords?.tasks,
    hide: !options?.contract,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'manageprojects',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.projects,
    titleEn: tempwords?.projects,
    hide: !options?.project,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'appointments',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.appointments,
    titleEn: tempwords?.appointments,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managment',
    top: true,
    ref: 'opr',
    titleAr: 'الإدارة',
    titleEn: 'Management',
    view: false,
  },
  {
    name: 'manageresourses',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.resourses}`,
    titleEn: `${tempwords?.resourses} Mangement`,
    hide: !options?.resourse,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'manageemployees',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.employees}`,
    titleEn: `${tempwords?.employees} Mangement`,
    hide: !options?.employee,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managedepartments',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.departments}`,
    titleEn: `${tempwords?.departments} Mangment`,
    hide: !options?.department,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    top: true,
    name: 'formdocs',
    ref: 'opr',
    titleAr: `ادارة ${words?.formdocs}`,
    titleEn: `${words?.formdocs} Mangment`,
    hide: !options?.formdocs,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'letters',
    ref: 'opr',
    titleAr: `ادارة ${words?.letters}`,
    titleEn: `${words?.letters} Mangment`,
    hide: !options?.letters,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'presales',
    ref: 'opr',
    titleAr: `ادارة ${words?.presales}`,
    titleEn: `${words?.presales} Mangment`,
    hide: !options?.presales,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'sales',
    ref: 'opr',
    titleAr: `ادارة ${words?.sales}`,
    titleEn: `${words?.sales} Mangment`,
    hide: !options?.sales,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'operations',
    ref: 'opr',
    titleAr: `ادارة ${words?.operations}`,
    titleEn: `${words?.operations} Mangment`,
    hide: !options?.operations,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'purchase',
    ref: 'opr',
    titleAr: `ادارة ${words?.purchase}`,
    titleEn: `${words?.purchase} Mangment`,
    hide: !options?.purchase,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'expenses',
    ref: 'opr',
    titleAr: `ادارة ${words?.expenses}`,
    titleEn: `${words?.expenses} Mangment`,
    hide: !options?.expenses,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'finance',
    ref: 'opr',
    titleAr: `ادارة ${words?.finance}`,
    titleEn: `${words?.finance} Mangment`,
    hide: !options?.finance,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'mail',
    ref: 'opr',
    titleAr: `ادارة المرفقات`,
    titleEn: `Attachments Mangment`,
    hide: !options?.mail,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة',
    titleEn: 'Under Approval',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة',
    titleEn: 'Under Review',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadinprogress',
    ref: 'opr',
    titleAr: 'قيد التسجيل',
    titleEn: 'In Progress',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadarchive',
    ref: 'opr',
    titleAr: `الأرشيف`,
    titleEn: `Archive`,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'entryunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة - ادارة',
    titleEn: 'Under Approval - Admin',
    hide: true,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'entryunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة - ادارة',
    titleEn: 'Under Review - Admin',
    hide: true,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'entryarchive',
    ref: 'opr',
    titleAr: `الأرشيف - ادارة`,
    titleEn: `Archive - Admin`,
    hide: true,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'pos',
    ref: 'opr',
    titleAr: `ادارة ${words?.pos}`,
    titleEn: `${words?.pos} Mangment`,
    hide: !options?.pos,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'reports',
    ref: 'opr',
    titleAr: `ادارة ${words?.reports}`,
    titleEn: `${words?.reports} Mangment`,
    hide: !options?.reports,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'reminder',
    ref: 'opr',
    titleAr: `ادارة ${words?.reminder}`,
    titleEn: `${words?.reminder} Mangment`,
    hide: !options?.reminder,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'calendarempl',
    ref: 'opr',
    titleAr: 'جدول الموظفين',
    titleEn: 'Employees Calendar',
    hide: !options?.emplcalendar,
    view: false,
  },
  {
    name: 'eventsitemsreports',
    ref: 'opr',
    titleAr: `تقرير الانجاز`,
    titleEn: `Performance Report`,
    view: false,
  },
  {
    name: 'calreports',
    ref: 'opr',
    titleAr: `تقرير ${tempwords?.appointments}`,
    titleEn: `${tempwords?.appointments} Report`,
    view: false,
  },
  {
    name: 'contractseports',
    ref: 'opr',
    titleAr: `تقرير ${tempwords?.tasks}`,
    titleEn: `${tempwords?.tasks} Report`,
    hide: !options?.contract,
    view: false,
  },
  {
    name: 'invoices',
    ref: 'sal',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'receipts',
    ref: 'sal',
    titleAr: 'المقبوضات',
    titleEn: 'Receipts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'customers',
    ref: 'sal',
    titleAr: tempwords?.customers,
    titleEn: tempwords?.customers,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'customerreport',
    ref: 'sal',
    titleAr: 'تقرير العملاء',
    titleEn: 'Customer Report',
    view: false,
  },
  {
    name: 'salesreport',
    ref: 'sal',
    titleAr: 'تقرير الايرادات',
    titleEn: 'Sales Report',
    view: false,
  },
  {
    name: 'purchasestock',
    top: true,
    ref: 'pur',
    titleAr: 'المشتريات',
    titleEn: 'Purchase',
    hide: !options?.purchase,
    view: false,
  },
  {
    name: 'purchase',
    ref: 'pur',
    titleAr: 'فواتير الشراء',
    titleEn: 'Purchase',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'payments',
    ref: 'pur',
    titleAr: 'المدفوعات',
    titleEn: 'Payments',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'supliers',
    ref: 'pur',
    titleAr: tempwords?.suppliers,
    titleEn: tempwords?.suppliers,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'supplierreport',
    ref: 'pur',
    titleAr: 'تقرير الموردين',
    titleEn: 'Supplier Report',
    view: false,
  },
  {
    name: 'purchasereport',
    ref: 'pur',
    titleAr: 'تقرير المشتريات',
    titleEn: 'Purchase Report',
    view: false,
  },
  {
    name: 'stockreport',
    ref: 'pur',
    titleAr: 'تقرير المخازن',
    titleEn: 'Stock Report',
    view: false,
  },
  {
    name: 'stockitems',
    ref: 'pur',
    titleAr: 'المنتجات المتوفرة',
    titleEn: 'Stock Items',
    view: false,
  },
  {
    name: 'expensesandpro',
    top: false,
    ref: 'exp',
    titleAr: 'المصروفات',
    titleEn: 'Expenses',
    view: false,
  },
  {
    name: 'expenses',
    ref: 'exp',
    titleAr: 'المصروفات النقدية',
    titleEn: 'Expenses',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'expproducts',
    ref: 'exp',
    titleAr: 'استهلاك المنتجات',
    titleEn: 'Products Expenses',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'expensesreport',
    ref: 'exp',
    titleAr: 'تقرير المصروفات',
    titleEn: 'Expenses Report',
    view: false,
  },
  {
    name: 'expproductreport',
    ref: 'exp',
    titleAr: 'تقرير المنتجات',
    titleEn: 'Products Report',
    view: false,
  },
  {
    name: 'financeexpenses',
    top: true,
    ref: 'fin',
    titleAr: 'الحسابات',
    titleEn: 'Accountant',
    view: false,
  },
  {
    name: 'receiptsadvance',
    ref: 'fin',
    titleAr: 'مقبوضات سلفة',
    titleEn: 'Advanced Receipts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'paymentsadvance',
    ref: 'fin',
    titleAr: 'مدفوعات سلفة',
    titleEn: 'Advance Payments',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'cash',
    ref: 'fin',
    titleAr: 'حركة مالية',
    titleEn: 'Finance',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'financeall',
    ref: 'fin',
    titleAr: 'قيد اليومية',
    titleEn: 'General Journal',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'financereport',
    ref: 'fin',
    titleAr: 'تقرير الحسابات',
    titleEn: 'Finance Report',
    view: false,
  },
  {
    name: 'profitability',
    ref: 'fin',
    titleAr: 'تقرير الربحية',
    titleEn: 'Profitability Report',
    view: false,
  },
  {
    name: 'kaidreport',
    ref: 'fin',
    titleAr: 'تقرير شامل',
    titleEn: 'General Report',
    view: false,
  },
  {
    name: 'budgetreport',
    ref: 'fin',
    titleAr: 'الحسابات الختامية',
    titleEn: 'Closing Accounts',
    view: false,
  },
  {
    name: 'accounts',
    ref: 'fin',
    titleAr: 'قائمة الحسابات',
    titleEn: 'Accounts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'items',
    top: true,
    ref: 'opr',
    titleAr: 'البنود',
    titleEn: 'Items',
    view: false,
  },
  {
    name: 'categories',
    ref: 'opr',
    titleAr: 'التصنيفات',
    titleEn: 'Categories',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'brands',
    ref: 'opr',
    titleAr: 'البراندات',
    titleEn: 'Brands',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'services',
    ref: 'opr',
    titleAr: 'بنود الخدمات',
    titleEn: 'Services Items',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'products',
    ref: 'opr',
    titleAr: 'بنود المنتجات',
    titleEn: 'Products Items',
    hide: !options?.purchase,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'expenseitems',
    ref: 'opr',
    titleAr: 'بنود المصروفات',
    titleEn: 'Expense Items',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'rentitem',
    ref: 'opr',
    titleAr: 'بنود التأجير',
    titleEn: 'Rent Items',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'collectiveitem',
    ref: 'opr',
    titleAr: 'بنود التجميع',
    titleEn: 'Collective Items',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'resoursetypes',
    ref: 'opr',
    titleAr: 'ادارة الانواع',
    titleEn: 'Types Management',
    hide: !options?.retype,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'servicesreports',
    ref: 'opr',
    titleAr: 'تقرير البنود',
    titleEn: 'Items Report',
    view: false,
  },
  {
    name: 'procedures',
    top: true,
    ref: 'prc',
    titleAr: 'الاجراءات',
    titleEn: 'Procedures',
    hide: !options?.procedures,
    view: false,
  },
  {
    name: 'stepitems',
    ref: 'prc',
    titleAr: 'بنود الخطوات',
    titleEn: 'Step Items',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },

  {
    name: 'pcategories',
    ref: 'prc',
    titleAr: 'تصنيفات الاجراءات',
    titleEn: 'Procedure Category',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'procitems',
    ref: 'prc',
    titleAr: `بنود الاجراءات`,
    titleEn: 'Procedure Items',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'sms',
    top: true,
    ref: 'sms',
    titleAr: 'رسائل SMS',
    titleEn: 'SMS Messages',
    hide: !options?.sms,
    view: false,
  },
  {
    name: 'contacts',
    ref: 'sms',
    titleAr: 'جهات الاتصال',
    titleEn: 'Contacts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'groups',
    ref: 'sms',
    titleAr: 'المجموعات',
    titleEn: 'Groups',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'messages',
    ref: 'sms',
    titleAr: 'الرسائل',
    titleEn: 'Messages',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'sendreqs',
    ref: 'sms',
    titleAr: 'حملات الارسال',
    titleEn: 'SMS campaign',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'msgtemplates',
    ref: 'sms',
    titleAr: 'نماذج الرسائل',
    titleEn: 'Message Templates',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managereminders',
    top: true,
    ref: 'sms',
    titleAr: 'المفكرة',
    titleEn: 'Reminders',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'users',
    top: true,
    titleAr: 'المستخدمين',
    titleEn: 'Users',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'notifications',
    top: true,
    ref: 'opr',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    view: false,
  },
  {
    name: 'options',
    top: true,
    ref: 'opr',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    view: false,
    edit: false,
    admin: false,
  },
];
export const badmintemplate = [
  {
    name: 'home',
    top: true,
    ref: 'opr',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    view: true,
  },
  {
    name: 'calendar',
    top: true,
    ref: 'opr',
    titleAr: 'التقويم',
    titleEn: 'Calendar',
    hide: !options?.calendar,
    view: true,
  },
  {
    name: 'tasks',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.tasks,
    titleEn: tempwords?.tasks,
    hide: !options?.contract,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'manageprojects',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.projects,
    titleEn: tempwords?.projects,
    hide: !options?.project,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'appointments',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.appointments,
    titleEn: tempwords?.appointments,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'managment',
    top: true,
    ref: 'opr',
    titleAr: 'الإدارة',
    titleEn: 'Management',
    view: true,
  },
  {
    name: 'manageresourses',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.resourses}`,
    titleEn: `${tempwords?.resourses} Mangement`,
    hide: !options?.resourse,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'manageemployees',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.employees}`,
    titleEn: `${tempwords?.employees} Mangement`,
    hide: !options?.employee,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'managedepartments',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.departments}`,
    titleEn: `${tempwords?.departments} Mangment`,
    hide: !options?.department,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'formdocs',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.formdocs}`,
    titleEn: `${tempwords?.formdocs} Mangment`,
    hide: !options?.formdocs,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'letters',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.letters}`,
    titleEn: `${tempwords?.letters} Mangment`,
    hide: !options?.letters,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'presales',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.presales}`,
    titleEn: `${tempwords?.presales} Mangment`,
    hide: !options?.presales,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'sales',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.sales}`,
    titleEn: `${tempwords?.sales} Mangment`,
    hide: !options?.sales,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'operations',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.operations}`,
    titleEn: `${tempwords?.operations} Mangment`,
    hide: !options?.operations,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'purchase',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.purchase}`,
    titleEn: `${tempwords?.purchase} Mangment`,
    hide: !options?.purchase,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'finance',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.finance}`,
    titleEn: `${tempwords?.finance} Mangment`,
    hide: !options?.finance,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'expenses',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.expenses}`,
    titleEn: `${tempwords?.expenses} Mangment`,
    hide: !options?.expenses,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'mail',
    ref: 'opr',
    titleAr: `ادارة المرفقات`,
    titleEn: `Attachments Mangment`,
    hide: !options?.mail,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة',
    titleEn: 'Under Approval',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة',
    titleEn: 'Under Review',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadinprogress',
    ref: 'opr',
    titleAr: 'قيد التسجيل',
    titleEn: 'In Progress',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadarchive',
    ref: 'opr',
    titleAr: `الأرشيف`,
    titleEn: `Archive`,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'entryunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة - ادارة',
    titleEn: 'Under Approval - Admin',
    hide: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'entryunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة - ادارة',
    titleEn: 'Under Review - Admin',
    hide: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'entryarchive',
    ref: 'opr',
    titleAr: `الأرشيف - ادارة`,
    titleEn: `Archive - Admin`,
    hide: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'pos',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.pos}`,
    titleEn: `${tempwords?.pos} Mangment`,
    hide: !options?.pos,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'reports',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.reports}`,
    titleEn: `${tempwords?.reports} Mangment`,
    hide: !options?.reports,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'reminder',
    ref: 'opr',
    titleAr: `ادارة ${tempwords?.reminder}`,
    titleEn: `${tempwords?.reminder} Mangment`,
    hide: !options?.reminder,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'calendarempl',
    ref: 'opr',
    titleAr: 'جدول الموظفين',
    titleEn: 'Employees Calendar',
    hide: !options?.emplcalendar,
    view: true,
  },
  {
    name: 'eventsitemsreports',
    ref: 'opr',
    titleAr: `تقرير الانجاز`,
    titleEn: `Performance Report`,
    view: true,
  },
  {
    name: 'calreports',
    ref: 'opr',
    titleAr: `تقرير ${tempwords?.appointments}`,
    titleEn: `${tempwords?.appointments} Report`,
    view: true,
  },
  {
    name: 'contractseports',
    ref: 'opr',
    titleAr: `تقرير ${tempwords?.tasks}`,
    titleEn: `${tempwords?.tasks} Report`,
    hide: !options?.contract,
    view: true,
  },
  {
    name: 'invoices',
    ref: 'sal',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'receipts',
    ref: 'sal',
    titleAr: 'المقبوضات',
    titleEn: 'Receipts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'customers',
    ref: 'sal',
    titleAr: tempwords?.customers,
    titleEn: tempwords?.customers,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'customerreport',
    ref: 'sal',
    titleAr: 'تقرير العملاء',
    titleEn: 'Customer Report',
    view: true,
  },
  {
    name: 'salesreport',
    ref: 'sal',
    titleAr: 'تقرير الايرادات',
    titleEn: 'Sales Report',
    view: true,
  },
  {
    name: 'purchasestock',
    top: true,
    ref: 'pur',
    titleAr: 'المشتريات',
    titleEn: 'Purchase',
    hide: !options?.purchase,
    view: true,
  },
  {
    name: 'purchase',
    ref: 'pur',
    titleAr: 'فواتير الشراء',
    titleEn: 'Purchase',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'payments',
    ref: 'pur',
    titleAr: 'المدفوعات',
    titleEn: 'Payments',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'supliers',
    ref: 'pur',
    titleAr: tempwords?.suppliers,
    titleEn: tempwords?.suppliers,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'supplierreport',
    ref: 'pur',
    titleAr: 'تقرير الموردين',
    titleEn: 'Supplier Report',
    view: true,
  },
  {
    name: 'purchasereport',
    ref: 'pur',
    titleAr: 'تقرير المشتريات',
    titleEn: 'Purchase Report',
    view: true,
  },
  {
    name: 'stockreport',
    ref: 'pur',
    titleAr: 'تقرير المخازن',
    titleEn: 'Stock Report',
    view: true,
  },
  {
    name: 'stockitems',
    ref: 'pur',
    titleAr: 'المنتجات المتوفرة',
    titleEn: 'Stock Items',
    view: true,
  },
  {
    name: 'expensesandpro',
    top: false,
    ref: 'exp',
    titleAr: 'المصروفات',
    titleEn: 'Expenses',
    view: true,
  },
  {
    name: 'expenses',
    ref: 'exp',
    titleAr: 'المصروفات النقدية',
    titleEn: 'Expenses',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'expproducts',
    ref: 'exp',
    titleAr: 'استهلاك المنتجات',
    titleEn: 'Products Expenses',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'expensesreport',
    ref: 'exp',
    titleAr: 'تقرير المصروفات',
    titleEn: 'Expenses Report',
    view: true,
  },
  {
    name: 'expproductreport',
    ref: 'exp',
    titleAr: 'تقرير المنتجات',
    titleEn: 'Products Report',
    view: true,
  },
  {
    name: 'financeexpenses',
    top: true,
    ref: 'fin',
    titleAr: 'الحسابات',
    titleEn: 'Accountant',
    view: true,
  },
  {
    name: 'receiptsadvance',
    ref: 'fin',
    titleAr: 'مقبوضات سلفة',
    titleEn: 'Advanced Receipts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'paymentsadvance',
    ref: 'fin',
    titleAr: 'مدفوعات سلفة',
    titleEn: 'Advance Payments',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'cash',
    ref: 'fin',
    titleAr: 'حركة مالية',
    titleEn: 'Finance',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'financeall',
    ref: 'fin',
    titleAr: 'قيد اليومية',
    titleEn: 'General Journal',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'financereport',
    ref: 'fin',
    titleAr: 'تقرير الحسابات',
    titleEn: 'Finance Report',
    view: true,
  },
  {
    name: 'profitability',
    ref: 'fin',
    titleAr: 'تقرير الربحية',
    titleEn: 'Profitability Report',
    view: true,
  },
  {
    name: 'kaidreport',
    ref: 'fin',
    titleAr: 'تقرير شامل',
    titleEn: 'General Report',
    view: true,
  },
  {
    name: 'budgetreport',
    ref: 'fin',
    titleAr: 'الحسابات الختامية',
    titleEn: 'Closing Accounts',
    view: true,
  },
  {
    name: 'accounts',
    ref: 'fin',
    titleAr: 'قائمة الحسابات',
    titleEn: 'Accounts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'items',
    top: true,
    ref: 'opr',
    titleAr: 'البنود',
    titleEn: 'Items',
    view: true,
  },
  {
    name: 'categories',
    ref: 'opr',
    titleAr: 'التصنيفات',
    titleEn: 'Categories',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'brands',
    ref: 'opr',
    titleAr: 'البراندات',
    titleEn: 'Brands',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'services',
    ref: 'opr',
    titleAr: 'بنود الخدمات',
    titleEn: 'Services Items',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'products',
    ref: 'opr',
    titleAr: 'بنود المنتجات',
    titleEn: 'Products Items',
    hide: !options?.purchase,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'expenseitems',
    ref: 'opr',
    titleAr: 'بنود المصروفات',
    titleEn: 'Expense Items',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'rentitem',
    ref: 'opr',
    titleAr: 'بنود التأجير',
    titleEn: 'Rent Items',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'collectiveitem',
    ref: 'opr',
    titleAr: 'بنود التجميع',
    titleEn: 'Collective Items',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'resoursetypes',
    ref: 'opr',
    titleAr: 'ادارة الانواع',
    titleEn: 'Types Management',
    hide: !options?.retype,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'servicesreports',
    ref: 'opr',
    titleAr: 'تقرير البنود',
    titleEn: 'Items Report',
    view: true,
  },
  {
    name: 'procedures',
    top: true,
    ref: 'prc',
    titleAr: 'الاجراءات',
    titleEn: 'Procedures',
    hide: !options?.procedures,
    view: true,
  },
  {
    name: 'stepitems',
    ref: 'prc',
    titleAr: 'بنود الخطوات',
    titleEn: 'Step Items',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },

  {
    name: 'pcategories',
    ref: 'prc',
    titleAr: 'تصنيفات الاجراءات',
    titleEn: 'Procedure Category',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'procitems',
    ref: 'prc',
    titleAr: `بنود الاجراءات`,
    titleEn: 'Procedure Items',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'sms',
    top: true,
    ref: 'sms',
    titleAr: 'رسائل SMS',
    titleEn: 'SMS Messages',
    hide: !options?.sms,
    view: true,
  },
  {
    name: 'contacts',
    ref: 'sms',
    titleAr: 'جهات الاتصال',
    titleEn: 'Contacts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'groups',
    ref: 'sms',
    titleAr: 'المجموعات',
    titleEn: 'Groups',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'messages',
    ref: 'sms',
    titleAr: 'الرسائل',
    titleEn: 'Messages',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'sendreqs',
    ref: 'sms',
    titleAr: 'حملات الارسال',
    titleEn: 'SMS campaign',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'msgtemplates',
    ref: 'sms',
    titleAr: 'نماذج الرسائل',
    titleEn: 'Message Templates',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'managereminders',
    top: true,
    ref: 'sms',
    titleAr: 'المفكرة',
    titleEn: 'Reminders',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'users',
    top: true,
    titleAr: 'المستخدمين',
    titleEn: 'Users',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'notifications',
    top: true,
    ref: 'opr',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    view: true,
  },
  {
    name: 'options',
    top: true,
    ref: 'opr',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    view: true,
    edit: true,
    admin: true,
  },
];
export const employeetemplate = [
  {
    name: 'home',
    top: true,
    ref: 'opr',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    view: true,
  },
  {
    name: 'calendar',
    top: true,
    ref: 'opr',
    titleAr: 'التقويم',
    titleEn: 'Calendar',
    hide: !options?.calendar,
    view: true,
  },
  {
    name: 'tasks',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.tasks,
    titleEn: tempwords?.tasks,
    hide: !options?.contract,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'appointments',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.appointments,
    titleEn: tempwords?.appointments,
    view: true,
    add: true,
    edit: true,
    delete: false,
    admin: false,
  },
  {
    name: 'invoices',
    ref: 'sal',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    view: true,
    add: true,
    edit: true,
    delete: false,
    admin: false,
  },
  {
    name: 'receipts',
    ref: 'sal',
    titleAr: 'المقبوضات',
    titleEn: 'Receipts',
    view: true,
    add: true,
    edit: true,
    delete: false,
    admin: false,
  },
  {
    name: 'customers',
    ref: 'sal',
    titleAr: tempwords?.customers,
    titleEn: tempwords?.customers,
    view: true,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managereminders',
    top: true,
    ref: 'sms',
    titleAr: 'المفكرة',
    titleEn: 'Reminders',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'notifications',
    top: true,
    ref: 'opr',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    view: true,
  },
  {
    name: 'options',
    top: true,
    ref: 'opr',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    view: true,
    edit: false,
    admin: false,
  },
];
export const userrolesTypes = {
  home: { view: true },
  calendar: { view: true },
  tasks: { view: true, add: true, edit: true, delete: true, admin: true },
  manageprojects: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  appointments: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  managment: {
    view: true,
  },
  manageresourses: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  manageemployees: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  managedepartments: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  formdocs: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  letters: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  presales: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  mail: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadunderapproval: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadunderreview: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadinprogress: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadarchive: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  entryunderapproval: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  entryunderreview: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  entryarchive: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  pos: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  reports: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  reminder: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  sales: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  operations: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  purchase: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  expenses: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  finance: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  calendarempl: {
    view: true,
  },
  eventsitemsreports: {
    view: true,
  },
  calreports: {
    view: true,
  },
  contractseports: {
    view: true,
  },
  invoices: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  receipts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  customers: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  customerreport: {
    view: true,
  },
  salesreport: {
    view: true,
  },
  purchasestock: {
    view: true,
  },
  payments: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  supliers: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  supplierreport: {
    view: true,
  },
  purchasereport: {
    view: true,
  },
  stockreport: {
    view: true,
  },
  stockitems: {
    view: true,
  },
  expensesandpro: {
    view: true,
  },
  expproducts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  expensesreport: {
    view: true,
  },
  expproductreport: {
    view: true,
  },
  financeexpenses: {
    view: true,
  },
  receiptsadvance: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  paymentsadvance: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  cash: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  financeall: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  financereport: {
    view: true,
  },
  profitability: {
    view: true,
  },
  kaidreport: {
    view: true,
  },
  budgetreport: {
    view: true,
  },
  accounts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  items: {
    view: true,
  },
  categories: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  brands: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  services: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  products: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  expenseitems: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  rentitem: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  collectiveitem: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  resoursetypes: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  servicesreports: {
    view: true,
  },
  procedures: {
    view: true,
  },
  stepitems: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },

  pcategories: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  procitems: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  sms: {
    view: true,
  },
  contacts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  groups: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  messages: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  sendreqs: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  msgtemplates: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  managereminders: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  users: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  notifications: {
    view: true,
  },
  options: {
    view: true,
    edit: true,
    admin: true,
  },
  isSuperAdmin: false,
  isBranchsAdmin: false,
  isBranchAdmin: false,
  isEmployee: false,
};
