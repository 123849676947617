import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { graphqlURI, wsURI } from '../constants';
import { getStoreItem } from '../store';

const httpLink = new HttpLink({ uri: graphqlURI });
const wsLink = new GraphQLWsLink(createClient({ url: wsURI }));

const authLink = setContext(async (_, { headers }) => {
  const gStore = getStoreItem('store');
  const token = gStore ? gStore.token : null;
  return {
    headers: {
      ...headers,
      authshee: token ? `Jadwal ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache(),
});

export { client };

//
