import { gql } from '@apollo/client';

export default gql`
  mutation updateProcStatus(
    $procId: String
    $stepId: Int
    $stepName: String
    $stepNameAr: String
    $stepDesc: String
    $stepColor: String
  ) {
    updateProcStatus(
      procId: $procId
      stepId: $stepId
      stepName: $stepName
      stepNameAr: $stepNameAr
      stepDesc: $stepDesc
      stepColor: $stepColor
    ) {
      ok
      message
      data
      error
    }
  }
`;
