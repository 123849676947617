/* eslint-disable array-callback-return */

import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Paper } from '@material-ui/core';
import { basicitem, renderCell, rid } from './renders';
import ItemStyle from './ItemStyle';
import { useClassnames } from '../common';
import { getPrintPageDimentions } from '../common/helpers';

import { useWindowDimensions } from '../../../hooks';
import UploadJson, { downloadJSONFile } from '../components/UploadJson';

const renderButton = (title: any, action: any) => (
  <Button variant="outlined" color="primary" onClick={action} fullWidth>
    {title}
  </Button>
);

const FormBuilder = ({
  company,
  rows,
  setRows,
  size,
  header,
  footer,
  isRTL,
  fields,
  words,
  variables,
  setVariables,
}: any) => {
  const [localRows, setLocalRows] = useState([]);
  const [selected, setSelected] = useState(null);
  const [sub, setSub] = useState(null);
  const { height } = useWindowDimensions();
  const classes = useClassnames();

  const { pageWidth, headeHight, footerHight } = getPrintPageDimentions({
    size,
    header,
    footer,
  });

  useEffect(() => {
    const rowswind = rows.map((ro: any, index: any) => {
      return ro?.map((r: any) => {
        return {
          ...r,
          indx: index,
        };
      });
    });

    const indexs = rowswind.map((r: any, index: any) => {
      if (r?.[0].type === 'pb') {
        return index;
      } else {
        return undefined;
      }
    });
    const findexs = indexs.filter((x: any) => x);
    if (findexs && findexs?.length > 0) {
      let newrows = [];
      let ind = 0;
      findexs.map((fi: any) => {
        const arr = rowswind.slice(ind ? ind + 1 : ind, fi + 1);
        newrows.push(arr);
        ind = fi;
      });
      const farr = rowswind.slice(ind + 1);
      newrows.push(farr);
      setLocalRows(newrows);
    } else {
      setLocalRows([rowswind]);
    }
  }, [rows]);

  const addItem = () => {
    const item = [{ id: rid(), ...basicitem }];
    const newites = [...rows, item];
    setRows(newites);
  };
  const insertItem = () => {
    const item = [{ id: rid(), ...basicitem }];
    const newites = [...rows];
    newites.splice(selected, 0, item);
    setRows(newites);
  };
  const removeSelected = () => {
    const nrows = [...rows];
    nrows.splice(selected, 1);
    setRows(nrows);
    setSelected(null);
  };
  const addSubItem = () => {
    if (selected === null) return;
    const nrows = [...rows];
    nrows?.[selected].push({ id: rid(), ...basicitem });
    setRows(nrows);
  };
  const insertSubItem = () => {
    if (selected === null) return;
    const item = { id: rid(), ...basicitem };
    const nrows = [...rows];
    const row = nrows?.[selected];
    const index = row.indexOf(sub);
    row.splice(index, 0, item);
    nrows[selected] = row;
    setRows(nrows);
  };
  const removeSubItem = () => {
    const nrows = [...rows];
    const row = rows[selected];
    if (row.length === 1) return;
    const nrow = row?.filter((it: any) => it?.id !== sub.id);
    nrows[selected] = nrow;
    setRows(nrows);
  };

  const setRow = (nrow: any) => {
    setSub(nrow);
    const nrows = [...rows];
    const row = rows[selected];
    if (!row) return;
    const srow = row?.map((r: any) => {
      if (nrow.id === r.id) {
        return nrow;
      } else {
        return r;
      }
    });
    nrows[selected] = srow;
    setRows(nrows);
  };

  const renderOptions = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <UploadJson setValue={setRows}></UploadJson>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => downloadJSONFile(rows)}>Download</Button>
        </Grid>
      </Grid>
    );
  };
  const renderSide = () => {
    return (
      <Paper
        style={{
          height: height - 300,
          overflow: 'auto',
          padding: 10,
        }}
      >
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={4}>
            {renderButton('Add Row', addItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Insert', insertItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Remove', removeSelected)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Add Sub', addSubItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Insert', insertSubItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Remove', removeSubItem)}
          </Grid>
          <Grid item xs={12}>
            <ItemStyle fields={fields} row={sub} setRow={setRow}></ItemStyle>
          </Grid>
        </Grid>
      </Paper>
    );
  };
  const renderHeader = (company: any) => (
    <Grid item xs={12}>
      <img
        src={company?.header}
        alt={company?.name}
        height="auto"
        width="100%"
        style={{
          objectFit: 'contain',
        }}
      />
    </Grid>
  );

  const renderFooter = (company: any) => (
    <Grid item xs={12}>
      <img
        src={company?.footer}
        alt={company?.name}
        height="auto"
        width="100%"
        style={{
          objectFit: 'contain',
        }}
      />
    </Grid>
  );

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          {renderOptions()}
          <Divider></Divider>
          {renderSide()}
        </Grid>
        <Grid item xs={9}>
          <Paper
            style={{
              backgroundColor: '#ddd',
              padding: 20,
              paddingLeft: 100,
              overflow: 'auto',
              height: height - 300,
            }}
          >
            {localRows?.map((lrows: any) => {
              return (
                <Paper
                  style={{
                    width: pageWidth,
                    height: 'auto',
                    marginTop: 20,
                  }}
                  elevation={5}
                >
                  <Box>
                    {header && (
                      <Box
                        style={{
                          display: 'flex',
                          height: headeHight,
                          position: 'relative',
                          top: 0,
                        }}
                      >
                        {renderHeader(company)}
                      </Box>
                    )}
                    <div
                      style={{
                        height: 'auto',
                      }}
                    >
                      {lrows?.map((listitem: any) => {
                        return (
                          <Grid container spacing={0}>
                            {listitem?.map((item: any) => {
                              const { id, size, skip, type, indx } = item;
                              const args = {
                                ...item,
                                classes,
                                item,
                                setRow,
                                isRTL,
                                words,
                                variables,
                                setVariables,
                              };
                              const subactive = sub?.id === id;
                              const shadow = {
                                border: '1px solid #20b2aa',
                              };
                              return (
                                <>
                                  {skip > 0 && <Grid item xs={skip}></Grid>}
                                  <Grid
                                    item
                                    xs={size}
                                    style={subactive ? shadow : {}}
                                    onClick={() => {
                                      if (subactive) {
                                        setSelected(null);
                                        setSub(null);
                                      } else {
                                        setSelected(indx);
                                        setSub(item);
                                      }
                                    }}
                                  >
                                    {renderCell(type, args, classes)}
                                  </Grid>
                                </>
                              );
                            })}
                          </Grid>
                        );
                      })}
                    </div>
                    {footer && (
                      <Box
                        style={{
                          display: 'flex',
                          height: footerHight,
                          position: 'relative',
                          bottom: 0,
                        }}
                      >
                        {renderFooter(company)}
                      </Box>
                    )}
                  </Box>
                </Paper>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormBuilder;
