import { gql } from '@apollo/client';

export default gql`
  mutation createTempEvent(
    $startDate: Date
    $endDate: Date
    $department: DepartmentInput
    $employee: EmployeeInput
    $resourse: ResourseInput
  ) {
    createTempEvent(
      startDate: $startDate
      endDate: $endDate
      department: $department
      employee: $employee
      resourse: $resourse
    ) {
      _id
      id
      branch
      title
      startDate
      endDate
      allDay
      rRule
      exDate
      allowDrag

      autoNo
      docNo
      priority
      amount
      status

      bonos
      overtime
      absent
      cashin
      teamMembers
      groupId
      locationLink
      address
      houseno
      temp

      customerId
      customerName
      customerNameAr
      customerPhone

      itemId
      itemName
      itemNameAr

      departmentId
      departmentName
      departmentNameAr
      departmentColor

      employeeId
      employeeName
      employeeNameAr
      employeeColor
      employeePhone

      resourseId
      resourseName
      resourseNameAr
      resourseColor

      contractId
      contractName
      contractNameAr

      retypeId
      retypeName
      retypeNameAr
      retypeColor

      retype2Id
      retype2Name
      retype2NameAr
      retype2Color
      userId
      createdAt
      updatedAt
    }
  }
`;
