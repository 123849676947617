/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { useExpenseItems, useTemplate } from '../../hooks';
import { downloadCSVFile, getRowId, userroles } from '../../common';
import { PopupExpenseItem } from '../../pubups';
import { currencyFormatter, isSalaryFormatter } from '../../Shared/colorFormat';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import ImportBtn from '../../common/ImportBtn';
import PopupItemsImport from '../../pubups/PopupItemsImport';
import { getColumns } from '../../common/columns';
import ExportBtn from '../../common/ExportBtn';

const ExpenseItems = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme, tempId } = props;

  const [openImport, setOpenImport] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { tempoptions } = useTemplate();

  const col = getColumns({ isRTL, words });
  const isDel = tempId === 9;
  const [columns] = useState(
    isDel
      ? [
          tempoptions?.barcode && { name: 'barcode', title: words.code },
          col.name,
          { name: 'price', title: words.price },
          { name: 'unit', title: words.unit },
          {
            name: 'isSalary',
            title: isRTL ? 'مستقطع الراتب' : 'Salary Deduction',
          },
          col.category,
          { name: 'desc', title: words.description },
        ].filter((x) => x)
      : [
          tempoptions?.barcode && { name: 'barcode', title: words.code },
          col.name,
          { name: 'price', title: words.price },
          { name: 'unit', title: words.unit },
          col.category,
          { name: 'desc', title: words.description },
        ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'barcode', width: 150 },
    { columnName: col.name.name, width: 250 },
    { columnName: 'price', width: 150 },
    { columnName: 'unit', width: 150 },
    { columnName: 'isSalary', width: 150 },
    { columnName: col.category.name, width: 200 },
    { columnName: 'desc', width: 250 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.name.name, togglingEnabled: false },
  ]);

  const {
    expenseItems,
    addExpenseItem,
    addMultiExpenseItems,
    editExpenseItem,
    removeExpenseItem,
    refreshexpitems,
  } = useExpenseItems();
  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removeExpenseItem({ variables: { _id } });
      if (res?.data?.deleteItem?.ok === false) {
        if (res?.data?.deleteItem?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    refresh() {
      if (refreshexpitems) {
        refreshexpitems();
      }
    },
  }));

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(expenseItems, 'expenseItems', colnames);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
      </Box>

      <Grid rows={expenseItems} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 45 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={[
            'photo',
            col.name.name,
            'price',
            'unit',
            col.category.name,
            'isSalary',
            'desc',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['price']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['isSalary']}
          formatterComponent={(props: any) =>
            isSalaryFormatter({ ...props, editAction: editExpenseItem })
          }
        ></DataTypeProvider>

        <TableEditColumn
          showEditCommand={userroles?.expenses?.edit}
          showDeleteCommand={userroles?.expenses?.delete}
          showAddCommand={userroles?.expenses?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 15, 20, 50, 0] : [5, 10, 15, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing
          theme={theme}
          addAction={addExpenseItem}
          editAction={editExpenseItem}
        >
          <PopupExpenseItem></PopupExpenseItem>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupItemsImport
        open={openImport}
        onClose={() => setOpenImport(false)}
        addMultiItems={addMultiExpenseItems}
        isRTL={isRTL}
        theme={theme}
        words={words}
        itemType={10}
        filename="expenses"
      ></PopupItemsImport>
    </>
  );
});

export default ExpenseItems;
