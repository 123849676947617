/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, userroles } from '../../common';
import { PopupPrintTemplate } from '../../pubups';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import { sentShowFormatter } from '../../Shared/colorFormat';
import useBranchPrintTemplates from '../../hooks/useBranchPrintTemplates';
import { useTemplate } from '../../hooks';

export default function PrintTemplates({
  isRTL,
  words,
  theme,
  branch,
  company,
}: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [columns] = useState([
    { name: 'name', title: words.name },
    { name: 'type', title: isRTL ? 'النوع' : 'Type' },
    { name: 'isdefault', title: isRTL ? 'افتراضي' : 'Default' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'name', width: 250 },
    { columnName: 'type', width: 250 },
    { columnName: 'isdefault', width: 250 },
  ]);

  const {
    printtemplates,
    addPrintTemplate,
    editPrintTemplate,
    removePrintTemplate,
  } = useBranchPrintTemplates(branch);
  const { tempoptions } = useTemplate();

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removePrintTemplate({ variables: { _id } });
      if (res?.data?.deletePrintTemplate?.ok === false) {
        if (res?.data?.deletePrintTemplate?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const bgcolor = '#EFFAF100';

  return (
    <Box
      style={{
        maxHeight: 1000,
        overflow: 'auto',
        backgroundColor: bgcolor,
      }}
    >
      <Paper
        elevation={5}
        style={{
          marginTop: 10,
          marginBottom: 20,
          overflow: 'auto',
          width: '100%',
          borderRadius: 10,
        }}
      >
        <Grid rows={printtemplates} columns={columns} getRowId={getRowId}>
          <SortingState />
          <EditingState onCommitChanges={commitChanges} />
          <SearchState />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />

          <IntegratedSorting />
          <IntegratedFiltering />
          <IntegratedPaging />
          <DragDropProvider />
          <Table
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            tableComponent={TableComponent}
            rowComponent={(props: any) => (
              <Table.Row {...props} style={{ height: 68 }}></Table.Row>
            )}
            columnExtensions={tableColumnExtensions}
          />
          <TableColumnReordering
            defaultOrder={[isRTL ? 'nameAr' : 'name', 'type', 'isdefault']}
          />
          <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableColumnVisibility
            columnExtensions={[]}
            defaultHiddenColumnNames={[]}
          />
          <DataTypeProvider
            for={['isdefault']}
            formatterComponent={sentShowFormatter}
          ></DataTypeProvider>
          <TableEditColumn
            showEditCommand={userroles?.isSuperAdmin}
            showDeleteCommand={userroles?.isSuperAdmin}
            showAddCommand={userroles?.isSuperAdmin}
            commandComponent={Command}
          ></TableEditColumn>
          <Toolbar />
          <ColumnChooser />
          <PagingPanel
            pageSizes={
              tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
            }
          />

          <SearchPanel
            inputComponent={(props: any) => {
              return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
            }}
          />

          <PopupEditing
            theme={theme}
            addAction={addPrintTemplate}
            editAction={editPrintTemplate}
          >
            <PopupPrintTemplate
              company={company}
              branch={branch}
            ></PopupPrintTemplate>
          </PopupEditing>
        </Grid>
      </Paper>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
    </Box>
  );
}
