/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, updateDocNumbers, userroles } from '../../common';
import {
  getPurchaseInvoices,
  createPurchaseInvoice,
  updatePurchaseInvoice,
  deletePurchaseInvoice,
  getProducts,
} from '../../graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  amountFormatter,
  currencyFormatter,
  timeFormatter,
} from '../../Shared/colorFormat';
import { getColumns } from '../../common/columns';
import { PurchaseContext } from '../../contexts';
import { SearchTable } from '../../components';
import PopupPurchaseInvoice from '../../pubups/PopupPurchaseInvoice';
import { useProducts, useTemplate } from '../../hooks';
import useDepartments from '../../hooks/useDepartments';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useTasks from '../../hooks/useTasks';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/ItemsTable';
import { moneyFormat } from '../common/formater';
import _ from 'lodash';

const PurchaseInvoices = forwardRef((props: any, ref: any) => {
  const { isRTL, words, start, end, theme, company, drawerWidth } = props;

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      { name: 'time', title: words?.time },
      { name: 'docNo', title: words?.no },
      tempoptions?.contract && col.contract,
      col.supplier,
      { name: 'supplierPhone', title: words.phoneNumber },
      { name: 'total', title: words?.total },
      { name: 'discount', title: words?.discount },
      { name: 'amount', title: words?.amount },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 100 },
    { columnName: 'docNo', width: 120 },
    { columnName: col.supplier.name, width: 250 },
    { columnName: col.contract.name, width: 250 },
    { columnName: 'supplierPhone', width: 150 },
    { columnName: 'total', width: 120 },
    { columnName: 'discount', width: 120 },
    { columnName: 'amount', width: 120 },
  ]);

  const [rows, setRows] = useState([]);
  const [sum, setSum] = useState(0);

  const { tasks } = useTasks();

  const { products } = useProducts();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();

  const {
    state: { hiddenColumnNames, pageSize },
    dispatch,
  } = useContext(PurchaseContext);

  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const setPageSize = (pageSize: any) => {
    dispatch({ type: 'setPageSize', payload: pageSize });
  };

  const [loadPurchaseInvoices, opData]: any = useLazyQuery(
    getPurchaseInvoices,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const refresQuery = {
    refetchQueries: [
      {
        query: getPurchaseInvoices,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
      { query: getProducts },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    if (start) {
      loadPurchaseInvoices({
        variables,
      });
    }
  }, [start, end]);

  const [addPurchaseInvoice] = useMutation(createPurchaseInvoice, refresQuery);
  const [editPurchaseInvoice] = useMutation(updatePurchaseInvoice, refresQuery);
  const [removePurchaseInvoice] = useMutation(
    deletePurchaseInvoice,
    refresQuery
  );

  useImperativeHandle(ref, () => ({
    refresh() {
      if (opData?.refetch) {
        opData.refetch();
      }
    },
  }));

  const commitChanges: any = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removePurchaseInvoice({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };

  useEffect(() => {
    if (opData?.data?.getPurchaseInvoices?.data) {
      const { data } = opData.data.getPurchaseInvoices;
      const rdata = updateDocNumbers(data);
      const samount = _.sumBy(rdata, 'amount');
      setSum(samount);
      setRows(rdata);
    }
  }, [opData]);

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 50,
          right: isRTL ? drawerWidth + 50 : undefined,
          bottom: 50,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          style={{
            color: '#333',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {isRTL ? ' المجموع ' : ' Total '}: {moneyFormat(sum)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          defaultCurrentPage={0}
          defaultPageSize={10}
        />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'time',
            'docNo',
            col.eventNo.name,
            col.contract.name,
            col.customer.name,
            'customerPhone',
            'total',
            'discount',
            'amount',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <DataTypeProvider
          for={['time']}
          formatterComponent={timeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={amountFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['total', 'discount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.purchase?.edit}
          showDeleteCommand={userroles?.purchase?.delete}
          showAddCommand={userroles?.purchase?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          addAction={addPurchaseInvoice}
          editAction={editPurchaseInvoice}
        >
          <PopupPurchaseInvoice
            resourses={resourses}
            employees={employees}
            departments={departments}
            company={company}
            servicesproducts={products}
            tasks={tasks}
            theme={theme}
          ></PopupPurchaseInvoice>
        </PopupEditing>
      </Grid>
    </>
  );
});

export default PurchaseInvoices;
