import { Grid } from '@material-ui/core';
import { useClassnames } from '../common';
import { renderCell } from './renders';

function FormPaper({
  user,
  setAlrt,
  template,
  isRTL,
  words,
  variables,
  setVariables,
  itemsList,
  totals,
  addItemToList,
  editItemInList,
  removeItemFromList,
}: any) {
  const tempdata = template ? JSON.parse(template) : [];
  const classes = useClassnames();
  return (
    <div>
      {tempdata?.map((listitem: any) => {
        return (
          <Grid container spacing={0}>
            {listitem?.map((item: any) => {
              const { size, skip, type } = item;
              const args = {
                ...item,
                classes,
                item,
                isRTL,
                words,
                variables,
                setVariables,
                user,
                setAlrt,
                itemsList,
                totals,
                addItemToList,
                editItemInList,
                removeItemFromList,
              };
              return (
                <>
                  {skip > 0 && <Grid item xs={skip}></Grid>}
                  <Grid item xs={size}>
                    {renderCell(type, args, classes, true)}
                  </Grid>
                </>
              );
            })}
          </Grid>
        );
      })}
    </div>
  );
}

export default FormPaper;
