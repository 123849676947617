/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import UploadDocView from '../components/viewer/UploadDocView';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

export const kaidlist = (list: any) => {
  return list.map((item: any) => {
    return {
      ...item,
      amountDebit: item.debit,
      amountCredit: item.credit,
      acc: item.accCode,
    };
  });
};

const PopupArchiveDoc = ({ open, onClose, row, theme, company }: any) => {
  const [attachments, setAttachments] = useState([]);

  const {
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);

  useEffect(() => {
    if (row && row._id) {
      if (row.attachments) {
        const phs = JSON.parse(row.attachments);
        if (phs && phs.length > 0) {
          setAttachments(phs);
        }
      }
    }
  }, [row, open]);

  const closeModal = () => {
    setAttachments([]);
    onClose();
  };

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={words.generalkaid}
      onSubmit={() => null}
      theme={theme}
      alrt={{}}
      mt={10}
      onlyclose
      maxWidth="lg"
      // fs
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UploadDocView images={attachments} doc={row}></UploadDocView>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupArchiveDoc;
