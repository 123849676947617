import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import MyIcon from '../Shared/MyIcon';
import { userroles } from './check';
import CopyClipBoard from './CopyClipBoard';

export default function ButtonShort({
  value,
  row,
  loading,
  selected,
  addLink,
  isRTL,
}: any) {
  const disabled = !userroles?.appointments?.edit;
  const id = row?._id;
  const isloading = selected === id && loading;

  if (value) {
    return (
      <Box
        display="flex"
        style={{
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <CopyClipBoard text={value} icon></CopyClipBoard>
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            marginTop: 3,
          }}
        >
          <MyIcon size={28} color="#4ca3dd" icon="link"></MyIcon>
        </a>
      </Box>
    );
  }

  return (
    <Button
      disabled={disabled || isloading}
      variant="outlined"
      onClick={() => addLink(id)}
    >
      <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
        {isRTL ? 'انشاء' : 'Create'}
      </Typography>
      {isloading && <CircularProgress color="primary" size={14} />}
    </Button>
  );
}
