import { operationTypes } from '.';
import { getStoreItem } from '../store';

const store = getStoreItem('store');
const template = store?.template;
const lang = store?.lang;
const words = template?.words?.[lang];
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const groupList = (isRTL: any, product = false) => [
  {
    id: 1,
    value: 'none',
    name: isRTL ? 'بدون تجميع' : 'No Grouping',
  },
  {
    id: 2,
    value: 'employee',
    name: isRTL ? `تجميع بحسب ${words.employee}` : `Group By ${words.employee}`,
  },
  {
    id: 3,
    value: 'department',
    name: isRTL
      ? `تجميع بحسب ${words.department}`
      : `Group By ${words.department}`,
  },
  {
    id: 4,
    value: 'service',
    name: isRTL ? 'تجميع بحسب البند' : 'Group By Item',
  },
  {
    id: 5,
    value: 'customer',
    name: isRTL ? `تجميع بحسب ${words.customer}` : `Group By ${words.customer}`,
  },
  {
    id: 5.5,
    value: 'supplier',
    name: isRTL ? `تجميع بحسب المورد` : `Group By Supplier`,
  },
  {
    id: 6,
    value: 'category',
    name: isRTL ? 'تجميع بحسب التصنيف' : 'Group By Category',
  },
  {
    id: 7,
    value: 'status',
    name: isRTL ? 'تجميع بحسب الحالة' : 'Group By status',
  },
  {
    id: 8,
    value: 'contract',
    name: isRTL ? `تجميع بحسب ${words.task}` : `Group By  ${words.task}`,
  },
  {
    id: 9,
    value: 'opType',
    name: isRTL ? 'تجميع بحسب الوثيقة' : 'Group By Document',
  },
  {
    id: 10,
    value: 'project',
    name: isRTL ? `تجميع بحسب ${words.project}` : `Group By ${words.project}`,
  },
  {
    id: 11,
    value: 'resourse',
    name: isRTL ? `تجميع بحسب ${words.resourse}` : `Group By ${words.resourse}`,
  },
  {
    id: 12,
    value: 'retype',
    name: isRTL ? `تجميع بحسب ${words.evretype}` : `Group By ${words.retype}`,
  },
  {
    id: 13,
    value: 'retype2',
    name: isRTL ? `تجميع بحسب ${words.evretype2}` : `Group By ${words.retype2}`,
  },
];

export const documentTypes = [
  {
    id: 1,
    value: 'none',
    name: 'All',
    nameAr: 'الكل',
  },
  {
    id: 2,
    value: operationTypes.event,
    name: 'Appointment',
    nameAr: 'المواعيد',
  },
  {
    id: 3,
    value: operationTypes.salesInvoice,
    name: 'Sales Invoices',
    nameAr: 'فواتير البيع',
  },
  {
    id: 4,
    value: operationTypes.customerReceipt,
    name: 'Receipts',
    nameAr: 'سندات القبض',
  },
  {
    id: 5,
    value: operationTypes.salesDelivery,
    name: 'Sales Delivery',
    nameAr: 'تسليم بضاعة',
  },
  {
    id: 6,
    value: operationTypes.customerDiscount,
    name: 'customerDiscount',
    nameAr: 'خصم مبيعات',
  },
  {
    id: 7,
    value: operationTypes.purchaseInvoice,
    name: 'Purchase Invoices',
    nameAr: 'فواتير الشراء',
  },
  {
    id: 8,
    value: operationTypes.supplierPayemnt,
    name: 'Payemnts',
    nameAr: 'سندات الدفع',
  },
  {
    id: 9,
    value: operationTypes.purchaseDelivery,
    name: 'Purchase Delivery',
    nameAr: 'استلام بضاعة',
  },
  {
    id: 10,
    value: operationTypes.supplierDiscount,
    name: 'supplierDiscount',
    nameAr: 'خصم مشتريات',
  },
  {
    id: 11,
    value: operationTypes.expenses,
    name: 'Expenses',
    nameAr: 'المصروفات',
  },
  {
    id: 12,
    value: operationTypes.expproducts,
    name: 'Products Expenses',
    nameAr: 'استهلاك منتجات',
  },
  {
    id: 13,
    value: operationTypes.exppettycash,
    name: 'Petty Cash Expenses',
    nameAr: 'مصروف عهدة',
  },
  {
    id: 14,
    value: operationTypes.exppayable,
    name: 'Payable Expenses',
    nameAr: 'مصروف موردين',
  },
  {
    id: 15,
    value: operationTypes.cashDeposet,
    name: 'Cash Deposet',
    nameAr: 'ايداع في البنك',
  },
  {
    id: 16,
    value: operationTypes.cashDraw,
    name: 'Cash Draw',
    nameAr: 'سحب من البنك',
  },
  {
    id: 17,
    value: operationTypes.pettyCashPay,
    name: 'Petty Cash Payment',
    nameAr: 'دفع عهدة',
  },
  {
    id: 18,
    value: operationTypes.pettyCashRec,
    name: 'Petty Cash Receipt',
    nameAr: 'قبض عهدة',
  },
  {
    id: 19,
    value: operationTypes.kaid,
    name: 'Jornal Vaucher',
    nameAr: 'قيود اليومية',
  },
  {
    id: 20,
    value: operationTypes.advanceReceipt,
    name: 'Advance Receipt',
    nameAr: 'سند قبض سلفة',
  },
  {
    id: 21,
    value: operationTypes.advancePayemnt,
    name: 'Advance Payemnt',
    nameAr: 'سند دفع سلفة',
  },
];
export const docTypes = [
  {
    id: 3,
    value: operationTypes.salesInvoice,
    name: 'Sales Invoices',
    nameAr: 'فواتير البيع',
  },
  {
    id: 4,
    value: operationTypes.customerReceipt,
    name: 'Receipts',
    nameAr: 'سندات القبض',
  },
  {
    id: 7,
    value: operationTypes.purchaseInvoice,
    name: 'Purchase Invoices',
    nameAr: 'فواتير الشراء',
  },
  {
    id: 8,
    value: operationTypes.supplierPayemnt,
    name: 'Payemnts',
    nameAr: 'سندات الدفع',
  },
  {
    id: 11,
    value: operationTypes.expenses,
    name: 'Expenses',
    nameAr: 'المصروفات',
  },
  {
    id: 15,
    value: operationTypes.cashDeposet,
    name: 'Cash Deposet',
    nameAr: 'ايداع في البنك',
  },
  {
    id: 16,
    value: operationTypes.cashDraw,
    name: 'Cash Draw',
    nameAr: 'سحب من البنك',
  },
  {
    id: 19,
    value: operationTypes.kaid,
    name: 'Jornal Vaucher',
    nameAr: 'قيود اليومية',
  },
];
export const docTypesExtend = [
  {
    id: 1,
    value: operationTypes.salesInvoice,
    name: 'Sales Invoices Cash',
    nameAr: 'فواتير بيع نقدي',
    debitmainacc: 1,
    debitacc: 1000,
    creditmainacc: 13,
    creditacc: 4000,
  },
  {
    id: 2,
    value: operationTypes.salesInvoiceCard,
    name: 'Sales Invoices Card',
    nameAr: 'فواتير بيع بطاقة',
    debitmainacc: 1,
    debitacc: 1010,
    creditmainacc: 13,
    creditacc: 4000,
  },
  {
    id: 3,
    value: operationTypes.salesInvoiceCredit,
    name: 'Sales Invoices Credit',
    nameAr: 'فواتير بيع آجلة',
    debitmainacc: 2,
    debitacc: 1100,
    creditmainacc: 13,
    creditacc: 4000,
  },
  {
    id: 4,
    value: operationTypes.customerReceipt,
    name: 'Receipts',
    nameAr: 'سندات قبض',
    debitmainacc: 1,
    debitacc: 1000,
    creditmainacc: 2,
    creditacc: 1100,
  },
  {
    id: 5,
    value: operationTypes.customerReceiptCheque,
    name: 'Receipts Cheque',
    nameAr: 'سندات قبض شيكات',
    debitmainacc: 4,
    debitacc: 1150,
    creditmainacc: 2,
    creditacc: 1100,
  },
  {
    id: 6,
    value: operationTypes.expenses,
    name: 'Expenses Cash',
    nameAr: 'مصروفات نقدية',
    debitmainacc: 15,
    debitacc: 6000,
    creditmainacc: 1,
    creditacc: 1000,
  },
  {
    id: 7,
    value: operationTypes.expensesCredit,
    name: 'Expenses Credit',
    nameAr: 'مصروفات آجلة',
    debitmainacc: 15,
    debitacc: 6000,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 8,
    value: operationTypes.expensesSalary,
    name: 'Accrued Salary',
    nameAr: 'رواتب مستحقة',
    debitmainacc: 15,
    debitacc: 6100,
    creditmainacc: 8,
    creditacc: 2100,
  },
  {
    id: 9,
    value: operationTypes.advancedRent,
    name: 'Advanced Rent',
    nameAr: 'ايجارات مقدمة',
    debitmainacc: 4,
    debitacc: 1370,
    creditmainacc: 15,
    creditacc: 6200,
  },
  {
    id: 10,
    value: operationTypes.purchaseInvoice,
    name: 'Purchase Invoices Cash',
    nameAr: 'فواتير شراء نقدا',
    debitmainacc: 14,
    debitacc: 5050,
    creditmainacc: 1,
    creditacc: 1000,
  },
  {
    id: 11,
    value: operationTypes.purchaseInvoiceCredit,
    name: 'Purchase Invoices Credit',
    nameAr: 'فواتير شراء آجلة',
    debitmainacc: 14,
    debitacc: 5000,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 12,
    value: operationTypes.purchaseInvoiceStock,
    name: 'Purchase Invoices Stock',
    nameAr: 'فواتير شراء مخزنية نقدا',
    debitmainacc: 3,
    debitacc: 1200,
    creditmainacc: 1,
    creditacc: 1000,
  },
  {
    id: 13,
    value: operationTypes.purchaseInvoiceCreditStock,
    name: 'Purchase Invoices Credit Stock',
    nameAr: 'فواتير شراء مخزنية آجلة',
    debitmainacc: 3,
    debitacc: 1200,
    creditmainacc: 7,
    creditacc: 2000,
  },
  {
    id: 14,
    value: operationTypes.supplierPayemnt,
    name: 'Payemnts',
    nameAr: 'سندات دفع',
    debitmainacc: 7,
    debitacc: 2000,
    creditmainacc: 1,
    creditacc: 1000,
  },
  {
    id: 15,
    value: operationTypes.supplierPayemntCheque,
    name: 'Payemnts Cheque',
    nameAr: 'سندات دفع شيكات',
    debitmainacc: 7,
    debitacc: 2000,
    creditmainacc: 8,
    creditacc: 2150,
  },
  {
    id: 16,
    value: operationTypes.productsCost,
    name: 'Products Cost',
    nameAr: 'تكلفة المنتجات المباعة',
    debitmainacc: 14,
    debitacc: 5000,
    creditmainacc: 3,
    creditacc: 1200,
  },
  {
    id: 17,
    value: operationTypes.expproducts,
    name: 'Expenses Products',
    nameAr: 'استهلاك المنتجات',
    debitmainacc: 14,
    debitacc: 5060,
    creditmainacc: 3,
    creditacc: 1200,
  },
  {
    id: 18,
    value: operationTypes.cashDeposet,
    name: 'Cash Bank Deposet',
    nameAr: 'ايداع نقدي في البنك',
    debitmainacc: 1,
    debitacc: 1020,
    creditmainacc: 1,
    creditacc: 1000,
  },
  {
    id: 19,
    value: operationTypes.cashDeposetCard,
    name: 'Card Bank Deposet',
    nameAr: 'ايداع بطاقة في البنك',
    debitmainacc: 1,
    debitacc: 1020,
    creditmainacc: 1,
    creditacc: 1010,
  },
  {
    id: 20,
    value: operationTypes.cashDeposetCheque,
    name: 'Cheque Bank Deposet',
    nameAr: 'ايداع شيك في البنك',
    debitmainacc: 1,
    debitacc: 1020,
    creditmainacc: 4,
    creditacc: 1150,
  },
  {
    id: 21,
    value: operationTypes.chequeDraw,
    name: 'Cheque Bank Draw',
    nameAr: 'سحب شيك من البنك',
    debitmainacc: 8,
    debitacc: 2150,
    creditmainacc: 1,
    creditacc: 1020,
  },
  {
    id: 22,
    value: operationTypes.cashDraw,
    name: 'Cash Bank Draw',
    nameAr: 'سحب من البنك',
    debitmainacc: 1,
    debitacc: 1000,
    creditmainacc: 1,
    creditacc: 1020,
  },
  {
    id: 23,
    value: operationTypes.ownerDeposit,
    name: 'Partner Deposet',
    nameAr: 'ايداع الشريك',
    debitmainacc: 1,
    debitacc: 1020,
    creditmainacc: 8,
    creditacc: 2800,
  },
  {
    id: 24,
    value: operationTypes.ownerDraw,
    name: 'Partner Draw',
    nameAr: 'سحب الشريك',
    debitmainacc: 8,
    debitacc: 2800,
    creditmainacc: 1,
    creditacc: 1020,
  },
  {
    id: 25,
    value: operationTypes.kaid,
    name: 'Jornal Vaucher',
    nameAr: 'قيود اليومية',
    debitmainacc: null,
    debitacc: null,
    creditmainacc: null,
    creditacc: null,
  },
];

export const fieldsNames = [
  'time', //Date
  'title', //String
  'desc', //String
  'showOnCal', //Boolean
  'id', //Int
  'startDate', //Date
  'endDate', //Date
  'priority', //Int
  'status', //Int
  'locationLink', //String
  'address', //String
  'houseno', //String
  'periodfrom', //Date
  'periodto', //Date
  'item', //Item
  'customer', //Customer
  'supplier', //supplier
  'contact', //contact
  'department', //department
  'employee', //employee
  'project', //project
  'contract', //contract
  'resourse', //resourse
  'retype', //retype
  'retype2', //retype2
  'bonos', //Float
  'overtime', //Float
  'absent', //Boolean
  'discount', //Float
  'amount', //Float
  'chequeBank', //String
  'chequeNo', //String
  'chequeDate', //String
  'note', //String
  'signatureName', //String
  'signatureImage', //String
  'items',
];

export const sectionTypes = [
  {
    id: 1,
    value: 1,
    name: 'Managment',
    nameAr: 'اداري',
  },
  {
    id: 2,
    value: 2,
    name: 'Operational',
    nameAr: 'فني',
  },
];

export const priorityOptions = [
  {
    id: 1,
    nameAr: 'عادي',
    name: 'Low Priority',
    color: '#888',
    value: 1,
  },
  {
    id: 2,
    nameAr: 'مهم',
    name: 'Medium Priority',
    color: '#800080',
    value: 2,
  },
  {
    id: 3,
    nameAr: 'مهم جداً',
    name: 'High Priority',
    color: '#ff4040',
    value: 3,
  },
];
