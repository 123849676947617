/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Categories from './Categories';
import Products from './Products';

const CAT_HIGHT = 70;

export default function ProductsBody({
  height,
  width,
  prodcats,
  products,
  isRTL,
  theme,
  selected,
}) {
  const [cat, setCat] = useState(null);
  const [prods, setProds] = useState([]);
  let bodyheight = height;
  if (prodcats) bodyheight = bodyheight - CAT_HIGHT;

  useEffect(() => {
    setCat(null);
  }, [selected]);

  useEffect(() => {
    if (prodcats?.[0]) {
      setCat(prodcats?.[0]);
    }
  }, [prodcats, selected]);

  useEffect(() => {
    const prds = products.filter((p: any) => p.categoryId === cat?._id);
    if (prds) {
      setProds(prds);
    } else {
      setProds([]);
    }
  }, [cat, selected]);

  return (
    <Box style={{ height }}>
      {prodcats && (
        <Categories
          categories={prodcats}
          bgcolor={undefined}
          height={70}
          cat={cat}
          setCat={setCat}
          width={width}
          isRTL={isRTL}
          theme={theme}
        ></Categories>
      )}
      <Products
        theme={theme}
        isRTL={isRTL}
        prods={prods}
        height={bodyheight}
      ></Products>
    </Box>
  );
}
