import { gql } from '@apollo/client';

export default gql`
  mutation updateFoundation(
    $_id: String
    $name: String
    $nameAr: String
    $logo: String
    $tel1: String
    $tel2: String
    $fax: String
    $mob: String
    $email: String
    $website: String
    $address: String
    $poBox: String
    $city: String
    $country: String
    $note: String
  ) {
    updateFoundation(
      _id: $_id
      name: $name
      nameAr: $nameAr
      logo: $logo
      tel1: $tel1
      tel2: $tel2
      fax: $fax
      mob: $mob
      email: $email
      website: $website
      address: $address
      poBox: $poBox
      city: $city
      country: $country
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
