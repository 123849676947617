import { gql } from '@apollo/client';

export default gql`
  query getFormOperations(
    $opType: Int
    $dcategoryId: String
    $departmentId: String
    $employeeId: String
    $projectId: String
    $resourseId: String
    $customerId: String
    $contractId: String
    $contactId: String
    $status: Int
    $start: Date
    $end: Date
  ) {
    getFormOperations(
      opType: $opType
      dcategoryId: $dcategoryId
      departmentId: $departmentId
      employeeId: $employeeId
      projectId: $projectId
      resourseId: $resourseId
      customerId: $customerId
      contractId: $contractId
      contactId: $contactId
      status: $status
      start: $start
      end: $end
    ) {
      ok
      error
      data {
        _id
        id
        time
        opType

        branch
        title
        desc
        startDate
        endDate
        allDay
        rRule
        reminder
        exDate
        allowDrag
        groupId
        locationLink
        address
        houseno
        autoNo
        docNo
        temp
        priority
        amount
        status
        nots
        activenots
        smss
        activesmss

        bonos
        overtime
        absent
        cashin
        teamMembers

        customerId
        customerName
        customerNameAr
        customerPhone

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone

        projectId
        projectName
        projectNameAr
        projectColor
        resourseId
        resourseName
        resourseNameAr
        resourseColor

        contractId
        contractName
        contractNameAr

        contactId
        contactName
        contactNameAr

        retypeId
        retypeName
        retypeNameAr
        retypeColor

        retype2Id
        retype2Name
        retype2NameAr
        retype2Color

        dcategoryId
        dcategoryName
        dcategoryNameAr
        gref

        invoiceId
        link
        sent
        paid
        invoiced
        procs

        signatureName
        signatureImage

        createUser
        activeUser

        refNo
        refType
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
