import { Paper } from '@material-ui/core';
import { useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

function ImageViewList({ images, height, width, big, small }: any) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const refsize = 60;
  if (!images || images.length === 0) {
    return <Paper style={{ height, width, overflow: 'auto' }}></Paper>;
  }
  return (
    <div style={{ height, width, overflow: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {images.map((src: any, index: any) => (
          <img
            src={src}
            onClick={() => openImageViewer(index)}
            width={refsize}
            height={refsize}
            key={index}
            style={{
              padding: big ? 10 : 5,
              overflow: 'hidden',
              objectFit: 'cover',
              cursor: 'pointer',
            }}
            alt=""
          />
        ))}

        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.7)',
              zIndex: 150,
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </div>
  );
}

export default ImageViewList;
