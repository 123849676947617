import { gql } from '@apollo/client';

export default gql`
  mutation createFormOperation(
    $branch: String
    $docNo: String
    $prefix: String
    $time: Date
    $title: String
    $desc: String
    $periodfrom: Date
    $periodto: Date
    $isMonthly: Boolean
    $customer: CustomerInput
    $department: DepartmentInput
    $employee: EmployeeInput
    $project: ProjectInput
    $contract: ContractInput
    $contact: ContactInput
    $resourse: ResourseInput
    $dcategoryId: String
    $dcategoryName: String
    $dcategoryNameAr: String
    $gref: String
    $items: String
    $amount: Float
    $userId: String
    $note: String
    $opType: Int
    $autoNoType: String
    $autoNoPrefix: String
    $signatureName: String
    $signatureImage: String
  ) {
    createFormOperation(
      branch: $branch
      docNo: $docNo
      prefix: $prefix
      time: $time
      title: $title
      desc: $desc
      periodfrom: $periodfrom
      periodto: $periodto
      isMonthly: $isMonthly
      customer: $customer
      department: $department
      employee: $employee
      project: $project
      contract: $contract
      contact: $contact
      resourse: $resourse
      dcategoryId: $dcategoryId
      dcategoryName: $dcategoryName
      dcategoryNameAr: $dcategoryNameAr
      gref: $gref
      items: $items
      amount: $amount
      userId: $userId
      note: $note
      opType: $opType
      autoNoType: $autoNoType
      autoNoPrefix: $autoNoPrefix
      signatureName: $signatureName
      signatureImage: $signatureImage
    ) {
      ok
      message
      data
      error
    }
  }
`;
