import DatePicker from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import ar from 'date-fns/locale/ar-SA';
import { Box, Typography } from '@material-ui/core';

const CalenderKeyboard = ({ mt = 15, label, value, onChange, isRTL }: any) => {
  return (
    <Box style={{ marginTop: mt }}>
      {label && (
        <Typography
          style={{ marginLeft: 10, marginRight: 10, marginBottom: 3 }}
        >
          {label} dd/mm/yyyy
        </Typography>
      )}
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={onChange}
        locale={isRTL ? ar : en}
        dateFormat={'dd/MM/yyyy'}
        calendarStartDay={6}
      />
    </Box>
  );
};

export default CalenderKeyboard;
