import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { moneyFormat, qtyFormat } from '../common/helpers';

export default function PrintTable({
  value,
  classnames,
  classes,
  style,
  rows,
  columns,
  data,
  addTotal,
  isPOS,
  isRTL,
}: any) {
  const clss = classnames?.map((cn: any) => classes?.cn);
  const className = clss.join(' ');
  const { amount, discount, total } = data;
  const nrows = rows?.map((row: any, index: any) => {
    const col = columns?.[index];
    return {
      ...row,
      width: col?.width,
      align: col?.align,
    };
  });
  const cols = columns?.length;
  const percentage = discount && discount > 0 ? (discount * 100) / total : null;
  return (
    <TableContainer className={className} style={style}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow style={{ backgroundColor: isPOS ? undefined : '#ddd' }}>
            {columns?.map((col: any) => {
              const { id, value, width, height, bold, size, color } = col;
              return (
                <TableCell
                  key={id}
                  style={{
                    fontWeight: bold ? 'bold' : undefined,
                    fontSize: size ? Number(size) : 16,
                    color,
                    margin: isPOS ? 0 : undefined,
                    padding: isPOS ? 7 : undefined,
                    fontFamily: 'Arial',
                    wordWrap: 'normal',
                    letterSpacing: 'normal',
                  }}
                  variant="head"
                  height={Number(height)}
                  width={Number(width)}
                  align={'center'}
                >
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {nrows?.map((row: any) => {
            return (
              <TableRow
                style={{
                  backgroundColor: isPOS ? undefined : '#f7f7f7',
                }}
              >
                {columns?.map((col: any) => {
                  const {
                    name,
                    width,
                    align,
                    money,
                    qty,
                    itemheight,
                    itemsize,
                    color,
                  } = col;
                  return (
                    <TableCell
                      style={{
                        fontWeight: isPOS ? 'bold' : undefined,
                        fontSize: itemsize ? Number(itemsize) : 16,
                        color,
                        margin: isPOS ? 0 : undefined,
                        padding: isPOS ? 4 : undefined,
                        fontFamily: 'Arial',
                        wordWrap: 'normal',
                        letterSpacing: 'normal',
                      }}
                      height={Number(itemheight)}
                      width={Number(width)}
                      align={
                        isRTL ? align : align === 'left' ? 'right' : 'left'
                      }
                    >
                      {money
                        ? moneyFormat(row?.[name])
                        : qty
                        ? qtyFormat(row?.[name])
                        : row?.[name]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {addTotal && (
        <Table
          style={{ breakInside: 'avoid' }}
          size="small"
          aria-label="a dense table"
        >
          <TableBody>
            {!isPOS && (
              <TableRow
                style={{
                  height: 35,
                  backgroundColor: '#f7f7f7',
                }}
              >
                <TableCell colSpan={cols} />
              </TableRow>
            )}
            <TableRow
              style={{
                height: isPOS ? 30 : 40,
                backgroundColor: isPOS ? undefined : '#f7f7f7',
              }}
            >
              <TableCell />
              <TableCell
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  wordWrap: 'normal',
                  letterSpacing: 'normal',
                  color: '#000000',
                }}
                align={isRTL ? 'left' : 'right'}
                colSpan={cols - 2}
              >
                Subtotal المجموع
              </TableCell>
              <TableCell
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
                align={isRTL ? 'left' : 'right'}
              >
                {moneyFormat(total)}
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                height: isPOS ? 30 : 40,
                backgroundColor: isPOS ? undefined : '#f7f7f7',
              }}
            >
              <TableCell />
              <TableCell
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  wordWrap: 'normal',
                  letterSpacing: 'normal',
                  color: '#000000',
                }}
                align={isRTL ? 'left' : 'right'}
                colSpan={cols - 2}
              >
                {percentage && percentage > 0
                  ? `(${moneyFormat(percentage)}%) Discount الحسم`
                  : 'Discount الحسم'}
              </TableCell>
              <TableCell
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
                align={isRTL ? 'left' : 'right'}
              >
                {moneyFormat(discount)}
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                height: isPOS ? 30 : 40,
                backgroundColor: isPOS ? undefined : '#ddd',
              }}
            >
              <TableCell />
              <TableCell
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  wordWrap: 'normal',
                  letterSpacing: 'normal',
                  color: '#000000',
                }}
                colSpan={isPOS ? cols - 2 : cols - 2}
                align={isRTL ? 'left' : 'right'}
              >
                Net Amount الصافي
              </TableCell>
              <TableCell
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                  color: '#000000',
                }}
                align={isRTL ? 'left' : 'right'}
              >
                {moneyFormat(amount)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
