import { gql } from '@apollo/client';

export default gql`
  query getSuppliersRaseeds($start: Date, $end: Date) {
    getSuppliersRaseeds(start: $start, end: $end) {
      ok
      error
      data
      message
    }
  }
`;
