/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  yup,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Box, Grid } from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { TextFieldLocal } from '../components';
import FilterSelectSingle from '../Shared/FilterSelectSingle';
import { msgTypes } from '../constants/datatypes';
import CopyClipBoard from '../common/CopyClipBoard';

const PopupMSGTemplate = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  editAction,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [msgtype, setMsgtype] = useState(msgTypes?.[0]);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [smsqty, setSmsqty] = useState(0);
  const isSMS = msgtype?.id === 1;
  const isEmail = msgtype?.id === 2;

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { register, handleSubmit, errors, reset } = useForm(yup.groupResolver);
  const {
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);

  useEffect(() => {
    const smss = Math.ceil(body.length / 70);
    setSmsqty(smss);
  }, [body]);

  useEffect(() => {
    if (row?._id) {
      if (row?.msgtype) {
        const mt = msgTypes.filter((m: any) => m.id === row?.msgtype);
        if (mt && mt.length > 0) setMsgtype(mt[0]);
      }
      setSubject(row?.subject);
      if (row?.body) {
        setBody(row?.body);
      }
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data.name.trim();
    const rbody = body;
    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      name,
      nameAr: name,
      msgtype: msgtype?.id,
      subject,
      body: rbody,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createMSGTemplate' : 'updateMSGTemplate';
    apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem, 'group');
      closeModal();
      await successAlert(setAlrt, isRTL);
      onClose();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const closeModal = () => {
    onClose();
    reset();
    setSaving(false);
    setSmsqty(0);
    setSubject('');
    setBody('');
    setMsgtype(msgTypes?.[0]);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const title = isRTL
    ? isNew
      ? 'مجموعة جديدة'
      : 'تعديل مجموعة'
    : isNew
    ? 'New MSGTemplate'
    : 'Edit MSGTemplate';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      maxWidth="md"
      fullWidth
    >
      <Grid container spacing={2} style={{ paddingLeft: 20 }}>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextFieldLocal
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={4}>
              <FilterSelectSingle
                options={msgTypes}
                value={msgtype}
                setValue={setMsgtype}
                words={words}
                isRTL={isRTL}
                name="msgtype"
              ></FilterSelectSingle>
            </Grid>
            {msgtype?.id === 2 && (
              <Grid item xs={12}>
                <TextFieldLocal
                  name="subject"
                  label={isRTL ? 'العنوان' : 'Subject'}
                  value={subject}
                  onChange={(e: any) => setSubject(e.target.value)}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box
                display={'flex'}
                style={{ alignItems: 'center', justifyContent: 'space-around' }}
              >
                <CopyClipBoard text="{name}"></CopyClipBoard>
                <CopyClipBoard text="{nameAr}"></CopyClipBoard>
                <CopyClipBoard text="{date}"></CopyClipBoard>
                <CopyClipBoard text="{time}"></CopyClipBoard>
              </Box>
            </Grid>

            <Grid item xs={12}>
              {!isEmail && (
                <>
                  <TextFieldLocal
                    name="body"
                    label={isRTL ? 'نص الرسالة' : 'Message'}
                    value={body}
                    onChange={(e: any) => setBody(e.target.value)}
                    row={row}
                    multiline
                    rows={4}
                    fullWidth
                    mb={0}
                  />
                  {isSMS && `SMSs: (${smsqty})`}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupMSGTemplate;
