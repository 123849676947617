/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, fade, Paper, Typography, withStyles } from '@material-ui/core';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import { CommandSmall } from './CommandSmall';
import { Getter } from '@devexpress/dx-react-core';
import PopupEditing from './PopupEditing';
import PopupItem from '../pubups/PopupItem';
import { getColumns } from '../common/columns';
import { useTemplate } from '../hooks';
// import { Command, PopupEditing } from "../../Shared";
export const getRowId = (row: any) => row.index;

const CurrencyFormatter = ({ value }) => (
  <Box
    display="flex"
    style={{
      flex: 1,
      alignItems: 'center',
      justifyContent: 'flex-end',
    }}
  >
    <Typography variant="subtitle2">
      {value ? value.toLocaleString('en-QA') : ''}
    </Typography>
  </Box>
);

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);
const NumberTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <Typography variant="subtitle2">{Number(value) + 1}</Typography>
    )}
    {...props}
  />
);

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function ExpensesItemsTable({
  rows,
  removeItem,
  editItem,
  isRTL,
  words,
  user,
  height = 250,
  products,
  itemrelations,
}: any) {
  const col = getColumns({ isRTL, words });
  const { tempoptions } = useTemplate();
  const [columns] = useState(
    [
      { name: 'index', title: words.no },
      { name: isRTL ? 'nameAr' : 'name', title: words.expenses },
      tempoptions?.itemdescription && {
        name: 'note',
        title: words.description,
      },
      tempoptions?.contract && itemrelations && col.contract,
      tempoptions?.department && itemrelations && col.department,
      tempoptions?.employee && itemrelations && col.employee,
      tempoptions?.resourse && itemrelations && col.resourse,
      { name: 'itemqty', title: words.qty },
      { name: 'itemprice', title: words.cost },
      { name: 'itemtotal', title: words.total },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'index', width: '10%', align: 'left' },
    { columnName: 'name', width: 150 },
    { columnName: 'note', width: 'auto' },
    { columnName: col.contract.name, align: 'left' },
    { columnName: col.department.name, align: 'left' },
    { columnName: col.employee.name, align: 'left' },
    { columnName: col.resourse.name, align: 'left' },
    { columnName: 'itemqty', width: '10%', align: 'right' },
    { columnName: 'itemprice', width: '10%', align: 'right' },
    { columnName: 'itemtotal', width: '10%', align: 'right' },
  ]);

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const index = deleted[0];
      removeItem(index);
    }
  };

  const renderEmpty = () => {
    return <Box display="flex"></Box>;
  };

  const addAction = () => {
    //
  };

  return (
    <Paper
      style={{
        maxHeight: height,
        overflow: 'auto',
        margin: 10,
        minHeight: height,
      }}
    >
      {rows && (
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <Table
            noDataCellComponent={renderEmpty}
            columnExtensions={tableColumnExtensions}
            tableComponent={TableComponent}
          />
          <CurrencyTypeProvider for={['itemtotal', 'itemqty', 'itemprice']} />
          <NumberTypeProvider for={['index']} />
          <TableHeaderRow
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableEditColumn
            showDeleteCommand
            showEditCommand
            commandComponent={CommandSmall}
          ></TableEditColumn>
          <PopupEditing addAction={addAction} editAction={editItem}>
            <PopupItem
              products={products}
              user={user}
              isRTL={isRTL}
              words={words}
              itemrelations={itemrelations}
            ></PopupItem>
          </PopupEditing>
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                {
                  key: 'editCommand',
                  type: TableEditColumn.COLUMN_TYPE,
                  width: 120,
                },
                ...tableColumns.filter(
                  (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
              ];
              return result;
            }}
          />
        </Grid>
      )}
    </Paper>
  );
}
