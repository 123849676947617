import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Box, Button, Typography } from '@material-ui/core';

function Signature({ signature, setSignature, isRTL }: any) {
  const signatureRef: any = useRef();
  const clearSignature = () => {
    signatureRef?.current.clear();
  };

  const width = 400;
  const height = 200;

  const saveSignature = () => {
    const sign = signatureRef?.current
      // ?.getTrimmedCanvas()
      ?.toDataURL('image/png');
    setSignature(sign);
  };

  return (
    <div>
      <Typography variant="h6">{isRTL ? 'التوقيع' : 'Signature'}</Typography>
      {signature && (
        <Box
          border={1}
          borderRadius={10}
          borderColor="#ddd"
          style={{ width, height }}
          marginTop={1}
        >
          <div
            onClick={() => setSignature(null)}
            style={{
              position: 'relative',
              zIndex: 115,
              marginLeft: 10,
              cursor: 'pointer',
            }}
          >
            <HighlightOffIcon style={{ color: '#ff9d2d' }}></HighlightOffIcon>
          </div>
          <img
            alt="signature"
            style={{
              overflow: 'hidden',
              borderRadius: 5,
              cursor: 'pointer',
              objectFit: 'cover',
            }}
            width={width}
            height={height}
            src={signature}
          />
        </Box>
      )}
      {!signature && (
        <>
          <Box
            border={1}
            borderRadius={10}
            borderColor="#ddd"
            marginTop={1}
            style={{ width, height, backgroundColor: '#eee' }}
          >
            <SignatureCanvas
              ref={signatureRef}
              penColor="darkblue"
              velocityFilterWeight={0.7}
              minWidth={2}
              maxWidth={3}
              minDistance={1}
              canvasProps={{ width, height, className: 'sigCanvas' }}
            />
          </Box>
          <Box style={{ display: 'flex', marginTop: 7 }}>
            <Button variant="outlined" onClick={saveSignature}>
              <Typography style={{ fontSize: 12 }}>
                {isRTL ? 'تأكيد' : 'Confirm'}
              </Typography>
            </Button>
            <div style={{ width: 10 }}></div>
            <Button variant="outlined" onClick={clearSignature}>
              <Typography style={{ fontSize: 12 }}>
                {isRTL ? 'مسح' : 'Clear'}
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </div>
  );
}

export default Signature;
