/* eslint-disable no-var */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext, useEffect, useReducer, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, CssBaseline } from '@material-ui/core';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppDrawer } from '../../components';
import { mainmenu } from '../../constants';
import {
  EventsContext,
  ExpensesReportContext,
  initExpensesReportContext,
  expensesReportReducer,
  CustomerReportContext,
  initCustomerReportContext,
  customerReportReducer,
  FinanceContext,
  financeReducer,
  GlobalContext,
  initFinanceContext,
  initPurchaseReportContext,
  initSalesReportContext,
  PurchaseReportContext,
  purchaseReportReducer,
  SalesReportContext,
  salesReportReducer,
  receiptReducer,
  initReceiptContext,
  ReceiptContext,
  CalendarContext,
  initEventsContext,
  eventsReducer,
  initSalesContext,
  salesReducer,
  SalesContext,
  initPurchaseContext,
  purchaseReducer,
  PurchaseContext,
  expensesReducer,
  initExpensesContext,
  ExpensesContext,
} from '../../contexts';
import { layoutClasses } from '../../themes';
import Options from '../options';
import {
  Users,
  Services,
  Accounts,
  Products,
  Categories,
  Brands,
  Invoices,
  Customers,
} from '../adds';
import { GContextTypes } from '../../types';
import Finance from '../adds/Finance';

import { useBranches, useSuppliers, useTemplate, useUsers } from '../../hooks';
import PageLayout from './PageLayout';
import { initCalendar, calendarReducer } from '../../contexts';
import SalesReport from '../reports/SalesReport';
import useAccounts from '../../hooks/useAccounts';
import FinanceReport from '../reports/FinanceReport';
import {
  eventsReportReducer,
  initEventsReportContext,
} from '../../contexts/eventsreport/salesReducer';
import EventsReport from '../reports/EventsReport';
import CustomerReport from '../reports/CustomerReport';
import EventsReportContext from '../../contexts/eventsreport';
import { filterMenu, getparentAccountsNames } from '../../common/helpers';
import PurchaseReport from '../reports/PurchaseReport';
import ExpensesReport from '../reports/ExpensesReport';
import DocumentsReport from '../reports/DocumentsReport';
import {
  documentsReportReducer,
  initDocumentsReportContext,
} from '../../contexts/documentsReport/documentsReducer';
import DocumentsReportContext from '../../contexts/documentsReport';
import ServicesReportContext from '../../contexts/servicesReport';
import ServicesReport from '../reports/ServicesReport';
import {
  initServicesReportContext,
  servicesReportReducer,
} from '../../contexts/servicesReport/servicesReducer';
import ManageEmployees from '../adds/ManageEmployees';
import ManageDepartments from '../adds/ManageDepartments';
import ManageResourses from '../adds/ManageResourses';
import Branches from '../adds/Branches';
import React from 'react';
import AlertWithClose from '../../components/fields/AlertWithClose';
import ManageProjects from '../adds/ManageProjects';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useCompany from '../../hooks/useCompany';
import Contacts from '../adds/Contacts';
import Groups from '../adds/Groups';
import Sendreqs from '../adds/Sendreqs';
import Messages from '../adds/Messages';
import Notifications from '../adds/Notifications';
import FinanceAllKaid from '../adds/FinanceAllKaid';
import ProfitReport from '../reports/ProfitReport';
import BudgetReport from '../reports/BudgetReport';
import ExpenseItems from '../adds/ExpenseItems';
import {
  initSupplierReportContext,
  supplierReportReducer,
} from '../../contexts/supplierReport/customerReportReducer';
import StockItems from '../adds/StockItems';
import ProductsReport from '../reports/ProductsReport';
import {
  DepartmentContext,
  departmentReducer,
  EmployeeContext,
  employeeReducer,
  initDepartmentContext,
  initEmployeeContext,
  initResourseContext,
  ResourseContext,
  resourseReducer,
  ProjectContext,
  initProjectContext,
  projectReducer,
  kaidReducer,
  initKaidContext,
  KaidContext,
  ProfitabilityContext,
  profitabilityReducer,
  initProfitabilityContext,
  initCustomerContext,
  customerReducer,
  CustomerContext,
} from '../../contexts/managment';
import KaidsReport from '../reports/KaidsReport';
import ProfitabilitysReport from '../reports/ProfitabilityReport';
import ResourseTypes from '../adds/ResourseTypes';
import ReceiptAdvance from '../adds/ReceiptAdvance';
import PaymentAdvance from '../adds/PaymentAdvance';
import {
  ContractsReportContext,
  contractsReportReducer,
  initContractsReportContext,
} from '../../contexts/managment/contractsreport';
import ContractsReport from '../reports/ContractsReport';
import EventsItemsReport from '../reports/EventsItemsReport';
import MSGTemplates from '../adds/MSGTemplates';
import { getStoreItem, setStoreItem } from '../../store';
import {
  initProductContext,
  ProductContext,
  productReducer,
} from '../../contexts/managment/product';
import ExpProductReport from '../reports/ExpProductReport';
import { useLazyQuery, useSubscription } from '@apollo/client';
import {
  eventChange,
  eventStopChange,
  mailAdded,
  mailUpdated,
  notifiAdded,
} from '../../graphql/subscription';
import AdminLanding from './AdminLanding';
// import FormOperations from '../adds/FormOperations';
import PCategories from '../adds/PCategories';
import ProcItems from '../adds/ProcItems';
import CustomerAllReport from '../reports/CustomerAllReport';
import SupplierReportNew from '../reports/SupplierReportNew';
import SupplierReportContext from '../../contexts/supplierReport';
import SupplierAllReport from '../reports/SupplierAllReport';
import LetterOperations from '../../Sections/Letters/LetterOperations';
import FormDocs from '../../Sections/Forms/FormDocs';
import PresalesOperations from '../../Sections/Presales/PresalesOperations';
import OperationOperations from '../../Sections/Operations/OperationOperations';
import {
  addsList,
  expensesList,
  financeList,
  jadwalList,
  mailList,
  operationsList,
  purchaseList,
  reminderList,
  reportsList,
  salesList,
} from '../../constants/menu';
import SalesOperations from '../../Sections/Sales/SalesOperations';
import PurchaseOperations from '../../Sections/Purchase/PurchaseOperations';
import ExpensesOperations from '../../Sections/Expenses/ExpensesOperations';
import FinanceOperations from '../../Sections/Finance/FinanceOperations';
import BasicsAdd from '../../Sections/Basics/BasicsAdd';
import MailOperations from '../../Sections/Mail/MailOperations';
import ReminderOperations from '../../Sections/Reminder/ReminderOperations';
import CalendarOperations from '../../Sections/Calendar/CalendarOperations';
import LandingOperations from '../../Sections/Landing/LandingOperations';
import ReportsList from '../../Sections/reports/ReportsList';
import getMyInbox from '../../graphql/query/getMyInbox';
import CalendarEmp from '../calendar/CalendarEmp';
import Appointments from '../adds/Appointments';
import MainPOS from '../../Sections/POS/MainPOS';
import JadwalOperations from '../../Sections/Jadwal/JadwalOperations';

const Content = ({ formdocs, isEmployee }: any) => {
  const classes = layoutClasses();
  const [mmenu, setMmenu] = useState(null);
  const [menuitem, setMenuitem] = useState(mainmenu[0]);
  const [evenetOpened, setEvenetOpened] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(80);
  const [open, setOpen] = React.useState(false);
  const [newin, setNewin] = useState(0);

  const theme = useTheme();
  const location = useLocation();
  const pathname = location?.pathname;
  const { tempoptions, tempwords } = useTemplate();
  const { allusers } = useUsers();
  const fusers = allusers
    .map((usr: any) => {
      const name = usr?.name ? usr?.name : usr?.username;
      if (usr?.isSuperAdmin) return undefined;
      return {
        ...usr,
        name,
        nameAr: name,
      };
    })
    ?.filter((x: any) => x);

  useEffect(() => {
    if (pathname && pathname.length > 0) {
      if (pathname === '/') {
        setMenuitem(mainmenu[0]);
      } else {
        const pname = pathname?.split('/')?.[1];
        const mitem = mainmenu?.filter(
          (mi: any) => mi.uri === `/${pname}`
        )?.[0];
        if (mitem && mitem !== menuitem) {
          setMenuitem(mitem);
        }
      }
    }
  }, [pathname]);

  // const navigate = useNavigate();
  // const location = useLocation();
  // const autoNoType = pathname?.split('-')?.[1];
  // const formdoc = formdocs?.filter(
  //   (fd: any) => fd?.autoNoType === autoNoType
  // )?.[0];
  const letterforms = formdocs
    ?.filter((fd: any) => fd?.gref === 'letter')
    ?.map((it: any) => {
      return {
        ...it,
        path: it?.autoNoType,
      };
    });
  const presalesforms = formdocs
    ?.filter((fd: any) => fd?.gref === 'presales')
    ?.map((it: any) => {
      return {
        ...it,
        path: it?.autoNoType,
      };
    });
  const { company, editCompany, refreshcompany } = useCompany();
  const { branches } = useBranches();
  const { suppliers } = useSuppliers();
  const { accounts, refreshAccount } = useAccounts();
  const {
    store: {
      user,
      calendar,
      network,
      packIssue,
      packIssueMsg,
      notify,
      tempId,
      scrollbar,
    },
    dispatch,
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);
  const { isMobile } = useWindowDimensions();

  const logout = () => {
    dispatch({ type: 'logout' });
  };
  const isBranchsAdmin = user?.isBranchsAdmin;
  // products
  const productStoreState = getStoreItem('productStore', initProductContext);
  const [productStore, productDispatch] = useReducer(
    productReducer,
    productStoreState ? productStoreState : initProductContext
  );
  useEffect(() => {
    setStoreItem('productStore', productStore);
  }, [productStore]);

  // employees
  const employeeStoreState = getStoreItem('employeeStore', initEmployeeContext);
  const [employeeStore, employeeDispatch] = useReducer(
    employeeReducer,
    employeeStoreState ? employeeStoreState : initEmployeeContext
  );
  useEffect(() => {
    setStoreItem('employeeStore', employeeStore);
  }, [employeeStore]);

  // resourses
  const resourseStoreState = getStoreItem('resourseStore', initResourseContext);
  const [resourseStore, resourseDispatch] = useReducer(
    resourseReducer,
    resourseStoreState ? resourseStoreState : initResourseContext
  );
  useEffect(() => {
    setStoreItem('resourseStore', resourseStore);
  }, [resourseStore]);

  // departments
  const departmentStoreState = getStoreItem(
    'departmentStore',
    initDepartmentContext
  );
  const [departmentStore, departmentDispatch] = useReducer(
    departmentReducer,
    departmentStoreState ? departmentStoreState : initDepartmentContext
  );
  useEffect(() => {
    setStoreItem('departmentStore', departmentStore);
  }, [departmentStore]);

  const purchaseStoreState = getStoreItem('purchaseStore', initPurchaseContext);
  const [purchaseStore, purchaseDispatch] = useReducer(
    purchaseReducer,
    purchaseStoreState ? purchaseStoreState : initPurchaseContext
  );
  useEffect(() => {
    setStoreItem('purchaseStore', purchaseStore);
  }, [purchaseStore]);

  // expenses
  const expensesStoreState = getStoreItem('expensesStore', initExpensesContext);
  const [expensesStore, expensesDispatch] = useReducer(
    expensesReducer,
    expensesStoreState ? expensesStoreState : initExpensesContext
  );
  useEffect(() => {
    setStoreItem('expensesStore', expensesStore);
  }, [expensesStore]);

  const receiptStoreState = getStoreItem('receiptStore', initReceiptContext);
  const [receiptStore, receiptDispatch] = useReducer(
    receiptReducer,
    receiptStoreState ? receiptStoreState : initReceiptContext
  );
  useEffect(() => {
    setStoreItem('receiptStore', receiptStore);
  }, [receiptStore]);

  // events
  const eventsStoreState = getStoreItem('eventsStore', initEventsContext);
  const [eventsStore, eventsDispatch] = useReducer(
    eventsReducer,
    eventsStoreState ? eventsStoreState : initEventsContext
  );
  useEffect(() => {
    setStoreItem('eventsStore', eventsStore);
  }, [eventsStore]);

  // sales
  const salesStoreState = getStoreItem('salesStore', initSalesContext);
  const [salesStore, salesDispatch] = useReducer(
    salesReducer,
    salesStoreState ? salesStoreState : initSalesContext
  );
  useEffect(() => {
    setStoreItem('salesStore', salesStore);
  }, [salesStore]);

  // customers
  const customerStoreState = getStoreItem('customerStore', initCustomerContext);
  const [customerStore, customerDispatch] = useReducer(
    customerReducer,
    customerStoreState ? customerStoreState : initCustomerContext
  );
  useEffect(() => {
    setStoreItem('customerStore', customerStore);
  }, [customerStore]);

  const financeStoreState = getStoreItem('financeStore', initCustomerContext);
  const [financeStore, financeDispatch] = useReducer(
    financeReducer,
    financeStoreState ? financeStoreState : initFinanceContext
  );
  useEffect(() => {
    setStoreItem('financeStore', financeStore);
  }, [financeStore]);

  // end contexts

  const [calendarStore, calendarDispatch] = useReducer(
    calendarReducer,
    initCalendar
  );

  const [documentsStore, documentsDispatch] = useReducer(
    documentsReportReducer,
    initDocumentsReportContext
  );
  const [servicesStore, servicesDispatch] = useReducer(
    servicesReportReducer,
    initServicesReportContext
  );

  const [salesReportStore, salesReportDispatch] = useReducer(
    salesReportReducer,
    initSalesReportContext
  );
  const [purchaseReportStore, purchaseReportDispatch] = useReducer(
    purchaseReportReducer,
    initPurchaseReportContext
  );
  const [eventsReportStore, eventsReportDispatch] = useReducer(
    eventsReportReducer,
    initEventsReportContext
  );
  const [contractsReportStore, contractsReportDispatch] = useReducer(
    contractsReportReducer,
    initContractsReportContext
  );

  const [expensesReportStore, expensesReportDispatch] = useReducer(
    expensesReportReducer,
    initExpensesReportContext
  );
  const [customerReportStore, customerReportDispatch] = useReducer(
    customerReportReducer,
    initCustomerReportContext
  );
  const [supplierReportStore, supplierReportDispatch] = useReducer(
    supplierReportReducer,
    initSupplierReportContext
  );

  const [projectStore, projectDispatch] = useReducer(
    projectReducer,
    initProjectContext
  );

  const [kaidStore, kaidDispatch] = useReducer(kaidReducer, initKaidContext);
  const [profitabilityStore, profitabilityDispatch] = useReducer(
    profitabilityReducer,
    initProfitabilityContext
  );
  const mainaccountsnames = getparentAccountsNames();

  const sub = useSubscription(
    evenetOpened || !tempoptions?.livedata ? eventStopChange : eventChange,
    {
      fetchPolicy: 'network-only',
      shouldResubscribe: true,
    }
  );
  const mailsub = useSubscription(mailAdded, {
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });
  const outboxsub = useSubscription(mailUpdated, {
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });
  const notifisub = useSubscription(notifiAdded, {
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
  });

  // Load inbox budge
  const [loadInbox, inboxData]: any = useLazyQuery(getMyInbox);
  useEffect(() => {
    const d = inboxData?.data?.getMyInbox?.data;
    if (d) {
      const newin = d.filter((i: any) => !i.read)?.length;
      setNewin(newin);
    }
  }, [inboxData]);

  useEffect(() => {
    loadInbox({ skip: 0 });
  }, []);
  const isPOS = pathname === '/pos';
  return (
    <Box
      className={classes.root}
      dir={isRTL ? 'rtl' : undefined}
      display="flex"
      style={{
        flex: 1,
      }}
    >
      <CssBaseline />
      {!isPOS && (
        <AppDrawer
          isRTL={isRTL}
          setMenuitem={setMenuitem}
          menuitem={menuitem}
          user={user}
          branches={branches}
          menu={filterMenu(isMobile)}
          logout={logout}
          network={network}
          setMmenu={setMmenu}
          mmenu={mmenu}
          notify={notify}
          company={company}
          scrollbar={scrollbar}
          drawerWidth={drawerWidth}
          setDrawerWidth={setDrawerWidth}
          open={open}
          setOpen={setOpen}
          newin={newin}
          isEmployee={isEmployee}
        ></AppDrawer>
      )}
      <main
        style={{ marginTop: isMobile ? 50 : undefined }}
        className={classes.content}
      >
        <Routes>
          <Route
            path="/"
            element={
              isBranchsAdmin ? (
                <AdminLanding
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  theme={theme}
                  company={company}
                  dispatch={dispatch}
                ></AdminLanding>
              ) : (
                <LandingOperations
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  theme={theme}
                  company={company}
                  dispatch={dispatch}
                  notifisub={notifisub}
                ></LandingOperations>
              )
            }
          />
          {isBranchsAdmin && (
            <Route
              path="/main"
              element={
                <LandingOperations
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  theme={theme}
                  company={company}
                  dispatch={dispatch}
                  notifisub={notifisub}
                ></LandingOperations>
              }
            />
          )}
          <Route
            path={'/letters/*'}
            element={
              <LetterOperations
                menuitem={menuitem}
                formgroup={letterforms}
                isRTL={isRTL}
                words={words}
                theme={theme}
                user={user}
                drawerWidth={drawerWidth}
                users={fusers}
                company={company}
              ></LetterOperations>
            }
          />
          <Route
            path={'/operations/*'}
            element={
              <EventsContext.Provider
                value={{ state: eventsStore, dispatch: eventsDispatch }}
              >
                <OperationOperations
                  menuitem={menuitem}
                  formgroup={operationsList?.filter((item: any) => !item.hide)}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  drawerWidth={drawerWidth}
                ></OperationOperations>
              </EventsContext.Provider>
            }
          />
          <Route
            path={'/presales/*'}
            element={
              <PresalesOperations
                menuitem={menuitem}
                formgroup={
                  presalesforms
                    ? [
                        ...presalesforms,
                        {
                          id: 400,
                          name: tempwords?.customers,
                          nameAr: tempwords?.customers,
                          opType: 400,
                          path: 'customers',
                          side: true,
                        },
                      ]
                    : [
                        {
                          id: 400,
                          name: tempwords?.customers,
                          nameAr: tempwords?.customers,
                          opType: 400,
                          path: 'customers',
                          side: true,
                        },
                      ]
                }
                isRTL={isRTL}
                words={words}
                user={user}
                theme={theme}
                company={company}
                users={fusers}
                drawerWidth={drawerWidth}
              ></PresalesOperations>
            }
          />
          <Route
            path={'/sales/*'}
            element={
              <SalesContext.Provider
                value={{ state: salesStore, dispatch: salesDispatch }}
              >
                <SalesOperations
                  menuitem={menuitem}
                  formgroup={salesList?.filter((item: any) => !item.hide)}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  drawerWidth={drawerWidth}
                ></SalesOperations>
              </SalesContext.Provider>
            }
          />
          <Route
            path={'/purchase/*'}
            element={
              <PurchaseContext.Provider
                value={{ state: purchaseStore, dispatch: purchaseDispatch }}
              >
                <PurchaseOperations
                  menuitem={menuitem}
                  formgroup={purchaseList?.filter((item: any) => !item.hide)}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  drawerWidth={drawerWidth}
                ></PurchaseOperations>
              </PurchaseContext.Provider>
            }
          />
          <Route
            path={'/expenses/*'}
            element={
              <ExpensesContext.Provider
                value={{ state: expensesStore, dispatch: expensesDispatch }}
              >
                <ExpensesOperations
                  menuitem={menuitem}
                  formgroup={expensesList?.filter((item: any) => !item.hide)}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  drawerWidth={drawerWidth}
                ></ExpensesOperations>
              </ExpensesContext.Provider>
            }
          />
          <Route
            path={'/finance/*'}
            element={
              <FinanceContext.Provider
                value={{ state: financeStore, dispatch: financeDispatch }}
              >
                <FinanceOperations
                  menuitem={menuitem}
                  formgroup={financeList?.filter((item: any) => !item.hide)}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  drawerWidth={drawerWidth}
                ></FinanceOperations>
              </FinanceContext.Provider>
            }
          />
          <Route
            path={'/basicadd/*'}
            element={
              <BasicsAdd
                menuitem={menuitem}
                formgroup={addsList?.filter((item: any) => !item.hide)}
                isRTL={isRTL}
                words={words}
                theme={theme}
                drawerWidth={drawerWidth}
              ></BasicsAdd>
            }
          />
          <Route
            path={'/reports/*'}
            element={
              <ReportsList
                menuitem={menuitem}
                formgroup={reportsList?.filter((item: any) => !item.hide)}
                isRTL={isRTL}
                words={words}
                theme={theme}
                drawerWidth={drawerWidth}
              ></ReportsList>
            }
          />
          <Route
            path={'/mail/*'}
            element={
              <MailOperations
                sub={mailsub}
                outboxsub={outboxsub}
                menuitem={menuitem}
                formgroup={mailList?.filter((item: any) => !item.hide)}
                letterforms={letterforms}
                isRTL={isRTL}
                words={words}
                theme={theme}
                user={user}
                users={fusers}
                drawerWidth={drawerWidth}
                company={company}
                newin={newin}
                setNewin={setNewin}
              ></MailOperations>
            }
          />
          <Route
            path={'/pos/*'}
            element={
              <MainPOS
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
                user={user}
                company={company}
              ></MainPOS>
            }
          />
          <Route
            path="/reminder/*"
            element={
              <ReminderOperations
                menuitem={menuitem}
                formgroup={reminderList?.filter((item: any) => !item.hide)}
                isRTL={isRTL}
                words={words}
                theme={theme}
              ></ReminderOperations>
            }
          />
          <Route
            path={'/jadwal/*'}
            element={
              <JadwalOperations
                menuitem={menuitem}
                formgroup={jadwalList}
                isRTL={isRTL}
                words={words}
                theme={theme}
                drawerWidth={drawerWidth}
              ></JadwalOperations>
            }
          />
          <Route
            path="/calendar"
            element={
              <CalendarOperations
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
                calendar={calendar}
                dispatch={dispatch}
                company={company}
                tempId={tempId}
                user={user}
                evenetOpened={evenetOpened}
                setEvenetOpened={setEvenetOpened}
                sub={sub}
                drawerWidth={drawerWidth}
              ></CalendarOperations>
            }
          />
          <Route
            path="/mobcalendar"
            element={
              <CalendarContext.Provider
                value={{ state: calendarStore, dispatch: calendarDispatch }}
              >
                <CalendarEmp
                  menuitem={menuitem}
                  isMobile={isMobile}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  calendar={calendar}
                  gDispatch={dispatch}
                  company={company}
                  tempId={tempId}
                  user={user}
                  evenetOpened={evenetOpened}
                  setEvenetOpened={setEvenetOpened}
                  sub={sub}
                  drawerWidth={drawerWidth}
                ></CalendarEmp>
              </CalendarContext.Provider>
            }
          />
          <Route
            path="/appointments"
            element={
              <EventsContext.Provider
                value={{ state: eventsStore, dispatch: eventsDispatch }}
              >
                <Appointments
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                  tempId={tempId}
                ></Appointments>
              </EventsContext.Provider>
            }
          />
          <Route
            path="/invoices"
            element={
              <SalesContext.Provider
                value={{ state: salesStore, dispatch: salesDispatch }}
              >
                <Invoices
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                ></Invoices>
              </SalesContext.Provider>
            }
          />
          <Route
            path="/customers"
            element={
              <CustomerContext.Provider
                value={{ state: customerStore, dispatch: customerDispatch }}
              >
                <Customers
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  menuitem={menuitem}
                  company={company}
                ></Customers>
              </CustomerContext.Provider>
            }
          />
          <Route
            path="/cash"
            element={
              <FinanceContext.Provider
                value={{ state: financeStore, dispatch: financeDispatch }}
              >
                <Finance
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                ></Finance>
              </FinanceContext.Provider>
            }
          />
          <Route
            path="/receiptsadvance"
            element={
              <ReceiptContext.Provider
                value={{ state: receiptStore, dispatch: receiptDispatch }}
              >
                <ReceiptAdvance
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                ></ReceiptAdvance>
              </ReceiptContext.Provider>
            }
          />
          <Route
            path="/paymentsadvance"
            element={
              <ReceiptContext.Provider
                value={{ state: receiptStore, dispatch: receiptDispatch }}
              >
                <PaymentAdvance
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                ></PaymentAdvance>
              </ReceiptContext.Provider>
            }
          />
          <Route
            path="/financeall"
            element={
              <FinanceContext.Provider
                value={{ state: financeStore, dispatch: financeDispatch }}
              >
                <FinanceAllKaid
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                ></FinanceAllKaid>
              </FinanceContext.Provider>
            }
          />

          <Route
            path="/pcategories"
            element={
              <PCategories
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
              ></PCategories>
            }
          />
          <Route
            path="/procitems"
            element={
              <ProcItems
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
              ></ProcItems>
            }
          />
          <Route
            path="/contacts"
            element={
              <Contacts
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
              ></Contacts>
            }
          />
          <Route
            path="/groups"
            element={
              <Groups
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
              ></Groups>
            }
          />
          <Route
            path="/msgtemplates"
            element={
              <MSGTemplates
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
              ></MSGTemplates>
            }
          />

          <Route
            path="/messages"
            element={
              <EventsContext.Provider
                value={{ state: calendarStore, dispatch: calendarDispatch }}
              >
                <Messages
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  menuitem={menuitem}
                  company={company}
                  refreshcompany={refreshcompany}
                ></Messages>
              </EventsContext.Provider>
            }
          />
          <Route
            path="/notifications"
            element={
              <Notifications
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
                notify={notify}
                dispatch={dispatch}
                company={company}
                user={user}
              ></Notifications>
            }
          />
          <Route
            path="/sendreqs"
            element={
              <Sendreqs
                isRTL={isRTL}
                words={words}
                theme={theme}
                menuitem={menuitem}
                company={company}
              ></Sendreqs>
            }
          />
          <Route
            path="/users"
            element={
              <Users
                words={words}
                menuitem={menuitem}
                theme={theme}
                isRTL={isRTL}
                user={user}
              ></Users>
            }
          />
          <Route
            path="/accounts"
            element={
              <PageLayout
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
                refresh={refreshAccount}
              >
                <Accounts accounts={accounts}></Accounts>
              </PageLayout>
            }
          />
          {user.isSuperAdmin && (
            <Route
              path="/branches"
              element={
                <PageLayout
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                >
                  <Branches
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                  ></Branches>
                </PageLayout>
              }
            />
          )}
          <Route
            path="/options"
            element={
              <PageLayout
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
                refresh={refreshcompany}
                editCompany={editCompany}
                company={company}
              >
                <Options></Options>
              </PageLayout>
            }
          />
          <Route
            path="/managedepartments"
            element={
              <DepartmentContext.Provider
                value={{ state: departmentStore, dispatch: departmentDispatch }}
              >
                <ManageDepartments
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                  tempId={tempId}
                ></ManageDepartments>
              </DepartmentContext.Provider>
            }
          />
          <Route
            path="/resoursetypes"
            element={
              <ResourseTypes
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
              ></ResourseTypes>
            }
          />
          <Route
            path="/categories"
            element={
              <Categories
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
              ></Categories>
            }
          />
          <Route
            path="/brands"
            element={
              <Brands
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
              ></Brands>
            }
          />
          <Route
            path="/manageformdocs"
            element={
              <FormDocs
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
              ></FormDocs>
            }
          />
          <Route
            path="/manageprojects"
            element={
              <ProjectContext.Provider
                value={{ state: projectStore, dispatch: projectDispatch }}
              >
                <ManageProjects
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                ></ManageProjects>
              </ProjectContext.Provider>
            }
          />
          <Route
            path="/manageemployees"
            element={
              <EmployeeContext.Provider
                value={{ state: employeeStore, dispatch: employeeDispatch }}
              >
                <ManageEmployees
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                  tempId={tempId}
                ></ManageEmployees>
              </EmployeeContext.Provider>
            }
          />
          <Route
            path="/manageresourses"
            element={
              <ResourseContext.Provider
                value={{ state: resourseStore, dispatch: resourseDispatch }}
              >
                <ManageResourses
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                  company={company}
                  tempId={tempId}
                ></ManageResourses>
              </ResourseContext.Provider>
            }
          />
          <Route
            path="/services"
            element={
              <ProductContext.Provider
                value={{ state: productStore, dispatch: productDispatch }}
              >
                <Services
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                ></Services>
              </ProductContext.Provider>
            }
          />
          <Route
            path="/products"
            element={
              <ProductContext.Provider
                value={{ state: productStore, dispatch: productDispatch }}
              >
                <Products
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  theme={theme}
                ></Products>
              </ProductContext.Provider>
            }
          />
          <Route
            path="/stockitems"
            element={
              <PageLayout
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
                refresh={() => null}
              >
                <StockItems></StockItems>
              </PageLayout>
            }
          />
          <Route
            path="/expenseitems"
            element={
              <ExpenseItems
                menuitem={menuitem}
                isRTL={isRTL}
                words={words}
                theme={theme}
                tempId={tempId}
              ></ExpenseItems>
            }
          />
          <Route
            path="/calreports"
            element={
              <EventsReportContext.Provider
                value={{
                  state: eventsReportStore,
                  dispatch: eventsReportDispatch,
                }}
              >
                <EventsReport
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  categories={[]}
                  company={company}
                  theme={theme}
                ></EventsReport>
              </EventsReportContext.Provider>
            }
          />
          <Route
            path="/contractseports"
            element={
              <ContractsReportContext.Provider
                value={{
                  state: contractsReportStore,
                  dispatch: contractsReportDispatch,
                }}
              >
                <ContractsReport
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  categories={[]}
                  company={company}
                  theme={theme}
                ></ContractsReport>
              </ContractsReportContext.Provider>
            }
          />
          <Route
            path="/docreports"
            element={
              <DocumentsReportContext.Provider
                value={{
                  state: documentsStore,
                  dispatch: documentsDispatch,
                }}
              >
                <DocumentsReport
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  categories={[]}
                  company={company}
                  theme={theme}
                ></DocumentsReport>
              </DocumentsReportContext.Provider>
            }
          />
          <Route
            path="/servicesreports"
            element={
              <ServicesReportContext.Provider
                value={{
                  state: servicesStore,
                  dispatch: servicesDispatch,
                }}
              >
                <ServicesReport
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  categories={[]}
                  company={company}
                  theme={theme}
                ></ServicesReport>
              </ServicesReportContext.Provider>
            }
          />
          <Route
            path="/eventsitemsreports"
            element={
              <ServicesReportContext.Provider
                value={{
                  state: servicesStore,
                  dispatch: servicesDispatch,
                }}
              >
                <EventsItemsReport
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  categories={[]}
                  company={company}
                  theme={theme}
                ></EventsItemsReport>
              </ServicesReportContext.Provider>
            }
          />
          <Route
            path="/stockreport"
            element={
              <ServicesReportContext.Provider
                value={{
                  state: servicesStore,
                  dispatch: servicesDispatch,
                }}
              >
                <ProductsReport
                  menuitem={menuitem}
                  isRTL={isRTL}
                  words={words}
                  categories={[]}
                  company={company}
                  theme={theme}
                ></ProductsReport>
              </ServicesReportContext.Provider>
            }
          />
          <Route
            path="/salesreport"
            element={
              <SalesReportContext.Provider
                value={{
                  state: salesReportStore,
                  dispatch: salesReportDispatch,
                }}
              >
                <SalesReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                ></SalesReport>
              </SalesReportContext.Provider>
            }
          />
          <Route
            path="/purchasereport"
            element={
              <PurchaseReportContext.Provider
                value={{
                  state: purchaseReportStore,
                  dispatch: purchaseReportDispatch,
                }}
              >
                <PurchaseReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  suppliers={suppliers}
                  categories={[]}
                  company={company}
                ></PurchaseReport>
              </PurchaseReportContext.Provider>
            }
          />
          <Route
            path="/financereport"
            element={
              <FinanceContext.Provider
                value={{
                  state: financeStore,
                  dispatch: financeDispatch,
                }}
              >
                <FinanceReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                  accounts={accounts}
                ></FinanceReport>
              </FinanceContext.Provider>
            }
          />
          <Route
            path="/kaidreport"
            element={
              <KaidContext.Provider
                value={{
                  state: kaidStore,
                  dispatch: kaidDispatch,
                }}
              >
                <KaidsReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                  mainaccounts={mainaccountsnames}
                  accounts={accounts}
                ></KaidsReport>
              </KaidContext.Provider>
            }
          />
          <Route
            path="/profitability"
            element={
              <ProfitabilityContext.Provider
                value={{
                  state: profitabilityStore,
                  dispatch: profitabilityDispatch,
                }}
              >
                <ProfitabilitysReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                  mainaccounts={mainaccountsnames}
                  accounts={accounts}
                ></ProfitabilitysReport>
              </ProfitabilityContext.Provider>
            }
          />
          <Route
            path="/profitreport"
            element={
              <FinanceContext.Provider
                value={{
                  state: financeStore,
                  dispatch: financeDispatch,
                }}
              >
                <ProfitReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                ></ProfitReport>
              </FinanceContext.Provider>
            }
          />
          <Route
            path="/budgetreport"
            element={
              <FinanceContext.Provider
                value={{
                  state: financeStore,
                  dispatch: financeDispatch,
                }}
              >
                <BudgetReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                ></BudgetReport>
              </FinanceContext.Provider>
            }
          />
          <Route
            path="/expensesreport"
            element={
              <ExpensesReportContext.Provider
                value={{
                  state: expensesReportStore,
                  dispatch: expensesReportDispatch,
                }}
              >
                <ExpensesReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                  accounts={accounts}
                ></ExpensesReport>
              </ExpensesReportContext.Provider>
            }
          />
          <Route
            path="/expproductreport"
            element={
              <ExpensesReportContext.Provider
                value={{
                  state: expensesReportStore,
                  dispatch: expensesReportDispatch,
                }}
              >
                <ExpProductReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                  accounts={accounts}
                ></ExpProductReport>
              </ExpensesReportContext.Provider>
            }
          />
          <Route
            path="/customerreport"
            element={
              <CustomerReportContext.Provider
                value={{
                  state: customerReportStore,
                  dispatch: customerReportDispatch,
                }}
              >
                <CustomerReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  categories={[]}
                  company={company}
                ></CustomerReport>
              </CustomerReportContext.Provider>
            }
          />
          <Route
            path="/customerallreport"
            element={
              <CustomerReportContext.Provider
                value={{
                  state: customerReportStore,
                  dispatch: customerReportDispatch,
                }}
              >
                <CustomerAllReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                ></CustomerAllReport>
              </CustomerReportContext.Provider>
            }
          />
          <Route
            path="/supplierallreport"
            element={
              <SupplierReportContext.Provider
                value={{
                  state: supplierReportStore,
                  dispatch: supplierReportDispatch,
                }}
              >
                <SupplierAllReport
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                ></SupplierAllReport>
              </SupplierReportContext.Provider>
            }
          />
          <Route
            path="/supplierreport"
            element={
              <SupplierReportContext.Provider
                value={{
                  state: supplierReportStore,
                  dispatch: supplierReportDispatch,
                }}
              >
                <SupplierReportNew
                  isRTL={isRTL}
                  words={words}
                  menuitem={menuitem}
                  theme={theme}
                  company={company}
                ></SupplierReportNew>
              </SupplierReportContext.Provider>
            }
          />
        </Routes>
        <AlertWithClose
          open={packIssue}
          dispatch={dispatch}
          isRTL={isRTL}
          msg={packIssueMsg}
          onClose={() => dispatch({ type: 'closePackIssue' })}
        ></AlertWithClose>
      </main>
    </Box>
  );
};
export default Content;
