/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext } from 'react';
import { useState } from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { errorAlert, PopupEditing } from '../../Shared';
import { Box, Fab, IconButton, Typography } from '@material-ui/core';
import { useTemplate, useUsers } from '../../hooks';
import {
  activeFormatter,
  avatarFormatter,
  rolesFormatter,
} from '../../Shared/colorFormat';
import { AlertLocal, SearchTable } from '../../components';
import useCompany from '../../hooks/useCompany';
import PopupUserEmail from '../../pubups/PopupUserEmail';
import useEmployees from '../../hooks/useEmployees';
import { TableComponent } from '../../Shared/TableComponent';
import { getColumns } from '../../common/columns';
import { userroles } from '../../common';
import { GlobalContext } from '../../contexts';

const getRowId = (row: { _id: any }) => row._id;

export default function Users({ isRTL, words, theme }: any) {
  const col = getColumns({ isRTL, words });

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const {
    store: { user },
  } = useContext(GlobalContext);

  const [columns] = useState([
    { name: 'avatar', title: words.avatar },
    { name: 'username', title: words.email },
    { name: 'name', title: words.name },
    { name: 'phone', title: words.phoneNumber },
    { name: 'title', title: isRTL ? 'نوع الحساب' : 'Account Type' },
    col.employee,
    { name: 'block', title: isRTL ? 'الحالة' : 'Status' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 100 },
    { columnName: 'username', width: 200 },
    { columnName: 'name', width: 250 },
    { columnName: 'phone', width: 150 },
    { columnName: 'title', width: 150 },
    { columnName: col.employee.name, width: 200 },
    { columnName: 'block', width: 100 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'avatar', togglingEnabled: false },
    { columnName: 'username', togglingEnabled: false },
  ]);

  const { users, addUser, editUser, removeUser, editPasswordQuick, block } =
    useUsers();
  const { company } = useCompany();
  const { employees } = useEmployees();
  const { tempoptions } = useTemplate();

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeUser({ variables: { _id } });
      if (res?.data?.deleteUser?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        return;
      }
    }
  };

  const AddButton = ({ onExecute }) => (
    <div style={{ textAlign: 'center' }}>
      <Box m={1}>
        <Fab color="primary" onClick={onExecute} title="Create new row">
          <AddIcon />
        </Fab>
      </Box>
    </div>
  );

  const EditButton = ({ onExecute }) => (
    <IconButton onClick={onExecute} title="Edit row">
      <EditIcon />
    </IconButton>
  );

  const DeleteButton = ({ onExecute }) => (
    <IconButton
      onClick={() => {
        if (window.confirm('Are you sure you want to delete this row?')) {
          onExecute();
        }
      }}
      title="Delete row"
    >
      <DeleteIcon />
    </IconButton>
  );

  const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
  };

  const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return <CommandButton onExecute={onExecute} />;
  };

  const fusers = user.isSuperAdmin
    ? users
    : users.filter((us: any) => !us.isSuperAdmin);

  const emplusers = fusers.filter(
    (fu: any) => fu?.employeeId && fu?.isEmployee
  );
  const emplIds = emplusers.map((eu: any) => eu?.employeeId);
  const emplnoaccount =
    emplIds && emplIds?.length > 0
      ? employees?.filter((emp: any) => !emplIds.includes(emp?._id))
      : employees;

  return (
    <>
      <Grid rows={fusers} columns={columns} getRowId={getRowId}>
        <SortingState />
        <SearchState />
        <EditingState onCommitChanges={commitChanges} />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'avatar',
            'username',
            'name',
            'phone',
            'title',
            col.employee.name,
            'block',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['avatar']}
          formatterComponent={avatarFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['title']}
          formatterComponent={(props: any) =>
            rolesFormatter({ ...props, isRTL })
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['block']}
          formatterComponent={activeFormatter}
        ></DataTypeProvider>

        <TableEditColumn
          showEditCommand={userroles?.users?.edit}
          showDeleteCommand={userroles?.users?.delete}
          showAddCommand={userroles?.users?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 15, 20, 50, 0] : [5, 10, 15, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing theme={theme} addAction={addUser} editAction={editUser}>
          <PopupUserEmail
            company={company}
            editPassword={editPasswordQuick}
            block={block}
            employees={employees}
            emplnoaccount={emplnoaccount}
          ></PopupUserEmail>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
    </>
  );
}
