import { gql } from '@apollo/client';

export default gql`
  mutation createEmployeeLink($id: String) {
    createEmployeeLink(id: $id) {
      ok
      message
      data
      error
    }
  }
`;
