import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MonthsChartSimple from '../Sections/generator/page/MonthsChartSimple';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  media: {
    height: 150,
    width: 150,
  },
});

export default function BranchBox({ branch, isCurrent, isRTL }: any) {
  const classes = useStyles();
  const docscount = branch?.docscount ? JSON.parse(branch?.docscount) : [];
  return (
    <Card
      elevation={2}
      style={{
        backgroundColor: isCurrent ? '#f2faf2' : '#fafafa',
        margin: 5,
        height: 450,
      }}
    >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={branch?.logo}
          title="Contemplative Reptile"
        />
        <CardContent>
          {docscount && docscount.length > 0 && (
            <Box p={1}>
              {docscount.map((dc: any) => {
                return (
                  <Box style={{ display: 'flex' }}>
                    <Typography style={{ fontWeight: 'bold', width: 120 }}>
                      {isRTL ? dc.nameAr : dc.name}
                    </Typography>
                    <Typography>{dc.count}</Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          <Typography gutterBottom variant="h5" component="h2">
            {branch?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {branch?.address}
          </Typography>
          {branch?.salesMonths && (
            <MonthsChartSimple
              data={branch?.salesMonths}
              isRTL={isRTL}
              color={'#555'}
              dataKey="total"
              height={160}
            ></MonthsChartSimple>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
