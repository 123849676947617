/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PopupLayout from '../pages/main/PopupLayout';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { templates } from '../constants';
import { TextFieldLocal } from '../components';
import checkUsername from '../graphql/query/checkUsername';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { isValidEmail } from '../common/helpers';
import { errorAlertMsg } from '../Shared/helpers';

import TemplateOptions from '../Shared/TemplateOptions';
import TemplateWords from '../Shared/TemplateWords';
import UploadJson, { downloadJSONFile } from '../common/UploadJson';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PrintTemplates from '../pages/adds/PrintTemplates';
import { ImageOnlineUpload, uploadPhotoOnline } from '../common/upload';
import TemplateWordsTrans from '../Shared/TemplateWordsTrans';
import LandingTemplate from '../Sections/generator/page/LandingTemplate';
import EmployeeTemplate from '../Sections/generator/page/EmployeeTemplate';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import useFoundations from '../hooks/useFoundations';
import PopupFoundation from './PopupFoundation';

const search = _.debounce(({ checkUser, username }) => {
  checkUser({ variables: { username } });
}, 300);

const branchSchema = yup.object().shape({
  password: yup.string().required().min(3).max(100),
  name: yup.string().required().min(3).max(100),
  nameAr: yup.string().required().min(3).max(100),
});

const PopupBranch = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  isRTL,
  theme,
  words,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [temp, setTemp] = useState(null);
  const [username, setUsername] = useState(null);
  const [valid, setValid] = useState(null);

  const [foundation, setFoundation] = useState(null);
  const [newtext, setNewtext] = useState('');
  const [openFound, setOpenFound] = useState(false);

  const [resimage, setResimage] = useState(null);
  const [resurl, setResurl] = useState(null);
  const [empimage, setEmpimage] = useState(null);
  const [empurl, setEmpurl] = useState(null);
  const [conimage, setConimage] = useState(null);
  const [conurl, setConurl] = useState(null);

  const [fronttemp, setFronttemp] = useState(null);
  const [emptemp, setEmptemp] = useState(null);
  const [deptemp, setDeptemp] = useState(null);
  const [custemp, setCustemp] = useState(null);
  const [suptemp, setSuptemp] = useState(null);
  const [restemp, setRestemp] = useState(null);
  const [tsktemp, setTsktemp] = useState(null);
  const [en, setEn] = useState(null);
  const [ar, setAr] = useState(null);

  const brandchResolver = { resolver: yupResolver(branchSchema) };
  const { foundations, addFoundation, editFoundation } = useFoundations();
  const { register, handleSubmit, errors, reset } = useForm(
    isNew ? brandchResolver : undefined
  );
  const [checkUser, userData] = useLazyQuery(checkUsername);

  const openFoundation = () => {
    setOpenFound(true);
  };

  const onCloseFoundation = () => {
    setOpenFound(false);
    setNewtext('');
  };

  const onNewFoundChange = (nextValue: any) => {
    setFoundation(nextValue);
  };

  useEffect(() => {
    if (row && row._id) {
      if (row?.template) {
        setTemp(JSON.parse(row?.template));
      }
      if (row?.foundationId) {
        const found = foundations.filter(
          (f: any) => f._id === row?.foundationId
        )?.[0];
        if (found) setFoundation(found);
      }
      if (row.photos) {
        const phs = JSON.parse(row.photos);
        const res = phs?.res;
        const emp = phs?.emp;
        const con = phs?.con;
        setResurl(res);
        setEmpurl(emp);
        setConurl(con);
      }
      if (row?.fronttemp) {
        setFronttemp(JSON.parse(row?.fronttemp));
      }
      if (row?.emptemp) {
        setEmptemp(JSON.parse(row?.emptemp));
      }
      if (row?.deptemp) {
        setDeptemp(JSON.parse(row?.deptemp));
      }
      if (row?.custemp) {
        setCustemp(JSON.parse(row?.custemp));
      }
      if (row?.suptemp) {
        setSuptemp(JSON.parse(row?.suptemp));
      }
      if (row?.restemp) {
        setRestemp(JSON.parse(row?.restemp));
      }
      if (row?.tsktemp) {
        setTsktemp(JSON.parse(row?.tsktemp));
      }
      if (row?.en) {
        setEn(JSON.parse(row?.en));
      }
      if (row?.ar) {
        setAr(JSON.parse(row?.ar));
      }
    }
  }, [row]);

  useEffect(() => {
    search({ checkUser, username });
  }, [username]);

  useEffect(() => {
    if (userData?.data?.checkUsername?.ok && isValidEmail(username)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [userData]);

  const onUsernameChange = async (e: any) => {
    setUsername(e.target.value);
  };

  const onSubmit = async (data: any) => {
    if (isNew && !valid) {
      await errorAlertMsg(setAlrt, 'valid email require');
      return;
    }
    if (!temp) {
      await errorAlertMsg(setAlrt, 'template required');
      return;
    }
    if (!foundation) {
      await errorAlertMsg(setAlrt, 'main company required');
      return;
    }

    let res: any;
    let emp: any;
    let con: any;

    if (resimage) {
      res = await uploadPhotoOnline(resimage);
      res = res.replace('http://', 'https://');
    }
    if (empimage) {
      emp = await uploadPhotoOnline(empimage);
      emp = emp.replace('http://', 'https://');
    }
    if (conimage) {
      con = await uploadPhotoOnline(conimage);
      con = con.replace('http://', 'https://');
    }
    const { name, nameAr, password, tel1 } = data;
    const variables: any = isNew
      ? {
          foundationId: foundation?._id,
          username,
          password,
          name,
          nameAr,
          tel1,
          temp: JSON.stringify(temp),
          fronttemp: fronttemp ? JSON.stringify(fronttemp) : undefined,
          emptemp: emptemp ? JSON.stringify(emptemp) : undefined,
          deptemp: deptemp ? JSON.stringify(deptemp) : undefined,
          custemp: custemp ? JSON.stringify(custemp) : undefined,
          suptemp: suptemp ? JSON.stringify(suptemp) : undefined,
          restemp: restemp ? JSON.stringify(restemp) : undefined,
          tsktemp: tsktemp ? JSON.stringify(tsktemp) : undefined,
          photos: JSON.stringify({
            res: res || resurl,
            emp: emp || empurl,
            con: con || conurl,
          }),
        }
      : {
          _id: row._id,
          foundationId: foundation?._id,
          name,
          nameAr,
          tel1,
          temp: JSON.stringify(temp),
          fronttemp: fronttemp ? JSON.stringify(fronttemp) : undefined,
          emptemp: emptemp ? JSON.stringify(emptemp) : undefined,
          deptemp: deptemp ? JSON.stringify(deptemp) : undefined,
          custemp: custemp ? JSON.stringify(custemp) : undefined,
          suptemp: suptemp ? JSON.stringify(suptemp) : undefined,
          restemp: restemp ? JSON.stringify(restemp) : undefined,
          tsktemp: tsktemp ? JSON.stringify(tsktemp) : undefined,
          en: en ? JSON.stringify(en) : undefined,
          ar: ar ? JSON.stringify(ar) : undefined,
          photos: JSON.stringify({
            res: res || resurl,
            emp: emp || empurl,
            con: con || conurl,
          }),
        };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      closeForm();
    } catch (error) {
      onError(error);
    }
  };
  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
    } else {
      reset();
      console.log(error);
    }
  };

  const resetAll = () => {
    setUsername(null);
    setValid(null);
    setTemp(null);
    setResimage(null);
    setResurl(null);
    setEmpimage(null);
    setEmpurl(null);
    setConimage(null);
    setConurl(null);
    setFronttemp(null);
    setEmptemp(null);
    setDeptemp(null);
    setCustemp(null);
    setSuptemp(null);
    setRestemp(null);
    setTsktemp(null);
    setEn(null);
    setAr(null);
    setFoundation(null);
    setNewtext(null);
    reset();
  };

  const closeForm = () => {
    resetAll();
    onClose();
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const title = isRTL
    ? isNew
      ? 'اضافة شركة'
      : 'تعديل الشركة'
    : isNew
    ? 'New Company'
    : 'Edit Company';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      preventclose
      maxWidth="lg"
    >
      <div style={{ padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextFieldLocal
              required
              name="nameAr"
              label={words.name}
              register={register}
              errors={errors}
              row={row}
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLocal
              required
              name="name"
              ltr
              label={words.nameEn}
              register={register}
              errors={errors}
              row={row}
              fullWidth
              mb={0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldLocal
              name="tel1"
              label={words.phoneNumber}
              register={register}
              errors={errors}
              row={row}
              fullWidth
              margin={20}
              mb={0}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          {isNew && (
            <>
              <Grid item xs={6}>
                <TextFieldLocal
                  required
                  name="username"
                  label={words.email}
                  value={username}
                  onChange={onUsernameChange}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>

              <Grid item xs={6}>
                <Box
                  style={{
                    marginTop: 15,
                    width: 28,
                    height: 28,
                    borderRadius: 14,
                    backgroundColor: valid ? '#b6fcd5' : '#ffc0cb',
                  }}
                ></Box>
              </Grid>
              <Grid item xs={6}>
                <TextFieldLocal
                  required
                  name="password"
                  label={words.password}
                  register={register}
                  errors={errors}
                  row={row}
                  type="password"
                  fullWidth
                  mb={0}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <AutoFieldLocal
              name="foundation"
              title={isRTL ? 'Main Company' : 'الشركة الأم'}
              words={words}
              options={foundations}
              value={foundation}
              setSelectValue={setFoundation}
              openAdd={openFoundation}
              isRTL={isRTL}
              fullWidth
              mb={0}
            ></AutoFieldLocal>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ marginTop: 10, marginBottom: 20 }}
              variant="h5"
            >
              {isRTL ? 'النماذج' : 'Templates'}
            </Typography>
          </Grid>

          {templates.map((tm: any) => {
            const selected = tm?.title === temp?.title;
            return (
              <Grid item xs={3}>
                <Card
                  style={{
                    backgroundColor: selected ? '#b6fcd5' : '#f5f5f5',
                    cursor: 'pointer',
                    height: 100,
                  }}
                  onClick={() => setTemp(tm)}
                >
                  <CardContent>
                    <Typography
                      style={{ marginBottom: 20, fontWeight: 'bold' }}
                      variant="subtitle1"
                      component="div"
                    >
                      {isRTL ? tm.nameAr : tm.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => downloadJSONFile(temp)}
                >
                  Download
                </Button>
              </Grid>
              <Grid item xs={3}>
                <UploadJson setValue={setTemp}></UploadJson>
              </Grid>
              <Grid item xs={3}></Grid>
              <Divider></Divider>
              <Grid item xs={4}>
                <ImageOnlineUpload
                  url={resurl}
                  setUrl={setResurl}
                  image={resimage}
                  setImage={setResimage}
                  width={150}
                  height={150}
                  size="Resourse 400x400"
                ></ImageOnlineUpload>
              </Grid>
              <Grid item xs={4}>
                <ImageOnlineUpload
                  url={empurl}
                  setUrl={setEmpurl}
                  image={empimage}
                  setImage={setEmpimage}
                  width={150}
                  height={150}
                  size="Employee 400x400"
                ></ImageOnlineUpload>
              </Grid>
              <Grid item xs={4}>
                <ImageOnlineUpload
                  url={conurl}
                  setUrl={setConurl}
                  image={conimage}
                  setImage={setConimage}
                  width={150}
                  height={150}
                  size="Contract 400x400"
                ></ImageOnlineUpload>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Template General Options</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TemplateOptions
                      temp={temp}
                      setTemp={setTemp}
                    ></TemplateOptions>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Template Names & Tabs Options</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TemplateWords
                      temp={temp}
                      setTemp={setTemp}
                    ></TemplateWords>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Translate</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TemplateWordsTrans
                      en={en}
                      setEn={setEn}
                      ar={ar}
                      setAr={setAr}
                    ></TemplateWordsTrans>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Main Page Template</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <LandingTemplate
                          template={fronttemp}
                          setTemplate={setFronttemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></LandingTemplate>
                      </Grid>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={4}>
                        <EmployeeTemplate
                          name="Employee Template"
                          template={emptemp}
                          setTemplate={setEmptemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></EmployeeTemplate>
                      </Grid>
                      <Grid item xs={4}>
                        <EmployeeTemplate
                          name="Department Template"
                          template={deptemp}
                          setTemplate={setDeptemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></EmployeeTemplate>
                      </Grid>
                      <Grid item xs={4}>
                        <EmployeeTemplate
                          name="Customer Template"
                          template={custemp}
                          setTemplate={setCustemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></EmployeeTemplate>
                      </Grid>
                      <Grid item xs={4}>
                        <EmployeeTemplate
                          name="Supplier Template"
                          template={suptemp}
                          setTemplate={setSuptemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></EmployeeTemplate>
                      </Grid>
                      <Grid item xs={4}>
                        <EmployeeTemplate
                          name="Resourse Template"
                          template={restemp}
                          setTemplate={setRestemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></EmployeeTemplate>
                      </Grid>
                      <Grid item xs={4}>
                        <EmployeeTemplate
                          name="Contract Template"
                          template={tsktemp}
                          setTemplate={setTsktemp}
                          company={row}
                          isRTL={isRTL}
                          theme={theme}
                          words={words}
                        ></EmployeeTemplate>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Print Templates</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PrintTemplates
                      company={row}
                      branch={row?.basename}
                      isRTL={isRTL}
                      theme={theme}
                      words={words}
                    ></PrintTemplates>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PopupFoundation
          newtext={newtext}
          open={openFound}
          onClose={onCloseFoundation}
          isNew={true}
          setNewValue={onNewFoundChange}
          row={null}
          addAction={addFoundation}
          editAction={editFoundation}
        ></PopupFoundation>
      </div>
    </PopupLayout>
  );
};

export default PopupBranch;
