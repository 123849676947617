/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  fade,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { getColumns } from '../common/columns';
import {
  createdAtFormatter,
  currencyFormatter,
  statusViewFormatter,
} from './colorFormat';
import { useLazyQuery, useMutation } from '@apollo/client';

import getObjectEvents from '../graphql/query/getObjectEvents';
import useTasks from '../hooks/useTasks';
import { useCustomers, useServices } from '../hooks';
import { updateDocNumbers, userroles } from '../common';
import { createEvent, deleteEventById, updateEvent } from '../graphql';
import { Getter } from '@devexpress/dx-react-core';
import PopupEditing from './PopupEditing';
import { Command } from './Command';
import PopupAppointmentCustomer from '../pubups/PopupAppointmentCustomer';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import { getProjects, getTasks } from '../graphql/query';
import { GlobalContext } from '../contexts';
import deleteEventsByIds from '../graphql/mutation/deleteEventsByIds';
import { dayslist } from '../constants/datatypes';
export const getRowId = (row: any) => row._id;

const NumberTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <Typography variant="subtitle2">{Number(value) + 1}</Typography>
    )}
    {...props}
  />
);

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function EventsTask({
  isRTL,
  words,
  company,
  value,
  theme,
  id,
  name,
  start,
  end,
  tempoptions,
  mstart,
  mend,
  openMulti,
}: any) {
  const col = getColumns({ isRTL, words });
  const [selection, setSelection] = useState([]);
  const [groups, setGroups] = useState([]);

  const [selg, setSelg] = useState(null);
  const [day, setDay] = useState(null);

  const handleGroupChange = (event: any) => {
    setSelg(event.target.value);
  };
  const handleDayChange = (event: any) => {
    setDay(event.target.value);
  };

  const [columns] = useState(
    [
      col.startDate,
      col.customer,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      col.groupId,
      col.amount,
      col.status,
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'startDate', width: 200 },
    { columnName: col.customer.name, width: 180 },
    { columnName: col.employee.name, width: 180, align: 'center' },
    { columnName: col.department.name, width: 150 },
    { columnName: 'groupId', width: 100 },
    { columnName: 'status', width: 100 },
    { columnName: 'amount', width: 120 },
  ]);

  const { tasks } = useTasks();
  const [rows, setRows] = useState([]);
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { customers } = useCustomers();
  const { services } = useServices();
  const refresQuery = {
    refetchQueries: [
      {
        query: getObjectEvents,
        variables: {
          [name]: id,
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getGereralCalculation,
        variables: {
          [name]: id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      name === 'contractId'
        ? {
            query: getTasks,
            variables: {
              contractId: id,
              start: mstart ? new Date(mstart).setHours(0, 0, 0, 0) : undefined,
              end: mend ? new Date(mend).setHours(23, 59, 59, 999) : undefined,
            },
          }
        : undefined,
      name === 'contractId' ? { query: getTasks } : undefined,
      name === 'projectId' ? { query: getProjects } : undefined,
    ].filter((x: any) => x),
  };

  const [getEvents, eventsData]: any = useLazyQuery(getObjectEvents, {
    fetchPolicy: 'cache-and-network',
  });
  const [removeEventsByIds] = useMutation(deleteEventsByIds, refresQuery);

  const deleteSelected = async () => {
    if (window.confirm('Are you sure you want to delete rows?')) {
      await removeEventsByIds({ variables: { ids: selection } });
      setSelection([]);
    }
  };

  useEffect(() => {
    if (id) {
      const variables = {
        [name]: id,
        start: start ? start.setHours(0, 0, 0, 0) : undefined,
        end: end ? end.setHours(23, 59, 59, 999) : undefined,
      };
      getEvents({ variables });
    }
  }, [id, start, end, openMulti]);

  useEffect(() => {
    const data = eventsData?.data?.['getObjectEvents']?.data;
    const events = data || [];
    const rdata = updateDocNumbers(events);
    let rfdata = rdata;
    if (selg) {
      rfdata = rdata.filter((rd: any) => rd.groupId === selg);
    }
    if (day || day === 0) {
      rfdata = rdata.filter(
        (rd: any) => new Date(rd.startDate).getDay() === day
      );
    }
    setRows(rfdata);
    const grps = rdata.map((rd: any) => rd?.groupId)?.filter((x: any) => x);
    const gids = Array.from(new Set([...grps]));
    setGroups(gids);
  }, [eventsData, selg, day]);

  const [addEvent] = useMutation(createEvent, refresQuery);
  const [editEvent] = useMutation(updateEvent, refresQuery);
  const [removeEventById] = useMutation(deleteEventById, refresQuery);

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeEventById({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };
  return (
    <Box style={{ marginTop: 5 }}>
      <Paper elevation={5}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box style={{ marginTop: 10 }}>
            <Typography style={{ fontSize: 14 }}>
              {isRTL ? 'العدد' : 'Qty'} {rows?.length}
            </Typography>
          </Box>
          <Box style={{ marginLeft: 100, marginRight: 100, marginTop: 10 }}>
            <Typography style={{ fontSize: 14 }}>
              {isRTL ? 'المحدد' : 'Selected'} {selection?.length}
            </Typography>
          </Box>

          <FormControl
            style={{
              width: 150,
              marginLeft: 10,
              marginRight: 10,
              height: 35,
              marginTop: -15,
            }}
          >
            <InputLabel id="select-label-day">
              {isRTL ? 'اليوم' : 'Day'}
            </InputLabel>
            <Select
              value={day}
              onChange={handleDayChange}
              inputProps={{ 'aria-label': 'Without label' }}
              label="Bundle"
            >
              <MenuItem value={null}>
                <em>{isRTL ? 'الكل' : 'All'}</em>
              </MenuItem>
              {dayslist.map((d: any) => (
                <MenuItem key={d.value} value={d.value}>
                  {isRTL ? d.nameAr : d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {groups?.length > 1 && (
            <FormControl
              style={{
                width: 150,
                marginLeft: 10,
                marginRight: 10,
                height: 35,
                marginTop: -15,
              }}
            >
              <InputLabel id="select-label-selg">
                {isRTL ? 'الرزمة' : 'Pack'}
              </InputLabel>
              <Select
                value={selg}
                onChange={handleGroupChange}
                inputProps={{ 'aria-label': 'Without label' }}
                label="Bundle"
              >
                <MenuItem value={null}>
                  <em>{isRTL ? 'الكل' : 'All'}</em>
                </MenuItem>
                {groups.map((g: any) => (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {userroles?.customers?.admin && (
            <Button
              color="secondary"
              variant="contained"
              disabled={selection?.length === 0}
              onClick={deleteSelected}
              style={{ margin: 10, height: 32 }}
            >
              <Typography>
                {isRTL ? 'حذف المحدد' : 'Delete Selected'}
              </Typography>
            </Button>
          )}
        </Box>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState />
          <EditingState onCommitChanges={commitChanges} />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <IntegratedSelection />
          <IntegratedSorting />
          <VirtualTable
            height={350}
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            estimatedRowHeight={40}
            tableComponent={TableComponent}
            columnExtensions={tableColumnExtensions}
          />
          <DataTypeProvider
            for={['startDate']}
            formatterComponent={createdAtFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['amount']}
            formatterComponent={currencyFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['status']}
            formatterComponent={statusViewFormatter}
          ></DataTypeProvider>
          <NumberTypeProvider for={['index']} />
          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableEditColumn
            showEditCommand={userroles?.appointments?.edit}
            showDeleteCommand={userroles?.appointments?.delete}
            showAddCommand={userroles?.appointments?.add}
            commandComponent={Command}
          ></TableEditColumn>

          <PopupEditing addAction={addEvent} editAction={editEvent}>
            <PopupAppointmentCustomer
              resourses={resourses}
              employees={employees}
              departments={departments}
              customers={customers}
              servicesproducts={services}
              theme={theme}
              company={company}
              tasks={tasks}
              name={name}
              value={value}
              calendar={calendar}
            ></PopupAppointmentCustomer>
          </PopupEditing>
          <TableSelection showSelectAll />
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                {
                  key: 'editCommand',
                  type: TableEditColumn.COLUMN_TYPE,
                  width: 120,
                },
                ...tableColumns.filter(
                  (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
              ];
              return result;
            }}
          />
        </Grid>
      </Paper>
    </Box>
  );
}
