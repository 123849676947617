/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { colorPatternFormatter, Command, PopupEditing } from '../../Shared';
import { useTemplate } from '../../hooks';
import { getRowId, userroles } from '../../common';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import PopupStepItem from '../../pubups/PopupStepItem';
import useStepItems from '../../hooks/useStepItems';

export default function StepItems({ isRTL, words, theme }: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const { tempoptions } = useTemplate();

  const [columns] = useState([
    { name: 'id', title: words.no },
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'color', title: words.color },
    { name: 'disabled', title: words.status },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'id', width: 100 },
    { columnName: isRTL ? 'nameAr' : 'name', width: 250 },
    { columnName: 'color', width: 150 },
    { columnName: 'disabled', width: 150 },
  ]);

  const { allstepitems, addStepItem, editStepItem, removeStepItem } =
    useStepItems();

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeStepItem({ variables: { _id } });
      if (res?.data?.deleteStepItems?.ok === false) {
        if (res?.data?.deleteStepItems?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  return (
    <>
      <Grid
        rows={allstepitems?.filter((si: any) => !si.auto)}
        columns={columns}
        getRowId={getRowId}
      >
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 68 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={['id', isRTL ? 'nameAr' : 'name', 'color', 'disabled']}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={[]}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['color']}
          formatterComponent={colorPatternFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['disabled']}
          formatterComponent={({ value }) => {
            return (
              <Typography>
                {value ? (isRTL ? 'غير مفعل' : 'Disabled') : ''}
              </Typography>
            );
          }}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.sales?.edit}
          showDeleteCommand={userroles?.sales?.delete}
          showAddCommand={userroles?.sales?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          theme={theme}
          addAction={addStepItem}
          editAction={editStepItem}
        >
          <PopupStepItem></PopupStepItem>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
    </>
  );
}
