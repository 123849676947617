/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import TabsList from '../components/TabsList';
import Reminders from './Reminders';
import Actions from './Actions';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export default function ReminderOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { height, width } = useWindowDimensions();

  const todoref: any = useRef();
  const manageref: any = useRef();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };

  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const refresh = () => {
    if (pathname?.includes('todo')) {
      todoref?.current?.refresh();
    }
    if (pathname?.includes('manage')) {
      manageref?.current?.refresh();
    }
  };

  const bgcolor = '#F2B50508';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      bgcolor={bgcolor}
      bgimage={bgimage}
      refresh={refresh}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
            marginTop: 5,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Paper
          elevation={5}
          style={{
            borderRadius: 25,
            margin: 25,
            marginTop: 5,
            width: width - drawerWidth - 50,
            height: height - 135,
          }}
        >
          <TabsList
            handleSelect={handleSelect}
            isRTL={isRTL}
            list={formgroup}
            theme={theme}
            width={width - drawerWidth - 50}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            pathname={pathname}
          ></TabsList>
          <Paper
            style={{
              display: 'flex',
              height: height - 185,
              overflow: 'auto',
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            <Routes>
              <Route
                index
                element={<Navigate to={formgroup?.[0]?.path} replace />}
              />
              <Route
                path={'todo'}
                element={
                  <Actions
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                  ></Actions>
                }
              />
              <Route
                path={'manage'}
                element={
                  <Reminders
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                  ></Reminders>
                }
              />
            </Routes>
          </Paper>
        </Paper>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </Box>
    </PageLayout>
  );
}
