import { useMutation } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';
import { client } from '../graphql';
import { changeUserBranch } from '../graphql/mutation';
import BranchBox from './BranchBox';

export default function BranchesBox({
  branches,
  basename,
  dispatch,
  isRTL,
}: any) {
  const [switchBranch] = useMutation(changeUserBranch);

  const onSubmit = async (brn: any) => {
    const userData = await switchBranch({ variables: { branch: brn } });
    if (userData?.data?.changeUserBranch?.ok === true) {
      const { data, accessToken, refreshToken, tempId, template } =
        userData.data.changeUserBranch;
      const user = {
        ...data,
        roles: JSON.parse(data.roles),
      };
      await client.resetStore();
      const token = JSON.stringify({ accessToken, refreshToken });
      const temp = JSON.parse(template);
      dispatch({
        type: 'login',
        payload: { user, token, template: temp, tempId },
      });
      dispatch({
        type: 'setThemeId',
        payload:
          user?.themeId || user?.themeId === 0
            ? user?.themeId
            : template?.id - 1,
      });
      window.location.reload();
    }
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      {branches?.map((branch: any) => {
        const isCurrent = branch?.basename === basename;
        return (
          <Grid item xs={12} md={4}>
            <Box
              border={isCurrent ? 2 : undefined}
              borderColor={isCurrent ? 'lightgreen' : undefined}
              borderRadius={5}
              style={{
                cursor: 'pointer',
                padding: 2,
              }}
              onClick={() => onSubmit(branch.basename)}
            >
              <BranchBox
                isRTL={isRTL}
                isCurrent={isCurrent}
                branch={branch}
              ></BranchBox>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
