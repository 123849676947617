/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { Box, Button, fade, IconButton, Typography } from '@material-ui/core';

import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { isSameDay } from 'date-fns';
import { DateViewSwitcher } from '../../components/DateViewSwitcher';
import { getStartEndEventView } from '../../constants/time';
import CalenderLocal from '../CalenderLocal';
export default function DateNavigatorReportsList({
  setStart,
  setEnd,
  currentDate,
  currentViewName,
  currentDateChange,
  currentViewNameChange,
  endDate,
  endDateChange,
  isRTL,
  words,
  theme,
  views,
  color = theme.palette.primary.main,
  bgcolor = fade(theme.palette.secondary.light, 0.7),
}: any) {
  const [period, setPeriod] = useState(null);

  useEffect(() => {
    const { start, end, period }: any = getStartEndEventView({
      time: currentDate,
      view: currentViewName,
      isRTL,
      endDate,
    });

    if (
      currentViewName === '3Days' ||
      currentViewName === 'Week' ||
      currentViewName === 'Month' ||
      currentViewName === 'Year'
    ) {
      setPeriod(period);
    } else {
      setPeriod(null);
    }
    setStart(start);
    setEnd(end);
  }, [currentDate, currentViewName, endDate]);

  const stepForward = () => {
    if (currentViewName === 'Day') {
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      currentDateChange(tomorrow);
    } else if (currentViewName === '3Days') {
      const next3days = new Date(currentDate);
      next3days.setDate(next3days.getDate() + 3);
      currentDateChange(next3days);
    } else if (currentViewName === 'Week') {
      const nextweek = new Date(currentDate);
      nextweek.setDate(nextweek.getDate() + 7);
      currentDateChange(nextweek);
    } else if (currentViewName === 'Month') {
      const nextmonth = new Date(currentDate);
      nextmonth.setMonth(nextmonth.getMonth() + 1);
      currentDateChange(nextmonth);
    } else if (currentViewName === 'Year') {
      const nextyear = new Date(currentDate);
      nextyear.setFullYear(nextyear.getFullYear() + 1);
      currentDateChange(nextyear);
    }
  };
  const stepBackward = () => {
    if (currentViewName === 'Day') {
      const yestarday = new Date(currentDate);
      yestarday.setDate(yestarday.getDate() - 1);
      currentDateChange(yestarday);
    } else if (currentViewName === '3Days') {
      const last3days = new Date(currentDate);
      last3days.setDate(last3days.getDate() - 3);
      currentDateChange(last3days);
    } else if (currentViewName === 'Week') {
      const lastweek = new Date(currentDate);
      lastweek.setDate(lastweek.getDate() - 7);
      currentDateChange(lastweek);
    } else if (currentViewName === 'Month') {
      const lastmonth = new Date(currentDate);
      lastmonth.setMonth(lastmonth.getMonth() - 1);
      currentDateChange(lastmonth);
    } else if (currentViewName === 'Year') {
      const lastyear = new Date(currentDate);
      lastyear.setFullYear(lastyear.getFullYear() - 1);
      currentDateChange(lastyear);
    }
  };
  const disabled = isSameDay(new Date(), currentDate);
  return (
    <Box
      display="flex"
      style={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginRight: 10,
        marginLeft: 10,
        minWidth: 450,
      }}
    >
      <Button
        style={{
          height: 32,
          width: 32,
          padding: 0,
          margin: 0,
          marginLeft: 5,
          backgroundColor: bgcolor,
        }}
        size="small"
        disabled={disabled}
        onClick={() => {
          currentDateChange(new Date());
          endDateChange(new Date());
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: 13,
            color: disabled ? '#bbb' : color,
          }}
        >
          {isRTL ? 'الأن' : 'Now'}
        </Typography>
      </Button>

      <Box display="flex">
        <DateViewSwitcher
          currentViewName={currentViewName}
          onChange={currentViewNameChange}
          currentDateChange={currentDateChange}
          isRTL={isRTL}
          words={words}
          views={views}
        />
      </Box>
      <Box
        display="flex"
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {currentViewName !== 'Custom' && (
          <React.Fragment>
            <IconButton
              color="primary"
              style={{}}
              onClick={isRTL ? stepForward : stepBackward}
            >
              {isRTL && (
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 18, color: '#555' }}
                />
              )}
              {!isRTL && (
                <ArrowBackIosOutlinedIcon
                  style={{ fontSize: 18, color: '#555' }}
                />
              )}
            </IconButton>
            <IconButton
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={isRTL ? stepBackward : stepForward}
            >
              {isRTL && (
                <ArrowBackIosOutlinedIcon
                  style={{ fontSize: 18, color: '#555' }}
                />
              )}
              {!isRTL && (
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 18, color: '#555' }}
                />
              )}
            </IconButton>
          </React.Fragment>
        )}
        <CalenderLocal
          isRTL={isRTL}
          value={currentDate}
          onChange={currentDateChange}
          format="dd/MM/yyyy"
          mt={0}
          nobg
          period={period}
        ></CalenderLocal>
        {currentViewName === 'Custom' && (
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <CalenderLocal
              isRTL={isRTL}
              value={endDate}
              onChange={endDateChange}
              format="dd/MM/yyyy"
              mt={0}
              nobg
            ></CalenderLocal>
          </div>
        )}
      </Box>
    </Box>
  );
}
