import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../common/style.css';
import { useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { editorToolbar } from '../common/helpers';

const DraftEditor = ({ value, setValue, readOnly = false }) => {
  const [editorState, setEditorState] = useState(
    value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: any) => {
    setValue(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    setEditorState(editorState);
  };
  return (
    <div>
      <Editor
        // toolbarOnFocus
        toolbarHidden={readOnly}
        // readOnly={readOnly}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={editorToolbar}
      />
    </div>
  );
};

export default DraftEditor;
