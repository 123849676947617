import { gql } from '@apollo/client';

export default gql`
  mutation createSection(
    $branch: String
    $disabled: Boolean
    $additionId: String
    $name: String
    $nameAr: String
    $avatar: String
    $color: String
    $ispos: Boolean
    $order: Int
  ) {
    createSection(
      branch: $branch
      disabled: $disabled
      additionId: $additionId
      name: $name
      nameAr: $nameAr
      avatar: $avatar
      color: $color
      ispos: $ispos
      order: $order
    ) {
      ok
      message
      data
      error
    }
  }
`;
