import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import EventsDaysChart from '../EventsDaysChart';

function DaysEvents({ isRTL, theme, systemcolor, color, height }) {
  const { eventDays } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {eventDays && userroles?.appointments?.admin && (
        <EventsDaysChart
          dataKey="count"
          isRTL={isRTL}
          data={eventDays}
          color={clr}
          pricolor={theme.palette.primary.main}
          seccolor={theme.palette.secondary.main}
          height={height}
        ></EventsDaysChart>
      )}
    </Box>
  );
}

export default DaysEvents;
