import { Box, Typography, useTheme } from '@material-ui/core';
import MyIcon from '../Shared/MyIcon';

export default function ImportBtn({ open, isRTL }) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      style={{
        flexDirection: 'row',
        width: 100,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
      }}
      onClick={open}
    >
      <Typography
        style={{
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {isRTL ? 'استيراد' : 'Import'}
      </Typography>
      <MyIcon
        size={22}
        color={theme.palette.primary.main}
        icon="import"
      ></MyIcon>
    </Box>
  );
}
