import { gql } from '@apollo/client';

export default gql`
  query getLandingChartData($employeeId: String) {
    getLandingChartData(employeeId: $employeeId) {
      ok
      error
      message
      data
    }
  }
`;
