/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import PopupLayout from './PopupLayout';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { TabPanel, a11yProps } from '../components/TabPanel';
import { operationManamentTabs } from '../constants/rrule';
import ProcedureBox from './ProcedureBox';
import { useUsers } from '../../hooks';

const PopupFormOperationView = ({
  open,
  onClose,
  row,
  theme,
  isRTL,
  words,
  user,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const { width, height } = useWindowDimensions();
  const { allusers } = useUsers();
  const [value, setValue] = React.useState(0);
  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);
  };

  const onCloseView = () => {
    setValue(0);
    onClose();
  };

  const fusers = allusers
    .map((usr: any) => {
      const name = usr?.name ? usr?.name : usr?.username;
      if (usr?.isSuperAdmin) return undefined;
      return {
        ...usr,
        name,
        nameAr: name,
      };
    })
    ?.filter((x: any) => x);

  const renderMain = () => {
    return (
      <ProcedureBox
        row={row}
        user={user}
        words={words}
        isRTL={isRTL}
        theme={theme}
        setAlrt={setAlrt}
        height={height}
        width={width}
        users={fusers}
      ></ProcedureBox>
    );
  };
  const renderAttachments = () => {
    return <Typography>ATTACHMENTS</Typography>;
  };

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseView}
      title={row?.docNo}
      onSubmit={() => null}
      onlyclose
      theme={theme}
      alrt={alrt}
      maxWidth={'xl'}
      canceltitle={isRTL ? 'اغلاق' : 'Close'}
      mt={0}
      mb={0}
      preventclose
    >
      <Box style={{ height: height - 200 }}>
        <Box>
          {row && (
            <Box style={{ marginTop: 10 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="items"
                variant="fullWidth"
                textColor="primary"
                centered
              >
                {operationManamentTabs.map((item: any, index: any) => {
                  if (item.hide) {
                    return <div></div>;
                  }
                  return (
                    <Tab
                      style={{
                        backgroundColor: value === index ? '#ddd' : undefined,
                      }}
                      label={
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: 13 }}
                        >
                          {isRTL ? item.nameAr : item.name}
                        </Typography>
                      }
                      {...a11yProps(item.id)}
                    />
                  );
                })}
              </Tabs>
            </Box>
          )}
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ minWidth: 1200 }}>
              {row && (
                <Paper
                  elevation={0}
                  style={{
                    display: 'flex',
                    flex: 1,
                    height: height - 260,
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    padding: 20,
                    width: width - 400,
                  }}
                >
                  {operationManamentTabs.map((item: any, index: any) => {
                    return (
                      <TabPanel value={value} index={index}>
                        {item.ref === 'main' && renderMain()}
                        {item.ref === 'attachments' && renderAttachments()}
                      </TabPanel>
                    );
                  })}
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </PopupLayout>
  );
};

export default PopupFormOperationView;
