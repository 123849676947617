/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useReducer, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade, Typography } from '@material-ui/core';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';

import Brands from '../shared/Brands';
import Categories from '../shared/Categories';
import Products from '../shared/Products';
import Services from '../shared/Services';
import ExpenseItems from '../shared/ExpenseItems';

import Customers from '../shared/Customers';
import Suppliers from '../shared/Suppliers';
import Contacts from '../shared/Contacts';
import Employees from '../shared/Employees';
import Departments from '../shared/Departments';
import Resourses from '../shared/Resourses';
import ResourseTypes from '../shared/ResourseTypes';
import PCategories from '../shared/PCategories';
import ProcItems from '../shared/ProcItems';

import Users from '../shared/Users';
import Accounts from '../shared/Accounts';

import useCompany from '../../hooks/useCompany';
import { getStoreItem, setStoreItem } from '../../store';
import {
  initProductContext,
  ProductContext,
  productReducer,
} from '../../contexts/managment/product';
import {
  CustomerContext,
  customerReducer,
  DepartmentContext,
  departmentReducer,
  EmployeeContext,
  employeeReducer,
  initCustomerContext,
  initDepartmentContext,
  initEmployeeContext,
  initResourseContext,
  initSupplierContext,
  ResourseContext,
  resourseReducer,
  SupplierContext,
  supplierReducer,
} from '../../contexts/managment';
import TabsListVertical from '../components/TabsListVertical';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import StepItems from '../shared/Steptems';
import Sections from '../shared/Sections';

export default function BasicsAdd({
  isRTL,
  words,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const { height, width } = useWindowDimensions();
  const { company } = useCompany();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const it = formgroup?.filter((fg: any) => pathname?.includes(fg?.path))?.[0];

  const contref: any = useRef();
  const custref: any = useRef();
  const suppref: any = useRef();

  // products
  const productStoreState = getStoreItem('productStore', initProductContext);
  const [productStore, productDispatch] = useReducer(
    productReducer,
    productStoreState ? productStoreState : initProductContext
  );
  useEffect(() => {
    setStoreItem('productStore', productStore);
  }, [productStore]);

  // employees
  const employeeStoreState = getStoreItem('employeeStore', initEmployeeContext);
  const [employeeStore, employeeDispatch] = useReducer(
    employeeReducer,
    employeeStoreState ? employeeStoreState : initEmployeeContext
  );
  useEffect(() => {
    setStoreItem('employeeStore', employeeStore);
  }, [employeeStore]);

  // resourses
  const resourseStoreState = getStoreItem('resourseStore', initResourseContext);
  const [resourseStore, resourseDispatch] = useReducer(
    resourseReducer,
    resourseStoreState ? resourseStoreState : initResourseContext
  );
  useEffect(() => {
    setStoreItem('resourseStore', resourseStore);
  }, [resourseStore]);

  // departments
  const departmentStoreState = getStoreItem(
    'departmentStore',
    initDepartmentContext
  );
  const [departmentStore, departmentDispatch] = useReducer(
    departmentReducer,
    departmentStoreState ? departmentStoreState : initDepartmentContext
  );
  useEffect(() => {
    setStoreItem('departmentStore', departmentStore);
  }, [departmentStore]);

  const customerStoreState = getStoreItem('customerStore', initCustomerContext);
  const [customerStore, customerDispatch] = useReducer(
    customerReducer,
    customerStoreState ? customerStoreState : initCustomerContext
  );
  useEffect(() => {
    setStoreItem('customerStore', customerStore);
  }, [customerStore]);

  const supplierStoreState = getStoreItem('supplierStore', initSupplierContext);
  const [supplierStore, supplierDispatch] = useReducer(
    supplierReducer,
    supplierStoreState ? supplierStoreState : initSupplierContext
  );
  useEffect(() => {
    setStoreItem('supplierStore', supplierStore);
  }, [supplierStore]);

  const bgcolor = '#ae2ae212';

  const refresh = () => {
    if (pathname?.includes('contacts')) {
      contref?.current?.refresh();
    }
    if (pathname?.includes('customers')) {
      custref?.current?.refresh();
    }
    if (pathname?.includes('suppliers')) {
      suppref?.current?.refresh();
    }
  };

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          style={{
            borderRadius: 25,
            marginLeft: 25,
            marginTop: 25,
            width: width - drawerWidth - 25,
            height: height - 100,
          }}
        >
          <Box display={'flex'}>
            <Box
              style={{
                height: height - 100,
                paddingTop: 25,
                width: 200,
              }}
            >
              <TabsListVertical
                handleSelect={handleSelect}
                isRTL={isRTL}
                list={formgroup}
                theme={theme}
                color={fade(theme?.palette?.primary?.light, 0.3)}
                pathname={pathname}
                height={height}
                width={200}
              ></TabsListVertical>
            </Box>

            <Paper
              elevation={3}
              style={{
                display: 'flex',
                height: height - 100,
                overflow: 'auto',
                borderRadius: 25,
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  margin: 8,
                  marginLeft: 50,
                  marginRight: 50,
                }}
              >
                <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                  {isRTL ? it?.nameAr : it?.name}
                </Typography>
              </Box>

              <Routes>
                <Route
                  index
                  element={<Navigate to={formgroup?.[0]?.path} replace />}
                />
                <Route
                  path={'contacts'}
                  element={
                    <Contacts
                      ref={contref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></Contacts>
                  }
                />
                <Route
                  path={'customers'}
                  element={
                    <CustomerContext.Provider
                      value={{
                        state: customerStore,
                        dispatch: customerDispatch,
                      }}
                    >
                      <Customers
                        ref={custref}
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        toolbar
                      ></Customers>
                    </CustomerContext.Provider>
                  }
                />
                <Route
                  path={'suppliers'}
                  element={
                    <SupplierContext.Provider
                      value={{
                        state: supplierStore,
                        dispatch: supplierDispatch,
                      }}
                    >
                      <Suppliers
                        ref={suppref}
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                      ></Suppliers>
                    </SupplierContext.Provider>
                  }
                />
                <Route
                  path={'departments'}
                  element={
                    <DepartmentContext.Provider
                      value={{
                        state: departmentStore,
                        dispatch: departmentDispatch,
                      }}
                    >
                      <Departments
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                      ></Departments>
                    </DepartmentContext.Provider>
                  }
                />
                <Route
                  path={'employees'}
                  element={
                    <EmployeeContext.Provider
                      value={{
                        state: employeeStore,
                        dispatch: employeeDispatch,
                      }}
                    >
                      <Employees
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                      ></Employees>
                    </EmployeeContext.Provider>
                  }
                />
                <Route
                  path={'resourses'}
                  element={
                    <ResourseContext.Provider
                      value={{
                        state: resourseStore,
                        dispatch: resourseDispatch,
                      }}
                    >
                      <Resourses
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                      ></Resourses>
                    </ResourseContext.Provider>
                  }
                />
                <Route
                  path={'types'}
                  element={
                    <ResourseTypes
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></ResourseTypes>
                  }
                />
                <Route
                  path={'brands'}
                  element={
                    <Brands isRTL={isRTL} words={words} theme={theme}></Brands>
                  }
                />
                <Route
                  path={'categories'}
                  element={
                    <Categories
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></Categories>
                  }
                />
                <Route
                  path={'sections'}
                  element={
                    <Sections
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></Sections>
                  }
                />
                <Route
                  path={'products'}
                  element={
                    <ProductContext.Provider
                      value={{ state: productStore, dispatch: productDispatch }}
                    >
                      <Products
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                      ></Products>
                    </ProductContext.Provider>
                  }
                />
                <Route
                  path={'services'}
                  element={
                    <ProductContext.Provider
                      value={{ state: productStore, dispatch: productDispatch }}
                    >
                      <Services
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                      ></Services>
                    </ProductContext.Provider>
                  }
                />
                <Route
                  path={'expensesitems'}
                  element={
                    <ExpenseItems
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></ExpenseItems>
                  }
                />
                <Route
                  path={'procedurecats'}
                  element={
                    <PCategories
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></PCategories>
                  }
                />
                <Route
                  path={'procedureitems'}
                  element={
                    <ProcItems
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></ProcItems>
                  }
                />
                <Route
                  path={'stepitems'}
                  element={
                    <StepItems
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></StepItems>
                  }
                />
                <Route
                  path={'users'}
                  element={
                    <Users isRTL={isRTL} words={words} theme={theme}></Users>
                  }
                />
                <Route
                  path={'accounts'}
                  element={
                    <Accounts
                      toolbar
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                    ></Accounts>
                  }
                />
              </Routes>
            </Paper>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
}
