import { Box, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import ListboxComponent from './ListboxComponent';

export default function AutoFieldSimple({
  name,
  options,
  value,
  setSelectValue,
  selectError,
  openAdd,
  title,
  ms,
  openOnFocus,
  classes,
  disabled,
  isRTL,
  mb,
  width,
}: any) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={openAdd ? 11 : 12}>
        <Autocomplete
          openOnFocus={openOnFocus}
          options={options}
          ListboxComponent={ListboxComponent}
          getOptionLabel={(option: any) => {
            const nm = isRTL ? option?.nameAr : option?.name;
            return nm ? nm : '';
          }}
          getOptionSelected={(option, values) => option?.id === values?.id}
          renderOption={(option) => {
            const name = isRTL ? option?.nameAr : option?.name;
            return (
              <Box
                display="flex"
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  direction: isRTL ? 'rtl' : 'ltr',
                  textAlign: isRTL ? 'right' : 'left',
                  justifyContent: 'space-between',
                  position: 'relative',
                }}
              >
                <Typography
                  style={{
                    textAlign: isRTL ? 'right' : 'left',
                  }}
                  variant={isRTL ? 'subtitle1' : 'subtitle2'}
                >
                  {name}
                </Typography>
              </Box>
            );
          }}
          value={value}
          disabled={disabled}
          onChange={(_, newValue: any) => {
            setSelectValue(newValue);
          }}
          style={{
            marginInlineStart: ms ? ms : undefined,
            width: width ? width : undefined,
            direction: isRTL ? 'rtl' : 'ltr',
          }}
          classes={classes ? { input: classes.smallFont } : undefined}
          renderInput={(params) => (
            <TextField
              {...params}
              id={name}
              name={name}
              label={title}
              variant="outlined"
              error={selectError}
              style={{ marginBottom: mb }}
              fullWidth
            ></TextField>
          )}
        />
      </Grid>
    </Grid>
  );
}
