import { colors, Grid, Paper } from '@material-ui/core';
import { renderColorTag } from '../../common/helpers';
import ExpensesChart from '../charts/ExpensesChart';

export default function ExpenseDataBx({ row, theme, isRTL, height = 200 }) {
  const toatlExpenses = row?.toatlExpenses;
  const totalExpPetty = row?.totalExpPetty;
  const toatlExpPayable = row?.toatlExpPayable;
  const toatlProdExpenses = row?.toatlProdExpenses;

  const ecolor = colors.red[300];
  const pcolor = colors.deepOrange[500];
  const bcolor = colors.orange[500];
  const dcolor = colors.amber[500];
  const etitle = isRTL ? 'المصروفات' : 'Expenses';
  const ptitle = isRTL ? 'مصروف عهدة' : 'Products';
  const btitle = isRTL ? 'مصروف مورد' : 'Products';
  const dtitle = isRTL ? 'الاستهلاك' : 'Products';
  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <ExpensesChart
            ecolor={ecolor}
            pcolor={pcolor}
            bcolor={bcolor}
            dcolor={dcolor}
            row={row}
            theme={theme}
            height={height}
          ></ExpensesChart>
        </Grid>
        <Grid item xs={8} style={{ marginTop: 30 }}>
          {renderColorTag(etitle, ecolor, toatlExpenses || 0)}
          {renderColorTag(ptitle, pcolor, totalExpPetty || 0)}
          {renderColorTag(btitle, bcolor, toatlExpPayable || 0)}
          {renderColorTag(dtitle, dcolor, toatlProdExpenses || 0)}
        </Grid>
      </Grid>
    </Paper>
  );
}
