import * as yup from './yupSchemas';
import { userroles } from './user/check';

export { userroles, yup };

export const bgimage =
  'https://res.cloudinary.com/jadwalio/image/upload/v1675412238/5487739_rsqkqi.jpg';
// export const bgimage =
//   'https://res.cloudinary.com/jadwalio/image/upload/v1675411838/bgimage_q3junm.jpg';

export const appversion = '1.11';
