/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext } from 'react';
import Language from './LanguageSelect';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import GenTheme from './ThemeSelect';
import { GContextTypes } from '../../types';
import { GlobalContext } from '../../contexts';
import CalendarOptions from './CalendarOptions';
import Company from './Company';
import { userroles } from '../../common';
import { useMutation } from '@apollo/client';
import updateUserTheme from '../../graphql/mutation/updateUserTheme';
import GeneralOptions from './GeneralOptions';

const initcalendar = {
  duration: 30,
  start: 8.5,
  end: 21.5,
};

const Options = ({ isRTL, words, company, editCompany, isEmployee }: any) => {
  const { store, dispatch }: GContextTypes = useContext(GlobalContext);
  const lang = store?.lang;
  const calendar = store?.calendar;
  const tirms = store?.tirms;
  const fullscreen = store?.fullscreen;
  const scrollbar = store?.scrollbar;
  const themeId = store?.themeId;
  const daysales = store?.daysales;
  const minstock = store?.minstock;

  const [addUserTheme] = useMutation(updateUserTheme);

  const setLang = (lang: any) => {
    dispatch({ type: 'setLang', payload: lang });
    window.location.reload();
  };
  const setThemeId = (id: any) => {
    dispatch({ type: 'setThemeId', payload: id });
    addUserTheme({ variables: { themeId: id } });
  };

  const setCalendar = (data: any) => {
    dispatch({ type: 'setCalendar', payload: data });
  };
  const setTirms = (data: any) => {
    dispatch({ type: 'setTirms', payload: data });
  };
  const setFullscreen = (data: any) => {
    dispatch({ type: 'setFullscreen', payload: data });
  };
  const setScrollbar = (data: any) => {
    dispatch({ type: 'setScrollbar', payload: data });
  };
  const setDaysales = (data: any) => {
    dispatch({ type: 'setDaysales', payload: data });
  };
  const setMinstock = (data: any) => {
    dispatch({ type: 'setMinstock', payload: data });
  };

  if (!calendar) {
    setCalendar(initcalendar);
  }
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Box
      style={{
        margin: 10,
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#f5f5f5',
        marginLeft: 5,
        marginRight: 5,
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <GenTheme
                isRTL={isRTL}
                themeId={themeId}
                setThemeId={setThemeId}
              ></GenTheme>
            </Grid>
            <Grid item xs={12} md={4}>
              <GeneralOptions
                isRTL={isRTL}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                scrollbar={scrollbar}
                setScrollbar={setScrollbar}
                tirms={tirms}
                setTirms={setTirms}
                daysales={daysales}
                setDaysales={setDaysales}
                minstock={minstock}
                setMinstock={setMinstock}
              ></GeneralOptions>
              <Language lang={lang} setLang={setLang} isRTL={isRTL}></Language>
            </Grid>
            <Grid item xs={12} md={3}>
              <CalendarOptions
                calendar={calendar}
                words={words}
                setCalendar={setCalendar}
                isRTL={isRTL}
                isEmployee={isEmployee}
              ></CalendarOptions>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile &&
          (userroles?.isBranchAdmin ||
            userroles?.isBranchsAdmin ||
            userroles?.isSuperAdmin) && (
            <Grid item xs={12}>
              <Company
                company={company}
                editCompany={editCompany}
                words={words}
                isRTL={isRTL}
              ></Company>
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default Options;
