import { Box, colors, Typography } from '@material-ui/core';
import { carstatuss, weekdaysObj } from '../../constants/datatypes';
import { tasktypes } from '../../constants/rrule';

export const moneyFormat = (amount: number) => {
  if (amount) {
    if (!isNaN(amount)) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
  return '0.00';
};

export const qtyFormat = (amount: any) => {
  if (amount) {
    if (!isNaN(amount)) {
      return +parseFloat(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
  return '0';
};

export const currencyFormatter = ({ value }: any) => {
  return (
    <span
      style={{
        color: value < 0 ? colors.red[800] : colors.blue[950],
      }}
    >
      {moneyFormat(value)}
    </span>
  );
};
export const quantityFormatter = ({ value }: any) => {
  return (
    <span
      style={{
        color: value < 0 ? colors.red[800] : colors.blue[800],
      }}
    >
      {qtyFormat(value)}
    </span>
  );
};
export const getPrintPageDimentions = ({
  size = 'A4',
  header = true,
  footer = true,
}) => {
  const headeHight = 38;
  const footerHight = 19;
  const pageHeight =
    size === 'A4'
      ? 297
      : size === 'A4/2'
      ? 145
      : size === 'A5' || size === 'A4 landscape'
      ? 210
      : size === 'A5 landscape'
      ? 148
      : 297;
  const pageWidth =
    size === 'A4' || size === 'A4/2' || size === 'A5 landscape'
      ? 210
      : size === 'A5'
      ? 148
      : size === 'A4 landscape'
      ? 297
      : size === 'A5 landscape'
      ? 148
      : 210;
  const midHeight =
    pageHeight - (header ? headeHight : 0) - (footer ? footerHight : 0);
  return {
    pageHeight: `${pageHeight}mm`,
    pageWidth: `${pageWidth}mm`,
    midHeight: `${midHeight}mm`,
    headeHight: `${headeHight}mm`,
    footerHight: `${footerHight}mm`,
  };
};

export const editorToolbar = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'textAlign',
    'colorPicker',
    'remove',
    'list',
    // 'link',
    // 'embedded',
    // 'emoji',
    // 'image',
    'history',
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      'bold',
      'italic',
      'underline',
      // 'strikethrough',
      // 'monospace',
      // 'superscript',
      // 'subscript',
    ],
  },
  blockType: {
    inDropdown: true,
    options: [
      'Normal',
      'H1',
      'H2',
      'H3',
      'H4',
      'H5',
      'H6',
      'Blockquote',
      'Code',
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    options: [
      'Arial',
      'Georgia',
      'Impact',
      'Tahoma',
      'Times New Roman',
      'Verdana',
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    linkCallback: undefined,
  },
  embedded: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  image: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  },
  remove: { className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
  },
};
export const editorToolbarMini = {
  options: ['inline', 'fontSize', 'textAlign', 'colorPicker'],
  inline: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ],
  },
};

export const getTaskStatus = (tasks: any, isRTL: any) => {
  const rtasks = tasks.map((task: any) => {
    const { isClosed, start, end, freq, interval, typefree } = task;
    let type = '';
    if (typefree) {
      const tt = tasktypes.filter((cu: any) => cu.id === typefree)[0];
      type = tt ? (isRTL ? tt.nameAr : tt.name) : '';
    } else {
      if (freq) {
        type =
          freq === 1 || (freq === 3 && interval > 27)
            ? isRTL
              ? 'شهري'
              : 'Monthly'
            : freq === 2
            ? isRTL
              ? 'اسبوعي'
              : 'Weekly'
            : isRTL
            ? 'يومي'
            : 'Daily';
      }
    }

    if (isClosed) {
      return { ...task, status: isRTL ? 'مقفل' : 'Closed', type };
    } else {
      const startms = new Date(start).getTime();
      const endms = new Date(end).getTime();
      const now = new Date().getTime();
      if (now < startms) {
        return {
          ...task,
          status: isRTL ? 'لم يبدأ بعد' : 'Not Started',
          type,
        };
      }
      if (end && now > endms) {
        return {
          ...task,
          status: isRTL ? 'غير مقفل' : 'Not Closed',
          type,
        };
      }
      return {
        ...task,
        status: isRTL ? 'ساري' : 'In Progress',
        type,
      };
    }
  });
  return rtasks;
};

export const renderTag = ({
  value,
  color,
  bgcolor,
  fontSize = 12,
  width = 75,
}) => {
  if (!value) return <></>;
  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width,
        height: 24,
        backgroundColor: bgcolor,
        borderRadius: 5,
        margin: 4,
      }}
    >
      <Typography
        style={{
          fontSize,
          fontWeight: 'bold',
          color,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const renderColorTag = (title: any, color: any, value: any) => {
  return (
    <Box
      style={{
        display: 'flex',
        height: 30,
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Typography style={{ color, width: 150, fontSize: 14 }}>
        {title}
      </Typography>
      <Typography style={{ color, fontSize: 14 }}>
        {moneyFormat(value)}
      </Typography>
    </Box>
  );
};

export const simpleDateFormatter = (time: any) => {
  return new Date(time).toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const carstatusFormatter = ({ value, isRTL }: any) => {
  let status = isRTL ? 'متوفر' : 'Available';
  const color =
    value === 1
      ? colors.green[500]
      : value === 3
      ? colors.red[500]
      : value === 2
      ? colors.grey[500]
      : value === 10
      ? colors.blue[500]
      : '#333';
  const bgcolor =
    value === 1
      ? colors.green[50]
      : value === 3
      ? colors.red[50]
      : value === 2
      ? colors.grey[50]
      : value === 10
      ? colors.blue[50]
      : '#ddd';
  const cstat = carstatuss.filter((cs: any) => cs.id === value)?.[0];
  if (cstat?.nameAr) {
    status = isRTL ? cstat?.nameAr : cstat?.name;
  }
  return <>{renderTag({ value: status, color, bgcolor })}</>;
};

export const daysoffFormatter = ({ value, isRTL }: any) => {
  let days: any;
  if (value) {
    const obj = JSON.parse(value);
    days = Object.keys(obj).filter((k) => obj[k] === true);
  }
  if (days) {
    return (
      <>
        {days.map((day: any) => {
          return (
            <>{isRTL ? weekdaysObj[day]?.nameAr : weekdaysObj[day]?.name} </>
          );
        })}
      </>
    );
  } else {
    <>{value}</>;
  }
  return <></>;
};

export const rCell = (title: any, color: any) => (
  <Typography
    variant="body2"
    style={{
      fontSize: 12,
      color,
      fontWeight: 'bold',
    }}
  >
    {title}
  </Typography>
);
export const rCellMain = (title: any, color: any) => (
  <Typography
    style={{
      fontSize: 14,
      color,
      fontWeight: 'bold',
    }}
  >
    {title}
  </Typography>
);

export const nameToColor = (name: string, s = 70, l = 40) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const simpleDateFormatter2 = (time: any) => {
  return new Date(time).toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getTaskTimeAmountData = (task: any, time = new Date()) => {
  if (!task) return null;

  const { start, end, amount, dayCost } = task;

  const startms = new Date(start).getTime();
  const endms = new Date(end).getTime();
  const now = time.getTime();

  const daysnow = Math.ceil((now - startms) / (1000 * 60 * 60 * 24));
  const days = end
    ? Math.ceil((endms - startms) / (1000 * 60 * 60 * 24))
    : daysnow;
  const progress = Math.round((daysnow / days) * 100) / 100;
  const dayamount = dayCost ? dayCost : Math.round(amount / days);

  if (!end) {
    const amountnow = Number(dayCost) * daysnow;
    return {
      progress,
      days,
      daysnow,
      amountnow,
      remaining: amount - amountnow,
      amount,
      dayamount,
    };
  }

  if (now < startms) {
    return {
      progress: 0,
      days,
      daysnow: null,
      amountnow: null,
      remaining: amount,
      amount,
      dayamount,
    };
  }

  if (now > endms) {
    return {
      progress: 1,
      days,
      daysnow: days,
      amountnow: amount,
      remaining: 0,
      amount,
      dayamount,
    };
  }

  const amountnow = Math.round(dayamount * daysnow);
  const remaining = Math.round(amount - amountnow);

  return {
    progress,
    days,
    daysnow,
    amountnow,
    remaining,
    amount,
    dayamount,
  };
};

export const getColor = ({ systemcolor, theme, color }) => {
  let scolor: any;
  if (systemcolor === 'primary') scolor = theme.palette.primary.main;
  if (systemcolor === 'secondary') scolor = theme.palette.secondary.main;
  const clr = scolor ? scolor : color ? color : theme.palette.primary.main;
  return clr;
};
