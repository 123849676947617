import { colors, Grid, Paper, Typography } from '@material-ui/core';
import { renderTag, simpleDateFormatter2 } from '../../../common/helpers';

export default function TaskDataBx({ row, words, isRTL, height }) {
  const title = row?.title;
  const start = row?.start;
  const end = row?.end;
  const docNo = row?.docNo;
  const status = row?.status;
  const type = row?.type;
  const customerNameAr = row?.customerNameAr;
  const customerName = row?.customerName;
  const customerPhone = row?.customerPhone;
  const employeeNameAr = row?.employeeNameAr;
  const employeeName = row?.employeeName;
  const departmentNameAr = row?.departmentNameAr;
  const departmentName = row?.departmentName;
  const resourseNameAr = row?.resourseNameAr;
  const resourseName = row?.resourseName;

  let color;
  let bgcolor;
  if (status === 'مقفل' || status === 'Closed') {
    color = colors.blue[500];
    bgcolor = colors.blue[50];
  }
  if (status === 'لم يبدأ بعد' || status === 'Not Started') {
    color = colors.deepPurple[500];
    bgcolor = colors.deepPurple[50];
  }
  if (status === 'غير مقفل' || status === 'Not Closed') {
    color = colors.orange[500];
    bgcolor = colors.orange[50];
  }
  if (status === 'ساري' || status === 'In Progress') {
    color = colors.green[500];
    bgcolor = colors.green[50];
  }
  return (
    <Paper style={{ height, padding: 20 }}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ fontSize: 14 }}>{docNo}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
            {isRTL ? resourseNameAr : resourseName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{words.customer}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{isRTL ? customerNameAr : customerName}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{customerPhone}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{words.employee}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>{isRTL ? employeeNameAr : employeeName}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{words.department}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>{isRTL ? departmentNameAr : departmentName}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{words?.time}</Typography>
        </Grid>
        <Grid item xs={3} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Typography>{simpleDateFormatter2(start)}</Typography>
        </Grid>
        {end && (
          <Grid item xs={3}>
            <Typography>{simpleDateFormatter2(end)}</Typography>
          </Grid>
        )}
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography>
            {renderTag({ value: status, color, bgcolor })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {renderTag({
              value: type,
              color: '#333',
              bgcolor: colors.blueGrey[50],
            })}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Paper>
  );
}
