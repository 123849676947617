import { gql } from '@apollo/client';

export default gql`
  query getCustomers($isRTL: Boolean) {
    getCustomers(isRTL: $isRTL) {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        disabled
        additionId
        name
        nameAr
        phone
        mobile
        address
        email
        avatar
        logo
        headphoto

        amount
        totalinvoiced
        totalDiscount
        totalPurchaseInvoiced
        totalPurchasePaid
        totalPurchaseDiscount
        toatlProdExpenses
        totalpaid
        toatlExpenses
        totalkaidsdebit
        totalKaidscredit
        progress
        evQty
        evDone

        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone

        openingbalance

        driver
        licenseNo
        licenseDate
        national
        nationalNo
        nationalDate
        birthday
        msgId
        reminder
        breminder
        sreminder
        nreminder
        link

        area
        parentName
        parentPhone
        grade
        location {
          lat
          lng
        }
        locationLink
        houseno

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
