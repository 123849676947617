/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, updateDocNumbers, userroles } from '../../common';
import { useLazyQuery } from '@apollo/client';
import { getArchiveDocUploads } from '../../graphql';
import {
  attachmentsFormatter,
  opTypeFormatter,
  createdAtFormatter,
} from '../../Shared/colorFormat';
import { SearchTable } from '../../components';
import { Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import { useTemplate } from '../../hooks';
import PopupArchiveDoc from '../../pubups/PopupArchiveDoc';

const ArchiveDoc = forwardRef((props: any, ref: any) => {
  const { isRTL, words, company, theme } = props;

  const [rows, setRows] = useState([]);
  const [columns] = useState([
    { name: 'createdAt', title: words?.time },
    { name: 'opType', title: isRTL ? 'العملية' : 'Document' },
    { name: 'desc', title: words?.description },
    { name: 'stepName', title: isRTL ? 'الحالة' : 'Status' },
    { name: 'stepDesc', title: isRTL ? 'ملاحظة الحالة' : 'Status Note' },
    { name: 'attachments', title: isRTL ? 'المرفقات' : 'Attachments' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'createdAt', width: 200 },
    { columnName: 'opType', width: 200 },
    { columnName: 'desc', width: 250 },
    { columnName: 'stepName', width: 150 },
    { columnName: 'stepDesc', width: 250 },
    { columnName: 'attachments', width: 300 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'createdAt', togglingEnabled: false },
  ]);

  const [getDocs, docsData]: any = useLazyQuery(getArchiveDocUploads);

  useEffect(() => {
    getDocs();
  }, [getDocs]);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (docsData?.refetch) {
        docsData.refetch();
      }
    },
  }));
  const { tempoptions } = useTemplate();

  useEffect(() => {
    if (docsData?.data?.getArchiveDocUploads?.data) {
      const { data } = docsData.data.getArchiveDocUploads;
      const rdata = updateDocNumbers(data);
      setRows(rdata);
    }
  }, [docsData]);

  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <SortingState />
      <EditingState onCommitChanges={() => null} />
      <SearchState />
      <PagingState defaultCurrentPage={0} defaultPageSize={10} />
      <IntegratedSorting />
      <IntegratedFiltering />
      <IntegratedPaging />
      <DragDropProvider />
      <Table
        messages={{
          noData: isRTL ? 'لا يوجد بيانات' : 'no data',
        }}
        tableComponent={TableComponent}
        rowComponent={(props: any) => (
          <Table.Row {...props} style={{ height: 60 }}></Table.Row>
        )}
        columnExtensions={tableColumnExtensions}
      />

      <TableColumnReordering
        defaultOrder={[
          'attachments',
          'time',
          'opType',
          'desc',
          'stepName',
          'stepDesc',
        ]}
      />
      <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

      <TableHeaderRow
        showSortingControls
        titleComponent={({ children }) => {
          return (
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
              {children}
            </Typography>
          );
        }}
      />
      <TableColumnVisibility
        columnExtensions={tableColumnVisibilityColumnExtensions}
        defaultHiddenColumnNames={[]}
      />
      <DataTypeProvider
        for={['createdAt']}
        formatterComponent={createdAtFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['opType']}
        formatterComponent={opTypeFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['attachments']}
        formatterComponent={attachmentsFormatter}
      ></DataTypeProvider>
      <TableEditColumn
        showEditCommand={userroles?.financeall?.edit}
        commandComponent={Command}
      ></TableEditColumn>
      <Toolbar
        rootComponent={(props: any) => (
          <Toolbar.Root
            style={{
              position: 'absolute',
              left: isRTL ? 40 : undefined,
              right: isRTL ? undefined : 40,
              top: 62,
              zIndex: 115,
            }}
            {...props}
          ></Toolbar.Root>
        )}
      />
      <ColumnChooser />
      <PagingPanel
        pageSizes={tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]}
      />
      <SearchPanel
        inputComponent={(props: any) => {
          return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
        }}
      />

      <PopupEditing theme={theme}>
        <PopupArchiveDoc company={company}></PopupArchiveDoc>
      </PopupEditing>
    </Grid>
  );
});

export default ArchiveDoc;
