/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  SearchState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  Table,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { PopupEditing, Command, errorAlert } from '../../Shared';
import { PopupAccount } from '../../pubups';
import useAccounts from '../../hooks/useAccounts';
import { useBranches, useEmployees } from '../../hooks';
import { AlertLocal, SearchTable } from '../../components';
import { errorAccountAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { getAccounts, getEmployees } from '../../graphql';
import { createNewEmployeeAccount } from '../../graphql/mutation';
import { userroles } from '../../common';
import { TableComponent } from '../components/TableComponent';

export const getRowId = (row: { _id: any }) => row._id;

const Accounts = forwardRef((props: any, ref: any) => {
  const { isRTL, toolbar } = props;

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [columns] = useState([
    { name: isRTL ? 'nameAr' : 'name', title: isRTL ? 'اسم الحساب' : 'Name' },
    { name: 'code', title: isRTL ? 'رقم الحساب' : 'Code' },
    {
      name: isRTL ? 'parentAr' : 'parent',
      title: isRTL ? 'الحساب الرئيسي' : 'Main Account',
    },
    {
      name: isRTL ? 'employeeNameAr' : 'employeeName',
      title: isRTL ? 'الموظف' : 'Employee',
    },
  ]);
  const { accounts, addAccount, editAccount, removeAccount, accData } =
    useAccounts();
  const { branches } = useBranches();
  const { employees } = useEmployees();

  const [addNewEmployeeAccount] = useMutation(createNewEmployeeAccount, {
    refetchQueries: [{ query: getAccounts }, { query: getEmployees }],
  });

  useImperativeHandle(ref, () => ({
    refresh() {
      if (accData?.refetch) {
        accData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const account = accounts.filter((acc: any) => acc._id === _id)?.[0];
      if (account && account?.canedit) {
        const res = await removeAccount({ variables: { _id } });
        if (res?.data?.deleteAccount?.ok === false) {
          if (res?.data?.deleteAccount?.error.includes('related')) {
            await errorDeleteAlert(setAlrt, isRTL);
          } else {
            await errorAlert(setAlrt, isRTL);
          }
        }
      } else {
        await errorAccountAlert(setAlrt, isRTL);
      }
    }
  };

  const branchFormatter = ({ value }) => {
    const branch = branches.filter((br: any) => br.basename === value)[0];
    const name = isRTL ? 'nameAr' : 'name';
    return <div>{branch ? branch[name] : ''}</div>;
  };

  const emplusers = accounts.filter((fu: any) => fu?.employeeId);
  const emplIds = emplusers.map((eu: any) => eu?.employeeId);
  const emplnoaccount =
    emplIds && emplIds?.length > 0
      ? employees?.filter((emp: any) => !emplIds.includes(emp?._id))
      : employees;

  return (
    <>
      <Grid rows={accounts} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <IntegratedSorting />
        <IntegratedFiltering />

        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <DataTypeProvider
          for={['branch']}
          formatterComponent={branchFormatter}
        ></DataTypeProvider>

        <TableEditColumn
          showEditCommand={userroles?.finance?.edit}
          showDeleteCommand={userroles?.finance?.delete}
          showAddCommand={userroles?.finance?.add}
          commandComponent={Command}
        ></TableEditColumn>
        {toolbar && <Toolbar></Toolbar>}
        {!toolbar && (
          <Toolbar
            rootComponent={(props: any) => (
              <Toolbar.Root
                style={{
                  position: 'absolute',
                  left: isRTL ? 40 : undefined,
                  right: isRTL ? undefined : 40,
                  top: 62,
                  zIndex: 115,
                }}
                {...props}
              ></Toolbar.Root>
            )}
          />
        )}
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing addAction={addAccount} editAction={editAccount}>
          <PopupAccount
            accounts={accounts}
            emplnoaccount={emplnoaccount}
            addNewEmployeeAccount={addNewEmployeeAccount}
          ></PopupAccount>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
    </>
  );
});

export default Accounts;
