import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import { useTemplate } from '../../../../hooks';
import useCompany from '../../../../hooks/useCompany';
import useTasks from '../../../../hooks/useTasks';
import { getColor } from '../../common/helpers';
import ContractsStatus from '../ContractsStatus';

function ContractsBox({
  isRTL,
  theme,
  words,
  dark,
  systemcolor,
  color,
  height,
}) {
  const { tempwords, templateId } = useTemplate();
  const { tasks } = useTasks();
  const { company } = useCompany();
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {userroles?.tasks?.admin && tasks && (
        <ContractsStatus
          title={tempwords?.tasks}
          data={tasks}
          height={height}
          isRTL={isRTL}
          color={clr}
          templateId={templateId}
          photos={company?.photos}
        ></ContractsStatus>
      )}
    </Box>
  );
}

export default ContractsBox;
