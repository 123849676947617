/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, fade, Typography } from '@material-ui/core';

export default function TabsList({
  isRTL,
  list,
  handleSelect,
  theme,
  color,
  width,
  pathname,
}: any) {
  const [value, setValue] = React.useState(0);
  const TAB_MIN_WID = isRTL ? 175 : 200;

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
    handleSelect(list?.[newValue]);
  };

  const mrg = width - list?.length * TAB_MIN_WID;

  useEffect(() => {
    let index: any;
    list.map((it: any, i: any) => {
      if (pathname?.includes(it?.path)) {
        index = i;
      }
    });
    setValue(index);
  }, [pathname]);

  return (
    <Box
      style={{
        flexGrow: 1,
        backgroundColor: fade(theme.palette.primary.light, 0.1),
        height: 50,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        TabIndicatorProps={{ style: { height: 3 } }}
      >
        {list?.map((item: any, index: any) => {
          const isselect = pathname?.includes(item?.path);
          const { side } = item;
          const title = isRTL
            ? item.pnameAr
              ? item.pnameAr
              : item.nameAr
            : item.pname
            ? item.pname
            : item.name;
          return (
            <Tab
              style={{
                backgroundColor: isselect ? color : undefined,
                width: TAB_MIN_WID,
                borderTopRightRadius:
                  isRTL && index === 0
                    ? 25
                    : side
                    ? isRTL
                      ? undefined
                      : 25
                    : undefined,
                borderTopLeftRadius:
                  !isRTL && index === 0
                    ? 25
                    : side
                    ? isRTL
                      ? 25
                      : undefined
                    : undefined,
                marginRight: side ? (isRTL ? mrg : undefined) : undefined,
                marginLeft: side ? (isRTL ? undefined : mrg) : undefined,
              }}
              key={item._id}
              label={
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  {title}
                </Typography>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
