/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  dublicateAlert,
  errorAlert,
  messageAlert,
  successAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Box, colors, fade, Typography } from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal, TextFieldLocal } from '../components';
import { weekdaysNNo } from '../constants/datatypes';
import _ from 'lodash';
import { getPopupTitle } from '../constants/menu';
import { useCustomers, useTemplate } from '../hooks';
import PopupCustomer from './PopupCustomer';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import PopupResourses from './PopupResourses';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import PopupProject from './PopupProject';
import useProjects from '../hooks/useProjects';
import ServiceItemForm from '../Shared/ServiceItemForm';
import ItemsTable from '../Shared/ItemsTable';
import { invoiceClasses } from '../themes';
import { byweekdayOptions } from '../constants/rrule';
import RRule from 'rrule';
import { getDefaultTemplate, getInvDays, tafkeet } from '../common/helpers';
import { useReactToPrint } from 'react-to-print';
import { useLazyQuery } from '@apollo/client';
import { sleep } from '../Shared/helpers';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import usePrintTemplates from '../hooks/usePrintTemplates';
import PrintDownload from '../Shared/PrintDownload';
import ExtraList from '../Shared/ExtraList';
import useRetypes from '../hooks/useRetypes';
import { rid } from '../Sections/generator/print/renders';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const getParagraphs = ({ items, extra, isRTL }: any) => {
  if (items?.length === 0 && extra?.length === 0) return [];
  const list =
    !items || items?.length === 0
      ? []
      : _(items)
          .groupBy(isRTL ? 'categoryNameAr' : 'categoryNameAr')
          .map((array, key) => ({
            group: key,
            qty: array.length,
            subitems: array,
          }))
          .value();

  const ronelist =
    !items || items?.length === 0
      ? []
      : list.map((item: any) => {
          const subitems = item?.subitems?.map((sitem: any) => {
            return {
              id: sitem._id,
              title: isRTL ? sitem.nameAr : sitem.name,
              desc: sitem.desc,
            };
          });
          return {
            id: rid(),
            title: item?.group,
            subitems,
          };
        });
  return [...ronelist, ...extra];
};

const PopupTaskFull = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  employees,
  resourses,
  departments,
  projects,
  customers,
  theme,
  value = null,
  name = null,
  company,
}: any) => {
  const classes = invoiceClasses();

  const [saving, setSaving] = useState(false);
  const [ismemorized, setIsmemorized] = useState(false);
  const [memitem, setMemitem] = useState(null);
  const [tasktitle, setTasktitle]: any = useState(null);
  const [start, setStart]: any = useState(null);
  const [end, setEnd]: any = useState(null);
  const [printdate, setPrintdate]: any = useState(null);
  const [desc, setDesc]: any = useState(null);
  const [address, setAddress]: any = useState(null);
  const [extra, setExtra]: any = useState([]);
  const [deposit, setDeposit]: any = useState(null);
  const [rtypevalue, setRtypevalue] = useState<any>(null);
  const [rtype2value, setRtype2value] = useState<any>(null);

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [projvalue, setProjvalue] = useState<any>(
    name === 'projectId' ? value : null
  );
  const [projError, setProjError] = useState<any>(false);
  const projRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();
  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [custvalue, setCustvalue] = useState<any>(
    name === 'customerId' ? value : null
  );
  const [custError, setCustError] = useState<any>(false);
  const custRef: any = React.useRef();

  const [newtext, setNewtext] = useState('');
  const [openCust, setOpenCust] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openPro, setOpenPro] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);

  const [itemsList, setItemsList] = useState<any>([]);
  const [weekdays, setWeekdays]: any = useState([]);
  const [monthdays, setMonthdays] = useState([]);

  const [invdays, setInvdays] = useState(0);

  const [isatStart, setIsatStart] = useState(false);

  const [freq, setFreq] = useState(RRule.DAILY);
  const [count, setCount] = useState(1);
  const [dayCost, setDayCost] = useState(null);
  const [interval, setInterval] = useState(1);
  const [periodType, setPeriodType] = useState(1);
  const [many, setMany] = useState(false);

  const [total, setTotal] = useState<any>(0);
  const [info, setInfo] = useState<any>(null);
  const [calcs, setCalcs] = useState<any>(null);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const { addCustomer, editCustomer } = useCustomers();
  const { addDepartment, editDepartment } = useDepartments();
  const { addEmployee, editEmployee } = useEmployees();
  const { addResourse, editResourse } = useResourses();
  const { addProject, editProject } = useProjects();
  const { tempwords, tempoptions, taskExtra, taskoptions } = useTemplate();
  const { printtemplates } = usePrintTemplates();
  const { retypes } = useRetypes();

  const { register, handleSubmit, reset } = useForm({});
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const [loadCalcs, calcsData]: any = useLazyQuery(getGereralCalculation, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (row) {
      setMemitem(row);
    }
  }, [row, open]);

  const setExtraInfo = ({ item, value }) => {
    const newitem = { ...item, value };
    const newinfo = info?.map((initem: any) => {
      if (initem.id === item.id) {
        return newitem;
      } else {
        return initem;
      }
    });
    setInfo(newinfo);
  };

  const onChangeDayCost = (e: any) => {
    const value = Number(e.target.value);
    setDayCost(value);
  };
  const onChangeDeposit = (e: any) => {
    const value = Number(e.target.value);
    setDeposit(value);
  };

  useEffect(() => {
    if (isNew) {
      if (projvalue) {
        if (projvalue?.customerId && name !== 'customerId') {
          const cust = customers.filter(
            (dep: any) => dep._id === projvalue?.customerId
          )?.[0];
          setCustvalue(cust);
        }
        if (projvalue?.resourseId && name !== 'resourseId') {
          const res = resourses.filter(
            (dep: any) => dep._id === projvalue?.resourseId
          )?.[0];
          setResovalue(res);
        }
        if (projvalue?.employeeId && name !== 'employeeId') {
          const emp = employees.filter(
            (dep: any) => dep._id === projvalue?.employeeId
          )?.[0];
          setEmplvalue(emp);
        }
        if (projvalue?.departmentId && name !== 'departmentId') {
          const dept = departments.filter(
            (dep: any) => dep._id === projvalue?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
      }
    }
  }, [projvalue]);
  useEffect(() => {
    if (memitem && memitem._id) {
      const calcs = calcsData?.data?.['getGereralCalculation']?.data;
      if (calcs) {
        const data = JSON.parse(calcs);
        setCalcs(data);
      }
    }
  }, [memitem]);
  useEffect(() => {
    const itemsTotal = _.sumBy(itemsList, 'total');
    setTotal(itemsTotal);
  }, [itemsList]);

  useEffect(() => {
    if (isNew && !info) {
      setInfo(taskExtra);
    }
  }, [taskExtra]);

  useEffect(() => {
    if (start && end) {
      const days = getInvDays(start, end);
      setInvdays(days);
    } else {
      setInvdays(0);
    }
  }, [start, end]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const template = getDefaultTemplate(printtemplates, 'contract');

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Contract #${memitem?.docNo}`,
    removeAfterPrint: true,
  });

  const kmout = Number(info?.[0]?.value);
  const kmin = Number(info?.[1]?.value);
  const km = kmout && kmin ? kmin - kmout : null;
  const balance = memitem?.amount - calcs?.totalpaid;
  const duration =
    freq === 1 || (freq === 3 && interval > 27)
      ? isRTL
        ? 'شهري'
        : 'Monthly'
      : freq === 2
      ? isRTL
        ? 'اسبوعي'
        : 'Weekly'
      : isRTL
      ? 'يومي'
      : 'Daily';

  const paragraphs = tempoptions?.contracting
    ? getParagraphs({ items: itemsList, extra, isRTL })
    : [];
  const printData = {
    no: memitem?.docNo,
    amount: memitem?.amount,
    start,
    end,
    resovalue,
    custvalue,
    resourseName: isRTL ? resovalue?.nameAr : resovalue?.name,
    resoursePlate: resovalue?.plate,
    resourseInsurance: resovalue?.insurance,
    customerName: isRTL ? custvalue?.nameAr : custvalue?.name,
    customerArea: custvalue?.area,
    customerAddress: custvalue?.address,
    customerPhone: custvalue?.phone,
    customerDriver: custvalue?.driver,
    customerLicenseNo: custvalue?.licenseNo,
    customerLicenseDate: custvalue?.licenseDate,
    customerNational: custvalue?.national,
    customerNationalNo: custvalue?.nationalNo,
    customerNationalDate: custvalue?.nationalDate,
    totalpaid: calcs?.totalpaid,
    notes: info?.[2]?.value,
    kmout,
    kmin,
    km,
    daily:
      memitem?.dayCost > 0
        ? memitem?.dayCost
        : memitem?.amount / memitem?.evQty || 0,
    price: memitem?.amount,
    isRTL,
    tafkeet: tafkeet(memitem?.amount, isRTL),
    balance,
    duration,
    paragraphs,
    address,
    desc,
    title: memitem?.title,
    printdate,
  };

  const openDepartment = () => {
    setOpenDep(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openProject = () => {
    setOpenPro(true);
  };
  const onCloseProject = () => {
    setOpenPro(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };
  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };

  const onNewCustChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };
  const onNewDepartChange = (nextValue: any) => {
    setDepartvalue(nextValue);
  };
  const onNewProjChange = (nextValue: any) => {
    setProjvalue(nextValue);
  };
  const onNewEmplChange = (nextValue: any) => {
    setEmplvalue(nextValue);
  };
  const onNewResoChange = (nextValue: any) => {
    setResovalue(nextValue);
  };

  const isemployee = user?.isEmployee && user?.employeeId;

  useEffect(() => {
    if (isemployee) {
      const emp = employees.filter(
        (em: any) => em._id === user.employeeId
      )?.[0];
      setEmplvalue(emp);
    }
  }, [user, employees]);

  useEffect(() => {
    if (isNew) {
      const start = new Date();
      // start.setHours(8, 0, 0);
      setStart(start);
      setPrintdate(start);
    }
  }, [open]);

  useEffect(() => {
    if (memitem && memitem._id) {
      loadCalcs({ variables: { contractId: memitem._id } });
      const depId = memitem.departmentId;
      const empId = memitem.employeeId;
      const custId = memitem.customerId;
      const proId = memitem.projectId;
      const resId = memitem.resourseId;
      const start = memitem?.start ? new Date(memitem?.start) : null;
      const end = memitem?.end ? new Date(memitem?.end) : null;
      const printd = memitem?.printdate ? new Date(memitem?.printdate) : null;
      setFreq(memitem?.freq || 1);
      setMany(memitem?.many || false);
      setInterval(memitem?.interval || 1);
      setDeposit(memitem?.deposit);

      if (memitem?.info) {
        setInfo(JSON.parse(memitem?.info));
      } else {
        setInfo(taskExtra);
      }
      if (!isNew) {
        setStart(start);
        setEnd(end);
        setPrintdate(printd);
      }
      setTasktitle(memitem?.title);
      setPeriodType(
        memitem?.periodType
          ? memitem?.periodType
          : memitem?.freq === RRule.DAILY && memitem?.interval === 30
          ? 30
          : memitem?.freq === RRule.MINUTELY
          ? 31
          : memitem?.freq === RRule.WEEKLY
          ? 7
          : 1
      );
      setCount(memitem?.count ? memitem?.count : memitem?.evQty || 1);
      const wd = memitem?.weekdays ? JSON.parse(memitem?.weekdays) : [];
      const awd = wd.map(
        (d: any) => byweekdayOptions.filter((a: any) => a.id === d.id)?.[0]
      );
      awd.filter((x: any) => x);
      setWeekdays(awd);
      const md = memitem?.monthdays ? JSON.parse(memitem?.monthdays) : [];
      setMonthdays(md);
      setDayCost(memitem?.dayCost || 0);
      if (memitem?.periodType === 11 || memitem?.periodType === 33) {
        setMonthdays([{ id: 1, name: '1', nameAr: '1', value: 1 }]);
      }
      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (proId) {
        const empl = projects.filter((emp: any) => emp._id === proId)[0];
        setProjvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }
      if (custId) {
        const cust = customers.filter((cu: any) => cu._id === custId)[0];
        setCustvalue(cust);
      }
      if (ismemorized && !isNew) {
        setIsmemorized(ismemorized);
      }
      setIsatStart(memitem?.isatStart);
      setDesc(memitem?.desc);
      setAddress(memitem?.address);
      if (memitem?.extra) {
        const ext = JSON.parse(memitem?.extra);
        setExtra(ext);
      }
      if (memitem?.retypeId) {
        const depart = retypes.filter(
          (dep: any) => dep._id === memitem.retypeId
        )[0];
        setRtypevalue(depart);
      }
      if (memitem?.retype2Id) {
        const depart = retypes.filter(
          (dep: any) => dep._id === memitem.retype2Id
        )[0];
        setRtype2value(depart);
      }
      setItemsList(memitem?.items);
    }
  }, [memitem]);
  const customer = custvalue
    ? {
        customerId: custvalue._id,
        customerName: custvalue.name,
        customerNameAr: custvalue.nameAr,
        customerPhone: custvalue.phone,
      }
    : {
        customerId: undefined,
        customerName: undefined,
        customerNameAr: undefined,
        customerPhone: undefined,
      };

  const department = departvalue
    ? {
        departmentId: departvalue._id,
        departmentName: departvalue.name,
        departmentNameAr: departvalue.nameAr,
        departmentColor: departvalue.color,
      }
    : {
        departmentId: undefined,
        departmentName: undefined,
        departmentNameAr: undefined,
        departmentColor: undefined,
      };
  const employee = emplvalue
    ? {
        employeeId: emplvalue._id,
        employeeName: emplvalue.name,
        employeeNameAr: emplvalue.nameAr,
        employeeColor: emplvalue.color,
        employeePhone: emplvalue.phone,
      }
    : {
        employeeId: undefined,
        employeeName: undefined,
        employeeNameAr: undefined,
        employeeColor: undefined,
        employeePhone: undefined,
      };
  const resourse = resovalue
    ? {
        resourseId: resovalue._id,
        resourseName: resovalue.name,
        resourseNameAr: resovalue.nameAr,
        resourseColor: resovalue.color,
      }
    : {
        resourseId: undefined,
        resourseName: undefined,
        resourseNameAr: undefined,
        resourseColor: undefined,
      };
  const retype = rtypevalue
    ? {
        retypeId: rtypevalue._id,
        retypeName: rtypevalue.name,
        retypeNameAr: rtypevalue.nameAr,
        retypeColor: rtypevalue.color,
      }
    : {
        retypeId: undefined,
        retypeName: undefined,
        retypeNameAr: undefined,
        retypeColor: undefined,
      };

  const retype2 = rtype2value
    ? {
        retype2Id: rtype2value._id,
        retype2Name: rtype2value.name,
        retype2NameAr: rtype2value.nameAr,
        retype2Color: rtype2value.color,
      }
    : {
        retype2Id: undefined,
        retype2Name: undefined,
        retype2NameAr: undefined,
        retype2Color: undefined,
      };

  const project = projvalue
    ? {
        projectId: projvalue._id,
        projectName: projvalue.name,
        projectNameAr: projvalue.nameAr,
        projectColor: projvalue.color,
      }
    : {
        projectId: undefined,
        projectName: undefined,
        projectNameAr: undefined,
        projectColor: undefined,
      };

  const resetAllForms = () => {
    setStart(null);
    setPrintdate(null);
    setDeposit(null);
    setDesc(null);
    setAddress(null);
    setExtra([]);
    setEnd(null);
    setCustvalue(null);
    setDepartvalue(null);
    setProjvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setTasktitle(null);
    setSaving(false);
    setIsmemorized(false);
    setItemsList([]);
    setCount(1);
    setInterval(1);
    setPeriodType(1);
    setFreq(RRule.DAILY);
    setMany(false);
    setTotal(0);
    setInfo(null);
    setIsatStart(false);
    setMonthdays([]);
    setDayCost(null);
    setCalcs(null);
    setInvdays(0);
    setWeekdays([]);
    setMemitem(null);
    setRtypevalue(null);
    setRtype2value(null);
  };

  const onSubmit = async () => {
    if (!start) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    if (taskoptions?.endrequire && !end) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب اضافة تاريخ الانتهاء' : 'End Date should be added'
      );
      return;
    }
    if (!tasktitle) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة اسم للعقد' : 'Please add Contract title'
      );
      return;
    }
    if (taskoptions?.customerrequire && !custvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة عميل' : 'Please add Customer'
      );
      return;
    }
    if (taskoptions?.resourserequire && !resovalue) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يرجى اضافة ${tempwords?.resourse}`
          : `Please add ${tempwords?.resourse}`
      );
      return;
    }
    setSaving(true);

    const variables: any = {
      _id: !isNew && memitem && memitem._id ? memitem._id : undefined, // is it new or edit
      title: tasktitle ? tasktitle : custvalue?.name,
      start,
      end,
      amount: total * count,
      tasktype: 3, // 1: single event, 2: multi events, 3: no events - only items and time
      items: itemsList?.length > 0 ? JSON.stringify(itemsList) : undefined,
      customer,
      department,
      employee,
      resourse,
      retype,
      retype2,
      project,
      info: JSON.stringify(info),
      ismemorized,
      memId: isNew && memitem ? memitem?._id : undefined,
      freq,
      many,
      count,
      interval,
      periodType,
      periodCost: total,
      dayCost,
      weekdays: JSON.stringify(weekdays),
      monthdays: JSON.stringify(monthdays),
      isatStart,
      desc,
      printdate,
      deposit,
      address,
      extra: extra ? JSON.stringify(extra) : null,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      mutate({ variables });
      await sleep(3);
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
    setSaving(false);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const date = memitem?.start ? new Date(memitem?.start) : new Date();
  const day = weekdaysNNo?.[date.getDay()];

  const title = getPopupTitle('task', isNew);
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      // onlyclose={memitem?.isNotEditable}
      alrt={alrt}
      print={
        !isNew && [4, 5, 7, 8].includes(company?.tempId)
          ? handleReactPrint
          : undefined
      }
      maxWidth={'lg'}
      fs
      preventclose
      saving={saving}
      canceltitle={isRTL ? 'اغلاق' : 'close'}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              {taskoptions?.printdate && (
                <Grid item xs={12}>
                  <CalenderLocal
                    label={isRTL ? 'التاريخ' : 'Date'}
                    value={printdate}
                    onChange={(d: any) => setPrintdate(d)}
                    format="dd/MM/yyyy"
                    mb={0}
                    style={{
                      marginTop: 0,
                      width: 180,
                    }}
                  ></CalenderLocal>
                </Grid>
              )}
              <Grid item xs={3}>
                <CalenderLocal
                  label={words.start}
                  value={start}
                  onChange={(d: any) => setStart(d)}
                  format={
                    tempoptions?.contracting
                      ? 'dd/MM/yyyy'
                      : 'dd/MM/yyyy - hh:mm'
                  }
                  time={!tempoptions?.contracting}
                  mb={0}
                  style={{
                    marginTop: 0,
                    width: 180,
                  }}
                ></CalenderLocal>
              </Grid>
              <Grid item xs={3}>
                <CalenderLocal
                  value={end}
                  label={words.end}
                  onChange={(d: any) => {
                    if (d) {
                      setEnd(d);
                    } else {
                      setEnd(null);
                    }
                  }}
                  format={
                    tempoptions?.contracting
                      ? 'dd/MM/yyyy'
                      : 'dd/MM/yyyy - hh:mm'
                  }
                  time={!tempoptions?.contracting}
                  style={{
                    marginTop: 0,
                    width: 180,
                  }}
                  mb={0}
                ></CalenderLocal>
              </Grid>
              <Grid item xs={2}>
                {invdays > 0 && (
                  <Typography
                    style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
                  >{`( ${invdays} ${isRTL ? 'يوم' : 'Day'} )`}</Typography>
                )}
              </Grid>
              {taskoptions?.customer && (
                <Grid item xs={6}>
                  <AutoFieldLocal
                    name="customer"
                    title={tempwords?.customer}
                    words={words}
                    options={customers}
                    value={custvalue}
                    setSelectValue={setCustvalue}
                    setSelectError={setCustError}
                    selectError={custError}
                    refernce={custRef}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    showphone
                    openAdd={openCustomer}
                    disabled={name === 'customerId'}
                  ></AutoFieldLocal>
                </Grid>
              )}
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <TextFieldLocal
                  required
                  autoFocus={true}
                  name="tasktitle"
                  label={words.name}
                  value={tasktitle}
                  onChange={(e: any) => setTasktitle(e.target.value)}
                  row={memitem}
                  fullWidth
                  mb={0}
                />
              </Grid>
              {taskoptions?.address && (
                <Grid item xs={6}>
                  <TextFieldLocal
                    autoFocus={true}
                    name="address"
                    label={words.theaddress}
                    value={address}
                    onChange={(e: any) => setAddress(e.target.value)}
                    row={memitem}
                    fullWidth
                    mb={0}
                  />
                </Grid>
              )}
              {taskoptions?.desc && (
                <Grid item xs={12}>
                  <TextFieldLocal
                    name="desc"
                    onChange={(e: any) => setDesc(e.target.value)}
                    value={desc}
                    label={words.info}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    mb={0}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  style={{
                    backgroundColor: fade(colors.grey[400], 0.2),
                    marginTop: 10,
                    borderRadius: 10,
                    height: 290,
                  }}
                >
                  <Box display="flex">
                    <ServiceItemForm
                      addItem={addItemToList}
                      words={words}
                      classes={classes}
                      user={user}
                      isRTL={isRTL}
                      setAlrt={setAlrt}
                      taskv={memitem}
                      itemrelations={
                        tempoptions?.invoiceitemrelations ||
                        tempoptions?.itemrelations
                      }
                    ></ServiceItemForm>
                  </Box>
                  <Box>
                    <ItemsTable
                      rows={itemsList}
                      editItem={editItemInList}
                      removeItem={removeItemFromList}
                      isRTL={isRTL}
                      words={words}
                      user={user}
                      itemrelations={
                        tempoptions?.invoiceitemrelations ||
                        tempoptions?.itemrelations
                      }
                    ></ItemsTable>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <TextFieldLocal
                  name="total"
                  label={words.total}
                  type="number"
                  value={total}
                  onChange={() => null}
                  row={memitem}
                  fullWidth
                  mb={0}
                />
              </Grid>
              <Grid item xs={9}></Grid>
              {tempoptions?.docrelations && (
                <>
                  {tempoptions?.project && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="project"
                        title={tempwords?.project}
                        words={words}
                        options={projects}
                        value={projvalue}
                        setSelectValue={setProjvalue}
                        setSelectError={setProjError}
                        selectError={projError}
                        refernce={projRef}
                        register={register}
                        isRTL={isRTL}
                        fullWidth
                        openAdd={openProject}
                        showphone
                        disabled={name === 'projectId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {taskoptions?.department && tempoptions?.department && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="department"
                        title={tempwords?.department}
                        words={words}
                        options={departments.filter(
                          (d: any) => d.depType === 1
                        )}
                        value={departvalue}
                        setSelectValue={setDepartvalue}
                        setSelectError={setDepartError}
                        selectError={departError}
                        refernce={departRef}
                        register={register}
                        openAdd={openDepartment}
                        isRTL={isRTL}
                        fullWidth
                        disabled={name === 'departmentId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {taskoptions?.employee && tempoptions?.employee && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="employee"
                        title={tempwords?.employee}
                        words={words}
                        options={employees.filter((emp: any) => !emp.disabled)}
                        disabled={isemployee || name === 'employeeId'}
                        value={emplvalue}
                        setSelectValue={setEmplvalue}
                        setSelectError={setEmplError}
                        selectError={emplError}
                        refernce={emplRef}
                        register={register}
                        openAdd={openEmployee}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {taskoptions?.resourse && tempoptions?.resourse && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="resourse"
                        title={tempwords?.resourse}
                        words={words}
                        options={resourses}
                        disabled={name === 'resourseId'}
                        value={resovalue}
                        setSelectValue={setResovalue}
                        setSelectError={setResoError}
                        selectError={resoError}
                        refernce={resoRef}
                        register={register}
                        openAdd={openResourse}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            <Grid container spacing={1}>
              {!isNew && (
                <Grid item md={12}>
                  <PrintDownload
                    company={company}
                    printData={printData}
                    componentRef={componentRef}
                    handleReactPrint={handleReactPrint}
                    isRTL={isRTL}
                    template={template}
                    no={row?.docNo}
                    element="contract"
                  ></PrintDownload>
                </Grid>
              )}
              {tempoptions?.retype && taskoptions?.retype && (
                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="retype"
                    title={tempwords?.evretype}
                    words={words}
                    options={retypes.filter((dep: any) => dep.reType === 4)}
                    value={rtypevalue}
                    setSelectValue={setRtypevalue}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              {tempoptions?.retype2 && taskoptions?.retype2 && (
                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="retype2"
                    title={tempwords?.evretype2}
                    words={words}
                    options={retypes.filter((dep: any) => dep.reType === 5)}
                    value={rtype2value}
                    setSelectValue={setRtype2value}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {taskoptions?.dayCost && (
                    <Grid item xs={6}>
                      <TextFieldLocal
                        name="dayCost"
                        label={words.dayCost}
                        value={dayCost}
                        onChange={onChangeDayCost}
                        type="number"
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                  )}
                  {taskoptions?.dayCost && <Grid item xs={6}></Grid>}
                  {taskoptions?.info &&
                    info?.map((extra: any) => (
                      <Grid item xs={extra.multiline ? 12 : 6}>
                        <TextFieldLocal
                          name={extra.name}
                          label={isRTL ? extra.nameAr : extra.name}
                          value={extra.value}
                          type={extra.type}
                          multiline={extra.multiline}
                          rows={extra.multiline ? 4 : 1}
                          onChange={(e: any) =>
                            setExtraInfo({ item: extra, value: e.target.value })
                          }
                          fullWidth
                          mb={0}
                        />
                      </Grid>
                    ))}
                  {taskoptions?.deposit && (
                    <Grid item xs={12}>
                      <TextFieldLocal
                        name="deposit"
                        label={isRTL ? 'مبلغ تأمين' : 'Insurance Payment'}
                        value={deposit}
                        onChange={onChangeDeposit}
                        type="number"
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {taskoptions?.extra && (
                <Grid item xs>
                  <ExtraList
                    isRTL={isRTL}
                    list={extra}
                    setList={setExtra}
                  ></ExtraList>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
        <PopupProject
          newtext={newtext}
          employees={employees}
          departments={departments}
          resourses={resourses}
          customers={customers}
          open={openPro}
          onClose={onCloseProject}
          isNew={true}
          setNewValue={onNewProjChange}
          row={null}
          addAction={addProject}
          editAction={editProject}
        ></PopupProject>
      </Box>
    </PopupLayout>
  );
};

export default PopupTaskFull;
