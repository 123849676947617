import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import MonthsEmpChart from '../MonthsEmpChart';
import { useTemplate } from '../../../../hooks';

function MonthEmplEvents({ isRTL, theme, systemcolor, color, height }) {
  const { eventsMonth } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });
  const { tempwords } = useTemplate();

  return (
    <Box>
      {eventsMonth && userroles?.appointments?.admin && (
        <MonthsEmpChart
          data={eventsMonth}
          isRTL={isRTL}
          color={clr}
          employee={true}
          title={
            isRTL
              ? `${tempwords?.appointments} هذا الشهر بحسب الموظف`
              : `Month ${tempwords?.appointments} - Employee `
          }
          height={height}
        ></MonthsEmpChart>
      )}
    </Box>
  );
}

export default MonthEmplEvents;
