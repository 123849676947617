/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Grid, Typography } from '@material-ui/core';
import { eventStatus } from '../../constants';
import { getCalendarResourses } from '../../common/helpers';

const DepartemplSelect = ({
  value,
  setValue,
  isRTL,
  count,
  theme,
  refresh,
  employees,
  departments,
  setResourseData,
  loading,
  tempoptions,
}: any) => {
  const onchange = (e: any) => {
    const val = e.target.value;
    setValue(val);
    let res: any;
    if (val === 'employeeId') {
      res = employees;
    }
    if (val === 'status') {
      res = eventStatus;
    }
    // if (val === 'departmentId') {
    //   res = departments;
    // }
    const resourses = getCalendarResourses(res, val, 'Data');
    setResourseData(resourses);
  };

  useEffect(() => {
    if (!value && employees && employees.length > 0) {
      const resourses = getCalendarResourses(employees, 'employeeId', 'Data');
      setValue('employeeId');
      setResourseData(resourses);
    }
  }, [employees]);

  return (
    <Box m={1}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <RadioGroup
            aria-label="Views"
            name="views"
            row
            value={value}
            onChange={onchange}
          >
            {tempoptions?.employee && (
              <FormControlLabel
                value="employeeId"
                control={<Radio color="primary" />}
                label={isRTL ? 'الموظف' : 'Employee'}
              />
            )}
            {/* {tempoptions?.employee && (
              <FormControlLabel
                value="departmentId"
                control={<Radio color="primary" />}
                label={isRTL ? 'القسم' : 'Department'}
              />
            )} */}
            <FormControlLabel
              value="status"
              control={<Radio color="primary" />}
              label={isRTL ? 'الحالة' : 'Status'}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={4}>
          {count > 0 && (
            <Box display="flex">
              <Typography
                style={{
                  marginTop: 10,
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 'bold',
                }}
              >
                {isRTL ? 'العدد : ' : 'Count : '} {count}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DepartemplSelect;
