import { gql } from '@apollo/client';

export default gql`
  query getObjectEvents(
    $departmentId: String
    $employeeId: String
    $projectId: String
    $resourseId: String
    $customerId: String
    $supplierId: String
    $contractId: String
    $start: Date
    $end: Date
  ) {
    getObjectEvents(
      departmentId: $departmentId
      employeeId: $employeeId
      projectId: $projectId
      resourseId: $resourseId
      customerId: $customerId
      supplierId: $supplierId
      contractId: $contractId
      start: $start
      end: $end
    ) {
      ok
      error
      data {
        _id
        id
        branch
        title
        startDate
        endDate
        duration
        allDay
        rRule
        reminder
        exDate
        allowDrag
        location {
          lat
          lng
        }
        groupId
        locationLink
        address
        houseno
        autoNo
        docNo
        priority
        amount
        status
        steps

        customerId
        customerName
        customerNameAr
        customerPhone

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone
        workersIds

        contractId
        contractName
        contractNameAr

        projectId
        projectName
        projectNameAr
        projectColor
        resourseId
        resourseName
        resourseNameAr
        resourseColor

        retypeId
        retypeName
        retypeNameAr
        retypeColor

        retype2Id
        retype2Name
        retype2NameAr
        retype2Color

        invoiceId
        link
        sent
        paid
        invoiced
        procs

        attachments

        refNo
        refType
        userId

        createdAt
        updatedAt
      }
    }
  }
`;
