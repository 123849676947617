/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { userroles } from '../../common';
import useCompany from '../../hooks/useCompany';
import UserBox from '../../Shared/UserBox';

export default function PageLayout(props: any) {
  const {
    children,
    menuitem,
    isRTL,
    refresh,
    theme,
    bgcolor = '#ffffff',
    loading,
    height = 49,
  } = props;

  const { company } = useCompany();

  return (
    <>
      <Hidden xsDown implementation="css">
        <div
          style={{
            paddingLeft: 25,
            paddingRight: 25,
            height,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: bgcolor,
          }}
        >
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            {isRTL ? menuitem.titleAr : menuitem.titleEn}
          </Typography>
          {userroles.isBranchsAdmin && (
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontSize: 18,
              }}
            >
              {isRTL ? company?.nameAr : company?.name}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UserBox
              refresh={refresh}
              loading={loading}
              isRTL={isRTL}
              theme={theme}
            ></UserBox>
          </Box>
        </div>
        <div
          style={{ width: '100%', height: 1, backgroundColor: '#eee' }}
        ></div>
      </Hidden>
      {React.cloneElement(children, { ...props })}
    </>
  );
}
