import { userroles } from '../common/check';
import { getStoreItem } from '../store';

const store = getStoreItem('store');
// const languages = getStoreItem('languages');
const template = store?.template;
const lang = store?.lang;
const tempwords = template?.words?.[lang];
const options = template?.options;
const isRTL = lang === 'ar';
// const words = isRTL ? languages?.ar?.words : languages?.en?.words;

export const mainmenu = [
  {
    id: 0,
    name: 'home',
    uri: '/',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    icon: 'home',
    req: null,
    dep: null,
    hide: !userroles?.home?.view,
  },
  {
    id: 0.2,
    name: 'main',
    uri: '/main',
    titleAr: 'الشركة',
    titleEn: 'Main',
    icon: 'home',
    req: null,
    dep: null,
    hide: !userroles?.home?.view || !userroles?.isBranchsAdmin,
  },
  {
    id: 14.4,
    name: 'mail',
    uri: '/mail',
    titleAr: 'البريد',
    titleEn: 'Mail',
    icon: 'email',
    req: null,
    dep: null,
    hide: !options?.mail || !userroles?.operations?.view,
  },
  {
    id: 14.5,
    name: 'pos',
    uri: '/pos',
    titleAr: 'POS',
    titleEn: 'POS',
    icon: 'pos',
    req: null,
    dep: null,
    hide: !options?.pos || !userroles?.sales?.view,
  },
  {
    id: 13.6,
    name: 'letters',
    uri: '/letters',
    titleAr: 'الخطابات',
    titleEn: 'Letters',
    icon: 'contact',
    req: null,
    dep: null,
    hide: !options?.letters || !userroles?.letters?.view,
  },
  {
    id: 14.5,
    name: 'presales',
    uri: '/presales',
    titleAr: 'التسويق',
    titleEn: 'Marketing',
    icon: 'marketing',
    req: null,
    dep: null,
    hide: !options?.presales || !userroles?.presales?.view,
  },
  {
    id: 0.5,
    name: 'calendar',
    uri: '/calendar',
    titleAr: 'التقويم',
    titleEn: 'Calendar',
    icon: 'calendar',
    req: 'cal',
    dep: 'pos',
    hide: !options?.calendar || !userroles?.operations?.view,
  },

  {
    id: 14,
    name: 'operations',
    uri: '/operations',
    titleAr: tempwords?.operations,
    titleEn: tempwords?.operations,
    icon: 'depart',
    req: null,
    dep: null,
    hide: !options?.operations || !userroles?.operations?.view,
  },

  {
    id: 14.7,
    name: 'sales',
    uri: '/sales',
    titleAr: 'المبيعات',
    titleEn: 'Sales',
    icon: 'sales',
    req: null,
    dep: null,
    hide: !options?.sales || !userroles?.sales?.view,
  },
  {
    id: 14.8,
    name: 'purchase',
    uri: '/purchase',
    titleAr: 'المشتريات',
    titleEn: 'Purchase',
    icon: 'purchase',
    req: null,
    dep: null,
    hide: !options?.purchase || !userroles?.purchase?.view,
  },
  {
    id: 14.9,
    name: 'expenses',
    uri: '/expenses',
    titleAr: 'المصروفات',
    titleEn: 'Expenses',
    icon: 'expenses',
    req: null,
    dep: null,
    hide: !options?.expenses || !userroles?.expenses?.view,
  },
  {
    id: 14.1,
    name: 'finance',
    uri: '/finance',
    titleAr: 'الحسابات',
    titleEn: 'Accounting',
    icon: 'finance',
    req: null,
    dep: null,
    hide: !options?.finance || !userroles?.finance?.view,
  },
  {
    id: 14.11,
    name: 'reports',
    uri: '/reports',
    titleAr: 'التقارير',
    titleEn: 'Reports',
    icon: 'report',
    req: null,
    dep: null,
    hide: !options?.reports || !userroles?.reports?.view,
  },
  {
    id: 14.12,
    name: 'reminder',
    uri: '/reminder',
    titleAr: 'المفكرة',
    titleEn: 'Reminder',
    icon: 'belladd',
    req: 'cal',
    dep: 'pos',
    hide: !options?.reminder || !userroles?.reminder?.view,
  },
  {
    id: 14.12,
    name: 'basicadd',
    uri: '/basicadd',
    titleAr: 'الاضافات',
    titleEn: 'Basics',
    icon: 'add',
    req: null,
    dep: null,
    // hide: !userroles?.finance?.edit,
  },
  {
    id: 1,
    name: 'managment',
    uri: '/managment',
    titleAr: 'الإدارة',
    titleEn: 'Management',
    icon: 'depart',
    req: null,
    dep: null,
    hide:
      !userroles?.isSuperAdmin &&
      !userroles?.isBranchsAdmin &&
      !userroles?.isBranchAdmin,
    subMenu: [
      // {
      //   id: 10.5,
      //   name: 'manageformdocs',
      //   uri: 'manageformdocs',
      //   titleAr: `${words?.formdocs}`,
      //   titleEn: `${words?.formdocs}`,
      //   icon: 'formdocs',
      //   req: null,
      //   dep: null,
      //   hide:
      //     !userroles?.isSuperAdmin &&
      //     !userroles?.isBranchsAdmin &&
      //     !userroles?.isBranchAdmin,
      // },
      {
        id: 11,
        name: 'branches',
        uri: 'branches',
        titleAr: 'الشركات',
        titleEn: 'Companies',
        icon: 'business',
        req: null,
        dep: null,
        hide: !userroles?.isSuperAdmin,
      },
      {
        id: 11.4,
        name: 'jadwal',
        uri: '/jadwal',
        titleAr: 'جدول',
        titleEn: 'Jadwal',
        icon: 'business',
        req: null,
        dep: null,
        hide: !userroles?.isSuperAdmin,
      },
    ],
  },
  // {
  //   id: 7,
  //   name: 'sms',
  //   uri: 'sms',
  //   titleAr: 'رسائل SMS',
  //   titleEn: 'SMS Messages',
  //   icon: 'email',
  //   req: 'pos',
  //   dep: null,
  //   hide: !options?.sms || !userroles?.sms?.view,
  //   subMenu: [
  //     {
  //       id: 71,
  //       name: 'contacts',
  //       uri: 'contacts',
  //       titleAr: 'جهات الاتصال',
  //       titleEn: 'Contacts',
  //       icon: 'group',
  //       req: 'pos',
  //       dep: null,
  //       hide: !userroles?.sms?.view,
  //     },
  //     {
  //       id: 72,
  //       name: 'groups',
  //       uri: 'groups',
  //       titleAr: 'المجموعات',
  //       titleEn: 'Groups',
  //       req: 'pos',
  //       icon: 'list',
  //       dep: null,
  //       hide: !userroles?.sms?.view,
  //     },
  //     {
  //       id: 73,
  //       name: 'messages',
  //       uri: 'messages',
  //       titleAr: 'الرسائل',
  //       titleEn: 'Messages',
  //       req: 'pos',
  //       icon: 'email',
  //       dep: null,
  //       hide: !userroles?.sms?.view,
  //     },
  //     {
  //       id: 74,
  //       name: 'sendreqs',
  //       uri: 'sendreqs',
  //       titleAr: 'حملات الارسال',
  //       titleEn: 'SMS campaign',
  //       icon: 'camp',
  //       req: 'pos',
  //       dep: null,
  //       hide: !userroles?.sms?.view,
  //     },
  //     {
  //       id: 75,
  //       name: 'msgtemplates',
  //       uri: 'msgtemplates',
  //       titleAr: 'نماذج الرسائل',
  //       titleEn: 'Message Templates',
  //       icon: 'camp',
  //       req: 'pos',
  //       dep: null,
  //       hide: !userroles?.sms?.view,
  //     },
  //     {
  //       id: 10,
  //       name: 'notifications',
  //       uri: 'notifications',
  //       titleAr: 'التنبيهات',
  //       titleEn: 'Notifications',
  //       icon: 'bell',
  //       req: 'cal',
  //       dep: 'pos',
  //       hide: !options?.notification || !userroles?.notifications?.view,
  //     },
  //   ],
  // },
];
export const emplmenu = [
  {
    id: 0,
    name: 'home',
    uri: '/',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    icon: 'home',
    req: null,
    dep: null,
  },
  {
    id: 1,
    name: 'mobcalendar',
    uri: '/mobcalendar',
    titleAr: 'التقويم',
    titleEn: 'Calendar',
    icon: 'calendar',
    req: null,
    dep: null,
  },
  {
    id: 2,
    name: 'appointments',
    uri: '/appointments',
    titleAr: tempwords?.appointments,
    titleEn: tempwords?.appointments,
    icon: 'event',
    req: null,
    dep: null,
  },
  {
    id: 3,
    name: 'invoices',
    uri: '/invoices',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    icon: 'expenses',
    req: null,
    dep: null,
  },
  // {
  //   id: 23,
  //   name: 'customers',
  //   uri: '/customers',
  //   titleAr: tempwords?.customers,
  //   titleEn: tempwords?.customers,
  //   icon: 'business',
  //   req: 'pos',
  //   dep: null,
  //   hide: !userroles?.customers?.view,
  // },
  {
    id: 4,
    name: 'notifications',
    uri: '/notifications',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    icon: 'bell',
    req: null,
    dep: null,
  },
  {
    id: 5,
    name: 'options',
    uri: '/options',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    icon: 'options',
    req: null,
    dep: null,
  },
  {
    id: 99,
    name: 'logout',
    uri: 'logout',
    titleAr: 'تسجيل الخروج',
    titleEn: 'Logout',
    icon: 'logout',
    req: null,
    dep: null,
  },
];

export const systemsNames = {
  cal: {
    name: 'Calendar',
    nameAr: 'جدول المواعيد',
  },
  pos: {
    name: 'Sales',
    nameAr: 'نظام المبيعات',
  },
  pur: {
    name: 'Purchase',
    nameAr: 'نظام المشتريات',
  },
  exp: {
    name: 'Expenses',
    nameAr: 'نظام المصروفات',
  },
  inv: {
    name: 'Inventory',
    nameAr: 'نظام المخازن',
  },
  hr: {
    name: 'Human Resources',
    nameAr: 'نظام الموارد البشرية',
  },
  ass: {
    name: 'Assets Accounting',
    nameAr: 'نظام حسابات الأصول',
  },
  acc: {
    name: 'General Accounting',
    nameAr: 'نظام الحسابات العامة',
  },
};

export const operationsList = [
  {
    id: 81,
    name: tempwords?.tasks,
    nameAr: tempwords?.tasks,
    opType: 81,
    path: 'tasks',
    hide: !options?.contract || !userroles?.operations.view,
  },
  {
    id: 80,
    name: tempwords?.appointments,
    nameAr: tempwords?.appointments,
    opType: 80,
    path: 'events',
    hide: !userroles?.operations?.view,
  },
  {
    id: 400,
    name: tempwords?.customers,
    nameAr: tempwords?.customers,
    opType: 400,
    path: 'customers',
    side: true,
    hide: !userroles?.customers?.view,
  },
];
export const reportsList = [
  {
    id: 502,
    name: `${tempwords?.tasks} Report`,
    nameAr: `تقرير ${tempwords?.tasks}`,
    opType: 502,
    path: 'tasks',
    hide: !userroles?.tasks?.view || !userroles?.reports?.view,
  },
  {
    id: 1001,
    opType: 1001,
  },
  {
    id: 503,
    name: 'Sales Report',
    nameAr: 'تقرير المبيعات',
    opType: 503,
    path: 'sales',
    hide: !userroles?.sales?.view || !userroles?.reports?.view,
  },
  {
    id: 504,
    name: `${tempwords?.customers} Report`,
    nameAr: `تقرير ${tempwords?.customers}`,
    opType: 504,
    path: 'customers',
    hide: !userroles?.customerreport?.view || !userroles?.reports?.view,
  },
  {
    id: 505,
    name: `${tempwords?.customers} Balance`,
    nameAr: `ارصدة ${tempwords?.customers}`,
    opType: 505,
    path: 'cusbalnce',
    hide: !userroles?.customerreport?.view || !userroles?.reports?.view,
  },
  {
    id: 1002,
    opType: 1002,
  },
  {
    id: 506,
    name: 'Purchase Report',
    nameAr: 'تقرير المشتريات',
    opType: 506,
    path: 'purchase',
    hide: !userroles?.purchasereport?.view || !userroles?.reports?.view,
  },
  {
    id: 507,
    name: `${tempwords?.suppliers} Report`,
    nameAr: `تقرير ${tempwords?.suppliers}`,
    opType: 507,
    path: 'suppliers',
    hide: !userroles?.supplierreport?.view || !userroles?.reports?.view,
  },
  {
    id: 508,
    name: `${tempwords?.suppliers} Balance`,
    nameAr: `ارصدة ${tempwords?.suppliers}`,
    opType: 508,
    path: 'supbalnce',
    hide: !userroles?.supplierreport?.view || !userroles?.reports?.view,
  },
  {
    id: 1003,
    opType: 1003,
  },
  {
    id: 510,
    name: `Expenses Report`,
    nameAr: 'تقرير المصروفات',
    opType: 510,
    path: 'expenses',
    hide: !userroles?.expensesreport?.view || !userroles?.reports?.view,
  },
  {
    id: 512,
    name: `Finance Report`,
    nameAr: 'تقرير الحسابات',
    opType: 512,
    path: 'finance',
    hide: !userroles?.finance?.view || !userroles?.reports?.view,
  },
  {
    id: 513,
    name: `Profit Report`,
    nameAr: 'تقرير الربحية',
    opType: 513,
    path: 'profit',
    hide: !userroles?.profitability?.view || !userroles?.reports?.view,
  },
  {
    id: 514,
    name: `Full Report`,
    nameAr: 'تقرير شامل',
    opType: 514,
    path: 'full',
    hide: !userroles?.finance?.view || !userroles?.reports?.view,
  },
  {
    id: 516,
    name: `Budget Report`,
    nameAr: 'الميزانية',
    opType: 516,
    path: 'budget',
    hide: !userroles?.budgetreport?.view || !userroles?.reports?.view,
  },
];
export const salesList = [
  {
    id: 10,
    name: 'Invoices',
    nameAr: 'فواتير البيع',
    opType: 10,
    path: 'invoices',
    hide: !userroles?.invoices?.view,
  },
  {
    id: 14,
    name: 'Receipts',
    nameAr: 'سندات القبض',
    opType: 14,
    path: 'receipts',
    hide: !userroles?.receipts?.view,
  },
  {
    id: 400,
    name: tempwords?.customers,
    nameAr: tempwords?.customers,
    opType: 400,
    path: 'customers',
    side: true,
    hide: !userroles?.customers?.view,
  },
];
export const jadwalList = [
  {
    id: 1,
    name: 'Subscribtions',
    nameAr: 'الاشتراكات',
    opType: 1,
    path: 'subscribtions',
  },
  {
    id: 10,
    name: 'Jadwal Invoices',
    nameAr: 'فواتير البيع',
    opType: 10,
    path: 'invoices',
  },
  {
    id: 14,
    name: 'Receipts',
    nameAr: 'سندات القبض',
    opType: 14,
    path: 'receipts',
  },
];
export const purchaseList = [
  {
    id: 30,
    name: 'Invoices',
    nameAr: 'فواتير الشراء',
    opType: 30,
    path: 'pinvoices',
    hide: !userroles?.purchase?.view,
  },
  {
    id: 34,
    name: 'Payments',
    nameAr: 'سندات الدفع',
    opType: 34,
    path: 'payments',
    hide: !userroles?.payments?.view,
  },
  {
    id: 401,
    name: 'Suppliers',
    nameAr: 'الموردين',
    opType: 401,
    path: 'suppliers',
    side: true,
    hide: !userroles?.supliers?.view,
  },
];
export const expensesList = [
  {
    id: 60,
    name: 'Cash Expenses',
    nameAr: 'مصروفات نقدية',
    opType: 60,
    path: 'cash',
    hide: !userroles?.expenses?.view,
  },
  {
    id: 61,
    name: 'Product Expenses',
    nameAr: 'استهلاك منتجات',
    opType: 61,
    path: 'product',
    hide: !userroles?.expenses?.view,
  },
];
export const financeList = [
  // {
  //   id: 1,
  //   name: 'Uploads',
  //   nameAr: 'الاضافة',
  //   opType: 1,
  //   path: 'uploads',
  //   hide: !userroles?.finance?.view,
  // },
  // {
  //   id: 2,
  //   name: 'Archive',
  //   nameAr: 'الأرشيف',
  //   opType: 2,
  //   path: 'archive',
  //   hide: !userroles?.finance?.view,
  // },
  // {
  //   id: 3,
  //   name: 'Documents',
  //   nameAr: 'المستندات',
  //   opType: 3,
  //   path: 'doc',
  //   hide: !userroles?.finance?.view,
  // },
  {
    id: 70,
    name: 'Finance Entry',
    nameAr: 'حركة مالية',
    opType: 70,
    path: 'transfer',
    hide: !userroles?.finance?.view,
  },
  {
    id: 78,
    name: 'Advance Payemnt',
    nameAr: 'دفع سلفة',
    opType: 78,
    path: 'adpayment',
    hide: !userroles?.finance?.view,
  },
  {
    id: 77,
    name: 'Advance Receipt',
    nameAr: 'قبض سلفة',
    opType: 77,
    path: 'adreceipt',
    hide: !userroles?.finance?.view,
  },
  {
    id: 90,
    name: 'General Entry',
    nameAr: 'قيد يومية',
    opType: 90,
    path: 'entry',
    hide: !userroles?.financeall?.view,
  },
  {
    id: 410,
    name: 'Accounts',
    nameAr: 'قائمة الحسابات',
    opType: 410,
    path: 'accounts',
    side: true,
    hide: !userroles?.finance?.view,
  },
];
export const addsList = [
  {
    id: 40,
    name: 'Contacts',
    nameAr: 'جهات الاتصال',
    opType: 40,
    path: 'contacts',
    hide: !userroles?.sms?.view,
  },
  {
    id: 2,
    name: tempwords?.customers,
    nameAr: tempwords?.customers,
    opType: 2,
    path: 'customers',
    hide: !userroles?.sales?.view,
  },
  {
    id: 3,
    name: 'Suppliers',
    nameAr: 'الموردين',
    opType: 3,
    path: 'suppliers',
    hide: !userroles?.purchase?.view,
  },
  {
    id: 1002,
    opType: 1002,
  },
  {
    id: 4,
    name: 'Departments',
    nameAr: 'الاقسام',
    opType: 4,
    path: 'departments',
    hide: !options?.department || !userroles?.sales?.view,
  },
  {
    id: 1,
    name: tempwords?.employees,
    nameAr: tempwords?.employees,
    opType: 1,
    path: 'employees',
    hide: !options?.employee || !userroles?.sales?.view,
  },
  {
    id: 5,
    name: tempwords?.resourses,
    nameAr: tempwords?.resourses,
    opType: 5,
    path: 'resourses',
    hide: !options?.resourse || !userroles?.sales?.view,
  },
  {
    id: 1001,
    opType: 1001,
  },

  {
    id: 11,
    name: 'Products',
    nameAr: 'المنتجات',
    opType: 11,
    path: 'products',
    hide: !options?.purchase || !userroles?.sales?.view,
  },
  {
    id: 12,
    name: 'Services',
    nameAr: 'الخدمات',
    opType: 12,
    path: 'services',
    hide: !userroles?.sales?.view,
  },
  {
    id: 13,
    name: 'Expenses Items',
    nameAr: 'بنود المصاريف',
    opType: 13,
    path: 'expensesitems',
    hide: !options?.expenses || !userroles?.finance?.view,
  },
  {
    id: 1003,
    opType: 1003,
  },
  {
    id: 6,
    name: 'Categories',
    nameAr: 'التصنيفات',
    opType: 6,
    path: 'categories',
    hide: !options?.category || !userroles?.sales?.view,
  },
  {
    id: 7,
    name: 'Sections',
    nameAr: 'تصنيفات رئيسية',
    opType: 7,
    path: 'sections',
    hide: !options?.section || !userroles?.sales?.view,
  },
  {
    id: 8,
    name: 'Brands',
    nameAr: 'البراندات',
    opType: 8,
    path: 'brands',
    hide: !options?.brand || !userroles?.sales?.view,
  },

  {
    id: 20,
    name: 'Types',
    nameAr: 'الانواع',
    opType: 20,
    path: 'types',
    hide: !options?.retype,
  },
  {
    id: 1004,
    opType: 1004,
  },
  {
    id: 16,
    name: 'Procedure Category',
    nameAr: 'تصنيفات الاجراءات',
    opType: 16,
    path: 'procedurecats',
    hide: !options?.procedures || !userroles?.procedures?.view,
  },
  {
    id: 15,
    name: 'Procedure Items',
    nameAr: `بنود الاجراءات`,
    opType: 15,
    path: 'procedureitems',
    hide: !options?.procedures || !userroles?.procedures?.view,
  },
  {
    id: 14,
    name: 'Status Items',
    nameAr: `بنود الحالات`,
    opType: 14,
    path: 'stepitems',
    hide: !options?.procedures || !userroles?.procedures?.view,
  },
  {
    id: 1005,
    opType: 1005,
  },
  {
    id: 30,
    name: 'Users',
    nameAr: 'المستخدمين',
    opType: 30,
    path: 'users',
    hide: !(
      userroles?.isBranchAdmin ||
      userroles?.isBranchsAdmin ||
      userroles?.isSuperAdmin
    ),
  },
  {
    id: 31,
    name: 'Accounts',
    nameAr: 'قائمة الحسابات',
    opType: 31,
    path: 'accounts',
    hide: !userroles?.finance?.view,
  },
];
export const mailList = [
  {
    id: 1,
    name: 'Inbox',
    nameAr: 'البريد الوارد',
    opType: 1,
    path: 'inbox',
    icon: 'inbox',
    new: 0,
    hide: !options?.mail || !userroles?.mail?.view,
  },
  {
    id: 2,
    name: 'Outbox',
    nameAr: 'البريد الصادر',
    opType: 2,
    path: 'outbox',
    icon: 'outbox',
    hide: !options?.mail || !userroles?.mail?.view,
  },
  {
    id: 1002,
    opType: 1002,
  },
  {
    id: 3,
    name: 'Important',
    nameAr: 'البريد الهام',
    opType: 3,
    path: 'important',
    icon: 'important',
    hide: !options?.mail || !userroles?.mail?.view,
  },
  {
    id: 4,
    name: 'Starred',
    nameAr: 'البريد المميز',
    opType: 4,
    path: 'starred',
    icon: 'star',
    hide: !options?.mail || !userroles?.mail?.view,
  },
];
export const uploadList = [
  {
    id: 0,
    name: 'Under Approval',
    nameAr: 'قيد الموافقة',
    opType: 1,
    path: 'uploadunderapproval',
    icon: 'upload',
    new: 0,
    hide: !options?.mail || !userroles?.uploadunderapproval?.view,
  },
  {
    id: 1,
    name: 'Under Review',
    nameAr: 'قيد المراجعة',
    opType: 1,
    path: 'uploadunderreview',
    icon: 'inbox',
    new: 0,
    hide: !options?.mail || !userroles?.uploadunderreview?.view,
  },
  {
    id: 2,
    name: 'In Progress',
    nameAr: 'قيد التسجيل',
    opType: 2,
    path: 'uploadinprogress',
    icon: 'outbox',
    hide: !options?.mail || !userroles?.uploadinprogress?.view,
  },
  {
    id: 1002,
    opType: 1002,
  },
  {
    id: 3,
    name: 'Archive',
    nameAr: 'الأرشيف',
    opType: 3,
    path: 'uploadarchive',
    icon: 'important',
    hide: !options?.mail || !userroles?.uploadarchive?.view,
  },
];
export const entryList = [
  {
    id: 0,
    name: 'Under Approval',
    nameAr: 'قيد الموافقة',
    opType: 1,
    path: 'entryunderapproval',
    icon: 'upload',
    new: 0,
    hide: !options?.mail || !userroles?.entryunderapproval?.view,
  },
  {
    id: 1,
    name: 'Under Review',
    nameAr: 'قيد المراجعة',
    opType: 1,
    path: 'entryunderreview',
    icon: 'inbox',
    new: 0,
    hide: !options?.mail || !userroles?.entryunderreview?.view,
  },
  {
    id: 1002,
    opType: 1002,
  },
  {
    id: 3,
    name: 'Archive',
    nameAr: 'الأرشيف',
    opType: 3,
    path: 'entryarchive',
    icon: 'important',
    hide: !options?.mail || !userroles?.entryarchive?.view,
  },
];
export const reminderList = [
  {
    id: 1,
    name: 'Reminders',
    nameAr: 'التذكيرات',
    opType: 1,
    path: 'todo',
    hide: !options?.reminder || !userroles?.reminder?.view,
  },
  {
    id: 2,
    name: 'Management',
    nameAr: 'ادارة التذكيرات',
    opType: 2,
    path: 'manage',
    hide: !options?.reminder || !userroles?.reminder?.view,
  },
];

export const getPopupTitle = (item: any, isNew: boolean) => {
  const addAr = `${tempwords?.[item]} جديد`;
  const add = `Add ${tempwords?.[item]}`;
  const editAr = `تعديل ${tempwords?.[item]}`;
  const edit = `Edit ${tempwords?.[item]}`;
  return isNew ? (isRTL ? addAr : add) : isRTL ? editAr : edit;
};
export const getPopupGeneralTitle = (item: any) => {
  const gtitleAr = `بيانات ${tempwords?.[item]}`;
  const gtitle = `${tempwords?.[item]}`;
  return isRTL ? gtitleAr : gtitle;
};

export const sortMenu = ({ menu, sortOrder }) => {
  if (!sortOrder) return menu;

  const itemPositions = {};
  for (const [index, id] of sortOrder.entries()) {
    itemPositions[id] = index;
  }

  return menu.sort(
    (a: any, b: any) => itemPositions[a.id] - itemPositions[b.id]
  );
};
