/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import { Box, Tooltip, Typography } from '@material-ui/core';
import { nameToColor } from '../common';

export default function AvatarEmail({
  name,
  size = 36,
  bc = '#ddd',
  bg,
  isread,
}: any) {
  const color = bg ? bg : name ? nameToColor(name) : '';
  const letter = name?.substring(0, 1)?.toUpperCase();
  return (
    <Tooltip title={name ? name : ''}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: size,
              height: size,
              borderRadius: size / 2,
              backgroundColor: color,
              borderColor: bc,
            }}
          >
            <Typography
              style={{ fontSize: 14, fontWeight: 'bold', color: '#eee' }}
            >
              {letter}
            </Typography>
          </Box>
        </Box>
        <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Typography
            style={{
              fontSize: isread ? undefined : 16,
              fontWeight: isread ? undefined : 'bold',
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}
