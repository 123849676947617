/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  PagingPanel,
  TableSelection,
  // TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import {
  downloadCSVFile,
  getRowId,
  LinkLocation,
  LinkPhone,
  userroles,
} from '../../common';
import { PopupCustomer } from '../../pubups';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import PageLayout from '../main/PageLayout';
import { getColumns } from '../../common/columns';
import {
  avataManageFormatter,
  linkFormatter,
  nameManageLinkCustomer,
} from '../../Shared/colorFormat';
import PopupCustomerView from '../../pubups/PopupCustomerView';
import {
  createCustomer,
  deleteCustomer,
  getCustomers,
  updateCustomer,
} from '../../graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { TableComponent } from '../../Shared/TableComponent';
import PopupCustomerImport from '../../pubups/PopupCustomerImport';
import ImportBtn from '../../common/ImportBtn';
import createMultiCustomers from '../../graphql/mutation/createMultiCustomers';
import { CustomerContext } from '../../contexts/managment';
import { useTemplate } from '../../hooks';
import ExportBtn from '../../common/ExportBtn';
import Whatsapp from '../../common/Whatsapp';
import {
  createCustomerLink,
  deleteMultiCustomers,
} from '../../graphql/mutation';
import ButtonShort from '../../common/ButtonShort';

export default function Customers(props: any) {
  const { isRTL, words, menuitem, theme, company } = props;
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState(null);
  const [openImport, setOpenImport] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [selection, setSelection] = useState([]);
  const [adding, setAdding] = useState(false);
  const [selected, setSelected] = useState(null);

  const { height, width } = useWindowDimensions();

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(CustomerContext);
  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };
  const { customeroptions, tempoptions } = useTemplate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const col = getColumns({ isRTL, words });
  const [columns] = useState(
    [
      { name: 'avatar', title: ' ' },
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      col.name,
      { name: 'phone', title: words.phoneNumber },
      customeroptions?.email && { name: 'email', title: words.email },
      customeroptions?.employee && col.employee,
      customeroptions?.area && { name: 'area', title: words.area },
      customeroptions?.address && { name: 'address', title: words.theaddress },
      customeroptions?.grade && { name: 'grade', title: words.grade },
      tempoptions?.shortcustomer && {
        name: 'link',
        title: isRTL ? 'رابط' : 'Link',
      },
      customeroptions?.location && col.locationLink,
      customeroptions?.location && col.houseno,
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 150 },
    { columnName: 'additionId', width: 120 },
    { columnName: col.name.name, width: 300 },
    { columnName: 'phone', width: 180 },
    { columnName: 'email', width: 200 },
    { columnName: col.employee.name, width: 180, align: 'center' },
    { columnName: 'area', width: 150 },
    { columnName: 'address', width: 200 },
    { columnName: 'grade', width: 150 },
    { columnName: 'locationLink', width: 120, align: 'center' },
    { columnName: 'link', width: 100, align: 'center' },
    { columnName: 'houseno', width: 120, align: 'center' },
  ]);

  const [columnsViewer] = useState([
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'avatar', title: words.color },
  ]);

  const [loadCusts, custssData]: any = useLazyQuery(getCustomers, {
    fetchPolicy: 'cache-and-network',
  });

  const refresQuery = {
    refetchQueries: [{ query: getCustomers }],
  };

  const deleteSelected = async () => {
    if (window.confirm('Are you sure you want to delete rows?')) {
      await removeMultiCustomers({ variables: { ids: selection } });
      setSelection([]);
    }
  };

  useEffect(() => {
    if (openItem) {
      const tsks = custssData?.data?.['getCustomers']?.data || [];
      if (tsks && tsks.length > 0) {
        const opened = tsks.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
  }, [custssData]);

  useEffect(() => {
    loadCusts({ isRTL });
  }, []);

  const [addCustomer] = useMutation(createCustomer, refresQuery);
  const [addMultiCustomers] = useMutation(createMultiCustomers, refresQuery);
  const [editCustomer] = useMutation(updateCustomer, refresQuery);
  const [removeCustomer] = useMutation(deleteCustomer, refresQuery);
  const [removeMultiCustomers] = useMutation(deleteMultiCustomers, refresQuery);
  const [addCustomerLink] = useMutation(createCustomerLink, refresQuery);

  const addLink = async (id: any) => {
    setAdding(true);
    setSelected(id);
    try {
      await addCustomerLink({ variables: { id } });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
      setAdding(false);
    }
    setSelected(null);
    setAdding(false);
  };

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeCustomer({ variables: { _id } });
      if (res?.data?.deleteCustomer?.ok === false) {
        if (res?.data?.deleteCustomer?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  useEffect(() => {
    if (custssData?.data?.getCustomers?.data) {
      const { data } = custssData.data.getCustomers;
      const rdata = data.map((d: any) => {
        return {
          ...d,
          raseed: d?.totalinvoiced - d?.totalpaid - d?.totalDiscount || 0,
        };
      });
      setRows(rdata);
    }
  }, [custssData]);

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'customers', colnames);
  };
  const refresh = () => {
    custssData?.refetch();
  };
  const bgcolor = '#e6e6fa66';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      bgcolor={bgcolor}
      refresh={refresh}
      loading={custssData?.loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: bgcolor,
        }}
      >
        {!isMobile && (
          <>
            <ImportBtn
              open={() => setOpenImport(true)}
              isRTL={isRTL}
            ></ImportBtn>
            <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
          </>
        )}
        {!isMobile && userroles?.customers?.admin && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: isRTL ? 400 : undefined,
              right: isRTL ? undefined : 400,
              display: 'flex',
              margin: 5,
              padding: 5,
              marginBottom: -5,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {selection?.length > 0 && (
              <Typography>{selection?.length}</Typography>
            )}
            <Button
              color="secondary"
              variant="contained"
              disabled={selection?.length === 0}
              onClick={deleteSelected}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <Typography>
                {isRTL ? 'حذف المحدد' : 'Delete Selected'}
              </Typography>
            </Button>
          </Box>
        )}
        <Paper
          elevation={5}
          style={{
            marginTop: 10,
            marginLeft: isMobile ? undefined : 40,
            marginRight: isMobile ? undefined : 40,
            marginBottom: 20,
            overflow: 'auto',
            borderRadius: 10,
            width: !isMobile ? width - 330 : width,
          }}
        >
          <Grid
            rows={rows}
            columns={userroles?.customers?.edit ? columns : columnsViewer}
            getRowId={getRowId}
          >
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <SearchState />
            <PagingState defaultCurrentPage={0} defaultPageSize={8} />

            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <IntegratedSelection />
            <DragDropProvider />

            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 80 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnReordering
              defaultOrder={[
                'avatar',
                col.name.name,
                'phone',
                'email',
                col.employee.name,
                'area',
                'address',
                'grade',
                'locationLink',
                'houseno',
                'link',
              ]}
            />
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
            <TableColumnVisibility
              defaultHiddenColumnNames={hiddenColumnNames}
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />

            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            {userroles?.customers?.admin && (
              <DataTypeProvider
                for={['avatar']}
                formatterComponent={(props: any) =>
                  avataManageFormatter({
                    ...props,
                    setItem,
                    setOpenItem,
                    isRTL,
                    height: 70,
                  })
                }
              ></DataTypeProvider>
            )}
            <DataTypeProvider
              for={['phone']}
              formatterComponent={({ value }) => (
                <Box display={'flex'}>
                  {value && <Whatsapp phone={value}></Whatsapp>}
                  {value && <LinkPhone value={value}></LinkPhone>}
                </Box>
              )}
            ></DataTypeProvider>
            {userroles?.customers?.admin && !isMobile && (
              <DataTypeProvider
                for={[col.name.name]}
                formatterComponent={(props: any) =>
                  nameManageLinkCustomer({
                    ...props,
                    setItem,
                    setOpenItem,
                    isRTL,
                  })
                }
              ></DataTypeProvider>
            )}
            <DataTypeProvider
              for={['locationLink']}
              formatterComponent={({ value }) => {
                return (
                  <LinkLocation value={value} isRTL={isRTL}></LinkLocation>
                );
              }}
            ></DataTypeProvider>
            {userroles?.customers?.admin ? (
              <DataTypeProvider
                for={['link']}
                formatterComponent={(props: any) => (
                  <ButtonShort
                    {...props}
                    addLink={addLink}
                    isRTL={isRTL}
                    loading={adding}
                    selected={selected}
                  ></ButtonShort>
                )}
              ></DataTypeProvider>
            ) : (
              <DataTypeProvider
                for={['link']}
                formatterComponent={linkFormatter}
              ></DataTypeProvider>
            )}
            <TableEditColumn
              showEditCommand={userroles?.customers?.edit}
              showDeleteCommand={userroles?.customers?.delete}
              showAddCommand={userroles?.customers?.add}
              commandComponent={Command}
            ></TableEditColumn>
            <Toolbar />
            <ColumnChooser />
            <PagingPanel
              pageSizes={
                tempoptions?.pageall
                  ? [5, 8, 10, 20, 50, 0]
                  : [5, 8, 10, 20, 50]
              }
            />
            <TableSelection showSelectAll />

            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />
            <PopupEditing
              theme={theme}
              addAction={addCustomer}
              editAction={editCustomer}
            >
              <PopupCustomer></PopupCustomer>
            </PopupEditing>
          </Grid>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
        <PopupCustomerView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          company={company}
        ></PopupCustomerView>
        <PopupCustomerImport
          open={openImport}
          onClose={() => setOpenImport(false)}
          addMultiItems={addMultiCustomers}
          isRTL={isRTL}
          theme={theme}
          words={words}
        ></PopupCustomerImport>
      </Box>
    </PageLayout>
  );
}
