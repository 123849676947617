/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  yup,
  messageAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { CalenderLocal, TextFieldLocal } from '../components';
import { getPopupTitle } from '../constants/menu';
import { useTemplate } from '../hooks';
import { ImageOnlineUpload, uploadPhotoOnline } from '../common/upload';
import CheckBoxLocal from '../Shared/CheckBoxLocal';
import useMSGTemplates from '../hooks/useMSGTemplates';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { LinkLocation } from '../common';
import CalenderKeyboard from '../components/fields/CalenderKeyboard';

const PopupCustomer = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  editAction,
  newtext,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);

  const [birthday, setBirthday] = useState(null);
  const [breminder, setbReminder] = useState(false);
  const [sreminder, setsReminder] = useState(false);
  const [nationalDate, setNationalDate] = useState(null);
  const [nreminder, setnReminder] = useState(false);
  const [licenseDate, setLicenseDate] = useState(null);
  const [reminder, setReminder] = useState(false);
  const [addlink, setAddlink] = useState(false);
  const [smsmsgvalue, setSmsmsgvalue] = useState(null);
  const [locationLink, setLocationLink] = useState(null);
  const [houseno, setHouseno] = useState(null);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [logoimage, setLogoimage] = useState(null);
  const [logourl, setLogourl] = useState(null);

  const { register, handleSubmit, errors, reset } = useForm(yup.custResolver);
  const {
    translate: { words, isRTL },
    store: { user, tempId },
  }: GContextTypes = useContext(GlobalContext);
  const { tempoptions, customeroptions } = useTemplate();
  const { msgtemplates } = useMSGTemplates();
  useEffect(() => {
    if (row?._id) {
      if (row?.logo) {
        setLogourl(row?.logo);
      }
      if (row?.msgId) {
        const msg = msgtemplates?.filter(
          (mt: any) => mt?._id === row?.msgId
        )?.[0];
        setSmsmsgvalue(msg);
      }
      setBirthday(row?.birthday);
      setbReminder(row?.breminder);
      setsReminder(row?.sreminder);
      setNationalDate(row?.nationalDate);
      setnReminder(row?.nreminder);
      setLicenseDate(row?.licenseDate);
      setReminder(row?.reminder);
      if (row?.locationLink) {
        setLocationLink(row?.locationLink);
      }
      if (row?.houseno) {
        setHouseno(row?.houseno);
      }
    }
  }, [row]);

  useEffect(() => {
    if (!sreminder) {
      setSmsmsgvalue(null);
    }
  }, [sreminder]);

  const onSubmit = async (data: any) => {
    if (sreminder && !smsmsgvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تحديد نموذج الرسالة' : 'Message template required'
      );
      return;
    }

    setSaving(true);
    const name = data.name.trim();
    const nameAr = !tempoptions?.nameonelang ? data.nameAr.trim() : name;

    const {
      phone,
      email,
      address,
      driver,
      licenseNo,
      national,
      nationalNo,
      area,
      parentName,
      parentPhone,
      grade,
      openingbalance,
      additionId,
    } = data;

    let logo: any;

    if (logoimage) {
      logo = await uploadPhotoOnline(logoimage);
      logo = logo.replace('http://', 'https://');
    }
    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      additionId: data?.additionId?.length > 0 ? additionId : undefined,
      name,
      nameAr,
      phone,
      email,
      address,
      logo,
      driver,
      licenseNo,
      licenseDate,
      national,
      nationalNo,
      nationalDate,
      birthday,
      msgId: smsmsgvalue ? smsmsgvalue?._id : undefined,
      breminder,
      sreminder,
      nreminder,
      reminder,
      area,
      parentName,
      parentPhone,
      grade,
      locationLink,
      houseno,
      openingbalance: openingbalance ? openingbalance : undefined,
      addlink,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createCustomer' : 'updateCustomer';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem, 'customer');
      closeModal();
      await successAlert(setAlrt, isRTL);
      onClose();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const closeModal = () => {
    onClose();
    reset();
    setSaving(false);
    setLogoimage(null);
    setLogourl(null);
    setBirthday(null);
    setbReminder(false);
    setsReminder(false);
    setNationalDate(null);
    setnReminder(false);
    setLicenseDate(null);
    setReminder(false);
    setSmsmsgvalue(null);
    setLocationLink(null);
    setHouseno(null);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const title = getPopupTitle('customer', isNew);
  const isEdu = tempId === 3;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <ImageOnlineUpload
                url={logourl}
                setUrl={setLogourl}
                image={logoimage}
                setImage={setLogoimage}
                width={150}
                height={150}
                size="400x400"
              ></ImageOnlineUpload>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {tempoptions?.additionId && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      autoFocus={tempoptions?.additionId}
                      name="additionId"
                      label={words.no}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextFieldLocal
                    autoFocus={!tempoptions?.additionId}
                    required
                    name="name"
                    label={words.name}
                    register={register}
                    errors={errors}
                    row={row}
                    newtext={newtext}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                {!tempoptions?.nameonelang && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      required
                      name="nameAr"
                      label={words.nameAr}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextFieldLocal
                    required
                    name="phone"
                    label={words.phoneNumber}
                    register={register}
                    errors={errors}
                    row={row}
                    newtext={newtext}
                    fullWidth
                    mb={0}
                  />
                </Grid>
              </Grid>
            </Grid>
            {customeroptions?.email && (
              <Grid item xs={12} md={6}>
                <TextFieldLocal
                  name="email"
                  label={words.email}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.area && (
              <Grid item xs={12} md={6}>
                <TextFieldLocal
                  name="area"
                  label={words.area}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.driver && (
              <Grid item xs={12} md={6}>
                <TextFieldLocal
                  name="driver"
                  label={words.driver}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.licenseNo && (
              <Grid item xs={12} md={6}>
                <TextFieldLocal
                  name="licenseNo"
                  label={words.licenseNo}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.national && (
              <Grid item xs={12} md={6}>
                <TextFieldLocal
                  name="national"
                  label={words.national}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.nationalNo && (
              <Grid item xs={12} md={6}>
                <TextFieldLocal
                  name="nationalNo"
                  label={words.nationalNo}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.address && (
              <Grid item xs={12}>
                <TextFieldLocal
                  name="address"
                  label={words.theaddress}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            {customeroptions?.birthday && (
              <Grid item xs={12} md={6} style={{ marginTop: -20 }}>
                <CalenderKeyboard
                  isRTL={isRTL}
                  label={words.birthday}
                  value={birthday}
                  onChange={(date: any) => setBirthday(date)}
                  width={200}
                ></CalenderKeyboard>
              </Grid>
            )}
            {customeroptions?.birthday && (
              <Grid item xs={6} md={2} style={{ marginTop: 10 }}>
                <CheckBoxLocal
                  name={isRTL ? 'تذكير' : 'Reminder'}
                  value={breminder}
                  setValue={setbReminder}
                  disabled={!birthday}
                ></CheckBoxLocal>
              </Grid>
            )}
            {customeroptions?.birthday && (
              <Grid item xs={6} md={2} style={{ marginTop: 10 }}>
                <CheckBoxLocal
                  name={isRTL ? 'رسالة نصية' : 'SMS'}
                  value={sreminder}
                  setValue={setsReminder}
                  disabled={!birthday}
                ></CheckBoxLocal>
              </Grid>
            )}
            {customeroptions?.birthday && (
              <Grid item xs={6} md={4}>
                <AutoFieldLocal
                  name="smsmsg"
                  title={words.smsmsg}
                  words={words}
                  options={msgtemplates?.filter((mt: any) => mt?.msgtype === 1)}
                  value={smsmsgvalue}
                  setSelectValue={setSmsmsgvalue}
                  isRTL={isRTL}
                  fullwidth
                  disabled={!sreminder}
                  mb={0}
                ></AutoFieldLocal>
              </Grid>
            )}

            {customeroptions?.nationalNo && (
              <Grid item xs={6} md={4} style={{ marginTop: -20 }}>
                <CalenderLocal
                  isRTL={isRTL}
                  label={words.nationalDate}
                  value={nationalDate}
                  onChange={(date: any) => setNationalDate(date)}
                  width={200}
                ></CalenderLocal>
              </Grid>
            )}
            {customeroptions?.nationalNo && (
              <Grid item xs={6} md={2} style={{ marginTop: 10 }}>
                <CheckBoxLocal
                  name={isRTL ? 'تذكير' : 'Reminder'}
                  value={nreminder}
                  setValue={setnReminder}
                  disabled={!nationalDate}
                ></CheckBoxLocal>
              </Grid>
            )}
            <Grid item xs={12} md={6}></Grid>
            {customeroptions?.licenseDate && (
              <Grid item xs={4} style={{ marginTop: -20 }}>
                <CalenderLocal
                  isRTL={isRTL}
                  label={words.expiretime}
                  value={licenseDate}
                  onChange={(date: any) => setLicenseDate(date)}
                  width={200}
                ></CalenderLocal>
              </Grid>
            )}
            {customeroptions?.licenseDate && (
              <Grid item xs={6} md={2} style={{ marginTop: 10 }}>
                <CheckBoxLocal
                  name={isRTL ? 'تذكير' : 'Reminder'}
                  value={reminder}
                  setValue={setReminder}
                  disabled={!licenseDate}
                ></CheckBoxLocal>
              </Grid>
            )}
            {customeroptions?.openingbalance && (
              <Grid item xs={12} md={6} style={{ marginTop: 10 }}>
                <TextFieldLocal
                  name="openingbalance"
                  label={words.openingbalance}
                  register={register}
                  errors={errors}
                  type="number"
                  row={row}
                  fullWidth
                  disabled={!isNew}
                  mb={0}
                />
              </Grid>
            )}

            {isEdu && (
              <>
                {customeroptions?.parentName && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      name="parentName"
                      label={words.parentName}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                {customeroptions?.parentPhone && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      name="parentPhone"
                      label={words.parentPhone}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
                {customeroptions?.grade && (
                  <Grid item xs={12}>
                    <TextFieldLocal
                      name="grade"
                      label={words.grade}
                      register={register}
                      errors={errors}
                      row={row}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                )}
              </>
            )}
            {customeroptions?.location && (
              <>
                <Grid item xs={12} md={8}>
                  <TextFieldLocal
                    name="locationLink"
                    label={isRTL ? 'الموقع ' : 'Location'}
                    value={locationLink}
                    onChange={(e: any) => setLocationLink(e.target.value)}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  {locationLink && (
                    <LinkLocation
                      isRTL={isRTL}
                      value={locationLink}
                    ></LinkLocation>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextFieldLocal
                    name="houseno"
                    label={isRTL ? 'رقم البيت' : 'House No'}
                    value={houseno}
                    onChange={(e: any) => setHouseno(e.target.value)}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
              </>
            )}
            {tempoptions?.shortcustomer && isNew && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addlink}
                      onChange={() => {
                        setAddlink(!addlink);
                      }}
                      name="isCalendar"
                      color="primary"
                    />
                  }
                  style={{ marginTop: 10 }}
                  label={
                    <Typography>
                      {isRTL ? 'اضافة رابط' : 'Add short Link'}
                    </Typography>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupCustomer;
