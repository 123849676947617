/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { Box, Fab, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useForm } from 'react-hook-form';

import { Autocomplete } from '@material-ui/lab';
import OptionProductData from './OptionProductData';
import { yup } from '../constants';
import { PopupExpenseItem } from '../pubups';
import {
  useCategories,
  useDepartments,
  useExpenseItems,
  useTemplate,
} from '../hooks';
import ListboxComponent from '../components/fields/ListboxComponent';
import { messageAlert } from './helpers';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import useTasks from '../hooks/useTasks';
import useResourses from '../hooks/useResourses';
import _ from 'lodash';

export default function ExpensesItemForm({
  items,
  addItem,
  words,
  classes,
  user,
  isRTL,
  setAlrt,
  opType,
  cost = false,
  itemrelations,
  employees,
}: any) {
  const [itemError, setItemError] = useState<any>(false);

  const [itemvalue, setItemvalue] = useState<any>(null);
  const [itemqty, setItemqty] = useState(1);
  const [itemprice, setItemprice] = useState(0);
  const [openItem, setOpenItem] = useState(false);
  const [newtext, setNewtext] = useState('');
  const [desc, setDesc] = useState('');

  const [taskvalue, setTaskvalue] = useState<any>(null);
  const [departvalue, setDepartvalue] = useState<any>(null);
  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [resovalue, setResovalue] = useState<any>(null);
  const [catvalue, setCatvalue] = useState<any>(null);

  const [fitems, setFItems] = useState([]);

  const { register, handleSubmit, errors } = useForm(yup.invItemResolver);

  const itemRef: any = React.useRef();
  const { addExpenseItem, editExpenseItem } = useExpenseItems();
  const { tempoptions, tempwords } = useTemplate();
  const { categories } = useCategories();
  const { departments } = useDepartments();
  const { resourses } = useResourses();
  const { tasks } = useTasks();

  useEffect(() => {
    if (catvalue) {
      const nitems = items.filter((it: any) => it.categoryId === catvalue?._id);
      const orderedItems = _.orderBy(nitems, 'barcode');
      setFItems(orderedItems);
    } else {
      const orderedallitems = _.orderBy(items, 'barcode');
      setFItems(orderedallitems);
    }
  }, [catvalue]);

  // useEffect(() => {
  //   setDepartvalue(departv);
  // }, [departv]);
  // useEffect(() => {
  //   setEmplvalue(emplv);
  // }, [emplv]);
  // useEffect(() => {
  //   setTaskvalue(taskv);
  // }, [taskv]);
  // const onOpenItem = () => {
  //   setOpenItem(true);
  // };
  const onCloseItem = () => {
    setOpenItem(false);
    setNewtext('');
  };

  const onNewItemChange = (nextValue: any) => {
    setItemvalue(nextValue);
    setItemprice(nextValue?.price || 0);
  };

  const resetAll = () => {
    setItemprice(0);
    setItemqty(1);
    setItemvalue(null);
    setDesc('');
  };

  const costprice = itemprice === 0 ? 0 : cost ? itemvalue?.cost : itemprice;

  const addLocalItem = async () => {
    if (!itemvalue) {
      setItemError(true);
      itemRef.current.focus();
      return;
    }
    if (opType === 61 && itemvalue.quantity < itemqty) {
      await messageAlert(
        setAlrt,
        isRTL ? 'لا يوجد كمية في المخزم' : 'No stock avaliable'
      );
      return;
    }
    const itemdata = {
      ...itemvalue,
      itemqty,
      itemprice: costprice,
      itemtotal: itemqty * costprice,
      itemtotalcost: itemqty * itemvalue.cost,
      contractId: taskvalue?._id,
      contractName: taskvalue?.name,
      contractNameAr: taskvalue?.nameAr,
      departmentId: departvalue?._id,
      departmentName: departvalue?.name,
      departmentNameAr: departvalue?.nameAr,
      departmentColor: departvalue?.color,
      employeeId: emplvalue?._id,
      employeeName: emplvalue?.name,
      employeeNameAr: emplvalue?.nameAr,
      employeeColor: emplvalue?.color,
      employeePhone: emplvalue?.phone,
      resourseId: resovalue?._id,
      resourseName: resovalue?.name,
      resourseNameAr: resovalue?.nameAr,
      resourseColor: resovalue?.color,
      userId: user._id,
      note: desc,
    };
    addItem(itemdata);
    resetAll();
    itemRef.current.focus();
  };

  const name =
    opType === 61
      ? isRTL
        ? 'المنتج'
        : 'Product'
      : isRTL
      ? 'المصروف'
      : 'Expenses';

  return (
    <Box
      display="flex"
      style={{
        flex: 1,
        margin: 10,
        borderRadius: 5,
      }}
    >
      <Box display="flex" style={{ flex: 1 }}>
        <Grid container spacing={1}>
          <Grid xs={12} md={2}>
            <Autocomplete
              autoSelect
              size="small"
              options={categories.filter((dep: any) => dep.catType === 10)}
              ListboxComponent={ListboxComponent}
              getOptionLabel={(option: any) =>
                `${isRTL ? option.nameAr : option.name}`
              }
              getOptionSelected={(option, values) => option._id === values._id}
              renderOption={(option) => (
                <OptionProductData
                  isRTL={isRTL}
                  item={option}
                  price={false}
                ></OptionProductData>
              )}
              fullWidth
              value={catvalue}
              onChange={(_, newValue: any) => {
                setCatvalue(newValue);
                setItemvalue(null);
              }}
              style={{ direction: isRTL ? 'rtl' : 'ltr', marginTop: 4 }}
              classes={{ input: classes.smallFont }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="category"
                  name="category"
                  label={words.category}
                  error={itemError}
                  variant="outlined"
                  fullWidth
                  inputRef={(ref) => {
                    itemRef.current = ref;
                  }}
                  InputLabelProps={{
                    style: { fontSize: isRTL ? 16 : 13 },
                  }}
                ></TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              autoSelect
              size="small"
              options={fitems}
              ListboxComponent={ListboxComponent}
              getOptionLabel={(option: any) =>
                `${isRTL ? option.nameAr : option.name} ${option.barcode}`
              }
              getOptionSelected={(option, values) => option._id === values._id}
              renderOption={(option) => (
                <OptionProductData
                  isRTL={isRTL}
                  item={option}
                ></OptionProductData>
              )}
              fullWidth
              value={itemvalue}
              onChange={(_, newValue: any) => {
                setItemvalue(newValue);
                setItemprice(newValue?.price || 0);
                if (newValue) {
                  setItemError(false);
                }
              }}
              style={{ direction: isRTL ? 'rtl' : 'ltr' }}
              classes={{ input: classes.smallFont }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="service"
                  name="service"
                  label={name}
                  error={itemError}
                  variant="outlined"
                  fullWidth
                  inputRef={(ref) => {
                    itemRef.current = ref;
                  }}
                  InputLabelProps={{
                    style: { fontSize: isRTL ? 16 : 13 },
                  }}
                ></TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <TextField
              name="qty"
              onChange={(e: any) => setItemqty(Number(e.target.value))}
              value={itemqty}
              label={words.qty}
              variant="outlined"
              inputRef={register}
              type="number"
              error={errors.qty ? true : false}
              margin="dense"
              onFocus={(e) => e.target.select()}
              inputProps={{
                style: { textAlign: 'right', fontSize: 13, height: 13 },
                inputMode: 'decimal',
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={10} md={2}>
            <TextField
              name="price"
              onChange={(e: any) => setItemprice(Number(e.target.value))}
              value={costprice}
              label={words.cost}
              error={errors.price ? true : false}
              variant="outlined"
              inputRef={register}
              type="number"
              margin="dense"
              onFocus={(e) => e.target.select()}
              inputProps={{
                style: { textAlign: 'right', fontSize: 13, height: 13 },
                inputMode: 'decimal',
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} md={1}>
            <Fab
              style={{ marginLeft: 10, marginTop: 5, width: 36, height: 36 }}
              color="primary"
              size="small"
              onClick={handleSubmit(addLocalItem)}
              title="Create new row"
            >
              <AddIcon />
            </Fab>
          </Grid>
          {tempoptions?.itemdescription && (
            <Grid item xs={12} md={11}>
              <div>
                <TextField
                  name="desc"
                  onChange={(e: any) => setDesc(e.target.value)}
                  value={desc}
                  label={words.description}
                  variant="outlined"
                  margin="dense"
                  onFocus={(e) => e.target.select()}
                  fullWidth
                  multiline
                  rowsMax={2}
                  rows={2}
                  style={{ marginTop: 0, marginBottom: 0 }}
                />
              </div>
            </Grid>
          )}
          {itemrelations && (
            <>
              {tempoptions?.contract && (
                <Grid item xs={3}>
                  <AutoFieldLocal
                    name="task"
                    title={tempwords?.task}
                    words={words}
                    options={tasks}
                    value={taskvalue}
                    setSelectValue={setTaskvalue}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              {tempoptions?.department && (
                <Grid item xs={3}>
                  <AutoFieldLocal
                    name="department"
                    title={tempwords?.department}
                    words={words}
                    options={departments.filter((d: any) => d.depType === 1)}
                    value={departvalue}
                    setSelectValue={setDepartvalue}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              {tempoptions?.employee && (
                <Grid item xs={3}>
                  <AutoFieldLocal
                    name="employee"
                    title={tempwords?.employee}
                    words={words}
                    options={employees.filter((emp: any) => !emp.disabled)}
                    value={emplvalue}
                    setSelectValue={setEmplvalue}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              {tempoptions?.resourse && (
                <Grid item xs={3}>
                  <AutoFieldLocal
                    name="resourse"
                    title={tempwords?.resourse}
                    words={words}
                    options={resourses.filter((emp: any) => !emp.disabled)}
                    value={resovalue}
                    setSelectValue={setResovalue}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <PopupExpenseItem
          newtext={newtext}
          open={openItem}
          onClose={onCloseItem}
          isNew={true}
          setNewValue={onNewItemChange}
          row={null}
          addAction={addExpenseItem}
          editAction={editExpenseItem}
        ></PopupExpenseItem>
      </Box>
    </Box>
  );
}
