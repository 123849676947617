import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import MonthsChart from '../MonthsChart';

function MonthsSales({ isRTL, theme, systemcolor, color, height }) {
  const { salesMonths } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {salesMonths && userroles?.invoices?.admin && (
        <MonthsChart
          data={salesMonths}
          isRTL={isRTL}
          color={clr}
          dataKey="total"
          title={isRTL ? 'المبيعات بحسب الشهور' : 'Months Sales'}
          height={height}
        ></MonthsChart>
      )}
    </Box>
  );
}

export default MonthsSales;
