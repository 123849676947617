/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Badge, Box, Divider, Typography } from '@material-ui/core';
import MyIcon from '../../Shared/MyIcon';

export default function TabsListVertical({
  isRTL,
  list,
  handleSelect,
  color,
  pathname,
  height,
  width = 200,
}: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_: any, newValue: number) => {
    if (value !== newValue) {
      setValue(newValue);
      handleSelect(list?.[newValue]);
    }
  };

  useEffect(() => {
    let index: any;
    list.map((it: any, i: any) => {
      if (pathname?.includes(it?.path)) {
        index = i;
      }
    });
    setValue(index);
  }, [pathname]);

  return (
    <Box style={{ flexGrow: 1, height: height - 100, overflow: 'auto', width }}>
      <Tabs
        value={value}
        orientation="vertical"
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {list?.map((item: any) => {
          const isselect = pathname?.includes(item?.path);
          const title = isRTL ? item.nameAr : item.name;
          if (item.opType > 1000) return <Divider></Divider>;
          return (
            <Tab
              style={{
                backgroundColor: isselect ? color : undefined,
              }}
              key={item.opType}
              label={
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    minWidth: 120,
                  }}
                >
                  {item?.icon && item?.new > 0 && (
                    <>
                      <Badge
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        badgeContent={item.new}
                        color="error"
                      >
                        <MyIcon
                          size={26}
                          color="#555"
                          icon={item?.icon}
                        ></MyIcon>
                      </Badge>
                      <div style={{ width: 10 }}></div>
                    </>
                  )}
                  {item?.icon && !item.new && (
                    <>
                      <MyIcon size={26} color="#555" icon={item?.icon}></MyIcon>
                      <div style={{ width: 10 }}></div>
                    </>
                  )}
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
