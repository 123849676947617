import { gql } from '@apollo/client';

export default gql`
  query getPCategories {
    getPCategories {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        additionId
        name
        nameAr
        userId

        createdAt
        updatedAt
      }
    }
  }
`;
