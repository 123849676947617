import { Box, colors, Grid, Paper } from '@material-ui/core';
import { moneyFormat, rCellMain } from '../../common/helpers';
import PurchaseChart from '../charts/PurchaseChart';

export default function PurchaseDataBx({ row, theme, isRTL, height = 200 }) {
  const scolor = colors.deepPurple[400];
  const pcolor = colors.green[400];
  const dcolor = colors.orange[400];
  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <PurchaseChart
            scolor={scolor}
            pcolor={pcolor}
            dcolor={dcolor}
            row={row}
            theme={theme}
            height={height}
          ></PurchaseChart>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              {rCellMain(isRTL ? 'المشتريات' : 'Purchases', scolor)}
            </Grid>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              {rCellMain(moneyFormat(row?.totalPurchaseInvoiced), scolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'المدفوعات' : 'Payments', pcolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(row?.totalPurchasePaid), pcolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'الخصومات' : 'Discounts', dcolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(row?.totalPurchaseDiscount), dcolor)}
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: 20 }}>
                {rCellMain(isRTL ? 'الرصيد' : 'Balance', '#333')}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: 20 }}>
                {rCellMain(
                  moneyFormat(
                    row?.totalPurchaseInvoiced -
                      row?.totalPurchasePaid -
                      row?.totalPurchaseDiscount
                  ),
                  '#333'
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
