/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import { Box, Fab, Grid, TextField, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default function ServiceItemForm({ addItem, words }: any) {
  const [name, setName] = useState<any>(null);
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(0);

  const isMobile = useMediaQuery('(max-width:600px)');

  const resetAll = () => {
    setName(null);
    setPrice(0);
    setQty(1);
  };

  const addLocalItem = async () => {
    if (!name) {
      return;
    }
    const itemdata = {
      name,
      qty,
      price,
      total: Math.round(qty * price),
    };
    addItem(itemdata);
    resetAll();
  };

  return (
    <Box
      display="flex"
      style={{
        flex: 1,
        margin: 5,
        borderRadius: 5,
        alignItems: 'center',
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <Box display="flex" style={{ flex: 1 }}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <TextField
                  name="name"
                  onChange={(e: any) => setName(e.target.value)}
                  value={name}
                  label={words.item}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  // style={{ marginTop: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <TextField
                  name="qty"
                  onChange={(e: any) => setQty(Number(e.target.value))}
                  value={qty}
                  label={words.qty}
                  variant="outlined"
                  type="number"
                  margin="dense"
                  onFocus={(e) => e.target.select()}
                  inputProps={{
                    style: { textAlign: 'right', fontSize: 13 },
                    inputMode: 'decimal',
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} md={2}>
                <div>
                  <TextField
                    name="price"
                    onChange={(e: any) => setPrice(Number(e.target.value))}
                    value={price}
                    label={words.theprice}
                    variant="outlined"
                    type="number"
                    fullWidth
                    margin="dense"
                    onFocus={(e) => e.target.select()}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                        fontSize: 13,
                      },
                      inputMode: 'decimal',
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={2} md={1}>
                <Fab
                  style={{
                    marginLeft: 10,
                    marginTop: 5,
                    width: 36,
                    height: 36,
                  }}
                  color="primary"
                  onClick={addLocalItem}
                  title="Create new row"
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
