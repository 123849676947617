/* eslint-disable import/no-anonymous-default-export */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createMultiSections,
  createSection,
  deleteSection,
  updateSection,
} from '../graphql/mutation';
import { getSections } from '../graphql/query';

export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getSections);

  const [addSection] = useMutation(createSection, {
    refetchQueries: [{ query: getSections }],
  });
  const [addMultiSection] = useMutation(createMultiSections, {
    refetchQueries: [{ query: getSections }],
  });
  const [editSection] = useMutation(updateSection, {
    refetchQueries: [{ query: getSections }],
  });
  const [removeSection] = useMutation(deleteSection, {
    refetchQueries: [{ query: getSections }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const sections = catpData?.data?.['getSections']?.data || [];
  const refreshsection = () => catpData?.refetch();
  return {
    sections,
    addSection,
    addMultiSection,
    editSection,
    removeSection,
    refreshsection,
    loading: catpData?.loading,
  };
};
