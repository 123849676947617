import { gql } from '@apollo/client';

export default gql`
  mutation deleteMultiEmployees($ids: [String]) {
    deleteMultiEmployees(ids: $ids) {
      ok
      message
      data
      error
    }
  }
`;
