/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';

export default function Avatar({
  name,
  username,
  size = 44,
  bc = '#ddd',
  bg,
  header,
  setOpen,
  email,
  isRTL,
  logout,
  navigate,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const uname = name ? name : username;
  const uemail = email ? email : username;
  const letter = uname?.substring(0, 1)?.toUpperCase();
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (logout) {
      logout();
    }
    setAnchorEl(null);
  };
  const handleHome = () => {
    if (navigate) {
      navigate('/');
    }
    setAnchorEl(null);
  };
  const handleOptions = () => {
    if (navigate) {
      navigate('/options');
    }
    setAnchorEl(null);
  };
  const handleOpenProfile = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Paper
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          padding: 0,
          margin: 0,
          backgroundColor: theme.palette.secondary.main,
        }}
        aria-describedby={id}
      >
        <Box
          style={{
            display: 'flex',
            width: size,
            height: size,
            borderRadius: size / 2,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleClick}
        >
          <Typography
            color="primary"
            style={{
              fontWeight: 'bold',
              fontSize: size / 2,
            }}
          >
            {letter}
          </Typography>
        </Box>
      </Paper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { borderRadius: 15, marginTop: 5 } }}
      >
        <Box>
          <List style={{ minWidth: 200, padding: 10 }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography style={{ fontWeight: 'bold' }}>
                    {uname}
                  </Typography>
                }
                secondary={uemail}
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={handleHome}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'الصفحة الرئيسية' : 'Home'}</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={handleOpenProfile}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'الملف الشخصي' : 'Profile'}</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={handleOptions}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'الاعدادات' : 'Options'}</Typography>
              </ListItemText>
            </ListItem>
            <Divider />

            <ListItem
              button
              onClick={handleLogout}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography style={{ fontSize: 13, fontWeight: 'bold' }}>
                  {isRTL ? 'تسجيل خروج' : 'Logout'}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
}
