/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { useTemplate } from '../../hooks';
import { Typography } from '@material-ui/core';
import useCompany from '../../hooks/useCompany';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getFormOperations } from '../../graphql/query';
import {
  createFormOperation,
  deleteFormOperation,
  updateFormOperation,
} from '../../graphql/mutation';

import PopupEditing from '../components/PopupEditing';
import { Command } from '../components/Command';
import { errorAlert, errorDeleteAlert, getRowId } from '../common/funcs';
import { userroles } from '../constants';
import SearchTable from '../components/SearchTable';
import AlertLocal from '../components/AlertLocal';
import { TableComponent } from '../components/TableComponent';
import { getColumns } from '../constants/columns';
import {
  contactTypeFormatter,
  operationNameFormatter,
  timeFormatter,
  userFormatter,
} from '../common/formater';
import PopupFormOperation from '../shared/PopupFormOperation';
import PopupFormOperationView from '../shared/PopupFormOperationView';

export default function PresalesDocument({
  isRTL,
  words,
  theme,
  start,
  end,
  formdoc,
  users,
}: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([]);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const { company } = useCompany();
  const { tempoptions } = useTemplate();

  const col = getColumns({ isRTL, words });

  const fields = formdoc?.fields ? JSON.parse(formdoc?.fields) : [];
  const fieldsnames =
    fields && fields.length > 0 ? fields.map((f: any) => f.field) : [];

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 120 },
    { columnName: 'docNo', width: 120 },
    { columnName: col.title.name, width: 300 },
    { columnName: col.contact.name, width: 200 },
    { columnName: col.employee.name, width: 200, align: 'center' },
    { columnName: 'desc', width: 200 },
    { columnName: 'userId', width: 120, align: 'center' },
    { columnName: 'name', width: 200 },
    { columnName: 'phone', width: 150 },
    { columnName: 'contacttype', width: 150 },
    { columnName: 'groupIds', width: 200 },
    { columnName: 'email', width: 200 },
    { columnName: 'company', width: 200 },
    { columnName: 'address', width: 200 },
    { columnName: 'notes', width: 200 },
  ]);

  useEffect(() => {
    const cls = [
      fieldsnames.includes('time') && col.time,
      col.docNo,
      fieldsnames.includes('title') && col.title,
      fieldsnames.includes('contact') && col.contact,
      fieldsnames.includes('employee') && tempoptions?.employee && col.employee,
      col.userId,
    ].filter((x) => x);
    setColumns(cls);
  }, [formdoc?.autoNoType]);

  const [loadOperations, operationsData]: any = useLazyQuery(
    getFormOperations,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const refresQuery = {
    refetchQueries: [
      {
        query: getFormOperations,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
          dcategoryId: formdoc?.dcategoryId,
        },
      },
    ],
  };

  const [addFormOperation] = useMutation(createFormOperation, refresQuery);
  const [editFormOperation] = useMutation(updateFormOperation, refresQuery);
  const [removeFormOperation] = useMutation(deleteFormOperation, refresQuery);

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
      dcategoryId: formdoc?.dcategoryId,
    };
    if (start && end) {
      loadOperations({ variables });
    }
  }, [start, end]);

  useEffect(() => {
    const opsdata = operationsData?.data?.getFormOperations?.data;
    if (opsdata) {
      const rdata = opsdata.filter((od: any) => od.opType === formdoc?.opType);
      setRows(rdata);
    } else {
      setRows([]);
    }
  }, [operationsData, formdoc]);

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeFormOperation({ variables: { _id } });
      if (res?.data?.deleteFormOperation?.ok === false) {
        if (res?.data?.deleteFormOperation?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  return (
    <>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 50 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={[
            'name',
            'phone',
            'contacttype',
            'groupIds',
            'email',
            'company',
            'address',
            'notes',
            col.time.name,
            col.docNo.name,
            col.title.name,
            col.contact.name,
            col.employee.name,
            col.desc.name,
            col.userId.name,
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={[]}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['time']}
          formatterComponent={timeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['userId']}
          formatterComponent={(props: any) =>
            userFormatter({ ...props, users })
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['contacttype']}
          formatterComponent={contactTypeFormatter}
        ></DataTypeProvider>
        <TableColumnVisibility
          defaultHiddenColumnNames={['address', 'notes']}
        />
        {userroles?.presales?.admin && (
          <DataTypeProvider
            for={['title']}
            formatterComponent={(props: any) =>
              operationNameFormatter({
                ...props,
                setItem,
                setOpenItem,
                isRTL,
              })
            }
          ></DataTypeProvider>
        )}
        <TableEditColumn
          showEditCommand={userroles?.presales?.edit}
          showDeleteCommand={userroles?.presales?.delete}
          showAddCommand={userroles?.presales?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 15, 20, 50, 0] : [5, 10, 15, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          theme={theme}
          addAction={addFormOperation}
          editAction={editFormOperation}
          company={company}
        >
          <PopupFormOperation
            words={words}
            formdoc={formdoc}
            formTempValues={
              formdoc?.formTempValues ? JSON.parse(formdoc?.formTempValues) : {}
            }
          ></PopupFormOperation>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupFormOperationView
        open={openItem}
        onClose={onCloseItem}
        row={item}
        theme={theme}
      ></PopupFormOperationView>
    </>
  );
}
