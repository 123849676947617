import { Grid } from '@material-ui/core';
import { useClassnames } from './clasnames';
import { renderCell } from './renders';
import './index.css';
function PrintPaper({ template, data, isRTL }) {
  const isPOS = template?.size === 'POS';

  const tempdata = template?.template ? JSON.parse(template?.template) : [];
  let bgimages: any;
  if (template?.bgimages) {
    bgimages = JSON.parse(template?.bgimages);
  }
  const stl = bgimages?.[0]
    ? {
        backgroundImage: `url(${bgimages[0]})`,
        backgroundPosition: 'top center', // 'center'
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }
    : {};

  const classes = useClassnames();
  if (!template || !data) return;
  return (
    <div style={stl}>
      {tempdata?.map((listitem: any) => {
        return (
          <Grid container spacing={0}>
            {listitem?.map((item: any) => {
              const { size, skip, type } = item;
              const args = {
                ...item,
                classes,
                data,
                item,
                isPOS,
                isRTL,
              };
              return (
                <>
                  {skip > 0 && <Grid item xs={skip}></Grid>}
                  <Grid item xs={size}>
                    {renderCell(type, args, classes, true)}
                  </Grid>
                </>
              );
            })}
          </Grid>
        );
      })}
    </div>
  );
}

export default PrintPaper;
