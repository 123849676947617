import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useState } from 'react';
import { userroles } from '../../../constants';
import { createInvoice, getLandingChartData } from '../../../../graphql';
import { useDepartments, useEmployees, useServices } from '../../../../hooks';
import useResourses from '../../../../hooks/useResourses';
import useTasks from '../../../../hooks/useTasks';
import { PopupInvoice } from '../../../../pubups';
import IconView from '../IconView';

function InvoiceIcon({
  isRTL,
  theme,
  color,
  height,
  company,
  employeeId = null,
}) {
  const [openInv, setOpenInv] = useState(false);
  const { resourses } = useResourses();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { services } = useServices();
  const { tasks } = useTasks();

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };

  const [addInvoice] = useMutation(createInvoice, refresQuery);

  return (
    <Box>
      {userroles?.invoices?.add && (
        <>
          <IconView
            icon="sales"
            title={isRTL ? 'انشاء فاتورة' : 'Add Invoice'}
            height={height}
            onOpen={() => setOpenInv(true)}
            color={color ? color : theme.palette.primary.main}
          ></IconView>
          <PopupInvoice
            open={openInv}
            onClose={() => setOpenInv(false)}
            addAction={addInvoice}
            editAction={() => null}
            resourses={resourses}
            employees={employees}
            departments={departments}
            company={company}
            servicesproducts={services}
            tasks={tasks}
            isNew={true}
            theme={theme}
          ></PopupInvoice>
        </>
      )}
    </Box>
  );
}

export default InvoiceIcon;
