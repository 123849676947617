/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { useTemplate } from '../../hooks';
import { downloadCSVFile, getRowId, userroles } from '../../common';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import {
  avataCategoryFormatter,
  booleanFormatter,
} from '../../Shared/colorFormat';
import ImportBtn from '../../common/ImportBtn';
import ExportBtn from '../../common/ExportBtn';
import useSections from '../../hooks/useSections';
import PopupSection from '../../pubups/PopupSection';
import PopupSectionImport from '../../pubups/PopupSectionImport';

const Categories = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme } = props;

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [openImport, setOpenImport] = useState(false);

  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      { name: 'avatar', title: ' ' },
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      { name: isRTL ? 'nameAr' : 'name', title: words.name },
      tempoptions?.pos && { name: 'ispos', title: isRTL ? 'نقطة بيع' : 'POS' },
      { name: 'order', title: isRTL ? 'الترتيب' : 'Order' },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 150 },
    { columnName: 'additionId', width: 150 },
    { columnName: isRTL ? 'nameAr' : 'name', width: 250 },
    { columnName: 'ispos', width: 100 },
    { columnName: 'order', width: 100 },
  ]);

  const {
    sections,
    addSection,
    addMultiSection,
    editSection,
    removeSection,
    refreshsection,
  } = useSections();

  const rows = sections;

  useImperativeHandle(ref, () => ({
    refresh() {
      if (refreshsection) {
        refreshsection();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removeSection({ variables: { _id } });
      if (res?.data?.deleteSection?.ok === false) {
        if (res?.data?.deleteSection?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'sections', colnames);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 68 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={['avatar', isRTL ? 'nameAr' : 'name', 'ispos', 'order']}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={[]}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['avatar']}
          formatterComponent={avataCategoryFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['ispos']}
          formatterComponent={booleanFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.sales?.edit}
          showDeleteCommand={userroles?.sales?.delete}
          showAddCommand={userroles?.sales?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          theme={theme}
          addAction={addSection}
          editAction={editSection}
        >
          <PopupSection></PopupSection>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupSectionImport
        open={openImport}
        onClose={() => setOpenImport(false)}
        addMultiItems={addMultiSection}
        isRTL={isRTL}
        theme={theme}
        words={words}
      ></PopupSectionImport>
    </>
  );
});

export default Categories;
