/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, userroles } from '../../common';
import { PopupContact } from '../../pubups';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import ImportBtn from '../../common/ImportBtn';
import useContacts from '../../hooks/useContacts';
import PopupContactImport from '../../pubups/PopupContactImport';
import useGroups from '../../hooks/useGroups';
import { avatarFormatter, groupFormatter } from '../../Shared/colorFormat';
import ImportBtns from '../../common/ImportBtns';
import { useTemplate } from '../../hooks';

const Contacts = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme } = props;
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [openImport, setOpenImport] = useState(false);
  const {
    contacts,
    refreshcontact,
    addContact,
    addMultiContacts,
    editContact,
    removeContact,
    syncCust,
    syncEmpl,
  } = useContacts();
  const { groups } = useGroups();
  const [columns] = useState([
    { name: 'avatar', title: ' ' },
    { name: 'name', title: words.name },
    { name: 'phone', title: words.phoneNumber },
    { name: 'groupIds', title: isRTL ? 'المجموعات' : 'Groups' },
    { name: 'email', title: words.email },
    { name: 'company', title: words.companyName },
    { name: 'address', title: words.address },
    { name: 'notes', title: words.notes },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 100 },
    { columnName: 'name', width: 200 },
    { columnName: 'phone', width: 150 },
    { columnName: 'groupIds', width: 200 },
    { columnName: 'email', width: 200 },
    { columnName: 'company', width: 200 },
    { columnName: 'address', width: 200 },
    { columnName: 'notes', width: 200 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'avatar', togglingEnabled: false },
    { columnName: 'name', togglingEnabled: false },
  ]);
  const { tempoptions } = useTemplate();

  useImperativeHandle(ref, () => ({
    refresh() {
      if (refreshcontact) {
        refreshcontact();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeContact({ variables: { _id } });
      if (res?.data?.deleteContact?.ok === false) {
        if (res?.data?.deleteContact?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ImportBtns
          isRTL={isRTL}
          syncCust={syncCust}
          syncEmpl={syncEmpl}
        ></ImportBtns>
      </Box>
      <Grid rows={contacts} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 60 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            'avatar',
            'name',
            'phone',
            'groupIds',
            'email',
            'company',
            'address',
            'notes',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={['address', 'notes']}
        />
        <DataTypeProvider
          for={['groupIds']}
          formatterComponent={(props) => groupFormatter(props, groups, isRTL)}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['avatar']}
          formatterComponent={avatarFormatter}
        ></DataTypeProvider>
        <TableColumnVisibility
          defaultHiddenColumnNames={['address', 'notes']}
        />
        <TableEditColumn
          showEditCommand={userroles?.contacts?.edit}
          showDeleteCommand={userroles?.contacts?.delete}
          showAddCommand={userroles?.contacts?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing
          theme={theme}
          addAction={addContact}
          editAction={editContact}
        >
          <PopupContact></PopupContact>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupContactImport
        open={openImport}
        onClose={() => setOpenImport(false)}
        addMultiItems={addMultiContacts}
        isRTL={isRTL}
        theme={theme}
        words={words}
      ></PopupContactImport>
    </>
  );
});
export default Contacts;
