/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import useServerLang from '../../hooks/useServerLang';
import Auth from '../auth';
import Content from './Content';

const Layout = ({ user, lang, languages, setLanguages, formdocs }: any) => {
  const { ar, en, updatedAt } = useServerLang();
  useEffect(() => {
    if (
      ar &&
      en &&
      lang &&
      (!languages?.updatedAt || languages?.updatedAt !== updatedAt)
    ) {
      setLanguages({ ar, en, updatedAt });
      window.location.reload();
    }
  }, [lang, ar, en, updatedAt]);

  return (
    <BrowserRouter>
      {!user && <Auth></Auth>}
      {user && <Content formdocs={formdocs}></Content>}
    </BrowserRouter>
  );
};
export default Layout;
