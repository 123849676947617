import { BarChart, Bar, ResponsiveContainer } from 'recharts';

export default function IncomeChart({ row, scolor, ecolor, height }: any) {
  const totalinvoiced = row?.totalinvoiced;
  const toatlProdExpenses = row?.toatlProdExpenses;
  const toatlExpenses = row?.toatlExpenses;
  const totalDiscount = row?.totalDiscount;
  const data = [
    {
      name: 'Sales',
      invoiced: (totalinvoiced || 0) - (totalDiscount || 0),
      expenses: (toatlExpenses || 0) + (toatlProdExpenses || 0),
    },
  ];
  const h = height ? height : 100;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={h * 0.8}
        height={h}
        data={data}
        margin={{
          top: 10,
          right: 5,
          left: 0,
          bottom: 10,
        }}
      >
        <Bar dataKey="invoiced" fill={scolor} />
        <Bar dataKey="expenses" fill={ecolor} />
      </BarChart>
    </ResponsiveContainer>
  );
}
