/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dublicateAlert, errorAlert, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal, TextFieldLocal } from '../components';
import { eventStatus, weekdaysNNo } from '../constants/datatypes';
import ServiceItemForm from '../Shared/ServiceItemForm';
import ItemsTable from '../Shared/ItemsTable';
import { invoiceClasses } from '../themes/classes';
import { getDateDayWeek, moneyFormat } from '../Shared/colorFormat';
import { useCustomers, useProducts, useTemplate } from '../hooks';
import PopupCustomer from './PopupCustomer';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import PopupResourses from './PopupResourses';
import useResourses from '../hooks/useResourses';
import { SelectLocal } from '../pages/calendar/common/SelectLocal';
import {
  byweekdayOptions,
  getDaysList,
  intervalOptions,
  monthdaysOptions,
} from '../constants/rrule';
import { LinkLocation, userroles } from '../common';
import useRetypes from '../hooks/useRetypes';
import SelectSimpleNumber from '../Shared/SelectSimpleNumber';
import RRule from 'rrule';
import { getEventsList, nanoSerial } from '../common/helpers';
import _ from 'lodash';
import { compressEvents } from '../common/time';
import getRruleData from '../common/getRruleData';
import SelectMulti from '../Shared/SelectMulti';
import { calculateDateList } from '../Sections/common/funcs';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const PopupAppointmentMulti = ({
  open,
  onClose,
  addAction,
  employees,
  resourses,
  departments,
  servicesproducts,
  tasks,
  task,
  theme,
  calendar,
}: any) => {
  const classes = invoiceClasses();
  const [saving, setSaving] = useState(false);
  const [rtypevalue, setRtypevalue] = useState<any>(null);
  const [rtype2value, setRtype2value] = useState<any>(null);
  const [bonos, setBonos] = useState<any>(null);
  const [overtime, setOvertime] = useState<any>(null);
  const [absent, setAbsent] = useState<any>(null);
  const [groupId, setGroupId] = useState<any>(nanoSerial(5));

  const [startDate, setStartDate]: any = useState(null);
  const [endDate, setEndDate]: any = useState(null);

  const [tminutes, setTMinutes]: any = useState(null);
  const [thours, setTHours]: any = useState(null);
  const [duration, setDuration]: any = useState(null);

  // const [eventLength, setEventLength]: any = useState(calendar?.eventlen);

  const [datelist, setDatelist]: any = useState([]);

  const [departvalue, setDepartvalue] = useState<any>(null);
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();

  const [resovalue, setResovalue] = useState<any>(null);
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [custvalue, setCustvalue] = useState<any>(null);
  const [openCust, setOpenCust] = useState(false);
  const [newtext, setNewtext] = useState('');

  const [status, setStatus]: any = useState(null);

  const [totals, setTotals] = useState<any>({});
  const [itemsList, setItemsList] = useState<any>([]);

  const [taskvalue, setTaskvalue] = useState<any>(task ? task : null);
  const [openDep, setOpenDep] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [tasktitle, setTasktitle]: any = useState(null);

  const [locationLink, setLocationLink] = useState(null);
  const [address, setAddress] = useState(null);
  const [houseno, setHouseno] = useState(null);

  const [evList, setEvList] = useState<any>([]);
  const [start, setStart]: any = useState(null);
  const [end, setEnd]: any = useState(null);
  const [rrule, setRrule] = useState<any>(null);
  const [weekdays, setWeekdays]: any = useState([]);
  const [byweekday, setByweekday] = useState([]);
  const [monthdays, setMonthdays] = useState([]);
  const [bymonthday, setBymonthday] = useState([]);
  const [isLastday, setIsLastday] = useState(false); // lastday of month
  const [isatStart, setIsatStart] = useState(false);
  const [freq, setFreq] = useState(RRule.DAILY);
  const [count, setCount] = useState(1);
  const [interval, setInterval] = useState(1);
  const [periodType, setPeriodType] = useState(1);
  const [many, setMany] = useState(false);
  const [total, setTotal] = useState<any>(0);
  const [tirm, setTirm] = useState<any>('custom');

  const { retypes } = useRetypes();
  const { customers, addCustomer, editCustomer } = useCustomers();
  const { addDepartment, editDepartment } = useDepartments();
  const { addEmployee, editEmployee } = useEmployees();
  const { addResourse, editResourse } = useResourses();
  const { tempwords, tempoptions, templateId, eventoptions } = useTemplate();
  const { products } = useProducts();

  const { register, handleSubmit } = useForm({});
  const {
    translate: { words, isRTL },
    store: { user, tirms },
  }: GContextTypes = useContext(GlobalContext);

  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };
  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };
  useEffect(() => {
    if (custvalue) {
      setLocationLink(
        custvalue.locationLink?.length > 0
          ? custvalue.locationLink
          : locationLink
      );
      setHouseno(custvalue.houseno?.length > 0 ? custvalue.houseno : houseno);
      setHouseno(custvalue.address?.length > 0 ? custvalue.address : address);
    }
  }, [custvalue]);

  useEffect(() => {
    if (startDate) {
      const end = duration
        ? new Date(startDate).getTime() + duration * 60 * 1000
        : null;
      if (end) {
        setEndDate(new Date(end));
      }
    }
  }, [duration, startDate]);

  useEffect(() => {
    if (taskvalue) {
      if (taskvalue?.departmentId) {
        const dept = departments.filter(
          (dep: any) => dep._id === taskvalue?.departmentId
        )?.[0];
        setDepartvalue(dept);
      }
      if (taskvalue?.customerId) {
        const dept = customers.filter(
          (dep: any) => dep._id === taskvalue?.customerId
        )?.[0];
        setCustvalue(dept);
      }
      if (taskvalue?.employeeId) {
        const dept = employees.filter(
          (dep: any) => dep._id === taskvalue?.employeeId
        )?.[0];
        setEmplvalue(dept);
      }
      if (taskvalue?.resourseId) {
        const dept = resourses.filter(
          (dep: any) => dep._id === taskvalue?.resourseId
        )?.[0];
        setResovalue(dept);
      }
      if (taskvalue?.retypeId) {
        const depart = retypes.filter(
          (dep: any) => dep._id === taskvalue.retypeId
        )[0];
        setRtypevalue(depart);
      }
      if (taskvalue?.retype2Id) {
        const depart = retypes.filter(
          (dep: any) => dep._id === taskvalue.retype2Id
        )[0];
        setRtype2value(depart);
      }
    }
  }, [taskvalue, open]);

  useEffect(() => {
    getOverallTotal();
  }, [itemsList]);
  useEffect(() => {
    const mins = calendar?.eventlen || 60;
    setDuration(mins);
    setTHours(Math.floor(mins / 60));
    setTMinutes(mins % 60);
  }, []);

  useEffect(() => {
    const start = new Date();
    setStart(start);
    setEvList([]);
  }, [open]);
  useEffect(() => {
    if (task) {
      setTaskvalue(task);
    }
  }, [open, task]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum;
    const profit = sum - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
  };

  const customer = custvalue
    ? {
        customerId: custvalue._id,
        customerName: custvalue.name,
        customerNameAr: custvalue.nameAr,
        customerPhone: custvalue.phone,
      }
    : {
        customerId: undefined,
        customerName: undefined,
        customerNameAr: undefined,
        customerPhone: undefined,
      };
  const contract = taskvalue
    ? {
        contractId: taskvalue._id,
        contractName: taskvalue.name,
        contractNameAr: taskvalue.nameAr,
      }
    : {
        contractId: undefined,
        contractName: undefined,
        contractNameAr: undefined,
      };

  const department = departvalue
    ? {
        departmentId: departvalue._id,
        departmentName: departvalue.name,
        departmentNameAr: departvalue.nameAr,
        departmentColor: departvalue.color,
      }
    : {
        departmentId: undefined,
        departmentName: undefined,
        departmentNameAr: undefined,
        departmentColor: undefined,
      };
  const employee = emplvalue
    ? {
        employeeId: emplvalue._id,
        employeeName: emplvalue.name,
        employeeNameAr: emplvalue.nameAr,
        employeeColor: emplvalue.color,
        employeePhone: emplvalue.phone,
      }
    : {
        employeeId: undefined,
        employeeName: undefined,
        employeeNameAr: undefined,
        employeeColor: undefined,
        employeePhone: undefined,
      };
  const resourse = resovalue
    ? {
        resourseId: resovalue._id,
        resourseName: resovalue.name,
        resourseNameAr: resovalue.nameAr,
        resourseColor: resovalue.color,
      }
    : {
        resourseId: undefined,
        resourseName: undefined,
        resourseNameAr: undefined,
        resourseColor: undefined,
      };
  const retype = rtypevalue
    ? {
        retypeId: rtypevalue._id,
        retypeName: rtypevalue.name,
        retypeNameAr: rtypevalue.nameAr,
        retypeColor: rtypevalue.color,
      }
    : {
        retypeId: undefined,
        retypeName: undefined,
        retypeNameAr: undefined,
        retypeColor: undefined,
      };

  const retype2 = rtype2value
    ? {
        retype2Id: rtype2value._id,
        retype2Name: rtype2value.name,
        retype2NameAr: rtype2value.nameAr,
        retype2Color: rtype2value.color,
      }
    : {
        retype2Id: undefined,
        retype2Name: undefined,
        retype2NameAr: undefined,
        retype2Color: undefined,
      };

  useEffect(() => {
    const itemsTotal = _.sumBy(itemsList, 'itemtotal');
    setTotal(itemsTotal);
  }, [itemsList]);

  useEffect(() => {
    if (weekdays && weekdays.length > 0) {
      const vals = getDaysList(weekdays);
      setByweekday(vals);
    }
  }, [weekdays]);

  useEffect(() => {
    if (monthdays && monthdays.length > 0) {
      const bmd = monthdays.map((wd: any) => wd.value);
      setBymonthday(bmd);
    }
  }, [monthdays]);

  useEffect(() => {
    if (freq !== RRule.WEEKLY) {
      setWeekdays([]);
      setByweekday([]);
    }
  }, [freq]);

  useEffect(() => {
    if ((start && count) || (start && end && many)) {
      const rdata = getRruleData({
        freq,
        byweekday: byweekday?.length > 0 ? byweekday : undefined,
        dtstart: start,
        until: many ? end : undefined,
        interval,
        bymonthday: bymonthday?.length > 0 ? bymonthday : undefined,
        count: many ? null : count,
      });
      if (!end && rdata) {
        const d = rdata.all[rdata?.all?.length - 1];
        setEnd(d);
      }
      setRrule(rdata);
      const calcdatelist = calculateDateList(rdata?.all, duration);
      setDatelist(calcdatelist);
    }
  }, [
    start,
    end,
    freq,
    count,
    interval,
    weekdays,
    byweekday,
    monthdays,
    bymonthday,
    duration,
    many,
  ]);
  useEffect(() => {
    if (start) {
      const newList = !tempoptions?.itemrelations
        ? itemsList?.map((il: any) => {
            return {
              ...il,
              contractId: taskvalue?._id,
              contractName: taskvalue?.name,
              contractNameAr: taskvalue?.nameAr,
              departmentId: departvalue?._id,
              departmentName: departvalue?.name,
              departmentNameAr: departvalue?.nameAr,
              departmentColor: departvalue?.color,
              employeeId: emplvalue?._id,
              employeeName: emplvalue?.name,
              employeeNameAr: emplvalue?.nameAr,
              employeeColor: emplvalue?.color,
              employeePhone: emplvalue?.phone,
              resourseId: resovalue?._id,
              resourseName: resovalue?.name,
              resourseNameAr: resovalue?.nameAr,
              resourseColor: resovalue?.color,
            };
          })
        : itemsList;
      const event = {
        title: tasktitle,
        startDate: start,
        endDate: new Date(new Date(start).getTime() + duration * 60 * 1000),
        locationLink: locationLink?.length > 0 ? locationLink : null,
        address,
        houseno: houseno?.length > 0 ? houseno : null,
        amount: total,
        status: 2,
        items: JSON.stringify(newList),
        showOnCal: true,
        customer,
        contract,
        department,
        employee,
        resourse,
        retype,
        retype2,
        groupId,
        user: user._id,
      };
      if (rrule) {
        const eventlist = getEventsList({
          event,
          rrule,
          isRTL,
          weekdays,
          monthdays,
          isLastday,
          isatStart,
          eventLength: duration,
        });
        const sorted = _.sortBy(eventlist, 'startDate');
        const listwithindex = indexTheList(sorted);
        setEvList(listwithindex);
      }
    }
  }, [
    itemsList,
    rrule,
    custvalue,
    taskvalue,
    departvalue,
    emplvalue,
    resovalue,
    rtypevalue,
    rtype2value,
    start,
    end,
    total,
    isatStart,
    isLastday,
    weekdays,
    bymonthday,
    duration,
    locationLink,
    address,
    houseno,
    groupId,
  ]);

  const onChangePeriodType = (e: any) => {
    const value = e.target.value;
    setPeriodType(value);
    if (value === 1) {
      setFreq(RRule.DAILY);
      setInterval(value);
      setMonthdays([]);
      setBymonthday([]);
    } else if (value === 7) {
      setFreq(RRule.WEEKLY);
      setInterval(1);
      setMonthdays([]);
      setBymonthday([]);
    } else if (value === 30) {
      setFreq(RRule.DAILY);
      setInterval(value);
      setMonthdays([]);
      setBymonthday([]);
    } else if (value === 31) {
      setFreq(RRule.MONTHLY);
      setInterval(1);
      setMonthdays([]);
      setBymonthday([]);
    } else if (value === 11) {
      setFreq(1);
      setInterval(1);
      setMonthdays([{ id: 1, name: '1', nameAr: '1', value: 1 }]);
      setBymonthday([1]);
      setIsLastday(false);
    } else if (value === 33) {
      setFreq(1);
      setInterval(1);
      setMonthdays([{ id: 1, name: '1', nameAr: '1', value: 1 }]);
      setBymonthday([1]);
      setIsLastday(true);
    } else if (value === 100) {
      setFreq(1);
      setInterval(1);
      setCount(1);
      setMonthdays([]);
      setBymonthday([]);
      const rdata = getRruleData({
        freq: 1,
        byweekday: [],
        dtstart: start,
        until: end,
        interval: 1,
        bymonthday: [],
        count: 1,
      });
      setRrule(rdata);
    } else if (value === 180) {
      setFreq(RRule.MONTHLY);
      setInterval(6);
      setMonthdays([]);
      setBymonthday([]);
      setIsLastday(false);
    }
  };

  const onChangeCount = (e: any) => {
    const value = Number(e.target.value);
    const count = value < 1 ? 1 : value > 365 ? 365 : value;
    setCount(count);
  };
  const onChangeInterval = (e: any) => {
    const value = Number(e.target.value);
    setInterval(value > 1 ? value : 1);
  };
  const onNewCustChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };
  const onNewDepartChange = (nextValue: any) => {
    setDepartvalue(nextValue);
  };
  const onNewEmplChange = (nextValue: any) => {
    setEmplvalue(nextValue);
  };
  const onNewResoChange = (nextValue: any) => {
    setResovalue(nextValue);
  };

  const resetAllForms = () => {
    setStartDate(null);
    setEndDate(null);
    setCustvalue(null);
    setDepartvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setStatus(null);
    setTaskvalue(null);
    setItemsList([]);
    setTotals({});
    setSaving(false);
    setTasktitle(null);
    setDuration(null);
    setTHours(null);
    setTMinutes(null);
    setRtypevalue(null);
    setRtype2value(null);
    setBonos(null);
    setOvertime(null);
    setAbsent(null);
    setLocationLink(null);
    setAddress(null);
    setHouseno(null);
    setStart(null);
    setEnd(null);
    setCustvalue(null);
    setDepartvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setTasktitle(null);
    setSaving(false);
    setRrule(null);
    setItemsList([]);
    setCount(1);
    setInterval(1);
    setPeriodType(1);
    setFreq(RRule.DAILY);
    setMany(false);
    setTotal(0);
    setBymonthday(null);
    setIsLastday(false);
    setIsatStart(false);
    setMonthdays([]);
    setWeekdays([]);
    setRtypevalue(null);
    setRtype2value(null);
    setGroupId(nanoSerial(5));
    setTirm('custom');
    setDatelist([]);
  };
  const onSubmit = async () => {
    if (startDate > endDate) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    setSaving(true);
    const events =
      evList && evList.length > 0 ? compressEvents(evList) : undefined;
    try {
      await addAction({ variables: { events } });
      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log('error popup', error);
    }
  };
  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
    setSaving(false);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const day = weekdaysNNo?.[startDate?.getDay()];

  const title = isRTL ? 'اضافة متعدد' : 'Add Multi';

  const contEditor = templateId === 6 && !userroles?.appointments?.admin;
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      mt={10}
      mb={20}
      preventclose
      maxWidth="lg"
      taskvalue={taskvalue}
    >
      <>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                  {tempoptions?.tirms && (
                    <Box
                      style={{
                        position: 'absolute',
                        left: 100,
                        top: 7,
                        zIndex: 22,
                        display: 'flex',
                      }}
                    >
                      <Box
                        style={{
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: -5,
                        }}
                      >
                        <TextFieldLocal
                          name="groupId"
                          label={isRTL ? 'مجموعة' : 'Group'}
                          value={groupId}
                          onChange={(e: any) => setGroupId(e.target.value)}
                          width={150}
                          mb={0}
                        />
                      </Box>
                      <RadioGroup
                        aria-label="Views"
                        name="views"
                        row
                        value={tirm}
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setTirm(value);
                          if (value === 'first' && tirms?.endFirst) {
                            setStart(new Date());
                            setEnd(new Date(tirms?.endFirst));
                          }
                          if (value === 'second' && tirms?.endSecond) {
                            setStart(new Date());
                            setEnd(new Date(tirms?.endSecond));
                          }
                          if (value === 'custom') {
                            setStart(new Date());
                            setEnd(null);
                          }
                        }}
                      >
                        <FormControlLabel
                          value="custom"
                          control={<Radio color="primary" />}
                          label={
                            <Typography
                              style={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                              {isRTL ? 'مخصص' : 'Custom'}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="first"
                          control={<Radio color="primary" />}
                          label={
                            <Typography
                              style={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                              {isRTL ? 'الفصل الأول' : 'First Tirm'}
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          value="second"
                          control={<Radio color="primary" />}
                          label={
                            <Typography
                              style={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                              {isRTL ? 'الفصل الثاني' : 'Second Tirm'}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  )}
                  <Grid item xs={12} md={3}>
                    <CalenderLocal
                      label={words.start}
                      value={start}
                      onChange={(d: any) => setStart(d)}
                      time
                      isRTL={isRTL}
                    ></CalenderLocal>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <CalenderLocal
                      value={end}
                      label={words.end}
                      onChange={(d: any) => {
                        if (d) {
                          setEnd(d);
                        } else {
                          setEnd(null);
                        }
                      }}
                    ></CalenderLocal>
                  </Grid>
                  <Grid item xs={12} md={2} style={{ marginTop: 25 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isatStart}
                          onChange={() => setIsatStart(!isatStart)}
                          color="primary"
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: theme.palette.primary.main,
                            marginTop: 5,
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                          variant="subtitle1"
                        >
                          {isRTL ? 'مع بداية الفترة' : 'At Beginning'}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{ marginTop: 25 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={many}
                          onChange={() => {
                            setMany(!many);
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography
                          style={{
                            color: theme.palette.primary.main,
                            marginTop: 5,
                            fontSize: 12,
                            fontWeight: 'bold',
                            width: 75,
                          }}
                          variant="subtitle1"
                        >
                          {isRTL ? 'حتى النهاية' : 'Till The End'}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: 10 }}>
                    <SelectLocal
                      options={intervalOptions}
                      value={periodType}
                      onChange={onChangePeriodType}
                      isRTL={isRTL}
                      fullWidth
                    ></SelectLocal>
                  </Grid>
                  {freq === RRule.WEEKLY && (
                    <Grid item xs={3} style={{ marginTop: 18 }}>
                      <SelectMulti
                        options={byweekdayOptions}
                        value={weekdays}
                        setValue={setWeekdays}
                        words={words}
                        isRTL={isRTL}
                        name="weekdays"
                        fullWidth
                        mb={0}
                      ></SelectMulti>
                    </Grid>
                  )}
                  {freq === RRule.MONTHLY && periodType === 31 && (
                    <Grid item xs={3} style={{ marginTop: 18 }}>
                      <SelectMulti
                        options={monthdaysOptions}
                        value={monthdays}
                        setValue={setMonthdays}
                        words={words}
                        isRTL={isRTL}
                        name="monthdays"
                        fullWidth
                        mb={0}
                      ></SelectMulti>
                    </Grid>
                  )}
                  <Grid item xs={3} style={{ marginTop: 10 }}>
                    {!many && (
                      <TextFieldLocal
                        required
                        name="count"
                        label={words.qty}
                        value={count}
                        onChange={onChangeCount}
                        type="number"
                        mb={0}
                      />
                    )}
                    {many && (
                      <TextFieldLocal
                        required
                        name="count"
                        label={words.qty}
                        value={evList?.length}
                        onChange={() => null}
                        type="number"
                        disabled={many}
                        mb={0}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: 10 }}>
                    <TextFieldLocal
                      required
                      name="interval"
                      label={words.interval}
                      value={interval}
                      onChange={onChangeInterval}
                      type="number"
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Divider
                      style={{ marginBottom: 10, marginTop: 20 }}
                    ></Divider>
                  </Grid>
                  {eventoptions?.title &&
                    tempoptions?.contract &&
                    !contEditor && (
                      <Grid item xs={12}>
                        <TextFieldLocal
                          name="tasktitle"
                          label={words.title}
                          value={tasktitle}
                          onChange={(e: any) => setTasktitle(e.target.value)}
                          fullWidth
                          mb={0}
                        />
                      </Grid>
                    )}
                  <Grid item xs={12} md={3}>
                    <Box style={{ display: 'flex' }}>
                      <TextField
                        name="minutes"
                        onChange={(e: any) => {
                          const value = Number(e.target?.value);
                          if (value < 0) return;
                          setTMinutes(value);
                          const end = startDate
                            ? new Date(startDate).getTime() +
                              (value * 60 * 1000 || 0) +
                              (thours * 60 * 60 * 1000 || 0)
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                          }
                          setDuration((value || 0) + (thours * 60 || 0));
                        }}
                        value={tminutes}
                        label={`${words.duration} ${
                          isRTL ? 'دقيقة' : 'Minutes'
                        }`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                      />
                      <TextField
                        name="hours"
                        onChange={(e: any) => {
                          const value = Number(e.target?.value);
                          if (value < 0) return;
                          setTHours(value);
                          const end = startDate
                            ? new Date(startDate).getTime() +
                              (value * 60 * 60 * 1000 || 0) +
                              (tminutes * 60 * 1000 || 0)
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                          }
                          setDuration((value * 60 || 0) + (tminutes || 0));
                        }}
                        value={thours}
                        label={`${words.duration} ${isRTL ? 'ساعة' : 'Hours'}`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="dense"
                      />
                      {/* <SelectLocal
                        options={eventLengthOptions}
                        value={eventLength}
                        height={36}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          setEventLength(value);
                          const end = startDate
                            ? new Date(startDate).getTime() + value * 60 * 1000
                            : null;
                          if (end) {
                            setEndDate(new Date(end));
                          }
                        }}
                        isRTL={isRTL}
                      ></SelectLocal> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <AutoFieldLocal
                      name="customer"
                      title={tempwords?.customer}
                      words={words}
                      options={customers}
                      value={custvalue}
                      setSelectValue={setCustvalue}
                      register={register}
                      setNewtext={setNewtext}
                      isRTL={isRTL}
                      openAdd={openCustomer}
                      showphone
                      fullWidth
                      mb={0}
                    ></AutoFieldLocal>
                  </Grid>

                  {tempoptions?.docrelations && (
                    <>
                      {tempoptions?.contract && (
                        <Grid item xs={12} md={6}>
                          <AutoFieldLocal
                            name="task"
                            title={tempwords?.task}
                            words={words}
                            options={task ? [task] : tasks ? tasks : []}
                            disabled={task ? true : false}
                            value={taskvalue}
                            setSelectValue={setTaskvalue}
                            register={register}
                            isRTL={isRTL}
                            fullWidth
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}
                      {tempoptions?.employee && (
                        <Grid item xs={12} md={6}>
                          <AutoFieldLocal
                            name="employee"
                            title={tempwords?.employee}
                            words={words}
                            options={employees.filter(
                              (emp: any) => !emp.disabled
                            )}
                            value={emplvalue}
                            setSelectValue={setEmplvalue}
                            setSelectError={setEmplError}
                            selectError={emplError}
                            refernce={emplRef}
                            register={register}
                            openAdd={openEmployee}
                            isRTL={isRTL}
                            fullWidth
                            datelist={datelist}
                            day={day}
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}
                      {tempoptions?.department && (
                        <Grid item xs={12} md={6}>
                          <AutoFieldLocal
                            name="department"
                            title={tempwords?.department}
                            words={words}
                            options={departments.filter(
                              (d: any) => d.depType === 1
                            )}
                            value={departvalue}
                            setSelectValue={setDepartvalue}
                            setSelectError={setDepartError}
                            selectError={departError}
                            refernce={departRef}
                            register={register}
                            isRTL={isRTL}
                            fullWidth
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}

                      {tempoptions?.resourse && (
                        <Grid item xs={12} md={6}>
                          <AutoFieldLocal
                            name="resourse"
                            title={tempwords?.resourse}
                            words={words}
                            options={resourses}
                            value={resovalue}
                            setSelectValue={setResovalue}
                            setSelectError={setResoError}
                            selectError={resoError}
                            refernce={resoRef}
                            register={register}
                            openAdd={openResourse}
                            isRTL={isRTL}
                            fullWidth
                            day={day}
                            mb={0}
                          ></AutoFieldLocal>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {rrule?.all && (
                      <Paper
                        style={{
                          height: 152,
                          overflow: 'auto',
                        }}
                        elevation={3}
                      >
                        <Box style={{ flexDirection: 'row' }}>
                          {rrule?.all?.map((al: any, index: any) => {
                            const isfrom = weekdays?.[0] || monthdays?.[0];
                            if (!isfrom) {
                              if (!isatStart && index === 0) {
                                return null;
                              }
                              if (
                                isatStart &&
                                index === rrule?.all?.length - 1
                              ) {
                                return null;
                              }
                            }
                            const al2 = new Date(al);
                            if (isLastday && al2.getDate() === 1) {
                              al2.setDate(al2.getDate() - 1);
                            }

                            return (
                              <Box
                                display="flex"
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  backgroundColor: '#f5f5f5',
                                  margin: 5,
                                  padding: 5,
                                }}
                              >
                                <Typography>
                                  {getDateDayWeek(isLastday ? al2 : al, isRTL)}
                                </Typography>
                                <Typography variant="caption">
                                  {index}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Paper>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Divider
                      style={{ marginTop: 7, marginBottom: 10 }}
                    ></Divider>
                  </Grid>
                  {!contEditor && eventoptions?.status && (
                    <Grid item xs={12}>
                      <AutoFieldLocal
                        name="status"
                        title={words.status}
                        words={words}
                        options={eventStatus}
                        value={status}
                        setSelectValue={setStatus}
                        noPlus
                        isRTL={isRTL}
                        fullWidth
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.retype && eventoptions?.retype && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="retype"
                        title={tempwords?.evretype}
                        words={words}
                        options={retypes.filter((dep: any) => dep.reType === 4)}
                        value={rtypevalue}
                        setSelectValue={setRtypevalue}
                        register={register}
                        isRTL={isRTL}
                        fullWidth
                        mb={0}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.retype2 && eventoptions?.retype2 && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="retype2"
                        title={tempwords?.evretype2}
                        words={words}
                        options={retypes.filter((dep: any) => dep.reType === 5)}
                        value={rtype2value}
                        setSelectValue={setRtype2value}
                        register={register}
                        isRTL={isRTL}
                        fullWidth
                        mb={0}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Box
              style={{
                backgroundColor: '#f4f4f4',
                padding: 10,
                marginTop: 15,
                marginBottom: 5,
                borderRadius: 10,
              }}
            >
              <Box display="flex">
                <ServiceItemForm
                  services={servicesproducts}
                  products={products}
                  addItem={addItemToList}
                  words={words}
                  classes={classes}
                  user={user}
                  isRTL={isRTL}
                  setAlrt={setAlrt}
                  taskv={task}
                  itemrelations={tempoptions?.itemrelations}
                  datelist={datelist}
                  day={day}
                ></ServiceItemForm>
              </Box>
              <Box>
                <ItemsTable
                  products={[...servicesproducts, ...products]}
                  rows={itemsList}
                  editItem={editItemInList}
                  removeItem={removeItemFromList}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  itemrelations={tempoptions?.itemrelations}
                ></ItemsTable>
              </Box>
              {itemsList?.length > 0 && (
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {words.total} : {moneyFormat(totals.amount)}
                </Typography>
              )}
            </Box>
            <Grid container spacing={2}>
              {eventoptions?.location && (
                <>
                  <Grid item xs={12} md={4}>
                    <TextFieldLocal
                      name="address"
                      label={isRTL ? 'العنوان ' : 'Address'}
                      value={address}
                      onChange={(e: any) => setAddress(e.target.value)}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextFieldLocal
                      name="locationLink"
                      label={isRTL ? 'الموقع ' : 'Location'}
                      value={locationLink}
                      onChange={(e: any) => setLocationLink(e.target.value)}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextFieldLocal
                      name="houseno"
                      label={isRTL ? 'رقم البيت' : 'House No'}
                      value={houseno}
                      onChange={(e: any) => setHouseno(e.target.value)}
                      fullWidth
                      mb={0}
                    />
                  </Grid>
                  {locationLink && (
                    <Grid item xs={12} md={2}>
                      {locationLink && (
                        <LinkLocation
                          isRTL={isRTL}
                          value={locationLink}
                        ></LinkLocation>
                      )}
                    </Grid>
                  )}
                </>
              )}
              {eventoptions?.bonos && (
                <Grid item xs={6} md={3}>
                  <SelectSimpleNumber
                    titel={isRTL ? 'مكافئة' : 'Bonos'}
                    value={bonos}
                    setValue={setBonos}
                    items={[0, 5, 10, 15, 20, 25, 30]}
                  ></SelectSimpleNumber>
                </Grid>
              )}
              {eventoptions?.overtime && (
                <Grid item xs={6} md={3}>
                  <SelectSimpleNumber
                    titel={isRTL ? 'اضافي' : 'Overtime'}
                    value={overtime}
                    setValue={setOvertime}
                    items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  ></SelectSimpleNumber>
                </Grid>
              )}

              {eventoptions?.absent && (
                <>
                  <Grid item xs={6} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={absent}
                          onChange={() => {
                            setAbsent(!absent);
                          }}
                          name="absent"
                          color="primary"
                        />
                      }
                      style={{ marginTop: 10 }}
                      label={
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: 13 }}
                        >
                          {isRTL ? 'غائب' : 'Absent'}
                        </Typography>
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
      </>
    </PopupLayout>
  );
};

export default PopupAppointmentMulti;
