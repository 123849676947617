/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import DateNavigatorReports from '../components/filters/DateNavigatorReports';
import _ from 'lodash';
import getReportServices from '../graphql/query/getReportServices';
import SimpleTable from './SimpleTable';
import { InfoBox } from '../components';
import { moneyFormat, qtyFormat } from './colorFormat';
import { userroles } from '../common';

const getStatisticsFromData = ({ data, events, isRTL }) => {
  const efname = isRTL ? 'employeeNameAr' : 'employeeName';
  const dfname = isRTL ? 'departmentNameAr' : 'departmentName';
  const cfname = isRTL ? 'categoryNameAr' : 'categoryName';
  const rfname = isRTL ? 'retypeNameAr' : 'retypeName';
  const ifname = isRTL ? 'itemNameAr' : 'itemName';
  const sfname = isRTL ? 'statusAr' : 'statusEn';
  const contfname = isRTL ? 'contractNameAr' : 'contractName';
  const absentdata = events?.filter((d: any) => d.absent);

  const departments = _(data)
    .groupBy(dfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const categories = _(data)
    .groupBy(cfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const retypes = _(data)
    .groupBy(rfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const items = _(data)
    .groupBy(ifname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const status = _(data)
    .groupBy(sfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const employees = _(data)
    .groupBy(efname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();
  const absent = _(absentdata)
    .groupBy(efname)
    .map((array, key) => ({
      name: key,
      qty: array?.length,
    }))
    .orderBy('name')
    .value();

  const contracts = _(data)
    .groupBy(contfname)
    .map((array, key) => ({
      name: key,
      total: _.sumBy(array, 'total'),
      qty: _.sumBy(array, 'qty'),
    }))
    .orderBy('name')
    .value();

  return {
    departments,
    employees,
    categories,
    retypes,
    items,
    status,
    absent,
    contracts,
  };
};

function WorkStatus({
  isRTL,
  words,
  theme,
  tempwords,
  eventsMonth,
  height,
  tempoptions,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [isdone, setIsdone] = useState<any>(false);

  const [events, setEvents] = useState({ count: 0, done: 0 });
  const [total, setTotal]: any = useState(null);
  const [count, setCount] = useState<any>(0);
  const [bonos, setBonos] = useState<any>(0);
  const [overtime, setOvertime] = useState<any>(0);

  const [row, setRow] = useState(null);

  const [currentViewName, setCurrentViewName] = useState('Day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };
  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const [getSummary, summaryData]: any = useLazyQuery(getReportServices);

  useEffect(() => {
    const slsData = summaryData?.data?.['getReportServices']?.data || [];
    const ops = summaryData?.data?.['getReportServices']?.message || null;
    let rdata = slsData;
    let events = [];
    if (ops) {
      events = JSON.parse(ops);
      if (events && events.length > 0) {
        rdata = slsData.map((item: any) => {
          const itemev = events.filter(
            (evt: any) => evt._id === item.opId
          )?.[0];
          if (itemev) {
            const statusAr = itemev.status === 10 ? 'منجز' : 'غير منجز';
            const statusEn = itemev.status === 10 ? 'Done' : 'Not yet';
            return {
              ...item,
              status: itemev.status,
              statusAr,
              statusEn,
              retypeId: itemev.retypeId,
              retypeName: itemev.retypeName,
              retypeNameAr: itemev.retypeNameAr,
              retype2Id: itemev.retype2Id,
              retype2Name: itemev.retype2Name,
              retype2NameAr: itemev.retype2NameAr,
            };
          } else {
            return { ...item, statusAr: 'غير منجز', statusEn: 'Not yet' };
          }
        });
      }
    }
    rdata = isdone ? rdata?.filter((d: any) => d?.status === 10) : rdata;
    const statics = getStatisticsFromData({ data: rdata, events, isRTL });
    setRow(statics);
    const doneevents = events.filter((ev: any) => ev.status === 10);
    setEvents({ count: events?.length, done: doneevents?.length });
    setTotal(_.sumBy(rdata, 'total'));
    setCount(_.sumBy(rdata, 'qty'));
    setBonos(_.sumBy(events, 'bonos'));
    setOvertime(_.sumBy(events, 'overtime'));
  }, [summaryData, isdone]);

  const fetchData = () => {
    const variables = {
      types: [80],
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [currentViewName, start, end, eventsMonth]);
  return (
    <Paper
      elevation={2}
      style={{
        height: height ? height : 700,
        // width: '100%',
      }}
    >
      <Box
        display={'flex'}
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
          height: 50,
          paddingTop: 10,
        }}
      >
        <DateNavigatorReports
          setStart={setStart}
          setEnd={setEnd}
          currentDate={currentDate}
          currentDateChange={currentDateChange}
          currentViewName={currentViewName}
          currentViewNameChange={currentViewNameChange}
          endDate={endDate}
          endDateChange={endDateChange}
          views={[1, 7, 30]}
          isRTL={isRTL}
          words={words}
          theme={theme}
        ></DateNavigatorReports>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isdone}
                onChange={() => setIsdone(!isdone)}
                name="bold"
                color="primary"
              />
            }
            label={isRTL ? 'المنجز' : 'is Done'}
          />
        </Box>
        <Box style={{ marginLeft: 15, marginRight: 15 }}>
          <Typography
            color="primary"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#555' }}
          >
            {isRTL ? 'انجاز العمل' : 'Work Progress'}
          </Typography>
        </Box>
      </Box>
      <Paper
        elevation={0}
        style={{
          overflow: 'auto',
          height: height - 90,
          marginLeft: 15,
          marginRight: 15,
          marginTop: 20,
          backgroundColor: '#f5f5f5',
        }}
      >
        <Grid container spacing={0}>
          <Grid xs={12} style={{ padding: 10 }}>
            <Grid container spacing={2}>
              <Grid item xs>
                <InfoBox
                  title={
                    isRTL
                      ? ` عدد ${tempwords?.appointments}`
                      : ` Total ${tempwords?.appointments}`
                  }
                  value={qtyFormat(events?.count)}
                  icon="calendar"
                  height={120}
                ></InfoBox>
              </Grid>
              <Grid item xs>
                <InfoBox
                  title={
                    isRTL
                      ? `${tempwords?.appointments} منجزة`
                      : `${tempwords?.appointments} Done`
                  }
                  value={qtyFormat(events.done)}
                  icon="event"
                  height={120}
                ></InfoBox>
              </Grid>
              {userroles?.appointments?.admin && (
                <Grid item xs>
                  <InfoBox
                    title={isRTL ? 'المجموع' : 'Total Amount'}
                    value={moneyFormat(total)}
                    icon="cash"
                    height={120}
                  ></InfoBox>
                </Grid>
              )}
              <Grid item xs>
                <InfoBox
                  title={isRTL ? 'الكمية' : 'Quantity'}
                  value={qtyFormat(count)}
                  icon="list"
                  height={120}
                ></InfoBox>
              </Grid>
              <Grid item xs>
                <InfoBox
                  title={isRTL ? 'المكافأت' : 'Bonos'}
                  value={moneyFormat(bonos)}
                  icon="card"
                  height={120}
                ></InfoBox>
              </Grid>
              <Grid item xs>
                <InfoBox
                  title={isRTL ? 'ساعات اضافي' : 'Overtime'}
                  value={qtyFormat(overtime)}
                  icon="add"
                  height={120}
                ></InfoBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Divider style={{ margin: 10 }}></Divider>
          </Grid>
          <Grid item xs={4}>
            {tempoptions?.department && (
              <>
                <Typography
                  variant="h6"
                  style={{ paddingLeft: 15, paddingRight: 15 }}
                >
                  {isRTL
                    ? `بحسب ${tempwords?.department}`
                    : `By ${tempwords?.department}`}
                </Typography>
                <SimpleTable
                  data={row?.departments}
                  isRTL={isRTL}
                ></SimpleTable>
              </>
            )}
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}
            >
              {isRTL
                ? `بحسب ${tempwords?.evretype}`
                : `By ${tempwords?.evretype}`}
            </Typography>
            <SimpleTable data={row?.retypes} isRTL={isRTL}></SimpleTable>
            {tempoptions?.contract && (
              <>
                <Typography
                  variant="h6"
                  style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}
                >
                  {isRTL ? `بحسب ${tempwords?.task}` : `By ${tempwords?.task}`}
                </Typography>
                <SimpleTable data={row?.contracts} isRTL={isRTL}></SimpleTable>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              {isRTL ? `بحسب ${words.status}` : `By ${words.status}`}
            </Typography>
            <SimpleTable data={row?.status} isRTL={isRTL}></SimpleTable>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}
            >
              {isRTL ? `بحسب ${words.employee}` : `By ${words.employee}`}
            </Typography>
            <SimpleTable data={row?.employees} isRTL={isRTL}></SimpleTable>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}
            >
              {isRTL ? `بحسب الغياب` : `By Absent`}
            </Typography>
            <SimpleTable data={row?.absent} isRTL={isRTL}></SimpleTable>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15 }}
            >
              {isRTL ? `بحسب ${words.category}` : `By ${words.category}`}
            </Typography>
            <SimpleTable data={row?.categories} isRTL={isRTL}></SimpleTable>
            <Typography
              variant="h6"
              style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}
            >
              {isRTL ? `بحسب ${words.item}` : `By ${words.item}`}
            </Typography>
            <SimpleTable data={row?.items} isRTL={isRTL}></SimpleTable>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
}

export default WorkStatus;
