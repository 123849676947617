/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import {
  calculateAmount,
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatter,
  moneyFormat,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  fade,
  FormControlLabel,
  Typography,
  withStyles,
  Grid as MGrid,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FinanceReportPrint } from '../../print/FinanceReportPrint';
import {
  useCustomers,
  useExpenseItems,
  useProducts,
  useServices,
  useSuppliers,
  useTemplate,
} from '../../hooks';
import { SearchTable } from '../../components';
import { KaidContext } from '../../contexts/managment';
import getKaidsReport from '../../graphql/query/getKaidsReport';
import KaidReportFilter from '../../Shared/KaidReportFilter';
import useDepartments from '../../hooks/useDepartments';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useProjects from '../../hooks/useProjects';
import useTasks from '../../hooks/useTasks';
import useRetypes from '../../hooks/useRetypes';
import _ from 'lodash';

const styles = (theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function KaidsReport({
  isRTL,
  words,
  start,
  end,
  mainaccounts,
  accounts,
  company,
  theme,
  drawerWidth,
}: any) {
  const [rows, setRows] = useState([]);
  const [isRaseed, setIsRaseed] = useState(true);
  const [isGroup, setIsGroup] = useState(false);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const [columns] = useState(
    [
      col.opTime,
      col.opType,
      col.opDocNo,
      col.refNo,
      col.acc,
      col.opAcc,
      col.item,
      { name: 'qty', title: isRTL ? 'الكمية' : 'Quantity' },
      tempoptions?.employee && col.employee,
      tempoptions?.resourse && col.resourse,
      tempoptions?.department && col.department,
      tempoptions?.contract && col.contract,
      col.customer,
      col.supplier,
      col.amount,
      col.amountdebit,
      col.amountcredit,
      col.rased,
      col.desc,
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getKaidsReport, {
    fetchPolicy: 'cache-and-network',
  });

  const { customers } = useCustomers();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { projects } = useProjects();
  const { tasks } = useTasks();
  const { services } = useServices();
  const { products } = useProducts();
  const { expenseItems } = useExpenseItems();
  const { suppliers } = useSuppliers();
  const { retypes } = useRetypes();

  const {
    state: {
      optypevalue,
      itemtypevalue,
      itemvalue,
      projvalue,
      departvalue,
      emplvalue,
      resovalue,
      custvalue,
      suppvalue,
      taskvalue,
      accvalue,
      pcodevalue,
      sort,
    },
    dispatch,
  } = useContext(KaidContext);
  const { height } = useWindowDimensions();

  const setDepartvalueDispatch = (value: any) => {
    dispatch({ type: 'setDepartvalue', payload: value });
  };
  const setProjvalueDispatch = (value: any) => {
    dispatch({ type: 'setProjvalue', payload: value });
  };
  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setResovalueDispatch = (value: any) => {
    dispatch({ type: 'setResovalue', payload: value });
  };
  const setCustvalueDispatch = (value: any) => {
    dispatch({ type: 'setCustvalue', payload: value });
  };
  const setSuppvalueDispatch = (value: any) => {
    dispatch({ type: 'setSuppvalue', payload: value });
  };
  const setTaskvalueDispatch = (value: any) => {
    dispatch({ type: 'setTaskvalue', payload: value });
  };
  const setOptypevalueDispatch = (value: any) => {
    dispatch({ type: 'setOptypevalue', payload: value });
  };
  const setItemtypevalueDispatch = (value: any) => {
    dispatch({ type: 'setItemtypevalue', payload: value });
  };
  const setItemvalueDispatch = (value: any) => {
    dispatch({ type: 'setItemvalue', payload: value });
  };
  const setAccountvalueDispatch = (value: any) => {
    dispatch({ type: 'setAccvalue', payload: value });
  };
  const setPcodevalueDispatch = (value: any) => {
    dispatch({ type: 'setPcodevalue', payload: value });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getKaidsReport']?.data || [];
    const balance = summaryData?.data?.['getKaidsReport']?.message || null;
    const updatedRows = slsData.map((x: any) => x);
    const amount = isRaseed && balance ? JSON.parse(balance) : null;

    if (amount !== null) {
      const { credit, debit } = amount;

      if (credit || debit) {
        const am = debit - credit;
        updatedRows.unshift({
          _id: Date.now(),
          opTime: start,
          opType: 94,
          debit,
          credit,
          amount: am,
          qty: 0,
        });
      }
    }

    let rased = 0;
    const rdata =
      updatedRows?.length > 0
        ? updatedRows.map((item: any) => {
            const rowRased = item.debit ? item.debit : -item.credit;
            rased = rased + rowRased;
            return {
              ...item,
              amount: calculateAmount(item),
              rased,
            };
          })
        : [];

    // const rdata = updateOpDocRefNumbers(updatedRows2);
    if (isGroup) {
      const grdata = _(rdata)
        .groupBy('opDocNo')
        .map((array) => ({
          ...array[0],
          amount: _.sumBy(array, 'amount'),
          credit: _.sumBy(array, 'credit'),
          debit: _.sumBy(array, 'debit'),
        }))
        .value();
      setRows(grdata);
    } else {
      setRows(rdata);
    }
  }, [summaryData, isRaseed, isGroup]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;

  const getValues = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv.value) : undefined;

  const fetchData = () => {
    const variables = {
      opTypes: getValues(optypevalue),
      itemTypes: getValues(itemtypevalue),
      itemIds: getIds(itemvalue),
      accountIds: getIds(accvalue),
      parentCodes: getValues(pcodevalue),
      projectIds: getIds(projvalue),
      contractIds: getIds(taskvalue),
      departmentIds: getIds(departvalue),
      employeeIds: getIds(emplvalue),
      resourseIds: getIds(resovalue),
      customerIds: getIds(custvalue),
      supplierIds: getIds(suppvalue),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };

    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    optypevalue,
    itemtypevalue,
    itemvalue,
    projvalue,
    taskvalue,
    departvalue,
    emplvalue,
    resovalue,
    custvalue,
    suppvalue,
    accvalue,
    pcodevalue,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `finance-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'credit', type: 'sum' },
    { columnName: 'debit', type: 'sum' },
    { columnName: 'qty', type: 'sum' },
  ];
  const componentRef: any = useRef();

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 8,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <Box style={{ marginLeft: 10, marginRight: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: 7 }}
                checked={isRaseed}
                onChange={() => setIsRaseed(!isRaseed)}
                color="primary"
              />
            }
            label={
              <Typography
                style={{ color: theme.palette.primary.main }}
                variant="subtitle2"
              >
                {isRTL ? 'رصيد افتتاحي' : 'Opening Balance'}
              </Typography>
            }
            style={{ fontSize: 14 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: 7 }}
                checked={isGroup}
                onChange={() => setIsGroup(!isGroup)}
                color="primary"
              />
            }
            label={
              <Typography
                style={{ color: theme.palette.primary.main }}
                variant="subtitle2"
              >
                {isRTL ? 'تجميع بحسب المستند' : 'Group by document'}
              </Typography>
            }
            style={{ fontSize: 14 }}
          />
        </Box>
      </Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 225,
          right: isRTL ? drawerWidth + 225 : undefined,
          bottom: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: '#403795' }}>
          {isRTL ? 'الرصيد' : 'Balance'}:{' '}
          {moneyFormat(rows?.[rows?.length - 1]?.rased)}
        </Typography>
      </Box>
      <MGrid container spacing={0}>
        <MGrid item xs={10}>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState
              defaultSorting={sort}
              onSortingChange={(srt: any) => setSortDispatch(srt)}
            />
            <SummaryState totalItems={totalSummaryItems} />
            <IntegratedSummary />
            <IntegratedSorting />
            <SearchState />
            <IntegratedFiltering />
            <VirtualTable
              height={height - 180}
              tableComponent={TableComponent}
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              estimatedRowHeight={30}
            />
            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnVisibility
              columnExtensions={tableColumnVisibilityColumnExtensions}
              defaultHiddenColumnNames={[
                col.amount.name,
                col.rased.name,
                col.item.name,
                'qty',
                col.contract.name,
                col.customer.name,
                col.supplier.name,
                col.employee.name,
                col.resourse.name,
                col.department.name,
                col.refNo.name,
              ]}
            />

            <DataTypeProvider
              for={['opTime']}
              formatterComponent={createdAtFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['credit', 'debit', 'rased']}
              formatterComponent={currencyFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['opType']}
              formatterComponent={opTypeFormatter}
            ></DataTypeProvider>
            <Toolbar />
            <ColumnChooser />
            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />
            <ExportPanel startExport={startExport} />
            <TableSummaryRow
              messages={{
                sum: isRTL ? 'المجموع' : 'Total',
                count: isRTL ? 'العدد' : 'Count',
              }}
            ></TableSummaryRow>
          </Grid>
        </MGrid>
        <MGrid item xs={2}>
          <KaidReportFilter
            accounts={accounts}
            mainaccounts={mainaccounts}
            services={services}
            products={products}
            expenseItems={expenseItems}
            departments={departments}
            setDepartvalue={setDepartvalueDispatch}
            employees={employees}
            setEmplvalue={setEmplvalueDispatch}
            resourses={resourses}
            setResovalue={setResovalueDispatch}
            projects={projects}
            setProjvalue={setProjvalueDispatch}
            customers={customers}
            setCustvalue={setCustvalueDispatch}
            suppliers={suppliers}
            setSuppvalue={setSuppvalueDispatch}
            tasks={tasks}
            setTaskvalue={setTaskvalueDispatch}
            setOptypevalue={setOptypevalueDispatch}
            setItemtypevalue={setItemtypevalueDispatch}
            setItemvalue={setItemvalueDispatch}
            setAccountvalue={setAccountvalueDispatch}
            setPcodevalue={setPcodevalueDispatch}
            pcodevalue={pcodevalue}
            accvalue={accvalue}
            optypevalue={optypevalue}
            itemtypevalue={itemtypevalue}
            itemvalue={itemvalue}
            emplvalue={emplvalue}
            resovalue={resovalue}
            departvalue={departvalue}
            projvalue={projvalue}
            taskvalue={taskvalue}
            custvalue={custvalue}
            suppvalue={suppvalue}
            words={words}
            isRTL={isRTL}
            retypes={retypes}
          ></KaidReportFilter>
        </MGrid>
      </MGrid>

      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
      <Box>
        <div style={{ display: 'none' }}>
          <FinanceReportPrint
            company={company}
            items={rows}
            columns={columns}
            ref={componentRef}
            isRTL={isRTL}
            account={accvalue?.[0]}
            start={start}
            end={end}
          />
        </div>
      </Box>
    </>
  );
}
