/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  yup,
  ColorPicker,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { TextFieldLocal } from '../components';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { itemTypes } from '../constants/datatypes';
import { useTemplate } from '../hooks';
import { AvatarUpload, uploadPhotoOnline } from '../common/AvatarUpload';
import useSections from '../hooks/useSections';

const PopupCategory = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  editAction,
  theme,
  catType,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [catTypevalue, setCatTypevalue] = useState<any>(null);
  const [section, setSection] = useState<any>(null);

  const [ispos, setIspos] = useState(true);
  const [order, setOrder] = useState(null);
  const [logoimage, setLogoimage] = useState(null);
  const [logourl, setLogourl] = useState(null);
  const [color, setColor] = useState<any>('#252B3B');

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { register, handleSubmit, errors, reset } = useForm(yup.catResolver);
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const { tempoptions } = useTemplate();
  const { sections } = useSections();
  useEffect(() => {
    if (row && row._id) {
      if (row?.catType) {
        const dep = itemTypes.filter((de: any) => de.id === row?.catType);
        if (dep && dep.length > 0) {
          setCatTypevalue(dep[0]);
        }
      }
      if (row?.sectionId) {
        const sec = sections.filter(
          (de: any) => de._id === row?.sectionId
        )?.[0];
        if (sec) {
          setSection(sec);
        }
      }
      setIspos(row?.ispos);
      setOrder(row?.order);
      setColor(row?.color);
      if (row?.avatar) {
        setLogourl(row?.avatar);
      }
    }
  }, [row]);

  useEffect(() => {
    if (catType) {
      setCatTypevalue(catType);
    }
  }, [catType]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data.name.trim();
    const nameAr = !isNew ? data.nameAr.trim() : name;
    const additionId = data.additionId;

    let logo: any;

    if (logoimage) {
      logo = await uploadPhotoOnline(logoimage);
      logo = logo.replace('http://', 'https://');
    }

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      additionId: data?.additionId?.length > 0 ? additionId : undefined,
      name,
      nameAr,
      avatar: logo,
      color,
      ispos,
      order,
      catType: catTypevalue ? catTypevalue?.id : undefined,
      sectionId: section ? section._id : null,
      sectionName: section ? section.name : null,
      sectionNameAr: section ? section.nameAr : null,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createCategory' : 'updateCategory';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem, 'category');
      setSaving(false);
      await successAlert(setAlrt, isRTL);
      closeModal();
    } catch (error) {
      setSaving(false);
      onError(error);
    }
  };

  const closeModal = () => {
    onClose();
    setSaving(false);
    setIspos(true);
    setOrder(null);
    setLogoimage(null);
    setLogourl(null);
    setColor('#252B3B');
    setSection(null);
    reset();
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const title = isRTL
    ? isNew
      ? 'اضافة تصنيف'
      : 'تعديل تصنيف'
    : isNew
    ? 'New Category'
    : 'Edit Category';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            {tempoptions?.additionId && (
              <Grid item xs={12}>
                <TextFieldLocal
                  autoFocus={tempoptions?.additionId}
                  name="additionId"
                  label={words.no}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 125,
                  height: 125,
                  marginTop: 5,
                  padding: 5,
                }}
              >
                <AvatarUpload
                  url={logourl}
                  setUrl={setLogourl}
                  image={logoimage}
                  setImage={setLogoimage}
                  width={125}
                  height={125}
                  size="Avatar"
                ></AvatarUpload>
              </Box>
              <TextFieldLocal
                autoFocus={!tempoptions?.additionId}
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            {!isNew && (
              <Grid item xs={12}>
                <TextFieldLocal
                  required
                  name="nameAr"
                  label={words.nameAr}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <AutoFieldLocal
                name="catType"
                title={words?.type}
                words={words}
                options={itemTypes}
                value={catTypevalue}
                setSelectValue={setCatTypevalue}
                register={register}
                isRTL={isRTL}
                fullWidth
                disabled={catType}
                mb={0}
              ></AutoFieldLocal>
            </Grid>
            {tempoptions?.section && (
              <Grid item xs={12}>
                <AutoFieldLocal
                  name="section"
                  title={isRTL ? 'التصنيف الرئيسي' : 'Section'}
                  words={words}
                  options={sections}
                  value={section}
                  setSelectValue={setSection}
                  isRTL={isRTL}
                  fullWidth
                  mb={0}
                ></AutoFieldLocal>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldLocal
                name="order"
                value={order}
                onChange={(e: any) => setOrder(Number(e.target.value))}
                label={isRTL ? 'الترتيب' : 'Order'}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            {tempoptions?.pos && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ispos}
                      onChange={() => {
                        setIspos(!ispos);
                      }}
                      name="ispos"
                      color="primary"
                    />
                  }
                  label={
                    <Typography>
                      {isRTL ? 'تفعيل في نقطة البيع' : 'Activate in POS'}
                    </Typography>
                  }
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                disabled
                name="color"
                value={row?.color ? row.color : color}
                variant="outlined"
                style={{
                  width: 200,
                  backgroundColor: color,
                  marginTop: 10,
                }}
                InputProps={{ style: { borderRadius: 5, color: '#fff' } }}
                margin="dense"
              />
              <ColorPicker setColor={setColor} color={color}></ColorPicker>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupCategory;
