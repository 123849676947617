import { Box, Button, colors, Grid, Typography } from '@material-ui/core';
import { nameToColor } from '../generator/common/helpers';
import Avatar from '../components/Avatar';

export const operationNameFormatter = ({ row, preset, navigate }: any) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Button
          onClick={() => {
            if (navigate) {
              navigate(row._id);
              // navigate(`/${preset}/${row?._id}`);
            }
          }}
          variant="text"
          color="primary"
          fullWidth
          style={{
            // height: 30,
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            color="primary"
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {row?.title}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const covertToDate = (time: any) => {
  if (time) {
    const date = new Date(time);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } else {
    return '';
  }
};

export const timeFormatter = ({ row }: any) => {
  const { time } = row;
  return <div>{covertToDate(time)}</div>;
};

export const userFormatter = ({ value, users }: any) => {
  const user = users?.filter((us: any) => us?._id === value)?.[0];
  return <Typography>{user?.name ? user?.name : user?.username}</Typography>;
};

export const booleanFormatter = ({ value }: any) => {
  return value ? (
    <span
      style={{
        padding: 5,
        fontSize: 18,
        fontWeight: 'bold',
      }}
    >
      ✔
    </span>
  ) : (
    <span></span>
  );
};

export const getDateDayShortFormat = (time: any, isRTL: any) => {
  if (time) {
    const date = new Date(time);
    return date.toLocaleString(isRTL ? 'ar-QA' : 'en-GB', {
      weekday: 'long',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    return '';
  }
};

export const createdAtFormatter = ({ value }: any) => {
  return <div>{getDateDayShortFormat(value, false)}</div>;
};

export const moneyFormat = (amount: number) => {
  if (amount) {
    if (!isNaN(amount)) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
  }
  return '0.00';
};

export const currencyFormatter = ({ value }: any) => {
  return (
    <span
      style={{
        color: value < 0 ? colors.red[800] : colors.blue[950],
      }}
    >
      {moneyFormat(value)}
    </span>
  );
};

export const groupFormatter = (props: any, groups: any, isRTL: any) => {
  const gs = groups.filter((grp: any) => props.value?.includes(grp._id));
  if (gs && gs.length > 0) {
    return gs.map((g: any) => (
      <span style={{ padding: 5 }}>{isRTL ? g.nameAr : g.name}</span>
    ));
  } else {
    return <span></span>;
  }
};

export const avatarFormatter = ({ row }: any) => {
  const { name, username, color } = row;
  const bgcolor = color ? color : nameToColor(`${name}Jadwal2!@`);

  return (
    <Box>
      <Avatar username={username} name={name} bg={bgcolor}></Avatar>
    </Box>
  );
};

const renderTag = ({ value, color, bgcolor, fontSize = 12, width = 75 }) => {
  if (!value) return <></>;
  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width,
        height: 24,
        backgroundColor: bgcolor,
        borderRadius: 5,
        margin: 4,
      }}
    >
      <Typography
        style={{
          fontSize,
          fontWeight: 'bold',
          color,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const contactTypeFormatter = ({ row }: any) => {
  const { customerId, supplierId } = row;
  if (!customerId && !supplierId) return <div></div>;
  return (
    <Box>
      {customerId &&
        renderTag({
          value: 'Customer',
          color: 'green',
          bgcolor: '#ddd',
        })}
      {supplierId &&
        renderTag({
          value: 'Supplier',
          color: 'blue',
          bgcolor: '#ddd',
        })}
    </Box>
  );
};

export const covertToTimeDateDigit = (time: any) => {
  if (time) {
    const date = new Date(time);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    return '';
  }
};
