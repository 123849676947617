/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createEmployee,
  deleteEmployee,
  getAccounts,
  getEmployees,
  updateEmployee,
} from '../graphql';
import { createEmployeeLink } from '../graphql/mutation';
import createMultiEmployees from '../graphql/mutation/createMultiEmployees';

export default () => {
  const [getemparts, empData]: any = useLazyQuery(getEmployees);

  const [addEmployee] = useMutation(createEmployee, {
    refetchQueries: [{ query: getEmployees }, { query: getAccounts }],
  });
  const [addEmployeeLink] = useMutation(createEmployeeLink, {
    refetchQueries: [{ query: getEmployees }, { query: getAccounts }],
  });
  const [addMultiEmployees] = useMutation(createMultiEmployees, {
    refetchQueries: [{ query: getEmployees }],
  });
  const [editEmployee] = useMutation(updateEmployee, {
    refetchQueries: [{ query: getEmployees }, { query: getAccounts }],
  });
  const [removeEmployee] = useMutation(deleteEmployee, {
    refetchQueries: [{ query: getEmployees }, { query: getAccounts }],
  });

  useEffect(() => {
    getemparts();
  }, [getemparts]);
  const employeesfullbr = empData?.data?.['getEmployees']?.data || [];
  const employeesfull =
    employeesfullbr?.length > 0
      ? employeesfullbr?.map((eb: any) => {
          let worktimes = eb?.worktimes ? JSON.parse(eb?.worktimes) : [];
          if (worktimes && worktimes?.length > 0) {
            worktimes = worktimes.map((wt: any) => {
              return {
                ...wt,
                startDate: new Date(wt?.startDate),
                endDate: new Date(wt?.endDate),
              };
            });
          }
          return {
            ...eb,
            worktimes,
          };
        })
      : [];
  const adminemployees = employeesfull.filter((ef: any) => ef.isAdminstaff);
  const workemployees = employeesfull.filter((ef: any) => ef.isWorkstaff);
  const refreshemployee = () => empData?.refetch();

  return {
    employees: adminemployees,
    wemployees: workemployees,
    employeesfull,
    refreshemployee,
    addEmployee,
    addEmployeeLink,
    addMultiEmployees,
    editEmployee,
    removeEmployee,
  };
};
