import { colors, Grid, Paper, Typography } from '@material-ui/core';
import {
  getTaskTimeAmountData,
  moneyFormat,
  rCellMain,
} from '../../common/helpers';
import PercentChartTask from '../charts/PercentChartTask';

export default function TaskDataBx({
  row,
  isRTL,
  theme,
  height = 250,
  bc = '#ddd',
}) {
  const daysData = getTaskTimeAmountData(row);
  const marginTop = height > 100 ? 20 : 8;
  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <PercentChartTask
            pricolor={colors.deepPurple[500]}
            seccolor={colors.deepPurple[200]}
            progress={daysData?.progress}
            height={height - 10}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ marginTop }}>
              <Typography
                variant="body2"
                style={{ fontSize: 16, fontWeight: 'bold' }}
              >
                {isRTL ? 'عدد الايام' : 'Days'}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop }}>
              <Typography
                variant="body2"
                style={{ fontSize: 16, fontWeight: 'bold' }}
              >
                {daysData?.days}
              </Typography>
            </Grid>
            {daysData?.daysnow && (
              <>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {isRTL ? 'أيام مضت' : 'Spent Days'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {daysData?.daysnow}
                  </Typography>
                </Grid>
              </>
            )}
            {daysData?.dayamount && (
              <>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {isRTL ? 'تكلفة اليوم' : 'Day cost'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {moneyFormat(daysData?.dayamount)}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'المستحق' : 'Due', '#333')}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(daysData?.amountnow), '#333')}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ fontSize: 16, fontWeight: 'bold' }}
              >
                {isRTL ? 'المتبقي' : 'Amount'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                style={{ fontSize: 16, fontWeight: 'bold' }}
              >
                {moneyFormat(daysData?.remaining)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
