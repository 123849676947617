import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

const RenderCheckboc = ({ item, name, label, value, setValue }: any) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={() => setValue({ item, name, value: !value })}
          name="bold"
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default function RolesItemView({
  item,
  setItemValue,
  bgcolor,
  isRTL,
}: any) {
  return (
    <>
      <Grid
        style={{
          backgroundColor: bgcolor,
        }}
        item
        xs={2}
      >
        <Typography
          style={{
            padding: 10,
            fontWeight: item?.top ? 'bold' : undefined,
            fontSize: 16,
          }}
        >
          {isRTL ? item?.titleAr : item?.titleEn}
        </Typography>
      </Grid>
      <Grid style={{ backgroundColor: bgcolor }} item xs={2}>
        {'view' in item && (
          <RenderCheckboc
            item={item}
            label={isRTL ? 'ظهور' : 'View'}
            value={item?.view}
            name="view"
            setValue={setItemValue}
          ></RenderCheckboc>
        )}
      </Grid>
      <Grid style={{ backgroundColor: bgcolor }} item xs={2}>
        {'add' in item && (
          <RenderCheckboc
            item={item}
            label={isRTL ? 'اضافة' : 'Add'}
            value={item?.add}
            name="add"
            setValue={setItemValue}
          ></RenderCheckboc>
        )}
      </Grid>
      <Grid style={{ backgroundColor: bgcolor }} item xs={2}>
        {'edit' in item && (
          <RenderCheckboc
            item={item}
            label={isRTL ? 'تعديل' : 'Edit'}
            value={item?.edit}
            name="edit"
            setValue={setItemValue}
          ></RenderCheckboc>
        )}
      </Grid>

      <Grid style={{ backgroundColor: bgcolor }} item xs={2}>
        {'delete' in item && (
          <RenderCheckboc
            item={item}
            label={isRTL ? 'حذف' : 'Delete'}
            value={item?.delete}
            name="delete"
            setValue={setItemValue}
          ></RenderCheckboc>
        )}
      </Grid>
      <Grid style={{ backgroundColor: bgcolor }} item xs={2}>
        {'admin' in item && (
          <RenderCheckboc
            item={item}
            label={isRTL ? 'ادارة' : 'Admin'}
            value={item?.admin}
            name="admin"
            setValue={setItemValue}
          ></RenderCheckboc>
        )}
      </Grid>
    </>
  );
}
