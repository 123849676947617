import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

export const renderCheckbox = (name: any, value: any, setValue: any) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          style={{ padding: 7 }}
          checked={value}
          onChange={() => {
            setValue(name);
          }}
          color="primary"
        />
      }
      label={
        <Typography
          style={{
            fontWeight: 'bold',
            minWidth: 100,
          }}
          color="primary"
        >
          {name}
        </Typography>
      }
      style={{ fontSize: 14 }}
    />
  );
};

const TemplateOptions = ({ temp, setTemp }) => {
  if (!temp) return <div></div>;
  const {
    options,
    calendaroptions,
    taskoptions,
    eventoptions,
    resorseoptions,
    employeeoptions,
    departmentoptions,
    customeroptions,
    supplieroptions,
  } = temp;
  const setOption = (name: any) => {
    const newtemp = {
      ...temp,
      options: { ...temp.options, [name]: !options[name] },
    };
    setTemp(newtemp);
  };
  const setCalendaroptions = (name: any) => {
    const newtemp = {
      ...temp,
      calendaroptions: {
        ...temp.calendaroptions,
        [name]: !calendaroptions[name],
      },
    };
    setTemp(newtemp);
  };
  const setContractoptions = (name: any) => {
    const newtemp = {
      ...temp,
      taskoptions: { ...temp.taskoptions, [name]: !taskoptions[name] },
    };
    setTemp(newtemp);
  };
  const setEventoptions = (name: any) => {
    const newtemp = {
      ...temp,
      eventoptions: { ...temp.eventoptions, [name]: !eventoptions[name] },
    };
    setTemp(newtemp);
  };
  const setResourseoptions = (name: any) => {
    const newtemp = {
      ...temp,
      resorseoptions: { ...temp.resorseoptions, [name]: !resorseoptions[name] },
    };
    setTemp(newtemp);
  };
  const setEmployeeoptions = (name: any) => {
    const newtemp = {
      ...temp,
      employeeoptions: {
        ...temp.employeeoptions,
        [name]: !employeeoptions[name],
      },
    };
    setTemp(newtemp);
  };
  const setDepartmentoptions = (name: any) => {
    const newtemp = {
      ...temp,
      departmentoptions: {
        ...temp.departmentoptions,
        [name]: !departmentoptions[name],
      },
    };
    setTemp(newtemp);
  };
  const setCustomeroptions = (name: any) => {
    const newtemp = {
      ...temp,
      customeroptions: {
        ...temp.customeroptions,
        [name]: !customeroptions[name],
      },
    };
    setTemp(newtemp);
  };
  const setSupplieroptions = (name: any) => {
    const newtemp = {
      ...temp,
      supplieroptions: {
        ...temp.supplieroptions,
        [name]: !supplieroptions[name],
      },
    };
    setTemp(newtemp);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Main Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('mail', options.mail, setOption)}
        {renderCheckbox('pos', options.pos, setOption)}
        {renderCheckbox('letters', options.letters, setOption)}
        {renderCheckbox('presales', options.presales, setOption)}
        {renderCheckbox('calendar', options.calendar, setOption)}
        {renderCheckbox('operations', options.operations, setOption)}
        {renderCheckbox('sales', options.sales, setOption)}
        {renderCheckbox('purchase', options.purchase, setOption)}
        {renderCheckbox('expenses', options.expenses, setOption)}
        {renderCheckbox('finance', options.finance, setOption)}
        {renderCheckbox('reports', options.reports, setOption)}
        {renderCheckbox('reminder', options.reminder, setOption)}

        {renderCheckbox('project', options.project, setOption)}
        {renderCheckbox('contract', options.contract, setOption)}
        {renderCheckbox('resourse', options.resourse, setOption)}
        {renderCheckbox('employee', options.employee, setOption)}
        {renderCheckbox('department', options.department, setOption)}
        {/* {renderCheckbox('itemdepartempl', options.itemdepartempl, setOption)} */}
        {renderCheckbox('docrelations', options.docrelations, setOption)}
        {renderCheckbox('itemrelations', options.itemrelations, setOption)}
        {renderCheckbox('itemdescription', options.itemdescription, setOption)}
        {renderCheckbox(
          'expensesitemrelations',
          options.expensesitemrelations,
          setOption
        )}
        {renderCheckbox(
          'purchaseitemrelations',
          options.purchaseitemrelations,
          setOption
        )}
        {renderCheckbox(
          'proexpensesitemrelations',
          options.proexpensesitemrelations,
          setOption
        )}
        {renderCheckbox('events', options.events, setOption)}
        {renderCheckbox('barcode', options.barcode, setOption)}
        {renderCheckbox('docdescription', options.docdescription, setOption)}
        {renderCheckbox('invoicetip', options.invoicetip, setOption)}
        {renderCheckbox('shortinvoice', options.shortinvoice, setOption)}
        {renderCheckbox('shortemployee', options.shortemployee, setOption)}
        {renderCheckbox('shortcustomer', options.shortcustomer, setOption)}
        {renderCheckbox(
          'resoursesavailable',
          options.resoursesavailable,
          setOption
        )}
        {renderCheckbox('dublicatename', options.dublicatename, setOption)}
        {renderCheckbox('dublicatephone', options.dublicatephone, setOption)}
        {renderCheckbox('nameonelang', options.nameonelang, setOption)}
        {renderCheckbox(
          'taskendlastevent',
          options.taskendlastevent,
          setOption
        )}
        {renderCheckbox('dochirtory', options.dochirtory, setOption)}

        {renderCheckbox('posprod', options.posprod, setOption)}
        {renderCheckbox('posserv', options.posserv, setOption)}
        {renderCheckbox(
          'contractsalescalc',
          options.contractsalescalc,
          setOption
        )}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('retype', options.retype, setOption)}
        {renderCheckbox('retype2', options.retype2, setOption)}
        {renderCheckbox('emplcalendar', options.emplcalendar, setOption)}
        {renderCheckbox('sms', options.sms, setOption)}
        {renderCheckbox('cashinvoice', options.cashinvoice, setOption)}
        {renderCheckbox(
          'invoiceitemrelations',
          options.invoiceitemrelations,
          setOption
        )}
        {renderCheckbox('brand', options.brand, setOption)}
        {renderCheckbox('category', options.category, setOption)}
        {renderCheckbox('section', options.section, setOption)}
        {renderCheckbox('accountant', options.accountant, setOption)}
        {renderCheckbox('notification', options.notification, setOption)}
        {renderCheckbox('additionId', options.additionId, setOption)}
        {renderCheckbox('contracting', options.contracting, setOption)}
        {renderCheckbox('products', options.products, setOption)}
        {renderCheckbox('rentitem', options.rentitem, setOption)}
        {renderCheckbox('collectiveitem', options.collectiveitem, setOption)}
        {renderCheckbox('productsdef', options.productsdef, setOption)}
        {renderCheckbox('tirms', options.tirms, setOption)}
        {renderCheckbox(
          'emailnotification',
          options.emailnotification,
          setOption
        )}
        {renderCheckbox('groupinvoice', options.groupinvoice, setOption)}
        {renderCheckbox('customersmenu', options.customersmenu, setOption)}
        {renderCheckbox('employeesmenu', options.employeesmenu, setOption)}
        {renderCheckbox('resoursesmenu', options.resoursesmenu, setOption)}
        {renderCheckbox('departmentsmenu', options.departmentsmenu, setOption)}
        {renderCheckbox('livedata', options.livedata, setOption)}
        {renderCheckbox('tempevent', options.tempevent, setOption)}
        {renderCheckbox('pageall', options.pageall, setOption)}
        {renderCheckbox('pinvoiceattach', options.pinvoiceattach, setOption)}
        {renderCheckbox('expenseattach', options.expenseattach, setOption)}
        {renderCheckbox('receiptattach', options.receiptattach, setOption)}
        {renderCheckbox('eventattach', options.eventattach, setOption)}
        {renderCheckbox('formdocs', options.formdocs, setOption)}
        {renderCheckbox('procedures', options.procedures, setOption)}
        {renderCheckbox('calendarclock', options.calendarclock, setOption)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Calendar Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox(
          'emplfilter',
          calendaroptions.emplfilter,
          setCalendaroptions
        )}
        {renderCheckbox(
          'departfilter',
          calendaroptions.departfilter,
          setCalendaroptions
        )}
        {renderCheckbox(
          'statusfilter',
          calendaroptions.statusfilter,
          setCalendaroptions
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Contract Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('oneday', taskoptions.oneday, setContractoptions)}
        {renderCheckbox('info', taskoptions.info, setContractoptions)}
        {renderCheckbox('dayCost', taskoptions.dayCost, setContractoptions)}
        {renderCheckbox('note', taskoptions.note, setContractoptions)}
        {renderCheckbox(
          'endrequire',
          taskoptions.endrequire,
          setContractoptions
        )}
        {renderCheckbox(
          'notefyonend',
          taskoptions.notefyonend,
          setContractoptions
        )}
        {renderCheckbox('events', taskoptions.events, setContractoptions)}
        {renderCheckbox('items', taskoptions.items, setContractoptions)}
        {renderCheckbox(
          'itemsrequire',
          taskoptions.itemsrequire,
          setContractoptions
        )}
        {renderCheckbox('tasktype', taskoptions.tasktype, setContractoptions)}
        {renderCheckbox('time', taskoptions.time, setContractoptions)}
        {renderCheckbox('tasktitle', taskoptions.tasktitle, setContractoptions)}
        {renderCheckbox('taskfree', taskoptions.taskfree, setContractoptions)}
        {renderCheckbox('duration', taskoptions.duration, setContractoptions)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('customer', taskoptions.customer, setContractoptions)}
        {renderCheckbox(
          'customerrequire',
          taskoptions.customerrequire,
          setContractoptions
        )}
        {renderCheckbox('employee', taskoptions.employee, setContractoptions)}
        {renderCheckbox(
          'employeerequire',
          taskoptions.employeerequire,
          setContractoptions
        )}
        {renderCheckbox(
          'department',
          taskoptions.department,
          setContractoptions
        )}
        {renderCheckbox('resourse', taskoptions.resourse, setContractoptions)}
        {renderCheckbox(
          'resourserequire',
          taskoptions.resourserequire,
          setContractoptions
        )}
        {renderCheckbox('desc', taskoptions.desc, setContractoptions)}
        {renderCheckbox('printdate', taskoptions.printdate, setContractoptions)}
        {renderCheckbox(
          'printtitle',
          taskoptions.printtitle,
          setContractoptions
        )}
        {renderCheckbox('address', taskoptions.address, setContractoptions)}
        {renderCheckbox('extra', taskoptions.extra, setContractoptions)}
        {renderCheckbox('deposit', taskoptions.deposit, setContractoptions)}
        {renderCheckbox(
          'ismemorized',
          taskoptions.ismemorized,
          setContractoptions
        )}
        {renderCheckbox('retype', taskoptions.retype, setContractoptions)}
        {renderCheckbox('retype2', taskoptions.retype2, setContractoptions)}
        {renderCheckbox('sms', taskoptions.sms, setContractoptions)}
        {renderCheckbox('photos', taskoptions.photos, setContractoptions)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Event Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('title', eventoptions.title, setEventoptions)}
        {renderCheckbox('bonos', eventoptions.bonos, setEventoptions)}
        {renderCheckbox('overtime', eventoptions.overtime, setEventoptions)}
        {renderCheckbox('absent', eventoptions.absent, setEventoptions)}
        {renderCheckbox('location', eventoptions.location, setEventoptions)}
        {renderCheckbox('retype', eventoptions.retype, setEventoptions)}
        {renderCheckbox('retype2', eventoptions.retype2, setEventoptions)}
        {renderCheckbox(
          'dueeventscheck',
          eventoptions.dueeventscheck,
          setEventoptions
        )}
        {renderCheckbox(
          'noinvoicecheck',
          eventoptions.noinvoicecheck,
          setEventoptions
        )}
        {renderCheckbox('duetoday', eventoptions.duetoday, setEventoptions)}
        {renderCheckbox('driverbox', eventoptions.driverbox, setEventoptions)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('status', eventoptions.status, setEventoptions)}
        {renderCheckbox('newinvoice', eventoptions.newinvoice, setEventoptions)}
        {renderCheckbox('note', eventoptions.note, setEventoptions)}
        {renderCheckbox(
          'retypefilter',
          eventoptions.retypefilter,
          setEventoptions
        )}
        {renderCheckbox(
          'retype2filter',
          eventoptions.retype2filter,
          setEventoptions
        )}
        {renderCheckbox(
          'customerfilter',
          eventoptions.customerfilter,
          setEventoptions
        )}
        {renderCheckbox(
          'employeefilter',
          eventoptions.employeefilter,
          setEventoptions
        )}
        {renderCheckbox(
          'multievents',
          eventoptions.multievents,
          setEventoptions
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Resourse Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {/* {renderCheckbox(
          'additionId',
          resorseoptions.additionId,
          setResourseoptions
        )} */}
        {renderCheckbox('workId', resorseoptions.workId, setResourseoptions)}
        {renderCheckbox('target', resorseoptions.target, setResourseoptions)}
        {renderCheckbox('city', resorseoptions.city, setResourseoptions)}
        {renderCheckbox('address', resorseoptions.address, setResourseoptions)}
        {renderCheckbox('color', resorseoptions.color, setResourseoptions)}
        {renderCheckbox('status', resorseoptions.status, setResourseoptions)}
        {renderCheckbox(
          'carstatus',
          resorseoptions.carstatus,
          setResourseoptions
        )}
        {renderCheckbox('garage', resorseoptions.garage, setResourseoptions)}
        {renderCheckbox('brand', resorseoptions.brand, setResourseoptions)}
        {renderCheckbox('model', resorseoptions.model, setResourseoptions)}
        {renderCheckbox('plate', resorseoptions.plate, setResourseoptions)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('cost', resorseoptions.cost, setResourseoptions)}
        {renderCheckbox('purtime', resorseoptions.purtime, setResourseoptions)}
        {renderCheckbox(
          'insurance',
          resorseoptions.insurance,
          setResourseoptions
        )}
        {renderCheckbox(
          'licenseDate',
          resorseoptions.licenseDate,
          setResourseoptions
        )}
        {renderCheckbox('photos', resorseoptions.photos, setResourseoptions)}
        {renderCheckbox(
          'location',
          resorseoptions.location,
          setResourseoptions
        )}
        {renderCheckbox(
          'department',
          resorseoptions.department,
          setResourseoptions
        )}
        {renderCheckbox('retype', resorseoptions.retype, setResourseoptions)}
        {renderCheckbox('info', resorseoptions.info, setResourseoptions)}
        {renderCheckbox(
          'disabled',
          resorseoptions.disabled,
          setResourseoptions
        )}
        {renderCheckbox('note', resorseoptions.note, setResourseoptions)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Employee Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {/* {renderCheckbox(
          'additionId',
          employeeoptions.additionId,
          setEmployeeoptions
        )} */}
        {renderCheckbox('target', employeeoptions.target, setEmployeeoptions)}
        {renderCheckbox('workId', employeeoptions.workId, setEmployeeoptions)}
        {renderCheckbox('telHome', employeeoptions.telHome, setEmployeeoptions)}
        {renderCheckbox(
          'national',
          employeeoptions.national,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'nationalNo',
          employeeoptions.nationalNo,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'nationalDate',
          employeeoptions.nationalDate,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'licenseNo',
          employeeoptions.licenseNo,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'licenseDate',
          employeeoptions.licenseDate,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'startWorkDate',
          employeeoptions.startWorkDate,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'basicSalary',
          employeeoptions.basicSalary,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'extraSalary',
          employeeoptions.extraSalary,
          setEmployeeoptions
        )}
        {renderCheckbox('daysoff', employeeoptions.daysoff, setEmployeeoptions)}
        {renderCheckbox(
          'speciality',
          employeeoptions.speciality,
          setEmployeeoptions
        )}
        {renderCheckbox('color', employeeoptions.color, setEmployeeoptions)}
        {renderCheckbox('tel', employeeoptions.tel, setEmployeeoptions)}

        {renderCheckbox(
          'salsales',
          employeeoptions.salsales,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salevents',
          employeeoptions.salevents,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salexpenses',
          employeeoptions.salexpenses,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salbonos',
          employeeoptions.salbonos,
          setEmployeeoptions
        )}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('fax', employeeoptions.fax, setEmployeeoptions)}
        {renderCheckbox('mobile', employeeoptions.mobile, setEmployeeoptions)}
        {renderCheckbox('email', employeeoptions.email, setEmployeeoptions)}
        {renderCheckbox('city', employeeoptions.city, setEmployeeoptions)}
        {renderCheckbox('address', employeeoptions.address, setEmployeeoptions)}
        {renderCheckbox('pId', employeeoptions.pId, setEmployeeoptions)}
        {renderCheckbox('pIdDate', employeeoptions.pIdDate, setEmployeeoptions)}
        {renderCheckbox('photos', employeeoptions.photos, setEmployeeoptions)}
        {renderCheckbox(
          'location',
          employeeoptions.location,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'comPercent',
          employeeoptions.comPercent,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'department',
          employeeoptions.department,
          setEmployeeoptions
        )}
        {renderCheckbox('retype', employeeoptions.retype, setEmployeeoptions)}
        {renderCheckbox('info', employeeoptions.info, setEmployeeoptions)}
        {renderCheckbox(
          'disabled',
          employeeoptions.disabled,
          setEmployeeoptions
        )}
        {renderCheckbox('note', employeeoptions.note, setEmployeeoptions)}
        {renderCheckbox(
          'salovertime',
          employeeoptions.salovertime,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salabsent',
          employeeoptions.salabsent,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'birthday',
          employeeoptions.birthday,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salworkpercent',
          employeeoptions.salworkpercent,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salsalespercent',
          employeeoptions.salsalespercent,
          setEmployeeoptions
        )}
        {renderCheckbox(
          'salextra',
          employeeoptions.salextra,
          setEmployeeoptions
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Department Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {/* {renderCheckbox(
          'additionId',
          departmentoptions.additionId,
          setDepartmentoptions
        )} */}
        {renderCheckbox(
          'target',
          departmentoptions.target,
          setDepartmentoptions
        )}
        {renderCheckbox('color', departmentoptions.color, setDepartmentoptions)}
        {renderCheckbox('desc', departmentoptions.desc, setDepartmentoptions)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox(
          'retype',
          departmentoptions.retype,
          setDepartmentoptions
        )}
        {renderCheckbox(
          'disabled',
          departmentoptions.disabled,
          setDepartmentoptions
        )}
        {renderCheckbox('note', departmentoptions.note, setDepartmentoptions)}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Customer Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {/* {renderCheckbox(
          'additionId',
          customeroptions.additionId,
          setCustomeroptions
        )} */}
        {renderCheckbox('tel', customeroptions.tel, setCustomeroptions)}
        {renderCheckbox('fax', customeroptions.fax, setCustomeroptions)}
        {renderCheckbox('mobile', customeroptions.mobile, setCustomeroptions)}
        {renderCheckbox('email', customeroptions.email, setCustomeroptions)}
        {renderCheckbox('city', customeroptions.city, setCustomeroptions)}
        {renderCheckbox('address', customeroptions.address, setCustomeroptions)}
        {renderCheckbox('balance', customeroptions.balance, setCustomeroptions)}
        {renderCheckbox('area', customeroptions.area, setCustomeroptions)}
        {renderCheckbox('driver', customeroptions.driver, setCustomeroptions)}
        {renderCheckbox(
          'licenseNo',
          customeroptions.licenseNo,
          setCustomeroptions
        )}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox(
          'licenseDate',
          customeroptions.licenseDate,
          setCustomeroptions
        )}
        {renderCheckbox(
          'national',
          customeroptions.national,
          setCustomeroptions
        )}
        {renderCheckbox(
          'nationalNo',
          customeroptions.nationalNo,
          setCustomeroptions
        )}
        {renderCheckbox(
          'nationalDate',
          customeroptions.nationalDate,
          setCustomeroptions
        )}
        {renderCheckbox(
          'parentName',
          customeroptions.parentName,
          setCustomeroptions
        )}
        {renderCheckbox(
          'parentPhone',
          customeroptions.parentPhone,
          setCustomeroptions
        )}
        {renderCheckbox('grade', customeroptions.grade, setCustomeroptions)}
        {renderCheckbox(
          'location',
          customeroptions.location,
          setCustomeroptions
        )}
        {renderCheckbox(
          'disabled',
          customeroptions.disabled,
          setCustomeroptions
        )}
        {renderCheckbox(
          'birthday',
          customeroptions.birthday,
          setCustomeroptions
        )}
        {renderCheckbox(
          'openingbalance',
          customeroptions.openingbalance,
          setCustomeroptions
        )}
        {renderCheckbox('note', customeroptions.note, setCustomeroptions)}
        {renderCheckbox(
          'employee',
          customeroptions.employee,
          setCustomeroptions
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Supplier Options</Typography>
      </Grid>
      <Grid item xs={6}>
        {/* {renderCheckbox(
          'additionId',
          supplieroptions.additionId,
          setSupplieroptions
        )} */}
        {renderCheckbox('tel', supplieroptions.tel, setSupplieroptions)}
        {renderCheckbox('fax', supplieroptions.fax, setSupplieroptions)}
        {renderCheckbox('mobile', supplieroptions.mobile, setSupplieroptions)}
        {renderCheckbox('email', supplieroptions.email, setSupplieroptions)}
      </Grid>
      <Grid item xs={6}>
        {renderCheckbox('city', supplieroptions.city, setSupplieroptions)}
        {renderCheckbox('address', supplieroptions.address, setSupplieroptions)}
        {renderCheckbox('balance', supplieroptions.balance, setSupplieroptions)}
        {renderCheckbox(
          'disabled',
          supplieroptions.disabled,
          setSupplieroptions
        )}
        {renderCheckbox(
          'openingbalance',
          supplieroptions.openingbalance,
          setSupplieroptions
        )}
        {renderCheckbox('note', supplieroptions.note, setSupplieroptions)}
      </Grid>
    </Grid>
  );
};

export default TemplateOptions;
