/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useLayoutEffect,
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react';
import { Box, fade, Grid, Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import useLandingChart from '../../hooks/useLandingChart';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { eventChange } from '../../graphql/subscription';
import LandingFooter from '../components/LandingFooter';
import { renderCell } from '../generator/page/renders';

const Landing = forwardRef((props: any, ref: any) => {
  const { words, isRTL, theme, company, drawerWidth, isMobile } = props;
  const [time, setTime] = useState(null);

  const template = company?.fronttemp ? JSON.parse(company?.fronttemp) : [];
  const { height, width } = useWindowDimensions();
  const sub = useSubscription(eventChange, {
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    onComplete: () => console.log('Subscription Complete'),
  });
  const location = useLocation();
  const { chartData } = useLandingChart(null);

  const refresh = () => {
    if (chartData?.refetch) {
      chartData.refetch();
    }
  };

  useImperativeHandle(ref, () => ({
    refresh() {
      if (chartData?.refetch) {
        chartData.refetch();
      }
    },
  }));

  useLayoutEffect(() => {
    refresh();
  }, [location]);

  useEffect(() => {
    if (sub?.data?.eventChange?.time && sub?.data?.eventChange?.time !== time) {
      setTime(sub?.data?.eventChange?.time);
      refresh();
    }
  }, [sub]);

  return (
    <>
      <Paper
        style={{
          width: width - drawerWidth - 50,
          borderRadius: isMobile ? undefined : 25,
          height: height - (isMobile ? 50 : 100),
          overflow: 'auto',
          backgroundColor: fade(theme.palette.primary.main, 0.1),
        }}
      >
        <Box style={{ margin: 15 }}>
          {template?.map((listitem: any) => {
            return (
              <Grid container spacing={3}>
                {listitem?.map((item: any) => {
                  const { size, type } = item;
                  const args = {
                    ...item,
                    isRTL,
                    theme,
                    words,
                    company,
                  };
                  return (
                    <Grid item xs={12} md={size} style={{ marginBottom: 10 }}>
                      {renderCell(type, args, isMobile)}
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Box>
        <LandingFooter></LandingFooter>
      </Paper>
    </>
  );
});

export default Landing;
