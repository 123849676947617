/* eslint-disable no-new-func */
import { nanoid } from 'nanoid';
import { Box, TextField, Typography } from '@material-ui/core';
import { getClassNames } from '../common/clasnames';
import CalenderLocal from '../components/CalenderLocal';
import Editor from '../components/Editor';
import EditorMini from '../components/EditorMini';
import AutoFieldLocal from '../components/AutoFieldLocal';
import { moneyFormat } from '../common/helpers';

import {
  useProducts,
  useServices,
  useContacts,
  useEmployees,
} from '../../../hooks';
import { ItemsTable, ServiceItemForm } from '../../../Shared';

const renderText = ({ value, classnames, classes, style, namevalue }: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div
      className={cn}
      style={{
        ...style,
        wordWrap: 'normal',
        letterSpacing: 'normal',
        flexWrap: 'wrap',
      }}
    >
      {value ? value : namevalue}
    </div>
  );
};

const renderDate = ({
  type,
  name,
  nameAr,
  variables,
  setVariables,
  classnames,
  style,
  classes,
  isRTL,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div className={cn} style={{ ...style }}>
      <CalenderLocal
        isRTL={isRTL}
        label={isRTL ? nameAr : name}
        value={variables?.[type]}
        onChange={(d: any) => setVariables({ ...variables, [type]: d })}
        format="dd/MM/yyyy"
        height={style?.height}
        fontSize={style?.fontSize}
        fontWeight={style?.fontWeight}
        backgroundColor={style?.backgroundColor}
        mt={0}
      ></CalenderLocal>
    </div>
  );
};
const renderTextField = ({
  type,
  name,
  nameAr,
  variables,
  setVariables,
  classnames,
  style,
  isRTL,
  classes,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  return (
    <div className={cn} style={{ ...style }}>
      <TextField
        name={type}
        label={isRTL ? nameAr : name}
        value={variables?.[type] || ''}
        onChange={(e: any) =>
          setVariables({ ...variables, [type]: e.target.value })
        }
        fullWidth
        variant="outlined"
      />
    </div>
  );
};
const RenderItems = ({
  classnames,
  style,
  classes,
  words,
  isRTL,
  user,
  setAlrt,
  itemsList,
  totals,
  addItemToList,
  editItemInList,
  removeItemFromList,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  const { products } = useProducts();
  const { services } = useServices();
  return (
    <div className={cn} style={{ ...style }}>
      <Box
        style={{
          backgroundColor: '#f4f4f4',
          padding: 10,
          marginTop: 15,
          marginBottom: 15,
          borderRadius: 10,
        }}
      >
        <Box display="flex">
          <ServiceItemForm
            services={services}
            products={products}
            addItem={addItemToList}
            words={words}
            classes={classes}
            user={user}
            isRTL={isRTL}
            setAlrt={setAlrt}
            itemrelations
          ></ServiceItemForm>
        </Box>
        <Box>
          <ItemsTable
            products={[...services, ...products]}
            rows={itemsList}
            editItem={editItemInList}
            removeItem={removeItemFromList}
            isRTL={isRTL}
            words={words}
            user={user}
            itemrelations
          ></ItemsTable>
        </Box>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: 16,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {words.total} : {moneyFormat(totals.amount)}
        </Typography>
      </Box>
    </div>
  );
};
const renderEditor = ({ type, variables, setVariables, style }: any) => {
  return (
    <div style={{ ...style, direction: 'ltr' }}>
      <Editor
        value={variables?.[type] || ''}
        setValue={(d: any) => setVariables({ ...variables, [type]: d })}
      />
    </div>
  );
};
const renderEditorMini = ({ type, variables, setVariables, style }: any) => {
  return (
    <div style={{ ...style, direction: 'ltr' }}>
      <EditorMini
        value={variables?.[type] || ''}
        setValue={(d: any) => setVariables({ ...variables, [type]: d })}
      />
    </div>
  );
};
const renderSignatureImage = ({
  name,
  nameAr,
  variables,
  setVariables,
  style,
}: any) => {
  return (
    <div style={{ ...style }}>
      <div
        style={{
          width: 'auto',
          height: 100,
          borderRadius: 10,
          backgroundColor: '#eee',
        }}
      />
    </div>
  );
};
const RenderContacts = ({
  type,
  name,
  nameAr,
  variables,
  setVariables,
  classnames,
  style,
  classes,
  words,
  isRTL,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  const { contacts } = useContacts();
  return (
    <div className={cn} style={{ ...style }}>
      <AutoFieldLocal
        name="contact"
        title={isRTL ? nameAr : name}
        words={words}
        options={contacts}
        value={variables?.[type] || null}
        setSelectValue={(d: any) => setVariables({ ...variables, [type]: d })}
        isRTL={isRTL}
        fullWidth
      ></AutoFieldLocal>
    </div>
  );
};
const RenderEmployees = ({
  type,
  name,
  nameAr,
  variables,
  setVariables,
  classnames,
  style,
  classes,
  words,
  isRTL,
}: any) => {
  const cn = getClassNames({ classnames, classes });
  const { employees } = useEmployees();
  return (
    <div className={cn} style={{ ...style }}>
      <AutoFieldLocal
        name="employee"
        title={isRTL ? nameAr : name}
        words={words}
        options={employees}
        value={variables?.[type] || null}
        setSelectValue={(d: any) => setVariables({ ...variables, [type]: d })}
        isRTL={isRTL}
        fullWidth
      ></AutoFieldLocal>
    </div>
  );
};

export const basicitem = {
  name: '',
  nameAr: '',
  value: '-',
  type: 'text',
  skip: 0,
  size: 1,
  classnames: [],
  style: {},
  tableitems: [],
  border: false,
};

export const zeroeleven = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
];
export const onetwelve = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const renderCell = (
  type: any,
  args: any,
  classes: any,
  view = false
) => {
  const borderBottom = args?.classnames?.includes('bb') ? 1 : 0;
  return (
    <Box
      border={args?.border === true ? 1 : 0}
      borderBottom={borderBottom}
      borderColor="grey.400"
      className={view ? '' : classes.hvr}
    >
      {type === 'text' && renderText({ ...args, type })}
      {type === 'time' && renderDate({ ...args, type })}
      {type === 'title' && renderTextField({ ...args, type })}
      {type === 'desc' && renderEditor({ ...args, type })}
      {type === 'items' && <RenderItems {...args} type={type}></RenderItems>}
      {type === 'signatureName' && renderEditorMini({ ...args, type })}
      {type === 'signatureImage' && renderSignatureImage({ ...args, type })}
      {type === 'contact' && (
        <RenderContacts {...args} type={type}></RenderContacts>
      )}
      {type === 'employee' && (
        <RenderEmployees {...args} type={type}></RenderEmployees>
      )}
    </Box>
  );
};

export const rid = () => {
  return nanoid(4);
};
