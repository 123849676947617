import { Box } from '@material-ui/core';
import useLandingChart from '../../../../hooks/useLandingChart';
import { userroles } from '../../../constants';
import { moneyFormat, getColor } from '../../common/helpers';
import InfoBox from '../InfoBox';

function MonthTotalSales({
  isRTL,
  theme,
  dark,
  words,
  color,
  systemcolor,
  height,
}) {
  const { salesMonthTotal } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {userroles?.invoices?.admin && (
        <InfoBox
          title={isRTL ? 'هذا الشهر' : 'This Month'}
          desc={isRTL ? 'مبيعات هذا الشهر' : 'This Month Sales'}
          value={moneyFormat(salesMonthTotal)}
          icon="sales"
          color={clr}
          dark={dark}
          height={height}
        ></InfoBox>
      )}
    </Box>
  );
}

export default MonthTotalSales;
