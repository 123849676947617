import { Grid, Paper, Typography } from '@material-ui/core';

export default function CustomerDataBx({ row, isRTL, height }) {
  const name = row?.name;
  const nameAr = row?.nameAr;
  const phone = row?.phone;
  const mobile = row?.mobile;
  const email = row?.email;
  const address = row?.address;

  return (
    <Paper style={{ height, padding: 20 }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6">{isRTL ? nameAr : name}</Typography>
        </Grid>

        {phone && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'الهاتف : ' : '؛Phone : '} {phone}
            </Typography>
          </Grid>
        )}
        {mobile && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'الجوال : ' : 'Mobile : '} {mobile}
            </Typography>
          </Grid>
        )}
        {email && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'البريد : ' : 'Email : '} {email}
            </Typography>
          </Grid>
        )}
        {address && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'العنوان : ' : 'Address : '} {address}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
