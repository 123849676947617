/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { downloadCSVFile, getRowId, userroles } from '../../common';
import {
  avatarColorFormatter,
  nameManageLinkSimpleRes,
  nameResourseRest,
} from '../../Shared/colorFormat';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { getColumns } from '../../common/columns';
import PopupResoursesView from '../../pubups/PopupResoursesView';
import PopupResourses from '../../pubups/PopupResourses';
import useResourses from '../../hooks/useResourses';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import { ResourseContext } from '../../contexts/managment';
import { useLazyQuery } from '@apollo/client';
import { getResourses } from '../../graphql';
import AutoFieldLocal from '../../components/fields/AutoFieldLocal';
import useRetypes from '../../hooks/useRetypes';
import { useTemplate } from '../../hooks';
import ImportBtn from '../../common/ImportBtn';
import ExportBtn from '../../common/ExportBtn';
import PopupResourseImport from '../../pubups/PopupResourseImport';

const Resourses = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme, company } = props;

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [rows, setRows] = useState([]);
  const [rtypvalue, setRtypvalue] = useState<any>(null);
  const [openImport, setOpenImport] = useState(false);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const col = getColumns({ isRTL, words });

  const { retypes } = useRetypes();
  const { tempoptions, resorseoptions } = useTemplate();

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(ResourseContext);
  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const [columns] = useState(
    [
      { name: 'avatar', title: ' ' },
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      col.name,
      col.data,
      resorseoptions?.plate && { name: 'plate', title: words.plate },
      resorseoptions?.brand && { name: 'brand', title: words.brand },
      resorseoptions?.model && { name: 'model', title: words.model },
      resorseoptions?.licenseDate && col.licenseDate,
      resorseoptions?.info && { name: 'info', title: words.info },
      resorseoptions?.purtime && { name: 'purtime', title: words.purtime },
      resorseoptions?.cost && { name: 'cost', title: words.cost },
      resorseoptions?.insurance && {
        name: 'insurance',
        title: words.insurance,
      },
      resorseoptions?.carstatus && col.carstatus,
      resorseoptions?.retype && tempoptions?.retype && col.retype,
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 30 },
    { columnName: 'additionId', width: 120 },
    { columnName: col.name.name, width: 300 },
    { columnName: col.data.name, width: 300 },
    { columnName: 'plate', width: 100 },
    { columnName: 'brand', width: 100 },
    { columnName: 'model', width: 100 },
    { columnName: col.licenseDate.name, width: 150 },
    { columnName: 'info', width: 250 },
    { columnName: 'purtime', width: 100 },
    { columnName: 'cost', width: 100 },
    { columnName: 'insurance', width: 100 },
    { columnName: col.carstatus.name, width: 100 },
    { columnName: col.retype.name, width: 150 },
  ]);

  const [columnsViewer] = useState([
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'avatar', title: words.color },
  ]);

  const {
    addResourse,
    editResourse,
    removeResourse,
    addMultiResourses,
    refreshresourse,
  } = useResourses();
  const [getemps, empData]: any = useLazyQuery(getResourses, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getemps({});
  }, [rtypvalue]);

  useEffect(() => {
    if (empData?.data?.getResourses?.data) {
      const { data } = empData.data.getResourses;
      if (rtypvalue) {
        const fdata = data.filter((da: any) => da.retypeId === rtypvalue._id);
        setRows(fdata);
      } else {
        setRows(data);
      }
    }
  }, [empData, rtypvalue]);

  useEffect(() => {
    if (openItem) {
      if (rows && rows.length > 0) {
        const opened = rows.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
  }, [rows]);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (refreshresourse) {
        refreshresourse();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removeResourse({ variables: { _id } });
      if (res?.data?.deleteResourse?.ok === false) {
        if (res?.data?.deleteResourse?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'resourses', colnames);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          top: 72,
          right: isRTL ? undefined : 400,
          left: isRTL ? 400 : undefined,
          width: 220,
        }}
      >
        <AutoFieldLocal
          name="retype"
          title={words?.type}
          words={words}
          options={retypes.filter((d: any) => d.reType === 2)}
          value={rtypvalue}
          setSelectValue={setRtypvalue}
          isRTL={isRTL}
          fullWidth
          mb={0}
        ></AutoFieldLocal>
      </Box>

      <Grid
        rows={rows}
        columns={userroles?.manageresourses?.edit ? columns : columnsViewer}
        getRowId={getRowId}
      >
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />

        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 80 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={[
            'avatar',
            col.name.name,
            col.data.name,
            'plate',
            'brand',
            'model',
            col.licenseDate.name,
            'purtime',
            'cost',
            'insurance',
            col.carstatus.name,
            col.retype.name,
            'info',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <DataTypeProvider
          for={['avatar']}
          formatterComponent={(props: any) =>
            avatarColorFormatter({
              ...props,
              height: 70,
            })
          }
        ></DataTypeProvider>
        {userroles?.manageresourses?.admin && (
          <DataTypeProvider
            for={[col.name.name]}
            formatterComponent={(props: any) =>
              nameManageLinkSimpleRes({
                ...props,
                setItem,
                setOpenItem,
                isRTL,
              })
            }
          ></DataTypeProvider>
        )}
        {userroles?.manageresourses?.admin && (
          <DataTypeProvider
            for={[col.data.name]}
            formatterComponent={nameResourseRest}
          ></DataTypeProvider>
        )}

        <TableEditColumn
          showEditCommand={userroles?.manageresourses?.edit}
          showDeleteCommand={userroles?.manageresourses?.delete}
          showAddCommand={userroles?.manageresourses?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing
          theme={theme}
          addAction={addResourse}
          editAction={editResourse}
        >
          <PopupResourses></PopupResourses>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupResoursesView
        open={openItem}
        onClose={onCloseItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupResoursesView>
      <PopupResourseImport
        open={openImport}
        onClose={() => setOpenImport(false)}
        addMultiItems={addMultiResourses}
        isRTL={isRTL}
        theme={theme}
        words={words}
      ></PopupResourseImport>
    </>
  );
});

export default Resourses;
