/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dublicateAlert, errorAlert, yup, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import useAccounts from '../hooks/useAccounts';
import { operationTypes } from '../constants';
import { parents } from '../constants/kaid';
import PopupLayout from '../pages/main/PopupLayout';
import { Box, Grid } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import { CalenderLocal, TextFieldLocal } from '../components';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import {
  useCustomers,
  useDepartments,
  useEmployees,
  useTemplate,
} from '../hooks';
import { useLazyQuery } from '@apollo/client';
import getInvoicesList from '../graphql/query/getInvoicesList';
import PopupCustomer from './PopupCustomer';
import { successAlert } from '../Shared/helpers';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import { updateDocNumbers, userroles } from '../common';
import useTasks from '../hooks/useTasks';
import { weekdaysNNo } from '../constants/datatypes';
import useResourses from '../hooks/useResourses';
import usePrintTemplates from '../hooks/usePrintTemplates';
import { getDefaultTemplate, tafkeet } from '../common/helpers';
import useWindowDimensions from '../hooks/useWindowDimensions';
import PrintDownload from '../Shared/PrintDownload';
import { uploadMultiPhotoOnline } from '../common/AvatarUpload';
import { ImageView } from '../components/viewer';
import { UploadPhotos } from '../common/UploadPhotos';
const PopupReceipt = ({
  open,
  onClose,
  row,
  task,
  isNew,
  addAction,
  editAction,
  theme,
  name,
  value,
  company,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [debaccounts, setDebaccounts] = React.useState([]);

  const [debitAcc, setDebitAcc]: any = React.useState(null);
  const [creditAcc, setCreditAcc]: any = React.useState(null);

  const [custvalue, setCustvalue] = useState<any>(
    name === 'customerId' ? value : null
  );
  const [custError, setCustError] = useState<any>(false);

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [taskvalue, setTaskvalue] = useState<any>(
    task ? task : name === 'contractId' ? value : null
  );

  const [invoices, setInvoices] = useState<any>([]);
  const [invoicevalue, setInvoicevalue] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);

  const [newtext, setNewtext] = useState('');
  const [openCust, setOpenCust] = useState(false);

  const [urls, setUrls] = useState([]);
  const [photosimages, setPhotosimages] = useState([]);
  const [photosurls, setPhotosurls] = useState([]);

  const { register, handleSubmit, errors, reset } = useForm(
    yup.depositResolver
  );
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const { accounts } = useAccounts();
  const { customers, addCustomer, editCustomer } = useCustomers();
  const { tempwords, tempoptions } = useTemplate();
  const { tasks } = useTasks();
  const { departments } = useDepartments();
  const { employeesfull: employees } = useEmployees();
  const { resourses } = useResourses();
  const { printtemplates } = usePrintTemplates();
  const { isMobile } = useWindowDimensions();

  const [loadInvoices, invoicesData]: any = useLazyQuery(getInvoicesList, {
    fetchPolicy: 'cache-and-network',
  });

  const [loadBalance, balanceData]: any = useLazyQuery(getGereralCalculation, {
    fetchPolicy: 'cache-and-network',
  });

  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };
  const onNewCustChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };
  useEffect(() => {
    if (custvalue) {
      const variables = { customerId: custvalue._id };
      loadInvoices({ variables });
      loadBalance({ variables });
    }
    if (isNew) {
      if (name === 'contractId') {
        if (value?.customerId) {
          const dept = customers.filter(
            (dep: any) => dep._id === value?.customerId
          )?.[0];
          setCustvalue(dept);
        }
        if (value?.departmentId) {
          const dept = departments.filter(
            (dep: any) => dep._id === value?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (value?.employeeId) {
          const dept = employees.filter(
            (dep: any) => dep._id === value?.employeeId
          )?.[0];
          setEmplvalue(dept);
        }
        if (value?.resourseId) {
          const dept = resourses.filter(
            (dep: any) => dep._id === value?.resourseId
          )?.[0];
          setResovalue(dept);
        }
      }
    }
  }, [custvalue, open]);

  useEffect(() => {
    if (name && value) {
      if (name === 'customerId') {
        setCustvalue(value);
      }
    }
  }, [name, value, open]);
  useEffect(() => {
    if (invoicesData?.data?.getInvoicesList?.data) {
      const { data } = invoicesData.data.getInvoicesList;
      if (data?.length > 0) {
        const ndata = data.map((d: any) => {
          const ramount = d.amount - d.discount;
          const title = `${d.docNo} - ${ramount}QR`;
          return {
            ...d,
            name: title,
            nameAr: title,
          };
        });
        // const fndata =
        //   ndata && ndata.length > 0
        //     ? ndata.filter((nd: any) => !nd.paid)
        //     : ndata;
        const refdatar = updateDocNumbers(ndata);
        if (task) {
          const tfndata = refdatar.filter(
            (nd: any) => nd.contractId === task._id
          );
          setInvoices(tfndata);
        } else {
          setInvoices(refdatar);
        }
        if (row?.refNo) {
          const inv = refdatar.filter(
            (ts: any) => ts.docNo === row?.refNo
          )?.[0];
          setInvoicevalue(inv);
        }
      }
    } else {
      setInvoices([]);
      setInvoicevalue(null);
    }
  }, [invoicesData]);

  useEffect(() => {
    const res = balanceData?.data?.getGereralCalculation?.data;
    if (res) {
      const data = JSON.parse(res);
      setBalance(data);
    }
  }, [balanceData, open]);

  useEffect(() => {
    if (isNew) {
      if (invoicevalue) {
        // if (invoicevalue?.contractId && name !== 'contractId') {
        //   const tsk = tasks.filter(
        //     (ts: any) => ts._id === invoicevalue.contractId
        //   )?.[0];
        //   setTaskvalue(tsk);
        // }
        if (invoicevalue?.departmentId && name !== 'departmentId') {
          const dept = departments.filter(
            (dep: any) => dep._id === invoicevalue?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (invoicevalue?.employeeId && name !== 'employeeId') {
          const emp = employees.filter(
            (dep: any) => dep._id === invoicevalue?.employeeId
          )?.[0];
          setEmplvalue(emp);
        }
        if (invoicevalue?.resourseId && name !== 'resourseId') {
          const res = resourses.filter(
            (dep: any) => dep._id === invoicevalue?.resourseId
          )?.[0];
          setResovalue(res);
        }
      }
    }
  }, [invoicevalue]);

  useEffect(() => {
    if (row && row._id) {
      const ca = row.creditAcc;
      const da = row.debitAcc;

      const filteredcredit = accounts.filter(
        (acc: any) => acc.parentcode === parents.CASH
      );
      setDebaccounts(filteredcredit);

      if (ca) {
        const credit = accounts.filter((acc: any) => acc.code === ca)[0];
        setCreditAcc(credit);
      }
      if (da) {
        const debit = accounts.filter((acc: any) => acc.code === da)[0];
        setDebitAcc(debit);
      }
      const customerId = row?.customerId;
      const depId = row.departmentId;
      const empId = row.employeeId;
      const resId = row.resourseId;
      const contId = row.contractId;
      const attachments = row.attachments;
      if (attachments) {
        const phs = JSON.parse(attachments);
        if (phs && phs.length > 0) {
          setPhotosurls(phs);
        }
      }

      if (customerId) {
        const cust = customers.filter((it: any) => it._id === customerId)?.[0];
        setCustvalue(cust);
      }
      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)?.[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)?.[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)?.[0];
        setResovalue(empl);
      }
      if (!task && contId) {
        const tsk = tasks.filter((ts: any) => ts._id === row.contractId)?.[0];
        setTaskvalue(tsk);
      }
      handleDateChange(row.time);
    } else {
      const filtereddebits = accounts.filter(
        (acc: any) => acc.parentcode === parents.ACCOUNTS_RECEIVABLE
      );
      const filteredcredit = accounts.filter(
        (acc: any) => acc.parentcode === parents.CASH
      );
      setDebaccounts(filteredcredit);
      setDebitAcc(filteredcredit?.[0]);
      setCreditAcc(filtereddebits?.[0]);
    }
  }, [row, open]);

  useEffect(() => {
    let locals = [];
    let online = [];
    if (photosimages && photosimages.length > 0) {
      for (const img of photosimages) {
        const localimage = URL.createObjectURL(img);
        locals.push(localimage);
      }
    } else {
      locals = [];
    }
    if (photosurls && photosurls.length > 0) {
      online = photosurls;
    } else {
      online = [];
    }
    setUrls([...online, ...locals]);
  }, [photosimages, photosurls]);

  const addToPhotos = (photos: any) => {
    const lphotos = [...photosimages];
    const li = photosimages.length;
    const oi = photosurls.length;
    const n = 10 - (oi + li);
    if (n < 1 || !photos || photos.length === 0) {
      return;
    } else {
      const newphotos = [...lphotos, ...photos];
      const fnewphotos = newphotos.slice(0, n);
      setPhotosimages(fnewphotos);
    }
  };
  const removePhoto = (src: any, index: any) => {
    if (src.startsWith('http')) {
      const newphotosurls = [...photosurls];
      const newlist = newphotosurls.filter((nu: any) => nu !== src);
      setPhotosurls(newlist);
    } else {
      const newList = [...photosimages];
      const newindex = index - photosurls.length;
      newList.splice(newindex, 1);
      setPhotosimages(newList);
    }
  };

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Receipt #${row?.docNo}`,
    removeAfterPrint: true,
  });

  const totalDiscount = balance?.totalDiscount || 0;
  const totalinvoiced = balance?.totalinvoiced || 0;
  const totalpaid = balance?.totalpaid || 0;
  const blns = totalinvoiced - totalDiscount - totalpaid;
  const isDiscount = invoicevalue?.discount > 0;
  const amt = isDiscount
    ? `${invoicevalue?.amount} - ${invoicevalue?.discount} = ${
        invoicevalue?.amount - invoicevalue?.discount
      }`
    : invoicevalue?.amount;
  const isCash = !row?.chequeBank && !row?.chequeDate;
  const printData = {
    no: row?.docNo,
    invoiceno: invoicevalue?.docNo,
    time: selectedDate,
    customerName: custvalue?.name,
    customerNameAr: custvalue?.nameAr,
    customerPhone: custvalue?.phone,
    resourseNameAr: invoicevalue?.resourseNameAr,
    resourseName: invoicevalue?.resourseName,
    employeeNameAr: invoicevalue?.employeeNameAr,
    employeeName: invoicevalue?.employeeName,
    periodfrom: invoicevalue?.periodfrom,
    isMonthly: invoicevalue?.isMonthly,
    periodto: invoicevalue?.periodto,
    refNo: row?.refNo,
    title: row?.title,
    desc: row?.desc,
    amount: row?.amount,
    isRTL: isRTL,
    chequeBank: row?.chequeBank,
    chequeNo: row?.chequeNo,
    chequeDate: row?.chequeDate,
    balance: blns,
    isCash,
    isCheque: !isCash,
    tafkeet: tafkeet(row?.amount, isRTL),
    amt,
  };

  const template = getDefaultTemplate(printtemplates, 'receipt');

  const onSubmit = async (data: any) => {
    if (selectedDate > new Date()) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    const { amount, title, desc, chequeBank, chequeNo, chequeDate } = data;
    if (!debitAcc) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تحديد الحساب' : 'You have to select Account'
      );
      return;
    }

    if (!custvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تحديد العميل' : 'You have to select Customer'
      );
      return;
    }
    setSaving(true);
    const customer = custvalue
      ? {
          customerId: custvalue._id,
          customerName: custvalue.name,
          customerNameAr: custvalue.nameAr,
          customerPhone: custvalue.color,
        }
      : {
          customerId: undefined,
          customerName: undefined,
          customerNameAr: undefined,
          customerPhone: undefined,
        };

    const department = departvalue
      ? {
          departmentId: departvalue._id,
          departmentName: departvalue.name,
          departmentNameAr: departvalue.nameAr,
          departmentColor: departvalue.color,
        }
      : {
          departmentId: undefined,
          departmentName: undefined,
          departmentNameAr: undefined,
          departmentColor: undefined,
        };
    const employee = emplvalue
      ? {
          employeeId: emplvalue._id,
          employeeName: emplvalue.name,
          employeeNameAr: emplvalue.nameAr,
          employeeColor: emplvalue.color,
          employeePhone: emplvalue.phone,
        }
      : {
          employeeId: undefined,
          employeeName: undefined,
          employeeNameAr: undefined,
          employeeColor: undefined,
          employeePhone: undefined,
        };
    const resourse = resovalue
      ? {
          resourseId: resovalue._id,
          resourseName: resovalue.name,
          resourseNameAr: resovalue.nameAr,
          resourseColor: resovalue.color,
        }
      : {
          resourseId: undefined,
          resourseName: undefined,
          resourseNameAr: undefined,
          resourseColor: undefined,
        };
    const contract = taskvalue
      ? {
          contractId: taskvalue._id,
          contractName: taskvalue.name,
          contractNameAr: taskvalue.nameAr,
        }
      : {
          contractId: undefined,
          contractName: undefined,
          contractNameAr: undefined,
        };
    const project = taskvalue
      ? {
          projectId: taskvalue.projectId,
          projectName: taskvalue.projectName,
          projectNameAr: taskvalue.projectNameAr,
        }
      : {
          projectId: undefined,
          projectName: undefined,
          projectNameAr: undefined,
        };

    let localphotos = [];

    if (photosimages) {
      const photosurl = await uploadMultiPhotoOnline(photosimages);
      if (photosurl && photosurl.length > 0) {
        const rphotos = photosurl.map((photo: any) =>
          photo.replace('http://', 'https://')
        );
        localphotos = rphotos;
      }
    }

    const fphotos = [...photosurls, ...localphotos];
    const photos = JSON.stringify(fphotos);

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      opType: operationTypes.customerReceipt,
      time: selectedDate,
      debitAcc: debitAcc.code,
      creditAcc: creditAcc.code,
      refNo: invoicevalue ? invoicevalue.docNo : null,
      invoiceId: invoicevalue ? invoicevalue._id : null,
      attachments: fphotos && fphotos.length > 0 ? photos : null,
      customer,
      department,
      employee,
      project,
      resourse,
      contract,
      amount,
      chequeBank,
      chequeNo,
      chequeDate,
      title,
      desc,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      closeModal();
    } catch (error) {
      onError(error);
      setSaving(false);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      resetAll();
      console.log(error);
    }
  };

  const resetAll = () => {
    reset();
    setCreditAcc(null);
    setDebitAcc(null);
    setDebaccounts([]);
    setInvoices([]);
    setCustvalue(name === 'customerId' ? value : null);
    setDepartvalue(name === 'departmentId' ? value : null);
    setEmplvalue(name === 'employeeId' ? value : null);
    setResovalue(name === 'resourseId' ? value : null);
    setTaskvalue(name === 'contractId' ? value : null);

    setInvoicevalue(null);
    setCustError(false);
    setSaving(false);
    setPhotosimages([]);
    setUrls([]);
    setPhotosurls([]);
  };
  const closeModal = () => {
    resetAll();
    onClose();
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const day = weekdaysNNo?.[new Date().getDay()];
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={words.receipts}
      onSubmit={onHandleSubmit}
      saving={saving}
      theme={theme}
      alrt={alrt}
      print={!isNew ? handleReactPrint : undefined}
      onlyclose={!isNew && !userroles?.receipts?.edit}
      mt={10}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <CalenderLocal
                    isRTL={isRTL}
                    label={words?.time}
                    value={selectedDate}
                    onChange={handleDateChange}
                  ></CalenderLocal>
                </Grid>
                {!isMobile && (
                  <Grid item md={7}>
                    <PrintDownload
                      company={company}
                      printData={printData}
                      componentRef={componentRef}
                      handleReactPrint={handleReactPrint}
                      isRTL={isRTL}
                      template={template}
                      no={row?.docNo}
                      element="receipt"
                    ></PrintDownload>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="customer"
                    title={tempwords?.customer}
                    words={words}
                    options={customers}
                    value={custvalue}
                    setSelectValue={(value: any) => {
                      setCustvalue(value);
                      setInvoicevalue(null);
                    }}
                    setSelectError={setCustError}
                    selectError={custError}
                    isRTL={isRTL}
                    fullwidth
                    openAdd={openCustomer}
                    disabled={name === 'customerId' || name === 'contractId'}
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>

                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="invoice"
                    title={isRTL ? 'الفواتير' : 'Invoices'}
                    words={words}
                    options={invoices}
                    value={invoicevalue}
                    setSelectValue={setInvoicevalue}
                    register={register}
                    isRTL={isRTL}
                    disabled={!custvalue}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>

                {tempoptions?.contract && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="task"
                      title={tempwords?.task}
                      words={words}
                      options={tasks}
                      value={taskvalue}
                      setSelectValue={setTaskvalue}
                      isRTL={isRTL}
                      fullWidth
                      disabled={name === 'contractId'}
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.resourse && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="resourse"
                      title={tempwords?.resourse}
                      words={words}
                      options={resourses}
                      value={resovalue}
                      disabled={name === 'resourseId'}
                      setSelectValue={setResovalue}
                      isRTL={isRTL}
                      fullWidth
                      day={day}
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.employee && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="employee"
                      title={tempwords?.employee}
                      words={words}
                      options={employees.filter((emp: any) => !emp.disabled)}
                      value={emplvalue}
                      disabled={name === 'employeeId'}
                      setSelectValue={setEmplvalue}
                      isRTL={isRTL}
                      fullWidth
                      day={day}
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.department && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="department"
                      title={tempwords?.department}
                      words={words}
                      options={departments.filter((d: any) => d.depType === 1)}
                      value={departvalue}
                      setSelectValue={setDepartvalue}
                      isRTL={isRTL}
                      fullWidth
                      disabled={name === 'departmentId'}
                    ></AutoFieldLocal>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextFieldLocal
                    required
                    name="amount"
                    label={words.amount}
                    register={register}
                    errors={errors}
                    row={row}
                    type="number"
                    fullWidth
                    mb={0}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutoFieldLocal
                    name="debitAcc"
                    title={isRTL ? 'حساب القبض' : 'Receipt Acc'}
                    words={words}
                    options={debaccounts}
                    value={debitAcc}
                    setSelectValue={setDebitAcc}
                    register={register}
                    isRTL={isRTL}
                    fullwidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
                <Grid item xs={4}>
                  <TextFieldLocal
                    name="chequeBank"
                    label={words.chequeBank}
                    register={register}
                    errors={errors}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldLocal
                    name="chequeNo"
                    label={words.chequeNo}
                    register={register}
                    errors={errors}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldLocal
                    name="chequeDate"
                    label={words.chequeDate}
                    register={register}
                    errors={errors}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldLocal
                    name="desc"
                    rows={4}
                    label={words.for}
                    register={register}
                    errors={errors}
                    row={row}
                    multiline
                    fullWidth
                    mb={0}
                  />
                </Grid>
              </Grid>
            </Grid>
            {tempoptions?.receiptattach && (
              <Grid item xs={4}>
                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    height: 40,
                    width: 200,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 5,
                  }}
                >
                  <UploadPhotos
                    setImages={addToPhotos}
                    isRTL={isRTL}
                  ></UploadPhotos>
                </Box>
                <Box
                  style={{
                    height: 300,
                    width: 200,
                    margin: 5,
                  }}
                >
                  <ImageView
                    images={urls}
                    removePhoto={removePhoto}
                    width={200}
                    height={300}
                  ></ImageView>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isMobile && (
          <Grid item md={12}>
            <PrintDownload
              company={company}
              printData={printData}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              isRTL={isRTL}
              template={template}
              no={row?.docNo}
              element="receipt"
            ></PrintDownload>
          </Grid>
        )}
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
      </Grid>
    </PopupLayout>
  );
};

export default PopupReceipt;
