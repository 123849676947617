/* eslint-disable jsx-a11y/alt-text */
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import React from 'react';
import { moneyFormat, simpleDateFormatter2 } from '../Shared/colorFormat';

const RH = 36;

const renderBox = (title: any, number = false, bold = false, height = RH) => {
  return (
    <Box
      border={1}
      borderColor="grey.300"
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: number ? 'flex-end' : 'flex-start',
        height,
        fontSize: height > 36 ? 24 : 16,
      }}
    >
      <Typography style={{ fontWeight: bold ? 'bold' : undefined }}>
        {title}
      </Typography>
    </Box>
  );
};

export class SalaryPrintFree extends React.PureComponent<any, any> {
  renderFooter = (data: any) => {
    const { row, isRTL } = data;
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Typography style={{ fontSize: 16 }}>
              {isRTL ? 'الاسم' : 'Name'}: {row.name}
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} style={{ height: 10 }}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Typography style={{ fontSize: 16 }}>
              {isRTL ? 'التوقيع' : 'Sign'}: ...............................
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Grid>
    );
  };
  renderHeader = (company: any) => (
    <Grid item xs={12}>
      <img
        src={company?.header}
        alt={company?.name}
        height="auto"
        width="100%"
        style={{
          objectFit: 'contain',
        }}
      />
      <Divider></Divider>
    </Grid>
  );

  renderTitle = (data: any) => {
    const { row, isRTL } = data;
    return (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography>
              {isRTL ? 'التاريخ' : 'Date'}: {simpleDateFormatter2(new Date())}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 40, marginTop: 20 }}>
            <Typography
              style={{ textAlign: 'center', fontFamily: 'sans-serif' }}
              variant="h3"
            >
              {isRTL ? 'سند استلام راتب' : 'SALARY RECEIPT'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {renderBox(isRTL ? 'الاسم' : 'Name', false, true, 50)}
          </Grid>
          <Grid item xs={9}>
            {renderBox(row.name, false, true, 50)}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderRow = (title: any, value: any, min = false, isRTL) => {
    if (!value) return <></>;
    return (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            {renderBox(title)}
          </Grid>
          {min === true && (
            <>
              <Grid item xs={3}>
                {renderBox(moneyFormat(value), true && !isRTL)}
              </Grid>
              <Grid item xs={3}>
                {renderBox('')}
              </Grid>
            </>
          )}
          {min === false && (
            <>
              <Grid item xs={3}>
                {renderBox('')}
              </Grid>
              <Grid item xs={3}>
                {renderBox(moneyFormat(value), true && !isRTL)}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  renderSalary = (data: any) => {
    const {
      basicSalary,
      extraSalary,
      absentCharge,
      overtimecharge,
      bonos,
      percentageSale,
      percentageWork,
      plus,
      minus,
      salary,
      isRTL,
      totalplus,
      totalminus,
    } = data;
    const now = new Date();
    const date = now.toLocaleString(isRTL ? 'ar-QA' : 'en-GB', {
      month: 'long',
      year: 'numeric',
    });
    let hi = 4;
    if (extraSalary) hi++;
    if (absentCharge) hi++;
    if (overtimecharge) hi++;
    if (bonos) hi++;
    if (percentageSale) hi++;
    if (percentageWork) hi++;
    if (plus?.value > 0) hi++;
    if (minus?.value > 0) hi++;

    const h = hi * RH;
    return (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            {renderBox(date, false, true, h)}
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                {renderBox(isRTL ? 'الراتب الاساسي' : 'Basic Salary')}
              </Grid>
              <Grid item xs={3}>
                {renderBox('')}
              </Grid>
              <Grid item xs={3}>
                {renderBox(moneyFormat(basicSalary), true && !isRTL)}
              </Grid>
              {this.renderRow(
                isRTL ? 'البدلات' : 'Allowances',
                extraSalary,
                false,
                isRTL
              )}
              {this.renderRow(
                isRTL ? 'المكافأت' : 'Bonos',
                bonos,
                false,
                isRTL
              )}
              {this.renderRow(
                isRTL ? 'ساعات اضافي' : 'Overtime',
                overtimecharge,
                false,
                isRTL
              )}
              {this.renderRow(
                isRTL ? 'نسبة على الدخل' : 'Income Percentage',
                percentageSale,
                false,
                isRTL
              )}
              {this.renderRow(
                isRTL ? 'نسبة على الانجاز' : 'Work Percentage',
                percentageWork,
                false,
                isRTL
              )}
              {this.renderRow(
                isRTL ? 'غياب' : 'Absent',
                absentCharge,
                true,
                isRTL
              )}
              {this.renderRow(plus?.title, plus?.value, false, isRTL)}
              {this.renderRow(minus?.title, minus?.value, true, isRTL)}
              <Grid item xs={12}>
                {renderBox('')}
              </Grid>
              <Grid item xs={6}>
                {renderBox(isRTL ? 'المجموع' : 'Total', false, true)}
              </Grid>
              <Grid item xs={3}>
                {renderBox(moneyFormat(totalminus), true && !isRTL, true)}
              </Grid>
              <Grid item xs={3}>
                {renderBox(moneyFormat(totalplus), true && !isRTL, true)}
              </Grid>
              <Grid item xs={6}>
                {renderBox(isRTL ? 'صافي الراتب' : 'Net Salary', false, true)}
              </Grid>
              <Grid item xs={3}>
                {renderBox('')}
              </Grid>
              <Grid item xs={3}>
                {renderBox(moneyFormat(salary), true && !isRTL, true)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  renderDivider = (x: number) => (
    <Grid item xs={12}>
      <Box m={x}></Box>
    </Grid>
  );
  render() {
    const { company, printData } = this.props;
    const { isRTL } = printData;
    return (
      <Box style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
        <Box>
          <Grid container spacing={0}>
            {this.renderHeader(company)}
            <Grid item xs={12}>
              <Box ml={8} mr={8}>
                <Grid container spacing={2}>
                  {this.renderDivider(1)}
                  {this.renderTitle(printData)}
                  {this.renderDivider(1)}
                  {this.renderSalary(printData)}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ position: 'absolute', bottom: 160, width: '100%' }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box ml={6} mr={6}>
                <Grid container spacing={2}>
                  {this.renderFooter(printData)}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}
