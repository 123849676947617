import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { POS_BACKGROUND } from '../MainPOS';

const FOOTER_HEIGHT = 260;

const renderBox = (value: any, number = false) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 10,
      }}
    >
      <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default function InvoiceBody({ height }) {
  return (
    <Box style={{ height }}>
      <Paper
        elevation={0}
        style={{
          height: height - FOOTER_HEIGHT,
          overflow: 'auto',
          // backgroundColor: 'red',
        }}
      ></Paper>
      <Box style={{ height: FOOTER_HEIGHT }}>
        <Box
          style={{
            height: FOOTER_HEIGHT - 40,
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flex: 1,
              height: FOOTER_HEIGHT - 100,
              backgroundColor: POS_BACKGROUND,
              margin: 20,
              marginTop: 0,
              width: '100%',
              borderRadius: 15,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={5}>
                {renderBox('Subtotal')}
              </Grid>
              <Grid item xs={5}>
                {renderBox('22.25', true)}
              </Grid>
              <Grid item xs={5}>
                {renderBox('Discount')}
              </Grid>
              <Grid item xs={5}>
                {renderBox('2.25', true)}
              </Grid>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={5}>
                {renderBox('Total')}
              </Grid>
              <Grid item xs={5}>
                {renderBox('20.00', true)}
              </Grid>
            </Grid>
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{
              height: 50,
              borderRadius: 15,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <Typography style={{ fontSize: 18 }}>Print Bill</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
