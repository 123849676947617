import { gql } from '@apollo/client';

export default gql`
  mutation deleteSpacesFile($path: String) {
    deleteSpacesFile(path: $path) {
      ok
      data
    }
  }
`;
