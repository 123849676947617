/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import TabsList from '../components/TabsList';
import useCompany from '../../hooks/useCompany';
import { getStoreItem, setStoreItem } from '../../store';
import {
  ExpensesContext,
  ExpensesprodContext,
  expensesprodReducer,
  expensesReducer,
  initExpensesContext,
  initExpensesprodContext,
} from '../../contexts';

import ExpensesDoc from './ExpensesDoc';
import ExpProducts from './ExpProducts';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export default function ExpensesOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { height, width } = useWindowDimensions();
  const { company } = useCompany();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const expensesref: any = useRef();
  const prodexpnref: any = useRef();

  // expenses
  const expensesStoreState = getStoreItem('expensesStore', initExpensesContext);
  const [expensesStore, expensesDispatch] = useReducer(
    expensesReducer,
    expensesStoreState ? expensesStoreState : initExpensesContext
  );
  useEffect(() => {
    setStoreItem('expensesStore', expensesStore);
  }, [expensesStore]);

  // expensesprod
  const expensesprodStoreState = getStoreItem(
    'expensesprodStore',
    initExpensesprodContext
  );
  const [expensesprodStore, expensesprodDispatch] = useReducer(
    expensesprodReducer,
    expensesprodStoreState ? expensesprodStoreState : initExpensesprodContext
  );
  useEffect(() => {
    setStoreItem('expensesprodStore', expensesprodStore);
  }, [expensesprodStore]);

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };

  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const refresh = () => {
    if (pathname?.includes('cash')) {
      expensesref?.current?.refresh();
    }
    if (pathname?.includes('product')) {
      prodexpnref?.current?.refresh();
    }
  };

  const bgcolor = '#EB413208';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
            marginTop: 5,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Paper
          elevation={5}
          style={{
            borderRadius: 25,
            margin: 25,
            marginTop: 5,
            width: width - drawerWidth - 50,
            height: height - 135,
          }}
        >
          <TabsList
            handleSelect={handleSelect}
            isRTL={isRTL}
            list={formgroup}
            theme={theme}
            width={width - drawerWidth - 50}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            pathname={pathname}
          ></TabsList>
          <Paper
            style={{
              display: 'flex',
              height: height - 185,
              overflow: 'auto',
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            <Routes>
              <Route
                index
                element={<Navigate to={formgroup?.[0]?.path} replace />}
              />
              <Route
                path={'cash'}
                element={
                  <ExpensesContext.Provider
                    value={{ state: expensesStore, dispatch: expensesDispatch }}
                  >
                    <ExpensesDoc
                      ref={expensesref}
                      start={start}
                      end={end}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></ExpensesDoc>
                  </ExpensesContext.Provider>
                }
              />
              <Route
                path={'product'}
                element={
                  <ExpensesprodContext.Provider
                    value={{
                      state: expensesprodStore,
                      dispatch: expensesprodDispatch,
                    }}
                  >
                    <ExpProducts
                      ref={prodexpnref}
                      start={start}
                      end={end}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></ExpProducts>
                  </ExpensesprodContext.Provider>
                }
              />
            </Routes>
          </Paper>
        </Paper>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </Box>
    </PageLayout>
  );
}
