/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { colors } from '../../constants';

import * as yup from 'yup';

import { PopupTextField } from '../../Shared';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectKeys from '../../Sections/generator/components/SelectKeys';

const calSchema = yup.object().shape({
  start: yup.number().required().min(0).max(23),
  end: yup.number().required().min(1).max(24),
  duration: yup.number().required().min(15).max(120),
});

const CalendarOptions = ({
  words,
  calendar,
  setCalendar,
  isRTL,
  isEmployee,
}: any) => {
  const [group, setGroup] = useState(calendar ? calendar?.group : false);
  const [todayColor, setTodayColor] = useState(calendar?.todayColor);
  const [dayColor, setDayColor] = useState(calendar?.dayColor);
  const [timeColor, setTimeColor] = useState(calendar?.timeColor);
  const [nameColor, setNameColor] = useState(calendar?.nameColor);
  const [eventColors, setEventColors] = useState(calendar?.eventColors);

  const [indicatorColor, setIndicatorColor] = useState(
    calendar?.indicatorColor
  );
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(calSchema),
    defaultValues: {
      start: calendar ? calendar?.start : 8.5,
      end: calendar ? calendar?.end : 21.5,
      duration: calendar ? calendar?.duration : 30,
      eventlen: calendar ? calendar?.eventlen : 1,
      todaySize: calendar?.todaySize,
      daySize: calendar?.daySize,
      timeSize: calendar?.timeSize,
      nameSize: calendar?.nameSize,
      todayColor: calendar?.todayColor,
      dayColor: calendar?.dayColor,
      timeColor: calendar?.timeColor,
      nameColor: calendar?.nameColor,
      indicatorColor: calendar?.indicatorColor,
    },
  });

  const onSubmit = (data: any) => {
    setCalendar({
      start: Number(data.start),
      end: Number(data.end),
      duration: Number(data.duration),
      eventlen: Number(data.eventlen),
      group,
      todaySize: Number(data.todaySize),
      daySize: Number(data.daySize),
      timeSize: Number(data.timeSize),
      nameSize: Number(data.nameSize),
      todayColor,
      dayColor,
      timeColor,
      nameColor,
      indicatorColor,
      eventColors,
    });
  };
  return (
    <Paper style={{ minHeight: 350, marginTop: 10 }}>
      <Box padding={3}>
        <Typography variant="h6">
          {isRTL ? 'تعديل التقويم' : 'Edit Calendar'}
        </Typography>
        <Box
          style={{
            marginTop: 20,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="start"
                label={words.start}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="end"
                label={words.end}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="duration"
                label={words.duration}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="eventlen"
                label={isRTL ? 'مدة الموعد' : 'Event Length'}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            {!isEmployee && (
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={group}
                      onChange={() => setGroup(!group)}
                      name="bold"
                      color="primary"
                    />
                  }
                  label={isRTL ? 'استعراض مجمع' : 'Grouped View'}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ marginBottom: -15 }}>
              <Typography variant="subtitle1">
                {isRTL ? 'اليوم' : 'Today'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="todaySize"
                label={isRTL ? 'الحجم' : 'Size'}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'اللون' : 'Color'}
                options={colors}
                value={todayColor}
                setValue={setTodayColor}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -15 }}>
              <Typography variant="subtitle1">
                {isRTL ? 'الايام' : 'Days'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="daySize"
                label={isRTL ? 'الحجم' : 'Size'}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'اللون' : 'Color'}
                options={colors}
                value={dayColor}
                setValue={setDayColor}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -15 }}>
              <Typography variant="subtitle1">
                {isRTL ? 'الوقت' : 'Time'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="timeSize"
                label={isRTL ? 'الحجم' : 'Size'}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'اللون' : 'Color'}
                options={colors}
                value={timeColor}
                setValue={setTimeColor}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -15 }}>
              <Typography variant="subtitle1">
                {isRTL ? 'الاسم' : 'Name'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                required
                name="nameSize"
                label={isRTL ? 'الحجم' : 'Size'}
                register={register}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'اللون' : 'Color'}
                options={colors}
                value={nameColor}
                setValue={setNameColor}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -15 }}>
              <Typography variant="subtitle1">
                {isRTL ? 'المؤشر' : 'Indicator'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'اللون' : 'Color'}
                options={colors}
                value={indicatorColor}
                setValue={setIndicatorColor}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: -15 }}>
              <Typography variant="subtitle1">
                {isRTL ? 'حالة الموعد' : 'Status'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'مؤكد' : 'Confirmed'}
                options={colors}
                value={eventColors?.[2]}
                setValue={(v: any) => setEventColors({ ...eventColors, 2: v })}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'ملغى' : 'Canceled'}
                options={colors}
                value={eventColors?.[4]}
                setValue={(v: any) => setEventColors({ ...eventColors, 4: v })}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'قيد الانجاز' : 'In Progress'}
                options={colors}
                value={eventColors?.[6]}
                setValue={(v: any) => setEventColors({ ...eventColors, 6: v })}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectKeys
                name={isRTL ? 'منجز' : 'Completed'}
                options={colors}
                value={eventColors?.[10]}
                setValue={(v: any) => setEventColors({ ...eventColors, 10: v })}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  height: 50,
                  marginRight: 50,
                }}
              >
                <Button
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                  variant="contained"
                  style={{
                    width: 90,
                    height: 34,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>{words.save}</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default CalendarOptions;
