import { Box, Button, Typography } from '@material-ui/core';

export default function CreateEmail({ isRTL, setOpen }: any) {
  return (
    <Box>
      <Box style={{ height: 50 }}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          style={{
            height: 44,
            marginTop: 24,
            marginLeft: 10,
            marginRight: 10,
            width: 180,
            borderRadius: 10,
          }}
          onClick={() => setOpen(true)}
        >
          <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
            {isRTL ? 'انشاء' : 'Compose'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
