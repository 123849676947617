/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  successAlert,
  dublicateAlert,
  errorAlert,
  messageAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { AlertLocal } from '../components';
import { printTamplateTypes } from '../constants/datatypes';
import AddIcon from '@material-ui/icons/Add';
import PrintBuilder from '../Sections/generator/print/PrintBuilder';
import SelectKeys from '../Sections/generator/components/SelectKeys';

const PopupPrintTemplate = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
  company,
  branch,
}: any) => {
  const [temp, setTemp] = useState([]);
  const [name, setName] = useState(null);
  const [header, setHeader] = useState(true);
  const [footer, setFooter] = useState(true);
  const [isdefault, setIsdefault] = useState(true);
  const [image, setImage] = useState(null);
  const [bgimages, setBgimages] = useState([]);
  const [size, setSize] = useState('A4');
  const [type, setType] = useState('invoice');

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const {
    translate: { isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  useEffect(() => {
    if (row?._id) {
      setName(row?.name);
      setIsdefault(row?.isdefault);
      if (row?.bgimages) setBgimages(JSON.parse(row?.bgimages));
      setHeader(row?.header);
      setFooter(row?.footer);
      if (row?.size) setSize(row?.size);
      if (row?.type) setType(row?.type);
      if (row?.template) {
        const tem = JSON.parse(row?.template);
        setTemp(tem);
      }
    }
  }, [row]);

  const pushImage = () => {
    if (image !== null) {
      const nimages = [...bgimages, image];
      setBgimages(nimages);
    }
    setImage(null);
  };

  const onSubmit = async (data: any) => {
    if (!size || !type || !name) {
      await messageAlert(
        setAlrt,
        isRTL
          ? 'يجب تحديد الاسم والنوع والقياس'
          : 'Name, Type and Size are required'
      );
      return;
    }

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      name,
      template: JSON.stringify(temp),
      type,
      size,
      header,
      footer,
      isdefault,
      bgimages: JSON.stringify(bgimages),
      branch,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      await successAlert(setAlrt, isRTL);
      closeModal();
    } catch (error) {
      onError(error);
    }
  };

  const closeModal = () => {
    onClose();
    setTemp([]);
    setName(null);
    setHeader(true);
    setIsdefault(true);
    setSize('A4');
    setType('invoice');
    setBgimages([]);
    setImage(null);
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const title = isNew ? 'New Print Template' : 'Edit Print Template';
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onSubmit}
      theme={theme}
      maxWidth="xl"
      alrt={{ show: false, msg: '', type: undefined }}
      mb={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            name="name"
            label="Name"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={2}>
          <SelectKeys
            name="Template Type"
            options={printTamplateTypes}
            setValue={(value: any) => setType(value)}
            value={type}
          ></SelectKeys>
        </Grid>
        <Grid item xs={2}>
          <SelectKeys
            name="Paper Size"
            options={[
              'A4',
              'A4/2',
              'A4 landscape',
              'A5',
              'A5 landscape',
              'POS',
            ]}
            setValue={(value: any) => setSize(value)}
            value={size}
          ></SelectKeys>
        </Grid>
        <Grid item xs={3}>
          <Box display={'flex'}>
            <TextField
              name="image"
              label="BG Image"
              value={image}
              onChange={(e: any) => setImage(e.target.value)}
              fullWidth
              variant="outlined"
            />
            <IconButton onClick={pushImage}>
              <AddIcon></AddIcon>
            </IconButton>
            {bgimages && bgimages?.length > 0 && (
              <Box style={{ position: 'absolute', top: 50, display: 'flex' }}>
                {bgimages.map((bgi: any, i: any) => {
                  return (
                    <Box
                      style={{
                        display: 'flex',
                        width: 20,
                        height: 20,
                        backgroundColor: '#ddd',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      title={bgi}
                      onClick={() => {
                        const newarr = bgimages.filter((bg: any) => bg !== bgi);
                        setBgimages(newarr);
                        setImage(null);
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {i + 1}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={header}
                onChange={() => setHeader(!header)}
                name="bold"
                color="primary"
              />
            }
            label="Header"
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={footer}
                onChange={() => setFooter(!footer)}
                name="bold"
                color="primary"
              />
            }
            label="Footer"
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isdefault}
                onChange={() => setIsdefault(!isdefault)}
                name="bold"
                color="primary"
              />
            }
            label="Default"
          />
        </Grid>
        <Grid item xs={12}>
          <PrintBuilder
            rows={temp}
            setRows={setTemp}
            bgimages={bgimages}
            setBgimages={setBgimages}
            company={company}
            size={size}
            header={header}
            footer={footer}
            type={type}
            isRTL={isRTL}
          ></PrintBuilder>
        </Grid>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
          ></AlertLocal>
        )}
      </Grid>
    </PopupLayout>
  );
};

export default PopupPrintTemplate;
