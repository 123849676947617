/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
  TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, updateOpDocRefNumbers } from '../../common';
import {
  calculateAmount,
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatter,
  moneyFormat,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  fade,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FinanceReportPrint } from '../../print/FinanceReportPrint';
import { useReactToPrint } from 'react-to-print';
import {
  useExpenseItems,
  useProducts,
  useServices,
  useTemplate,
} from '../../hooks';
import { SearchTable } from '../../components';
import { ProfitabilityContext } from '../../contexts/managment';
import getKaidsReport from '../../graphql/query/getKaidsReport';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useTasks from '../../hooks/useTasks';
import {
  getDefaultTemplate,
  getEmployeeResourseTypes,
} from '../../common/helpers';
import _ from 'lodash';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';
import { itemTypes } from '../../constants/datatypes';
import { documentTypes, groupList } from '../../constants/reports';
import usePrintTemplates from '../../hooks/usePrintTemplates';
import PrintDownload from '../../Shared/PrintDownload';
import { ReportGroupBySwitcher } from '../components/ReportGroupBySwitcher';

const styles = (theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function ProfitabilityReport({
  isRTL,
  words,
  start,
  end,
  accounts,
  company,
  theme,
  drawerWidth,
}: any) {
  const [rows, setRows] = useState([]);
  const [isRaseed, setIsRaseed] = useState(true);

  const col = getColumns({ isRTL, words });
  const { tempoptions } = useTemplate();
  const { printtemplates } = usePrintTemplates();

  const [columns] = useState(
    [
      col.opTime,
      col.opType,
      col.opDocNo,
      col.refNo,
      col.acc,
      col.opAcc,
      col.item,
      { name: 'qty', title: isRTL ? 'الكمية' : 'Quantity' },
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      tempoptions?.contract && col.contract,
      col.customer,
      col.emptype,
      col.restype,
      col.amount,
      col.amountdebit,
      col.amountcredit,
      col.rased,
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getKaidsReport, {
    fetchPolicy: 'cache-and-network',
  });

  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { tasks } = useTasks();
  const { services } = useServices();
  const { products } = useProducts();
  const { expenseItems } = useExpenseItems();

  const [items, setItems] = React.useState(services);

  const {
    state: {
      optypevalue,
      itemtypevalue,
      itemvalue,
      emplvalue,
      resovalue,
      taskvalue,
      accvalue,
      pcodevalue,
      group,
      groupby,
      sumcolumn,
      sort,
    },
    dispatch,
  } = useContext(ProfitabilityContext);
  const { height } = useWindowDimensions();

  useEffect(() => {
    let itms = [];
    const itty = itemtypevalue.map((a: any) => a.value);
    if (itty && itty.length > 0) {
      if (itty.includes(1)) itms = [...itms, ...products];
      if (itty.includes(2)) itms = [...itms, ...services];
      if (itty.includes(10)) itms = [...itms, ...expenseItems];
    }
    setItems(itms);
  }, [itemtypevalue]);

  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setTaskvalueDispatch = (value: any) => {
    dispatch({ type: 'setTaskvalue', payload: value });
  };
  const setItemtypevalueDispatch = (value: any) => {
    dispatch({ type: 'setItemtypevalue', payload: value });
  };
  const setItemvalueDispatch = (value: any) => {
    dispatch({ type: 'setItemvalue', payload: value });
  };

  const setGroupbyDispatch = (value: any) => {
    dispatch({ type: 'setGroupby', payload: value });
  };
  const setGroupDispatch = (value: any) => {
    dispatch({ type: 'setGroup', payload: value });
  };
  const setSumcolumnDispatch = (value: any) => {
    dispatch({ type: 'setSumcolumn', payload: value });
  };
  const setAccountvalueDispatch = (value: any) => {
    dispatch({ type: 'setAccvalue', payload: value });
  };
  const setOptypevalueDispatch = (value: any) => {
    dispatch({ type: 'setOptypevalue', payload: value });
  };

  const onSwitcherChange = (e: any) => {
    if (e.target.value === 'none') {
      setGroupDispatch(false);
      setGroupbyDispatch('none');
    } else {
      setGroupDispatch(true);
      setGroupbyDispatch(e.target.value);
      setSumcolumnDispatch(col[e.target.value].name);
    }
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getKaidsReport']?.data || [];
    const balance = summaryData?.data?.['getKaidsReport']?.message || null;
    const updatedRows = slsData.map((x: any) => x);
    const amount = isRaseed && balance ? JSON.parse(balance) : null;

    if (amount !== null) {
      const { credit, debit } = amount;

      if (credit || debit) {
        const am = debit - credit;
        updatedRows.unshift({
          _id: Date.now(),
          opTime: start,
          opType: 94,
          debit,
          credit,
          amount: am,
          qty: 0,
        });
      }
    }

    let rased = 0;
    const updatedRows2 =
      updatedRows?.length > 0
        ? updatedRows.map((item: any) => {
            const { emptype, restype } = getEmployeeResourseTypes({
              item,
              employees,
              resourses,
            });
            const rowRased = item.debit ? item.debit : -item.credit;
            rased = rased + rowRased;
            return {
              ...item,
              amount: calculateAmount(item),
              rased,
              ...emptype,
              ...restype,
            };
          })
        : [];

    const rdata = updateOpDocRefNumbers(updatedRows2);
    if (group) {
      const grdata = _(rdata)
        .groupBy('opDocNo')
        .map((array) => ({
          ...array[0],
          amount: _.sumBy(array, 'amount'),
          credit: _.sumBy(array, 'credit'),
          debit: _.sumBy(array, 'debit'),
        }))
        .value();
      setRows(grdata);
    } else {
      setRows(rdata);
    }
  }, [summaryData, isRaseed, group]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;

  const getValues = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv.value) : undefined;
  const deaccounts = accounts.filter((ac: any) =>
    accvalue && accvalue.length > 0
      ? accvalue.map((av: any) => av.code).includes(ac.code)
      : [4000, 5000, 6000].includes(ac.code)
  );
  const fetchData = () => {
    const variables = {
      opTypes: getValues(optypevalue),
      itemTypes: getValues(itemtypevalue),
      itemIds: getIds(itemvalue),
      accountIds: getIds(deaccounts),
      contractIds: getIds(taskvalue),
      employeeIds: getIds(emplvalue),
      resourseIds: getIds(resovalue),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };

    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    optypevalue,
    itemtypevalue,
    itemvalue,
    taskvalue,
    emplvalue,
    resovalue,
    accvalue,
    pcodevalue,
    group,
    groupby,
    sumcolumn,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `finance-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'credit', type: 'sum' },
    { columnName: 'debit', type: 'sum' },
    { columnName: 'qty', type: 'sum' },
  ];

  const grouping = [{ columnName: sumcolumn }];
  const groupSummaryItems = [
    {
      columnName: 'credit',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: 'debit',
      type: 'sum',
      alignByColumn: true,
    },
  ];

  const componentRef: any = useRef();
  const template = getDefaultTemplate(printtemplates, 'profitreport');

  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Profitability Report`,
    removeAfterPrint: true,
  });

  const printData = {
    start,
    end,
    items: rows,
    rased: rows?.[rows?.length - 1]?.rased,
    isRTL,
    date: new Date(),
  };

  const disgroup = [];
  if (!tempoptions?.project) disgroup.push(10);
  if (!tempoptions?.contract) disgroup.push(8);
  if (!tempoptions?.resourse) disgroup.push(11);
  if (!tempoptions?.department) disgroup.push(3);
  disgroup.push(5, 5.5, 6, 7, 9, 12, 13);
  const groupOptions = groupList(isRTL).filter(
    (item: any) => !disgroup.includes(item.id)
  );

  return (
    <>
      {(accvalue?.length > 0 || pcodevalue?.length > 0) && (
        <Box
          style={{
            position: 'absolute',
            left: isRTL ? 25 : undefined,
            right: isRTL ? undefined : 25,
            top: 47,
            zIndex: 112,
          }}
        >
          <PrintDownload
            company={company}
            printData={printData}
            componentRef={componentRef}
            handleReactPrint={handleReactPrint}
            isRTL={isRTL}
            template={template}
            no={'Profitability Report'}
            element="profitreport"
          ></PrintDownload>
        </Box>
      )}
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 57,
          left: 300,
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <FilterSelectMulti
          options={accounts.filter((ac: any) =>
            [4000, 5000, 6000].includes(ac.code)
          )}
          value={accvalue}
          setValue={setAccountvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="account"
          label={isRTL ? 'الحسابات' : 'Accounts'}
          width={250}
        ></FilterSelectMulti>
        <FilterSelectMulti
          options={itemTypes}
          value={itemtypevalue}
          setValue={setItemtypevalueDispatch}
          words={words}
          isRTL={isRTL}
          name="itemtype"
          label={isRTL ? 'نوع البند' : 'Item Type'}
          width={180}
        ></FilterSelectMulti>
        <FilterSelectMulti
          options={items}
          value={itemvalue}
          setValue={setItemvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="product"
          label={isRTL ? 'البنود' : 'Items'}
          width={240}
        ></FilterSelectMulti>

        <Box style={{ marginLeft: 10, marginRight: 10 }}>
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: 7 }}
                checked={isRaseed}
                onChange={() => setIsRaseed(!isRaseed)}
                color="primary"
              />
            }
            label={
              <Typography
                style={{ color: theme.palette.primary.main }}
                variant="subtitle2"
              >
                {isRTL ? 'رصيد افتتاحي' : 'Opening Balance'}
              </Typography>
            }
            style={{ fontSize: 14 }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 5,
          left: 400,
        }}
      >
        {tempoptions?.contract && tasks && tasks.length > 0 && (
          <FilterSelectMulti
            options={tasks}
            value={taskvalue}
            setValue={setTaskvalueDispatch}
            words={words}
            isRTL={isRTL}
            name="task"
            width={240}
          ></FilterSelectMulti>
        )}
        <Box style={{ width: 10 }}></Box>
        <FilterSelectMulti
          options={employees}
          value={emplvalue}
          setValue={setEmplvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="employee"
          width={240}
          fullwidth
        ></FilterSelectMulti>
        <Box style={{ width: 10 }}></Box>

        <FilterSelectMulti
          options={documentTypes}
          value={optypevalue}
          setValue={setOptypevalueDispatch}
          words={words}
          isRTL={isRTL}
          name="documenttype"
          label={isRTL ? 'نوع المستند' : 'Document Types'}
          width={200}
          fullwidth
        ></FilterSelectMulti>
        <Box style={{ width: 10 }}></Box>

        <Box
          style={{
            marginTop: -7,
            width: 200,
          }}
        >
          <ReportGroupBySwitcher
            options={groupOptions}
            value={groupby}
            onChange={onSwitcherChange}
            isRTL={isRTL}
          ></ReportGroupBySwitcher>
        </Box>
      </Box>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 225,
          right: isRTL ? drawerWidth + 225 : undefined,
          bottom: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: '#403795' }}>
          {isRTL ? 'الرصيد' : 'Balance'}:{' '}
          {moneyFormat(rows?.[rows?.length - 1]?.rased)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        {group && <GroupingState grouping={grouping} />}
        {group && (
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
        )}
        {group && <IntegratedGrouping />}
        {group && <IntegratedSummary />}
        <IntegratedSorting />
        <SearchState />
        <IntegratedFiltering />
        <VirtualTable
          height={height - 120}
          tableComponent={TableComponent}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={30}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[
            col.amount.name,
            col.rased.name,
            col.item.name,
            'qty',
            col.contract.name,
            col.customer.name,
            col.supplier.name,
            col.employee.name,
            col.resourse.name,
            col.department.name,
            col.refNo.name,
          ]}
        />

        <DataTypeProvider
          for={['opTime']}
          formatterComponent={createdAtFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['credit', 'debit', 'rased']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['opType']}
          formatterComponent={opTypeFormatter}
        ></DataTypeProvider>
        <Toolbar />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        {group && (
          <TableGroupRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
              sumOf: isRTL ? 'المجموع' : 'Total',
              countOf: isRTL ? 'العدد' : 'Count',
            }}
            showColumnsWhenGrouped
          />
        )}
        {group && (
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        )}
      </Grid>

      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
      <Box>
        <div style={{ display: 'none' }}>
          <FinanceReportPrint
            company={company}
            items={rows}
            columns={columns}
            ref={componentRef}
            isRTL={isRTL}
            account={accvalue?.[0]}
            start={start}
            end={end}
          />
        </div>
      </Box>
    </>
  );
}
