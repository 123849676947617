/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  PagingPanel,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { downloadCSVFile, getRowId, LinkPhone, userroles } from '../../common';
import { PopupEmployee } from '../../pubups';
import {
  avataManageFormatter,
  linkFormatter,
  nameManageEmployeeRest,
  nameManageLinkEmployee,
} from '../../Shared/colorFormat';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import PageLayout from '../main/PageLayout';
import { getColumns } from '../../common/columns';
import PopupEmployeeView from '../../pubups/PopupEmployeeView';
import useEmployees from '../../hooks/useEmployees';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { TableComponent } from '../../Shared/TableComponent';
import { EmployeeContext } from '../../contexts/managment';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getEmployees } from '../../graphql';
import AutoFieldLocal from '../../components/fields/AutoFieldLocal';
import useRetypes from '../../hooks/useRetypes';
import { useTemplate } from '../../hooks';
import ImportBtn from '../../common/ImportBtn';
import ExportBtn from '../../common/ExportBtn';
import PopupEmployeeImport from '../../pubups/PopupEmployeeImport';
import Whatsapp from '../../common/Whatsapp';
import { deleteMultiEmployees } from '../../graphql/mutation';
import ButtonShort from '../../common/ButtonShort';

export default function ManageEmployees({
  isRTL,
  words,
  theme,
  menuitem,
  company,
}: any) {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [rows, setRows] = useState([]);
  const [rtypvalue, setRtypvalue] = useState<any>(null);
  const [openImport, setOpenImport] = useState(false);
  const [selection, setSelection] = useState([]);
  const [adding, setAdding] = useState(false);
  const [selected, setSelected] = useState(null);

  const [teamfilter, setTeamfilter] = useState(false);
  const [astafffilter, setAstafffilter] = useState(false);
  const [wstafffilter, setWstafffilter] = useState(false);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);
  const col = getColumns({ isRTL, words });
  const { tempwords, tempoptions, employeeoptions } = useTemplate();

  const { retypes } = useRetypes();
  const { width, height } = useWindowDimensions();
  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(EmployeeContext);
  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const [columns] = useState(
    [
      { name: 'avatar', title: ' ' },
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      col.name,
      userroles?.manageemployees?.admin && col.data,
      { name: 'phone', title: words.phoneNumber },
      employeeoptions?.email && { name: 'email', title: words.email },
      employeeoptions?.telHome && col.telHome,
      employeeoptions?.speciality && col.speciality,
      employeeoptions?.licenseNo && col.licenseNo,
      employeeoptions?.licenseDate && col.licenseDate,
      employeeoptions?.national && col.national,
      employeeoptions?.nationalNo && col.nationalNo,
      employeeoptions?.nationalDate && col.nationalDate,
      employeeoptions?.workId && col.workId,
      employeeoptions?.comPercent && col.comPercent,
      tempoptions?.shortemployee && {
        name: 'link',
        title: isRTL ? 'رابط' : 'Link',
      },
      employeeoptions?.info && { name: 'info', title: words.info },
      employeeoptions?.retype && tempoptions?.retype && col.retype,
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 100 },
    { columnName: 'additionId', width: 120 },
    { columnName: col.name.name, width: 300 },
    { columnName: col.data.name, width: 300 },
    { columnName: 'phone', width: 180 },
    { columnName: 'email', width: 200 },
    { columnName: 'speciality', width: 120 },
    { columnName: col.telHome.name, width: 150 },
    { columnName: col.licenseNo.name, width: 150 },
    { columnName: col.licenseDate.name, width: 150 },
    { columnName: col.national.name, width: 150 },
    { columnName: col.nationalNo.name, width: 150 },
    { columnName: col.nationalDate.name, width: 150 },
    { columnName: col.workId.name, width: 150 },
    { columnName: 'comPercent', width: 100 },
    { columnName: 'info', width: 200 },
    { columnName: 'link', width: 100, align: 'center' },
    { columnName: col.retype.name, width: 150 },
  ]);

  const {
    addEmployee,
    addEmployeeLink,
    addMultiEmployees,
    editEmployee,
    removeEmployee,
    refreshemployee,
  } = useEmployees();

  const [getemps, empData]: any = useLazyQuery(getEmployees, {
    fetchPolicy: 'cache-and-network',
  });
  const refresQuery = {
    refetchQueries: [{ query: getEmployees }],
  };
  const [removeMultiEmployees] = useMutation(deleteMultiEmployees, refresQuery);

  const deleteSelected = async () => {
    if (window.confirm('Are you sure you want to delete rows?')) {
      await removeMultiEmployees({ variables: { ids: selection } });
      setSelection([]);
    }
  };

  useEffect(() => {
    getemps({});
  }, [rtypvalue]);

  useEffect(() => {
    if (empData?.data?.getEmployees?.data) {
      const { data } = empData.data.getEmployees;
      let fdata = data;
      if (rtypvalue) {
        fdata = data.filter((da: any) => da.retypeId === rtypvalue._id);
      }
      if (teamfilter) {
        fdata = fdata.filter((da: any) => da.isTeam);
      }
      if (astafffilter) {
        fdata = fdata.filter((da: any) => da.isAdminstaff);
      }
      if (wstafffilter) {
        fdata = fdata.filter((da: any) => da.isWorkstaff);
      }
      setRows(fdata);
    }
  }, [empData, rtypvalue, teamfilter, astafffilter, wstafffilter]);

  useEffect(() => {
    if (openItem) {
      if (rows && rows.length > 0) {
        const opened = rows.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
  }, [rows]);

  const addLink = async (id: any) => {
    setAdding(true);
    setSelected(id);
    try {
      await addEmployeeLink({ variables: { id } });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
      setAdding(false);
    }
    setSelected(null);
    setAdding(false);
  };

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removeEmployee({ variables: { _id } });
      if (res?.data?.deleteEmployee?.ok === false) {
        if (res?.data?.deleteEmployee?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'employees', colnames);
  };

  const bgcolor = '#E9F8FEaa';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refreshemployee}
      bgcolor={bgcolor}
      loading={empData?.loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: bgcolor,
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
        {userroles?.customers?.admin && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: isRTL ? 400 : undefined,
              right: isRTL ? undefined : 400,
              display: 'flex',
              margin: 5,
              padding: 5,
              marginBottom: -5,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {selection?.length > 0 && (
              <Typography>{selection?.length}</Typography>
            )}
            <Button
              color="secondary"
              variant="contained"
              disabled={selection?.length === 0}
              onClick={deleteSelected}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <Typography>
                {isRTL ? 'حذف المحدد' : 'Delete Selected'}
              </Typography>
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          style={{
            position: 'absolute',
            zIndex: 111,
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 7,
            left: isRTL ? undefined : 470,
            right: isRTL ? 470 : undefined,
            width: 220,
          }}
        >
          <AutoFieldLocal
            name="retype"
            title={tempwords?.retype}
            words={words}
            options={retypes.filter((d: any) => d.reType === 1)}
            value={rtypvalue}
            setSelectValue={setRtypvalue}
            isRTL={isRTL}
            width={200}
            fullWidth
            mb={5}
          ></AutoFieldLocal>
          <Box style={{ display: 'flex', marginLeft: 50, marginRight: 50 }}>
            <Button
              color="primary"
              variant={teamfilter ? 'contained' : 'outlined'}
              onClick={() => {
                setTeamfilter(!teamfilter);
                setAstafffilter(false);
                setWstafffilter(false);
              }}
              style={{ margin: 10, padding: 5, minWidth: 150 }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {isRTL ? 'الفرق' : 'Teams'}
              </Typography>
            </Button>
            <Button
              color="primary"
              variant={astafffilter ? 'contained' : 'outlined'}
              onClick={() => {
                setAstafffilter(!astafffilter);
                setTeamfilter(false);
                setWstafffilter(false);
              }}
              style={{ margin: 10, padding: 5, minWidth: 150 }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {isRTL ? 'الاداريين' : 'Admin Staff'}
              </Typography>
            </Button>
            <Button
              color="primary"
              variant={wstafffilter ? 'contained' : 'outlined'}
              onClick={() => {
                setWstafffilter(!wstafffilter);
                setTeamfilter(false);
                setAstafffilter(false);
              }}
              style={{ margin: 10, padding: 5, minWidth: 150 }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {isRTL ? 'التشغيلين' : 'Work Staff'}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Paper
          elevation={5}
          style={{
            marginTop: 10,
            marginLeft: 40,
            marginRight: 40,
            marginBottom: 20,
            overflow: 'auto',
            width: width - 320,
            borderRadius: 10,
          }}
        >
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SearchState />
            <PagingState defaultCurrentPage={0} defaultPageSize={8} />
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <IntegratedSelection />
            <DragDropProvider />

            <Table
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              tableComponent={TableComponent}
              rowComponent={(props: any) => (
                <Table.Row {...props} style={{ height: 80 }}></Table.Row>
              )}
              columnExtensions={tableColumnExtensions}
            />
            <TableColumnReordering
              defaultOrder={[
                'avatar',
                col.name.name,
                col.workId.name,
                col.data.name,
                'phone',
                'email',
                'speciality',
                col.telHome.name,
                col.nationalNo.name,
                col.nationalDate.name,
                col.national.name,
                col.licenseNo.name,
                col.licenseDate.name,
                'comPercent',
                col.retype.name,
                'info',
                'link',
              ]}
            />
            <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />
            <TableColumnVisibility
              defaultHiddenColumnNames={hiddenColumnNames}
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />

            {userroles?.manageemployees?.admin && (
              <DataTypeProvider
                for={['avatar']}
                formatterComponent={(props: any) =>
                  avataManageFormatter({
                    ...props,
                    setItem,
                    setOpenItem,
                    isRTL,
                    height: 70,
                  })
                }
              ></DataTypeProvider>
            )}
            {userroles?.manageemployees?.admin ? (
              <DataTypeProvider
                for={['link']}
                formatterComponent={(props: any) => (
                  <ButtonShort
                    {...props}
                    addLink={addLink}
                    isRTL={isRTL}
                    loading={adding}
                    selected={selected}
                  ></ButtonShort>
                )}
              ></DataTypeProvider>
            ) : (
              <DataTypeProvider
                for={['link']}
                formatterComponent={linkFormatter}
              ></DataTypeProvider>
            )}
            <DataTypeProvider
              for={['phone']}
              formatterComponent={({ value }) => (
                <Box display={'flex'}>
                  {value && <Whatsapp phone={value}></Whatsapp>}
                  {value && <LinkPhone value={value}></LinkPhone>}
                </Box>
              )}
            ></DataTypeProvider>
            {userroles?.manageemployees?.admin && (
              <DataTypeProvider
                for={[col.name.name]}
                formatterComponent={(props: any) =>
                  nameManageLinkEmployee({
                    ...props,
                    setItem,
                    setOpenItem,
                    isRTL,
                  })
                }
              ></DataTypeProvider>
            )}
            {userroles?.manageemployees?.admin && (
              <DataTypeProvider
                for={[col.data.name]}
                formatterComponent={(props: any) =>
                  nameManageEmployeeRest({
                    ...props,
                    isRTL,
                  })
                }
              ></DataTypeProvider>
            )}

            <TableEditColumn
              showEditCommand={userroles?.manageemployees?.edit}
              showDeleteCommand={userroles?.manageemployees?.delete}
              showAddCommand={userroles?.manageemployees?.add}
              commandComponent={Command}
            ></TableEditColumn>
            <Toolbar />
            <ColumnChooser />
            <PagingPanel
              pageSizes={
                tempoptions?.pageall
                  ? [5, 8, 10, 20, 50, 0]
                  : [5, 8, 10, 20, 50]
              }
            />
            <TableSelection showSelectAll />
            <SearchPanel
              inputComponent={(props: any) => {
                return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
              }}
            />

            <PopupEditing
              theme={theme}
              addAction={addEmployee}
              editAction={editEmployee}
            >
              <PopupEmployee></PopupEmployee>
            </PopupEditing>
          </Grid>
        </Paper>
        {alrt.show && (
          <AlertLocal
            isRTL={isRTL}
            type={alrt?.type}
            msg={alrt?.msg}
            top
          ></AlertLocal>
        )}
        <PopupEmployeeView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          company={company}
        ></PopupEmployeeView>
        <PopupEmployeeImport
          open={openImport}
          onClose={() => setOpenImport(false)}
          addMultiItems={addMultiEmployees}
          isRTL={isRTL}
          theme={theme}
          words={words}
        ></PopupEmployeeImport>
      </Box>
    </PageLayout>
  );
}
