/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { invoiceClasses } from '../themes';
import { useCustomers, useProducts, useTemplate } from '../hooks';
import { dublicateAlert, errorAlert, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import ServiceItemForm from '../Shared/ServiceItemForm';
import ItemsTable from '../Shared/ItemsTable';
import { PriceTotal } from '../Shared/TotalPrice';
import { operationTypes } from '../constants';
import { accountCode, parents } from '../constants/kaid';
import PaymentSelect from '../pages/options/PaymentSelect';
import PopupCustomer from './PopupCustomer';
import { useLazyQuery } from '@apollo/client';
import { getActions, getOperationItems } from '../graphql';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal } from '../components';
import { useReactToPrint } from 'react-to-print';
import { weekdaysNNo } from '../constants/datatypes';
import useTasks from '../hooks/useTasks';
import useCompany from '../hooks/useCompany';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import PopupResourses from './PopupResourses';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import { successAlert } from '../Shared/helpers';
import useAccounts from '../hooks/useAccounts';
import usePrintTemplates from '../hooks/usePrintTemplates';
import { getDefaultTemplate, tafkeet } from '../common/helpers';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import useWindowDimensions from '../hooks/useWindowDimensions';
import PrintDownload from '../Shared/PrintDownload';
import { userroles } from '../common';
import PopupIlisttemsImport from './PopupIlisttemsImport';
import ImportBtn from '../common/ImportBtn';
import {
  actionTypeFormatter,
  getDateDayTimeFormat,
  moneyFormat,
} from '../Shared/colorFormat';
import PopupAction from './PopupAction';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

export const indexTheListFullname = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
      no: index + 1,
      fullname: `${item?.nameAr}
${item?.name}`,
    };
  });
};

const PopupInvoice = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  resourses,
  employees,
  departments,
  servicesproducts,
  theme,
  task,
  value,
  name,
}: any) => {
  const classes = invoiceClasses();
  const [saving, setSaving] = useState(false);
  const { tasks } = useTasks();
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [invNo, setInvNo] = useState<any>('');

  const [itemsList, setItemsList] = useState<any>([]);
  const [accounts, setAccounts] = useState<any>([]);

  const [actionslist, setActionslist] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [selected, setSelected] = useState(null);

  const [discount, setDiscount] = useState(0);
  const [desc, setDesc] = useState(null);
  const [tip, setTip] = useState(0);
  const [totals, setTotals] = useState<any>({});
  const { accounts: mainAccounts } = useAccounts();
  const { company } = useCompany();

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();

  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [custvalue, setCustvalue] = useState<any>(
    name === 'customerId' ? value : null
  );
  const [taskvalue, setTaskvalue] = useState<any>(
    name === 'contractId' ? value : null
  );
  const { tempwords, tempoptions } = useTemplate();
  const [isCash, setIsCash] = useState(tempoptions?.cashinvoice);

  const [paid, setPaid] = useState<any>(0);
  const [debitAcc, setDebitAcc]: any = React.useState(null);

  const [paid2, setPaid2] = useState<any>(0);
  const [debitAcc2, setDebitAcc2]: any = React.useState(null);

  const [newtext, setNewtext] = useState('');

  const [percent, setPercent] = useState<any>(0);
  const [ispercent, setIspercent] = useState<any>(0);

  const [openCust, setOpenCust] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const [openImport, setOpenImport] = useState(false);

  const { customers, addCustomer, editCustomer } = useCustomers();
  const { addDepartment, editDepartment } = useDepartments();
  const { addEmployee, editEmployee } = useEmployees();
  const { addResourse, editResourse } = useResourses();
  const { isMobile } = useWindowDimensions();

  const { products } = useProducts();
  const { printtemplates } = usePrintTemplates();
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const isemployee = user?.isEmployee && user?.employeeId;
  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadActions, actionsData]: any = useLazyQuery(getActions, {
    fetchPolicy: 'cache-and-network',
  });
  const openDepartment = () => {
    setOpenDep(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };
  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };

  const onNewCustChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };
  const onNewDepartChange = (nextValue: any) => {
    setDepartvalue(nextValue);
  };
  const onNewEmplChange = (nextValue: any) => {
    setEmplvalue(nextValue);
  };
  const onNewResoChange = (nextValue: any) => {
    setResovalue(nextValue);
  };

  const addActionToList = (item: any) => {
    const newArray = [...actionslist, item];
    const listwithindex = indexTheList(newArray);
    setActionslist(listwithindex);
  };
  const editActionInList = (item: any) => {
    const newArray = actionslist.map((it: any) => {
      if (item._id) {
        if (it._id === item._id) {
          return item;
        } else {
          return it;
        }
      } else {
        if (it.index === item.index) {
          return item;
        } else {
          return it;
        }
      }
    });
    const listwithindex = indexTheList(newArray);
    setActionslist(listwithindex);
  };
  const removeActionFromList = (item: any) => {
    const newlist = actionslist.filter((il: any) => il.index !== item.index);
    const listwithindex = indexTheList(newlist);
    setActionslist(listwithindex);
  };

  useEffect(() => {
    if (isemployee) {
      const emp = employees.filter(
        (em: any) => em._id === user.employeeId
      )?.[0];
      setEmplvalue(emp);
    }
  }, [user, employees, open]);

  useEffect(() => {
    if (isNew && !isemployee) {
      if (taskvalue) {
        if (taskvalue?.departmentId && name !== 'departmentId') {
          const dept = departments.filter(
            (dep: any) => dep._id === taskvalue?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (taskvalue?.employeeId && name !== 'employeeId') {
          const emp = employees.filter(
            (dep: any) => dep._id === taskvalue?.employeeId
          )?.[0];
          setEmplvalue(emp);
        }
        if (taskvalue?.resourseId && name !== 'resourseId') {
          const res = resourses.filter(
            (dep: any) => dep._id === taskvalue?.resourseId
          )?.[0];
          setResovalue(res);
        }
        if (taskvalue?.customerId && name !== 'customerId') {
          const cust = customers.filter(
            (dep: any) => dep._id === taskvalue?.customerId
          )?.[0];
          setCustvalue(cust);
        }
      }
    }
  }, [taskvalue]);

  useEffect(() => {
    if (row && row._id) {
      const items = itemsData?.data?.['getOperationItems']?.data || [];
      const actions = actionsData?.data?.['getActions']?.data || [];

      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...servicesproducts, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );
        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            note,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          const fullname = `${serv?.name}
${serv?.nameAr}`;
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            no: index + 1,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            itemtotalcost: item.totalCost,
            note,
            fullname,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        setItemsList(itemsWqtyprice);
        const listwithindex = indexTheList(actions);
        setActionslist(listwithindex);
      }
    }
  }, [itemsData, actionsData]);

  const cashaccounts = mainAccounts.filter(
    (acc: any) => acc.parentcode === parents.CASH && acc.code < 10499
  );
  useEffect(() => {
    if (isCash) {
      setDebitAcc(cashaccounts?.[0]);
      setDebitAcc2(cashaccounts?.[1]);
    }
  }, [open, isCash]);

  const resetAllForms = () => {
    setItemsList([]);
    setDiscount(0);
    setDesc(null);
    setTip(0);
    setTotals({});
    setInvNo('');
    setAccounts([]);
    setIsCash(tempoptions?.cashinvoice);
    setSelectedDate(new Date());
    setCustvalue(name === 'customerId' ? value : null);
    setDepartvalue(name === 'departmentId' ? value : null);
    setEmplvalue(name === 'employeeId' ? value : null);
    setResovalue(name === 'resourseId' ? value : null);
    setTaskvalue(name === 'contractId' ? value : null);
    setPaid(0);
    setPaid2(0);
    setDebitAcc(null);
    setDebitAcc2(null);
    setPercent(0);
    setIspercent(false);
    setActionslist([]);
    setSelected(null);
  };

  const resetForNew = () => {
    setItemsList([]);
    setDiscount(0);
    setDesc(null);
    setTip(0);
    setTotals({});
    setInvNo('');
    setAccounts([]);
    setIsCash(tempoptions?.cashinvoice);
    setSelectedDate(new Date());
    setTaskvalue(name === 'contractId' ? value : null);
    setResovalue(name === 'resourseId' ? value : null);
    setEmplvalue(name === 'employeeId' ? value : null);
    setDepartvalue(name === 'departmentId' ? value : null);
    setPaid(0);
    setPaid2(0);
    setDebitAcc(null);
    setDebitAcc2(null);
    setPercent(0);
    setIspercent(false);
    setActionslist([]);
    setSelected(null);
  };

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheListFullname(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheListFullname(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheListFullname(newList);
    setItemsList(listwithindex);
  };

  const addMultiProducts = (data: any) => {
    const newliastitems = data.map((da: any) => {
      const item = servicesproducts.find(
        (prod: any) => prod?.barcode?.toString() === da?.barcode?.toString()
      );
      if (item) {
        return {
          ...item,
          itemqty: da?.qty,
          itemprice: da?.price,
          itemtotal: da?.qty * da?.price,
          userId: user?._id,
        };
      } else return null;
    });
    const rdata = newliastitems.filter((x: any) => x);
    const listwithindex = indexTheListFullname(rdata);

    setItemsList(listwithindex);
  };

  const handleDateChange = (date: any) => {
    if (date) {
      const d = new Date(date);
      // d?.setHours(8, 0, 0);
      setSelectedDate(d);
    }
  };

  useEffect(() => {
    if (isNew) {
      if (name === 'contractId') {
        if (value?.departmentId) {
          const dept = departments.filter(
            (dep: any) => dep._id === value?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (value?.employeeId) {
          const dept = employees.filter(
            (dep: any) => dep._id === value?.employeeId
          )?.[0];
          setEmplvalue(dept);
        }
        if (value?.resourseId) {
          const dept = resourses.filter(
            (dep: any) => dep._id === value?.resourseId
          )?.[0];
          setResovalue(dept);
        }
        if (value?.customerId) {
          const dept = customers.filter(
            (dep: any) => dep._id === value?.customerId
          )?.[0];
          setCustvalue(dept);
        }
      }
    }
  }, [open]);

  useEffect(() => {
    getOverallTotal();
  }, [itemsList, discount, isCash, paid, paid2, debitAcc, debitAcc2]);

  // useEffect(() => {
  //   if (isCash && (paid === 0 || paid2 === 0)) {
  //     const amount = simpleAmount();
  //     setPaid(amount || 0);
  //   }
  //   if (!isCash) {
  //     setPaid(0);
  //     setPaid2(0);
  //   }
  // }, [itemsList, discount, isCash]);

  useEffect(() => {
    if (row && row._id) {
      getItems({ variables: { opId: row._id } });
      loadActions({ variables: { opId: row._id, type: 1 } });

      const _id = row.customerId;
      const cust = customers.filter((it: any) => it._id === _id)?.[0];

      const depId = row.departmentId;
      const empId = row.employeeId;
      const resId = row.resourseId;
      const da = row.debitAcc;
      const da2 = row.debitAcc2;

      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)?.[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)?.[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)?.[0];
        setResovalue(empl);
      }

      if (!task && row.contractId) {
        const tsk = tasks.filter((ts: any) => ts._id === row.contractId)?.[0];
        setTaskvalue(tsk);
      }
      if (da) {
        const debit = mainAccounts.filter((acc: any) => acc.code === da)?.[0];
        setDebitAcc(debit);
      }
      if (da2) {
        const debit = mainAccounts.filter((acc: any) => acc.code === da2)?.[0];
        setDebitAcc2(debit);
      }
      if (row?.amountPaid) {
        setPaid(row?.amountPaid);
      }
      if (row?.amountPaid2) {
        setPaid2(row?.amountPaid2);
      }
      setCustvalue(cust);
      setIsCash(row?.isCash);
      setDiscount(row?.discount);
      setDesc(row?.desc);
      setTip(row?.tip);
      if (row?.discount) {
        const percentage = (row?.discount * 100) / row?.amount;
        setPercent(percentage);
      }
      setInvNo(row?.docNo);
      handleDateChange(row.time);
    }
  }, [row]);

  useEffect(() => {
    if (isNew) {
      const start = new Date();
      // start.setHours(8, 0, 0);
      setSelectedDate(start);
    }
  }, [open]);

  useEffect(() => {
    if (isNew && ispercent && percent > 0) {
      const newdisc = (totals.total * percent) / 100;
      setDiscount(newdisc);
    }
  }, [ispercent, percent, totals.total]);

  // const simpleAmount = () => {
  //   const totalsin = itemsList.map((litem: any) => litem.itemtotal);
  //   const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
  //   const amount = sum - discount;
  //   return amount;
  // };

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum - discount;
    const profit = sum - discount - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);

    const accs = [
      {
        debitAcc: accountCode.accounts_receivable,
        creditAcc: accountCode.sales_income,
        amount: sum,
        type: operationTypes.salesInvoice,
      },
      {
        debitAcc: accountCode.sales_income,
        creditAcc: accountCode.accounts_receivable,
        amount: discount,
        type: operationTypes.customerDiscount,
      },
      {
        debitAcc: 1000,
        creditAcc: accountCode.accounts_receivable,
        amount: isCash ? paid : 0,
        type: operationTypes.customerReceipt,
      },
      {
        debitAcc: 1010,
        creditAcc: accountCode.accounts_receivable,
        amount: isCash ? paid2 : 0,
        type: operationTypes.customerReceipt,
      },
      {
        debitAcc: accountCode.cost_of_sales,
        creditAcc: accountCode.inventory,
        amount: costsum,
        type: operationTypes.salesDelivery,
      },
    ];
    setAccounts(accs);
  };
  const onSubmit = async (stay: any, print: any) => {
    if (!custvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة عميل للفاتورة' : 'Please add Customer'
      );
      return;
    }
    if (!itemsList || itemsList.length === 0) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يجب اضافة عنصر (خدمة او منتج) واحد للفاتورة على الأقل`
          : `You should add min one service to invoice`
      );
      return;
    }
    setSaving(true);
    const { amount, costAmount, profit, total } = totals;

    const newList =
      !tempoptions?.itemrelations && !tempoptions?.invoiceitemrelations
        ? itemsList?.map((il: any) => {
            return {
              ...il,
              contractId: taskvalue?._id,
              contractName: taskvalue?.name,
              contractNameAr: taskvalue?.nameAr,
              departmentId: departvalue?._id,
              departmentName: departvalue?.name,
              departmentNameAr: departvalue?.nameAr,
              departmentColor: departvalue?.color,
              employeeId: emplvalue?._id,
              employeeName: emplvalue?.name,
              employeeNameAr: emplvalue?.nameAr,
              employeeColor: emplvalue?.color,
              employeePhone: emplvalue?.phone,
              resourseId: resovalue?._id,
              resourseName: resovalue?.name,
              resourseNameAr: resovalue?.nameAr,
              resourseColor: resovalue?.color,
            };
          })
        : itemsList;
    const items = JSON.stringify(newList);

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      docNo: invNo ? invNo.toString() : undefined,
      time: selectedDate,
      customer: {
        customerId: custvalue._id,
        customerName: custvalue.name,
        customerNameAr: custvalue.nameAr,
        customerPhone: custvalue.phone,
      },
      department: departvalue
        ? {
            departmentId: departvalue._id,
            departmentName: departvalue.name,
            departmentNameAr: departvalue.nameAr,
            departmentColor: departvalue.color,
          }
        : {
            departmentId: undefined,
            departmentName: undefined,
            departmentNameAr: undefined,
            departmentColor: undefined,
          },
      employee: emplvalue
        ? {
            employeeId: emplvalue._id,
            employeeName: emplvalue.name,
            employeeNameAr: emplvalue.nameAr,
            employeeColor: emplvalue.color,
            employeePhone: emplvalue.phone,
          }
        : {
            employeeId: undefined,
            employeeName: undefined,
            employeeNameAr: undefined,
            employeeColor: undefined,
            employeePhone: undefined,
          },
      resourse: resovalue
        ? {
            resourseId: resovalue._id,
            resourseName: resovalue.name,
            resourseNameAr: resovalue.nameAr,
            resourseColor: resovalue.color,
          }
        : {
            resourseId: undefined,
            resourseName: undefined,
            resourseNameAr: undefined,
            resourseColor: undefined,
          },
      contract: taskvalue
        ? {
            contractId: taskvalue._id,
            contractName: taskvalue.name,
            contractNameAr: taskvalue.nameAr,
          }
        : {
            contractId: undefined,
            contractName: undefined,
            contractNameAr: undefined,
          },
      project: taskvalue
        ? {
            projectId: taskvalue.projectId,
            projectName: taskvalue.projectName,
            projectNameAr: taskvalue.projectNameAr,
          }
        : {
            projectId: undefined,
            projectName: undefined,
            projectNameAr: undefined,
          },
      items,
      costAmount,
      total,
      discount,
      amount,
      profit,
      isPaid: isCash,
      isCash,
      debitAcc: debitAcc ? debitAcc.code : null,
      debitAcc2: debitAcc2 ? debitAcc2.code : null,
      amountPaid: isCash ? paid : 0,
      amountPaid2: isCash ? paid2 : 0,
      accounts,
      desc,
      actions: JSON.stringify(actionslist),
      tip,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;

    await apply(mutate, variables, stay, print);
  };
  const apply = async (mutate: any, variables: any, stay: any, print: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.createInvoice?.ok) {
        setInvNo(res?.data?.createInvoice?.message);
      }
      if (print) {
        handleReactPrint();
      }
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      if (stay) {
        resetForNew();
      } else {
        onCloseForm();
      }
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
  };

  const onHandleSubmit = (print: any) => {
    onSubmit(false, print);
  };
  const onSubmitStay = () => {
    onSubmit(true, false);
  };

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice #${invNo}`,
    removeAfterPrint: true,
  });
  const printData = {
    invoiceNo: invNo,
    time: selectedDate,
    docNo: invNo,
    customerName: custvalue?.name,
    customerPhone: custvalue?.phone,
    customerNameAr: custvalue?.nameAr,
    amountPaid: paid,
    amountPaid2: paid2,
    total: totals.total,
    percent,
    discount,
    amount: totals.amount,
    items: itemsList,
    user: user?.name,
    tafkeet: tafkeet(totals.amount, isRTL),
  };

  const template = getDefaultTemplate(printtemplates, 'invoice');

  const date = row?.startDate ? new Date(row?.startDate) : new Date();
  const day = weekdaysNNo?.[date.getDay()];
  const title = isRTL ? 'فاتورة بيع' : 'Sales Invoice';
  const bl = totals?.amount - (paid + paid2);
  const pa = `${paid + paid2} /  ${totals?.amount}`;

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      onSubmitStay={isNew ? onSubmitStay : undefined}
      theme={theme}
      alrt={alrt}
      print={handleReactPrint}
      maxWidth="lg"
      saving={saving}
      onlyclose={!isNew && !userroles?.sales?.edit}
      mt={0}
      mb={40}
      saveprint={template?.size === 'POS'}
    >
      <Grid container spacing={0}>
        {isMobile && (
          <Grid item md={12}>
            <PrintDownload
              company={company}
              printData={printData}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              isRTL={isRTL}
              template={template}
              no={row?.docNo}
              element="invoice"
              isNew={isNew}
            ></PrintDownload>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <CalenderLocal
            isRTL={isRTL}
            label={words?.time}
            value={selectedDate}
            onChange={handleDateChange}
          ></CalenderLocal>
          {!isNew && (
            <Box
              display="flex"
              style={{
                flex: 1,
                flexDirection: isRTL ? 'row-reverse' : 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Typography style={{ color: '#777' }}>{words.no}</Typography>
              <TextField
                name="invNo"
                disabled
                value={invNo}
                variant="outlined"
                style={{ width: isNew ? 70 : 100, marginLeft: 20 }}
                margin="dense"
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: 14,
                    height: 13,
                  },
                }}
              />
            </Box>
          )}
          {tempoptions?.sms && custvalue && (
            <Grid
              item
              xs={12}
              style={{
                marginTop: 5,
                backgroundColor: '#eee',
                borderRadius: 5,
                marginLeft: 10,
              }}
            >
              <Button
                variant="outlined"
                style={{
                  fontSize: 14,
                  minWidth: 80,
                  height: 30,
                }}
                onClick={() => {
                  setSelected(null);
                  setOpenAction(true);
                }}
              >
                <Typography style={{ fontSize: 13 }}>
                  {isRTL ? 'اضافة رسالة' : 'Add SMS'}
                </Typography>
              </Button>
              <Paper style={{ height: 70, overflow: 'auto' }}>
                {actionslist.map((act: any) => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={actionTypeFormatter({ row: act })}
                        secondary={getDateDayTimeFormat(act.sendtime, isRTL)}
                      />
                      <IconButton
                        onClick={() => removeActionFromList(act)}
                        title="Delete row"
                        style={{ padding: 5 }}
                      >
                        <DeleteOutlinedIcon
                          style={{ fontSize: 22, color: '#a76f9a' }}
                        />
                      </IconButton>
                      <IconButton
                        style={{ padding: 5 }}
                        onClick={() => {
                          setSelected(act);
                          setOpenAction(true);
                        }}
                        title="Edit row"
                      >
                        <EditOutlinedIcon
                          style={{ fontSize: 22, color: '#729aaf' }}
                        />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </Paper>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginTop: 15 }}>
              <AutoFieldLocal
                name="customer"
                title={tempwords?.customer}
                words={words}
                options={customers}
                value={custvalue}
                setSelectValue={setCustvalue}
                setNewtext={setNewtext}
                isRTL={isRTL}
                fullWidth
                openAdd={openCustomer}
                showphone
                disabled={name === 'customerId'}
              ></AutoFieldLocal>
            </Grid>
            {tempoptions?.docrelations &&
              !tempoptions?.invoiceitemrelations && (
                <>
                  {tempoptions?.contract && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="task"
                        title={tempwords?.task}
                        words={words}
                        options={tasks}
                        value={taskvalue}
                        setSelectValue={setTaskvalue}
                        isRTL={isRTL}
                        fullWidth
                        disabled={name === 'contractId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.employee && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="employee"
                        title={tempwords?.employee}
                        words={words}
                        options={employees.filter((emp: any) => !emp.disabled)}
                        value={emplvalue}
                        disabled={isemployee || name === 'employeeId'}
                        setSelectValue={setEmplvalue}
                        setSelectError={setEmplError}
                        selectError={emplError}
                        refernce={emplRef}
                        openAdd={openEmployee}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {tempoptions?.department && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="department"
                        title={tempwords?.department}
                        words={words}
                        options={departments.filter(
                          (d: any) => d.depType === 1
                        )}
                        value={departvalue}
                        setSelectValue={setDepartvalue}
                        setSelectError={setDepartError}
                        selectError={departError}
                        refernce={departRef}
                        openAdd={openDepartment}
                        isRTL={isRTL}
                        fullWidth
                        disabled={name === 'departmentId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}

                  {tempoptions?.resourse && (
                    <Grid item xs={12} md={6}>
                      <AutoFieldLocal
                        name="resourse"
                        title={tempwords?.resourse}
                        words={words}
                        options={resourses}
                        value={resovalue}
                        disabled={name === 'resourseId'}
                        setSelectValue={setResovalue}
                        setSelectError={setResoError}
                        selectError={resoError}
                        refernce={resoRef}
                        openAdd={openResourse}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                </>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={1}></Grid>

        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: '#f3f3f3',
              padding: 10,
              marginTop: 15,
              marginBottom: 15,
              borderRadius: 10,
            }}
          >
            <Box display="flex" style={{ paddingLeft: 10, paddingRight: 10 }}>
              <ServiceItemForm
                services={servicesproducts}
                products={products}
                addItem={addItemToList}
                words={words}
                classes={classes}
                user={user}
                isRTL={isRTL}
                setAlrt={setAlrt}
                taskv={task}
                issale={true}
                itemrelations={
                  tempoptions?.invoiceitemrelations ||
                  tempoptions?.itemrelations
                }
              ></ServiceItemForm>
            </Box>
            <ImportBtn
              open={() => setOpenImport(true)}
              isRTL={isRTL}
            ></ImportBtn>
            {(isNew || itemsList.length > 0) && (
              <Box style={{ marginBottom: 20 }}>
                <ItemsTable
                  products={[...servicesproducts, ...products]}
                  rows={itemsList}
                  editItem={editItemInList}
                  removeItem={removeItemFromList}
                  isRTL={isRTL}
                  words={words}
                  user={user}
                  itemrelations={
                    tempoptions?.invoiceitemrelations ||
                    tempoptions?.itemrelations
                  }
                ></ItemsTable>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            style={{
              alignItems: 'center',
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            {isNew && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: 7 }}
                    checked={ispercent}
                    onChange={() => {
                      const nv = !ispercent;
                      if (!nv) {
                        setPercent(0);
                        setDiscount(0);
                      }
                      setIspercent(nv);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle2">
                    {isRTL ? 'نسبة' : 'Percentage'}
                  </Typography>
                }
                style={{ fontSize: 14 }}
              />
            )}
            {(isNew || percent > 0) && (
              <TextField
                name="percent"
                label={'%'}
                value={percent}
                onChange={(e: any) => setPercent(Number(e.target.value))}
                variant="outlined"
                disabled={!isNew}
                style={{ width: 100 }}
                margin="dense"
                type="number"
              />
            )}
            <TextField
              name="discount"
              label={words.discount}
              value={discount}
              onChange={(e: any) => setDiscount(Number(e.target.value))}
              variant="outlined"
              style={{ width: 200 }}
              margin="dense"
              type="number"
              onFocus={(e) => e.target.select()}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {!isMobile && (
            <PrintDownload
              company={company}
              printData={printData}
              template={template}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              no={row?.docNo}
              element="invoice"
              isRTL={isRTL}
              isNew={isNew}
            ></PrintDownload>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <PriceTotal
            amount={totals?.amount}
            total={totals?.total}
            discount={discount}
            words={words}
            end={false}
          ></PriceTotal>
        </Grid>
        <Grid item xs={12} md={8} style={{ marginBottom: 10 }}>
          <PaymentSelect
            words={words}
            isCash={isCash}
            setIsCash={setIsCash}
            paid={paid}
            setPaid={setPaid}
            paid2={paid2}
            setPaid2={setPaid2}
            isRTL={isRTL}
            debitaccounts={cashaccounts}
            debitAcc={debitAcc}
            setDebitAcc={setDebitAcc}
            debitAcc2={debitAcc2}
            setDebitAcc2={setDebitAcc2}
            isNew={isNew}
          ></PaymentSelect>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
                color: 'tomato',
              }}
            >
              {moneyFormat(bl)} {isRTL ? 'المتبقي' : 'Balance'}
            </Typography>
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
              }}
            >
              ( {pa} ) {isRTL ? 'مدفوع' : 'Paid'}
            </Typography>
          </Box>
        </Grid>
        {tempoptions?.invoicetip && (
          <Grid item xs={12} md={2}>
            <TextField
              name="tip"
              value={tip}
              onChange={(e: any) => setTip(Number(e.target.value))}
              label={isRTL ? 'بقشيش' : 'Tip'}
              variant="outlined"
              margin="dense"
              type="number"
              fullWidth
              style={{ marginTop: 0 }}
            />
          </Grid>
        )}
        {tempoptions?.docdescription && (
          <>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={9}>
              <TextField
                name="desc"
                onChange={(e: any) => setDesc(e.target.value)}
                value={desc}
                label={words.notes}
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rowsMax={2}
                rows={2}
                style={{ marginTop: 0 }}
              />
            </Grid>
          </>
        )}
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
        <PopupIlisttemsImport
          open={openImport}
          onClose={() => setOpenImport(false)}
          addMultiItems={addMultiProducts}
          isRTL={isRTL}
          words={words}
          filename="items"
        ></PopupIlisttemsImport>
        <PopupAction
          open={openAction}
          onClose={() => {
            setOpenAction(false);
            setSelected(null);
          }}
          row={selected}
          type={1}
          isNew={selected ? false : true}
          customer={custvalue}
          addAction={addActionToList}
          editAction={editActionInList}
          theme={theme}
          event={{ ...row, startDate: selectedDate, endDate: selectedDate }}
        ></PopupAction>
      </Grid>
    </PopupLayout>
  );
};

export default PopupInvoice;
