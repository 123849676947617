/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import {
  covertToTimeDateDigit,
  createdAtPrintFormatter,
  currencyFormatter,
  moneyFormat,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  fade,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import { getReportContracts } from '../../graphql';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import PageLayout from '../main/PageLayout';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { useCustomers, useTemplate } from '../../hooks';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { SearchTable } from '../../components';
import { ContractsReportContext } from '../../contexts/managment/contractsreport';
import { getTaskStatus } from '../../common/helpers';
import _ from 'lodash';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);
export const TableComponent2 = withStyles(
  {},
  { name: 'TableComponent' }
)(TableComponentBase);

export default function ContractsReport({
  isRTL,
  words,
  menuitem,
  theme,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [total, setTotal] = useState<any>(0);
  const [createtime, setCreatetime] = useState<any>(false);

  const [rows, setRows] = useState([]);
  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const [columns] = useState([
    col.createdAt,
    col.start,
    col.end,
    col.docNo,
    col.title,
    // col.type,
    col.status,
    col.resourse,
    col.customer,
    // col.department,
    col.employee,
    col.amount,
    col.totalinvoiced,
    // col.totalDiscount,
    col.totalpaid,
    // col.toatlExpenses,
    col.balance,
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.title, togglingEnabled: false },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getReportContracts, {
    fetchPolicy: 'cache-and-network',
  });
  const { customers } = useCustomers();
  const { employees } = useEmployees();
  const { resourses } = useResourses();

  const {
    state: {
      currentDate,
      currentViewName,
      endDate,
      departvalue,
      resovalue,
      emplvalue,
      custvalue,
      sumcolumn,
      sort,
    },
    dispatch,
  } = useContext(ContractsReportContext);
  const { height } = useWindowDimensions();

  const currentViewNameChange = (e: any) => {
    dispatch({ type: 'setCurrentViewName', payload: e.target.value });
  };
  const currentDateChange = (curDate: any) => {
    dispatch({ type: 'setCurrentDate', payload: curDate });
  };
  const endDateChange = (curDate: any) => {
    dispatch({ type: 'setEndDate', payload: curDate });
  };

  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setResovalueDispatch = (value: any) => {
    dispatch({ type: 'setResovalue', payload: value });
  };
  const setCustvalueDispatch = (value: any) => {
    dispatch({ type: 'setCustvalue', payload: value });
  };
  useEffect(() => {
    const slsData = summaryData?.data?.['getReportContracts']?.data || [];
    const taskswithstatus = getTaskStatus(slsData, isRTL);
    setRows(taskswithstatus);
    const tot = _.sumBy(taskswithstatus, 'amount');
    setTotal(tot);
  }, [summaryData]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;
  const fetchData = () => {
    const variables = {
      departmentIds: getIds(departvalue),
      employeeIds: getIds(emplvalue),
      resourseIds: getIds(resovalue),
      customerIds: getIds(custvalue),
      createtime,
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    sumcolumn,
    departvalue,
    resovalue,
    emplvalue,
    custvalue,
    createtime,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `events-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };
  const refresh = () => {
    summaryData?.refetch();
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'amount', type: 'sum' },
    { columnName: 'totalinvoiced', type: 'sum' },
    { columnName: 'totalpaid', type: 'sum' },
    { columnName: 'balance', type: 'sum' },
  ];

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      loading={summaryData?.loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: '#fff',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <Box
          display="flex"
          style={{
            position: 'absolute',
            zIndex: 111,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
          <Box style={{ padding: 7 }}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 7 }}
                  checked={createtime}
                  onChange={() => {
                    setCreatetime(!createtime);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                  }}
                >
                  {isRTL ? 'بحسب تاريخ الانشاء' : 'By Created Date'}
                </Typography>
              }
              style={{ fontSize: 14 }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: isRTL ? 20 : 250,
            paddingRight: isRTL ? 250 : 20,
            marginTop: -50,
          }}
        >
          {tempoptions?.resourse && (
            <FilterSelectMulti
              options={resourses}
              value={resovalue}
              setValue={setResovalueDispatch}
              words={words}
              isRTL={isRTL}
              name="resourse"
              width={250}
              fullwidth
            ></FilterSelectMulti>
          )}
          <FilterSelectMulti
            options={employees}
            value={emplvalue}
            setValue={setEmplvalueDispatch}
            words={words}
            isRTL={isRTL}
            name="employee"
            width={250}
            fullwidth
          ></FilterSelectMulti>
          <FilterSelectMulti
            options={customers}
            value={custvalue}
            setValue={setCustvalueDispatch}
            words={words}
            isRTL={isRTL}
            name="customer"
            width={250}
            fullwidth
          ></FilterSelectMulti>
        </Box>
        <Box
          style={{
            position: 'absolute',
            zIndex: 111,
            right: isRTL ? undefined : 100,
            left: isRTL ? 100 : undefined,
            top: 15,
          }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
            }}
          >
            {isRTL ? 'المجموع' : 'Total'}: {moneyFormat(total)}
          </Typography>
        </Box>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            defaultSorting={sort}
            onSortingChange={(srt: any) => setSortDispatch(srt)}
          />
          <SearchState />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary />
          <IntegratedSorting />
          <IntegratedFiltering />
          <VirtualTable
            height={height - 100}
            tableComponent={TableComponent}
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            estimatedRowHeight={40}
          />
          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableColumnVisibility
            columnExtensions={tableColumnVisibilityColumnExtensions}
            onHiddenColumnNamesChange={(hcs: string[]) => {
              const all = [...columns];
              const newcol = all.filter((a: any) => !hcs.includes(a.name));
              newcol.sort((a: any, b: any) =>
                a.id > b.id ? 1 : b.id > a.id ? -1 : 0
              );
              // setActivecolumns(newcol);
            }}
          />
          <DataTypeProvider
            for={['start', 'end', 'createdAt']}
            formatterComponent={createdAtPrintFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={[
              'amount',
              'totalinvoiced',
              'totalDiscount',
              'totalpaid',
              'toatlExpenses',
              'balance',
            ]}
            formatterComponent={currencyFormatter}
          ></DataTypeProvider>{' '}
          <Toolbar />
          <ColumnChooser />
          <SearchPanel
            inputComponent={(props: any) => {
              return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
            }}
          />
          <ExportPanel startExport={startExport} />
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
        />
      </Box>
    </PageLayout>
  );
}
