/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createProcItem,
  deleteProcItem,
  updateProcItem,
} from '../graphql/mutation';
import { getProcItems } from '../graphql/query';

export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getProcItems);

  const [addProcItem] = useMutation(createProcItem, {
    refetchQueries: [{ query: getProcItems }],
  });
  const [editProcItem] = useMutation(updateProcItem, {
    refetchQueries: [{ query: getProcItems }],
  });
  const [removeProcItem] = useMutation(deleteProcItem, {
    refetchQueries: [{ query: getProcItems }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const procitems = catpData?.data?.['getProcItems']?.data || [];
  const refreshprocitems = () => catpData?.refetch();
  return {
    procitems,
    addProcItem,
    editProcItem,
    removeProcItem,
    refreshprocitems,
    loading: catpData?.loading,
  };
};
