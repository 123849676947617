export function sleep(s: any) {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
}

type AT = 'success' | 'info' | 'warning' | 'error' | undefined;
interface ShowAType {
  setAlrt: any;
  msg: string;
  type: AT;
  s?: number;
}

export const showAlert = async ({ setAlrt, msg, type, s = 2 }: ShowAType) => {
  setAlrt({ show: true, msg, type });
  await sleep(s);
  setAlrt({ show: false, msg: '', type: 'info' });
};

export const successAlert = async (setAlrt: any, isRTL: any) => {
  await showAlert({
    setAlrt,
    msg: isRTL ? 'تمت الاضافة بنجاح' : 'item added successfuly',
    type: 'success',
    s: 0.25,
  });
};
export const successAlert2S = async (setAlrt: any, isRTL: any) => {
  await showAlert({
    setAlrt,
    msg: isRTL ? 'تمت الاضافة بنجاح' : 'item added successfuly',
    type: 'success',
    s: 2,
  });
};

export const dublicateAlert = async (setAlrt: any, isRTL: any) => {
  showAlert({
    setAlrt,
    msg: isRTL ? 'خطأ - هذا المدخل موجود من قبل' : 'Error, Item already exist',
    type: 'error',
  });
};

export const errorAlert = async (setAlrt: any, isRTL: any) => {
  await showAlert({
    setAlrt,
    msg: isRTL ? 'خطأ - حدث خطأ في العملية' : 'Error on operation',
    type: 'error',
    s: 2,
  });
};
export const errorAlertMsg = async (setAlrt: any, msg: any) => {
  await showAlert({
    setAlrt,
    msg,
    type: 'error',
    s: 2,
  });
};

export const errorAccountAlert = async (setAlrt: any, isRTL: any) => {
  await showAlert({
    setAlrt,
    msg: isRTL ? 'خطأ - لا يمكن حذف هذا الحساب' : 'Error on operation',
    type: 'error',
    s: 2,
  });
};

export const errorDeleteAlert = async (setAlrt: any, isRTL: any) => {
  await showAlert({
    setAlrt,
    msg: isRTL
      ? 'لا يمكن حذف هذا المدخل - لديه ارتباطات'
      : "Can't Delete this Item, it has related",
    type: 'error',
    s: 2,
  });
};

export const messageAlert = async (setAlrt: any, msg: any) => {
  await showAlert({
    setAlrt,
    msg,
    type: 'error',
    s: 2,
  });
};
export const messageShow = async (setAlrt: any, msg: any) => {
  await showAlert({
    setAlrt,
    msg,
    type: 'success',
    s: 2,
  });
};

export const getRowId = (row: { _id: any }) => row._id;

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

export const subscribePushToken = async (user: any, checked: any) => {
  if (!user?.publicKey) {
    return undefined;
  }
  let sw = await navigator.serviceWorker.ready;
  let sub = await sw.pushManager.getSubscription();
  if (checked) {
    if (!sub) {
      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: user?.publicKey,
      });
      return JSON.stringify(push);
    } else {
      await sub.unsubscribe();
      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: user?.publicKey,
      });
      return JSON.stringify(push);
    }
  } else {
    if (sub) {
      await sub.unsubscribe();
      return undefined;
    } else {
      return undefined;
    }
  }
};

export const isDateBusy = (date: any, timeRanges: any) => {
  const { startDate, endDate } = date;
  if (!startDate || !endDate || !timeRanges) return { busy: false };
  for (let item of timeRanges) {
    if (startDate <= item?.endDate && endDate >= item?.startDate) {
      return { busy: true, docNo: item?.docNo }; // Date is busy
    }
  }
  return { busy: false }; // Date is free
};

export const isDatelistBusy = (datelist: any, timeRanges: any) => {
  const docNos = [];
  if (
    !datelist ||
    datelist.length === 0 ||
    !timeRanges ||
    timeRanges.length === 0
  ) {
    return { busy: false };
  }

  for (const date of datelist) {
    const { startDate, endDate } = date;
    for (let item of timeRanges) {
      if (startDate <= item?.endDate && endDate >= item?.startDate) {
        docNos.push(item?.docNo);
      }
    }
  }

  if (docNos.length > 0) {
    return { busy: true, docNos };
  } else {
    return { busy: false };
  }
};

export const calculateDateList = (startslist: any, eventLength: any) => {
  if (!startslist || startslist.length === 0 || !eventLength) return [];
  const list = [];
  for (const item of startslist) {
    const startDate = new Date(item);
    const endDate = new Date(item.getTime() + eventLength * 60000);
    list.push({ startDate, endDate });
  }
  return list;
};

export const fullscreen = () => document.body.requestFullscreen();
export const exitfullscreen = () => document.exitFullscreen();
