/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  dublicateAlert,
  errorAlert,
  messageAlert,
  successAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  colors,
  fade,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal, TextFieldLocal } from '../components';
import { weekdaysNNo } from '../constants/datatypes';
import _ from 'lodash';
import { getPopupTitle } from '../constants/menu';
import { useCustomers, useProducts, useServices, useTemplate } from '../hooks';
import PopupCustomer from './PopupCustomer';
import PopupDeprtment from './PopupDeprtment';
import PopupEmployee from './PopupEmployee';
import PopupResourses from './PopupResourses';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import ServiceItemForm from '../Shared/ServiceItemForm';
import ItemsTable from '../Shared/ItemsTable';
import { invoiceClasses } from '../themes';
import { getDefaultTemplate, getInvDays, tafkeet } from '../common/helpers';
import { useReactToPrint } from 'react-to-print';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createMultiEvents, getOperationItems } from '../graphql';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import usePrintTemplates from '../hooks/usePrintTemplates';
import PrintDownload from '../Shared/PrintDownload';
import ExtraList from '../Shared/ExtraList';
import useRetypes from '../hooks/useRetypes';
import { tasktypes } from '../constants/rrule';
import EventsTask from '../Shared/EventsTask';
import PopupAppointmentMulti from './PopupAppointmentMulti';
import getObjectEvents from '../graphql/query/getObjectEvents';
import { getEmployees, getTasks } from '../graphql/query';
import { moneyFormat } from '../Shared/colorFormat';
import { rid } from '../Sections/generator/print/renders';
import { ImageView } from '../components/viewer';
import { UploadPhotos } from '../common/UploadPhotos';
import { uploadMultiPhotoOnline } from '../common/AvatarUpload';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const getParagraphs = ({ items, extra, isRTL }: any) => {
  if (items?.length === 0 && extra?.length === 0) return [];
  const list =
    !items || items?.length === 0
      ? []
      : _(items)
          .groupBy(isRTL ? 'categoryNameAr' : 'categoryNameAr')
          .map((array, key) => ({
            group: key,
            qty: array.length,
            subitems: array,
          }))
          .value();

  const ronelist =
    !items || items?.length === 0
      ? []
      : list.map((item: any) => {
          const subitems = item?.subitems?.map((sitem: any) => {
            return {
              id: sitem._id,
              title: isRTL ? sitem.nameAr : sitem.name,
              desc: sitem.note,
            };
          });
          return {
            id: rid(),
            title: item?.group,
            subitems,
          };
        });
  return [...ronelist, ...extra];
};

const getMonthsQty = ({ start, end }) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const yearDiff = endDate.getFullYear() - startDate.getFullYear();
  const monthDiff = endDate.getMonth() - startDate.getMonth();

  const totalMonths = yearDiff * 12 + monthDiff;

  return Math.max(totalMonths, 1);
};

const PopupTaskFree = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  employees,
  resourses,
  departments,
  customers,
  theme,
  value = null,
  name = null,
  company,
  memorized,
}: any) => {
  const classes = invoiceClasses();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ismemorized, setIsmemorized] = useState(false);
  const [memitem, setMemitem] = useState(null);
  const [tasktitle, setTasktitle]: any = useState(null);
  const [start, setStart]: any = useState(null);
  const [end, setEnd]: any = useState(null);
  const [printdate, setPrintdate]: any = useState(null);
  const [printtitle, setPrinttitle]: any = useState(null);
  const [desc, setDesc]: any = useState(null);
  const [address, setAddress]: any = useState(null);
  const [extra, setExtra]: any = useState([]);
  const [deposit, setDeposit]: any = useState(null);
  const [rtypevalue, setRtypevalue] = useState<any>(null);
  const [rtype2value, setRtype2value] = useState<any>(null);
  const [typefree, setTypefree] = useState<any>(null); // يومي اسبوعي شهري سنوي
  const [openMulti, setOpenMulti] = useState(false);

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = React.useRef();

  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = React.useRef();
  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = React.useRef();

  const [custvalue, setCustvalue] = useState<any>(
    name === 'customerId' ? value : null
  );
  const [custError, setCustError] = useState<any>(false);
  const custRef: any = React.useRef();

  const [newtext, setNewtext] = useState('');
  const [openCust, setOpenCust] = useState(false);
  const [openDep, setOpenDep] = useState(false);
  const [openEmp, setOpenEmp] = useState(false);
  const [openRes, setOpenRes] = useState(false);

  const [showEvents, setShowEvents] = useState(false);

  const [itemsList, setItemsList] = useState<any>([]);
  const [invdays, setInvdays] = useState(0);

  const [dayCost, setDayCost] = useState(null);

  const [total, setTotal] = useState<any>(0);
  const [info, setInfo] = useState<any>(null);
  const [calcs, setCalcs] = useState<any>(null);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [urls, setUrls] = useState([]);
  const [photosimages, setPhotosimages] = useState([]);
  const [photosurls, setPhotosurls] = useState([]);
  const [discount, setDiscount] = useState(0);

  const { addCustomer, editCustomer } = useCustomers();
  const { addDepartment, editDepartment } = useDepartments();
  const { addEmployee, editEmployee } = useEmployees();
  const { addResourse, editResourse } = useResourses();
  const { tempwords, tempoptions, taskExtra, taskoptions } = useTemplate();
  const { products } = useProducts();
  const { services } = useServices();
  const { printtemplates } = usePrintTemplates();
  const { retypes } = useRetypes();

  const { register, handleSubmit, reset } = useForm({});
  const {
    translate: { words, isRTL },
    store: { user, calendar },
  }: GContextTypes = useContext(GlobalContext);
  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadCalcs, calcsData]: any = useLazyQuery(getGereralCalculation, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (row) {
      setMemitem(row);
    }
  }, [row, open]);

  const refresQuery = {
    refetchQueries: [
      {
        query: getObjectEvents,
        variables: {
          [name]: memitem?._id,
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getGereralCalculation,
        variables: {
          [name]: memitem?._id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      { query: getTasks },
      { query: getEmployees },
    ].filter((x: any) => x),
  };

  const [addMultiEvents] = useMutation(createMultiEvents, refresQuery);

  useEffect(() => {
    let locals = [];
    let online = [];
    if (photosimages && photosimages.length > 0) {
      for (const img of photosimages) {
        const localimage = URL.createObjectURL(img);
        locals.push(localimage);
      }
    } else {
      locals = [];
    }
    if (photosurls && photosurls.length > 0) {
      online = photosurls;
    } else {
      online = [];
    }
    setUrls([...online, ...locals]);
  }, [photosimages, photosurls]);

  const addToPhotos = (photos: any) => {
    const lphotos = [...photosimages];
    const li = photosimages.length;
    const oi = photosurls.length;
    const n = 10 - (oi + li);
    if (n < 1 || !photos || photos.length === 0) {
      return;
    } else {
      const newphotos = [...lphotos, ...photos];
      const fnewphotos = newphotos.slice(0, n);
      setPhotosimages(fnewphotos);
    }
  };
  const removePhoto = (src: any, index: any) => {
    if (src.startsWith('http')) {
      const newphotosurls = [...photosurls];
      const newlist = newphotosurls.filter((nu: any) => nu !== src);
      setPhotosurls(newlist);
    } else {
      const newList = [...photosimages];
      const newindex = index - photosurls.length;
      newList.splice(newindex, 1);
      setPhotosimages(newList);
    }
  };

  const setExtraInfo = ({ item, value }) => {
    const newitem = { ...item, value };
    const newinfo = info?.map((initem: any) => {
      if (initem.id === item.id) {
        return newitem;
      } else {
        return initem;
      }
    });
    setInfo(newinfo);
  };

  const onChangeDayCost = (e: any) => {
    const value = Number(e.target.value);
    setDayCost(value);
  };
  const onChangeDeposit = (e: any) => {
    const value = Number(e.target.value);
    setDeposit(value);
  };

  useEffect(() => {
    if (memitem && memitem._id) {
      const items = itemsData?.data?.['getOperationItems']?.data || [];
      const calcs = calcsData?.data?.['getGereralCalculation']?.data;
      if (calcs) {
        const data = JSON.parse(calcs);
        setCalcs(data);
      }
      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...services, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );
        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            note,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            note,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        setItemsList(itemsWqtyprice);
      } else {
        setItemsList([]);
      }
    }
  }, [itemsData, memitem]);

  useEffect(() => {
    const itemsTotal = _.sumBy(itemsList, 'itemtotal');
    setTotal(itemsTotal);
  }, [itemsList]);

  useEffect(() => {
    if (isNew && !info) {
      setInfo(taskExtra);
    }
  }, [taskExtra]);

  useEffect(() => {
    if (start && end) {
      const days = getInvDays(start, end);
      setInvdays(days);
    } else {
      setInvdays(0);
    }
  }, [start, end]);

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const template = getDefaultTemplate(printtemplates, 'contract');

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Contract #${memitem?.docNo}`,
    removeAfterPrint: true,
  });

  const kmout = Number(info?.[0]?.value);
  const kmin = Number(info?.[1]?.value);
  const km = kmout && kmin ? kmin - kmout : null;
  const balance = memitem?.amount - calcs?.totalpaid;

  const paragraphs = getParagraphs({ items: itemsList, extra, isRTL });
  const months = getMonthsQty({ start, end });
  const printData = {
    no: memitem?.docNo,
    amount: memitem?.amount,
    start,
    end,
    months,
    resovalue,
    custvalue,
    resourseName: isRTL ? resovalue?.nameAr : resovalue?.name,
    resoursePlate: resovalue?.plate,
    resourseInsurance: resovalue?.insurance,
    customerName: isRTL ? custvalue?.nameAr : custvalue?.name,
    customerArea: custvalue?.area,
    customerAddress: custvalue?.address,
    customerPhone: custvalue?.phone,
    customerDriver: custvalue?.driver,
    customerLicenseNo: custvalue?.licenseNo,
    customerLicenseDate: custvalue?.licenseDate,
    customerNational: custvalue?.national,
    customerNationalNo: custvalue?.nationalNo,
    customerNationalDate: custvalue?.nationalDate,
    totalpaid: calcs?.totalpaid,
    notes: info?.[2]?.value,
    kmout,
    kmin,
    km,
    daily:
      memitem?.dayCost > 0
        ? memitem?.dayCost
        : memitem?.amount / memitem?.evQty || 0,
    price: memitem?.amount,
    isRTL,
    tafkeet: tafkeet(memitem?.amount, isRTL),
    balance,
    paragraphs,
    address,
    desc,
    title: memitem?.title,
    printdate,
    printtitle,
    companyname: company?.name,
    companytel1: company?.tel1,
    companyfax: company?.fax,
    companyaddress: company?.address,
  };

  const openDepartment = () => {
    setOpenDep(true);
  };
  const onCloseDepartment = () => {
    setOpenDep(false);
    setNewtext('');
  };
  const openEmployee = () => {
    setOpenEmp(true);
  };
  const onCloseEmploee = () => {
    setOpenEmp(false);
    setNewtext('');
  };
  const openResourse = () => {
    setOpenRes(true);
  };
  const onCloseResourse = () => {
    setOpenRes(false);
    setNewtext('');
  };
  const openCustomer = () => {
    setOpenCust(true);
  };
  const onCloseCustomer = () => {
    setOpenCust(false);
    setNewtext('');
  };

  const onNewCustChange = (nextValue: any) => {
    setCustvalue(nextValue);
  };
  const onNewDepartChange = (nextValue: any) => {
    setDepartvalue(nextValue);
  };
  const onNewEmplChange = (nextValue: any) => {
    setEmplvalue(nextValue);
  };
  const onNewResoChange = (nextValue: any) => {
    setResovalue(nextValue);
  };

  const isemployee = user?.isEmployee && user?.employeeId;

  useEffect(() => {
    if (isemployee) {
      const emp = employees.filter(
        (em: any) => em._id === user.employeeId
      )?.[0];
      setEmplvalue(emp);
    }
  }, [user, employees]);

  useEffect(() => {
    if (isNew) {
      const start = new Date();
      // start.setHours(8, 0, 0);
      setStart(start);
      setPrintdate(start);
      if (taskoptions?.printtitle) {
        setPrinttitle('عنوان العقد للطباعة');
      }
    }
  }, [open]);

  useEffect(() => {
    if (memitem && memitem._id) {
      getItems({ variables: { opId: memitem._id } });
      loadCalcs({ variables: { contractId: memitem._id } });
      const depId = memitem.departmentId;
      const empId = memitem.employeeId;
      const custId = memitem.customerId;
      const resId = memitem.resourseId;
      const start = memitem?.start ? new Date(memitem?.start) : null;
      const end = memitem?.end ? new Date(memitem?.end) : null;
      const printd = memitem?.printdate ? new Date(memitem?.printdate) : null;
      setDeposit(memitem?.deposit);

      if (memitem?.info) {
        setInfo(JSON.parse(memitem?.info));
      } else {
        setInfo(taskExtra);
      }
      if (!isNew) {
        setStart(start);
        setEnd(end);
        setPrintdate(printd);
      }
      setPrinttitle(memitem?.printtitle);
      setTasktitle(memitem?.title);
      setDayCost(memitem?.dayCost || 0);

      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }
      if (custId) {
        const cust = customers.filter((cu: any) => cu._id === custId)[0];
        setCustvalue(cust);
      }
      if (memitem?.typefree) {
        const tt = tasktypes.filter(
          (cu: any) => cu.id === memitem?.typefree
        )[0];
        setTypefree(tt);
      }
      if (ismemorized && !isNew) {
        setIsmemorized(ismemorized);
      }
      setDesc(memitem?.desc);
      setAddress(memitem?.address);
      if (memitem?.extra) {
        const ext = JSON.parse(memitem?.extra);
        setExtra(ext);
      }
      if (memitem?.retypeId) {
        const depart = retypes.filter(
          (dep: any) => dep._id === memitem.retypeId
        )[0];
        setRtypevalue(depart);
      }
      if (memitem?.retype2Id) {
        const depart = retypes.filter(
          (dep: any) => dep._id === memitem.retype2Id
        )[0];
        setRtype2value(depart);
      }
      if (memitem.photos) {
        const phs = JSON.parse(memitem.photos);
        if (phs && phs.length > 0) {
          setPhotosurls(phs);
        }
      }
      setDiscount(memitem?.discount);
    }
  }, [memitem]);
  const customer = custvalue
    ? {
        customerId: custvalue._id,
        customerName: custvalue.name,
        customerNameAr: custvalue.nameAr,
        customerPhone: custvalue.phone,
      }
    : {
        customerId: undefined,
        customerName: undefined,
        customerNameAr: undefined,
        customerPhone: undefined,
      };

  const department = departvalue
    ? {
        departmentId: departvalue._id,
        departmentName: departvalue.name,
        departmentNameAr: departvalue.nameAr,
        departmentColor: departvalue.color,
      }
    : {
        departmentId: undefined,
        departmentName: undefined,
        departmentNameAr: undefined,
        departmentColor: undefined,
      };
  const employee = emplvalue
    ? {
        employeeId: emplvalue._id,
        employeeName: emplvalue.name,
        employeeNameAr: emplvalue.nameAr,
        employeeColor: emplvalue.color,
        employeePhone: emplvalue.phone,
      }
    : {
        employeeId: undefined,
        employeeName: undefined,
        employeeNameAr: undefined,
        employeeColor: undefined,
        employeePhone: undefined,
      };
  const resourse = resovalue
    ? {
        resourseId: resovalue._id,
        resourseName: resovalue.name,
        resourseNameAr: resovalue.nameAr,
        resourseColor: resovalue.color,
      }
    : {
        resourseId: undefined,
        resourseName: undefined,
        resourseNameAr: undefined,
        resourseColor: undefined,
      };
  const retype = rtypevalue
    ? {
        retypeId: rtypevalue._id,
        retypeName: rtypevalue.name,
        retypeNameAr: rtypevalue.nameAr,
        retypeColor: rtypevalue.color,
      }
    : {
        retypeId: undefined,
        retypeName: undefined,
        retypeNameAr: undefined,
        retypeColor: undefined,
      };

  const retype2 = rtype2value
    ? {
        retype2Id: rtype2value._id,
        retype2Name: rtype2value.name,
        retype2NameAr: rtype2value.nameAr,
        retype2Color: rtype2value.color,
      }
    : {
        retype2Id: undefined,
        retype2Name: undefined,
        retype2NameAr: undefined,
        retype2Color: undefined,
      };

  const resetAllForms = () => {
    setStart(null);
    setPrintdate(null);
    setPrinttitle(null);
    setDeposit(null);
    setDesc(null);
    setAddress(null);
    setExtra([]);
    setEnd(null);
    setCustvalue(null);
    setDepartvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setTasktitle(null);
    setSaving(false);
    setIsmemorized(false);
    setItemsList([]);
    setTotal(0);
    setInfo(null);
    setDayCost(null);
    setCalcs(null);
    setInvdays(0);
    setMemitem(null);
    setRtypevalue(null);
    setRtype2value(null);
    setTypefree(null);
    setShowEvents(false);
    setPhotosimages([]);
    setUrls([]);
    setPhotosurls([]);
    setDiscount(0);
  };

  const onSubmit = async () => {
    if (!start) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    if (taskoptions?.endrequire && !end) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب اضافة تاريخ الانتهاء' : 'End Date should be added'
      );
      return;
    }
    if (taskoptions?.customerrequire && !custvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة عميل' : 'Please add Customer'
      );
      return;
    }
    if (taskoptions?.resourserequire && !resovalue) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يرجى اضافة ${tempwords?.resourse}`
          : `Please add ${tempwords?.resourse}`
      );
      return;
    }
    if (
      taskoptions?.items &&
      taskoptions?.itemsrequire &&
      (!itemsList || itemsList.length === 0)
    ) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يجب اضافة عنصر واحد على الأقل`
          : `You should add min one service`
      );
      return;
    }

    setSaving(true);

    const items =
      !tempoptions?.itemrelations && !tempoptions?.invoiceitemrelations
        ? itemsList?.map((il: any) => {
            return {
              ...il,
              departmentId: departvalue?._id,
              departmentName: departvalue?.name,
              departmentNameAr: departvalue?.nameAr,
              departmentColor: departvalue?.color,
              employeeId: emplvalue?._id,
              employeeName: emplvalue?.name,
              employeeNameAr: emplvalue?.nameAr,
              employeeColor: emplvalue?.color,
              employeePhone: emplvalue?.phone,
              resourseId: resovalue?._id,
              resourseName: resovalue?.name,
              resourseNameAr: resovalue?.nameAr,
              resourseColor: resovalue?.color,
            };
          })
        : itemsList;

    let localphotos = [];
    if (photosimages) {
      const photosurl = await uploadMultiPhotoOnline(photosimages);
      if (photosurl && photosurl.length > 0) {
        const rphotos = photosurl.map((photo: any) =>
          photo.replace('http://', 'https://')
        );
        localphotos = rphotos;
      }
    }

    const fphotos = [...photosurls, ...localphotos];
    const photos = JSON.stringify(fphotos);

    const variables: any = {
      _id: memitem && memitem._id ? memitem._id : undefined, // is it new or edit
      title: tasktitle ? tasktitle : tempwords?.task,
      start,
      end,
      total,
      discount,
      amount: discount ? total - discount : total,
      typefree: typefree?.id,
      items: items?.length > 0 ? JSON.stringify(items) : undefined,
      photos: fphotos && fphotos.length > 0 ? photos : null,
      customer,
      department,
      employee,
      resourse,
      retype,
      retype2,
      info: taskoptions?.info ? JSON.stringify(info) : undefined,
      ismemorized,
      memId: isNew && memitem ? memitem?._id : undefined,
      periodCost: total,
      dayCost,
      desc,
      printdate,
      printtitle,
      deposit,
      address,
      extra: extra ? JSON.stringify(extra) : null,
    };
    const mutate = !memitem?._id ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
    setSaving(false);
  };
  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const day = weekdaysNNo?.[new Date(start).getDay()];

  const title = `${getPopupTitle('task', isNew)}  ${memitem?.title || ''}`;
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      print={handleReactPrint}
      maxWidth={'xl'}
      preventclose
      saving={saving}
      canceltitle={isRTL ? 'اغلاق' : 'close'}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              {taskoptions?.printdate && (
                <Grid item xs={3} style={{ marginTop: -20 }}>
                  <CalenderLocal
                    label={isRTL ? 'التاريخ' : 'Date'}
                    value={printdate}
                    onChange={(d: any) => setPrintdate(d)}
                    format="dd/MM/yyyy"
                    mb={0}
                    style={{
                      marginTop: 0,
                      width: 180,
                    }}
                  ></CalenderLocal>
                </Grid>
              )}
              <Grid item xs={3} style={{ marginTop: -20 }}>
                <CalenderLocal
                  label={words.start}
                  value={start}
                  onChange={(d: any) => setStart(d)}
                  format={
                    taskoptions?.time ? 'dd/MM/yyyy - hh:mm' : 'dd/MM/yyyy'
                  }
                  time={tempoptions?.time}
                  isRTL={isRTL}
                  mb={0}
                  style={{
                    marginTop: 0,
                    width: 180,
                  }}
                ></CalenderLocal>
              </Grid>
              <Grid item xs={3} style={{ marginTop: -20 }}>
                <CalenderLocal
                  value={end}
                  label={words.end}
                  onChange={(d: any) => {
                    if (d) {
                      setEnd(d);
                    } else {
                      setEnd(null);
                    }
                  }}
                  isRTL={isRTL}
                  format={
                    taskoptions?.time ? 'dd/MM/yyyy - hh:mm' : 'dd/MM/yyyy'
                  }
                  time={tempoptions?.time}
                  style={{
                    marginTop: 0,
                    width: 180,
                  }}
                  mb={0}
                ></CalenderLocal>
              </Grid>
              <Grid item xs={3} style={{ marginTop: -20 }}>
                {taskoptions?.duration && (
                  <AutoFieldLocal
                    name="type"
                    title={words?.duration}
                    words={words}
                    options={tasktypes}
                    value={typefree}
                    setSelectValue={setTypefree}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    nosort
                    mb={0}
                  ></AutoFieldLocal>
                )}
              </Grid>

              {taskoptions?.duration && invdays > 0 && (
                <Grid item xs={2}>
                  <Typography
                    style={{ marginTop: 25, marginLeft: 10, marginRight: 10 }}
                  >{`( ${invdays} ${isRTL ? 'يوم' : 'Day'} )`}</Typography>
                </Grid>
              )}
              {taskoptions?.printtitle && (
                <Grid item xs={6}>
                  <TextFieldLocal
                    name="printtitle"
                    label={isRTL ? 'عنوان للطباعة' : 'Print Title'}
                    value={printtitle}
                    onChange={(e: any) => setPrinttitle(e.target.value)}
                    row={memitem}
                    fullWidth
                    mb={0}
                  />
                </Grid>
              )}
              {taskoptions?.tasktitle && (
                <Grid item xs={6}>
                  <TextFieldLocal
                    required
                    autoFocus={true}
                    name="tasktitle"
                    label={words.name}
                    value={tasktitle}
                    onChange={(e: any) => setTasktitle(e.target.value)}
                    row={memitem}
                    fullWidth
                    mb={0}
                  />
                </Grid>
              )}
              {taskoptions?.customer && (
                <Grid item xs={6}>
                  <AutoFieldLocal
                    name="customer"
                    title={tempwords?.customer}
                    words={words}
                    options={customers}
                    value={custvalue}
                    setSelectValue={setCustvalue}
                    setSelectError={setCustError}
                    selectError={custError}
                    refernce={custRef}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    showphone
                    openAdd={openCustomer}
                    disabled={name === 'customerId'}
                  ></AutoFieldLocal>
                </Grid>
              )}

              {tempoptions?.docrelations && (
                <>
                  {taskoptions?.employee && tempoptions?.employee && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="employee"
                        title={tempwords?.employee}
                        words={words}
                        options={employees.filter((emp: any) => !emp.disabled)}
                        disabled={isemployee || name === 'employeeId'}
                        value={emplvalue}
                        setSelectValue={setEmplvalue}
                        setSelectError={setEmplError}
                        selectError={emplError}
                        refernce={emplRef}
                        register={register}
                        openAdd={openEmployee}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {taskoptions?.department && tempoptions?.department && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="department"
                        title={tempwords?.department}
                        words={words}
                        options={departments.filter(
                          (d: any) => d.depType === 1
                        )}
                        value={departvalue}
                        setSelectValue={setDepartvalue}
                        setSelectError={setDepartError}
                        selectError={departError}
                        refernce={departRef}
                        register={register}
                        openAdd={openDepartment}
                        isRTL={isRTL}
                        fullWidth
                        disabled={name === 'departmentId'}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                  {taskoptions?.resourse && tempoptions?.resourse && (
                    <Grid item xs={6}>
                      <AutoFieldLocal
                        name="resourse"
                        title={tempwords?.resourse}
                        words={words}
                        options={resourses}
                        disabled={name === 'resourseId'}
                        value={resovalue}
                        setSelectValue={setResovalue}
                        setSelectError={setResoError}
                        selectError={resoError}
                        refernce={resoRef}
                        register={register}
                        openAdd={openResourse}
                        isRTL={isRTL}
                        fullWidth
                        day={day}
                      ></AutoFieldLocal>
                    </Grid>
                  )}
                </>
              )}
              {tempoptions?.retype && taskoptions?.retype && (
                <Grid item xs={6}>
                  <AutoFieldLocal
                    name="retype"
                    title={tempwords?.evretype}
                    words={words}
                    options={retypes.filter((dep: any) => dep.reType === 4)}
                    value={rtypevalue}
                    setSelectValue={setRtypevalue}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              {tempoptions?.retype2 && taskoptions?.retype2 && (
                <Grid item xs={6}>
                  <AutoFieldLocal
                    name="retype2"
                    title={tempwords?.evretype2}
                    words={words}
                    options={retypes.filter((dep: any) => dep.reType === 5)}
                    value={rtype2value}
                    setSelectValue={setRtype2value}
                    register={register}
                    isRTL={isRTL}
                    fullWidth
                    mb={0}
                  ></AutoFieldLocal>
                </Grid>
              )}
              {taskoptions?.address && (
                <Grid item xs={6}>
                  <TextFieldLocal
                    autoFocus={true}
                    name="address"
                    label={words.theaddress}
                    value={address}
                    onChange={(e: any) => setAddress(e.target.value)}
                    row={memitem}
                    fullWidth
                    mb={0}
                  />
                </Grid>
              )}
              {taskoptions?.desc && (
                <Grid item xs={12}>
                  <TextFieldLocal
                    name="desc"
                    onChange={(e: any) => setDesc(e.target.value)}
                    value={desc}
                    label={words.info}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    mb={0}
                  />
                </Grid>
              )}
              {taskoptions?.items && (
                <Grid item xs={12}>
                  <Box
                    style={{
                      backgroundColor: '#f0f8ff',
                      marginTop: 10,
                      borderRadius: 10,
                      height: 390,
                    }}
                  >
                    <Box display="flex">
                      <ServiceItemForm
                        services={services}
                        products={products}
                        addItem={addItemToList}
                        words={words}
                        classes={classes}
                        user={user}
                        isRTL={isRTL}
                        setAlrt={setAlrt}
                        taskv={memitem}
                        itemrelations={
                          tempoptions?.invoiceitemrelations ||
                          tempoptions?.itemrelations
                        }
                      ></ServiceItemForm>
                    </Box>
                    <Box>
                      <ItemsTable
                        products={[...services, ...products]}
                        height={280}
                        rows={itemsList}
                        editItem={editItemInList}
                        removeItem={removeItemFromList}
                        isRTL={isRTL}
                        words={words}
                        user={user}
                        istask
                        itemrelations={
                          tempoptions?.invoiceitemrelations ||
                          tempoptions?.itemrelations
                        }
                      ></ItemsTable>
                      <TextField
                        name="discount"
                        label={words.discount}
                        value={discount?.toString()}
                        onChange={(e: any) =>
                          setDiscount(Number(e.target.value))
                        }
                        variant="outlined"
                        style={{ width: 200 }}
                        margin="dense"
                        type="number"
                        onFocus={(e) => e.target.select()}
                      />
                      {itemsList?.length > 0 && (
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: 16,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          {words.total} :{' '}
                          {moneyFormat(discount ? total - discount : total)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}

              {taskoptions?.events && (
                <Grid
                  item
                  xs={12}
                  style={{ backgroundColor: '#c6e2ff', marginTop: 10 }}
                >
                  <Grid container spacing={1} style={{ marginBottom: 5 }}>
                    <Grid item xs={8}>
                      <Box
                        style={{
                          marginTop: 8,
                          marginBottom: -8,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            marginRight: 20,
                            marginLeft: 20,
                          }}
                        >
                          {tempwords?.appointments}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      {!showEvents && isNew && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            style={{
                              height: 35,
                              marginBottom: -10,
                              marginTop: 5,
                            }}
                            onClick={async () => {
                              const variables: any = {
                                title: tasktitle ? tasktitle : tempwords?.task,
                                start,
                                end,
                                amount: total,
                                typefree: typefree?.id,
                                customer,
                                department,
                                employee,
                                resourse,
                                retype,
                                retype2,
                                info: taskoptions?.info
                                  ? JSON.stringify(info)
                                  : undefined,
                                periodCost: total,
                                dayCost,
                                desc,
                                printdate,
                                deposit,
                                address,
                                extra: extra ? JSON.stringify(extra) : null,
                              };
                              setLoading(true);
                              const res = await addAction({ variables });
                              if (res?.data?.createTask?.ok === true) {
                                const tsk = JSON.parse(
                                  res?.data?.createTask?.data
                                );
                                setMemitem(tsk);
                                setShowEvents(true);
                                setLoading(false);
                              }
                            }}
                          >
                            <Typography
                              style={{ fontSize: 14, fontWeight: 'bold' }}
                            >
                              {isRTL ? 'تفعيل' : 'Activate'}{' '}
                              {tempwords?.appointments}
                            </Typography>
                            {loading && (
                              <CircularProgress
                                style={{
                                  position: 'absolute',
                                  left: 30,
                                }}
                                color="primary"
                                size={16}
                              />
                            )}
                          </Button>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2} style={{}}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!((isNew && showEvents) || !isNew)}
                        style={{
                          height: 35,
                          marginBottom: -10,
                          marginTop: 5,
                        }}
                        onClick={() => setOpenMulti(true)}
                      >
                        <Typography
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          {isRTL ? 'اضافة متعدد' : 'Add Multi'}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {((isNew && showEvents) || !isNew) && (
                        <EventsTask
                          isRTL={isRTL}
                          words={words}
                          theme={theme}
                          id={memitem?._id}
                          name="contractId"
                          value={memitem}
                          company={company}
                          tempoptions={tempoptions}
                          openMulti={openMulti}
                        ></EventsTask>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              backgroundColor: fade(colors.grey[400], 0.1),
              borderRadius: 10,
              marginBottom: 10,
            }}
          >
            <Grid container spacing={1}>
              {!isNew && (
                <Grid item md={12}>
                  <PrintDownload
                    company={company}
                    printData={printData}
                    componentRef={componentRef}
                    handleReactPrint={handleReactPrint}
                    isRTL={isRTL}
                    template={template}
                    no={row?.docNo}
                    element="contract"
                  ></PrintDownload>
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {taskoptions?.dayCost && (
                    <Grid item xs={6}>
                      <TextFieldLocal
                        name="dayCost"
                        label={words.dayCost}
                        value={dayCost}
                        onChange={onChangeDayCost}
                        type="number"
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                  )}
                  {taskoptions?.dayCost && <Grid item xs={6}></Grid>}
                  {taskoptions?.info &&
                    info?.map((extra: any) => (
                      <Grid item xs={extra.multiline ? 12 : 6}>
                        <TextFieldLocal
                          name={extra.name}
                          label={isRTL ? extra.nameAr : extra.name}
                          value={extra.value}
                          type={extra.type}
                          multiline={extra.multiline}
                          rows={extra.multiline ? 4 : 1}
                          onChange={(e: any) =>
                            setExtraInfo({ item: extra, value: e.target.value })
                          }
                          fullWidth
                          mb={0}
                        />
                      </Grid>
                    ))}
                  {taskoptions?.deposit && (
                    <Grid item xs={12}>
                      <TextFieldLocal
                        name="deposit"
                        label={isRTL ? 'مبلغ تأمين' : 'Insurance Payment'}
                        value={deposit}
                        onChange={onChangeDeposit}
                        type="number"
                        fullWidth
                        mb={0}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {taskoptions?.extra && (
                <Grid item xs={12}>
                  <ExtraList
                    isRTL={isRTL}
                    list={extra}
                    setList={setExtra}
                  ></ExtraList>
                </Grid>
              )}
              {taskoptions?.photos && (
                <Grid item xs={12}>
                  <Box
                    style={{
                      height: 200,
                      margin: 5,
                    }}
                  >
                    <ImageView
                      images={urls}
                      removePhoto={removePhoto}
                      height={200}
                      small
                    ></ImageView>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flex: 1,
                      height: 40,
                      width: 200,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 5,
                    }}
                  >
                    <UploadPhotos
                      setImages={addToPhotos}
                      isRTL={isRTL}
                    ></UploadPhotos>
                  </Box>
                </Grid>
              )}
              {!isNew && taskoptions?.ismemorized && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ismemorized}
                        onChange={() => {
                          setIsmemorized(!ismemorized);
                        }}
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          color: theme.palette.primary.main,
                          marginTop: 5,
                          fontSize: 12,
                          fontWeight: 'bold',
                        }}
                        variant="subtitle1"
                      >
                        {isRTL ? 'حفظ النموذج' : 'Save Template'}
                      </Typography>
                    }
                  />
                </Grid>
              )}
              {isNew && taskoptions?.ismemorized && (
                <Grid item xs={12}>
                  <Paper
                    style={{
                      height: 270,
                      overflow: 'auto',
                    }}
                    elevation={3}
                  >
                    <Box style={{ flexDirection: 'row' }}>
                      {memorized?.map((mem: any) => {
                        return (
                          <Box
                            display="flex"
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              backgroundColor:
                                memitem === mem ? colors.blue[50] : '#f5f5f5',
                              margin: 10,
                              padding: 5,
                            }}
                          >
                            <Button
                              onClick={() => {
                                setMemitem(mem);
                              }}
                              fullWidth
                            >
                              {mem.title}
                            </Button>
                          </Box>
                        );
                      })}
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <PopupAppointmentMulti
          open={openMulti}
          onClose={() => setOpenMulti(false)}
          addAction={addMultiEvents}
          employees={employees}
          resourses={resourses}
          departments={departments}
          company={company}
          servicesproducts={services}
          theme={theme}
          task={memitem}
          calendar={calendar}
        ></PopupAppointmentMulti>
        <PopupCustomer
          newtext={newtext}
          open={openCust}
          onClose={onCloseCustomer}
          isNew={true}
          setNewValue={onNewCustChange}
          row={null}
          addAction={addCustomer}
          editAction={editCustomer}
        ></PopupCustomer>
        <PopupDeprtment
          newtext={newtext}
          open={openDep}
          onClose={onCloseDepartment}
          isNew={true}
          setNewValue={onNewDepartChange}
          row={null}
          addAction={addDepartment}
          editAction={editDepartment}
        ></PopupDeprtment>
        <PopupEmployee
          newtext={newtext}
          open={openEmp}
          onClose={onCloseEmploee}
          isNew={true}
          setNewValue={onNewEmplChange}
          row={null}
          addAction={addEmployee}
          editAction={editEmployee}
        ></PopupEmployee>
        <PopupResourses
          newtext={newtext}
          open={openRes}
          onClose={onCloseResourse}
          isNew={true}
          setNewValue={onNewResoChange}
          row={null}
          addAction={addResourse}
          editAction={editResourse}
        ></PopupResourses>
      </Box>
    </PopupLayout>
  );
};

export default PopupTaskFree;
