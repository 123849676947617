/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { useCategories, useTemplate } from '../../hooks';
import { downloadCSVFile, getRowId, userroles } from '../../common';
import { PopupCategory } from '../../pubups';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { Box, Button, Typography } from '@material-ui/core';
import { itemTypes } from '../../constants/datatypes';
import { TableComponent } from '../../Shared/TableComponent';
import {
  avataCategoryFormatter,
  categoryTypeFormat,
  booleanFormatter,
} from '../../Shared/colorFormat';
import PopupCategoryImport from '../../pubups/PopupCategoryImport';
import ImportBtn from '../../common/ImportBtn';
import ExportBtn from '../../common/ExportBtn';

const Categories = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme } = props;

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [catType, setCatType] = useState(itemTypes?.[1]);
  const [openImport, setOpenImport] = useState(false);

  const { tempoptions } = useTemplate();

  const [columns] = useState(
    [
      { name: 'avatar', title: ' ' },
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      { name: isRTL ? 'nameAr' : 'name', title: words.name },
      { name: 'catType', title: isRTL ? 'النوع' : 'Type' },
      tempoptions?.section && {
        name: isRTL ? 'sectionNameAr' : 'sectionName',
        title: isRTL ? 'التصنيف الرئيسي' : 'Section',
      },
      tempoptions?.pos && { name: 'ispos', title: isRTL ? 'نقطة بيع' : 'POS' },
      { name: 'order', title: isRTL ? 'الترتيب' : 'Order' },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 150 },
    { columnName: 'additionId', width: 150 },
    { columnName: isRTL ? 'nameAr' : 'name', width: 250 },
    { columnName: 'catType', width: 150 },
    { columnName: isRTL ? 'sectionNameAr' : 'sectionName', width: 150 },
    { columnName: 'ispos', width: 100 },
    { columnName: 'order', width: 100 },
  ]);

  const {
    categories,
    addCategory,
    addMultiCategory,
    editCategory,
    removeCategory,
    refreshcategory,
  } = useCategories();

  const rows = catType
    ? categories.filter((rt: any) => rt.catType === catType.id)
    : categories;

  useImperativeHandle(ref, () => ({
    refresh() {
      if (refreshcategory) {
        refreshcategory();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];

      const res = await removeCategory({ variables: { _id } });
      if (res?.data?.deleteCategory?.ok === false) {
        if (res?.data?.deleteCategory?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'categories', colnames);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          top: 72,
          right: isRTL ? undefined : 400,
          left: isRTL ? 400 : undefined,
        }}
      >
        {itemTypes
          ?.filter((it: any) => !it?.hide)
          .map((rt: any) => {
            return (
              <Button
                key={rt.id}
                color="primary"
                variant={rt === catType ? 'contained' : 'outlined'}
                onClick={() => setCatType(rt)}
                style={{ margin: 10, padding: 5, minWidth: 150 }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  {isRTL ? rt?.nameAr : rt?.name}
                </Typography>
              </Button>
            );
          })}
      </Box>

      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 68 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={[
            'avatar',
            isRTL ? 'nameAr' : 'name',
            'catType',
            isRTL ? 'sectionNameAr' : 'sectionName',
            'ispos',
            'order',
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={[]}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['catType']}
          formatterComponent={(props: any) =>
            categoryTypeFormat({ ...props, isRTL })
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={['avatar']}
          formatterComponent={avataCategoryFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['ispos']}
          formatterComponent={booleanFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.sales?.edit}
          showDeleteCommand={userroles?.sales?.delete}
          showAddCommand={userroles?.sales?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          theme={theme}
          addAction={addCategory}
          editAction={editCategory}
        >
          <PopupCategory catType={catType}></PopupCategory>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupCategoryImport
        open={openImport}
        onClose={() => setOpenImport(false)}
        addMultiItems={addMultiCategory}
        isRTL={isRTL}
        theme={theme}
        words={words}
        catType={catType?.id}
      ></PopupCategoryImport>
    </>
  );
});

export default Categories;
