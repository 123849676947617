import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import { useTemplate } from '../../../../hooks';
import useLandingChart from '../../../../hooks/useLandingChart';
import SalesStatus from '../../../../Shared/SalesStatus';

function SalesStatusBox({ isRTL, theme, words, height }) {
  const { tempwords, tempoptions } = useTemplate();
  const { salesMonth } = useLandingChart(null);
  return (
    <Box>
      {userroles?.invoices?.admin && (
        <SalesStatus
          isRTL={isRTL}
          words={words}
          theme={theme}
          tempwords={tempwords}
          tempoptions={tempoptions}
          salesMonth={salesMonth}
          height={height}
        ></SalesStatus>
      )}
    </Box>
  );
}

export default SalesStatusBox;
