/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  yup,
  messageAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { useTemplate } from '../hooks';
import SelectKeys from '../Sections/generator/components/SelectKeys';
import { colors } from '../constants';

const PopupStepItem = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  editAction,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [color, setColor] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { register, handleSubmit, errors, reset } = useForm(yup.brandResolver);
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const { tempoptions } = useTemplate();

  useEffect(() => {
    if (row?._id) {
      setColor(row?.color);
      setDisabled(row?.disabled);
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    if (isNew && (!data?.id || data?.id <= 10)) {
      await messageAlert(
        setAlrt,
        isRTL ? 'الرقم يجب ان يكون اكبر من 10' : 'No must be more than 10'
      );
      return;
    }
    setSaving(true);
    const name = data.name.trim();
    const id = data.id;
    const nameAr = !tempoptions?.nameonelang ? data.nameAr.trim() : name;
    const additionId = data.additionId;
    const variables: any = {
      _id: row._id ? row._id : undefined, // is it new or edit
      additionId: data?.additionId?.length > 0 ? additionId : undefined,
      id: id ? Number(id) : undefined,
      name,
      nameAr,
      color,
      disabled,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createStepItem' : 'updateStepItem';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem);
      closeModal();
      await successAlert(setAlrt, isRTL);
    } catch (error) {
      onError(error);
    }
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const closeModal = () => {
    reset();
    setColor('');
    setDisabled(false);
    onClose();
    setSaving(false);
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const title = isRTL
    ? isNew
      ? 'اضافة بند خطوة'
      : 'تعديل بند خطوة'
    : isNew
    ? 'New Step Item'
    : 'Edit Step Item';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            {tempoptions?.additionId && (
              <Grid item xs={12}>
                <TextFieldLocal
                  autoFocus={tempoptions?.additionId}
                  name="additionId"
                  label={words.no}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldLocal
                name="id"
                label={words.no}
                register={register}
                errors={errors}
                type="number"
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldLocal
                autoFocus={!tempoptions?.additionId}
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            {!tempoptions?.nameonelang && (
              <Grid item xs={12}>
                <TextFieldLocal
                  required
                  name="nameAr"
                  label={words.nameAr}
                  register={register}
                  errors={errors}
                  row={row}
                  fullWidth
                  mb={0}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <SelectKeys
                name={isRTL ? 'اللون' : 'Color'}
                options={colors}
                value={color}
                setValue={setColor}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disabled}
                      onChange={() => {
                        setDisabled(!disabled);
                      }}
                      name="disabled"
                      color="primary"
                    />
                  }
                  label={
                    <Typography>{isRTL ? 'غير مفعل' : 'Disabled'}</Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupStepItem;
