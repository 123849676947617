import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { CalenderLocal } from '../../components';
import { useTemplate } from '../../hooks';
import { PopupTextField } from '../../Shared';

const GeneralOptions = ({
  fullscreen,
  setFullscreen,
  scrollbar,
  setScrollbar,
  tirms,
  setTirms,
  isRTL,
  daysales,
  setDaysales,
  minstock,
  setMinstock,
}: any) => {
  const [startFirst, setstartFirst] = useState(
    tirms?.startFirst ? new Date(tirms?.startFirst) : null
  );
  const [sday, setSday] = useState(daysales);
  const [min, setMin] = useState(minstock);
  const [endFirst, setendFirst] = useState(
    tirms?.endFirst ? new Date(tirms?.endFirst) : null
  );
  const [startSecond, setstartSecond] = useState(
    tirms?.startSecond ? new Date(tirms?.startSecond) : null
  );
  const [endSecond, setendSecond] = useState(
    tirms?.endSecond ? new Date(tirms?.endSecond) : null
  );
  const { tempoptions } = useTemplate();

  const onSubmit = ({ startFirst, endFirst, startSecond, endSecond }) => {
    setTirms({
      startFirst,
      endFirst,
      startSecond,
      endSecond,
    });
  };

  const updateSalesPerDay = () => {
    setDaysales(sday);
    setMinstock(min);
  };

  return (
    <Paper style={{ minHeight: 350, marginTop: 10 }}>
      <Box padding={3}>
        <Typography variant="h6">{isRTL ? 'عام' : 'General'}</Typography>
        <Box
          style={{
            marginTop: 20,
          }}
        >
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fullscreen}
                  onChange={() => setFullscreen(!fullscreen)}
                  name="bold"
                  color="primary"
                />
              }
              label={isRTL ? 'نوافذ كامل الشاشة' : 'Fullscreen Modal'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={scrollbar}
                  onChange={() => setScrollbar(!scrollbar)}
                  name="bold"
                  color="primary"
                />
              }
              label={isRTL ? 'مسطرة افتراضية' : 'Default Scrollbar'}
            />
          </Grid>
          {tempoptions?.tirms && (
            <Paper style={{ padding: 20 }} elevation={5}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>
                  {isRTL ? 'توقيت الفصول الدراسية' : 'School Tirms Time'}
                </Typography>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <CalenderLocal
                    isRTL={isRTL}
                    label={isRTL ? 'بداية الفصل الاول' : 'First Tirm Start'}
                    value={startFirst}
                    onChange={(d: any) => {
                      setstartFirst(d);
                      onSubmit({
                        startFirst: d,
                        endFirst,
                        startSecond,
                        endSecond,
                      });
                    }}
                    format="dd/MM/yyyy"
                  ></CalenderLocal>
                  <CalenderLocal
                    isRTL={isRTL}
                    label={isRTL ? 'نهاية الفصل الاول' : 'First Tirm End'}
                    value={endFirst}
                    onChange={(d: any) => {
                      setendFirst(d);
                      onSubmit({
                        startFirst,
                        endFirst: d,
                        startSecond,
                        endSecond,
                      });
                    }}
                    format="dd/MM/yyyy"
                  ></CalenderLocal>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <CalenderLocal
                    isRTL={isRTL}
                    label={isRTL ? 'بداية الفصل الثاني' : 'Second Tirm Start'}
                    value={startSecond}
                    onChange={(d: any) => {
                      setstartSecond(d);
                      onSubmit({
                        startFirst,
                        endFirst,
                        startSecond: d,
                        endSecond,
                      });
                    }}
                    format="dd/MM/yyyy"
                  ></CalenderLocal>
                  <CalenderLocal
                    isRTL={isRTL}
                    label={isRTL ? 'نهاية الفصل الثاني' : 'Second Tirm End'}
                    value={endSecond}
                    onChange={(d: any) => {
                      setendSecond(d);
                      onSubmit({
                        startFirst,
                        endFirst,
                        startSecond,
                        endSecond: d,
                      });
                    }}
                    format="dd/MM/yyyy"
                  ></CalenderLocal>
                </Box>
              </Grid>
            </Paper>
          )}
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6}>
              <PopupTextField
                name="end"
                label={
                  isRTL ? 'المبيعات اليومية المتوقعة' : 'Expected Sales per Day'
                }
                value={sday ? sday : 0}
                onChange={(e: any) => setSday(Number(e.target.value))}
                type="number"
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12} md={6}>
              <PopupTextField
                name="end"
                label={isRTL ? 'الحد الادنى للمخزن' : 'Minimum Stock'}
                value={min ? min : 0}
                onChange={(e: any) => setMin(Number(e.target.value))}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                onClick={updateSalesPerDay}
              >
                <Typography>{isRTL ? 'حفظ' : 'Save'}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default GeneralOptions;
