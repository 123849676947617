import { makeStyles } from '@material-ui/core';

export const useClassnames = makeStyles({
  center: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  left: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
  },
  pl1: {
    paddingLeft: 10,
  },
  pr1: {
    paddingRight: 10,
  },
  border: {},
  bt: {},
  bb: {},
  bl: {},
  br: {},

  hvr: {
    '&:hover': {
      background: '#f0f8ff',
      cursor: 'pointer',
    },
  },
});
const styleclassnames = {
  center: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  left: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  h1: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  pl1: {
    paddingLeft: 7,
  },
  pr1: {
    paddingRight: 7,
  },
  border: {},
  bt: {},
  bb: {},
  bl: {},
  br: {},

  hvr: {
    '&:hover': {
      background: '#f0f8ff',
      cursor: 'pointer',
    },
  },
};

export const cnames = [
  'center',
  'right',
  'left',
  'h1',
  'pl1',
  'pr1',
  'border',
  'bt',
  'bb',
  'bl',
  'br',
];

export const getClassNames = ({ classnames, classes }) => {
  if (!classnames) return '';
  const clss = classnames.map((cn: any) => classes?.[cn]);
  if (clss?.length > 0) {
    return clss.join(' ');
  } else {
    return '';
  }
};
export const getClassNamesStyle = ({ classnames }) => {
  let styles: any = {};
  if (!classnames) return '';
  if (classnames?.length > 0) {
    for (const cl of classnames) {
      styles = { ...styles, ...styleclassnames[cl] };
    }
  }
  return styles;
};
