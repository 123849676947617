/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ENFlag } from '../components/ENFlag';
import { QAFlag } from '../components/QAFlag';

export default function LanguageSwitch({
  lang,
  bc = '#ddd',
  isRTL,
  handleLanguage,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    lang === 'ar' ? 0 : 1
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArabic = () => {
    if (handleLanguage && lang !== 'ar') {
      handleLanguage('ar');
      setSelectedIndex(0);
    }
  };
  const handleEnglish = () => {
    if (handleLanguage && lang !== 'en') {
      handleLanguage('en');
      setSelectedIndex(1);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-language' : undefined;

  return (
    <Box>
      <IconButton
        style={{
          width: 45,
          height: 45,
          padding: 0,
          margin: 0,
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        <Paper
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 35,
            height: 23,
            borderRadius: 0,
          }}
        >
          {lang === 'en' ? (
            <ENFlag width="33px" height="24px" />
          ) : (
            <QAFlag width="35px" height="21px" />
          )}
        </Paper>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { borderRadius: 15, marginTop: 5 } }}
      >
        <Box>
          <List style={{ minWidth: 150, padding: 10 }}>
            <ListItem
              button
              onClick={handleArabic}
              selected={selectedIndex === 0}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'العربية' : 'Arabic'}</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={handleEnglish}
              selected={selectedIndex === 1}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'الانكليزية' : 'English'}</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
}
