/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { subscribePushToken } from '../common/helpers';
import { updateNotifiRead } from '../graphql/mutation';
import updatePushToken from '../graphql/mutation/updatePushToken';
import getNotifications from '../graphql/query/getNotifications';
import { covertToTimeDateDigit } from './colorFormat';
import MyIcon from './MyIcon';

export default function NotificationIcvon({
  size = 44,
  bc = '#ddd',
  user,
  isRTL,
  navigate,
  dispatch,
  notify,
  notifisub,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newin, setNewin] = useState(0);

  const [loadMsgs, msgsData]: any = useLazyQuery(getNotifications);
  const [updatePush] = useMutation(updatePushToken);

  useEffect(() => {
    loadMsgs();
  }, [anchorEl]);
  useEffect(() => {
    if (msgsData?.data?.getNotifications?.data) {
      const { data } = msgsData.data.getNotifications;
      const slicedArray = data?.slice(0, 10);
      const newin = data?.filter((d: any) => !d?.read)?.length;
      setNewin(newin && newin > 0 ? newin : 0);
      setRows(slicedArray);
    }
  }, [msgsData]);

  useEffect(() => {
    if (
      notifisub?.data?.notifiAdded?.userId &&
      notifisub?.data?.notifiAdded?.userId === user?._id
    ) {
      msgsData?.refetch();
    }
  }, [notifisub]);
  const [read] = useMutation(updateNotifiRead);

  const handleSubscribe = async (e: any) => {
    setLoading(true);
    const { checked } = e.target;
    dispatch({ type: 'setNotify', payload: checked });
    try {
      const pushToken = await subscribePushToken(user, checked);
      const variables = { pushToken, notify: checked };
      await updatePush({ variables });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotification = ({ _id, isread }: any) => {
    if (_id && !isread) {
      read({ variables: { _id } });
    }
    if (navigate) {
      navigate('/notifications');
    }
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <IconButton
        style={{
          padding: 10,
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={newin}
          color="error"
        >
          <MyIcon icon="bell" size={25} color="#555"></MyIcon>
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { borderRadius: 15, marginTop: 5 } }}
      >
        <Box>
          <List
            style={{
              width: 350,
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={notify}
                          onChange={handleSubscribe}
                          name="checkedA"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          disabled={loading}
                        />
                      }
                      labelPlacement="end"
                      label=""
                    />
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>
                      {isRTL ? 'التنبيهات' : 'Notifications'}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
            {rows.map((item: any) => {
              return (
                <>
                  <Divider></Divider>
                  <ListItem
                    key={item?._id}
                    button
                    onClick={() =>
                      handleNotification({ _id: item?._id, isread: item?.item })
                    }
                  >
                    <ListItemText>
                      <Box style={{ margin: 5, marginTop: 5, marginBottom: 5 }}>
                        <Typography variant="caption">
                          {covertToTimeDateDigit(item?.createdAt)}
                        </Typography>
                        <Typography>{item?.body?.substring(0, 70)}</Typography>
                      </Box>
                    </ListItemText>
                  </ListItem>
                </>
              );
            })}
          </List>
        </Box>
      </Popover>
    </Box>
  );
}
