export const drawerWidth = 250;

// export const CLOUD_NAME = 'fivegstore';
// export const UPLOAD_PRESET = 'sfnboslv';
export const CLOUD_NAME = 'tadween';
export const UPLOAD_PRESET = 'f8j1tsev';
export const imageUploadOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1280,
  useWebWorker: true,
};
