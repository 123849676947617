/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import TabsList from '../components/TabsList';
import useCompany from '../../hooks/useCompany';
import { getStoreItem, setStoreItem } from '../../store';
import {
  initSupplierContext,
  SupplierContext,
  supplierReducer,
} from '../../contexts/managment';
import {
  initPaymentContext,
  initPurchaseContext,
  PaymentContext,
  paymentReducer,
  PurchaseContext,
  purchaseReducer,
} from '../../contexts';
import PurchaseInvoices from './PurchaseInvoices';
import Payment from './Payment';
import Suppliers from '../shared/Suppliers';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export default function PurchaseOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { height, width } = useWindowDimensions();
  const { company } = useCompany();

  const invoiceref: any = useRef();
  const paymentref: any = useRef();
  const supplierref: any = useRef();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const purchaseStoreState = getStoreItem('purchaseStore', initPurchaseContext);
  const [purchaseStore, purchaseDispatch] = useReducer(
    purchaseReducer,
    purchaseStoreState ? purchaseStoreState : initPurchaseContext
  );
  useEffect(() => {
    setStoreItem('purchaseStore', purchaseStore);
  }, [purchaseStore]);

  const paymentStoreState = getStoreItem('paymentStore', initPaymentContext);
  const [paymentStore, paymentDispatch] = useReducer(
    paymentReducer,
    paymentStoreState ? paymentStoreState : initPaymentContext
  );
  useEffect(() => {
    setStoreItem('paymentStore', paymentStore);
  }, [paymentStore]);

  const supplierStoreState = getStoreItem('supplierStore', initSupplierContext);
  const [supplierStore, supplierDispatch] = useReducer(
    supplierReducer,
    supplierStoreState ? supplierStoreState : initSupplierContext
  );
  useEffect(() => {
    setStoreItem('supplierStore', supplierStore);
  }, [supplierStore]);

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };

  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const refresh = () => {
    if (pathname?.includes('pinvoices')) {
      invoiceref?.current?.refresh();
    }
    if (pathname?.includes('payments')) {
      paymentref?.current?.refresh();
    }
    if (pathname?.includes('suppliers')) {
      supplierref?.current?.refresh();
    }
  };

  const bgcolor = '#F2B50508';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
            marginTop: 5,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Paper
          elevation={5}
          style={{
            borderRadius: 25,
            margin: 25,
            marginTop: 5,
            width: width - drawerWidth - 50,
            height: height - 135,
          }}
        >
          <TabsList
            handleSelect={handleSelect}
            isRTL={isRTL}
            list={formgroup}
            theme={theme}
            width={width - drawerWidth - 50}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            pathname={pathname}
          ></TabsList>
          <Paper
            style={{
              display: 'flex',
              height: height - 185,
              overflow: 'auto',
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            <Routes>
              <Route
                index
                element={<Navigate to={formgroup?.[0]?.path} replace />}
              />
              <Route
                path={'pinvoices'}
                element={
                  <PurchaseContext.Provider
                    value={{ state: purchaseStore, dispatch: purchaseDispatch }}
                  >
                    <PurchaseInvoices
                      ref={invoiceref}
                      start={start}
                      end={end}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></PurchaseInvoices>
                  </PurchaseContext.Provider>
                }
              />
              <Route
                path={'payments'}
                element={
                  <PaymentContext.Provider
                    value={{ state: paymentStore, dispatch: paymentDispatch }}
                  >
                    <Payment
                      ref={paymentref}
                      start={start}
                      end={end}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></Payment>
                  </PaymentContext.Provider>
                }
              />
              <Route
                path={'suppliers'}
                element={
                  <SupplierContext.Provider
                    value={{ state: supplierStore, dispatch: supplierDispatch }}
                  >
                    <Suppliers
                      ref={supplierref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                      drawerWidth={drawerWidth}
                    ></Suppliers>
                  </SupplierContext.Provider>
                }
              />
            </Routes>
          </Paper>
        </Paper>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </Box>
    </PageLayout>
  );
}
