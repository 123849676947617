/* eslint-disable no-new-func */
import { useState } from 'react';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import {
  renderButton,
  basicitem,
  renderCell,
  rid,
  basicempitem,
} from './renders';
import ItemStyle from './ItemStyle';
import { useWindowDimensions } from '../../../hooks';
import { downloadJSONFile } from '../components/UploadJson';

const LandingBuilder = ({
  rows,
  setRows,
  isRTL,
  theme,
  words,
  company,
  options,
  landing,
}: any) => {
  const { height } = useWindowDimensions();
  const [selected, setSelected] = useState(null);
  const [sub, setSub] = useState(null);
  const addItem = () => {
    const item = landing
      ? [{ id: rid(), ...basicitem }]
      : [{ id: rid(), ...basicempitem }];
    const newites = [...rows, item];
    setRows(newites);
  };
  const insertItem = () => {
    const item = landing
      ? [{ id: rid(), ...basicitem }]
      : [{ id: rid(), ...basicempitem }];
    const newites = [...rows];
    newites.splice(selected, 0, item);
    setRows(newites);
  };
  const removeSelected = () => {
    const nrows = [...rows];
    nrows.splice(selected, 1);
    setRows(nrows);
    setSelected(null);
  };
  const addSubItem = () => {
    if (selected === null) return;
    const nrows = [...rows];
    nrows?.[selected].push(
      landing ? { id: rid(), ...basicitem } : { id: rid(), ...basicempitem }
    );
    setRows(nrows);
  };
  const insertSubItem = () => {
    if (selected === null) return;
    const item = landing
      ? { id: rid(), ...basicitem }
      : { id: rid(), ...basicempitem };
    const nrows = [...rows];
    const row = nrows?.[selected];
    const index = row.indexOf(sub);
    row.splice(index, 0, item);
    nrows[selected] = row;
    setRows(nrows);
  };
  const removeSubItem = () => {
    const nrows = [...rows];
    const row = rows[selected];
    if (row.length === 1) return;
    const nrow = row?.filter((it: any) => it?.id !== sub.id);
    nrows[selected] = nrow;
    setRows(nrows);
  };
  const setRow = (nrow: any) => {
    setSub(nrow);
    const nrows = [...rows];
    const row = rows[selected];
    if (!row) return;
    const srow = row?.map((r: any) => {
      if (nrow.id === r.id) {
        return nrow;
      } else {
        return r;
      }
    });
    nrows[selected] = srow;
    setRows(nrows);
  };
  const renderSide = () => {
    return (
      <Box style={{ height: height - 150, padding: 10 }}>
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={12}>
            <Button onClick={() => downloadJSONFile(rows)}>Download</Button>
          </Grid>
          <Grid item xs={4}>
            {renderButton('Add Row', addItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Insert', insertItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Remove', removeSelected)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Add Sub', addSubItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Insert', insertSubItem)}
          </Grid>
          <Grid item xs={4}>
            {renderButton('Remove', removeSubItem)}
          </Grid>
          <Grid item xs={12}>
            <ItemStyle row={sub} setRow={setRow} options={options}></ItemStyle>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          {renderSide()}
        </Grid>
        <Grid item xs={9}>
          <Paper
            style={{
              backgroundColor: '#aaa',
              padding: 20,
              paddingRight: 50,
              overflow: 'auto',
              height: height - 200,
            }}
          >
            <Paper
              style={{
                width: 1670,
                minHeight: 3000,
                zoom: 0.7,
                backgroundColor: '#f5f5f5',
                padding: 15,
                overflow: 'auto',
              }}
              elevation={5}
            >
              {rows?.map((listitem: any, index: any) => {
                return (
                  <Grid container spacing={2}>
                    {listitem?.map((item: any) => {
                      const { id, size, skip, type } = item;
                      const args = {
                        ...item,
                        isRTL,
                        theme,
                        words,
                        company,
                      };
                      const subactive = sub?.id === id;
                      const shadow = {
                        border: '1px solid #20b2aa',
                      };
                      return (
                        <>
                          {skip > 0 && <Grid item xs={skip}></Grid>}
                          <Grid
                            item
                            xs={size}
                            style={subactive ? shadow : {}}
                            onClick={() => {
                              if (subactive) {
                                setSelected(null);
                                setSub(null);
                              } else {
                                setSelected(index);
                                setSub(item);
                              }
                            }}
                          >
                            {renderCell(type, args, false)}
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                );
              })}
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingBuilder;
