import { Box, Container, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { GlobalContext } from '../../contexts';
import MyIcon from '../../Shared/MyIcon';
import { GContextTypes } from '../../types';
import { SelectTheme } from '../calendar/common/SelectTheme';
import Login from './Login';
import Logo from './Logo';
import { useWindowDimensions } from '../../hooks';

export default function Auth() {
  const {
    dispatch,
    store: { wrongTimes, startBlock, lang, themeId },
    translate: { isRTL, words },
  }: GContextTypes = useContext(GlobalContext);
  const { height, isMobile } = useWindowDimensions();
  const onThemeSelect = (id: any) => {
    dispatch({ type: 'setThemeId', payload: id });
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        backgroundColor: '#eee',
        padding: 40,
        height: height - 20,
        borderRadius: 10,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Logo></Logo>
          {!isMobile && (
            <>
              <div style={{ height: 30 }}></div>
              <Box
                display="flex"
                style={{
                  cursor: 'pointer',
                  padding: 5,
                  alignItems: 'center',
                  margin: 40,
                }}
                onClick={async () => {
                  dispatch({
                    type: 'setLang',
                    payload: lang === 'ar' ? 'en' : 'ar',
                  });
                }}
              >
                <Tooltip
                  title={
                    lang === 'ar'
                      ? 'Change to English'
                      : 'تغيير الى اللغة العربية'
                  }
                >
                  <Box style={{ padding: 5, marginTop: 5 }}>
                    <MyIcon size={28} icon={'lang'}></MyIcon>
                  </Box>
                </Tooltip>
                <Typography style={{ fontWeight: 'bold' }}>
                  {lang === 'ar' ? 'English' : 'اللغة العربية'}
                </Typography>
              </Box>
              <SelectTheme
                themeId={themeId}
                onChange={onThemeSelect}
              ></SelectTheme>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{ backgroundColor: '#fff' }}>
          <Login
            isRTL={isRTL}
            height={height}
            dispatch={dispatch}
            wrongTimes={wrongTimes}
            startBlock={startBlock}
            words={words}
          ></Login>
        </Grid>
      </Grid>
    </Container>
  );
}
