import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React from 'react';

export default function CheckFilter({ value, setVlaue, title }) {
  return (
    <Box
      style={{
        display: 'flex',
        padding: 0,
        margin: 0,
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            style={{ padding: 7 }}
            checked={value}
            onChange={() => setVlaue(!value)}
            color="primary"
          />
        }
        label={<Typography variant="subtitle2">{title}</Typography>}
        style={{ fontSize: 14 }}
      />
    </Box>
  );
}
