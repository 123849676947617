import { templates } from './templates';
import { mainmenu, emplmenu } from './menu';
import { colors, colorsList, colorsList2 } from './colors';
import { drawerWidth } from './sizes';
import * as yup from './yupSchemas';
import {
  statusTypes,
  eventStatusEn,
  eventStatus,
  paymentTypes,
  carstatuss,
} from './datatypes';
import { operationTypes, catTypes } from './datatypes';
import { packages } from './roles';

export const burl = 'jadwal';

// export const graphqlURI = `https://jadwal.app.jadwal.io/graphql`;
// export const wsURI = `wss://jadwal.app.jadwal.io/graphql`;
export const graphqlURI = `https://jadwal.jadwal.io/graphql`;
export const wsURI = `wss://jadwal.jadwal.io/graphql`;

// export const graphqlURI = 'http://jadwal-server/graphql';
// export const wsURI = 'ws://jadwal-server/graphql';

export const appversion = '1.01';
export {
  mainmenu,
  emplmenu,
  catTypes,
  colors,
  colorsList,
  colorsList2,
  yup,
  drawerWidth,
  statusTypes,
  eventStatusEn,
  eventStatus,
  operationTypes,
  paymentTypes,
  templates,
  packages,
  carstatuss,
};
