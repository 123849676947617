import { gql } from '@apollo/client';
export default gql`
  query getJadwalInvoices($start: Date, $end: Date, $due: Boolean) {
    getJadwalInvoices(start: $start, end: $end, due: $due) {
      ok
      error
      count
      data {
        _id
        autoNo
        time
        title
        desc
        receiptId
        customerId
        customerName
        customerNameAr
        customerPhone
        items {
          name
          qty
          price
          total
        }
        total
        discount
        amount
        isPaid
        userId
        note
        createdAt
        updatedAt
      }
    }
  }
`;
