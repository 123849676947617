/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import TabsList from '../components/TabsList';
import useCompany from '../../hooks/useCompany';
import useAccounts from '../../hooks/useAccounts';
import ReceiptAdvance from './ReceiptAdvance';
import PaymentAdvance from './PaymentAdvance';
import Finance from './Finance';
import FinanceAllKaid from './FinanceAllKaid';
import Accounts from '../shared/Accounts';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import DocKaid from './DocKaid';
import UploadDoc from './UploadDoc';
import ArchiveDoc from './ArchiveDoc';

export default function FinanceOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const docref: any = useRef();
  const receiptref: any = useRef();
  const paymentref: any = useRef();
  const financeref: any = useRef();
  const kaidref: any = useRef();
  const accountref: any = useRef();
  const updocref: any = useRef();
  const archiveref: any = useRef();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { height, width } = useWindowDimensions();
  const { company } = useCompany();
  const { accounts } = useAccounts();

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };

  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const refresh = () => {
    if (pathname?.includes('transfer')) {
      financeref?.current?.refresh();
    }
    if (pathname?.includes('adpayment')) {
      paymentref?.current?.refresh();
    }
    if (pathname?.includes('adreceipt')) {
      receiptref?.current?.refresh();
    }
    if (pathname?.includes('doc')) {
      docref?.current?.refresh();
    }
    if (pathname?.includes('uploads')) {
      updocref?.current?.refresh();
    }
    if (pathname?.includes('archive')) {
      archiveref?.current?.refresh();
    }
    if (pathname?.includes('entry')) {
      kaidref?.current?.refresh();
    }
    if (pathname?.includes('accounts')) {
      accountref?.current?.refresh();
    }
  };

  const bgcolor = '#ae2ae212';
  // transfer
  // adpayment
  // adreceipt
  // entry
  // accounts
  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
            marginTop: 5,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Paper
          elevation={5}
          style={{
            borderRadius: 25,
            margin: 25,
            marginTop: 5,
            width: width - drawerWidth - 50,
            height: height - 135,
          }}
        >
          <TabsList
            handleSelect={handleSelect}
            isRTL={isRTL}
            list={formgroup}
            theme={theme}
            width={width - drawerWidth - 50}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            pathname={pathname}
          ></TabsList>
          <Paper
            style={{
              display: 'flex',
              height: height - 185,
              overflow: 'auto',
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            <Routes>
              <Route
                index
                element={<Navigate to={formgroup?.[0]?.path} replace />}
              />
              <Route
                path={'uploads'}
                element={
                  <UploadDoc
                    ref={updocref}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                    company={company}
                  ></UploadDoc>
                }
              />
              <Route
                path={'archive'}
                element={
                  <ArchiveDoc
                    ref={archiveref}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                    company={company}
                  ></ArchiveDoc>
                }
              />
              <Route
                path={'doc'}
                element={
                  <DocKaid
                    ref={docref}
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                    company={company}
                  ></DocKaid>
                }
              />
              <Route
                path={'transfer'}
                element={
                  <Finance
                    ref={financeref}
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                  ></Finance>
                }
              />
              <Route
                path={'adpayment'}
                element={
                  <PaymentAdvance
                    ref={paymentref}
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                    company={company}
                    drawerWidth={drawerWidth}
                  ></PaymentAdvance>
                }
              />
              <Route
                path={'adreceipt'}
                element={
                  <ReceiptAdvance
                    ref={receiptref}
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                    company={company}
                    drawerWidth={drawerWidth}
                  ></ReceiptAdvance>
                }
              />
              <Route
                path={'entry'}
                element={
                  <FinanceAllKaid
                    ref={kaidref}
                    start={start}
                    end={end}
                    isRTL={isRTL}
                    words={words}
                    theme={theme}
                    company={company}
                  ></FinanceAllKaid>
                }
              />
              <Route
                path={'accounts'}
                element={
                  <Accounts
                    isRTL={isRTL}
                    ref={accountref}
                    accounts={accounts}
                  ></Accounts>
                }
              />
            </Routes>
          </Paper>
        </Paper>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </Box>
    </PageLayout>
  );
}
