import { gql } from '@apollo/client';

export default gql`
  mutation updateDocUploadStatus(
    $docId: String
    $stepId: Int
    $desc: String
    $attachments: String
  ) {
    updateDocUploadStatus(
      docId: $docId
      stepId: $stepId
      desc: $desc
      attachments: $attachments
    ) {
      ok
      message
      data
      error
    }
  }
`;
