/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, updateOpDocRefNumbers } from '../../common';
import {
  calculateAmount,
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatter,
  moneyFormat,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import {
  Box,
  Checkbox,
  colors,
  fade,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import { getMonthlyReport } from '../../graphql';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import PageLayout from '../main/PageLayout';
import DateNavigatorReports from '../../components/filters/DateNavigatorReports';
import { FinanceContext } from '../../contexts';
import FilterSelectCkeckBox from '../../Shared/FilterSelectCkeckBox';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useReactToPrint } from 'react-to-print';
import { useTemplate } from '../../hooks';
import { SearchTable } from '../../components';
import usePrintTemplates from '../../hooks/usePrintTemplates';
import { getDefaultTemplate } from '../../common/helpers';
import PrintDownload from '../../Shared/PrintDownload';

const styles = (theme: any) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function FinanceReport({
  isRTL,
  words,
  menuitem,
  accounts,
  company,
  theme,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [rows, setRows] = useState([]);
  const [isRaseed, setIsRaseed] = useState(true);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const { printtemplates } = usePrintTemplates();

  const [columns] = useState(
    [
      col.opTime,
      col.acc,
      col.opDocNo,
      col.refNo,
      col.opType,
      tempoptions?.contract && col.contract,
      col.customer,
      col.opAcc,
      tempoptions?.employee && col.employee,
      col.amount,
      col.amountdebit,
      col.amountcredit,
      col.rased,
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
    { columnName: col.amount.name, togglingEnabled: false },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getMonthlyReport, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    state: {
      currentDate,
      currentViewName,
      endDate,
      accvalue,
      paccvalue,
      group,
      groupby,
      sumcolumn,
      sort,
    },
    dispatch,
  } = useContext(FinanceContext);
  const { height } = useWindowDimensions();
  const currentViewNameChange = (e: any) => {
    dispatch({ type: 'setCurrentViewName', payload: e.target.value });
  };
  const currentDateChange = (curDate: any) => {
    dispatch({ type: 'setCurrentDate', payload: curDate });
  };
  const endDateChange = (curDate: any) => {
    dispatch({ type: 'setEndDate', payload: curDate });
  };

  const setAccvalueDispatch = (value: any) => {
    dispatch({ type: 'setAccvalue', payload: value ? [value] : [] });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getMonthlyReport']?.data || [];
    const balance = summaryData?.data?.['getMonthlyReport']?.message || null;
    const updatedRows = slsData.map((x: any) => x);
    const amount = isRaseed && balance ? JSON.parse(balance) : null;
    const isCredit = accvalue?.[0]?.accType === 2;
    if (amount !== null) {
      const { credit, debit } = amount;
      if (credit || debit) {
        const am = isCredit ? credit - debit : debit - credit;
        const isPlus = am >= 0;
        updatedRows.unshift({
          _id: Date.now(),
          accNameAr: 'رصيد افتتاحي',
          accName: 'Opening Balancee',
          opTime: start,
          opType: 94,
          credit:
            (isCredit && isPlus) || (!isCredit && !isPlus) ? Math.abs(am) : 0,
          debit:
            (isCredit && !isPlus) || (!isCredit && isPlus) ? Math.abs(am) : 0,
          amount: Math.abs(am),
        });
      }
    }

    let rased = 0;
    const updatedRows2 =
      updatedRows?.length > 0
        ? updatedRows.map((item: any) => {
            const rowRased = isCredit
              ? item.credit - item.debit
              : item.debit - item.credit;
            rased = rased + rowRased;
            return {
              ...item,
              amount: calculateAmount(item),
              rased,
            };
          })
        : [];

    const rdata = updateOpDocRefNumbers(updatedRows2);

    setRows(rdata);
  }, [summaryData, isRaseed]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;
  const getPcode = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv.code) : undefined;

  const fetchData = () => {
    const variables = {
      parentcodes: getPcode(paccvalue),
      accountIds: getIds(accvalue),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
    };
    getSummary({
      variables,
    });
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [start, end, group, groupby, accvalue, paccvalue, sumcolumn]);

  const faccounts =
    paccvalue?.length > 0
      ? accounts.filter((ac: any) => ac?.parentcode === paccvalue?.[0]?.code)
      : accounts;

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `finance-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const refresh = () => {
    summaryData?.refetch();
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'credit', type: 'sum' },
    { columnName: 'debit', type: 'sum' },
  ];
  const componentRef: any = useRef();

  const template = getDefaultTemplate(printtemplates, 'financereport');

  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Finance Report`,
    removeAfterPrint: true,
  });

  const printData = {
    start,
    end,
    items: rows,
    rased: rows?.[rows?.length - 1]?.rased,
    isRTL,
    date: new Date(),
  };

  const bal = rows?.[rows?.length - 1]?.rased;
  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      loading={summaryData?.loading}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
          backgroundColor: '#fff',
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        {(accvalue?.length > 0 || paccvalue?.length > 0) && (
          <Box
            style={{
              position: 'absolute',
              left: isRTL ? 250 : undefined,
              right: isRTL ? undefined : 250,
              top: -8,
              zIndex: 112,
            }}
          >
            <PrintDownload
              company={company}
              printData={printData}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              isRTL={isRTL}
              template={template}
              no={'finance report'}
              element="financereport"
            ></PrintDownload>
          </Box>
        )}
        <Box
          display="flex"
          style={{
            position: 'absolute',
            zIndex: 111,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>

          <Box
            display="flex"
            style={{
              height: 38,
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <FilterSelectCkeckBox
              options={faccounts}
              value={accvalue?.[0]}
              setValue={setAccvalueDispatch}
              words={words}
              isRTL={isRTL}
              name="account"
              nomulti
              width={250}
            ></FilterSelectCkeckBox>
          </Box>
          <Box style={{ marginLeft: 10, marginRight: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 7 }}
                  checked={isRaseed}
                  onChange={() => setIsRaseed(!isRaseed)}
                  color="primary"
                />
              }
              disabled={!accvalue?.[0]}
              label={
                <Typography
                  style={{ color: theme.palette.primary.main }}
                  variant="subtitle2"
                >
                  {isRTL ? 'رصيد افتتاحي' : 'Opening Balance'}
                </Typography>
              }
              style={{ fontSize: 14, opacity: !accvalue?.[0] ? 0.3 : 1 }}
            />
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            zIndex: 111,
            right: isRTL ? undefined : 100,
            left: isRTL ? 100 : undefined,
            top: 15,
          }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              color: bal < 0 ? colors.red[500] : colors.blue[500],
            }}
          >
            {isRTL ? 'الرصيد' : 'Balance'}: {moneyFormat(bal)}
          </Typography>
        </Box>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            defaultSorting={sort}
            onSortingChange={(srt: any) => setSortDispatch(srt)}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary />
          <IntegratedSorting />
          <SearchState />
          <IntegratedFiltering />
          <VirtualTable
            height={height - 98}
            tableComponent={TableComponent}
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            estimatedRowHeight={30}
          />
          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableColumnVisibility
            columnExtensions={tableColumnVisibilityColumnExtensions}
            defaultHiddenColumnNames={[col.amount.name, col.rased.name]}
          />
          <DataTypeProvider
            for={['opTime']}
            formatterComponent={createdAtFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['credit', 'debit', 'rased']}
            formatterComponent={currencyFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['opType']}
            formatterComponent={opTypeFormatter}
          ></DataTypeProvider>
          <Toolbar />
          <ColumnChooser />
          <SearchPanel
            inputComponent={(props: any) => {
              return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
            }}
          />
          <ExportPanel startExport={startExport} />
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
        />
      </Box>
    </PageLayout>
  );
}
