/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { colors } from '../../constants/colors';
import SelectKeys from '../components/SelectKeys';
import { onetwelve, zeroeleven, systemcolors } from './renders';

function ItemStyle({ row, setRow, options }: any) {
  const setRowalue = (name: any, value: any) => {
    const nrow = {
      ...row,
      [name]: value,
    };
    setRow(nrow);
  };

  return (
    <Box style={{ direction: 'ltr' }}>
      <Grid
        container
        spacing={1}
        style={{ padding: 15, backgroundColor: '#efefef' }}
      >
        <Grid item xs={6}>
          <SelectKeys
            name="type"
            options={options}
            disabled={!row}
            value={row?.type}
            setValue={(v: any) => {
              setRowalue('type', v);
            }}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="size"
            options={onetwelve}
            disabled={!row}
            value={row?.size}
            setValue={(v: any) => setRowalue('size', Number(v))}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="skip"
            options={zeroeleven}
            disabled={!row}
            value={row?.skip}
            setValue={(v: any) => setRowalue('skip', Number(v))}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="systemcolor"
            options={systemcolors}
            disabled={!row}
            value={row?.systemcolor}
            setValue={(v: any) => setRowalue('systemcolor', v)}
          ></SelectKeys>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => setRowalue('height', undefined)}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="height"
                  label="height"
                  value={row?.height}
                  type="number"
                  onChange={(e: any) =>
                    setRowalue('height', Number(e.target.value))
                  }
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="color"
                options={colors}
                disabled={!row}
                value={row?.color}
                setValue={(v: any) => setRowalue('color', v)}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.dark}
                    onChange={() => setRowalue('dark', !row?.dark)}
                    name="bold"
                    color="primary"
                  />
                }
                label="Dark"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="background"
                options={colors}
                disabled={!row}
                value={row?.backgroundColor}
                setValue={(v: any) => setRowalue('background', v)}
                color
              ></SelectKeys>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ItemStyle;
