import DateNavigatorReportsClock from './navigator/DateNavigatorReportsClock';
import { useTemplate } from '../../hooks';
import DateNavigatorReportsList from './navigator/DateNavigatorReportsList';
export default function DateNavigatorReports(props: any) {
  const { tempoptions } = useTemplate();

  if (tempoptions?.calendarclock) {
    return <DateNavigatorReportsClock {...props}></DateNavigatorReportsClock>;
  }

  return <DateNavigatorReportsList {...props}></DateNavigatorReportsList>;
}
