/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper } from '@material-ui/core';
import { useState } from 'react';

import { useMutation } from '@apollo/client';

import updateProcStatus from '../../graphql/mutation/updateProcStatus';
import PopupStep from '../../pubups/PopupStep';
import getProcedureById from '../../graphql/query/getProcedureById';
import TimelineSingleProc from './TimelineSingleProc';

export default function ProcedureSingleBox({
  row,
  words,
  isRTL,
  theme,
  height,
}: any) {
  const [openstep, setOpenstep] = useState<any>(false);
  const [saving, setSaving] = useState<any>(false);

  const refresQuery = {
    refetchQueries: [
      {
        query: getProcedureById,
        variables: { procId: row?._id },
      },
    ],
  };
  const [addStep] = useMutation(updateProcStatus, refresQuery);

  return (
    <Box style={{ height: height - 85, overflow: 'hidden' }}>
      <Paper
        style={{
          overflow: 'auto',
          height: height - 85,
          backgroundColor: '#f5f5f5',
        }}
        elevation={5}
      >
        <Box>
          <TimelineSingleProc
            row={row}
            isRTL={isRTL}
            setOpenstep={setOpenstep}
          ></TimelineSingleProc>
        </Box>
      </Paper>
      <PopupStep
        open={openstep}
        onClose={() => {
          setOpenstep(false);
        }}
        isRTL={isRTL}
        words={words}
        addStep={addStep}
        theme={theme}
        saving={saving}
        setSaving={setSaving}
        procId={row?._id}
      ></PopupStep>
    </Box>
  );
}
