import { colors, Grid, Paper } from '@material-ui/core';
import { moneyFormat, rCellMain } from '../../common/helpers';

export default function KaidsDataBx({ row, theme, isRTL, height = 200 }) {
  const ccolor = colors.blueGrey[300];
  const dcolor = colors.brown[400];
  return (
    <Paper
      style={{
        height,
        paddingLeft: 30,
        paddingRight: 30,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={7} style={{ marginTop: 30 }}>
          {rCellMain(isRTL ? 'القيود المدينة' : 'Debit', dcolor)}
        </Grid>
        <Grid item xs={5} style={{ marginTop: 30 }}>
          {rCellMain(moneyFormat(row?.totalkaidsdebit), dcolor)}
        </Grid>
        <Grid item xs={7}>
          {rCellMain(isRTL ? 'القيود الدائنة' : 'Credit', ccolor)}
        </Grid>
        <Grid item xs={5}>
          {rCellMain(moneyFormat(row?.totalKaidscredit), ccolor)}
        </Grid>
        <Grid item xs={7} style={{ marginTop: 20 }}>
          {rCellMain(isRTL ? 'رصيد القيود' : 'Entries Balance', '#333')}
        </Grid>
        <Grid item xs={5} style={{ marginTop: 20 }}>
          {rCellMain(
            moneyFormat(row?.totalkaidsdebit - row?.totalKaidscredit),
            '#333'
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
