/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { onetwelve, zeroeleven } from './renders';
import SelectCN from '../components/SelectCN';
import SelectKeys from '../components/SelectKeys';
import { colors } from '../../constants/colors';

function ItemStyle({ row, setRow, fields }: any) {
  const setRowalue = (name: any, value: any) => {
    const nrow = {
      ...row,
      [name]: value,
    };
    setRow(nrow);
  };
  const fls = fields?.map((fl: any) => fl.field);
  return (
    <Box style={{ direction: 'ltr' }}>
      <Grid
        container
        spacing={1}
        style={{ padding: 15, backgroundColor: '#efefef' }}
      >
        <Grid item xs={12}>
          <SelectKeys
            name="type"
            options={['text', ...fls]}
            disabled={!row}
            value={row?.type}
            setValue={(v: any) => {
              const fl = fields.filter((f: any) => f.field === v)?.[0];
              const nrow = {
                ...row,
                type: v,
                name: fl?.name,
                nameAr: fl?.nameAr,
              };
              setRow(nrow);
            }}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="size"
            options={onetwelve}
            disabled={!row}
            value={row?.size}
            setValue={(v: any) => setRowalue('size', Number(v))}
          ></SelectKeys>
        </Grid>
        <Grid item xs={6}>
          <SelectKeys
            name="skip"
            options={zeroeleven}
            disabled={!row}
            value={row?.skip}
            setValue={(v: any) => setRowalue('skip', Number(v))}
          ></SelectKeys>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="value"
            label="Value"
            value={row?.value}
            onChange={(e: any) => setRowalue('value', e.target.value)}
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            style={{ direction: 'ltr' }}
            disabled={!row}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={row?.border === true}
                onChange={() => {
                  setRowalue('border', row?.border === true ? false : true);
                }}
                name="border"
                color="primary"
              />
            }
            label="Border"
          />
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, width: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="width"
                  label="width"
                  value={row?.style?.width || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      width: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, height: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="height"
                  label="height"
                  value={row?.style?.height || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      height: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, fontSize: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="fontSize"
                  label="Font Size"
                  value={row?.style?.fontSize || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      fontSize: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 150 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.style?.fontWeight === 'bold'}
                    onChange={() => {
                      const stl = {
                        ...row?.style,
                        fontWeight:
                          row?.style?.fontWeight === 'bold'
                            ? undefined
                            : 'bold',
                      };
                      setRowalue('style', stl);
                    }}
                    name="bold"
                    color="primary"
                  />
                }
                label="Bold"
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.style?.textAlign === 'right'}
                    onChange={() => {
                      const stl = {
                        ...row?.style,
                        textAlign:
                          row?.style?.textAlign === 'right'
                            ? undefined
                            : 'right',
                      };
                      setRowalue('style', stl);
                    }}
                    name="bold"
                    color="primary"
                  />
                }
                label="right"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row?.style?.position === 'relative'}
                    onChange={() => {
                      const stl = {
                        ...row?.style,
                        position:
                          row?.style?.position === 'relative'
                            ? undefined
                            : 'relative',
                      };
                      setRowalue('style', stl);
                    }}
                    name="bold"
                    color="primary"
                  />
                }
                label="absl"
              />
            </Grid>
            <Grid item xs={4}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, top: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="fontSize"
                  label="Top"
                  value={row?.style?.top || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      top: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 100 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display={'flex'}>
                <Box
                  onClick={() => {
                    const stl = { ...row?.style, left: undefined };
                    setRowalue('style', stl);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: 15,
                  }}
                >
                  X
                </Box>
                <TextField
                  name="fontSize"
                  label="Left"
                  value={row?.style?.left || ''}
                  type="number"
                  onChange={(e: any) => {
                    const stl = {
                      ...row?.style,
                      left: Number(e.target.value),
                    };
                    setRowalue('style', stl);
                  }}
                  variant="outlined"
                  style={{ direction: 'ltr', width: 100 }}
                  disabled={!row}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SelectCN
                disabled={!row}
                setRowalue={setRowalue}
                value={row?.classnames}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="color"
                options={colors}
                disabled={!row}
                value={row?.style?.color}
                setValue={(v: any) => {
                  const stl = {
                    ...row?.style,
                    color: v,
                  };
                  setRowalue('style', stl);
                }}
                color
              ></SelectKeys>
            </Grid>
            <Grid item xs={6}>
              <SelectKeys
                name="background"
                options={colors}
                disabled={!row}
                value={row?.style?.backgroundColor}
                setValue={(v: any) => {
                  const stl = {
                    ...row?.style,
                    backgroundColor: v,
                  };
                  setRowalue('style', stl);
                }}
                color
              ></SelectKeys>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ItemStyle;
