import { Grid, Paper } from '@material-ui/core';
import { POS_BACKGROUND } from '../MainPOS';
import Product from './Product';

export default function Products({ prods, height, isRTL, theme }) {
  return (
    <Paper
      elevation={0}
      style={{ height, overflow: 'auto', backgroundColor: POS_BACKGROUND }}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ margin: 24, marginTop: 10 }}>
          <Grid container spacing={4}>
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
            {prods?.map((item: any) => (
              <Grid item xs={3}>
                <Product isRTL={isRTL} item={item}></Product>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
