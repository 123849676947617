import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { moneyFormat, getColor } from '../../common/helpers';
import InfoBox from '../InfoBox';

function CashBoxToday({ isRTL, theme, dark, color, systemcolor, height }) {
  const { raseedstoday } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });
  return (
    <Box>
      {userroles?.invoices?.admin && (
        <InfoBox
          title={isRTL ? 'الصندوق اليوم' : 'Cash Today'}
          value={moneyFormat(raseedstoday?.cash)}
          icon="cash"
          color={clr}
          dark={dark}
          height={height}
        ></InfoBox>
      )}
    </Box>
  );
}

export default CashBoxToday;
