import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { qtyFormat, getColor } from '../../common/helpers';
import InfoBox from '../InfoBox';
import { useTemplate } from '../../../../hooks';

function MonthTotalEvents({ isRTL, theme, dark, color, systemcolor, height }) {
  const { eventsMonthCount } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });
  const { tempwords } = useTemplate();

  return (
    <Box>
      {userroles?.appointments?.admin && (
        <InfoBox
          title={isRTL ? 'هذا الشهر' : 'This Month'}
          desc={
            isRTL
              ? `${tempwords?.appointments} هذا الشهر`
              : `${tempwords?.appointments} This month`
          }
          value={qtyFormat(eventsMonthCount)}
          icon="sales"
          color={clr}
          dark={dark}
          height={height}
        ></InfoBox>
      )}
    </Box>
  );
}

export default MonthTotalEvents;
