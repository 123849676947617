import { Box, Button } from '@material-ui/core';
import { DocumentTempPrintForm } from '../generator/print/DocumentTempPrintForm';
import { generatePDF } from '../components/generatePDF';
import MyIcon from '../components/MyIcon';

export default function PrintDownloadForm({
  company,
  printData,
  template,
  componentRef,
  handleReactPrint,
  isRTL,
  no,
  element,
  isNew,
}: any) {
  return (
    <Box
      style={{
        margin: 10,
        padding: 10,
        borderRadius: 10,
        display: 'flex',
      }}
    >
      <Box
        style={{
          display: isNew ? 'none' : 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Button
          variant="text"
          endIcon={<MyIcon icon="print" size={20} />}
          onClick={handleReactPrint}
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          Print
        </Button>
        <Button
          onClick={() => generatePDF({ no, template, element })}
          variant="text"
          endIcon={<MyIcon icon="pdf" size={20} />}
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          Download
        </Button>
      </Box>
      <div
        id={element}
        style={{
          position: 'absolute',
          left: '-999em',
          direction: 'ltr',
          width:
            template?.size === 'POS'
              ? '80mm'
              : template?.size === 'A5'
              ? '148mm'
              : '210mm',
        }}
      >
        <DocumentTempPrintForm
          company={company}
          data={printData}
          template={template}
          ref={componentRef}
          isRTL={isRTL}
        ></DocumentTempPrintForm>
      </div>
    </Box>
  );
}
