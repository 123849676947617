import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import SalesDaysChart from '../SalesDaysChart';

function DaysSales({ isRTL, theme, systemcolor, color, height }) {
  const { salesDays } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {salesDays && userroles?.invoices?.admin && (
        <SalesDaysChart
          dataKey="total"
          isRTL={isRTL}
          data={salesDays}
          color={clr}
          height={300}
        ></SalesDaysChart>
      )}
    </Box>
  );
}

export default DaysSales;
