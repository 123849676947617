/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRef, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box } from '@material-ui/core';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';
import PopupFormOperationView from '../shared/PopupFormOperationView';
import Landing from './Landing';

export default function LandingOperations({
  isRTL,
  words,
  user,
  theme,
  menuitem,
  drawerWidth,
  company,
  dispatch,
  notifisub,
}: any) {
  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);
  const ref: any = useRef();

  const { isMobile, height, width } = useWindowDimensions();

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      bgimage={bgimage}
      width={width - drawerWidth}
      refresh={() => ref?.current?.refresh()}
      notifisub={notifisub}
    >
      <>
        <Box
          style={{
            margin: isMobile ? undefined : 25,
            width: width - drawerWidth - 50,
            height: height - (isMobile ? 50 : 100),
            borderRadius: isMobile ? undefined : 25,
            overflow: 'hidden',
          }}
        >
          <Landing
            ref={ref}
            isRTL={isRTL}
            words={words}
            user={user}
            theme={theme}
            company={company}
            dispatch={dispatch}
            drawerWidth={drawerWidth}
            isMobile={isMobile}
          ></Landing>
        </Box>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </>
    </PageLayout>
  );
}
