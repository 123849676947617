import { getStoreItem } from '../store';

const store = getStoreItem('store');
const template = store?.template;
const lang = store?.lang;
const tempwords = template?.words?.[lang];
const options = template?.options;

// TODO: to be removed
export const roles = [
  {
    branchName: { type: String },
    isBranchAdmin: { type: Boolean },
    systems: [
      {
        systemName: { type: String },
        isSystemAdmin: { type: Boolean },
        levels: {
          view: { type: Boolean },
          add: { type: Boolean },
          edit: { type: Boolean },
          delete: { type: Boolean },
          report: { type: Boolean },
        },
      },
    ],
  },
];
// TODO: to be removed
export const rolesExample = {
  branchs: [
    {
      name: 'branch1',
      isAdmin: true,
      systems: [
        {
          name: 'system1',
          isAdmin: true,
          levels: {
            view: true,
            add: true,
            edit: true,
            delete: true,
            report: true,
          },
        },
      ],
    },
  ],
};

export const packages = [
  {
    id: 10,
    name: 'TRIAL',
    nameAr: 'TRIAL',
    price: 0,
    cost: 0,
  },
  {
    id: 1,
    name: 'BASIC',
    nameAr: 'BASIC',
    price: 599,
    cost: 599,
  },
  {
    id: 2,
    name: 'PREMIUM',
    nameAr: 'PREMIUM',
    price: 899,
    cost: 899,
  },
  {
    id: 3,
    name: 'PRO',
    nameAr: 'PRO',
    price: 1199,
    cost: 1199,
  },
];

export const newrolestemplate = [
  {
    name: 'home',
    top: true,
    ref: 'opr',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    view: false,
  },
  {
    name: 'tasks',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.tasks,
    titleEn: tempwords?.tasks,
    hide: !options?.contract,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'manageresourses',
    ref: 'opr',
    titleAr: `${tempwords?.resourses}`,
    titleEn: `${tempwords?.resourses}`,
    hide: !options?.resourse,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'manageemployees',
    ref: 'opr',
    titleAr: `${tempwords?.employees}`,
    titleEn: `${tempwords?.employees}`,
    hide: !options?.employee,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managedepartments',
    ref: 'opr',
    titleAr: `${tempwords?.departments}`,
    titleEn: `${tempwords?.departments}`,
    hide: !options?.department,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'operations',
    ref: 'opr',
    titleAr: `المبيعات`,
    titleEn: `Sales`,
    hide: !options?.operations,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'sales',
    ref: 'opr',
    titleAr: `المبيعات`,
    titleEn: `Sales`,
    hide: !options?.sales,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'purchase',
    ref: 'opr',
    titleAr: `المشتريات`,
    titleEn: `Purchase`,
    hide: !options?.purchase,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'expenses',
    ref: 'opr',
    titleAr: `المصروفات`,
    titleEn: `Expenses`,
    hide: !options?.expenses,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'finance',
    ref: 'opr',
    titleAr: `الحسابات`,
    titleEn: `Finance`,
    hide: !options?.finance,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'mail',
    ref: 'opr',
    titleAr: `المرفقات`,
    titleEn: `Attachments`,
    hide: !options?.mail,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة',
    titleEn: 'Under Approval',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة',
    titleEn: 'Under Review',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadinprogress',
    ref: 'opr',
    titleAr: 'قيد التسجيل',
    titleEn: 'In Progress',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'uploadarchive',
    ref: 'opr',
    titleAr: `الأرشيف`,
    titleEn: `Archive`,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'entryunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة - ادارة',
    titleEn: 'Under Approval - Admin',
    hide: true,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'entryunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة - ادارة',
    titleEn: 'Under Review - Admin',
    hide: true,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'entryarchive',
    ref: 'opr',
    titleAr: `الأرشيف - ادارة`,
    titleEn: `Archive - Admin`,
    hide: true,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'reports',
    ref: 'opr',
    titleAr: `التقارير`,
    titleEn: `Reports`,
    hide: !options?.reports,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'reminder',
    ref: 'opr',
    titleAr: `المفكرة`,
    titleEn: `Reminder`,
    hide: !options?.reminder,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'contractseports',
    ref: 'opr',
    titleAr: `تقرير ${tempwords?.tasks}`,
    titleEn: `${tempwords?.tasks} Report`,
    hide: !options?.contract,
    view: false,
  },
  {
    name: 'invoices',
    ref: 'sal',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'receipts',
    ref: 'sal',
    titleAr: 'المقبوضات',
    titleEn: 'Receipts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'customers',
    ref: 'sal',
    titleAr: tempwords?.customers,
    titleEn: tempwords?.customers,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'customerreport',
    ref: 'sal',
    titleAr: 'تقرير العملاء',
    titleEn: 'Customer Report',
    view: false,
  },
  {
    name: 'salesreport',
    ref: 'sal',
    titleAr: 'تقرير الايرادات',
    titleEn: 'Sales Report',
    view: false,
  },
  {
    name: 'purchase',
    ref: 'pur',
    titleAr: 'فواتير الشراء',
    titleEn: 'Purchase',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'payments',
    ref: 'pur',
    titleAr: 'المدفوعات',
    titleEn: 'Payments',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'supliers',
    ref: 'pur',
    titleAr: tempwords?.suppliers,
    titleEn: tempwords?.suppliers,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'supplierreport',
    ref: 'pur',
    titleAr: 'تقرير الموردين',
    titleEn: 'Supplier Report',
    view: false,
  },
  {
    name: 'purchasereport',
    ref: 'pur',
    titleAr: 'تقرير المشتريات',
    titleEn: 'Purchase Report',
    view: false,
  },
  {
    name: 'expenses',
    ref: 'exp',
    titleAr: 'المصروفات النقدية',
    titleEn: 'Expenses',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'expensesreport',
    ref: 'exp',
    titleAr: 'تقرير المصروفات',
    titleEn: 'Expenses Report',
    view: false,
  },
  {
    name: 'financeexpenses',
    top: true,
    ref: 'fin',
    titleAr: 'الحسابات',
    titleEn: 'Accountant',
    view: false,
  },
  {
    name: 'receiptsadvance',
    ref: 'fin',
    titleAr: 'مقبوضات سلفة',
    titleEn: 'Advanced Receipts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'paymentsadvance',
    ref: 'fin',
    titleAr: 'مدفوعات سلفة',
    titleEn: 'Advance Payments',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'cash',
    ref: 'fin',
    titleAr: 'حركة مالية',
    titleEn: 'Finance',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'financeall',
    ref: 'fin',
    titleAr: 'قيد اليومية',
    titleEn: 'General Journal',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'financereport',
    ref: 'fin',
    titleAr: 'تقرير الحسابات',
    titleEn: 'Finance Report',
    view: false,
  },
  {
    name: 'profitability',
    ref: 'fin',
    titleAr: 'تقرير الربحية',
    titleEn: 'Profitability Report',
    view: false,
  },
  {
    name: 'budgetreport',
    ref: 'fin',
    titleAr: 'الحسابات الختامية',
    titleEn: 'Closing Accounts',
    view: false,
  },
  {
    name: 'accounts',
    ref: 'fin',
    titleAr: 'قائمة الحسابات',
    titleEn: 'Accounts',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managereminders',
    top: true,
    ref: 'sms',
    titleAr: 'المفكرة',
    titleEn: 'Reminders',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'users',
    top: true,
    titleAr: 'المستخدمين',
    titleEn: 'Users',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'notifications',
    top: true,
    ref: 'opr',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    view: false,
  },
  {
    name: 'options',
    top: true,
    ref: 'opr',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    view: false,
    edit: false,
    admin: false,
  },
];
export const badmintemplate = [
  {
    name: 'home',
    top: true,
    ref: 'opr',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    view: true,
  },
  {
    name: 'tasks',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.tasks,
    titleEn: tempwords?.tasks,
    hide: !options?.contract,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'manageresourses',
    ref: 'opr',
    titleAr: `${tempwords?.resourses}`,
    titleEn: `${tempwords?.resourses}`,
    hide: !options?.resourse,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'manageemployees',
    ref: 'opr',
    titleAr: `${tempwords?.employees}`,
    titleEn: `${tempwords?.employees}`,
    hide: !options?.employee,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'managedepartments',
    ref: 'opr',
    titleAr: `${tempwords?.departments}`,
    titleEn: `${tempwords?.departments}`,
    hide: !options?.department,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'operations',
    ref: 'opr',
    titleAr: `المبيعات`,
    titleEn: `Sales`,
    hide: !options?.operations,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'sales',
    ref: 'opr',
    titleAr: `العمليات`,
    titleEn: `Operations`,
    hide: !options?.sales,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'purchase',
    ref: 'opr',
    titleAr: `المشتريات`,
    titleEn: `Purchase`,
    hide: !options?.purchase,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'expenses',
    ref: 'opr',
    titleAr: `المصروفات`,
    titleEn: `Expenses`,
    hide: !options?.expenses,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'finance',
    ref: 'opr',
    titleAr: `الحسابات`,
    titleEn: `Finance`,
    hide: !options?.finance,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'mail',
    ref: 'opr',
    titleAr: `المرفقات`,
    titleEn: `Attachments`,
    hide: !options?.mail,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة',
    titleEn: 'Under Approval',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة',
    titleEn: 'Under Review',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadinprogress',
    ref: 'opr',
    titleAr: 'قيد التسجيل',
    titleEn: 'In Progress',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'uploadarchive',
    ref: 'opr',
    titleAr: `الأرشيف`,
    titleEn: `Archive`,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'entryunderapproval',
    ref: 'opr',
    titleAr: 'قيد الموافقة - ادارة',
    titleEn: 'Under Approval - Admin',
    hide: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'entryunderreview',
    ref: 'opr',
    titleAr: 'قيد المراجعة - ادارة',
    titleEn: 'Under Review - Admin',
    hide: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'entryarchive',
    ref: 'opr',
    titleAr: `الأرشيف - ادارة`,
    titleEn: `Archive - Admin`,
    hide: true,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'reports',
    ref: 'opr',
    titleAr: `التقارير`,
    titleEn: `Reports`,
    hide: !options?.reports,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'reminder',
    ref: 'opr',
    titleAr: `المفكرة`,
    titleEn: `Reminder`,
    hide: !options?.reminder,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'contractseports',
    ref: 'opr',
    titleAr: `تقرير ${tempwords?.tasks}`,
    titleEn: `${tempwords?.tasks} Report`,
    hide: !options?.contract,
    view: true,
  },
  {
    name: 'invoices',
    ref: 'sal',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'receipts',
    ref: 'sal',
    titleAr: 'المقبوضات',
    titleEn: 'Receipts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'customers',
    ref: 'sal',
    titleAr: tempwords?.customers,
    titleEn: tempwords?.customers,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'customerreport',
    ref: 'sal',
    titleAr: 'تقرير العملاء',
    titleEn: 'Customer Report',
    view: true,
  },
  {
    name: 'salesreport',
    ref: 'sal',
    titleAr: 'تقرير الايرادات',
    titleEn: 'Sales Report',
    view: true,
  },
  {
    name: 'purchase',
    ref: 'pur',
    titleAr: 'فواتير الشراء',
    titleEn: 'Purchase',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'payments',
    ref: 'pur',
    titleAr: 'المدفوعات',
    titleEn: 'Payments',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'supliers',
    ref: 'pur',
    titleAr: tempwords?.suppliers,
    titleEn: tempwords?.suppliers,
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'supplierreport',
    ref: 'pur',
    titleAr: 'تقرير الموردين',
    titleEn: 'Supplier Report',
    view: true,
  },
  {
    name: 'purchasereport',
    ref: 'pur',
    titleAr: 'تقرير المشتريات',
    titleEn: 'Purchase Report',
    view: true,
  },
  {
    name: 'expenses',
    ref: 'exp',
    titleAr: 'المصروفات النقدية',
    titleEn: 'Expenses',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'expensesreport',
    ref: 'exp',
    titleAr: 'تقرير المصروفات',
    titleEn: 'Expenses Report',
    view: true,
  },
  {
    name: 'financeexpenses',
    top: true,
    ref: 'fin',
    titleAr: 'الحسابات',
    titleEn: 'Accountant',
    view: true,
  },
  {
    name: 'receiptsadvance',
    ref: 'fin',
    titleAr: 'مقبوضات سلفة',
    titleEn: 'Advanced Receipts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'paymentsadvance',
    ref: 'fin',
    titleAr: 'مدفوعات سلفة',
    titleEn: 'Advance Payments',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'cash',
    ref: 'fin',
    titleAr: 'حركة مالية',
    titleEn: 'Finance',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'financeall',
    ref: 'fin',
    titleAr: 'قيد اليومية',
    titleEn: 'General Journal',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'financereport',
    ref: 'fin',
    titleAr: 'تقرير الحسابات',
    titleEn: 'Finance Report',
    view: true,
  },
  {
    name: 'profitability',
    ref: 'fin',
    titleAr: 'تقرير الربحية',
    titleEn: 'Profitability Report',
    view: true,
  },
  {
    name: 'budgetreport',
    ref: 'fin',
    titleAr: 'الحسابات الختامية',
    titleEn: 'Closing Accounts',
    view: true,
  },
  {
    name: 'accounts',
    ref: 'fin',
    titleAr: 'قائمة الحسابات',
    titleEn: 'Accounts',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'managereminders',
    top: true,
    ref: 'sms',
    titleAr: 'المفكرة',
    titleEn: 'Reminders',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'users',
    top: true,
    titleAr: 'المستخدمين',
    titleEn: 'Users',
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  {
    name: 'notifications',
    top: true,
    ref: 'opr',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    view: true,
  },
  {
    name: 'options',
    top: true,
    ref: 'opr',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    view: true,
    edit: true,
    admin: true,
  },
];
export const employeetemplate = [
  {
    name: 'home',
    top: true,
    ref: 'opr',
    titleAr: 'الرئيسية',
    titleEn: 'Home',
    view: true,
  },
  {
    name: 'calendar',
    top: true,
    ref: 'opr',
    titleAr: 'التقويم',
    titleEn: 'Calendar',
    hide: !options?.calendar,
    view: true,
  },
  {
    name: 'tasks',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.tasks,
    titleEn: tempwords?.tasks,
    hide: !options?.contract,
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'appointments',
    top: true,
    ref: 'opr',
    titleAr: tempwords?.appointments,
    titleEn: tempwords?.appointments,
    view: true,
    add: true,
    edit: true,
    delete: false,
    admin: false,
  },
  {
    name: 'invoices',
    ref: 'sal',
    titleAr: 'فواتير البيع',
    titleEn: 'Invoices',
    view: true,
    add: true,
    edit: true,
    delete: false,
    admin: false,
  },
  {
    name: 'receipts',
    ref: 'sal',
    titleAr: 'المقبوضات',
    titleEn: 'Receipts',
    view: true,
    add: true,
    edit: true,
    delete: false,
    admin: false,
  },
  {
    name: 'customers',
    ref: 'sal',
    titleAr: tempwords?.customers,
    titleEn: tempwords?.customers,
    view: true,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'managereminders',
    top: true,
    ref: 'sms',
    titleAr: 'المفكرة',
    titleEn: 'Reminders',
    view: false,
    add: false,
    edit: false,
    delete: false,
    admin: false,
  },
  {
    name: 'notifications',
    top: true,
    ref: 'opr',
    titleAr: 'التنبيهات',
    titleEn: 'Notifications',
    view: true,
  },
  {
    name: 'options',
    top: true,
    ref: 'opr',
    titleAr: 'الاعدادات',
    titleEn: 'Options',
    view: true,
    edit: false,
    admin: false,
  },
];
export const userrolesTypes = {
  home: { view: true },
  calendar: { view: true },
  tasks: { view: true, add: true, edit: true, delete: true, admin: true },
  manageprojects: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  appointments: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  // managment: {
  //   view: true,
  // },
  manageresourses: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  manageemployees: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  managedepartments: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  formdocs: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  letters: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  presales: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  operations: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  sales: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  purchase: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  expenses: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  finance: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  mail: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadunderapproval: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadunderreview: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadinprogress: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  uploadarchive: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  entryunderapproval: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  entryunderreview: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  entryarchive: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  pos: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  reports: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  reminder: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  calendarempl: {
    view: true,
  },
  eventsitemsreports: {
    view: true,
  },
  calreports: {
    view: true,
  },
  contractseports: {
    view: true,
  },
  invoices: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  receipts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  customers: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  customerreport: {
    view: true,
  },
  salesreport: {
    view: true,
  },
  // purchasestock: {
  //   view: true,
  // },
  payments: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  supliers: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  supplierreport: {
    view: true,
  },
  purchasereport: {
    view: true,
  },
  stockreport: {
    view: true,
  },
  stockitems: {
    view: true,
  },
  expensesandpro: {
    view: true,
  },
  expproducts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  expensesreport: {
    view: true,
  },
  expproductreport: {
    view: true,
  },
  financeexpenses: {
    view: true,
  },
  receiptsadvance: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  paymentsadvance: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  cash: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  financeall: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  financereport: {
    view: true,
  },
  profitability: {
    view: true,
  },
  kaidreport: {
    view: true,
  },
  budgetreport: {
    view: true,
  },
  accounts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  items: {
    view: true,
  },
  categories: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  brands: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  services: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  products: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  expenseitems: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  rentitem: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  collectiveitem: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  resoursetypes: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  servicesreports: {
    view: true,
  },
  procedures: {
    view: true,
  },
  stepitems: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },

  pcategories: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  procitems: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  sms: {
    view: true,
  },
  contacts: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  groups: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  messages: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  sendreqs: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  msgtemplates: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  managereminders: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  users: {
    view: true,
    add: true,
    edit: true,
    delete: true,
    admin: true,
  },
  notifications: {
    view: true,
  },
  options: {
    view: true,
    edit: true,
    admin: true,
  },
  isSuperAdmin: false,
  isBranchsAdmin: false,
  isBranchAdmin: false,
  isEmployee: false,
};
