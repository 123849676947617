import { Grid, Paper, Typography } from '@material-ui/core';
import { carstatusFormatter } from '../../../common/helpers';

export default function ResourseDataBx({ row, words, isRTL, height }) {
  const name = row?.name;
  const nameAr = row?.nameAr;
  const carstatus = row?.carstatus;
  const plate = row?.plate;
  const insurance = row?.insurance;
  const brand = row?.brand;
  const model = row?.model;
  const info = row?.info;

  return (
    <Paper style={{ height, padding: 20 }}>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Typography variant="h6">{isRTL ? nameAr : name}</Typography>
        </Grid>
        {plate && (
          <Grid item xs={4}>
            <Typography
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                marginRight: 5,
                marginLeft: 5,
              }}
              variant="caption"
            >
              {plate}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          {carstatus && carstatusFormatter({ value: carstatus, isRTL })}
        </Grid>

        {insurance && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'التأمين : ' : 'Insurance : '} {insurance}
            </Typography>
          </Grid>
        )}
        {brand && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'الماركة : ' : 'Brand : '} {brand}
            </Typography>
          </Grid>
        )}
        {model && (
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'السنة : ' : 'Model : '} {model}
            </Typography>
          </Grid>
        )}
        {info && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 12 }} variant="caption">
              {isRTL ? 'معلومات : ' : 'Information : '} {info}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
