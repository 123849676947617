/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  EditingState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  SearchPanel,
  TableEditColumn,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, Loading, PopupEditing } from '../../Shared';
import { getRowId, userroles } from '../../common';
import { createdAtFormatter } from '../../Shared/colorFormat';

import { AlertLocal, SearchTable } from '../../components';
import { getColumns } from '../../common/columns';

import { Typography } from '@material-ui/core';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import PopupReminder from '../../pubups/PopupReminder';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createReminder,
  deleteReminder,
  getReminders,
  updateReminder,
} from '../../graphql';

import { useDepartments, useEmployees, useTemplate } from '../../hooks';
import getRemindersActions from '../../graphql/query/getRemindersActions';
import useResourses from '../../hooks/useResourses';
import { TableComponent } from '../../Shared/TableComponent';

export default function Reminders(props: any) {
  const { isRTL, words, start, end, theme } = props;
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const col = getColumns({ isRTL, words });

  const { tempoptions } = useTemplate();
  const [columns] = useState(
    [
      col.time,
      { name: 'title', title: words?.description },
      tempoptions?.resourse && col.resourse,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      tempoptions?.contract && col.contract,
    ].filter((x) => x)
  );

  const { employees } = useEmployees();
  const { departments } = useDepartments();
  const { resourses } = useResourses();

  const [tableColumnExtensions]: any = useState([
    { columnName: col.sent.name, width: 120 },
    { columnName: col.time.name, width: 200 },
    { columnName: col.title.name, width: 250 },
    { columnName: col.resourse.name, width: 200 },
    { columnName: col.employee.name, width: 200 },
    { columnName: col.department.name, width: 200 },
    { columnName: col.contract.name, width: 200 },
  ]);
  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'time', togglingEnabled: false },
  ]);

  const [loadReminders, remindersData]: any = useLazyQuery(getReminders, {
    fetchPolicy: 'cache-and-network',
  });

  const refresQuery = {
    refetchQueries: [
      {
        query: getReminders,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getRemindersActions,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    loadReminders({
      variables,
    });
  }, [start, end]);

  const [addReminder] = useMutation(createReminder, refresQuery);
  const [editReminder] = useMutation(updateReminder, refresQuery);
  const [removeReminder] = useMutation(deleteReminder, refresQuery);

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeReminder({ variables: { _id } });
      if (res?.data?.deleteReminder?.ok === false) {
        if (res?.data?.deleteReminder?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  useEffect(() => {
    if (remindersData?.loading) {
      setLoading(true);
    }
    if (remindersData?.data?.getReminders?.data) {
      const { data } = remindersData.data.getReminders;
      const rdata = data.map((da: any) => {
        let time: any;
        let resourseNameAr: any;
        let resourseName: any;
        let departmentNameAr: any;
        let departmentName: any;
        let employeeNameAr: any;
        let employeeName: any;
        if (da?.resourseId) {
          const res = resourses.filter(
            (re: any) => re._id === da.resourseId
          )?.[0];
          resourseNameAr = res?.nameAr;
          resourseName = res?.name;
        }
        if (da?.departmentId) {
          const res = departments.filter(
            (re: any) => re._id === da.departmentId
          )?.[0];
          departmentNameAr = res?.nameAr;
          departmentName = res?.name;
        }
        if (da?.employeeId) {
          const res = employees.filter(
            (re: any) => re._id === da.employeeId
          )?.[0];
          employeeNameAr = res?.nameAr;
          employeeName = res?.name;
        }

        const rr = JSON.parse(da?.rruledata);
        if (rr) {
          const { all } = rr;
          const startms = start?.getTime();
          const endms = end?.getTime();
          all.map((tm: any) => {
            const date = new Date(tm).getTime();
            if (date > startms && date < endms) {
              time = new Date(tm);
            }
            return tm;
          });
        }
        return {
          ...da,
          resourseName,
          resourseNameAr,
          departmentNameAr,
          departmentName,
          employeeNameAr,
          employeeName,
          time,
        };
      });
      setRows(rdata);
      setLoading(false);
    }
  }, [remindersData]);

  return (
    <>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={15} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد تذكيرات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 40 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />

        <TableColumnReordering
          defaultOrder={[
            col.sent.name,
            col.time.name,
            col.title.name,
            col.resourse.name,
            col.employee.name,
            col.department.name,
            col.contract.name,
          ]}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />

        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[col.amount.name]}
        />
        <DataTypeProvider
          for={['time']}
          formatterComponent={createdAtFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showDeleteCommand={userroles?.managereminders?.delete}
          showEditCommand={userroles?.managereminders?.edit}
          showAddCommand={userroles?.managereminders?.add}
          commandComponent={Command}
        ></TableEditColumn>

        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 15, 20, 50, 0] : [5, 10, 15, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing
          theme={theme}
          addAction={addReminder}
          editAction={editReminder}
        >
          <PopupReminder></PopupReminder>
        </PopupEditing>
      </Grid>
      {loading && <Loading isRTL={isRTL} />}
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
    </>
  );
}
