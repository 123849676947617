/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';

import TabsListVertical from '../components/TabsListVertical';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Inbox from './Inbox';
import Outbox from './Outbox';
import EmailView from '../shared/EmailView';
import CreateEmail from '../../Shared/CreateEmail';
import PopupUploadDocs from '../shared/PopupUploadDocs';
// import { useMutation } from '@apollo/client';
// import { createFormOperation } from '../../graphql/mutation';
import Important from './Important';
import Starred from './Starred';
import useStepItems from '../../hooks/useStepItems';

export default function MailOperations({
  isRTL,
  words,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
  sub,
  outboxsub,
  user,
  users,
  company,
  newin,
  setNewin,
}: any) {
  const [open, setOpen] = useState(false);
  // const [formdoc, setFormdoc] = useState(null);

  const { height, width } = useWindowDimensions();
  const { pathname } = useLocation();
  const { stepitems } = useStepItems();
  const uploadref: any = useRef();
  const inboxref: any = useRef();
  const outboxref: any = useRef();
  const importantref: any = useRef();
  const starredref: any = useRef();

  const navigate = useNavigate();

  const onClose = () => {
    // setFormdoc(null);
    setOpen(false);
  };
  const handleSelect = (item: any) => {
    navigate(item?.path);
  };
  // const [addFormOperation] = useMutation(createFormOperation);

  useEffect(() => {
    if (
      sub?.data?.mailAdded?.userId &&
      sub?.data?.mailAdded?.userId === user?._id
    ) {
      inboxref?.current?.refresh();
    }
  }, [sub]);
  useEffect(() => {
    if (
      outboxsub?.data?.mailUpdated?.userId &&
      outboxsub?.data?.mailUpdated?.userId === user?._id
    ) {
      outboxref?.current?.refresh();
    }
  }, [outboxsub]);
  const refresh = () => {
    if (pathname?.includes('inbox')) {
      inboxref?.current?.refresh();
    }
    if (pathname?.includes('upload')) {
      uploadref?.current?.refresh();
    }
    if (pathname?.includes('outbox')) {
      outboxref?.current?.refresh();
    }
    if (pathname?.includes('important')) {
      importantref?.current?.refresh();
    }
    if (pathname?.includes('starred')) {
      starredref?.current?.refresh();
    }
  };

  const bgcolor = '#ae2ae212';
  const menu = formgroup.map((fg: any) => {
    if (fg.id === 1) {
      return {
        ...fg,
        new: newin,
      };
    } else return fg;
  });
  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box style={{ height: height - 50 }}>
        <Box
          style={{
            borderRadius: 25,
            marginLeft: 25,
            width: width - drawerWidth - 25,
            height: height - 125,
          }}
        >
          <Box display={'flex'}>
            <Box
              style={{
                height: height - 100,
                width: 200,
              }}
            >
              <CreateEmail isRTL={isRTL} setOpen={setOpen}></CreateEmail>
              <Box style={{ height: 50 }}></Box>
              <TabsListVertical
                handleSelect={handleSelect}
                isRTL={isRTL}
                list={menu}
                theme={theme}
                color={fade(theme?.palette?.primary?.light, 0.3)}
                pathname={pathname}
                height={height - 100}
              ></TabsListVertical>
            </Box>

            <Paper
              elevation={5}
              style={{
                display: 'flex',
                height: height - 100,
                overflow: 'auto',
                borderRadius: 25,
                marginTop: 25,
              }}
            >
              <Routes>
                <Route
                  index
                  element={<Navigate to={formgroup?.[0]?.path} replace />}
                />
                <Route
                  path={'inbox'}
                  element={
                    <Inbox
                      ref={inboxref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      drawerWidth={drawerWidth}
                      user={user}
                      stepitems={stepitems}
                      setNewin={setNewin}
                    ></Inbox>
                  }
                />
                <Route
                  path={'inbox/:procId'}
                  element={
                    <EmailView
                      theme={theme}
                      isRTL={isRTL}
                      words={words}
                      width={width - drawerWidth - 50}
                      height={height - 135}
                      company={company}
                    />
                  }
                />
                <Route
                  path={'outbox'}
                  element={
                    <Outbox
                      ref={outboxref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      drawerWidth={drawerWidth}
                      stepitems={stepitems}
                    ></Outbox>
                  }
                />
                <Route
                  path={'outbox/:procId'}
                  element={
                    <EmailView
                      theme={theme}
                      isRTL={isRTL}
                      words={words}
                      user={user}
                      width={width - drawerWidth - 50}
                      height={height - 135}
                      company={company}
                    />
                  }
                />
                <Route
                  path={'important'}
                  element={
                    <Important
                      ref={importantref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      drawerWidth={drawerWidth}
                      userId={user?._id}
                      stepitems={stepitems}
                    ></Important>
                  }
                />
                <Route
                  path={'important/:procId'}
                  element={
                    <EmailView
                      theme={theme}
                      isRTL={isRTL}
                      words={words}
                      user={user}
                      width={width - drawerWidth - 50}
                      height={height - 135}
                      company={company}
                    />
                  }
                />
                <Route
                  path={'starred'}
                  element={
                    <Starred
                      ref={starredref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      drawerWidth={drawerWidth}
                      userId={user?._id}
                      stepitems={stepitems}
                    ></Starred>
                  }
                />
                <Route
                  path={'starred/:procId'}
                  element={
                    <EmailView
                      theme={theme}
                      isRTL={isRTL}
                      words={words}
                      user={user}
                      width={width - drawerWidth - 50}
                      height={height - 135}
                      company={company}
                    />
                  }
                />
              </Routes>
            </Paper>
          </Box>
        </Box>
        <PopupUploadDocs
          open={open}
          onClose={onClose}
          theme={theme}
          company={company}
          words={words}
          width={width}
          height={height}
        ></PopupUploadDocs>
      </Box>
    </PageLayout>
  );
}
