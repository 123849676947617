/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dublicateAlert, errorAlert, yup, messageAlert } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import useAccounts from '../hooks/useAccounts';
import { operationTypes } from '../constants';
import { parents } from '../constants/kaid';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import { CalenderLocal, TextFieldLocal } from '../components';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import {
  useDepartments,
  useEmployees,
  useSuppliers,
  useTemplate,
} from '../hooks';
import { useLazyQuery } from '@apollo/client';
import getInvoicesList from '../graphql/query/getInvoicesList';
import PopupSupplier from './PopupSupplier';
import { successAlert } from '../Shared/helpers';
import { updateDocNumbers } from '../common';
import useResourses from '../hooks/useResourses';
import useTasks from '../hooks/useTasks';
import { weekdaysNNo } from '../constants/datatypes';
import { getDefaultTemplate, tafkeet } from '../common/helpers';
import usePrintTemplates from '../hooks/usePrintTemplates';
import useWindowDimensions from '../hooks/useWindowDimensions';
import PrintDownload from '../Shared/PrintDownload';

const PopupPayment = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
  name,
  value,
  company,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [credaccounts, setCredaccounts] = React.useState([]);

  const [debitAcc, setDebitAcc]: any = React.useState(null);
  const [creditAcc, setCreditAcc]: any = React.useState(null);

  const [suppvalue, setSuppvalue] = useState<any>(
    name === 'supplierId' ? value : null
  );
  const [suppError, setSuppError] = useState<any>(false);

  const [departvalue, setDepartvalue] = useState<any>(
    name === 'departmentId' ? value : null
  );
  const [emplvalue, setEmplvalue] = useState<any>(
    name === 'employeeId' ? value : null
  );
  const [resovalue, setResovalue] = useState<any>(
    name === 'resourseId' ? value : null
  );
  const [taskvalue, setTaskvalue] = useState<any>(
    name === 'contractId' ? value : null
  );
  const [invoices, setInvoices] = useState<any>([]);
  const [invoicevalue, setInvoicevalue] = useState<any>(null);

  const [newtext, setNewtext] = useState('');
  const [openCust, setOpenCust] = useState(false);

  const { register, handleSubmit, errors, reset } = useForm(
    yup.depositResolver
  );

  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const { accounts } = useAccounts();
  const { suppliers, addSupplier, editSupplier } = useSuppliers();
  const { tempwords, tempoptions } = useTemplate();
  const { tasks } = useTasks();
  const { departments } = useDepartments();
  const { employeesfull: employees } = useEmployees();
  const { resourses } = useResourses();
  const { printtemplates } = usePrintTemplates();
  const { isMobile } = useWindowDimensions();
  const [loadInvoices, invoicesData]: any = useLazyQuery(getInvoicesList, {
    fetchPolicy: 'cache-and-network',
  });

  const openSupplier = () => {
    setOpenCust(true);
  };
  const onCloseSupplier = () => {
    setOpenCust(false);
    setNewtext('');
  };
  const onNewSuppChange = (nextValue: any) => {
    setSuppvalue(nextValue);
  };

  useEffect(() => {
    if (suppvalue) {
      const variables = { supplierId: suppvalue._id };
      loadInvoices({ variables });
    }
    if (isNew) {
      if (name === 'contractId') {
        if (value?.supplierId) {
          const dept = suppliers.filter(
            (dep: any) => dep._id === value?.supplierId
          )?.[0];
          setSuppvalue(dept);
        }
        if (value?.departmentId) {
          const dept = departments.filter(
            (dep: any) => dep._id === value?.departmentId
          )?.[0];
          setDepartvalue(dept);
        }
        if (value?.employeeId) {
          const dept = employees.filter(
            (dep: any) => dep._id === value?.employeeId
          )?.[0];
          setEmplvalue(dept);
        }
        if (value?.resourseId) {
          const dept = resourses.filter(
            (dep: any) => dep._id === value?.resourseId
          )?.[0];
          setResovalue(dept);
        }
      }
    }
  }, [suppvalue, open]);

  useEffect(() => {
    if (invoicevalue) {
      if (invoicevalue?.supplierId) {
        const dept = suppliers.filter(
          (dep: any) => dep._id === invoicevalue?.supplierId
        )?.[0];
        setSuppvalue(dept);
      }
      if (invoicevalue?.departmentId) {
        const dept = departments.filter(
          (dep: any) => dep._id === invoicevalue?.departmentId
        )?.[0];
        setDepartvalue(dept);
      }
      if (invoicevalue?.employeeId) {
        const dept = employees.filter(
          (dep: any) => dep._id === invoicevalue?.employeeId
        )?.[0];
        setEmplvalue(dept);
      }
      if (invoicevalue?.resourseId) {
        const dept = resourses.filter(
          (dep: any) => dep._id === invoicevalue?.resourseId
        )?.[0];
        setResovalue(dept);
      }
    }
  }, [invoicevalue, open]);

  useEffect(() => {
    if (name && value) {
      if (name === 'supplierId') {
        setSuppvalue(value);
      }
    }
  }, [name, value, open]);

  useEffect(() => {
    if (invoicesData?.data?.getInvoicesList?.data) {
      const { data } = invoicesData.data.getInvoicesList;
      if (data?.length > 0) {
        const ndata = data.map((d: any) => {
          const title = `${d.docNo} - ${d.amount}QR`;
          return {
            ...d,
            name: title,
            nameAr: title,
          };
        });
        const refdatar = updateDocNumbers(ndata);

        setInvoices(refdatar);
        if (row.refNo) {
          const inv = refdatar.filter((ts: any) => ts.docNo === row.refNo)?.[0];
          setInvoicevalue(inv);
        }
      }
    } else {
      setInvoices([]);
      setInvoicevalue(null);
    }
  }, [invoicesData]);

  useEffect(() => {
    if (row && row._id) {
      const ca = row.creditAcc;
      const da = row.debitAcc;

      const filteredcredit = accounts.filter(
        (acc: any) => acc.parentcode === parents.CASH
      );
      setCredaccounts(filteredcredit);

      if (ca) {
        const credit = accounts.filter((acc: any) => acc.code === ca)[0];
        setCreditAcc(credit);
      }
      if (da) {
        const debit = accounts.filter((acc: any) => acc.code === da)[0];
        setDebitAcc(debit);
      }
      const supplierId = row?.supplierId;
      const depId = row.departmentId;
      const empId = row.employeeId;
      const resId = row.resourseId;
      const contId = row.contractId;
      if (supplierId) {
        const supp = suppliers.filter((it: any) => it._id === supplierId)[0];
        setSuppvalue(supp);
      }
      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }
      if (contId) {
        const tsk = tasks.filter((ts: any) => ts._id === row.contractId)[0];
        setTaskvalue(tsk);
      }
      handleDateChange(row.time);
    } else {
      const filtereddebits = accounts.filter(
        (acc: any) => acc.parentcode === parents.ACCOUNTS_PAYABLE
      );
      const filteredcredit = accounts.filter(
        (acc: any) => acc.parentcode === parents.CASH
      );
      setCredaccounts(filteredcredit);
      setDebitAcc(filtereddebits?.[0]);
      setCreditAcc(filteredcredit?.[0]);
    }
  }, [row, open]);

  const template = getDefaultTemplate(printtemplates, 'payment');

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Payment #${row?.docNo}`,
    removeAfterPrint: true,
  });
  const isCash = !row?.chequeBank && !row?.chequeDate;

  const printData = {
    no: row?.docNo,
    invoiceno: invoicevalue?.docNo,
    time: selectedDate,
    supplierName: suppvalue?.name,
    supplierNameAr: suppvalue?.nameAr,
    supplierPhone: suppvalue?.phone,
    resourseNameAr: invoicevalue?.resourseNameAr,
    resourseName: invoicevalue?.resourseName,
    employeeNameAr: invoicevalue?.employeeNameAr,
    employeeName: invoicevalue?.employeeName,
    periodfrom: invoicevalue?.periodfrom,
    isMonthly: invoicevalue?.isMonthly,
    periodto: invoicevalue?.periodto,
    refNo: row?.refNo,
    title: row?.title,
    desc: row?.desc,
    amount: row?.amount,
    isRTL: isRTL,
    chequeBank: row?.chequeBank,
    chequeNo: row?.chequeNo,
    chequeDate: row?.chequeDate,
    isCash,
    isCheque: !isCash,
    tafkeet: tafkeet(row?.amount, isRTL),
  };
  const onSubmit = async (data: any) => {
    if (selectedDate > new Date()) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    const { amount, title, desc, chequeBank, chequeNo, chequeDate } = data;
    if (!creditAcc) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تحديد الحساب' : 'You have to select Account'
      );
      return;
    }
    if (!suppvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تحديد المورد' : 'You have to select Supplier'
      );
      return;
    }
    setSaving(true);
    const supplier = suppvalue
      ? {
          supplierId: suppvalue._id,
          supplierName: suppvalue.name,
          supplierNameAr: suppvalue.nameAr,
          supplierPhone: suppvalue.color,
        }
      : {
          supplierId: undefined,
          supplierName: undefined,
          supplierNameAr: undefined,
          supplierPhone: undefined,
        };
    const department = departvalue
      ? {
          departmentId: departvalue._id,
          departmentName: departvalue.name,
          departmentNameAr: departvalue.nameAr,
          departmentColor: departvalue.color,
        }
      : {
          departmentId: undefined,
          departmentName: undefined,
          departmentNameAr: undefined,
          departmentColor: undefined,
        };
    const employee = emplvalue
      ? {
          employeeId: emplvalue._id,
          employeeName: emplvalue.name,
          employeeNameAr: emplvalue.nameAr,
          employeeColor: emplvalue.color,
          employeePhone: emplvalue.phone,
        }
      : {
          employeeId: undefined,
          employeeName: undefined,
          employeeNameAr: undefined,
          employeeColor: undefined,
          employeePhone: undefined,
        };
    const resourse = resovalue
      ? {
          resourseId: resovalue._id,
          resourseName: resovalue.name,
          resourseNameAr: resovalue.nameAr,
          resourseColor: resovalue.color,
        }
      : {
          resourseId: undefined,
          resourseName: undefined,
          resourseNameAr: undefined,
          resourseColor: undefined,
        };
    const contract = taskvalue
      ? {
          contractId: taskvalue._id,
          contractName: taskvalue.name,
          contractNameAr: taskvalue.nameAr,
        }
      : {
          contractId: undefined,
          contractName: undefined,
          contractNameAr: undefined,
        };
    const project = taskvalue
      ? {
          projectId: taskvalue.projectId,
          projectName: taskvalue.projectName,
          projectNameAr: taskvalue.projectNameAr,
        }
      : {
          projectId: undefined,
          projectName: undefined,
          projectNameAr: undefined,
        };

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      opType: operationTypes.supplierPayemnt,
      time: selectedDate,
      debitAcc: debitAcc.code,
      creditAcc: creditAcc.code,
      refNo: invoicevalue ? invoicevalue.docNo : null,
      invoiceId: invoicevalue ? invoicevalue._id : null,
      supplier,
      department,
      resourse,
      contract,
      employee,
      project,
      amount,
      chequeBank,
      chequeNo,
      chequeDate,
      title,
      desc,
      userId: user._id,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      await mutate({ variables });
      await successAlert(setAlrt, isRTL);
      setSaving(false);
      closeModal();
    } catch (error) {
      onError(error);
      setSaving(false);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      resetAll();
      console.log(error);
    }
  };

  const resetAll = () => {
    reset();
    setCreditAcc(null);
    setDebitAcc(null);
    setCredaccounts([]);
    setInvoices([]);
    setSuppvalue(name === 'supplierId' ? value : null);
    setDepartvalue(name === 'departmentId' ? value : null);
    setEmplvalue(name === 'employeeId' ? value : null);
    setResovalue(name === 'resourseId' ? value : null);
    setTaskvalue(name === 'contractId' ? value : null);
    setInvoicevalue(null);
    setSaving(false);
  };
  const closeModal = () => {
    resetAll();
    onClose();
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const day = weekdaysNNo?.[new Date().getDay()];

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={words.payments}
      onSubmit={onHandleSubmit}
      theme={theme}
      saving={saving}
      alrt={alrt}
      print={!isNew ? handleReactPrint : undefined}
      mt={10}
    >
      <Grid container spacing={2}>
        {isMobile && (
          <Grid item md={12}>
            <PrintDownload
              company={company}
              printData={printData}
              componentRef={componentRef}
              handleReactPrint={handleReactPrint}
              isRTL={isRTL}
              template={template}
              no={row?.docNo}
              element="payment"
            ></PrintDownload>
          </Grid>
        )}
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <CalenderLocal
                isRTL={isRTL}
                label={words?.time}
                value={selectedDate}
                onChange={handleDateChange}
              ></CalenderLocal>
            </Grid>
            {!isMobile && (
              <Grid item md={7}>
                <PrintDownload
                  company={company}
                  printData={printData}
                  componentRef={componentRef}
                  handleReactPrint={handleReactPrint}
                  isRTL={isRTL}
                  template={template}
                  no={row?.docNo}
                  element="payment"
                ></PrintDownload>
              </Grid>
            )}
            <Grid item xs={12}>
              <AutoFieldLocal
                name="supplier"
                title={words?.supplier}
                words={words}
                options={suppliers}
                value={suppvalue}
                setSelectValue={(value: any) => {
                  setSuppvalue(value);
                  setInvoicevalue(null);
                }}
                setSelectError={setSuppError}
                selectError={suppError}
                isRTL={isRTL}
                fullwidth
                openAdd={openSupplier}
                disabled={name === 'supplierId'}
                mb={0}
              ></AutoFieldLocal>
            </Grid>
            <Grid item xs={12}>
              <AutoFieldLocal
                name="invoice"
                title={isRTL ? 'الفواتير' : 'Invoices'}
                words={words}
                options={invoices}
                value={invoicevalue}
                setSelectValue={setInvoicevalue}
                register={register}
                isRTL={isRTL}
                disabled={!suppvalue}
                fullWidth
                mb={0}
              ></AutoFieldLocal>
            </Grid>

            {tempoptions?.contract && (
              <Grid item xs={6}>
                <AutoFieldLocal
                  name="task"
                  title={tempwords?.task}
                  words={words}
                  options={tasks}
                  value={taskvalue}
                  setSelectValue={setTaskvalue}
                  isRTL={isRTL}
                  fullWidth
                  disabled={name === 'contractId'}
                ></AutoFieldLocal>
              </Grid>
            )}
            {tempoptions?.resourse && (
              <Grid item xs={6}>
                <AutoFieldLocal
                  name="resourse"
                  title={tempwords?.resourse}
                  words={words}
                  options={resourses}
                  value={resovalue}
                  disabled={name === 'resourseId'}
                  setSelectValue={setResovalue}
                  isRTL={isRTL}
                  fullWidth
                  day={day}
                ></AutoFieldLocal>
              </Grid>
            )}
            {tempoptions?.employee && (
              <Grid item xs={6}>
                <AutoFieldLocal
                  name="employee"
                  title={tempwords?.employee}
                  words={words}
                  options={employees.filter((emp: any) => !emp.disabled)}
                  value={emplvalue}
                  disabled={name === 'employeeId'}
                  setSelectValue={setEmplvalue}
                  isRTL={isRTL}
                  fullWidth
                  day={day}
                ></AutoFieldLocal>
              </Grid>
            )}
            {tempoptions?.department && (
              <Grid item xs={6}>
                <AutoFieldLocal
                  name="department"
                  title={tempwords?.department}
                  words={words}
                  options={departments.filter((d: any) => d.depType === 1)}
                  value={departvalue}
                  setSelectValue={setDepartvalue}
                  isRTL={isRTL}
                  fullWidth
                  disabled={name === 'departmentId'}
                ></AutoFieldLocal>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextFieldLocal
                required
                name="amount"
                label={words.amount}
                register={register}
                errors={errors}
                row={row}
                type="number"
                fullWidth
                mb={0}
              />
            </Grid>

            <Grid item xs={12}>
              <AutoFieldLocal
                name="creditAcc"
                title={isRTL ? 'حساب الدفع' : 'Payment Acc'}
                words={words}
                options={credaccounts}
                value={creditAcc}
                setSelectValue={setCreditAcc}
                register={register}
                isRTL={isRTL}
                fullwidth
                mb={0}
              ></AutoFieldLocal>
            </Grid>
            <Grid item xs={4}>
              <TextFieldLocal
                name="chequeBank"
                label={words.chequeBank}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLocal
                name="chequeNo"
                label={words.chequeNo}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldLocal
                name="chequeDate"
                label={words.chequeDate}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldLocal
                name="desc"
                rows={4}
                label={words.for}
                register={register}
                errors={errors}
                row={row}
                multiline
                fullWidth
                mb={0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
        <PopupSupplier
          newtext={newtext}
          open={openCust}
          onClose={onCloseSupplier}
          isNew={true}
          setNewValue={onNewSuppChange}
          row={null}
          addAction={addSupplier}
          editAction={editSupplier}
        ></PopupSupplier>
      </Grid>
    </PopupLayout>
  );
};

export default PopupPayment;
