/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useReducer, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';
import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';

import useCompany from '../../hooks/useCompany';

import TabsListVertical from '../components/TabsListVertical';
import {
  initServicesReportContext,
  servicesReportReducer,
} from '../../contexts/servicesReport/servicesReducer';
import {
  eventsReportReducer,
  initEventsReportContext,
} from '../../contexts/eventsreport/salesReducer';
import {
  ContractsReportContext,
  contractsReportReducer,
  initContractsReportContext,
} from '../../contexts/managment/contractsreport';
import DateNavigatorReports from '../shared/DateNavigatorReports';
import ServicesReportContext from '../../contexts/servicesReport';
import EventsItemsReport from './EventsItemsReport';
import EventsReportContext from '../../contexts/eventsreport';
import EventsReport from './EventsReport';
import ContractsReport from './ContractsReport';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  CustomerReportContext,
  customerReportReducer,
  ExpensesReportContext,
  expensesReportReducer,
  FinanceContext,
  financeReducer,
  initCustomerReportContext,
  initExpensesReportContext,
  initFinanceContext,
  initPurchaseReportContext,
  initSalesReportContext,
  PurchaseReportContext,
  purchaseReportReducer,
  SalesReportContext,
  salesReportReducer,
} from '../../contexts';
import CustomerReport from './CustomerReport';
import CustomerAllReport from './CustomerAllReport';
import SalesReport from './SalesReport';
import PurchaseReport from './PurchaseReport';
import {
  initSupplierReportContext,
  supplierReportReducer,
} from '../../contexts/supplierReport/customerReportReducer';
import SupplierReportContext from '../../contexts/supplierReport';
import SupplierAllReport from './SupplierAllReport';
import SupplierReportNew from './SupplierReportNew';
import ExpensesReport from './ExpensesReport';
import useAccounts from '../../hooks/useAccounts';
import ExpProductReport from './ExpProductReport';
import FinanceReport from './FinanceReport';
import BudgetReport from './BudgetReport';
import StockItems from './StockItems';
import {
  initKaidContext,
  initProfitabilityContext,
  KaidContext,
  kaidReducer,
  ProfitabilityContext,
  profitabilityReducer,
} from '../../contexts/managment';
import ProfitabilityReport from './ProfitabilityReport';
import { getparentAccountsNames } from '../../common/helpers';
import KaidsReport from './KaidsReport';
import ServicesReport from './ServicesReport';

export default function ReportsList({
  isRTL,
  words,
  theme,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [currentViewName, setCurrentViewName] = useState('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { height, width } = useWindowDimensions();
  const { company } = useCompany();
  const { accounts } = useAccounts();

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };

  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };
  const mainaccountsnames = getparentAccountsNames();

  const [servicesStore, servicesDispatch] = useReducer(
    servicesReportReducer,
    initServicesReportContext
  );
  const [eventsReportStore, eventsReportDispatch] = useReducer(
    eventsReportReducer,
    initEventsReportContext
  );
  const [contractsReportStore, contractsReportDispatch] = useReducer(
    contractsReportReducer,
    initContractsReportContext
  );
  const [customerReportStore, customerReportDispatch] = useReducer(
    customerReportReducer,
    initCustomerReportContext
  );
  const [salesReportStore, salesReportDispatch] = useReducer(
    salesReportReducer,
    initSalesReportContext
  );
  const [purchaseReportStore, purchaseReportDispatch] = useReducer(
    purchaseReportReducer,
    initPurchaseReportContext
  );
  const [supplierReportStore, supplierReportDispatch] = useReducer(
    supplierReportReducer,
    initSupplierReportContext
  );
  const [expensesReportStore, expensesReportDispatch] = useReducer(
    expensesReportReducer,
    initExpensesReportContext
  );
  const [financeStore, financeDispatch] = useReducer(
    financeReducer,
    initFinanceContext
  );
  const [profitabilityStore, profitabilityDispatch] = useReducer(
    profitabilityReducer,
    initProfitabilityContext
  );
  const [kaidStore, kaidDispatch] = useReducer(kaidReducer, initKaidContext);

  const bgcolor = '#ae2ae212';

  const refresh = () => {};

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Box
          style={{
            borderRadius: 25,
            marginLeft: 25,
            width: width - drawerWidth - 30,
            height: height - 140,
          }}
        >
          <Box display={'flex'}>
            <Box
              style={{
                height: height - 100,
                paddingTop: 10,
                width: 200,
              }}
            >
              <TabsListVertical
                handleSelect={handleSelect}
                isRTL={isRTL}
                list={formgroup}
                theme={theme}
                color={fade(theme?.palette?.primary?.light, 0.3)}
                pathname={pathname}
                height={height}
              ></TabsListVertical>
            </Box>

            <Paper
              elevation={3}
              style={{
                marginTop: 5,
                display: 'flex',
                height: height - 130,
                overflow: 'auto',
                borderRadius: 25,
              }}
            >
              <Routes>
                <Route
                  index
                  element={<Navigate to={formgroup?.[0]?.path} replace />}
                />
                <Route
                  path={'progress'}
                  element={
                    <ServicesReportContext.Provider
                      value={{
                        state: servicesStore,
                        dispatch: servicesDispatch,
                      }}
                    >
                      <EventsItemsReport
                        start={start}
                        end={end}
                        isRTL={isRTL}
                        words={words}
                        company={company}
                        theme={theme}
                        drawerWidth={drawerWidth}
                      ></EventsItemsReport>
                    </ServicesReportContext.Provider>
                  }
                />
                <Route
                  path={'events'}
                  element={
                    <EventsReportContext.Provider
                      value={{
                        state: eventsReportStore,
                        dispatch: eventsReportDispatch,
                      }}
                    >
                      <EventsReport
                        isRTL={isRTL}
                        words={words}
                        company={company}
                        theme={theme}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></EventsReport>
                    </EventsReportContext.Provider>
                  }
                />
                <Route
                  path={'tasks'}
                  element={
                    <ContractsReportContext.Provider
                      value={{
                        state: contractsReportStore,
                        dispatch: contractsReportDispatch,
                      }}
                    >
                      <ContractsReport
                        isRTL={isRTL}
                        words={words}
                        company={company}
                        theme={theme}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></ContractsReport>
                    </ContractsReportContext.Provider>
                  }
                />
                <Route
                  path={'customers'}
                  element={
                    <CustomerReportContext.Provider
                      value={{
                        state: customerReportStore,
                        dispatch: customerReportDispatch,
                      }}
                    >
                      <CustomerReport
                        isRTL={isRTL}
                        words={words}
                        start={start}
                        end={end}
                        theme={theme}
                        company={company}
                        drawerWidth={drawerWidth}
                      ></CustomerReport>
                    </CustomerReportContext.Provider>
                  }
                />
                <Route
                  path={'cusbalnce'}
                  element={
                    <CustomerReportContext.Provider
                      value={{
                        state: customerReportStore,
                        dispatch: customerReportDispatch,
                      }}
                    >
                      <CustomerAllReport
                        isRTL={isRTL}
                        words={words}
                        start={start}
                        end={end}
                        theme={theme}
                        drawerWidth={drawerWidth}
                      ></CustomerAllReport>
                    </CustomerReportContext.Provider>
                  }
                />
                <Route
                  path={'sales'}
                  element={
                    <SalesReportContext.Provider
                      value={{
                        state: salesReportStore,
                        dispatch: salesReportDispatch,
                      }}
                    >
                      <SalesReport
                        isRTL={isRTL}
                        words={words}
                        start={start}
                        end={end}
                        theme={theme}
                        company={company}
                        drawerWidth={drawerWidth}
                      ></SalesReport>
                    </SalesReportContext.Provider>
                  }
                />
                <Route
                  path={'purchase'}
                  element={
                    <PurchaseReportContext.Provider
                      value={{
                        state: purchaseReportStore,
                        dispatch: purchaseReportDispatch,
                      }}
                    >
                      <PurchaseReport
                        isRTL={isRTL}
                        words={words}
                        start={start}
                        end={end}
                        theme={theme}
                        company={company}
                        drawerWidth={drawerWidth}
                      ></PurchaseReport>
                    </PurchaseReportContext.Provider>
                  }
                />
                <Route
                  path={'supbalnce'}
                  element={
                    <SupplierReportContext.Provider
                      value={{
                        state: supplierReportStore,
                        dispatch: supplierReportDispatch,
                      }}
                    >
                      <SupplierAllReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></SupplierAllReport>
                    </SupplierReportContext.Provider>
                  }
                />
                <Route
                  path={'suppliers'}
                  element={
                    <SupplierReportContext.Provider
                      value={{
                        state: supplierReportStore,
                        dispatch: supplierReportDispatch,
                      }}
                    >
                      <SupplierReportNew
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></SupplierReportNew>
                    </SupplierReportContext.Provider>
                  }
                />
                <Route
                  path="expenses"
                  element={
                    <ExpensesReportContext.Provider
                      value={{
                        state: expensesReportStore,
                        dispatch: expensesReportDispatch,
                      }}
                    >
                      <ExpensesReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        accounts={accounts}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></ExpensesReport>
                    </ExpensesReportContext.Provider>
                  }
                />
                <Route
                  path="peoductexp"
                  element={
                    <ExpensesReportContext.Provider
                      value={{
                        state: expensesReportStore,
                        dispatch: expensesReportDispatch,
                      }}
                    >
                      <ExpProductReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        accounts={accounts}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></ExpProductReport>
                    </ExpensesReportContext.Provider>
                  }
                />
                <Route
                  path="finance"
                  element={
                    <FinanceContext.Provider
                      value={{
                        state: financeStore,
                        dispatch: financeDispatch,
                      }}
                    >
                      <FinanceReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        accounts={accounts}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></FinanceReport>
                    </FinanceContext.Provider>
                  }
                />
                <Route
                  path="budget"
                  element={
                    <FinanceContext.Provider
                      value={{
                        state: financeStore,
                        dispatch: financeDispatch,
                      }}
                    >
                      <BudgetReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></BudgetReport>
                    </FinanceContext.Provider>
                  }
                />
                <Route
                  path="stock"
                  element={
                    <StockItems
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      start={start}
                      end={end}
                      drawerWidth={drawerWidth}
                    ></StockItems>
                  }
                />
                <Route
                  path="profit"
                  element={
                    <ProfitabilityContext.Provider
                      value={{
                        state: profitabilityStore,
                        dispatch: profitabilityDispatch,
                      }}
                    >
                      <ProfitabilityReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        mainaccounts={mainaccountsnames}
                        accounts={accounts}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></ProfitabilityReport>
                    </ProfitabilityContext.Provider>
                  }
                />
                <Route
                  path="full"
                  element={
                    <KaidContext.Provider
                      value={{
                        state: kaidStore,
                        dispatch: kaidDispatch,
                      }}
                    >
                      <KaidsReport
                        isRTL={isRTL}
                        words={words}
                        theme={theme}
                        company={company}
                        mainaccounts={mainaccountsnames}
                        accounts={accounts}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></KaidsReport>
                    </KaidContext.Provider>
                  }
                />
                <Route
                  path="items"
                  element={
                    <ServicesReportContext.Provider
                      value={{
                        state: servicesStore,
                        dispatch: servicesDispatch,
                      }}
                    >
                      <ServicesReport
                        isRTL={isRTL}
                        words={words}
                        company={company}
                        theme={theme}
                        start={start}
                        end={end}
                        drawerWidth={drawerWidth}
                      ></ServicesReport>
                    </ServicesReportContext.Provider>
                  }
                />
              </Routes>
            </Paper>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
}
