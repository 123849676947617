import { Box, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { sleep } from '../Shared/helpers';
import MyIcon from '../Shared/MyIcon';

function CopyClipBoard({ text, icon }: any) {
  const [open, setOpen] = React.useState(false);

  const copy = async () => {
    await navigator.clipboard.writeText(text);
    setOpen(true);
    await sleep(0.5);
    setOpen(false);
  };
  return (
    <Box display={'flex'}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied"
      >
        <Box
          title="Copy"
          display="flex"
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={copy}
        >
          {!icon && <Typography>{text}</Typography>}
          {icon && <MyIcon size={22} color="#7b7b7d" icon="copy"></MyIcon>}
        </Box>
      </Tooltip>
    </Box>
  );
}

export default CopyClipBoard;
