export const initStore = {
  lang: 'en',
  themeId: 0,
  user: null,
  token: null,
  notify: false,
  calendar: {
    duration: 30,
    start: 7.5,
    end: 22.5,
    eventlen: 60,
    group: false,
    todayColor: undefined,
    dayColor: undefined,
    todaySize: 16,
    daySize: 14,
    timeColor: undefined,
    timeSize: 12,
    indicatorColor: undefined,
    nameSize: 16,
    nameColor: undefined,
    eventColors: {
      2: '#7b7b7d',
      4: '#ff4040',
      6: '#3843d2',
      10: '#008000',
    },
  },
  tirms: {
    startFirst: new Date('2022-08-20'),
    endFirst: new Date('2022-11-20'),
    startSecond: new Date('2022-12-20'),
    endSecond: new Date('2023-06-20'),
  },
  daysales: null,
  minstock: null,
  packIssue: false,
  packIssueMsg: null,
  network: null, // web / local
  weburi: null, // jadwal.webredirect.org
  lastSuccess: Date.now(),
  wrongTimes: 0,
  startBlock: null,
  tempId: null,
  template: null,
  scrollbar: false,
  localuri: 'jadwal-main', // jadwal-server
  loading: false,
  fullscreen: false,
};
