import { Button } from '@material-ui/core';
import _ from 'lodash';
import { useRef } from 'react';

const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const convertJSONtoCSV = (items: any) => {
  const replacer = (_: any, value: any) =>
    value === null
      ? ''
      : value instanceof Date
      ? value.toLocaleString()
      : value.toString(); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  const csv = [
    header.join(','),
    ...items.map((row: any) =>
      header.map((fn) => JSON.stringify(row[fn], replacer)).join(',')
    ),
  ].join('\r\n');
  return '\uFEFF' + csv;
};

export const downloadCSVFile = (
  data: any,
  fileName = 'data',
  include = null
) => {
  if (!data) return;
  let rdata: any;
  if (include) {
    rdata = data.map((da: any) => _.pick(da, include));
  } else {
    rdata = data.map(
      ({
        __typename,
        _id,
        createdAt,
        updatedAt,
        branch,
        autoNo,
        disabled,
        userId,
        ...rest
      }) => ({ ...rest })
    );
  }

  const csv = convertJSONtoCSV(rdata);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadJSONFile = (data: any, fileName = 'template') => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + '.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const UploadJson = ({ setValue }: any) => {
  const inputRef = useRef(null);

  const openFile = (evt: any) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();
    let fileloaded = (e: any) => {
      const fileContents = e.target.result;
      if (isJsonString(fileContents)) {
        const value = JSON.parse(fileContents);
        setValue(value);
      }
    };
    reader.onload = fileloaded;
    reader.readAsText(fileObj);
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => inputRef.current.click()}
      >
        Upload
      </Button>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
        accept=".json"
        onChange={openFile}
      />
    </>
  );
};

export default UploadJson;

// export const downloadCSVFile = (rows: any, fileName = 'data') => {
//   var processRow = function (row: any) {
//     var finalVal = '';
//     for (var j = 0; j < row.length; j++) {
//       var innerValue = row[j] === null ? '' : row[j].toString();
//       if (row[j] instanceof Date) {
//         innerValue = row[j].toLocaleString();
//       }
//       var result = innerValue.replace(/"/g, '""');
//       if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
//       if (j > 0) finalVal += ',';
//       finalVal += result;
//     }
//     return finalVal + '\n';
//   };

//   var csvFile = '';
//   for (var i = 0; i < rows.length; i++) {
//     csvFile += processRow(rows[i]);
//   }

//   var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
//   console.log('csvFile', csvFile);
//   var link = document.createElement('a');
//   if (link.download !== undefined) {
//     var url = URL.createObjectURL(blob);
//     link.setAttribute('href', url);
//     link.setAttribute('download', fileName);
//     link.style.visibility = 'hidden';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }
// };
