/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { timeToHourMinute } from '../../../common';
import { renderStatusIcon } from './StatusIcon';
import { eventStatusEn } from '../../../constants';
import { GContextTypes } from '../../../types';
import { GlobalContext } from '../../../contexts';
import { eventColors as defColors } from '../../../constants/datatypes';
import WorkerLable from './WorkerLable';
// import LinkPhoneText from '../../../common/LinkPhoneText';

export const AppointmentContent = (props: any) => {
  const {
    startDate,
    // endDate,
    customerName,
    customerNameAr,
    customerPhone,
    serviceName,
    serviceNameAr,
    employeeName,
    employeeNameAr,
    status,
    desc,
    address,
    workers,
  } = props.data;

  const calendar = props.calendar;
  const eventColors = calendar?.eventColors ? calendar?.eventColors : defColors;
  const {
    translate: { isRTL },
  }: GContextTypes = useContext(GlobalContext);
  return (
    <Appointments.AppointmentContent {...props}>
      <div className={props.container}>
        {status && (
          <Tooltip title={eventStatusEn[status]}>
            <Box
              style={{
                position: 'absolute',
                right: isRTL ? undefined : 2,
                left: isRTL ? 2 : undefined,
                top: 2,
                zIndex: 12,
                width: 28,
                height: 28,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: eventColors?.[status],
                borderRadius: 14,
              }}
            >
              {renderStatusIcon(status, '#ffffff', 28)}
            </Box>
          </Tooltip>
        )}
        <Box display="flex" style={{ flexWrap: 'wrap', marginTop: -3 }}>
          <div style={{ fontSize: 14, fontWeight: 'bold' }}>
            {timeToHourMinute(startDate)}
          </div>
          {/* <div>{timeToHourMinute(endDate)}</div> */}
        </Box>
        <Box
          display="flex"
          style={{
            flexWrap: 'wrap',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          <Typography style={{ fontSize: 13 }}>{desc}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {workers?.map((worker: any) => (
            <WorkerLable isRTL={isRTL} worker={worker}></WorkerLable>
          ))}
        </Box>
        <Box
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            backgroundColor: '#3843d2',
            width: '100%',
            zIndex: 112,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle2">
              {isRTL ? customerNameAr : customerName}
            </Typography>
            <Typography variant="subtitle2">{customerPhone}</Typography>
            {/* <LinkPhoneText value={customerPhone}></LinkPhoneText> */}
          </Box>
          <Typography variant="subtitle2">{address}</Typography>
          <Box style={{ position: 'absolute', left: 5, bottom: 0 }}>
            <Typography variant="subtitle2">
              {isRTL ? employeeNameAr : employeeName}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption">
            {isRTL ? serviceNameAr : serviceName}
          </Typography>
        </Box>
      </div>
    </Appointments.AppointmentContent>
  );
};
