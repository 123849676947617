import { Button, CircularProgress, Typography } from '@material-ui/core';
import { userroles } from './check';

export default function ButtonInvoice({
  value,
  row,
  loading,
  selected,
  crtInvoice,
  isRTL,
}: any) {
  const disabled = !userroles?.appointments?.edit;
  const id = row?._id;
  const isloading = selected === id;
  if (value === true) {
    return (
      <span
        style={{
          padding: 5,
          color: '#25D366',
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        ✔
      </span>
    );
  }

  return (
    <Button
      disabled={disabled || isloading}
      variant="outlined"
      onClick={async () => await crtInvoice(id)}
    >
      <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
        {isRTL ? 'انشاء' : 'Create'}
      </Typography>
      {isloading && <CircularProgress color="primary" size={14} />}
    </Button>
  );
}
