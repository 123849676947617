import React from 'react';
import { moneyFormat, qtyFormat } from './colorFormat';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { userroles } from '../common';

export default function SimpleTable({ data, isRTL }) {
  return (
    <Box p={1} style={{ marginTop: -5 }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>
                {isRTL ? 'الاسم' : 'Name'}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">
                {isRTL ? 'العدد' : 'Quantity'}
              </TableCell>
              {userroles?.services?.admin && (
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  {isRTL ? 'المجموع' : 'Total'}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{qtyFormat(row?.qty)}</TableCell>
                {userroles?.services?.admin && (
                  <TableCell align="right">{moneyFormat(row?.total)}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
