/* eslint-disable import/no-anonymous-default-export */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createPrintTemplate,
  deletePrintTemplate,
  getPrintTemplates,
  updatePrintTemplate,
} from '../graphql';

export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getPrintTemplates);

  const [addPrintTemplate] = useMutation(createPrintTemplate, {
    refetchQueries: [{ query: getPrintTemplates }],
  });
  const [editPrintTemplate] = useMutation(updatePrintTemplate, {
    refetchQueries: [{ query: getPrintTemplates }],
  });
  const [removePrintTemplate] = useMutation(deletePrintTemplate, {
    refetchQueries: [{ query: getPrintTemplates }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const printtemplates = catpData?.data?.['getPrintTemplates']?.data || [];
  const refreshprinttemplate = () => catpData?.refetch();

  return {
    printtemplates,
    refreshprinttemplate,
    addPrintTemplate,
    editPrintTemplate,
    removePrintTemplate,
    loading: catpData?.loading,
  };
};
