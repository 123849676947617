import { Button, CircularProgress, Typography } from '@material-ui/core';
import { userroles } from './check';

export default function ButtonDone({
  row,
  doneEvent,
  loading,
  selected,
  isRTL,
}: any) {
  const disabled = !userroles?.appointments?.edit;
  const id = row.id;
  const isloading = selected === id;
  if (row?.status === 10) {
    return (
      <span
        style={{
          padding: 5,
          color: '#25D366',
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        ✔
      </span>
    );
  }

  return (
    <Button
      disabled={disabled || isloading}
      variant="outlined"
      onClick={async () => await doneEvent(id)}
      style={{ width: 70 }}
    >
      <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
        {isRTL ? 'انجاز' : 'Proceed'}
      </Typography>
      {isloading && (
        <CircularProgress
          style={{ position: 'absolute', left: 5 }}
          color="primary"
          size={14}
        />
      )}
    </Button>
  );
}
