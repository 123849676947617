/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { downloadCSVFile, getRowId, LinkPhone, userroles } from '../../common';
import { AlertLocal, SearchTable } from '../../components';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import PopupSupplier from '../../pubups/PopupSupplier';
import { getColumns } from '../../common/columns';
import {
  createSupplier,
  deleteSupplier,
  getSuppliers,
  updateSupplier,
} from '../../graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import {
  avataManageFormatter,
  nameManageLinkCustomer,
  purchaseFormatter,
  raseedFormatter,
} from '../../Shared/colorFormat';
import PopupSupplierView from '../../pubups/PopupSupplierView';
import { SupplierContext } from '../../contexts/managment';
import { useTemplate } from '../../hooks';
import createMultiSuppliers from '../../graphql/mutation/createMultiSuppliers';
import PopupSupplierImport from '../../pubups/PopupSupplierImport';
import ImportBtn from '../../common/ImportBtn';
import ExportBtn from '../../common/ExportBtn';
import Whatsapp from '../../common/Whatsapp';

const Suppliers = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme, company } = props;
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState(null);
  const [openImport, setOpenImport] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const { supplieroptions, tempoptions } = useTemplate();
  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const {
    state: { hiddenColumnNames },
    dispatch,
  } = useContext(SupplierContext);
  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };

  const col = getColumns({ isRTL, words });
  const [columns] = useState(
    [
      { name: 'avatar', title: ' ' },
      tempoptions?.additionId && { name: 'additionId', title: words.no },
      col.name,
      { name: 'phone', title: words.phoneNumber },
      supplieroptions?.email && { name: 'email', title: words.email },
      supplieroptions?.address && { name: 'address', title: words.address },
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 150 },
    { columnName: 'additionId', width: 120 },
    { columnName: col.name.name, width: 300 },
    { columnName: 'phone', width: 180 },
    { columnName: 'email', width: 200 },
    { columnName: 'address', width: 200 },
  ]);

  const [columnsViewer] = useState([
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'avatar', title: words.color },
  ]);

  const [loadSuppls, supplsData]: any = useLazyQuery(getSuppliers, {
    fetchPolicy: 'cache-and-network',
  });

  const refresQuery = {
    refetchQueries: [{ query: getSuppliers }],
  };

  useEffect(() => {
    if (openItem) {
      const tsks = supplsData?.data?.['getSuppliers']?.data || [];
      if (tsks && tsks.length > 0) {
        const opened = tsks.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
  }, [supplsData]);

  useEffect(() => {
    loadSuppls({ isRTL });
  }, []);

  const [addSupplier] = useMutation(createSupplier, refresQuery);
  const [editSupplier] = useMutation(updateSupplier, refresQuery);
  const [addMultiSuppliers] = useMutation(createMultiSuppliers, refresQuery);
  const [removeSupplier] = useMutation(deleteSupplier, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (supplsData?.refetch) {
        supplsData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeSupplier({ variables: { _id } });
      if (res?.data?.deleteSupplier?.ok === false) {
        if (res?.data?.deleteSupplier?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  useEffect(() => {
    if (supplsData?.data?.getSuppliers?.data) {
      const { data } = supplsData.data.getSuppliers;
      const rdata = data.map((d: any) => {
        return {
          ...d,
          raseed:
            d?.totalPurchaseInvoiced -
              d?.totalPurchasePaid -
              d?.totalPurchaseDiscount || 0,
        };
      });
      setRows(rdata);
    }
  }, [supplsData]);

  const downloadCSVSubmit = () => {
    const colnames = columns?.map((col: any) => col?.name);
    downloadCSVFile(rows, 'suppliers', colnames);
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: 8,
          left: isRTL ? 400 : undefined,
          right: isRTL ? undefined : 400,
          display: 'flex',
        }}
      >
        <ImportBtn open={() => setOpenImport(true)} isRTL={isRTL}></ImportBtn>
        <ExportBtn open={downloadCSVSubmit} isRTL={isRTL}></ExportBtn>
      </Box>

      <Grid
        rows={rows}
        columns={userroles?.supliers?.edit ? columns : columnsViewer}
        getRowId={getRowId}
      >
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />

        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 80 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={['avatar', col.name.name, 'phone', 'email', 'address']}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        {userroles?.supliers?.admin && (
          <DataTypeProvider
            for={['avatar']}
            formatterComponent={(props: any) =>
              avataManageFormatter({
                ...props,
                setItem,
                setOpenItem,
                isRTL,
                height: 70,
              })
            }
          ></DataTypeProvider>
        )}
        <DataTypeProvider
          for={['phone']}
          formatterComponent={({ value }) => (
            <Box display={'flex'}>
              {value && <Whatsapp phone={value}></Whatsapp>}
              {value && <LinkPhone value={value}></LinkPhone>}
            </Box>
          )}
        ></DataTypeProvider>
        {userroles?.supliers?.admin && (
          <DataTypeProvider
            for={[col.name.name]}
            formatterComponent={(props: any) =>
              nameManageLinkCustomer({
                ...props,
                setItem,
                setOpenItem,
                isRTL,
              })
            }
          ></DataTypeProvider>
        )}
        <DataTypeProvider
          for={[col.purchase.name]}
          formatterComponent={(props: any) =>
            purchaseFormatter({ ...props, theme, isRTL, height: 110 })
          }
        ></DataTypeProvider>
        <DataTypeProvider
          for={[col.raseed.name]}
          formatterComponent={raseedFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.supliers?.edit}
          showDeleteCommand={userroles?.supliers?.delete}
          showAddCommand={userroles?.supliers?.add}
          commandComponent={Command}
        ></TableEditColumn>
        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <PopupEditing
          theme={theme}
          addAction={addSupplier}
          editAction={editSupplier}
        >
          <PopupSupplier></PopupSupplier>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
      <PopupSupplierView
        open={openItem}
        onClose={onCloseItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupSupplierView>
      <PopupSupplierImport
        open={openImport}
        onClose={() => setOpenImport(false)}
        addMultiItems={addMultiSuppliers}
        isRTL={isRTL}
        theme={theme}
        words={words}
      ></PopupSupplierImport>
    </>
  );
});
export default Suppliers;
