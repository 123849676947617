import { Box, Paper } from '@material-ui/core';
import InvoiceBody from './InvoiceBody';
import InvoiceHeader from './InvoiceHeader';

const INV_HEADER = 50;

export default function Invoice({ height, width, isRTL }) {
  return (
    <Box style={{ height: height, width }}>
      <Paper style={{ margin: 20, marginTop: 0, borderRadius: 25 }}>
        <InvoiceHeader height={INV_HEADER}></InvoiceHeader>
        <InvoiceBody height={height - INV_HEADER - 20}></InvoiceBody>
      </Paper>
    </Box>
  );
}
