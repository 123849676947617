/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import { Box, Tooltip, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../contexts';
import { client } from '../graphql';
import AvatarColor from './AvatarColor';
import MyIcon from './MyIcon';

export default function UserBox(props: any) {
  const { isRTL } = props;
  const {
    store: { lang, user },
    dispatch,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const logout = () => {
    dispatch({ type: 'logout' });
  };
  const uname = user.name ? user.name : user.username;

  return (
    <Box
      style={{
        display: 'flex',
        height: 50,
        zIndex: 114,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {user && (
        <React.Fragment>
          <Box
            display="flex"
            alignItems="center"
            style={{
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <Box
              display="flex"
              style={{
                flex: 4,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                display="flex"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <Typography variant="subtitle1" style={{ color: '#fff' }}>
                  {uname?.substring(0, 10)}
                </Typography>
              </Box>
              <AvatarColor
                username={user.username}
                name={user.name}
                size={34}
              ></AvatarColor>
            </Box>
            <Tooltip
              title={
                lang === 'ar' ? 'Change to English' : 'تغيير الى اللغة العربية'
              }
            >
              <Box
                display="flex"
                style={{
                  width: 34,
                  height: 34,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  dispatch({
                    type: 'setLang',
                    payload: lang === 'ar' ? 'en' : 'ar',
                  });
                  window.location.reload();
                }}
              >
                <MyIcon size={24} color={'#fff'} icon={'lang'}></MyIcon>
              </Box>
            </Tooltip>
            <Tooltip title={isRTL ? 'تسجيل الخروج' : 'Logout'}>
              <Box
                display="flex"
                style={{
                  width: 34,
                  height: 34,
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  logout();
                  await client.resetStore();
                  navigate('/');
                }}
              >
                <MyIcon size={24} color={'#fff'} icon={'logout'}></MyIcon>
              </Box>
            </Tooltip>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
