/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from 'react';
import { getStoreItem } from '../store';

export default () => {
  const store = getStoreItem('store');
  const lang = store?.lang;
  const template = store?.template;

  const [tempwords, setTempwords]: any = useState({});

  useEffect(() => {
    const words = template?.words?.[lang];
    setTempwords(words);
  }, [lang]);

  return {
    templateId: template?.id,
    tempoptions: template?.options,
    calendaroptions: template?.calendaroptions,
    taskoptions: template?.taskoptions,
    eventoptions: template?.eventoptions,
    resorseoptions: template?.resorseoptions,
    employeeoptions: template?.employeeoptions,
    departmentoptions: template?.departmentoptions,
    customeroptions: template?.customeroptions,
    supplieroptions: template?.supplieroptions,
    mainPage: template?.mainPage,
    taskExtra: template?.taskExtra,
    emplExtra: template?.emplExtra,
    resoExtra: template?.resoExtra,
    custExtra: template?.custExtra,
    sortOrder: template?.sortOrder,
    tempwords,
  };
};
