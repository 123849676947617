import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect } from 'react';
import { getLastMonths } from '../common/time';

import getBranchsLandingChartData from '../graphql/query/getBranchsLandingChartData';
import { getDateMonthFormat } from '../Shared/colorFormat';
import { getStoreItem } from '../store';

const useBranchsLandingChart = () => {
  const store = getStoreItem('store');
  const lang = store?.lang;
  const isRTL = lang === 'ar' ? true : false;

  const [freshChartData, chartData]: any = useLazyQuery(
    getBranchsLandingChartData
  );

  useEffect(() => {
    freshChartData();
  }, [freshChartData]);

  const data = chartData?.data?.['getBranchsLandingChartData']?.data;

  const branchesData = [];

  const dfname = isRTL ? 'departmentNameAr' : 'departmentName';
  const efname = isRTL ? 'employeeNameAr' : 'employeeName';

  if (data) {
    const charts = JSON.parse(data);
    const { accounts, accountstoday, monthsSales } = charts;
    for (const acc of accounts) {
      branchesData.push({
        branch: acc?.branch,
        accounts: acc?.accounts,
      });
    }
    for (const acct of accountstoday) {
      branchesData.push({
        branch: acct?.branch,
        accountstoday: acct?.accountstoday,
      });
    }

    if (monthsSales) {
      for (const msales of monthsSales) {
        let salesMonths: any;
        let salesMonth: any;
        let salesMonthTotal: any;

        const mdata = _.groupBy(msales?.sales, 'month');
        const monthsKeys = Object.keys(mdata);
        const monthsSale = monthsKeys.map((month: any) => {
          const departs = _(mdata[month])
            .groupBy(dfname)
            .map((array, key) => ({
              name: key,
              count: _.sumBy(array, 'count'),
              amount: _.sumBy(array, 'amount'),
            }))
            .orderBy('name')
            .value();

          const employees = _(mdata[month])
            .groupBy(efname)
            .map((array, key) => ({
              name: key,
              count: _.sumBy(array, 'count'),
              amount: _.sumBy(array, 'amount'),
              color: array[0].color,
            }))
            .orderBy('name')
            .value();
          const time: any = mdata[month]?.[0]?.date;
          const total = _.sumBy(mdata[month], 'amount');
          const count = _.sumBy(mdata[month], 'count');
          const name = getDateMonthFormat(time, isRTL);
          return {
            name,
            departments: departs,
            employees,
            count,
            total,
            date: new Date(time),
          };
        });

        const monthsList = getLastMonths(6, isRTL);
        salesMonths = monthsList.map((mon: any) => {
          const is = monthsSale.filter((ds: any) => ds.name === mon);
          if (is && is.length > 0) {
            return is[0];
          } else {
            return {
              name: mon,
              count: 0,
              total: 0,
            };
          }
        });
        const mname = getDateMonthFormat(new Date(), isRTL);
        salesMonth = salesMonths.filter((sm: any) => sm.name === mname)?.[0];
        salesMonthTotal = salesMonth?.total;
        branchesData.push({
          branch: msales?.branch,
          salesMonths: salesMonths,
          salesMonth: salesMonth,
          salesMonthTotal: salesMonthTotal,
        });
      }
    }
  }
  return {
    branchesData,
    chartData,
  };
};

export default useBranchsLandingChart;
