/* eslint-disable no-new-func */
import { Box, Button } from '@material-ui/core';

import { nanoid } from 'nanoid';
import {
  CardBox,
  CashBox,
  CardBoxToday,
  CashBoxToday,
  DaysEvents,
  DaysEventStatus,
  DaysSales,
  MonthDepartEvents,
  MonthDepartSales,
  MonthEmplEvents,
  MonthEmplSales,
  MonthsEvents,
  MonthsSales,
  MonthTotalEvents,
  MonthTotalSales,
  PercentTodayBox,
  ResoursesBox,
  TodayTotalEvents,
  TodayTotalSales,
  AppointmentsBox,
  ContractsBox,
  EmployeesBox,
  RemindBox,
  WorkStatusBox,
  ReminderBox,
  SalesStatusBox,
} from '../page/boxs';

import {
  AppointmentIcon,
  AppointReportIcon,
  ExpensesIcon,
  FinanceIcon,
  InvoiceIcon,
  PayAdvIcon,
  ProgressReportIcon,
  RecAdvIcon,
  ReceiptIcon,
} from '../page/IconBoxs';
import {
  AppointmentsSumBx,
  TaskDaysBx,
  ExpenseDataBx,
  ImagesBx,
  IncomeDataBx,
  KaidsDataBx,
  PurchaseDataBx,
  RaseedDataBx,
  SalesDataBx,
  ItemDataBox,
} from './main';
export const renderButton = (title: any, action: any) => (
  <Button variant="outlined" color="primary" onClick={action} fullWidth>
    {title}
  </Button>
);

export const rid = () => {
  return nanoid(4);
};

export const basicitem = {
  type: 'CashBox',
  skip: 0,
  size: 3,
  height: 150,
  dark: false,
  systemcolor: 'primary',
  color: undefined,
  background: undefined,
};
export const basicempitem = {
  type: 'AppointmentsSumBx',
  skip: 0,
  size: 3,
  height: 150,
  dark: false,
  systemcolor: 'primary',
  color: undefined,
  background: undefined,
};

export const zeroeleven = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
];
export const onetwelve = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];
export const systemcolors = ['primary', 'secondary'];
export const options = [
  'CardBox',
  'CashBox',
  'CardBoxToday',
  'CashBoxToday',
  'DaysEvents',
  'DaysEventStatus',
  'DaysSales',
  'MonthDepartEvents',
  'MonthDepartSales',
  'MonthEmplEvents',
  'MonthEmplSales',
  'MonthsEvents',
  'MonthsSales',
  'MonthTotalEvents',
  'MonthTotalSales',
  'PercentTodayBox',
  'ResoursesBox',
  'TodayTotalEvents',
  'TodayTotalSales',
  'AppointmentIcon',
  'AppointReportIcon',
  'ExpensesIcon',
  'FinanceIcon',
  'InvoiceIcon',
  'PayAdvIcon',
  'ProgressReportIcon',
  'RecAdvIcon',
  'ReceiptIcon',
  'AppointmentsBox',
  'ContractsBox',
  'EmployeesBox',
  'RemindBox',
  'WorkStatusBox',
  'SalesStatusBox',
].sort();
export const tempoptions = [
  'AppointmentsSumBx',
  'TaskDaysBx',
  'ExpenseDataBx',
  'ImagesBx',
  'IncomeDataBx',
  'KaidsDataBx',
  'PurchaseDataBx',
  'RaseedDataBx',
  'SalesDataBx',
  'ItemDataBox',
  'ReminderBox',
  'RemindBox',
].sort();

export const renderCell = (type: any, args: any, isMobile: any) => {
  return (
    <Box>
      {type === 'AppointmentsSumBx' && (
        <AppointmentsSumBx {...args}></AppointmentsSumBx>
      )}
      {type === 'TaskDaysBx' && <TaskDaysBx {...args}></TaskDaysBx>}
      {type === 'ExpenseDataBx' && <ExpenseDataBx {...args}></ExpenseDataBx>}
      {type === 'ImagesBx' && <ImagesBx {...args}></ImagesBx>}
      {type === 'IncomeDataBx' && <IncomeDataBx {...args}></IncomeDataBx>}
      {type === 'KaidsDataBx' && <KaidsDataBx {...args}></KaidsDataBx>}
      {type === 'PurchaseDataBx' && <PurchaseDataBx {...args}></PurchaseDataBx>}
      {type === 'RaseedDataBx' && <RaseedDataBx {...args}></RaseedDataBx>}
      {type === 'SalesDataBx' && <SalesDataBx {...args}></SalesDataBx>}
      {type === 'ItemDataBox' && <ItemDataBox {...args}></ItemDataBox>}
      {type === 'CardBox' && <CardBox {...args}></CardBox>}
      {type === 'CashBox' && <CashBox {...args}></CashBox>}
      {type === 'CardBoxToday' && <CardBoxToday {...args}></CardBoxToday>}
      {type === 'CashBoxToday' && <CashBoxToday {...args}></CashBoxToday>}
      {type === 'DaysEvents' && <DaysEvents {...args}></DaysEvents>}
      {type === 'DaysEventStatus' && (
        <DaysEventStatus {...args}></DaysEventStatus>
      )}
      {type === 'DaysSales' && <DaysSales {...args}></DaysSales>}
      {type === 'MonthDepartEvents' && (
        <MonthDepartEvents {...args}></MonthDepartEvents>
      )}
      {type === 'MonthDepartSales' && (
        <MonthDepartSales {...args}></MonthDepartSales>
      )}
      {type === 'MonthEmplEvents' && (
        <MonthEmplEvents {...args}></MonthEmplEvents>
      )}
      {type === 'MonthEmplSales' && <MonthEmplSales {...args}></MonthEmplSales>}
      {type === 'MonthsEvents' && <MonthsEvents {...args}></MonthsEvents>}
      {type === 'MonthsSales' && <MonthsSales {...args}></MonthsSales>}
      {type === 'MonthTotalEvents' && (
        <MonthTotalEvents {...args}></MonthTotalEvents>
      )}
      {type === 'MonthTotalSales' && (
        <MonthTotalSales {...args}></MonthTotalSales>
      )}
      {type === 'PercentTodayBox' && (
        <PercentTodayBox {...args}></PercentTodayBox>
      )}
      {type === 'ResoursesBox' && <ResoursesBox {...args}></ResoursesBox>}
      {type === 'TodayTotalEvents' && (
        <TodayTotalEvents {...args}></TodayTotalEvents>
      )}
      {type === 'TodayTotalSales' && (
        <TodayTotalSales {...args}></TodayTotalSales>
      )}
      {type === 'AppointmentIcon' && (
        <AppointmentIcon {...args}></AppointmentIcon>
      )}
      {type === 'AppointReportIcon' && (
        <AppointReportIcon {...args}></AppointReportIcon>
      )}
      {type === 'ExpensesIcon' && <ExpensesIcon {...args}></ExpensesIcon>}
      {type === 'FinanceIcon' && <FinanceIcon {...args}></FinanceIcon>}
      {type === 'InvoiceIcon' && <InvoiceIcon {...args}></InvoiceIcon>}
      {type === 'PayAdvIcon' && <PayAdvIcon {...args}></PayAdvIcon>}
      {type === 'ProgressReportIcon' && (
        <ProgressReportIcon {...args}></ProgressReportIcon>
      )}
      {type === 'RecAdvIcon' && <RecAdvIcon {...args}></RecAdvIcon>}
      {type === 'ReceiptIcon' && <ReceiptIcon {...args}></ReceiptIcon>}
      {type === 'AppointmentsBox' && !isMobile && (
        <AppointmentsBox {...args}></AppointmentsBox>
      )}
      {type === 'ContractsBox' && <ContractsBox {...args}></ContractsBox>}
      {type === 'EmployeesBox' && <EmployeesBox {...args}></EmployeesBox>}
      {type === 'RemindBox' && !isMobile && <RemindBox {...args}></RemindBox>}
      {type === 'WorkStatusBox' && !isMobile && (
        <WorkStatusBox {...args}></WorkStatusBox>
      )}
      {type === 'SalesStatusBox' && !isMobile && (
        <SalesStatusBox {...args}></SalesStatusBox>
      )}
      {type === 'ReminderBox' && !isMobile && (
        <ReminderBox {...args}></ReminderBox>
      )}
    </Box>
  );
};
