/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { nameToColor } from '../../common/helpers';

export default function Avatar({
  name,
  username,
  size = 45,
  bc = '#ddd',
  bg,
  header,
  email,
  isRTL,
  logout,
  navigate,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const uname = name ? name : username;
  const uemail = email ? email : username;
  const color = bg ? bg : uname ? nameToColor(`${uname}Jadwal`) : '';
  const names = uname ? uname.split(' ') : '';
  let letters = '';
  if (names.length > 1) {
    uname
      ? (letters = `${names[0].substring(0, 1).toUpperCase()}${names[1]
          .substring(0, 1)
          .toUpperCase()}`)
      : (letters = '');
  } else {
    uname ? (letters = uname.substring(0, 2).toUpperCase()) : (letters = '');
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (logout) {
      logout();
    }
    setAnchorEl(null);
  };
  const handleHome = () => {
    if (navigate) {
      navigate('/');
    }
    setAnchorEl(null);
  };
  const handleOptions = () => {
    if (navigate) {
      navigate('/options');
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Paper
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          padding: 0,
          margin: 0,
          backgroundColor: color,
        }}
        aria-describedby={id}
        elevation={4}
      >
        <Box
          style={{
            display: 'flex',
            width: size,
            height: size,
            borderRadius: size / 2,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={handleClick}
        >
          <Typography
            style={{
              color: '#f5f5f5',
              fontWeight: 'bold',
              fontSize: size / 2 - size / 8,
            }}
          >
            {letters}
          </Typography>
        </Box>
      </Paper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { borderRadius: 15, marginTop: 5 } }}
      >
        <Box>
          <List style={{ minWidth: 200, padding: 10 }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography style={{ fontWeight: 'bold' }}>
                    {uname}
                  </Typography>
                }
                secondary={uemail}
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={handleHome}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'الصفحة الرئيسية' : 'Home'}</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={handleOptions}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography>{isRTL ? 'الاعدادات' : 'Options'}</Typography>
              </ListItemText>
            </ListItem>
            <Divider />

            <ListItem
              button
              onClick={handleLogout}
              style={{ margin: 5, borderRadius: 7 }}
            >
              <ListItemText>
                <Typography style={{ fontSize: 13, fontWeight: 'bold' }}>
                  {isRTL ? 'تسجيل خروج' : 'Logout'}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </Box>
  );
}
