/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  Table,
  TableColumnReordering,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import { SearchTable } from '../../components';
import {
  createdAtEmailFormatter,
  emailAvatarFormatter,
  emailPriorityFormatter,
  emailStatusFormatter,
  favOutboxFormatter,
  textEmailFormatter,
  titleEmailFormatter,
} from '../../Shared/colorFormat';
import { useTemplate } from '../../hooks';
import getMyOutbox from '../../graphql/query/getMyOutbox';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TableComponentEM } from '../components/TableComponent';
import { useNavigate } from 'react-router-dom';
import updateProStar from '../../graphql/mutation/updateProStar';

const Outbox = forwardRef((props: any, ref: any) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const { isRTL, stepitems } = props;
  const [rows, setRows] = useState([]);

  const [columns] = useState([
    { name: 'outstar', title: 'Fav' },
    { name: 'inboxName', title: 'Reciver' },
    { name: 'title', title: 'title' },
    { name: 'desc', title: 'desciption' },
    { name: 'priority', title: 'Priority' },
    { name: 'stepId', title: 'Status' },
    { name: 'createdAt', title: 'Time' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'outstar', width: 70, align: 'center' },
    { columnName: 'inboxName', width: 220 },
    { columnName: 'title', width: 220 },
    { columnName: 'desc', width: 'auto' },
    { columnName: 'priority', width: 120 },
    { columnName: 'stepId', width: 120 },
    { columnName: 'createdAt', width: 180, align: 'center' },
  ]);

  const { tempoptions } = useTemplate();
  const navigate = useNavigate();

  const [loadInbox, inboxData]: any = useLazyQuery(getMyOutbox, {
    fetchPolicy: 'cache-and-network',
  });

  const refresQuery = {
    refetchQueries: [
      {
        query: getMyOutbox,
        variables: { skip: 0 },
      },
    ],
  };

  const [star] = useMutation(updateProStar, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (inboxData?.refetch) {
        inboxData.refetch();
      }
    },
  }));

  useEffect(() => {
    const d = inboxData?.data?.getMyOutbox?.data;
    if (d) {
      const rpross = d.map((pro: any) => {
        return { ...pro, read: true };
      });
      setRows(rpross);
    }
  }, [inboxData]);

  useEffect(() => {
    loadInbox({ variables: { skip: page * limit, limit } });
  }, [page, limit]);

  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <SortingState />
      <SearchState />
      <PagingState
        defaultCurrentPage={0}
        defaultPageSize={limit}
        onCurrentPageChange={(n: any) => setPage(n)}
        onPageSizeChange={(n: any) => setLimit(n)}
      />
      <IntegratedSorting />
      <IntegratedFiltering />
      <IntegratedPaging />
      <DragDropProvider />
      <Table
        messages={{
          noData: isRTL ? 'لا يوجد رسائل' : 'no mail',
        }}
        tableComponent={TableComponentEM}
        rowComponent={(props: any) => (
          <Table.Row
            {...props}
            onClick={() => {
              if (navigate) {
                navigate(props?.row._id);
              }
            }}
            style={{
              cursor: 'pointer',
              height: 50,
              backgroundColor: '#f7f7f7',
            }}
          ></Table.Row>
        )}
        columnExtensions={tableColumnExtensions}
      />

      <TableColumnReordering
        defaultOrder={[
          'outstar',
          'inboxName',
          'title',
          'desc',
          'stepId',
          'priority',
          'createdAt',
        ]}
      />

      <DataTypeProvider
        for={['outstar']}
        formatterComponent={(props: any) =>
          favOutboxFormatter({ ...props, star })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['inboxName']}
        formatterComponent={emailAvatarFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['title']}
        formatterComponent={(props: any) =>
          titleEmailFormatter({ ...props, isRTL })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['desc']}
        formatterComponent={textEmailFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['priority']}
        formatterComponent={(props: any) =>
          emailPriorityFormatter({ ...props, isRTL })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['stepId']}
        formatterComponent={(props: any) =>
          emailStatusFormatter({ ...props, stepitems, isRTL })
        }
      ></DataTypeProvider>
      <DataTypeProvider
        for={['createdAt']}
        formatterComponent={createdAtEmailFormatter}
      ></DataTypeProvider>
      <Toolbar />
      <PagingPanel
        pageSizes={tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]}
      />
      <SearchPanel
        inputComponent={(props: any) => {
          return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
        }}
      />
    </Grid>
  );
});
export default Outbox;
