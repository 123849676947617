import { gql } from '@apollo/client';

export default gql`
  query getNotifications(
    $userId: String
    $eventId: Int
    $contractId: String
    $read: Boolean
  ) {
    getNotifications(
      userId: $userId
      eventId: $eventId
      contractId: $contractId
      read: $read
    ) {
      ok
      error
      data {
        _id
        branch
        userId
        title
        body
        url
        reminderId
        eventId
        projectId
        contractId
        customerId
        departmentId
        employeeId
        resourseId
        read
        createdAt
        updatedAt
      }
    }
  }
`;
