import { gql } from '@apollo/client';

export default gql`
  mutation updatePrintTemplate(
    $_id: String
    $name: String
    $type: String
    $template: String
    $isdefault: Boolean
    $bgimages: String
    $size: String
    $header: Boolean
    $footer: Boolean
    $note: String
  ) {
    updatePrintTemplate(
      _id: $_id
      name: $name
      type: $type
      template: $template
      isdefault: $isdefault
      bgimages: $bgimages
      size: $size
      header: $header
      footer: $footer
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
