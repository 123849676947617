/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { moneyFormat } from './colorFormat';

export const PriceTotal = ({
  amount,
  total,
  words,
  totalonly,
  discount,
  end = true,
}: any) => {
  return (
    <Box
      display="flex"
      style={{
        flex: 1,
        flexDirection: 'column',
        alignItems: end ? 'flex-end' : 'flex-start',
        justifyContent: 'center',
        width: 250,
        margin: 20,
      }}
    >
      <Grid container spacing={1}>
        {!totalonly && (
          <>
            <Grid item xs={5}>
              <Typography style={{}}>{words.total}</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography style={{}}>{moneyFormat(total)}</Typography>
            </Grid>
          </>
        )}
        {(discount || discount === 0) && (
          <>
            <Grid item xs={5}>
              <Typography style={{}}>{words.discount}</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography style={{}}>{moneyFormat(discount)}</Typography>
            </Grid>
          </>
        )}
        <Grid item xs={5}>
          <Typography
            style={{ fontWeight: 'bold', fontSize: 16, paddingTop: 10 }}
          >
            {totalonly ? words.total : words.grandtotal}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            style={{ fontWeight: 'bold', fontSize: 16, paddingTop: 10 }}
          >
            {moneyFormat(amount)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
