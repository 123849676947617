/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableEditColumn,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  fade,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  DataTypeProvider,
  EditingState,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { getColumns } from '../common/columns';
import {
  createdAtFormatter,
  currencyFormatter,
  eventStatusFormatter,
  fromToFormatter,
  isActiveViewFormatter,
  locationFormatter,
} from './colorFormat';
import { useLazyQuery, useMutation } from '@apollo/client';

import getObjectEvents from '../graphql/query/getObjectEvents';
import useTasks from '../hooks/useTasks';
import { useCustomers, useServices, useTemplate } from '../hooks';
import { updateDocNumbers, userroles } from '../common';
import { createEvent, deleteEventById, updateEvent } from '../graphql';
import { Getter } from '@devexpress/dx-react-core';
import PopupEditing from './PopupEditing';
import { Command } from './Command';
import PopupAppointmentCustomer from '../pubups/PopupAppointmentCustomer';
import useDepartments from '../hooks/useDepartments';
import useEmployees from '../hooks/useEmployees';
import useResourses from '../hooks/useResourses';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import { getProjects, getTasks } from '../graphql/query';
import RefetchBox from './RefetchBox';
import { GlobalContext } from '../contexts';
import deleteEventsByIds from '../graphql/mutation/deleteEventsByIds';
import { createInvoicesFromEvents } from '../graphql/mutation';
import ButtonDone from '../common/ButtonDone';
export const getRowId = (row: any) => row._id;

const NumberTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <Typography variant="subtitle2">{Number(value) + 1}</Typography>
    )}
    {...props}
  />
);

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);

export default function EventsCustomer({
  isRTL,
  words,
  company,
  value,
  theme,
  id,
  name,
  width,
  height,
  start,
  end,
  tempoptions,
  mstart,
  mend,
}: any) {
  const col = getColumns({ isRTL, words });

  const [selection, setSelection] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selg, setSelg] = useState('');
  const [editing, setEditing] = useState(false);
  const [selected, setSelected] = useState(null);
  const { eventoptions } = useTemplate();

  const [columns] = useState(
    [
      col.startDate,
      col.docNo,
      col.customer,
      tempoptions?.employee && col.employee,
      tempoptions?.contract && col.contract,
      tempoptions?.resourse && col.resourse,
      // tempoptions?.department && col.department,
      // eventoptions?.retype && tempoptions?.retype && col.retype,
      // eventoptions?.retype2 && tempoptions?.retype2 && col.retype2,
      col.done,
      eventoptions?.noinvoicecheck && col.invoiced,
      eventoptions?.noinvoicecheck && col.invoiceId,
      name === 'contractId' && col.groupId,
      col.amount,
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: 'startDate', width: 200 },
    { columnName: 'docNo', width: 120 },
    { columnName: col.customer.name, width: 150 },
    { columnName: col.employee.name, width: 150, align: 'center' },
    { columnName: col.contract.name, width: 150 },
    { columnName: col.resourse.name, width: 150 },
    { columnName: col.department.name, width: 150 },
    { columnName: col.retype.name, width: 150 },
    { columnName: col.retype2.name, width: 150 },
    { columnName: 'done', width: 100, align: 'center' },
    { columnName: 'invoiced', width: 100, align: 'center' },
    { columnName: 'invoiceId', width: 100, align: 'center' },
    { columnName: 'groupId', width: 100 },
    { columnName: 'amount', width: 120 },
  ]);

  const { tasks } = useTasks();
  const [rows, setRows] = useState([]);
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { customers } = useCustomers();
  const { services } = useServices();
  const refresQuery = {
    refetchQueries: [
      {
        query: getObjectEvents,
        variables: {
          [name]: id,
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
      {
        query: getGereralCalculation,
        variables: {
          [name]: id,
          start: start ? new Date(start).setHours(0, 0, 0, 0) : undefined,
          end: end ? new Date(end).setHours(23, 59, 59, 999) : undefined,
        },
      },
      name === 'contractId'
        ? {
            query: getTasks,
            variables: {
              contractId: id,
              start: mstart ? new Date(mstart).setHours(0, 0, 0, 0) : undefined,
              end: mend ? new Date(mend).setHours(23, 59, 59, 999) : undefined,
            },
          }
        : undefined,
      name === 'contractId' ? { query: getTasks } : undefined,
      name === 'projectId' ? { query: getProjects } : undefined,
    ].filter((x: any) => x),
  };

  const [getEvents, eventsData]: any = useLazyQuery(getObjectEvents, {
    fetchPolicy: 'cache-and-network',
  });
  const [removeEventsByIds] = useMutation(deleteEventsByIds, refresQuery);
  const [addInvoicesEvents] = useMutation(
    createInvoicesFromEvents,
    refresQuery
  );

  const addInvoice = async (id: any) => {
    try {
      await addInvoicesEvents({ variables: { eventIds: [id], group: false } });
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteSelected = async () => {
    if (window.confirm('Are you sure you want to delete rows?')) {
      await removeEventsByIds({ variables: { ids: selection } });
      setSelection([]);
    }
  };

  useEffect(() => {
    const variables = {
      [name]: id,
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    getEvents({ variables });
  }, [id, start, end]);

  useEffect(() => {
    const data = eventsData?.data?.['getObjectEvents']?.data;
    const events = data || [];
    const rdata = updateDocNumbers(events);
    let rfdata = rdata;
    if (selg && selg !== '') {
      rfdata = rdata.filter((rd: any) => rd.groupId === selg);
    }
    if (selg === '') {
      rfdata = rdata;
    }
    setRows(rfdata);
    const grps = rdata.map((rd: any) => rd?.groupId)?.filter((x: any) => x);
    const gids = Array.from(new Set([...grps]));
    setGroups(gids);
  }, [eventsData, selg]);

  const [addEvent] = useMutation(createEvent, refresQuery);
  const [editEvent] = useMutation(updateEvent, refresQuery);
  const [removeEventById] = useMutation(deleteEventById, refresQuery);

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeEventById({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };

  const handleChange = (event: any) => {
    setSelg(event.target.value);
  };

  const doneEvent = async (id: any) => {
    setEditing(true);
    setSelected(id);
    try {
      const variables = { id, status: 10 };
      await editEvent({ variables });
    } catch (error) {
      console.log('error', error);
      setSelected(null);
      setEditing(false);
    }
    setSelected(null);
    setEditing(false);
  };

  const refresh = () => eventsData?.refetch();
  const loading = eventsData.loading;
  return (
    <Box
      style={{
        height: height - 250,
        width: width - 300,
        margin: 10,
      }}
    >
      <Box
        style={{
          position: 'absolute',
          width: 50,
          height: 50,
          left: isRTL ? 220 : undefined,
          right: isRTL ? undefined : 220,
          zIndex: 111,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 55,
        }}
      >
        <RefetchBox
          isRTL={isRTL}
          theme={theme}
          refresh={refresh}
          loading={loading}
        ></RefetchBox>
      </Box>
      <Paper
        style={{
          height: height - 260,
          width: width - 320,
          overflow: 'auto',
        }}
      >
        <Box
          style={{
            display: 'flex',
            margin: 5,
            padding: 5,
            marginBottom: -5,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {groups?.length > 1 && (
            <Select
              value={selg}
              onChange={handleChange}
              variant="outlined"
              label="Group"
              style={{
                width: 120,
                marginLeft: 10,
                marginRight: 10,
                height: 32,
              }}
            >
              <MenuItem value="">
                <em>{isRTL ? 'الكل' : 'All'}</em>
              </MenuItem>
              {groups.map((g: any) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
          )}
          {userroles?.customers?.admin && (
            <Button
              color="secondary"
              variant="contained"
              disabled={selection?.length === 0}
              onClick={deleteSelected}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <Typography>
                {isRTL ? 'حذف المحدد' : 'Delete Selected'}
              </Typography>
            </Button>
          )}
        </Box>
        {rows && (
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState />
            <EditingState onCommitChanges={commitChanges} />
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <IntegratedSorting />
            <IntegratedSelection />

            <VirtualTable
              height={680}
              messages={{
                noData: isRTL ? 'لا يوجد بيانات' : 'no data',
              }}
              estimatedRowHeight={45}
              tableComponent={TableComponent}
              columnExtensions={tableColumnExtensions}
            />
            <DataTypeProvider
              for={['startDate']}
              formatterComponent={createdAtFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['fromto']}
              formatterComponent={fromToFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['location']}
              formatterComponent={locationFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['status']}
              formatterComponent={eventStatusFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['amount']}
              formatterComponent={currencyFormatter}
            ></DataTypeProvider>
            <DataTypeProvider
              for={['invoiced']}
              formatterComponent={(props: any) =>
                isActiveViewFormatter({ ...props, addInvoice, isRTL })
              }
            ></DataTypeProvider>
            <DataTypeProvider
              for={['done']}
              formatterComponent={(props: any) => (
                <ButtonDone
                  {...props}
                  doneEvent={doneEvent}
                  isRTL={isRTL}
                  loading={editing}
                  selected={selected}
                ></ButtonDone>
              )}
            ></DataTypeProvider>
            <NumberTypeProvider for={['index']} />
            <TableHeaderRow
              showSortingControls
              titleComponent={({ children }) => {
                return (
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {children}
                  </Typography>
                );
              }}
            />

            <TableEditColumn
              showEditCommand={userroles?.appointments?.edit}
              showDeleteCommand={userroles?.appointments?.delete}
              showAddCommand={userroles?.appointments?.add}
              commandComponent={Command}
            ></TableEditColumn>

            <PopupEditing addAction={addEvent} editAction={editEvent}>
              <PopupAppointmentCustomer
                resourses={resourses}
                employees={employees}
                departments={departments}
                customers={customers}
                servicesproducts={services}
                theme={theme}
                company={company}
                tasks={tasks}
                name={name}
                value={value}
                calendar={calendar}
              ></PopupAppointmentCustomer>
            </PopupEditing>
            <TableSelection showSelectAll />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                const result = [
                  {
                    key: 'editCommand',
                    type: TableEditColumn.COLUMN_TYPE,
                    width: 100,
                  },
                  ...tableColumns.filter(
                    (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                  ),
                ];
                return result;
              }}
            />
          </Grid>
        )}
      </Paper>
    </Box>
  );
}
