import { gql } from '@apollo/client';

export default gql`
  query getFormDocs {
    getFormDocs {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        additionId
        name
        nameAr
        pname
        pnameAr
        dynamic
        disabled
        showOnCal

        dcategoryId
        dcategoryName
        dcategoryNameAr
        gref
        order

        opType
        autoNoType
        autoNoPrefix
        withitems
        itemTypes
        withkaids
        debitAcc
        creditAcc
        fields
        procTemp
        printTempDoc
        formTempDoc
        formTempValues
        printTempReport
        options
        note

        userId

        createdAt
        updatedAt
      }
    }
  }
`;
