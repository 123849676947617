/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  SearchPanel,
  Toolbar,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  ColumnChooser,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getRowId, updateDocNumbers, userroles } from '../../common';
import PopupFinance from '../../pubups/PopupFinance';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createFinance,
  deleteFinance,
  getFinances,
  updateFinance,
} from '../../graphql';
import {
  accountFormatter,
  currencyFormatter,
  opTypeFormatter,
  samllFormatter,
  timeFormatter,
} from '../../Shared/colorFormat';
import useAccounts from '../../hooks/useAccounts';
import { SearchTable } from '../../components';
import { TableComponent } from '../../Shared/TableComponent';
import { Typography } from '@material-ui/core';
import { useTemplate } from '../../hooks';

const Finance = forwardRef((props: any, ref: any) => {
  const { isRTL, words, start, end, theme } = props;
  const [columns] = useState([
    { name: 'time', title: words?.time },
    { name: 'debitAcc', title: words?.to },
    { name: 'creditAcc', title: words?.from },
    { name: 'opType', title: words?.type },
    { name: 'desc', title: words?.description },
    { name: 'docNo', title: words?.no },
    { name: 'amount', title: words?.amount },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'time', width: 120 },
    { columnName: 'debitAcc', width: 150 },
    { columnName: 'creditAcc', width: 150 },
    { columnName: 'opType', width: 150 },
    { columnName: 'desc', width: 250 },
    { columnName: 'docNo', width: 120 },
    { columnName: 'amount', width: 120 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'time', togglingEnabled: false },
    { columnName: 'creditAcc', togglingEnabled: false },
    { columnName: 'debitAcc', togglingEnabled: false },
  ]);

  const [rows, setRows] = useState([]);
  const { tempoptions } = useTemplate();

  const [loadFinances, financeData]: any = useLazyQuery(getFinances, {
    fetchPolicy: 'cache-and-network',
  });
  const { accounts } = useAccounts();
  const refresQuery = {
    refetchQueries: [
      {
        query: getFinances,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    if (start) {
      loadFinances({
        variables,
      });
    }
  }, [start, end]);

  const [addFinance] = useMutation(createFinance, refresQuery);
  const [editFinance] = useMutation(updateFinance, refresQuery);
  const [removeFinance] = useMutation(deleteFinance, refresQuery);

  useImperativeHandle(ref, () => ({
    refresh() {
      if (financeData?.refetch) {
        financeData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      removeFinance({ variables: { _id } });
      setRows(rows.filter((row: any) => row._id !== _id));
    }
  };

  useEffect(() => {
    if (financeData?.data?.getFinances?.data) {
      const { data } = financeData.data.getFinances;
      const rdata = updateDocNumbers(data);
      setRows(rdata);
    }
  }, [financeData]);

  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <SortingState />
      <EditingState onCommitChanges={commitChanges} />
      <SearchState />
      <PagingState defaultCurrentPage={0} defaultPageSize={10} />
      <IntegratedSorting />
      <IntegratedFiltering />
      <IntegratedPaging />
      <DragDropProvider />
      <Table
        messages={{
          noData: isRTL ? 'لا يوجد بيانات' : 'no data',
        }}
        tableComponent={TableComponent}
        rowComponent={(props: any) => (
          <Table.Row {...props} style={{ height: 60 }}></Table.Row>
        )}
        columnExtensions={tableColumnExtensions}
      />

      <TableColumnReordering
        defaultOrder={[
          'time',
          'debitAcc',
          'creditAcc',
          'opType',
          'desc',
          'docNo',
          'amount',
        ]}
      />
      <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
      <TableHeaderRow
        showSortingControls
        titleComponent={({ children }) => {
          return (
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
              {children}
            </Typography>
          );
        }}
      />
      <TableColumnVisibility
        columnExtensions={tableColumnVisibilityColumnExtensions}
        defaultHiddenColumnNames={[]}
      />
      <DataTypeProvider
        for={['time']}
        formatterComponent={timeFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['amount']}
        formatterComponent={currencyFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['docNo']}
        formatterComponent={samllFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['opType']}
        formatterComponent={opTypeFormatter}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['creditAcc']}
        formatterComponent={(props) => accountFormatter(props, accounts, isRTL)}
      ></DataTypeProvider>
      <DataTypeProvider
        for={['debitAcc']}
        formatterComponent={(props) => accountFormatter(props, accounts, isRTL)}
      ></DataTypeProvider>
      <TableEditColumn
        showEditCommand={userroles?.cash?.edit}
        showDeleteCommand={userroles?.cash?.delete}
        showAddCommand={userroles?.cash?.add}
        commandComponent={Command}
      ></TableEditColumn>
      <Toolbar
        rootComponent={(props: any) => (
          <Toolbar.Root
            style={{
              position: 'absolute',
              left: isRTL ? 40 : undefined,
              right: isRTL ? undefined : 40,
              top: 62,
              zIndex: 115,
            }}
            {...props}
          ></Toolbar.Root>
        )}
      />
      <ColumnChooser />
      <PagingPanel
        pageSizes={tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]}
      />

      <SearchPanel
        inputComponent={(props: any) => {
          return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
        }}
      />

      <PopupEditing
        theme={theme}
        addAction={addFinance}
        editAction={editFinance}
      >
        <PopupFinance></PopupFinance>
      </PopupEditing>
    </Grid>
  );
});

export default Finance;
