import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { userroles } from '../../../constants';
import { useTemplate } from '../../../../hooks';
import IconView from '../IconView';

function AppointReportIcon({ isRTL, theme, color, height }: any) {
  const { tempwords } = useTemplate();
  const navigate = useNavigate();

  return (
    <Box>
      {userroles?.appointments?.admin && (
        <IconView
          color={color ? color : theme.palette.primary.main}
          icon="depart"
          title={
            isRTL
              ? `تقرير ${tempwords?.appointments}`
              : `${tempwords?.appointments} Report`
          }
          height={height}
          onOpen={() => navigate('/calreports')}
        ></IconView>
      )}
    </Box>
  );
}

export default AppointReportIcon;
