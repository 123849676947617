/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { fade } from '@material-ui/core';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export const MonthsChartSimple = (props: any) => {
  const { data, color, dataKey, height, isRTL } = props;
  const ucolor = fade(color, 0.7);
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: isRTL ? 50 : 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis
          axisLine={false}
          tickLine={false}
          mirror={isRTL ? true : false}
          tickFormatter={(value) => value.toLocaleString()}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          formatter={(value) => [value.toLocaleString()]}
        />
        <Bar dataKey={dataKey} fill={ucolor} />;
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthsChartSimple;
