/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState } from 'react';
import { dublicateAlert, errorAlert, PopupTextField } from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Box, Button, Typography } from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import PopupPassword from './PopupPassword';
import { useForm } from 'react-hook-form';
import { ImageOnlineUpload, uploadPhotoOnline } from '../common/upload';
import Signature from '../Sections/components/Signature';

const PopupUserProfile = ({
  open,
  onClose,
  editAction,
  editPassword,
  theme,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [saving, setSaving] = useState(false);

  const [openPass, setOpenPass] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const {
    translate: { words, isRTL },
    store: { user, themeId },
  }: GContextTypes = useContext(GlobalContext);
  const [logoimage, setLogoimage] = useState(null);
  const [logourl, setLogourl] = useState(user?.avatar);
  const [signature, setSignature] = useState(user?.signatureImage);

  const onClosePass = () => setOpenPass(false);
  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const password = data?.password;
    const title = data?.title;

    let logo: any;

    if (logoimage) {
      logo = await uploadPhotoOnline(logoimage);
      logo = logo.replace('http://', 'https://');
    }

    const variables: any = {
      _id: user._id,
      name,
      phone,
      email,
      avatar: logo,
      signatureImage: signature,
      signatureName: name,
      password,
      title,
      themeId,
      userId: user._id,
    };
    try {
      await editAction({ variables });
      onFormClose();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const onFormClose = () => {
    reset();
    setSaving(false);
    setLogoimage(null);
    onClose();
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const title = isRTL ? 'تعديل بيانات مستخدم' : 'Edit User';
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onFormClose}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      mb={50}
      saving={saving}
      maxWidth="lg"
    >
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ImageOnlineUpload
                url={logourl}
                setUrl={setLogourl}
                image={logoimage}
                setImage={setLogoimage}
                width={150}
                height={150}
                size="400x400"
              ></ImageOnlineUpload>
            </Grid>
            <Grid item xs={12}>
              <PopupTextField
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                fullWidth
                row={user}
              />
            </Grid>
            <Grid item xs={12}>
              <PopupTextField
                name="phone"
                label={words.phoneNumber}
                register={register}
                errors={errors}
                fullWidth
                row={user}
              />
            </Grid>
            <Grid item xs={12}>
              <PopupTextField
                name="email"
                label={words.email}
                register={register}
                errors={errors}
                fullWidth
                row={user}
              />
            </Grid>
            <Grid item xs={12}>
              <Signature
                isRTL={isRTL}
                signature={signature}
                setSignature={setSignature}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 20,
            }}
          ></Box>
          <Box
            display="flex"
            style={{ alignItems: 'center', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenPass(true)}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Typography>
                {isRTL ? 'تغيير كلمة المرور' : 'Change Password'}
              </Typography>
            </Button>
          </Box>
        </Grid>
        <PopupPassword
          open={openPass}
          onClose={onClosePass}
          row={user}
          editPassword={editPassword}
          theme={theme}
        ></PopupPassword>
      </Grid>
    </PopupLayout>
  );
};

export default PopupUserProfile;
