import { Box, colors, Grid, Paper } from '@material-ui/core';
import { moneyFormat, rCellMain } from '../../common/helpers';
import SalesChart from '../charts/SalesChart';

export default function SalesDataBx({ row, isRTL, theme, height = 200 }) {
  const sales = (row?.totalinvoiced || 0) - (row?.totalDiscount || 0);

  const scolor = colors.blue[400];
  const acolor = colors.grey[700];

  const pcolor = colors.green[400];
  const dcolor = colors.orange[400];
  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <SalesChart
            scolor={scolor}
            pcolor={pcolor}
            acolor={acolor}
            dcolor={dcolor}
            row={row}
            theme={theme}
            height={height}
          ></SalesChart>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              {rCellMain(isRTL ? 'المبيعات' : 'Sales', scolor)}
            </Grid>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              {rCellMain(moneyFormat(sales), scolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'المقبوضات' : 'Receipts', pcolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(row?.totalpaid), pcolor)}
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: 20 }}>
                {rCellMain(isRTL ? 'الرصيد' : 'Balance', '#333')}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: 20 }}>
                {rCellMain(
                  moneyFormat(
                    row?.totalinvoiced - row?.totalpaid - row?.totalDiscount
                  ),
                  '#333'
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
