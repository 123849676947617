import { gql } from '@apollo/client';

export default gql`
  query getItemslist(
    $type: Int
    $categoryId: String
    $departmentId: String
    $employeeId: String
    $projectId: String
    $resourseId: String
    $customerId: String
    $supplierId: String
    $contractId: String
    $start: Date
    $end: Date
    $itemtype: Int
  ) {
    getItemslist(
      type: $type
      categoryId: $categoryId
      departmentId: $departmentId
      employeeId: $employeeId
      projectId: $projectId
      resourseId: $resourseId
      customerId: $customerId
      supplierId: $supplierId
      contractId: $contractId
      start: $start
      end: $end
      itemtype: $itemtype
    ) {
      ok
      error
      message
      count
      data {
        _id
        branch
        autoNo
        docNo
        indx

        startDate
        endDate
        duration

        itemId
        itemType
        itemBarcode
        itemName
        itemNameAr
        itemDesc
        itemDescPurchase
        itemSize
        itemWeight
        itemColor
        itemCost
        itemUnit
        itemPrice
        itemPhoto
        opId
        opType
        opTime
        opDocNo
        eventId
        refNo
        refType
        qty
        doneQty
        totalCost
        total
        amount
        categoryId
        categoryName
        categoryNameAr
        brandId
        brandName
        brandNameAr
        departmentId
        departmentName
        departmentNameAr
        departmentColor
        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone

        projectId
        projectName
        projectNameAr
        projectColor
        resourseId
        resourseName
        resourseNameAr
        resourseColor

        contractId
        contractName
        contractNameAr
        customerId
        customerName
        customerNameAr

        supplierId
        supplierName
        supplierNameAr

        createdAt
        updatedAt
      }
    }
  }
`;
