import { gql } from '@apollo/client';

export default gql`
  mutation updateEvent(
    $branch: String
    $id: Int
    $title: String
    $desc: String
    $docNo: String
    $rRule: String
    $prefix: String
    $startDate: Date
    $endDate: Date
    $showOnCal: Boolean
    $allDay: Boolean
    $reminder: Boolean
    $location: LocationInput
    $locationLink: String
    $address: String
    $houseno: String
    $priority: Int
    $amount: Float
    $status: Int
    $items: String
    $attachments: String
    $daction: Boolean
    $actions: String
    $bonos: Float
    $overtime: Float
    $absent: Boolean
    $cashin: Float
    $customer: CustomerInput
    $department: DepartmentInput
    $employee: EmployeeInput
    $project: ProjectInput
    $resourse: ResourseInput
    $contract: ContractInput
    $retype: RetypeInput
    $retype2: Retype2Input
  ) {
    updateEvent(
      branch: $branch
      id: $id
      title: $title
      desc: $desc
      docNo: $docNo
      rRule: $rRule
      prefix: $prefix
      startDate: $startDate
      endDate: $endDate
      showOnCal: $showOnCal
      allDay: $allDay
      reminder: $reminder
      location: $location
      locationLink: $locationLink
      address: $address
      houseno: $houseno
      priority: $priority
      amount: $amount
      status: $status
      items: $items
      attachments: $attachments
      daction: $daction
      actions: $actions
      bonos: $bonos
      overtime: $overtime
      absent: $absent
      cashin: $cashin
      customer: $customer
      department: $department
      employee: $employee
      project: $project
      resourse: $resourse
      contract: $contract
      retype: $retype
      retype2: $retype2
    ) {
      _id
      id
      branch
      title
      startDate
      endDate
      duration
      allDay
      rRule
      exDate
      allowDrag

      autoNo
      docNo
      priority
      amount
      status

      bonos
      overtime
      absent
      cashin
      teamMembers
      groupId
      locationLink
      address
      houseno

      customerId
      customerName
      customerNameAr
      customerPhone

      contractId
      contractName
      contractNameAr

      itemId
      itemName
      itemNameAr

      departmentId
      departmentName
      departmentNameAr
      departmentColor

      employeeId
      employeeName
      employeeNameAr
      employeeColor
      employeePhone
      workersIds

      retypeId
      retypeName
      retypeNameAr
      retypeColor

      retype2Id
      retype2Name
      retype2NameAr
      retype2Color

      attachments

      procs

      userId

      createdAt
      updatedAt
    }
  }
`;
