import { gql } from '@apollo/client';

export default gql`
  query getStepItems {
    getStepItems {
      ok
      error
      data {
        _id
        branch
        id
        autoNo
        docNo
        disabled
        additionId
        name
        nameAr
        color
        auto
        userId

        createdAt
        updatedAt
      }
    }
  }
`;
