import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useState } from 'react';
import { userroles } from '../../../constants';
import { createFinance, getLandingChartData } from '../../../../graphql';
import PopupReceipt from '../../../../pubups/PopupReceipt';
import IconView from '../IconView';

function ReceiptIcon({
  isRTL,
  theme,
  color,
  height,
  company,
  employeeId = null,
}) {
  const [openRece, setOpenRece] = useState(false);

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };

  const [addFinance] = useMutation(createFinance, refresQuery);

  return (
    <Box>
      {userroles?.receipts?.add && (
        <>
          <IconView
            color={color ? color : theme.palette.primary.main}
            icon="finance"
            title={isRTL ? 'انشاء سند قبض' : 'Add Receipt'}
            height={height}
            onOpen={() => setOpenRece(true)}
          ></IconView>

          <PopupReceipt
            open={openRece}
            onClose={() => setOpenRece(false)}
            isNew={true}
            addAction={addFinance}
            editAction={() => null}
            theme={theme}
            company={company}
          ></PopupReceipt>
        </>
      )}
    </Box>
  );
}

export default ReceiptIcon;
