/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, PopupEditing } from '../../Shared';
import { getProjects, getResourses } from '../../graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createdAtFormatter,
  daysdataFormatter,
  dueAmountFormatter,
  raseedFormatter,
  salesTaskFormatter,
  taskdataFormatter,
  tasksimpledataFormatter,
  taskTitleNameFormatter,
  userFormatter,
} from '../../Shared/colorFormat';
import { SearchTable } from '../../components';
import { getColumns } from '../../common/columns';
import { Typography } from '@material-ui/core';
import getTasks from '../../graphql/query/getTasks';
import PopupTaskFree from '../../pubups/PopupTaskFree';
import createTask from '../../graphql/mutation/createTask';
import updateTask from '../../graphql/mutation/updateTask';
import deleteTaskById from '../../graphql/mutation/deleteTaskById';
import { useCustomers, useTemplate, useUsers } from '../../hooks';
import { errorAlert, errorDeleteAlert } from '../../Shared/helpers';
import { TableComponent } from '../../Shared/TableComponent';
import PopupTaskView from '../../pubups/PopupTaskView';
import useDepartments from '../../hooks/useDepartments';
import useEmployees from '../../hooks/useEmployees';
import useResourses from '../../hooks/useResourses';
import useProjects from '../../hooks/useProjects';
import { userroles } from '../../common';
import closeTask from '../../graphql/mutation/closeTask';
import { getTaskStatus } from '../../common/helpers';
import { ContractContext } from '../../contexts/managment';
import PopupEmployeeView from '../../pubups/PopupEmployeeView';
import PopupResoursesView from '../../pubups/PopupResoursesView';
import PopupDepartmentView from '../../pubups/PopupDepartmentView';
import openTask from '../../graphql/mutation/openTask';
import PopupTaskFull from '../../pubups/PopupTaskFull';

export const getRowId = (row: { _id: any }) => row._id;

const Tasks = forwardRef((props: any, ref: any) => {
  const { isRTL, words, start, end, setAlrt, theme, company } = props;
  const col = getColumns({ isRTL, words });
  const { tempoptions, taskoptions } = useTemplate();

  const [columns] = useState(
    [
      col.autoNo,
      col.name,
      col.data,
      col.taskconnect,
      tempoptions?.contractsalescalc && col.sales,
      col.title,
      taskoptions?.printtitle && col.printtitle,
      taskoptions?.retype && tempoptions?.retype && col.retype,
      taskoptions?.retype2 && tempoptions?.retype2 && col.retype2,
      col.createdAt,
      col.type,
      col.status,
      col.start,
      col.end,
      col.customer,
      taskoptions?.resourse && tempoptions?.resourse && col.resourse,
      taskoptions?.department && tempoptions?.department && col.department,
      taskoptions?.employee && tempoptions?.employee && col.employee,
      taskoptions?.items && {
        name: 'amount',
        title: isRTL ? 'الاجمالي' : 'Total',
      },
      col.userId,
    ].filter((x) => x)
  );

  const [tableColumnExtensions]: any = useState([
    { columnName: col.autoNo.name, width: 100 },
    { columnName: col.name.name, width: 250 },
    { columnName: col.data.name, width: 250 },
    { columnName: col.taskconnect.name, width: 250 },
    { columnName: col.sales.name, width: 250 },
    { columnName: col.daysdata.name, width: 280 },
    { columnName: col.title.name, width: 200 },
    { columnName: col.printtitle.name, width: 200 },
    { columnName: col.retype.name, width: 150 },
    { columnName: col.retype2.name, width: 150 },
    { columnName: col.createdAt.name, width: 150 },
    { columnName: col.type.name, width: 100 },
    { columnName: col.status.name, width: 100 },
    { columnName: col.start.name, width: 120 },
    { columnName: col.end.name, width: 120 },
    { columnName: col.resourse.name, width: 200 },
    { columnName: col.customer.name, width: 200 },
    { columnName: col.department.name, width: 200 },
    { columnName: col.employee.name, width: 200 },
    { columnName: 'amount', width: 150 },
    { columnName: 'userId', width: 120, align: 'center' },
  ]);

  const [rows, setRows] = useState([]);
  const [item, setItem] = useState(null);
  const [name, setName] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const [openEmployeeItem, setOpenEmployeeItem] = useState(false);
  const [openResourseItem, setOpenResourseItem] = useState(false);
  const [openDepartmentItem, setOpenDepartmentItem] = useState(false);

  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { projects } = useProjects();
  const { users } = useUsers();

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };
  const onCloseEmployeeItem = () => {
    setOpenEmployeeItem(false);
    setItem(null);
    setName(null);
  };
  const onCloseResourseItem = () => {
    setOpenResourseItem(false);
    setItem(null);
    setName(null);
  };
  const onCloseDepartmentItem = () => {
    setOpenDepartmentItem(false);
    setItem(null);
    setName(null);
  };

  const setEmployeeItem = (data: any) => {
    const empl = employees.filter((em: any) => em._id === data.employeeId)?.[0];
    if (empl) {
      setItem(empl);
      setName('employee');
    }
  };

  const setResourseItem = (data: any) => {
    const empl = resourses.filter((em: any) => em._id === data.resourseId)?.[0];
    if (empl) {
      setItem(empl);
      setName('resourse');
    }
  };
  const setDepartmentItem = (data: any) => {
    const empl = departments.filter(
      (em: any) => em._id === data.departmentId
    )?.[0];
    if (empl) {
      setItem(empl);
      setName('department');
    }
  };

  const {
    state: { hiddenColumnNames, pageSize },
    dispatch,
  } = useContext(ContractContext);

  useEffect(() => {
    if (name === 'department') {
      if (departments && departments.length > 0) {
        const opened = departments.filter(
          (ts: any) => ts._id === item._id
        )?.[0];
        setItem(opened);
      }
    }
    if (name === 'employee') {
      if (employees && employees.length > 0) {
        const opened = employees.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
    if (name === 'resourse') {
      if (resourses && resourses.length > 0) {
        const opened = resourses.filter((ts: any) => ts._id === item._id)?.[0];
        setItem(opened);
      }
    }
  }, [departments, resourses, employees, name]);

  const setHiddenColumnNames = (hiddenColumns: any) => {
    dispatch({ type: 'setHiddenColumnNames', payload: hiddenColumns });
  };
  const setPageSize = (pageSize: any) => {
    dispatch({ type: 'setPageSize', payload: pageSize });
  };

  const [loadTasks, tasksData]: any = useLazyQuery(getTasks, {
    fetchPolicy: 'cache-and-network',
  });

  const refresQuery = {
    refetchQueries: [
      {
        query: getTasks,
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end ? end.setHours(23, 59, 59, 999) : undefined,
        },
      },
      { query: getTasks },
      { query: getProjects },
      { query: getResourses, variables: { isRTL, resType: 1 } },
    ],
  };

  useEffect(() => {
    const variables = {
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    if (start && end) {
      loadTasks({
        variables,
      });
    }
  }, [start, end]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.title.name, togglingEnabled: false },
  ]);

  const [addTask] = useMutation(createTask, refresQuery);
  const [editTask] = useMutation(updateTask, refresQuery);
  const [stopTask] = useMutation(closeTask, refresQuery);
  const [startTask] = useMutation(openTask, refresQuery);
  const [removeTaskById] = useMutation(deleteTaskById, refresQuery);
  const { customers, addCustomer, editCustomer } = useCustomers();

  useImperativeHandle(ref, () => ({
    refresh() {
      if (tasksData?.refetch) {
        tasksData.refetch();
      }
    },
  }));

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeTaskById({ variables: { _id } });
      if (res?.data?.deleteTaskById?.ok === false) {
        if (res?.data?.deleteTaskById?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  useEffect(() => {
    if (tasksData?.data?.getTasks?.data) {
      const { data } = tasksData.data.getTasks;
      const taskswithstatus = getTaskStatus(data, isRTL);
      setRows(taskswithstatus);
    }
  }, [tasksData]);
  const refresh = () => {
    tasksData?.refetch();
  };
  return (
    <>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          defaultCurrentPage={0}
          defaultPageSize={10}
        />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 80 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={[
            col.autoNo.name,
            col.name.name,
            col.data.name,
            col.taskconnect.name,
            col.sales.name,
            col.daysdata.name,
            col.title.name,
            col.printtitle.name,
            col.retype.name,
            col.retype2.name,
            col.createdAt.name,
            col.type.name,
            col.status.name,
            col.start.name,
            col.end.name,
            col.project.name,
            col.resourse.name,
            col.customer.name,
            col.department.name,
            col.employee.name,
            'amount',
            col.userId.name,
          ]}
        />

        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={hiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <DataTypeProvider
          for={['start', 'end', 'createdAt']}
          formatterComponent={createdAtFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['due']}
          formatterComponent={dueAmountFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['userId']}
          formatterComponent={(props: any) =>
            userFormatter({ ...props, users })
          }
        ></DataTypeProvider>
        {userroles?.operations?.add && (
          <DataTypeProvider
            for={[col.name.name]}
            formatterComponent={(props: any) =>
              taskTitleNameFormatter({
                ...props,
                setItem,
                setOpenItem,
                isRTL,
                taskoptions,
              })
            }
          ></DataTypeProvider>
        )}
        <DataTypeProvider
          for={[col.data.name]}
          formatterComponent={tasksimpledataFormatter}
        ></DataTypeProvider>
        {userroles?.operations?.admin && (
          <DataTypeProvider
            for={[col.taskconnect.name]}
            formatterComponent={(props: any) =>
              taskdataFormatter({
                ...props,
                setEmployeeItem,
                setOpenEmployeeItem,
                setResourseItem,
                setOpenResourseItem,
                setDepartmentItem,
                setOpenDepartmentItem,
                theme,
                isRTL,
              })
            }
          ></DataTypeProvider>
        )}
        {userroles?.operations?.admin && (
          <DataTypeProvider
            for={[col.daysdata.name]}
            formatterComponent={(props: any) =>
              daysdataFormatter({ ...props, theme, isRTL })
            }
          ></DataTypeProvider>
        )}
        {userroles?.operations?.admin && (
          <DataTypeProvider
            for={[col.sales.name]}
            formatterComponent={(props: any) =>
              salesTaskFormatter({ ...props, theme, isRTL })
            }
          ></DataTypeProvider>
        )}
        <DataTypeProvider
          for={['amount']}
          formatterComponent={raseedFormatter}
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.operations?.edit}
          showDeleteCommand={userroles?.operations?.delete}
          showAddCommand={userroles?.operations?.add}
          commandComponent={Command}
        ></TableEditColumn>

        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 10, 20, 50, 0] : [5, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        {taskoptions?.taskfree && (
          <PopupEditing addAction={addTask} editAction={editTask}>
            <PopupTaskFree
              resourses={resourses}
              employees={employees}
              departments={departments}
              projects={projects}
              customers={customers}
              addCustomer={addCustomer}
              editCustomer={editCustomer}
              company={company}
              theme={theme}
              refresh={refresh}
              startrange={start}
              endrange={end}
              memorized={rows.filter((r: any) => r.ismemorized)}
            ></PopupTaskFree>
          </PopupEditing>
        )}
        {!taskoptions?.taskfree && (
          <PopupEditing addAction={addTask} editAction={editTask}>
            <PopupTaskFull
              resourses={resourses}
              employees={employees}
              departments={departments}
              projects={projects}
              customers={customers}
              addCustomer={addCustomer}
              editCustomer={editCustomer}
              company={company}
              theme={theme}
              refresh={refresh}
              startrange={start}
              endrange={end}
              memorized={rows.filter((r: any) => r.ismemorized)}
            ></PopupTaskFull>
          </PopupEditing>
        )}
      </Grid>
      {item && (
        <PopupTaskView
          open={openItem}
          onClose={onCloseItem}
          item={item}
          tasks={rows}
          isNew={false}
          theme={theme}
          company={company}
          stopTask={stopTask}
          startTask={startTask}
          mstart={start}
          mend={end}
        ></PopupTaskView>
      )}
      <PopupEmployeeView
        open={openEmployeeItem}
        onClose={onCloseEmployeeItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupEmployeeView>
      <PopupResoursesView
        open={openResourseItem}
        onClose={onCloseResourseItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupResoursesView>
      <PopupDepartmentView
        open={openDepartmentItem}
        onClose={onCloseDepartmentItem}
        row={item}
        theme={theme}
        company={company}
      ></PopupDepartmentView>
    </>
  );
});

export default Tasks;
