import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { moneyFormat } from '../../../Shared';

export default function Product({ item, isRTL }: any) {
  const name = isRTL ? item?.nameAr : item?.name;
  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        borderRadius: 15,
        overflow: 'hidden',
        position: 'relative',
        zIndex: 102,
        cursor: 'pointer',
      }}
      onClick={() => console.log('"Test', item.name)}
    >
      <Box
        style={{
          display: 'flex',
          flex: 1,
          aspectRatio: '1.25',
        }}
      >
        {item?.photo && (
          <img
            style={{
              aspectRatio: '1.25',
              overflow: 'hidden',
              objectFit: 'cover',
            }}
            alt={item?.name}
            width={undefined}
            height={undefined}
            src={item?.photo}
          />
        )}
      </Box>
      <Divider></Divider>
      <Box style={{ height: 60 }}>
        <Typography style={{ fontWeight: 'bold', fontSize: 16, padding: 5 }}>
          {name?.substring(0, 40)}
        </Typography>
        <Box
          style={{
            position: 'absolute',
            bottom: 65,
            left: 5,
            minWidth: 60,
            height: 25,
            backgroundColor: '#065535',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Typography style={{ fontWeight: 'bold', color: '#fff' }}>
            {moneyFormat(item?.price)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
