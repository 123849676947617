/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { colors, fade } from '@material-ui/core';
import { getStoreItem } from '../store';

const store = getStoreItem('store');
const template = store?.template;
const options = template?.options;
const lang = store?.lang;
const words = template?.words?.[lang];
// const options = template?.options;
// const isRTL = lang === 'ar';

export const accountType = {
  DEBIT: 1,
  CREDIT: 2,
};

export const operationTypes = {
  letter: 1,
  request: 2,
  joborder: 3,
  order: 4,
  crmitem: 5,
  // المبيعات والمشتريات - زبائن وموردين
  salesInvoice: 10, /// فاتورة المبيع
  salesQoutation: 11,
  salesDelivery: 12, // مع فاتورة المبيعات عند تسليم المنتجات للزبون
  salesReturn: 13,
  customerReceipt: 14, // from customer // cash debit <- accounts_receivable credit
  customerDiscount: 15, // to customer //  accounts_receivable debit <- cash credit  خصمم خارج الفاتورة

  salesInvoiceCard: 20,
  salesInvoiceCredit: 21,
  customerReceiptCheque: 22,

  purchaseInvoice: 30, // فاتورة الشراء
  purchaseOrder: 31, // طلب شراء قبل فاتورة الشراء
  purchaseDelivery: 32, //  مع طلب الشراء عند استلام المنتجات من المورد
  purchaseReturn: 33,
  supplierPayemnt: 34, // to supplier // accounts_payable debit <- cash credit
  supplierDiscount: 35, // from supplier // cash debit <- accounts_payable credit خصمم خارج الفاتورة
  purchaseInvoiceCredit: 36,
  purchaseInvoiceStock: 37,
  purchaseInvoiceCreditStock: 38,
  supplierPayemntCheque: 39,

  cheque: 40,
  installment: 41,

  interDeliveryIn: 50, // حركة استلام بضاعة من احد مخازن الشركة
  interDeliveryOut: 51, // حركة تسليم بضاعة لاحد مخازن الشركة

  expenses: 60,
  expproducts: 61,
  exppettycash: 62,
  exppayable: 63,
  expensesCredit: 64,
  expensesSalary: 65,
  advancedRent: 66,
  productsCost: 67,

  // حركة مالية
  // transfare cash - bank - card - partners - branchs
  deposet: 70, // سحب من الصندوق وايداع بالبنك // bank debit <- cash or card credit
  ownerDraw: 71, // سحب من الصندوق للشريك  // partenr debit <- cash credit
  ownerDeposit: 72, // اضافة من الشريك في الصندوق  // cash debit <- partenr credit

  pettyCashPay: 73, // دفع عهدة
  pettyCashRec: 74, // قبض عهدة
  cashDraw: 75, // سحب
  cashDeposet: 76, // ايداع
  advanceReceipt: 77, // قبض سلفة من موظف
  advancePayemnt: 78, // دفع لموظف سلفة

  chequeDraw: 85,
  cashDeposetCard: 86,
  cashDeposetCheque: 87,

  event: 80,
  task: 81,
  project: 82,

  // special transfare operation
  kaid: 90,
};
export const opTypesNames = {
  1: {
    name: 'Letter',
    nameAr: 'خطاب',
    ref: 'letter',
    type: 1,
  },
  2: {
    name: 'Request',
    nameAr: 'مطلب',
    ref: 'request',
    type: 1,
  },
  3: {
    name: 'Job Order',
    nameAr: 'طلب عمل',
    ref: 'joborder',
    type: 1,
  },
  4: {
    name: 'Order',
    nameAr: 'طلبية',
    ref: 'order',
    type: 1,
  },
  5: {
    name: 'CRM Item',
    nameAr: 'علاقات العملاء',
    ref: 'crmitem',
    type: 1,
  },
  10: {
    name: 'Invoice',
    nameAr: 'فاتورة بيع',
    ref: 'salesInvoice',
    type: accountType.CREDIT,
  },
  11: {
    name: 'Qoutation',
    nameAr: 'عرض سعر',
    ref: 'salesQoutation',
    type: 1,
  },
  12: {
    name: 'Sales Delivery',
    nameAr: 'سند تسليم',
    ref: 'salesDelivery',
    type: 1,
  },
  13: {
    name: 'Sales Return',
    nameAr: 'مرتجع مبيعات',
    ref: 'salesReturn',
    type: 1,
  },
  14: {
    name: 'Receipt',
    nameAr: 'سند قبض',
    ref: 'customerReceipt',
    type: accountType.DEBIT,
  },
  15: {
    name: 'Sales Discount',
    nameAr: 'خصم مبيعات',
    ref: 'customerDiscount',
    type: accountType.DEBIT,
  },
  20: {
    name: 'Sales Invoices Card',
    nameAr: 'فواتير بيع بطاقة',
    ref: 'salesInvoiceCard',
    type: accountType.CREDIT,
  },
  21: {
    name: 'Sales Invoices Credit',
    nameAr: 'فواتير بيع آجلة',
    ref: 'salesInvoiceCredit',
    type: accountType.CREDIT,
  },
  22: {
    name: 'Receipts Cheque',
    nameAr: 'سندات قبض شيكات',
    ref: 'customerReceiptCheque',
    type: accountType.DEBIT,
  },
  30: {
    name: 'Purchase Invoice',
    nameAr: 'فاتورة شراء',
    ref: 'purchaseInvoice',
    type: accountType.DEBIT,
  },
  31: {
    name: 'Purchase Order',
    nameAr: 'طلب شراء',
    ref: 'purchaseOrder',
    type: 1,
  },
  32: {
    name: 'Purchase Delivery',
    nameAr: 'سند استلام',
    ref: 'purchaseDelivery',
    type: 1,
  },
  33: {
    name: 'Purchase Return',
    nameAr: 'مرتجع مشتريات',
    ref: 'purchaseReturn',
    type: 1,
  },
  34: {
    name: 'Payemnt',
    nameAr: 'سند دفع',
    ref: 'supplierPayemnt',
    type: accountType.CREDIT,
  },
  35: {
    name: 'Purchase Discount',
    nameAr: 'خصم مشتريات',
    ref: 'supplierDiscount',
    type: accountType.CREDIT,
  },
  36: {
    name: 'Purchase Invoices Credit',
    nameAr: 'فواتير شراء آجلة',
    ref: 'purchaseInvoiceCredit',
    type: accountType.DEBIT,
  },
  37: {
    name: 'Purchase Invoices Stock',
    nameAr: 'فواتير شراء مخزنية نقدا',
    ref: 'purchaseInvoiceStock',
    type: accountType.DEBIT,
  },
  38: {
    name: 'Purchase Invoices Credit Stock',
    nameAr: 'فواتير شراء مخزنية آجلة',
    ref: 'purchaseInvoiceCreditStock',
    type: accountType.DEBIT,
  },
  39: {
    name: 'Receipts Cheque',
    nameAr: 'سندات قبض شيكات',
    ref: 'supplierPayemntCheque',
    type: accountType.DEBIT,
  },
  40: {
    name: 'Cheque',
    nameAr: 'شيك',
    ref: 'cheque',
    type: 1,
  },
  41: {
    name: 'Installment',
    nameAr: 'قسط',
    ref: 'installment',
    type: 1,
  },
  50: {
    name: 'Stock In',
    nameAr: 'وارد مخزن',
    ref: 'interDeliveryIn',
    type: 1,
  },
  51: {
    name: 'Stock Out',
    nameAr: 'صادر مخزن',
    ref: 'interDeliveryOut',
    type: 1,
  },
  60: {
    name: 'Expenses',
    nameAr: 'مصاريف',
    ref: 'expenses',
    type: accountType.DEBIT,
  },
  61: {
    name: 'Products Expenses',
    nameAr: 'استهلاك منتجات',
    ref: 'expproducts',
    type: accountType.DEBIT,
  },
  62: {
    name: 'Petty Cash Expenses',
    nameAr: 'مصروف عهدة',
    ref: 'exppettycash',
    type: accountType.DEBIT,
  },
  63: {
    name: 'Payable Expenses',
    nameAr: 'مصروف موردين',
    ref: 'exppayable',
    type: accountType.DEBIT,
  },

  64: {
    name: 'Expenses Credit',
    nameAr: 'مصروفات آجلة',
    ref: 'expensesCredit',
    type: accountType.DEBIT,
  },
  65: {
    name: 'Accrued Salary',
    nameAr: 'رواتب مستحقة',
    ref: 'expensesSalary',
    type: accountType.DEBIT,
  },
  66: {
    name: 'Advanced Rent',
    nameAr: 'ايجارات مقدمة',
    ref: 'advancedRent',
    type: accountType.DEBIT,
  },
  67: {
    name: 'Products Cost',
    nameAr: 'تكلفة المنتجات المباعة',
    ref: 'productsCost',
    type: accountType.DEBIT,
  },

  70: {
    name: 'Deposet',
    nameAr: 'ايداع بنكي',
    ref: 'deposet',
    type: 1,
  },
  71: {
    name: 'Draw - Partner',
    nameAr: 'سحب - شريك',
    ref: 'ownerDraw',
    type: 1,
  },
  72: {
    name: 'Deposit - Partner',
    nameAr: 'ايداع - شريك',
    ref: 'ownerDeposit',
    type: 1,
  },
  73: {
    name: 'Petty Cash Payment',
    nameAr: 'دفع عهدة',
    ref: 'pettyCashPay',
    type: 1,
  },
  74: {
    name: 'Petty Cash Receipt',
    nameAr: 'قبض عهدة',
    ref: 'pettyCashRec',
    type: 1,
  },
  75: {
    name: 'Cash Draw',
    nameAr: 'سحب من البنك',
    ref: 'cashDraw',
    type: 1,
  },
  76: {
    name: 'Cash Deposet',
    nameAr: 'ايداع في البنك',
    ref: 'cashDeposet',
    type: 1,
  },
  77: {
    name: 'Advance Receipt',
    nameAr: 'سند قبض سلفة',
    ref: 'advanceReceipt',
    type: 1,
  },
  78: {
    name: 'Advance Payemnt',
    nameAr: 'سند دفع سلفة',
    ref: 'advancePayemnt',
    type: 1,
  },

  85: {
    name: 'Cheque Bank Draw',
    nameAr: 'سحب شيك من البنك',
    ref: 'chequeDraw',
    type: 1,
  },
  86: {
    name: 'Card Bank Deposet',
    nameAr: 'ايداع بطاقة في البنك',
    ref: 'cashDeposetCard',
    type: 1,
  },
  87: {
    name: 'Cheque Bank Deposet',
    nameAr: 'ايداع شيك في البنك',
    ref: 'cashDeposetCheque',
    type: 1,
  },

  80: {
    name: 'Appointment',
    nameAr: 'موعد',
    ref: 'event',
    type: accountType.DEBIT,
  },
  90: {
    name: 'Jornal Vaucher',
    nameAr: 'قيد يومية',
    ref: 'kaid',
    type: 1,
  },
  94: {
    name: 'Opening Balancee',
    nameAr: 'رصيد افتتاحي',
    ref: 'balance',
    type: 1,
  },
  95: {
    name: 'Reminder',
    nameAr: 'تذكير',
    ref: 'reminder',
    type: 1,
  },
};
export const operationNames = {
  letter: 'Letter',
  request: 'Request',
  joborder: 'Job Order',
  order: 'Order',
  crmitem: 'CRM Item',
  salesInvoice: 'Sales Invoice',
  salesQoutation: 'Sales Qoutation',
  salesDelivery: 'Sales Delivery',
  salesReturn: 'Sales Return',
  customerReceipt: 'Customer Receipt',
  customerDiscount: 'Customer Discount',
  purchaseInvoice: 'Purchase Invoice',
  purchaseOrder: 'Purchase Order',
  purchaseDelivery: 'Purchase Delivery',
  purchaseReturn: 'Purchase Return',
  supplierPayemnt: 'Supplier Payemnt',
  supplierDiscount: 'Supplier Discount',
  cheque: 'Cheque',
  installment: 'Installment',
  interDeliveryIn: 'Internal DeliveryIn',
  interDeliveryOut: 'Internal DeliveryOut',
  expenses: 'Expenses',
  expproducts: 'expproducts',
  exppettycash: 'exppettycash',
  exppayable: 'exppayable',
  deposet: 'Deposet',
  ownerDraw: 'Owner Draw',
  ownerDeposit: 'Owner Deposit',
  pettyCashPay: 'pettyCashPay',
  pettyCashRec: 'pettyCashRec',
  cashDraw: 'cashDraw',
  cashDeposet: 'cashDeposet',
  advanceReceipt: 'advanceReceipt',
  advancePayemnt: 'advancePayemnt',
  event: 'Event',
  task: 'Task',
  project: 'Project',

  // special transfare operation
  kaid: 'Kaid',
  salesInvoiceCard: 'salesInvoiceCard',
  salesInvoiceCredit: 'salesInvoiceCredit',
  customerReceiptCheque: 'customerReceiptCheque',
  purchaseInvoiceCredit: 'purchaseInvoiceCredit',
  purchaseInvoiceStock: 'purchaseInvoiceStock',
  purchaseInvoiceCreditStock: 'purchaseInvoiceCreditStock',
  supplierPayemntCheque: 'supplierPayemntCheque',
  expensesCredit: 'expensesCredit',
  expensesSalary: 'expensesSalary',
  advancedRent: 'advancedRent',
  productsCost: 'productsCost',
  chequeDraw: 'chequeDraw',
  cashDeposetCard: 'cashDeposetCard',
  cashDeposetCheque: 'cashDeposetCheque',
};
export const opTypesList = [
  {
    opType: 1,
    name: 'Letter',
    nameAr: 'خطاب',
    ref: 'letter',
    perfix: 'LTER',
  },
  {
    opType: 2,
    name: 'Request',
    nameAr: 'مطلب',
    ref: 'request',
    perfix: 'REQT',
  },
  {
    opType: 3,
    name: 'Job Order',
    nameAr: 'طلب عمل',
    ref: 'joborder',
    perfix: 'JORD',
  },
  {
    opType: 4,
    name: 'Order',
    nameAr: 'طلبية',
    ref: 'order',
    perfix: 'ORDR',
  },
  {
    opType: 5,
    name: 'CRM Item',
    nameAr: 'علاقات العملاء',
    ref: 'crmitem',
    perfix: 'CRMI',
  },
  {
    opType: 10,
    name: 'Invlice',
    nameAr: 'فاتورة',
    ref: 'salesInvoice',
    perfix: 'SINV',
  },
  {
    opType: 11,
    name: 'Qoutation',
    nameAr: 'عرض سعر',
    ref: 'salesQoutation',
    perfix: 'QOUT',
  },
  {
    opType: 12,
    name: 'Sales Delivery',
    nameAr: 'سند تسليم',
    ref: 'salesDelivery',
    perfix: 'SDEL',
  },
  {
    opType: 13,
    name: 'Sales Return',
    nameAr: 'مرتجع مبيعات',
    ref: 'salesReturn',
    perfix: 'SRET',
  },
  {
    opType: 14,
    name: 'Receipt',
    nameAr: 'سند قبض',
    ref: 'customerReceipt',
    perfix: 'CREC',
  },
  {
    opType: 15,
    name: 'Sales Discount',
    nameAr: 'خصم مبيعات',
    ref: 'customerDiscount',
    perfix: 'CDES',
  },
  {
    opType: 20,
    name: 'Sales Invoices Card',
    nameAr: 'فواتير بيع بطاقة',
    ref: 'salesInvoiceCard',
    perfix: 'SINR',
  },
  {
    opType: 21,
    name: 'Sales Invoices Credit',
    nameAr: 'فواتير بيع آجلة',
    ref: 'salesInvoiceCredit',
    perfix: 'SIND',
  },
  {
    opType: 22,
    name: 'Receipts Cheque',
    nameAr: 'سندات قبض شيكات',
    ref: 'customerReceiptCheque',
    perfix: 'CREQ',
  },

  {
    opType: 30,
    name: 'Purchase Invoice',
    nameAr: 'فاتورة شراء',
    ref: 'purchaseInvoice',
    perfix: 'PINV',
  },
  {
    opType: 31,
    name: 'Purchase Order',
    nameAr: 'طلب شراء',
    ref: 'purchaseOrder',
    perfix: 'PORD',
  },
  {
    opType: 32,
    name: 'Purchase Delivery',
    nameAr: 'سند استلام',
    ref: 'purchaseDelivery',
    perfix: 'PDEL',
  },
  {
    opType: 33,
    name: 'Purchase Return',
    nameAr: 'مرتجع مشتريات',
    ref: 'purchaseReturn',
    perfix: 'PRET',
  },
  {
    opType: 34,
    name: 'Payemnt',
    nameAr: 'سند دفع',
    ref: 'supplierPayemnt',
    perfix: 'SPAY',
  },
  {
    opType: 35,
    name: 'Purchase Discount',
    nameAr: 'خصم مشتريات',
    ref: 'supplierDiscount',
    perfix: 'SDES',
  },
  {
    opType: 36,
    name: 'Purchase Invoices Credit',
    nameAr: 'فواتير شراء آجلة',
    ref: 'purchaseInvoiceCredit',
    perfix: 'CRED',
  },
  {
    opType: 37,
    name: 'Purchase Invoices Stock',
    nameAr: 'فواتير شراء مخزنية نقدا',
    ref: 'purchaseInvoiceStock',
    perfix: 'PINS',
  },
  {
    opType: 38,
    name: 'Purchase Invoices Credit Stock',
    nameAr: 'فواتير شراء مخزنية آجلة',
    ref: 'purchaseInvoiceCreditStock',
    perfix: 'PICS',
  },
  {
    opType: 39,
    name: 'Receipts Cheque',
    nameAr: 'سندات قبض شيكات',
    ref: 'supplierPayemntCheque',
    perfix: 'SPAQ',
  },
  {
    opType: 40,
    name: 'Cheque',
    nameAr: 'شيك',
    ref: 'cheque',
    perfix: 'CHEQ',
  },
  {
    opType: 41,
    name: 'Installment',
    nameAr: 'قسط',
    ref: 'installment',
    perfix: 'INST',
  },
  {
    opType: 50,
    name: 'Stock In',
    nameAr: 'وارد مخزن',
    ref: 'interDeliveryIn',
    perfix: 'DEIN',
  },
  {
    opType: 51,
    name: 'Stock Out',
    nameAr: 'صادر مخزن',
    ref: 'interDeliveryOut',
    perfix: 'DOUT',
  },
  {
    opType: 60,
    name: 'Expenses',
    nameAr: 'مصاريف',
    ref: 'expenses',
    perfix: 'EXPN',
  },
  {
    opType: 61,
    name: 'Products Expenses',
    nameAr: 'استهلاك منتجات',
    ref: 'expproducts',
    perfix: 'EXPR',
  },
  {
    opType: 62,
    name: 'Petty Cash Expenses',
    nameAr: 'مصروف عهدة',
    ref: 'exppettycash',
    perfix: 'EXPC',
  },
  {
    opType: 63,
    name: 'Payable Expenses',
    nameAr: 'مصروف موردين',
    ref: 'exppayable',
    perfix: 'EXPB',
  },
  {
    opType: 64,
    name: 'Expenses Credit',
    nameAr: 'مصروفات آجلة',
    ref: 'expensesCredit',
    perfix: 'EXCA',
  },
  {
    opType: 65,
    name: 'Accrued Salary',
    nameAr: 'رواتب مستحقة',
    ref: 'expensesSalary',
    perfix: 'EXSA',
  },
  {
    opType: 66,
    name: 'Advanced Rent',
    nameAr: 'ايجارات مقدمة',
    ref: 'advancedRent',
    perfix: 'ARNT',
  },
  {
    opType: 67,
    name: 'Products Cost',
    nameAr: 'تكلفة المنتجات المباعة',
    ref: 'productsCost',
    perfix: 'PRCO',
  },
  {
    opType: 70,
    name: 'Deposet',
    nameAr: 'ايداع',
    ref: 'deposet',
    perfix: 'DEPO',
  },
  {
    opType: 71,
    name: 'Draw - Partner',
    nameAr: 'سحب - شريك',
    ref: 'ownerDraw',
    perfix: 'MNOT',
  },
  {
    opType: 72,
    name: 'Deposit - Partner',
    nameAr: 'ايداع - شريك',
    ref: 'ownerDeposit',
    perfix: 'MNIN',
  },
  {
    opType: 73,
    name: 'Petty Cash Payment',
    nameAr: 'دفع عهدة',
    ref: 'pettyCashPay',
    perfix: 'PTYP',
  },
  {
    opType: 74,
    name: 'Petty Cash Receipt',
    nameAr: 'قبض عهدة',
    ref: 'pettyCashRec',
    perfix: 'PTYR',
  },
  {
    opType: 75,
    name: 'Cash Draw',
    nameAr: 'سحب من البنك',
    ref: 'cashDraw',
    perfix: 'CDRW',
  },
  {
    opType: 76,
    name: 'Cash Deposet',
    nameAr: 'ايداع في البنك',
    ref: 'cashDeposet',
    perfix: 'CDEP',
  },
  {
    opType: 77,
    name: 'Advance Receipt',
    nameAr: 'سند قبض سلفة',
    ref: 'advanceReceipt',
    perfix: 'AREC',
  },
  {
    opType: 78,
    name: 'Advance Payemnt',
    nameAr: 'سند دفع سلفة',
    ref: 'advancePayemnt',
    perfix: 'APAY',
  },
  {
    opType: 85,
    name: 'Cheque Bank Draw',
    nameAr: 'سحب شيك من البنك',
    ref: 'chequeDraw',
    perfix: 'CQOT',
  },
  {
    opType: 86,
    name: 'Card Bank Deposet',
    nameAr: 'ايداع بطاقة في البنك',
    ref: 'cashDeposetCard',
    perfix: 'CRIN',
  },
  {
    opType: 87,
    name: 'Cheque Bank Deposet',
    nameAr: 'ايداع شيك في البنك',
    ref: 'cashDeposetCheque',
    perfix: 'CQIN',
  },
  {
    opType: 80,
    name: 'Appointment',
    nameAr: 'موعد',
    ref: 'event',
    perfix: 'EVNT',
  },
  {
    opType: 90,
    name: 'Jornal Vaucher',
    nameAr: 'قيد يومية',
    ref: 'kaid',
    perfix: 'KAID',
  },
  {
    opType: 94,
    name: 'Opening Balancee',
    nameAr: 'رصيد افتتاحي',
    ref: 'balance',
    perfix: 'BLNC',
  },
  {
    opType: 95,
    name: 'Reminder',
    nameAr: 'تذكير',
    ref: 'reminder',
    perfix: 'RMIN',
  },
];

export const paymentTypes = {
  cash: 'Cash',
  card: 'Card',
  check: 'Check',
  bank: 'Bank',
};

export const catTypes = [
  { id: 1, name: 'Product', nameAr: 'منتج' },
  { id: 2, name: 'Service', nameAr: 'خدمة' },
  { id: 3, name: 'Expenses', nameAr: 'مصاريف' },
];
export const msgTypes = [
  { id: 1, name: 'SMS', nameAr: 'رسالة نصية' },
  { id: 2, name: 'Email', nameAr: 'ايميل' },
  { id: 3, name: 'Notification', nameAr: 'تنبيه' },
];

export const itemTypes = [
  {
    id: 1,
    name: 'Product',
    nameAr: 'منتج',
    value: 1,
    hide: !options?.products,
  },
  { id: 2, name: 'Service', nameAr: 'خدمة', value: 2, hide: false },
  // { id: 3, name: 'nostockproduct', nameAr: 'مصاريف', value: 3, hide: true },
  { id: 4, name: 'Rent', nameAr: 'تأجير', value: 4, hide: !options?.rentitem },
  {
    id: 5,
    name: 'Rollective',
    nameAr: 'تجميعي',
    value: 5,
    hide: !options?.collectiveitem,
  },
  {
    id: 10,
    name: 'Expenses',
    nameAr: 'مصاريف',
    value: 10,
    hide: !options?.expenses,
  },
];

export const departmentTypes = [
  { id: 1, name: 'General', nameAr: 'عام' },
  { id: 2, name: 'Employees', nameAr: 'موظفين' },
  { id: 3, name: 'Resourses', nameAr: 'موارد' },
];
export const retypeTypes = [
  { id: 1, name: words?.employees, nameAr: words?.employees },
  { id: 2, name: words?.resourses, nameAr: words?.resourses },
  { id: 3, name: words?.departments, nameAr: words?.departments },
  { id: 4, name: words?.evretype, nameAr: words?.evretype },
  { id: 5, name: words?.evretype2, nameAr: words?.evretype2 },
];
export const recTypes = [
  { id: 1, name: 'Customer', nameAr: 'عميل', value: 1 },
  { id: 2, name: 'Employee', nameAr: 'موظف', value: 2 },
];
export const payTypes = [
  { id: 1, name: 'Supplier', nameAr: 'مورد', value: 1 },
  { id: 2, name: 'Employee', nameAr: 'موظف', value: 2 },
];

export const statusTypes = {
  Scheduled: 1,
  Confirmed: 2,
  OnHold: 3,
  Canceled: 4,
  // start: 5,
  progress: 6,
  // end: 7,
  Completed: 10,
};

export const eventStatusEn = {
  1: 'Scheduled',
  2: 'Confirmed',
  3: 'On-Hold',
  4: 'Canceled',
  // 5: 'Starts',
  6: 'In Progress',
  // 7: 'Ends',
  10: 'Completed',
};
export const eventStatusAr = {
  1: 'موعد غير مؤكد',
  2: 'موعد مؤكد',
  3: 'موعد معلق',
  4: 'موعد ملغى',
  // 5: 'بدأ',
  6: 'قيد الانجاز',
  // 7: 'انتهى',
  10: 'موعد منجز',
};
export const eventStatus = [
  // { id: 1, name: 'Scheduled', nameAr: 'موعد غير مؤكد', color: '#445E93' },
  { id: 2, name: 'Confirmed', nameAr: 'مؤكد', color: '#445E93' },
  // { id: 3, name: 'On-Hold', nameAr: 'موعد معلق', color: '#FED766' },
  { id: 4, name: 'Canceled', nameAr: 'ملغى', color: '#FE4A49' },
  // { id: 5, name: 'Starts', nameAr: 'بدأ', color: '#a084bb' },
  { id: 6, name: 'In Progress', nameAr: 'قيد الانجاز', color: '#2AB7CA' },
  // { id: 7, name: 'Ends', nameAr: 'انتهى', color: '#a084bb' },
  { id: 10, name: 'Completed', nameAr: 'منجز', color: '#61E294' },
];

export const eventStatusShort = [
  { id: 1, name: 'Scheduled', nameAr: 'غير مؤكد', color: '#aa98bb' },
  { id: 2, name: 'Confirmed', nameAr: 'مؤكد', color: '#a084bb' },
  { id: 3, name: 'On-Hold', nameAr: 'معلق', color: '#b6abc2' },
  { id: 4, name: 'Canceled', nameAr: 'ملغى', color: '#b6abc2' },
  // { id: 5, name: 'Starts', nameAr: 'بدأ', color: '#f1Ea94' },
  { id: 6, name: 'In Progress', nameAr: 'قيد الانجاز', color: '#f1Ea94' },
  // { id: 7, name: 'Ends', nameAr: 'انتهى', color: '#f1Ea94' },
  { id: 10, name: 'Completed', nameAr: 'منجز', color: '#7bcf9b' },
];

export const taskStatus = [
  { id: 1, name: 'Not Started', nameAr: 'لم يبدأ', color: '#445E93' },
  { id: 2, name: 'In Progress', nameAr: 'قيد الانجاز', color: '#2AB7CA' },
  { id: 3, name: 'On-Hold', nameAr: 'معلق', color: '#FED766' },
  { id: 4, name: 'Canceled', nameAr: 'ملغى', color: '#FE4A49' },
  { id: 10, name: 'Completed', nameAr: 'منجز', color: '#61E294' },
];

export const procStatus = [
  { id: 1, name: 'Created', nameAr: 'اُنشئ', color: '#2DCCFF', auto: true },
  { id: 2, name: 'Sent', nameAr: 'اُرسل', color: '#2DCCFF', auto: true },
  { id: 3, name: 'Opened', nameAr: 'فُتح', color: '#2DCCFF', auto: true },
  { id: 4, name: 'Scheduled', nameAr: 'غير مؤكد', color: '#808080' },
  { id: 5, name: 'Confirmed', nameAr: 'مؤكد', color: '#a084bb' },
  { id: 6, name: 'In Progress', nameAr: 'قيد الانجاز', color: '#7fe5f0' },
  { id: 6, name: 'Pending', nameAr: 'قيد الانتظار', color: '#f1Ea94' },
  { id: 8, name: 'On-Hold', nameAr: 'مُعلق', color: '#FED766' },
  { id: 9, name: 'Canceled', nameAr: 'مَلغى', color: '#ff6666' },
  { id: 10, name: 'Completed', nameAr: 'مُنجز', color: '#61E294' },
];

export const getThemeStatus = (theme: any) => {
  return [
    {
      id: 10,
      name: 'Completed',
      nameAr: 'منجز',
      color: fade(theme.palette.primary.light, 0.9),
    },
    {
      id: 2,
      name: 'Confirmed',
      nameAr: 'مؤكد',
      color: fade(theme.palette.secondary.light, 0.8),
    },
    {
      id: 1,
      name: 'Scheduled',
      nameAr: 'غير مؤكد',
      color: fade(theme.palette.secondary.light, 0.6),
    },
    {
      id: 3,
      name: 'On-Hold',
      nameAr: 'معلق',
      color: fade(theme.palette.secondary.light, 0.4),
    },
    {
      id: 4,
      name: 'Canceled',
      nameAr: 'ملغى',
      color: fade(theme.palette.secondary.light, 0.2),
    },
    // {
    //   id: 5,
    //   name: 'Starts',
    //   nameAr: 'بدأ',
    //   color: fade(theme.palette.secondary.light, 0.5),
    // },
    {
      id: 6,
      name: 'In Progress',
      nameAr: 'قيد الانجاز',
      color: fade(theme.palette.secondary.light, 0.5),
    },
    // {
    //   id: 7,
    //   name: 'Ends',
    //   nameAr: 'انتهى',
    //   color: fade(theme.palette.secondary.light, 0.5),
    // },
  ];
};
export const departempl = [
  { id: 'employeeId', name: 'Employee', nameAr: 'الموظفين' },
  { id: 'departmentId', name: 'Department', nameAr: 'الأقسام' },
];

export const periods = [
  { id: 1, period: 'cm', name: 'Current Month', nameAr: 'الشهر الحالي' },
  { id: 2, period: 'pm', name: 'Previous Month', nameAr: 'الشهر السابق' },
  { id: 3, period: 'cy', name: 'Current Year', nameAr: 'السنة الحالية' },
  { id: 4, period: 'py', name: 'Previous Year', nameAr: 'السنة السابقة' },
];
export const carstatuss = [
  { id: 1, name: 'Available', nameAr: 'متوفرة', color: colors.green[400] },
  { id: 2, name: 'In Garage', nameAr: 'قيد الصيانة', color: colors.grey[400] },
  { id: 3, name: 'Broken', nameAr: 'معطلة', color: colors.red[400] },
  { id: 10, name: 'Busy', nameAr: 'مشغولة', color: colors.blue[400] },
];

export const eventColors = {
  1: '#7b7b7d',
  2: '#7b7b7d',
  3: '#ffa500',
  4: '#ff4040',
  5: '#f1Ea94',
  6: '#3843d2',
  7: '#f1Ea94',
  10: '#008000',
};

export const weekdays = {
  sat: false,
  sun: false,
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
};
export const weekdaysNNo = {
  6: 'sat',
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
};

export const weekdaysObj = {
  sat: {
    id: 6,
    name: 'Saturday',
    nameAr: 'السبت',
    short: 'sat',
  },
  sun: {
    id: 0,
    name: 'Sunday',
    nameAr: 'الاحد',
    short: 'sun',
  },
  mon: {
    id: 1,
    name: 'Monday',
    nameAr: 'الأثنين',
    short: 'mon',
  },
  tue: {
    id: 2,
    name: 'Tuesday',
    nameAr: 'الثلاثاء',
    short: 'tue',
  },
  wed: {
    id: 3,
    name: 'Wednesday',
    nameAr: 'الأربعاء',
    short: 'wed',
  },
  thu: {
    id: 4,
    name: 'Thursday',
    nameAr: 'الخميس',
    short: 'thu',
  },
  fri: {
    id: 5,
    name: 'Friday',
    nameAr: 'الجمعة',
    short: 'fri',
  },
};

export const dayslist = [
  {
    id: 6,
    name: 'Saturday',
    nameAr: 'السبت',
    short: 'sat',
    value: 6,
  },
  {
    id: 0,
    name: 'Sunday',
    nameAr: 'الاحد',
    short: 'sun',
    value: 0,
  },
  {
    id: 1,
    name: 'Monday',
    nameAr: 'الأثنين',
    short: 'mon',
    value: 1,
  },
  {
    id: 2,
    name: 'Tuesday',
    nameAr: 'الثلاثاء',
    short: 'tue',
    value: 2,
  },
  {
    id: 3,
    name: 'Wednesday',
    nameAr: 'الأربعاء',
    short: 'wed',
    value: 3,
  },
  {
    id: 4,
    name: 'Thursday',
    nameAr: 'الخميس',
    short: 'thu',
    value: 4,
  },
  {
    id: 5,
    name: 'Friday',
    nameAr: 'الجمعة',
    short: 'fri',
    value: 5,
  },
];

export const printTamplateTypes = [
  'invoice',
  'purchase',
  'receipt',
  'payment',
  'contract',
  'customerreport',
  'supplierreport',
  'profitreport',
  'expensesreport',
  'financereport',
  'stockreport',
];

export const initInvoiceState = {
  invoiced: false,
  invoiceId: null,
  invoiceNo: null,
  eventIds: [],
  invoiceLink: null,
  sentSms: false,
  sentEmail: false,
  paid: 0,
};
