import { gql } from '@apollo/client';

export default gql`
  mutation deleteTempEvent {
    deleteTempEvent {
      ok
      message
      error
    }
  }
`;
