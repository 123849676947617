import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

function CheckBoxLocal({ name, value, setValue, disabled }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          style={{ padding: 7 }}
          checked={value}
          onChange={() => {
            setValue(!value);
          }}
          color="primary"
          disabled={disabled}
        />
      }
      label={
        <Typography
          style={{
            fontWeight: 'bold',
            minWidth: 100,
          }}
          color="primary"
        >
          {name}
        </Typography>
      }
      style={{ fontSize: 14 }}
    />
  );
}

export default CheckBoxLocal;
