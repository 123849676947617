import { gql } from '@apollo/client';

export default gql`
  query getProcItems {
    getProcItems {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        additionId
        name
        nameAr
        userId

        pcategoryId
        pcategoryName
        pcategoryNameAr

        createdAt
        updatedAt
      }
    }
  }
`;
