/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
} from '@material-ui/core';
import { itemClasses } from '../../themes/classes';
import { fieldsNames } from '../constants/reports';
import PopupLayout from './PopupLayout';
import TextFieldLocal from './TextFieldLocal';

export const indexTheList = (list: any) => {
  return list.map((item: any, index: any) => {
    return {
      ...item,
      index,
    };
  });
};

const PopupField = ({
  isNew,
  open,
  onClose,
  row,
  addAction,
  editAction,
  isRTL,
  words,
  theme,
}: any) => {
  const classes = itemClasses();
  const [field, setField] = useState(null);
  const [name, setName] = useState(null);
  const [nameAr, setNameAr] = useState(null);
  const [order, setOrder] = useState(null);
  const [show, setShow] = useState(null);

  useEffect(() => {
    if (row && row.field) {
      setField(row.field);
      setName(row.name);
      setNameAr(row.nameAr);
      setOrder(row.order);
      setShow(row.show);
    }
  }, [open]);

  const onSubmit = () => {
    const item = { field, name, nameAr, order, show };
    isNew ? addAction(item) : editAction(item);
    onFormClose();
  };

  const onFormClose = () => {
    onClose();
    setField(null);
    setName(null);
    setNameAr(null);
    setOrder(null);
    setShow(null);
  };

  return (
    <PopupLayout
      open={open}
      onClose={onFormClose}
      onSubmit={onSubmit}
      classes={classes}
      isRTL={isRTL}
      theme={theme}
      mt={10}
      mb={20}
      maxWidth="md"
      alrt={{}}
    >
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl style={{ paddingRight: 10 }}>
            <Select
              id="simple-menu"
              value={field}
              onChange={(e: any) => setField(e.target.value)}
              variant="outlined"
              style={{
                height: 38,
                width: 250,
                alignSelf: 'flex-end',
                fontSize: 12,
                fontWeight: 'bold',
                color: '#555',
              }}
            >
              {fieldsNames.map((item: any) => {
                return (
                  <MenuItem
                    key={item}
                    style={{
                      justifyContent: isRTL ? 'flex-end' : 'flex-start',
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextFieldLocal
            required
            name="nameAr"
            value={nameAr}
            onChange={(e: any) => setNameAr(e.target.value)}
            label={isRTL ? 'الاسم عربي' : 'Name English'}
            width={250}
            mb={0}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldLocal
            required
            name="name"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            label={isRTL ? 'الاسم انكليزي' : 'Name Arabic'}
            width={250}
            mb={0}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldLocal
            name="order"
            label={isRTL ? 'ترتيب' : 'Order'}
            value={order}
            onChange={(e: any) => setOrder(Number(e.target.value))}
            type="number"
            width={250}
            mb={0}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={show}
                onChange={() => setShow(!show)}
                name="bold"
                color="primary"
              />
            }
            label={isRTL ? 'اظهار' : 'Show'}
          />
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupField;
