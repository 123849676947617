/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext, useEffect, useState } from 'react';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import { CalenderLocal, TextFieldLocal } from '../components';
import React from 'react';
import { dublicateAlert, errorAlert, messageAlert } from '../Shared';
import { useDepartments, useEmployees, useTemplate } from '../hooks';
import useResourses from '../hooks/useResourses';
import useTasks from '../hooks/useTasks';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';

const PopupRAction = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [runtime, setRuntime]: any = useState(new Date());

  const [departvalue, setDepartvalue] = useState<any>(null);
  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [resovalue, setResovalue] = useState<any>(null);
  const [taskvalue, setTaskvalue] = useState<any>(null);
  const [rtitle, setRtitle]: any = useState(null);

  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { resourses } = useResourses();
  const { tempwords, tempoptions } = useTemplate();
  const { tasks } = useTasks();

  const {
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);
  useEffect(() => {
    if (row && row._id) {
      const depId = row.departmentId;
      const empId = row.employeeId;
      const resId = row.resourseId;

      setRtitle(row?.title);
      setRuntime(new Date(row?.sendtime));

      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const res = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(res);
      }
    }
  }, [row]);

  const onSubmit = async () => {
    if (!rtitle) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة البيان' : 'Please add title'
      );
      return;
    }
    setSaving(true);

    const variables: any = {
      _id: row && row._id ? row._id : undefined, // is it new or edit
      title: rtitle,
      sendtime: runtime,
      departmentId: departvalue ? departvalue._id : undefined,
      employeeId: emplvalue ? emplvalue._id : undefined,
      resourseId: resovalue ? resovalue._id : undefined,
      contractIdId: taskvalue ? taskvalue._id : undefined,
    };
    const mutate = isNew ? addAction : editAction;
    apply(mutate, variables);
  };

  const apply = async (mutate: any, variables: any) => {
    try {
      mutate({ variables });
      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log('error popup', error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      console.log(error);
    }
  };

  const resetAllForms = () => {
    setRuntime(null);
    setDepartvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setTaskvalue(null);
    setSaving(false);
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
  };

  const title = 'Edit Reminder Item';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <CalenderLocal
                    isRTL={isRTL}
                    label={words?.time}
                    value={runtime}
                    onChange={(d: any) => {
                      setRuntime(d);
                    }}
                    time
                  ></CalenderLocal>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldLocal
                    name="rtitle"
                    multiline
                    rows={3}
                    label={words.description}
                    value={rtitle}
                    onChange={(e: any) => setRtitle(e.target.value)}
                    row={row}
                    fullWidth
                    mb={0}
                  />
                </Grid>

                {tempoptions?.contract && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="resourse"
                      title={tempwords?.resourse}
                      words={words}
                      options={resourses}
                      // disabled={isemployee}
                      value={resovalue}
                      setSelectValue={setResovalue}
                      isRTL={isRTL}
                      fullWidth
                      mb={0}
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.employee && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="employee"
                      title={tempwords?.employee}
                      words={words}
                      options={employees}
                      value={emplvalue}
                      setSelectValue={setEmplvalue}
                      isRTL={isRTL}
                      fullWidth
                      mb={0}
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.contract && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="task"
                      title={tempwords?.task}
                      words={words}
                      options={tasks}
                      value={taskvalue}
                      setSelectValue={setTaskvalue}
                      isRTL={isRTL}
                      fullWidth
                    ></AutoFieldLocal>
                  </Grid>
                )}
                {tempoptions?.department && (
                  <Grid item xs={6}>
                    <AutoFieldLocal
                      name="department"
                      title={tempwords?.department}
                      words={words}
                      options={departments.filter((d: any) => d.depType === 1)}
                      value={departvalue}
                      setSelectValue={setDepartvalue}
                      isRTL={isRTL}
                      fullWidth
                      mb={0}
                    ></AutoFieldLocal>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupRAction;
