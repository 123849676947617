/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import {
  LinkLocation,
  LinkPhone,
  timeToHourMinute,
  userroles,
} from '../../../common';
import { eventStatusEn } from '../../../constants';
import { cardClasses } from '../../../themes/classes';
import { renderStatusIcon } from './StatusIcon';
import { GContextTypes } from '../../../types';
import { GlobalContext } from '../../../contexts';
import { eventStatusAr } from '../../../constants/datatypes';
import { useLazyQuery } from '@apollo/client';
import { getOperationItems } from '../../../graphql';
import useTasks from '../../../hooks/useTasks';
// import useDepartments from '../../../hooks/useDepartments';
import {
  useProducts,
  useServices,
  useTemplate,
  // useUsers,
} from '../../../hooks';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Whatsapp from '../../../common/Whatsapp';
import { TextFieldLocal } from '../../../components';

export const RenderToolTip = ({
  appointmentData,
  setAppointmentData,
  itemsList,
  setItemsList,
  openInvoice,
  editEvent,
  setVisible,
}: any) => {
  const [paid, setPaid] = useState(appointmentData?.cashin);
  const [statusaction, setStatusaction] = useState(appointmentData.status);

  const {
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);
  // const { users } = useUsers();
  const classes = cardClasses();
  const { tasks } = useTasks();
  // const { departments } = useDepartments();
  const { services } = useServices();
  const { products } = useProducts();
  const { isMobile } = useWindowDimensions();
  const { eventoptions } = useTemplate();

  const {
    startDate,
    endDate,
    customerName,
    customerNameAr,
    customerPhone,
    // employeePhone,
    // departmentId,
    // departmentName,
    // departmentNameAr,
    contractId,
    status,
    amount,
    docNo,
    title,
    locationLink,
    address,
    // userId,
    houseno,
    invoiced,
    desc,
  } = appointmentData;
  const task = tasks.filter((t: any) => t._id === contractId)?.[0];

  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (appointmentData && appointmentData._id) {
      setAppointmentData(appointmentData);
      const variables = { opId: appointmentData._id };
      getItems({
        variables,
      });
    }
  }, []);

  useEffect(() => {
    if (itemsList?.length === 0) {
      const items = itemsData?.data?.['getOperationItems']?.data || [];
      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...services, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );

        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            // itemtotalcost: item.qty * serv.cost,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        setItemsList(itemsWqtyprice);
      }
    }
  }, [itemsData]);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // const user = users.filter((us: any) => us._id === userId)?.[0];

  // const departColor = departments
  //   ? departments.filter((dep: any) => dep._id === departmentId)
  //   : '';
  // const departmentColor = departColor?.[0]?.color || '';

  let disabled = !userroles?.appointments?.edit;
  const caninvoice =
    customerPhone &&
    itemsList?.length > 0 &&
    status !== 10 &&
    userroles?.invoices?.edit &&
    // (userroles?.invoices?.edit || userroles?.isEmployee) &&
    !invoiced;
  return (
    <Card style={{ direction: isRTL ? 'rtl' : 'ltr' }} className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          {eventoptions?.driverbox && (
            <>
              <Grid item md={6} xs={12}>
                <FormControl
                  style={{ minWidth: 150, marginTop: isMobile ? undefined : 8 }}
                >
                  <InputLabel id="select-label">
                    {isRTL ? 'الحالة' : 'Status'}
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    disabled={disabled}
                    variant="outlined"
                    value={appointmentData.status}
                    onChange={(e: any) => {
                      setStatusaction(e.target.value);
                      // editEvent({
                      //   variables: {
                      //     id: appointmentData.id,
                      //     status: e.target.value,
                      //   },
                      //   optimisticResponse: {
                      //     __typename: 'updateEvent',
                      //     updateEvent: {
                      //       __typename: 'Operation',
                      //       ...appointmentData,
                      //       id: appointmentData.id,
                      //       status: e.target.value,
                      //     },
                      //   },
                      // });
                      // setVisible(false);
                    }}
                  >
                    <MenuItem value={2}>
                      {isRTL ? 'مؤكد' : 'Confirmed'}
                    </MenuItem>
                    {/* <MenuItem value={4}>{isRTL ? 'ملغى' : 'Canceled'}</MenuItem> */}
                    <MenuItem value={6}>
                      {isRTL ? 'قيد الانجاز' : 'In Progress'}
                    </MenuItem>
                    <MenuItem value={10}>
                      {isRTL ? 'منجز' : 'Completed'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  style={{
                    display: 'flex',
                    marginTop: isMobile ? -10 : undefined,
                  }}
                >
                  <TextFieldLocal
                    name="price"
                    label={isRTL ? 'نقداً' : 'Cash'}
                    value={paid}
                    onChange={(e: any) => setPaid(Number(e.target.value))}
                    type="number"
                    width={150}
                    mb={0}
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="outlined"
                  style={{
                    height: 36,
                    width: 150,
                  }}
                  onClick={() => {
                    editEvent({
                      variables: {
                        id: appointmentData.id,
                        cashin: paid,
                        status: statusaction ? statusaction : undefined,
                      },
                      optimisticResponse: {
                        __typename: 'updateEvent',
                        updateEvent: {
                          __typename: 'Operation',
                          ...appointmentData,
                          id: appointmentData.id,
                          cashin: paid,
                          status: statusaction,
                        },
                      },
                    });
                    setVisible(false);
                  }}
                >
                  <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                    {isRTL ? 'حفظ' : 'Save'}
                  </Typography>
                </Button>
              </Grid>
            </>
          )}
          <Grid item md={6} xs={12}>
            {locationLink && (
              <LinkLocation isRTL={isRTL} value={locationLink}></LinkLocation>
            )}
            <Typography>{houseno}</Typography>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
        {status && (
          <Box
            display="flex"
            style={{
              position: 'absolute',
              height: 30,
              paddingRight: 5,
              paddingLeft: 5,
              borderRadius: 3,
              alignItems: 'center',
              justifyContent: 'center',
              left: 10,
              top: 10,
            }}
          >
            {renderStatusIcon(status, '#555', 20)}
            <Typography
              style={{
                marginTop: -1,
                marginLeft: 3,
              }}
            >
              {isRTL ? eventStatusAr[status] : eventStatusEn[status]}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          style={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Typography style={{ fontWeight: 'bold' }} variant="body2">
            {docNo}
          </Typography>
        </Box>
        {eventoptions?.title && (
          <Box style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            <Typography style={{ fontWeight: 'bold' }} variant="body2">
              {task?.title}
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="body2">
              {title}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box>
            <Typography gutterBottom variant="subtitle2" component="h2">
              {startDate.toLocaleString(isRTL ? 'ar-QA' : 'en-US', options)}
            </Typography>
          </Box>
          <Box display="flex" style={{ marginBottom: 3 }}>
            <div>
              {timeToHourMinute(startDate, isRTL ? 'ar-QA' : 'en-US')} -{' '}
            </div>
            <div> {timeToHourMinute(endDate, isRTL ? 'ar-QA' : 'en-US')}</div>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography gutterBottom variant="h5">
            {isRTL ? customerNameAr : customerName}
          </Typography>
          {customerPhone && customerPhone?.length > 0 && (
            <Box display={'flex'}>
              <Whatsapp phone={customerPhone}></Whatsapp>
              <LinkPhone value={customerPhone}></LinkPhone>
            </Box>
          )}
        </Box>
        <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
        <Box
          display="flex"
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Grid container spacing={1}>
            {!isMobile &&
              itemsList?.map((item: any) => {
                const name = isRTL
                  ? item?.nameAr?.substring(0, 40)
                  : item?.name?.substring(0, 40);
                return (
                  <>
                    <Grid item xs={8}>
                      <Typography>{name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>{item?.itemqty}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>{item?.itemprice}</Typography>
                    </Grid>
                  </>
                );
              })}
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <Box
                display={'flex'}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#667fb5',
                }}
              >
                <Typography
                  style={{
                    marginTop: 3,
                    color: '#fff',
                  }}
                  gutterBottom
                  variant="subtitle2"
                  component="h2"
                >
                  {amount} QR
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          style={{
            flexWrap: 'wrap',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          <Typography>{desc}</Typography>
          <Typography style={{ marginTop: 10 }}>{address}</Typography>
        </Box>
        {caninvoice && (
          <Box m={1} display="flex" style={{ flex: 1, height: 36 }}>
            <Button color="primary" variant="outlined" onClick={openInvoice}>
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                {words.addInvoice}
              </Typography>
            </Button>
          </Box>
        )}
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};
