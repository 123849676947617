import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor, moneyFormat } from '../../common/helpers';
import { InfoBox } from '../charts';

function CardBox({
  isRTL,
  theme,
  words,
  dark,
  color,
  systemcolor,
  qty,
  money,
  height,
}) {
  const { raseeds } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {userroles?.invoices?.admin && (
        <InfoBox
          title={isRTL ? 'البطاقة' : 'Card'}
          value={moneyFormat(raseeds?.card)}
          icon="cash"
          color={clr}
          dark={dark}
          height={height}
        ></InfoBox>
      )}
    </Box>
  );
}

export default CardBox;
