import { gql } from '@apollo/client';

export default gql`
  subscription eventStopChange {
    eventStopChange {
      data
      userId
      time
    }
  }
`;
