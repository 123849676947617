/* eslint-disable import/no-anonymous-default-export */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createBranchPrintTemplate,
  deletePrintTemplate,
  getBranchPrintTemplates,
  updatePrintTemplate,
} from '../graphql';

export default (branch: any) => {
  const [getDeparts, catpData]: any = useLazyQuery(getBranchPrintTemplates, {
    variables: { branch },
  });

  const [addPrintTemplate] = useMutation(createBranchPrintTemplate, {
    refetchQueries: [{ query: getBranchPrintTemplates, variables: { branch } }],
  });
  const [editPrintTemplate] = useMutation(updatePrintTemplate, {
    refetchQueries: [{ query: getBranchPrintTemplates, variables: { branch } }],
  });
  const [removePrintTemplate] = useMutation(deletePrintTemplate, {
    refetchQueries: [{ query: getBranchPrintTemplates, variables: { branch } }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const printtemplates =
    catpData?.data?.['getBranchPrintTemplates']?.data || [];
  const refreshprinttemplate = () => catpData?.refetch();

  return {
    printtemplates,
    refreshprinttemplate,
    addPrintTemplate,
    editPrintTemplate,
    removePrintTemplate,
    loading: catpData?.loading,
  };
};
