/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import PopupLayout from '../shared/PopupLayout';
import PrintBuilderForm from '../generator/print/PrintBuilderForm';
import { formData } from '../generator/print/templatedata';
import _ from 'lodash';

const PopupPrintTemplate = ({
  open,
  onClose,
  temp,
  setTemp,
  company,
  isRTL,
  fields,
  words,
}: any) => {
  const [header, setHeader] = useState(true);
  const [footer, setFooter] = useState(true);

  const fieldsnames = fields.map((f: any) => {
    if (f.field === 'contact')
      return ['contactName', 'contactNameAr', 'contactPhone'];
    if (f.field === 'contract')
      return ['contractName', 'contractNameAr', 'contractPhone'];
    if (f.field === 'customer')
      return ['customerName', 'customerNameAr', 'customerPhone'];
    return f.field;
  });

  let ffieldsnames = _.flatten(fieldsnames);
  const viewdata = Object.keys(formData)
    .filter((key) => ffieldsnames.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: formData[key] });
    }, {});

  const title = 'Form Template';
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onClose}
      title={title}
      onSubmit={() => null}
      canceltitle={isRTL ? 'تم' : 'Done'}
      onlyclose
      maxWidth="xl"
      alrt={{ show: false, msg: '', type: undefined }}
      mb={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={header}
                onChange={() => setHeader(!header)}
                name="bold"
                color="primary"
              />
            }
            label="Header"
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={footer}
                onChange={() => setFooter(!footer)}
                name="bold"
                color="primary"
              />
            }
            label="Footer"
          />
        </Grid>
        <Grid item xs={12}>
          <PrintBuilderForm
            rows={temp}
            setRows={setTemp}
            company={company}
            size={'A4'}
            header={header}
            footer={footer}
            isRTL={isRTL}
            words={words}
            viewdata={viewdata}
          ></PrintBuilderForm>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupPrintTemplate;
