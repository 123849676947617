import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../../../contexts';
import { userroles } from '../../../constants';
import { createEvent, getLandingChartData } from '../../../../graphql';
import {
  useDepartments,
  useEmployees,
  useServices,
  useTemplate,
} from '../../../../hooks';
import useResourses from '../../../../hooks/useResourses';
import useTasks from '../../../../hooks/useTasks';
import PopupAppointment from '../../../../pubups/PopupAppointment';
import IconView from '../IconView';

function AppointmentIcon({ isRTL, theme, color, height, company }: any) {
  const [openAppoint, setOpenAppoint] = useState(false);
  const { resourses } = useResourses();
  const { departments } = useDepartments();
  const { employees } = useEmployees();
  const { tempwords } = useTemplate();
  const { services } = useServices();
  const { tasks } = useTasks();

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };
  const {
    store: { calendar },
  } = useContext(GlobalContext);
  const [addEvent] = useMutation(createEvent, refresQuery);

  return (
    <Box>
      {userroles?.appointments?.add && (
        <>
          <IconView
            color={color ? color : theme.palette.primary.main}
            icon="event"
            title={
              isRTL
                ? `انشاء ${tempwords?.appointment}`
                : `Add ${tempwords?.appointment}`
            }
            height={height}
            onOpen={() => setOpenAppoint(true)}
          ></IconView>
          <PopupAppointment
            open={openAppoint}
            onClose={() => setOpenAppoint(false)}
            addAction={addEvent}
            editAction={() => null}
            employees={employees}
            resourses={resourses}
            departments={departments}
            company={company}
            servicesproducts={services}
            theme={theme}
            tasks={tasks}
            isNew={true}
            calendar={calendar}
          ></PopupAppointment>
        </>
      )}
    </Box>
  );
}

export default AppointmentIcon;
