/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { invoiceClasses } from '../themes';
import { useProducts, useTemplate } from '../hooks';
import {
  dublicateAlert,
  errorAlert,
  messageAlert,
  successAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import ServiceItemForm from '../Shared/ServiceItemForm';
import ItemsTable from '../Shared/ItemsTable';
import { PriceTotal } from '../Shared/TotalPrice';
import { operationTypes } from '../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createInvoice, getInvoices, getOperationItems } from '../graphql';
import { accountCode, parents } from '../constants/kaid';
import PaymentSelect from '../pages/options/PaymentSelect';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import { CalenderLocal } from '../components';
import { useReactToPrint } from 'react-to-print';
import { getDefaultTemplate, getInvDays, tafkeet } from '../common/helpers';
import getGereralCalculation from '../graphql/query/getGereralCalculation';
import { getProjects, getTasks } from '../graphql/query';
import { getEndOfMonth, getStartOfMonth } from '../Shared/helpers';
import useAccounts from '../hooks/useAccounts';
import getTaskItems from '../graphql/query/getTaskItems';
// import _ from 'lodash';
import usePrintTemplates from '../hooks/usePrintTemplates';
import { indexTheList } from './PopupInvoice';
import useCompany from '../hooks/useCompany';
import { moneyFormat } from '../Shared/colorFormat';
import PrintDownload from '../Shared/PrintDownload';
// import PrintDownload from '../Shared/PrintDownload';

const PopupTaskInvoice = ({
  open,
  onClose,
  task,
  customers,
  services,
  resourses,
  employees,
  departments,
  isNew = true,
  theme,
  mstart,
  mend,
}: any) => {
  const classes = invoiceClasses();
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const [saving, setSaving] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [invNo, setInvNo] = useState<any>('');

  const [isPeriod, setIsPeriod] = useState(false);
  const [periodfrom, setPeriodfrom] = useState(null);
  const [periodto, setPeriodto] = useState(null);
  const [invdays, setInvdays] = useState(0);
  const [isMonthly, setIsMonthly] = useState(false);

  const [itemsList, setItemsList] = useState<any>([]);
  const [accounts, setAccounts] = useState<any>([]);

  const [discount, setDiscount] = useState(0);
  const [totals, setTotals] = useState<any>({});

  const [custvalue, setCustvalue] = useState<any>(null);
  const [custError, setCustError] = useState<any>(false);

  const [departvalue, setDepartvalue] = useState<any>(null);
  const [departError, setDepartError] = useState<any>(false);
  const departRef: any = useRef();

  const [emplvalue, setEmplvalue] = useState<any>(null);
  const [emplError, setEmplError] = useState<any>(false);
  const emplRef: any = useRef();

  const [resovalue, setResovalue] = useState<any>(null);
  const [resoError, setResoError] = useState<any>(false);
  const resoRef: any = useRef();

  const { tempwords, tempoptions } = useTemplate();
  const [isCash, setIsCash] = useState(tempoptions?.cashinvoice);

  const [paid, setPaid] = useState<any>(0);
  const [debitAcc, setDebitAcc]: any = React.useState(null);
  const [paid2, setPaid2] = useState<any>(0);
  const [debitAcc2, setDebitAcc2]: any = React.useState(null);

  const [itemstype, setItemstype]: any = React.useState('task');
  const [percent, setPercent] = useState<any>(0);
  const [ispercent, setIspercent] = useState<any>(0);
  const { accounts: mainAccounts } = useAccounts();
  const { products } = useProducts();
  const { printtemplates } = usePrintTemplates();
  const { company } = useCompany();

  const { reset } = useForm({});
  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);
  const refresQuery = {
    refetchQueries: [
      { query: getInvoices, variables: { contractId: task._id } },
      {
        query: getGereralCalculation,
        variables: {
          contractId: task._id,
          start: mstart ? new Date(mstart).setHours(0, 0, 0, 0) : undefined,
          end: mend ? new Date(mend).setHours(23, 59, 59, 999) : undefined,
        },
      },
      { query: getTasks },
      { query: getProjects },
    ],
  };
  const [getItems, itemsData]: any = useLazyQuery(getOperationItems, {
    fetchPolicy: 'cache-and-network',
  });

  const [getTaskItemsData, taskItemsData]: any = useLazyQuery(getTaskItems, {
    fetchPolicy: 'cache-and-network',
  });
  const [addInvoice] = useMutation(createInvoice, refresQuery);

  const cashaccounts = mainAccounts.filter(
    (acc: any) => acc.parentcode === parents.CASH && acc.code < 10499
  );

  useEffect(() => {
    if (isCash) {
      setDebitAcc(cashaccounts?.[0]);
      setDebitAcc2(cashaccounts?.[1]);
    }
  }, [open, isCash]);

  const resetAllForms = () => {
    setItemsList([]);
    setDiscount(0);
    setTotals({});
    setCustvalue(null);
    setInvNo('');
    setAccounts([]);
    setSaving(false);
    setSelectedDate(new Date());
    setDepartvalue(null);
    setEmplvalue(null);
    setResovalue(null);
    setIsPeriod(false);
    setPeriodfrom(null);
    setPeriodto(null);
    setInvdays(0);
    setIsCash(tempoptions?.cashinvoice);
    setPaid(0);
    setPaid2(0);
    setDebitAcc(null);
    setDebitAcc2(null);
  };

  const addItemToList = (item: any) => {
    const newArray = [...itemsList, { ...item, userId: user._id }];
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };
  const editItemInList = (item: any) => {
    const newArray = itemsList.map((it: any) => {
      if (it._id === item._id && it?.index === item?.index) {
        return item;
      } else {
        return it;
      }
    });
    const listwithindex = indexTheList(newArray);
    setItemsList(listwithindex);
  };

  const removeItemFromList = (index: any) => {
    const newList = [...itemsList];
    newList.splice(index, 1);
    const listwithindex = indexTheList(newList);
    setItemsList(listwithindex);
  };

  const handleDateChange = (date: any) => {
    if (date) {
      const d = new Date(date);
      d?.setHours(8, 0, 0);
      setSelectedDate(d);
    }
  };

  useEffect(() => {
    if (isPeriod === true) {
      if (!periodfrom) {
        if (isMonthly) {
          const som = getStartOfMonth(null);
          setPeriodfrom(som);
        } else {
          setPeriodfrom(task?.periodfrom ? task?.periodfrom : task.start);
        }
      }
      if (!periodto) {
        if (isMonthly) {
          const eom = getEndOfMonth(null);
          setPeriodto(eom);
        } else {
          setPeriodto(new Date());
        }
      }
      if (invdays) {
        const litems = itemsList.map((it: any) => {
          return { ...it, itemqty: invdays, itemtotal: invdays * it.itemprice };
        });
        const listwithindex = indexTheList(litems);
        setItemsList(listwithindex);
      }
    } else {
      setPeriodfrom(null);
      setPeriodto(null);
      setInvdays(0);
    }
  }, [isPeriod, invdays, isMonthly]);

  useEffect(() => {
    if (periodfrom && periodto) {
      const days = getInvDays(periodfrom, periodto);
      if (isMonthly) {
        const mdays = Math.floor(days / 28);
        setInvdays(mdays);
      } else {
        setInvdays(days);
      }
    }
  }, [periodfrom, periodto]);

  useEffect(() => {
    getOverallTotal();
  }, [itemsList, discount, isCash, paid, paid2, debitAcc, debitAcc2]);
  // useEffect(() => {
  //   if (isCash && (paid === 0 || paid2 === 0)) {
  //     const amount = simpleAmount();
  //     setPaid(amount || 0);
  //   }
  //   if (!isCash) {
  //     setPaid(0);
  //     setPaid2(0);
  //   }
  // }, [itemsList, discount, isCash]);

  // const simpleAmount = () => {
  //   const totalsin = itemsList.map((litem: any) => litem.itemtotal);
  //   const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
  //   const amount = sum - discount;
  //   return amount;
  // };

  useEffect(() => {
    if (task && itemstype === 'task') {
      const items = itemsData?.data?.['getOperationItems']?.data || [];
      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...services, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );
        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            note,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            note,
          };
        });
        itemsWqtyprice.sort((a: any, b: any) =>
          a.indx > b.indx ? 1 : b.indx > a.indx ? -1 : 0
        );
        const listwithindex = indexTheList(itemsWqtyprice);

        setItemsList(listwithindex);
      } else {
        setItemsList([]);
      }
    }
  }, [itemsData, itemstype]);

  useEffect(() => {
    if (open && itemstype === 'events') {
      const items = taskItemsData?.data?.['getTaskItems']?.data || [];
      if (items && items.length > 0) {
        const ids = items.map((it: any) => it.itemId);
        const servlist = [...services, ...products].filter((ser: any) =>
          ids.includes(ser._id)
        );

        const itemsWqtyprice = items.map((item: any, index: any) => {
          const {
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
          } = item;
          const serv = servlist.filter((se: any) => se._id === item.itemId)[0];
          return {
            ...serv,
            categoryId,
            categoryName,
            categoryNameAr,
            departmentId,
            departmentName,
            departmentNameAr,
            departmentColor,
            employeeId,
            employeeName,
            employeeNameAr,
            employeeColor,
            resourseId,
            resourseName,
            resourseNameAr,
            resourseColor,
            contractId,
            contractName,
            contractNameAr,
            index,
            itemprice: item.itemPrice,
            itemqty: item.qty,
            itemtotal: item.total,
            // itemtotalcost: item.qty * serv.cost,
          };
        });
        // const finalItems = _(itemsWqtyprice)
        //   .groupBy('_id')
        //   .map((array) => ({
        //     _id: array[0]._id,
        //     name: array[0].name,
        //     nameAr: array[0].nameAr,
        //     categoryId: array[0].categoryId,
        //     categoryName: array[0].categoryName,
        //     categoryNameAr: array[0].categoryNameAr,
        //     departmentId: array[0].departmentId,
        //     departmentName: array[0].departmentName,
        //     departmentNameAr: array[0].departmentNameAr,
        //     departmentColor: array[0].departmentColor,
        //     employeeId: array[0].employeeId,
        //     employeeName: array[0].employeeName,
        //     employeeNameAr: array[0].employeeNameAr,
        //     employeeColor: array[0].employeeColor,
        //     resourseId: array[0].resourseId,
        //     resourseName: array[0].resourseName,
        //     resourseNameAr: array[0].resourseNameAr,
        //     resourseColor: array[0].resourseColor,
        //     autoNo: array[0].autoNo,
        //     docNo: array[0].docNo,
        //     cost: array[0].cost,
        //     itemType: array[0].itemType,
        //     index: array[0].index,
        //     itemprice: array[0].itemprice,
        //     itemqty: _.sumBy(array, 'itemqty'),
        //     itemtotal: _.sumBy(array, 'itemtotal'),
        //   }))
        //   .orderBy('index')
        //   .value();
        const listwithindex = indexTheList(itemsWqtyprice);
        setItemsList(listwithindex);
      } else {
        setItemsList([]);
      }
    }
  }, [taskItemsData, itemstype, open]);
  useEffect(() => {
    if (task && task.start) {
      getItems({ variables: { opId: task._id } });
      getTaskItemsData({ variables: { contractId: task._id } });

      const _id = task.customerId;
      const cust = customers.filter((it: any) => it._id === _id)[0];
      setCustvalue(cust);
      const depId = task.departmentId;
      const empId = task.employeeId;
      const resId = task.resourseId;
      if (depId) {
        const depart = departments.filter((dep: any) => dep._id === depId)[0];
        setDepartvalue(depart);
      }
      if (empId) {
        const empl = employees.filter((emp: any) => emp._id === empId)[0];
        setEmplvalue(empl);
      }
      if (resId) {
        const empl = resourses.filter((emp: any) => emp._id === resId)[0];
        setResovalue(empl);
      }
    }
  }, [task, services, customers, open]);

  useEffect(() => {
    if (isNew) {
      const start = new Date();
      start.setHours(8, 0, 0);
      setSelectedDate(start);
    }
  }, [open]);

  const getOverallTotal = () => {
    const totalsin = itemsList.map((litem: any) => litem.itemtotal);
    const sum = totalsin.reduce((psum: any, a: any) => psum + a, 0);
    const costtotals = itemsList.map((litem: any) => litem.itemtotalcost);
    const costsum = costtotals.reduce((psum: any, a: any) => psum + a, 0);
    const amount = sum - discount;
    const profit = sum - discount - costsum;
    const tots = {
      itemsSum: amount,
      itemsCostSum: costsum,
      costAmount: costsum,
      total: sum,
      amount,
      profit,
    };
    setTotals(tots);
    const accs = [
      {
        debitAcc: accountCode.accounts_receivable,
        creditAcc: accountCode.sales_income,
        amount: sum,
        type: operationTypes.salesInvoice,
      },
      {
        debitAcc: accountCode.sales_income,
        creditAcc: accountCode.accounts_receivable,
        amount: discount,
        type: operationTypes.customerDiscount,
      },
      {
        debitAcc: 1000,
        creditAcc: accountCode.accounts_receivable,
        amount: isCash ? paid : 0,
        type: operationTypes.customerReceipt,
      },
      {
        debitAcc: 1010,
        creditAcc: accountCode.accounts_receivable,
        amount: isCash ? paid2 : 0,
        type: operationTypes.customerReceipt,
      },
      {
        debitAcc: accountCode.cost_of_sales,
        creditAcc: accountCode.inventory,
        amount: costsum,
        type: operationTypes.salesDelivery,
      },
    ];
    setAccounts(accs);
  };
  const onSubmit = async (print: any) => {
    if (!custvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يرجى اضافة عميل للفاتورة' : 'Please add Customer'
      );
      return;
    }
    if (!itemsList || itemsList.length === 0) {
      await messageAlert(
        setAlrt,
        isRTL
          ? `يجب اضافة عنصر (خدمة او منتج) واحد للفاتورة على الأقل`
          : `You should add min one service to invoice`
      );
      return;
    }
    setSaving(true);
    const { amount, costAmount, profit, total } = totals;
    const newList =
      !tempoptions?.itemrelations && !tempoptions?.invoiceitemrelations
        ? itemsList?.map((il: any) => {
            return {
              ...il,
              contractId: task?._id,
              contractName: task?.name,
              contractNameAr: task?.nameAr,
              departmentId: departvalue?._id,
              departmentName: departvalue?.name,
              departmentNameAr: departvalue?.nameAr,
              departmentColor: departvalue?.color,
              employeeId: emplvalue?._id,
              employeeName: emplvalue?.name,
              employeeNameAr: emplvalue?.nameAr,
              employeeColor: emplvalue?.color,
              employeePhone: emplvalue?.phone,
              resourseId: resovalue?._id,
              resourseName: resovalue?.name,
              resourseNameAr: resovalue?.nameAr,
              resourseColor: resovalue?.color,
            };
          })
        : itemsList;
    const items = JSON.stringify(newList);
    const variables: any = {
      time: selectedDate,
      customer: {
        customerId: custvalue._id,
        customerName: custvalue.name,
        customerNameAr: custvalue.nameAr,
        customerPhone: custvalue.phone,
      },
      department: departvalue
        ? {
            departmentId: departvalue._id,
            departmentName: departvalue.name,
            departmentNameAr: departvalue.nameAr,
            departmentColor: departvalue.color,
          }
        : {
            departmentId: undefined,
            departmentName: undefined,
            departmentNameAr: undefined,
            departmentColor: undefined,
          },
      employee: emplvalue
        ? {
            employeeId: emplvalue._id,
            employeeName: emplvalue.name,
            employeeNameAr: emplvalue.nameAr,
            employeeColor: emplvalue.color,
            employeePhone: emplvalue.phone,
          }
        : {
            employeeId: undefined,
            employeeName: undefined,
            employeeNameAr: undefined,
            employeeColor: undefined,
            employeePhone: undefined,
          },
      resourse: resovalue
        ? {
            resourseId: resovalue._id,
            resourseName: resovalue.name,
            resourseNameAr: resovalue.nameAr,
            resourseColor: resovalue.color,
          }
        : {
            resourseId: undefined,
            resourseName: undefined,
            resourseNameAr: undefined,
            resourseColor: undefined,
          },
      contract: {
        contractId: task._id,
        contractName: task.name,
        contractNameAr: task.nameAr,
      },
      project: task
        ? {
            projectId: task.projectId,
            projectName: task.projectName,
            projectNameAr: task.projectNameAr,
          }
        : {
            projectId: undefined,
            projectName: undefined,
            projectNameAr: undefined,
          },
      items,
      costAmount,
      total,
      discount,
      amount,
      profit,
      isPaid: isCash,
      isCash,
      debitAcc: debitAcc ? debitAcc.code : null,
      debitAcc2: debitAcc2 ? debitAcc2.code : null,
      amountPaid: isCash ? paid : 0,
      amountPaid2: isCash ? paid2 : 0,
      accounts,
      periodfrom,
      periodto,
      isMonthly,
      userId: user._id,
    };
    apply(addInvoice, variables, print);
  };
  const apply = async (mutate: any, variables: any, print: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.createInvoice?.ok) {
        setInvNo(res?.data?.createInvoice?.message);
      }
      if (print) {
        handleReactPrint();
      }
      await successAlert(setAlrt, isRTL);

      setSaving(false);
      onCloseForm();
    } catch (error) {
      onError(error);
      console.log(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onCloseForm = () => {
    resetAllForms();
    onClose();
    setSaving(false);
  };

  const onHandleSubmit = (print: any) => {
    onSubmit(print);
  };

  const template = getDefaultTemplate(printtemplates, 'invoice');

  const componentRef: any = useRef();
  const handleReactPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice #${invNo}`,
    removeAfterPrint: true,
  });
  const printData = {
    invoiceNo: invNo,
    time: selectedDate,
    docNo: invNo,
    customerName: custvalue?.name,
    customerPhone: custvalue?.phone,
    customerNameAr: custvalue?.nameAr,
    amountPaid: paid,
    amountPaid2: paid2,
    total: totals.total,
    percent,
    discount,
    amount: totals.amount,
    items: itemsList,
    user: user?.name,
    tafkeet: tafkeet(totals.amount, isRTL),
  };

  const title = isRTL
    ? isNew
      ? 'فاتورة جديدة'
      : 'تعديل فاتورة'
    : isNew
    ? 'New Invoice'
    : 'Edit Invoice';
  const bl = totals?.amount - (paid + paid2);
  const pa = `${paid + paid2} /  ${totals?.amount}`;
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      print={handleReactPrint}
      maxWidth="lg"
      saving={saving}
      saveprint={template?.size === 'POS'}
      mt={0}
      mb={50}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <CalenderLocal
                isRTL={isRTL}
                label={words?.time}
                value={selectedDate}
                onChange={handleDateChange}
              ></CalenderLocal>
            </Grid>
            <Grid item xs={7}>
              <FormControlLabel
                style={{ marginTop: 25, marginLeft: 10, marginRight: 10 }}
                control={
                  <Checkbox
                    checked={isPeriod}
                    onChange={() => {
                      setIsPeriod(!isPeriod);
                      setIsMonthly(false);
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{ color: theme.palette.primary.main }}
                    variant="body2"
                  >
                    {isRTL ? 'فاتورة وقت' : 'Time Invoice'}
                  </Typography>
                }
              />
              {isPeriod && (
                <FormControlLabel
                  style={{ marginTop: 25, marginLeft: 10, marginRight: 10 }}
                  control={
                    <Checkbox
                      checked={isMonthly}
                      onChange={() => setIsMonthly(!isMonthly)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      style={{ color: theme.palette.primary.main }}
                      variant="body2"
                    >
                      {isRTL ? 'شهري' : 'Monthly'}
                    </Typography>
                  }
                />
              )}
            </Grid>
            {isPeriod && (
              <Grid item xs={5}>
                <CalenderLocal
                  isRTL={isRTL}
                  label={words.start}
                  value={periodfrom}
                  onChange={(d: any) => {
                    if (isMonthly) {
                      const som = getStartOfMonth(d);
                      setPeriodfrom(som);
                    } else {
                      setPeriodfrom(d);
                    }
                  }}
                ></CalenderLocal>
              </Grid>
            )}
            {isPeriod && (
              <Grid item xs={5}>
                <CalenderLocal
                  isRTL={isRTL}
                  label={words.end}
                  value={periodto}
                  onChange={(d: any) => {
                    if (isMonthly) {
                      const eom = getEndOfMonth(d);
                      setPeriodto(eom);
                    } else {
                      setPeriodto(d);
                    }
                  }}
                ></CalenderLocal>
              </Grid>
            )}
            {isPeriod && (
              <Grid item xs={2}>
                <Typography
                  style={{ marginTop: 35, marginLeft: 10, marginRight: 10 }}
                >{`( ${invdays} )`}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <AutoFieldLocal
            name="customer"
            title={words.customer}
            words={words}
            options={customers}
            value={custvalue}
            setSelectValue={setCustvalue}
            setSelectError={setCustError}
            selectError={custError}
            isRTL={isRTL}
            fullWidth
            disabled
            // openAdd={openCustomer}
          ></AutoFieldLocal>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          {!isNew && (
            <Box
              display="flex"
              style={{
                flex: 1,
                flexDirection: isRTL ? 'row-reverse' : 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginLeft: isRTL ? undefined : 20,
                marginRight: isRTL ? 20 : undefined,
              }}
            >
              <TextField
                name="invNo"
                disabled
                value={invNo}
                variant="outlined"
                style={{ width: isNew ? 70 : 100, marginLeft: 20 }}
                margin="dense"
                // type="number"
                inputProps={{
                  style: {
                    textAlign: 'center',
                    fontSize: 14,
                    height: 13,
                  },
                }}
              />
            </Box>
          )}
        </Grid>
        {tempoptions?.docrelations && (
          <>
            {tempoptions?.employee && (
              <Grid item xs={4}>
                <AutoFieldLocal
                  name="employee"
                  title={tempwords?.employee}
                  words={words}
                  options={employees.filter((emp: any) => !emp.disabled)}
                  value={emplvalue}
                  setSelectValue={setEmplvalue}
                  setSelectError={setEmplError}
                  selectError={emplError}
                  refernce={emplRef}
                  noPlus
                  isRTL={isRTL}
                  fullWidth
                  disabled
                ></AutoFieldLocal>
              </Grid>
            )}
            {tempoptions?.department && (
              <Grid item xs={4}>
                <AutoFieldLocal
                  name="department"
                  title={words.department}
                  words={words}
                  options={departments.filter((d: any) => d.depType === 1)}
                  value={departvalue}
                  setSelectValue={setDepartvalue}
                  setSelectError={setDepartError}
                  selectError={departError}
                  refernce={departRef}
                  noPlus
                  isRTL={isRTL}
                  fullWidth
                  disabled
                ></AutoFieldLocal>
              </Grid>
            )}

            {tempoptions?.resourse && (
              <Grid item xs={4}>
                <AutoFieldLocal
                  name="resourse"
                  title={tempwords?.resourse}
                  words={words}
                  options={resourses}
                  value={resovalue}
                  setSelectValue={setResovalue}
                  setSelectError={setResoError}
                  selectError={resoError}
                  refernce={resoRef}
                  noPlus
                  isRTL={isRTL}
                  fullWidth
                  disabled
                ></AutoFieldLocal>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={6}>
          <RadioGroup
            aria-label="Views"
            style={{ marginTop: 12, display: 'flex', flexDirection: 'row' }}
            name="views"
            value={itemstype}
            onChange={(e: any) => setItemstype(e.target.value)}
          >
            <FormControlLabel
              value="task"
              control={<Radio color="primary" />}
              label={`${isRTL ? 'من' : 'From'}  ${tempwords?.task}`}
            />
            <FormControlLabel
              value="events"
              control={<Radio color="primary" />}
              label={`${isRTL ? 'من' : 'From'}  ${tempwords?.appointments}`}
            />
          </RadioGroup>
        </Grid>
        <Grid item md={6}>
          {/* <PrintDownload
            company={company}
            printData={printData}
            template={template}
            componentRef={componentRef}
            handleReactPrint={handleReactPrint}
            generatePDF={() => null}
            isRTL={isRTL}
          ></PrintDownload> */}
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: '#f4f4f4',
              padding: 10,
              marginBottom: 15,
              borderRadius: 10,
            }}
          >
            <Box display="flex">
              <ServiceItemForm
                services={services}
                products={products}
                addItem={addItemToList}
                words={words}
                classes={classes}
                user={user}
                isRTL={isRTL}
                setAlrt={setAlrt}
                taskv={task}
                itemrelations={
                  tempoptions?.invoiceitemrelations ||
                  tempoptions?.itemrelations
                }
              ></ServiceItemForm>
            </Box>
            <Box style={{ marginBottom: 20 }}>
              <ItemsTable
                products={[...services, ...products]}
                rows={itemsList}
                editItem={editItemInList}
                removeItem={removeItemFromList}
                isRTL={isRTL}
                words={words}
                user={user}
                itemrelations={
                  tempoptions?.invoiceitemrelations ||
                  tempoptions?.itemrelations
                }
              ></ItemsTable>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 7 }}
                  checked={ispercent}
                  onChange={() => {
                    const nv = !ispercent;
                    if (!nv) {
                      setPercent(0);
                      setDiscount(0);
                    }
                    setIspercent(nv);
                  }}
                  color="primary"
                />
              }
              label={
                <Typography variant="subtitle2">
                  {isRTL ? 'نسبة' : 'Percentage'}
                </Typography>
              }
              style={{ fontSize: 14 }}
            />
            {(isNew || percent > 0) && (
              <TextField
                name="percent"
                label={'%'}
                value={percent}
                onChange={(e: any) => setPercent(Number(e.target.value))}
                variant="outlined"
                disabled={!isNew}
                style={{ width: 100 }}
                margin="dense"
                type="number"
              />
            )}
            <TextField
              name="discount"
              label={words.discount}
              value={discount}
              onChange={(e: any) => setDiscount(Number(e.target.value))}
              variant="outlined"
              style={{ width: 200 }}
              margin="dense"
              type="number"
              onFocus={(e) => e.target.select()}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <PrintDownload
            company={company}
            printData={printData}
            template={template}
            componentRef={componentRef}
            handleReactPrint={handleReactPrint}
            generatePDF={() => null}
            isRTL={isRTL}
            no={''}
            element="invoice"
            isNew={true}
          ></PrintDownload>
        </Grid>
        <Grid item xs={12} md={4}>
          <PriceTotal
            amount={totals?.amount}
            total={totals?.total}
            discount={discount}
            words={words}
            end={false}
          ></PriceTotal>
        </Grid>
        <Grid item xs={12} md={8}>
          <PaymentSelect
            words={words}
            isCash={isCash}
            setIsCash={setIsCash}
            paid={paid}
            setPaid={setPaid}
            paid2={paid2}
            setPaid2={setPaid2}
            isRTL={isRTL}
            debitaccounts={cashaccounts}
            debitAcc={debitAcc}
            setDebitAcc={setDebitAcc}
            debitAcc2={debitAcc2}
            setDebitAcc2={setDebitAcc2}
            isNew={isNew}
          ></PaymentSelect>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 10,
            }}
          >
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
                color: 'tomato',
              }}
            >
              {moneyFormat(bl)} {isRTL ? 'المتبقي' : 'Balance'}
            </Typography>
            <Typography
              style={{
                direction: 'ltr',
                fontWeight: 'bold',
              }}
            >
              ( {pa} ) {isRTL ? 'مدفوع' : 'Paid'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupTaskInvoice;
