import { gql } from '@apollo/client';

export default gql`
  query getDCategories {
    getDCategories {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        additionId
        disabled
        name
        nameAr
        userId

        ref
        perfix

        createdAt
        updatedAt
      }
    }
  }
`;
