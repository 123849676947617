import { gql } from '@apollo/client';

export default gql`
  subscription mailUpdated {
    mailUpdated {
      data
      userId
    }
  }
`;
