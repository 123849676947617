import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, fade, Typography } from '@material-ui/core';

export default function TabsListFree({
  isRTL,
  theme,
  list,
  color,
  selected,
  setSelected,
}: any) {
  const [value, setValue] = React.useState(0);
  const TAB_MIN_WID = isRTL ? 175 : 200;

  const handleChange = (_: any, newValue: number) => {
    setSelected(list?.[newValue]);
    setValue(newValue);
  };
  return (
    <Box
      style={{
        flexGrow: 1,
        backgroundColor: fade(theme.palette.primary.light, 0.1),
        height: 50,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="primary"
        TabIndicatorProps={{ style: { height: 3 } }}
      >
        {list?.map((item: any) => {
          const isselect = selected?.id === item?.id;
          const title = isRTL ? item.nameAr : item.name;
          return (
            <Tab
              style={{
                backgroundColor: isselect ? color : undefined,
                width: TAB_MIN_WID,
              }}
              key={item._id}
              label={
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  {title}
                </Typography>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
