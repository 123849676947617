import { Paper } from '@material-ui/core';
import CustomerDataBx from './CustomerDataBx';
import EmployeeDataBx from './EmployeeDataBx';
import ResourseDataBx from './ResourseDataBx';
import TaskDataBx from './TaskDataBx';

export default function ItemDataBox(props: any) {
  const name = props?.name;
  const isCust = name === 'customerId' || name === 'supplierId';
  const isEmpl = name === 'employeeId' || name === 'departmentId';
  const isReso = name === 'resourseId';
  const isTask = name === 'contractId';
  if (isCust) return <CustomerDataBx {...props}></CustomerDataBx>;
  if (isEmpl) return <EmployeeDataBx {...props}></EmployeeDataBx>;
  if (isReso) return <ResourseDataBx {...props}></ResourseDataBx>;
  if (isTask) return <TaskDataBx {...props}></TaskDataBx>;
  return <Paper style={{ height: props?.height }}></Paper>;
}
