import { gql } from '@apollo/client';

export default gql`
  query getMSGTemplates {
    getMSGTemplates {
      ok
      error
      data {
        _id
        branch
        autoNo
        name
        nameAr
        msgtype
        userId
        subject
        body

        createdAt
        updatedAt
      }
    }
  }
`;
