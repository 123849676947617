/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId } from '../../common';
import {
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatter,
  moneyFormat,
  opTypeFormatter,
} from '../../Shared/colorFormat';
import { Box, Typography } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { SearchTable } from '../../components';
import getSuppliersRaseeds from '../../graphql/query/getSuppliersRaseeds';
import { TableComponent } from '../../Shared/TableComponent';
import SupplierReportContext from '../../contexts/supplierReport';

export default function SupplierAllReport({
  isRTL,
  words,
  start,
  end,
  theme,
  drawerWidth,
}: any) {
  const [rows, setRows] = useState([]);

  const col = getColumns({ isRTL, words });

  const [columns] = useState([
    col.supplier,
    {
      name: 'supplierPhone',
      title: isRTL ? 'رقم الهاتف' : 'Phone',
    },
    {
      name: 'oldraseed',
      title: isRTL ? 'رصيد افتتاحي' : 'Opening Balance',
    },
    {
      name: 'nowraseed',
      title: isRTL ? 'رصيد الفترة' : 'Period Balance',
    },
    {
      name: 'allraseed',
      title: isRTL ? 'الرصيد الاجمالي' : 'Total Balance',
    },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.supplier.name, togglingEnabled: false },
  ]);

  const [getRaseeds, raseedsData]: any = useLazyQuery(getSuppliersRaseeds, {
    fetchPolicy: 'cache-and-network',
  });

  const { height } = useWindowDimensions();

  const {
    state: { sort },
    dispatch,
  } = useContext(SupplierReportContext);

  useEffect(() => {
    const slsData = raseedsData?.data?.['getSuppliersRaseeds']?.data;
    const ndata = slsData ? JSON.parse(slsData) : [];
    let rdata = ndata;

    if (ndata && ndata.length > 0) {
      rdata = ndata.map((rd: any) => {
        return {
          ...rd,
          oldraseed: -rd.oldraseed,
          nowraseed: -rd.nowraseed,
          allraseed: -rd.oldraseed + -rd.nowraseed,
        };
      });
    }

    setRows(rdata);
  }, [raseedsData]);

  useEffect(() => {
    if (start && end) {
      getRaseeds({
        variables: {
          start: start ? start.setHours(0, 0, 0, 0) : undefined,
          end: end
            ? end.setHours(23, 59, 59, 999)
            : new Date().setHours(23, 59, 59, 999),
        },
      });
    }
  }, [start, end]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `finance-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: 'oldraseed', type: 'sum' },
    { columnName: 'nowraseed', type: 'sum' },
    { columnName: 'allraseed', type: 'sum' },
  ];

  const groupSummaryItems = [
    {
      columnName: col.opDocNo.name,
      type: 'count',
      alignByColumn: true,
    },
    {
      columnName: 'oldraseed',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: 'nowraseed',
      type: 'sum',
      alignByColumn: true,
    },
    {
      columnName: 'allraseed',
      type: 'sum',
      alignByColumn: true,
    },
  ];

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          zIndex: 111,
          left: isRTL ? undefined : drawerWidth + 225,
          right: isRTL ? drawerWidth + 225 : undefined,
          bottom: 2,
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: '#403795' }}>
          {isRTL ? 'الرصيد' : 'Balance'}:{' '}
          {moneyFormat(rows?.[rows?.length - 1]?.rased)}
        </Typography>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        <SummaryState
          totalItems={totalSummaryItems}
          groupItems={groupSummaryItems}
        />
        <IntegratedSummary />
        <IntegratedSorting />
        <SearchState />
        <IntegratedFiltering />
        <VirtualTable
          height={height - 100}
          tableComponent={TableComponent}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={40}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[col.rased.name]}
          onHiddenColumnNamesChange={(hcs: string[]) => {
            const all = [...columns];
            const newcol = all.filter((a: any) => !hcs.includes(a.name));
            newcol.sort((a: any, b: any) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );
          }}
        />
        <DataTypeProvider
          for={['opTime']}
          formatterComponent={createdAtFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={[
            'credit',
            'debit',
            'rased',
            'oldraseed',
            'nowraseed',
            'allraseed',
          ]}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['opType']}
          formatterComponent={opTypeFormatter}
        ></DataTypeProvider>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        <TableSummaryRow
          messages={{
            sum: isRTL ? 'المجموع' : 'Total',
            count: isRTL ? 'العدد' : 'Count',
          }}
        ></TableSummaryRow>
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </>
  );
}
