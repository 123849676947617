import { Button, CircularProgress, Typography } from '@material-ui/core';

export default function ButtonPayInvoice({
  row,
  payInvoice,
  selected,
  isRTL,
}: any) {
  const isloading = selected === row._id;
  if (row?.isPaid === true) {
    return (
      <span
        style={{
          padding: 5,
          color: '#25D366',
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        ✔
      </span>
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={async () => await payInvoice(row._id)}
      style={{ width: 100 }}
    >
      <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
        {isRTL ? 'دفع الفاتورة' : 'Pay Invoice'}
      </Typography>
      {isloading && (
        <CircularProgress
          style={{ position: 'absolute', left: 5 }}
          color="primary"
          size={14}
        />
      )}
    </Button>
  );
}
