/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box } from '@material-ui/core';
import { palettes } from '../../themes/palettes';

const size = 25;

const GenThemePopup = ({ themeId, setThemeId }) => {
  const renderColors = (pallet: any) => {
    return (
      <Box display="flex">
        <Box
          style={{
            width: size,
            height: size,
            backgroundColor: pallet.primary.main,
          }}
        ></Box>
        <Box
          style={{
            width: size,
            height: size,
            backgroundColor: pallet.secondary.main,
          }}
        ></Box>
      </Box>
    );
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {palettes.map((pal: any, index: number) => {
        return (
          <Box
            key={index}
            style={{
              cursor: 'pointer',
              padding: 5,
              backgroundColor: index === themeId ? 'skyblue' : '#fff',
            }}
            onClick={() => setThemeId(index)}
          >
            {renderColors(pal.palette)}
          </Box>
        );
      })}
    </Box>
  );
};

export default GenThemePopup;
