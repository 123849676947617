export const invoiceData = {
  docNo: 'SINV-00152',
  eventNo: 'EVNT-01121',
  time: '2022-08-07T05:00:00.406Z',
  customerName: 'Mr. Salem Ahmad',
  customerNameAr: 'السيد سالم علي',
  customerPhone: '97455445544',
  total: 765,
  discount: 50,
  percent: 7.6,
  amount: 715,
  amountPaid: 500,
  amountPaid2: 215,
  items: [
    {
      no: 1,
      name: 'ERTH DAY FRESH HYDRATEN BOX',
      nameAr: 'كيفين مورفي علاج للشعر شامبر و ماسك',
      desc: '',
      itemprice: 40,
      itemqty: 3,
      itemtotal: 120,
      unit: 'pic',
      fullname: `ERTH DAY FRESH HYDRATEN BOX
كيفين مورفي علاج للشعر شامبر و ماسك`,
    },
    {
      no: 2,
      name: 'PROXIDE 40 VOLUME',
      nameAr: 'كيفين مورفي صبغة شعر بدون امونيا',
      desc: '',
      itemprice: 450,
      itemqty: 1,
      itemtotal: 450,
      unit: 'pic',
      fullname: `PROXIDE 40 VOLUME
كيفين مورفي صبغة شعر بدون امونيا`,
    },
    {
      no: 3,
      name: 'KEVIN MURPHY BLOW DRY EVER BOUNCE',
      nameAr: 'كيفين مورفي علاج للشعر شامبر و ماسك',
      desc: null,
      itemprice: 195,
      itemqty: 1,
      itemtotal: 195,
      unit: null,
      fullname: `KEVIN MURPHY BLOW DRY EVER BOUNCE
كيفين مورفي علاج للشعر شامبر و ماسك`,
    },
  ],
  tafkeet: 'فقط سبعمائة وخمسة عشر ريال قطري لا غير',
  user: 'Ahmad Bin Naser',
};

export const purchaseData = {
  docNo: 'SINV-00152',
  eventNo: 'EVNT-01121',
  time: '2022-08-07T05:00:00.406Z',
  supplierName: 'Mr. Salem Ahmad',
  supplierNameAr: 'السيد سالم علي',
  supplierPhone: '97455445544',
  total: 765,
  discount: 50,
  amount: 715,
  items: [
    {
      no: 1,
      name: 'ERTH DAY FRESH HYDRATEN BOX',
      nameAr: 'كيفين مورفي علاج للشعر شامبر و ماسك',
      desc: '',
      itemprice: 40,
      itemqty: 3,
      itemtotal: 120,
      unit: 'pic',
      fullname: `ERTH DAY FRESH HYDRATEN BOX
كيفين مورفي علاج للشعر شامبر و ماسك`,
    },
    {
      no: 2,
      name: 'PROXIDE 40 VOLUME',
      nameAr: 'كيفين مورفي صبغة شعر بدون امونيا',
      desc: '',
      itemprice: 450,
      itemqty: 1,
      itemtotal: 450,
      unit: 'pic',
      fullname: `PROXIDE 40 VOLUME
كيفين مورفي صبغة شعر بدون امونيا`,
    },
    {
      no: 3,
      name: 'KEVIN MURPHY BLOW DRY EVER BOUNCE',
      nameAr: 'كيفين مورفي علاج للشعر شامبر و ماسك',
      desc: null,
      itemprice: 195,
      itemqty: 1,
      itemtotal: 195,
      unit: null,
      fullname: `KEVIN MURPHY BLOW DRY EVER BOUNCE
كيفين مورفي علاج للشعر شامبر و ماسك`,
    },
  ],
  tafkeet: 'فقط سبعمائة وخمسة عشر ريال قطري لا غير',
  user: 'Ahmad Bin Naser',
};
export const receiptData = {
  no: 'CREC-00210',
  invoiceno: 'SINV-00192',
  time: '2022-10-22T05:00:00.823Z',
  customerName: '25588552',
  customerNameAr: '25588552',
  customerPhone: '97425588552',
  resourseName: null,
  resourseNameAr: null,
  employeeName: 'Samar',
  employeeNameAr: 'سمر',
  periodfrom: null,
  isMonthly: null,
  periodto: null,
  refNo: 'SINV-00192',
  title: null,
  desc: null,
  amount: 250,
  isRTL: true,
  chequeBank: 'QIB',
  chequeNo: '4450211',
  chequeDate: '22/01/2023',
  balance: 0,
  isCash: false,
  isCheque: true,
  tafkeet: 'فقط سبعمائة وخمسة عشر ريال قطري لا غير',
  amt: '250 - 40 = 210',
};
export const paymentData = {
  no: 'CREC-00210',
  invoiceno: 'SINV-00192',
  time: '2022-10-22T05:00:00.823Z',
  supplierName: '25588552',
  supplierNameAr: '25588552',
  supplierPhone: '97425588552',
  resourseName: null,
  resourseNameAr: null,
  employeeName: 'Samar',
  employeeNameAr: 'سمر',
  periodfrom: null,
  isMonthly: null,
  periodto: null,
  refNo: 'SINV-00192',
  title: null,
  desc: null,
  amount: 250,
  isRTL: true,
  chequeBank: 'QIB',
  chequeNo: '4450211',
  chequeDate: '22/01/2023',
  balance: 0,
  isCash: false,
  isCheque: true,
  tafkeet: 'فقط سبعمائة وخمسة عشر ريال قطري لا غير',
};
export const contractData = {
  amount: 3000,
  customerAddress: 'New street 34 B-12',
  customerArea: 'Duhail',
  customerDriver: 'Ahmad Saad',
  customerLicenseDate: '2022-08-07T05:00:00.406Z',
  customerLicenseNo: '444554778876',
  customerName: '556556565',
  customerNational: 'Syria',
  customerNationalDate: '2022-08-07T05:00:00.406Z',
  customerNationalNo: '34242342342344',
  customerPhone: '974556556565',
  end: '2022-08-07T05:00:00.406Z',
  isRTL: true,
  kmin: 1256,
  kmout: 1100,
  km: 146,
  no: 'TASK-222',
  notes:
    'فقط سبعمائة وخمسة عشر ريال قطري وخمسة عشر ريال قطريوخمسة عشر ريال قطري  لا غير',
  price: 175,
  resourseInsurance: 'Full',
  resourseName: 'Honda',
  resoursePlate: '112521',
  resovalue: null,
  start: '2022-09-07T05:00:00.406Z',
  totalpaid: 55,
  balance: 100,
  daily: 55,
  duration: 'monthly',
  tafkeet: 'فقط سبعمائة وخمسة عشر ريال قطري لا غير',
  deposit: 2500,
  paragraphs: [
    {
      id: 'oWhY',
      title: 'مدة العم',
      subitems: [
        {
          id: 'R0jw',
          title: '00  يوم عمل من تاريخ التوقيع',
          desc: '00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع 00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع 00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع',
        },
      ],
    },
    {
      id: 'NKBh',
      title: 'السعر ',
      subitems: [
        {
          id: 'lLYI',
          title: '000 ريال قطري (ريال قطري).',
          desc: '00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع',
        },
      ],
    },
    {
      id: 'vvLE',
      title: 'طريقة الدفع',
      subitems: [
        {
          id: 'njXm',
          title: 'الدفعة الاولى 60 % عند التوقيع.',
          desc: '00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع',
        },
        {
          id: 'fPoG',
          title: 'الدفعة الثالثة 30% عند الانتهاء من الدهان.',
          desc: '00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع',
        },
        {
          id: 'R2fP',
          title: 'الدفعة الرابعة 10% عند تركيب الباب الرئيسي والتسليم.  ',
          desc: '00 يوم عمل من تاريخ التوقيعيوم عمل من تاريخ التوقيع  يوم عمل من تاريخ التوقيع',
        },
      ],
    },
  ],
  address:
    'رقم المنطقة (00) – رقم الشارع (0000) – رقم المبنى : 00 – إسم الشارع',
  desc: 'مساحة الأرض الكليه ( 000 )  متر مربع – مساحة البناء (000 ) متر مربع ، وتشمل',
  title: 'فيلا ارضي واول وبنت هاوس',
  printdate: '2022-08-01T05:00:00.406Z',
  printtitle: 'عقد مقاولة شقة جديدة',
  companyname: 'شركو انوفيشن للتجارة',
  companytel1: '44662233',
  companyfax: '44558855',
  companyaddress: 'العزيزية بناء رقم 5 الطابق الاول مكتب 33',
};
export const customerreportData = {
  customerName: '54654654654',
  customerPhone: '97454654654654',
  customerAddress: '',
  customerArea: '',
  date: '2022-09-07T05:00:00.406Z',
  end: '2022-10-07T05:00:00.406Z',
  isRTL: true,
  rased: 1995,
  start: '2022-09-08T05:00:00.406Z',
  items: [
    {
      accName: 'Accounts Receivable',
      accNameAr: 'المدينون',
      accType: 1,
      amount: 1130,
      categoryId: null,
      categoryName: null,
      categoryNameAr: null,
      contractId: '62d7e90289f0ffc7b3c19f56',
      contractName: 'emad - اسماء',
      contractNameAr: 'emad - اسماء',
      createdAt: '2022-07-20T12:01:01.057Z',
      credit: null,
      customerId: '62a896838af7584e0479373d',
      customerName: 'emad',
      customerNameAr: 'emad',
      debit: 1130,
      departmentId: '6238d4c9a11c0d0016a7371a',
      departmentName: 'قسم التسويق',
      departmentNameAr: 'قسم التسويق',
      desc: 'khedmeh',
      employeeId: '6238d4b5a11c0d0016a73719',
      employeeName: 'Asmaa',
      employeeNameAr: 'اسماء',
      eventId: null,
      itemId: '62a21f2f3ceca60460e70bb5',
      itemName: 'khedmeh',
      itemNameAr: 'khedmeh',
      itemPrice: 565,
      kaidType: 1,
      opDocNo: 'SINV-107',
      opId: '62d7ee7d89f0ffc7b3c1a007',
      opTime: '2022-07-20T12:00:01.023Z',
      opType: 10,
      opaccName: 'Sales Income',
      opaccNameAr: 'ايراد المبيعات',
      operation: 'فاتورة',
      projectId: null,
      projectName: null,
      projectNameAr: null,
      qty: 2,
      rased: 1130,
      refNo: 'SINV-107',
      refType: 10,
      resourseId: '62d2d85cef7d5ab3b2d4d9b5',
      resourseName: 'sayara 22',
      resourseNameAr: 'sayara 22',
      updatedAt: '2022-07-22T11:19:54.210Z',
    },
    {
      accName: 'Accounts Receivable',
      accNameAr: 'المدينون',
      accType: 1,
      amount: -1130,
      categoryId: null,
      categoryName: null,
      categoryNameAr: null,
      contractId: '62d7e90289f0ffc7b3c19f56',
      contractName: 'emad - اسماء',
      contractNameAr: 'emad - اسماء',
      createdAt: '2022-07-20T12:01:01.351Z',
      credit: 1130,
      customerId: '62a896838af7584e0479373d',
      customerName: 'emad',
      customerNameAr: 'emad',
      debit: null,
      departmentId: '6238d4c9a11c0d0016a7371a',
      departmentName: 'قسم التسويق',
      departmentNameAr: 'قسم التسويق',
      desc: null,
      employeeId: '6238d4b5a11c0d0016a73719',
      employeeName: 'Asmaa',
      employeeNameAr: 'اسماء',
      eventId: null,
      itemId: null,
      itemName: null,
      itemNameAr: null,
      itemPrice: null,
      kaidType: 2,
      opDocNo: 'CREC-109',
      opId: '62d7ee7d89f0ffc7b3c1a016',
      opTime: '2022-07-20T12:00:01.023Z',
      opType: 14,
      opaccName: 'Cash',
      opaccNameAr: 'الصندوق',
      operation: 'سند قبض',
      projectId: null,
      projectName: null,
      projectNameAr: null,
      qty: null,
      rased: 0,
      refNo: 'SINV-107',
      refType: 10,
      resourseId: '62d2d85cef7d5ab3b2d4d9b5',
      resourseName: 'sayara 22',
      resourseNameAr: 'sayara 22',
      updatedAt: '2022-07-22T11:19:54.210Z',
      userId: '6238d0c7a11c0d0016a736f3',
    },
  ],
};
export const profitreportData = {};
export const financereportData = {};
export const stockreportData = {};
export const supplierreportData = {};
export const expensesreportData = {};

export const formData = {
  absent: null,
  activeUser: null,
  address: null,
  amount: null,
  autoNo: 2429,
  bonos: null,
  contactId: '628e17042829026168bd97ca',
  contactName: 'اوغست بيوتي',
  contactNameAr: 'اوغست بيوتي',
  contractId: null,
  contractName: null,
  contractNameAr: null,
  createUser: null,
  createdAt: '2023-01-25T07:23:33.732Z',
  customerId: null,
  customerName: null,
  customerNameAr: null,
  customerPhone: null,
  dcategoryId: '63ca6b7cbd58003b9b46f551',
  dcategoryName: 'Letter',
  dcategoryNameAr: 'خطاب',
  departmentColor: null,
  departmentId: null,
  departmentName: null,
  departmentNameAr: null,
  desc: '{"blocks":[{"key":"fdqqh","text":"الرجاء التكرم بقبول استقالتي من العمل من شركة  .............2Ahmad...........................  لأسباب خاصة بي مع العلم أني لا أطالب الشركة بأي مستحقات بعد إستلام مستحقاتي كاملة من طرفكم و يكون آخر يوم عمل لديكم ...........2engineer............. وذلك للعلم دون أدنى مسؤولية على الشركة. ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":285,"style":"fontfamily-Arial"},{"offset":0,"length":285,"style":"BOLD"},{"offset":0,"length":285,"style":"fontsize-18"}],"entityRanges":[],"data":{}},{"key":"2smcn","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"21no8","text":"وتفضلوا بقبول فائق الاحترام ،،،","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":31,"style":"BOLD"},{"offset":0,"length":31,"style":"fontsize-18"}],"entityRanges":[],"data":{}},{"key":"616rq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"11v8k","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"ar6q7","text":"                                                                                  مقدمه /   ............................................  ","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":138,"style":"BOLD"},{"offset":0,"length":138,"style":"fontsize-18"}],"entityRanges":[],"data":{}},{"key":"37ibo","text":"                                                                                  بطاقة شخصية رقم:..................................","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":132,"style":"BOLD"},{"offset":0,"length":132,"style":"fontsize-18"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
  docNo: 'LTRG-3',
  employeeColor: null,
  employeeId: null,
  employeeName: null,
  employeeNameAr: null,
  employeePhone: null,
  endDate: null,
  exDate: null,
  gref: 'letter',
  groupId: null,
  houseno: null,
  id: null,
  invoiceId: null,
  invoiced: null,
  link: null,
  locationLink: null,
  nots: null,
  opType: 1,
  overtime: null,
  paid: null,
  priority: null,
  projectColor: null,
  projectId: null,
  projectName: null,
  projectNameAr: null,
  rRule: null,
  refNo: null,
  refType: null,
  reminder: null,
  resourseColor: null,
  resourseId: null,
  resourseName: null,
  resourseNameAr: null,
  retype2Color: null,
  retype2Id: null,
  retype2Name: null,
  retype2NameAr: null,
  retypeColor: null,
  retypeId: null,
  retypeName: null,
  retypeNameAr: null,
  sent: null,
  signatureImage: null,
  signatureName: null,
  smss: null,
  startDate: null,
  status: null,
  temp: null,
  time: '2023-01-25T07:21:59.271Z',
  title: 'الموضــــــوع / لا مانع من تغيير جهة العمل',
  updatedAt: '2023-01-25T07:43:30.476Z',
  userId: '6238d0c7a11c0d0016a736f3',
  _id: '63d0d8f547c82689ece198ec',
};
