import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useState } from 'react';
import { userroles } from '../../../constants';
import { createFinance, getLandingChartData } from '../../../../graphql';
import PopupReceiptAdvance from '../../../../pubups/PopupReceiptAdvance';
import IconView from '../IconView';

function RecAdvIcon({ isRTL, theme, color, height, company }) {
  const [openRecAd, setOpenRecAd] = useState(false);

  const refresQuery = {
    refetchQueries: [{ query: getLandingChartData }],
  };

  const [addFinance] = useMutation(createFinance, refresQuery);

  return (
    <Box>
      {userroles?.receiptsadvance?.add && (
        <>
          <IconView
            color={color ? color : theme.palette.primary.main}
            icon="callaction"
            title={isRTL ? 'قبض سلفة' : 'Advanced Receipt'}
            height={height}
            onOpen={() => setOpenRecAd(true)}
          ></IconView>

          <PopupReceiptAdvance
            open={openRecAd}
            onClose={() => setOpenRecAd(false)}
            isNew={true}
            addAction={addFinance}
            editAction={() => null}
            theme={theme}
            company={company}
          ></PopupReceiptAdvance>
        </>
      )}
    </Box>
  );
}

export default RecAdvIcon;
