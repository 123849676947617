import { Box, colors, Grid, Paper } from '@material-ui/core';
import { moneyFormat, rCellMain } from '../../common/helpers';
import IncomeChart from '../charts/IncomeChart';

export default function IncomeDataBx({ row, theme, isRTL, height = 200 }) {
  const scolor = colors.blue[400];
  const ecolor = colors.red[500];
  const sales = (row?.totalinvoiced || 0) - (row?.totalDiscount || 0);
  const expenses =
    (row?.toatlExpenses || 0) +
    (row?.toatlProdExpenses || 0) +
    (row?.totalExpPetty || 0) +
    (row?.toatlExpPayable || 0);
  const balance = sales - expenses;
  return (
    <Paper style={{ height }}>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <IncomeChart
            scolor={scolor}
            ecolor={ecolor}
            row={row}
            theme={theme}
            height={height}
          ></IncomeChart>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              {rCellMain(isRTL ? 'المبيعات' : 'Net Sales', scolor)}
            </Grid>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              {rCellMain(moneyFormat(sales || 0), scolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(isRTL ? 'المصروفات' : 'Net Expenses', ecolor)}
            </Grid>
            <Grid item xs={6}>
              {rCellMain(moneyFormat(expenses || 0), ecolor)}
            </Grid>

            <Grid item xs={6}>
              <Box style={{ marginTop: 30 }}>
                {rCellMain(isRTL ? 'صافي الربح' : 'Income', '#333')}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: 30 }}>
                {rCellMain(moneyFormat(balance || 0), '#333')}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
