import { gql } from '@apollo/client';

export default gql`
  query getMyOutbox($skip: Int, $limit: Int) {
    getMyOutbox(skip: $skip, limit: $limit) {
      ok
      error
      data {
        _id
        branch
        autoNo
        docNo
        indx

        outstar
        instar

        prevprocId
        inbox
        inboxName
        outbox
        outboxName
        activeUser

        start
        end

        title
        desc
        priority

        pitemId
        pitemName
        pitemNameAr

        pcategoryId
        pcategoryName
        pcategoryNameAr

        opId
        opType
        opTime
        opDocNo
        opDoc

        stepId
        stepTime
        stepName
        stepNameAr
        stepDesc
        stepUser
        stepUserName
        stepColor
        steps
        actions

        attachments
        isDone

        ismemorized

        departmentId
        departmentName
        departmentNameAr
        departmentColor

        employeeId
        employeeName
        employeeNameAr
        employeeColor
        employeePhone

        resourseId
        resourseName
        resourseNameAr
        resourseColor

        customerId
        customerName
        customerNameAr

        supplierId
        supplierName
        supplierNameAr

        contactId
        contactName
        contactNameAr
        contactPhone

        note
        userId

        createdAt
        updatedAt
      }
    }
  }
`;
