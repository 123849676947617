/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import PopupLayout from '../shared/PopupLayout';
import FormBuilder from '../generator/form/FormBuilder';

const PopupFormTemplate = ({
  open,
  onClose,
  temp,
  setTemp,
  company,
  isRTL,
  fields,
  words,
  variables,
  setVariables,
}: any) => {
  const [header, setHeader] = useState(true);
  const [footer, setFooter] = useState(true);

  const title = 'Form Template';
  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onClose}
      title={title}
      onSubmit={() => null}
      canceltitle={isRTL ? 'تم' : 'Done'}
      onlyclose
      maxWidth="xl"
      alrt={{ show: false, msg: '', type: undefined }}
      mb={0}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={header}
                onChange={() => setHeader(!header)}
                name="bold"
                color="primary"
              />
            }
            label="Header"
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={footer}
                onChange={() => setFooter(!footer)}
                name="bold"
                color="primary"
              />
            }
            label="Footer"
          />
        </Grid>
        <Grid item xs={12}>
          <FormBuilder
            rows={temp}
            setRows={setTemp}
            variables={variables}
            setVariables={setVariables}
            company={company}
            size={'A4'}
            header={header}
            footer={footer}
            isRTL={isRTL}
            fields={fields}
            words={words}
          ></FormBuilder>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupFormTemplate;
