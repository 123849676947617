import { gql } from '@apollo/client';

export default gql`
  query getBranchPrintTemplates($branch: String) {
    getBranchPrintTemplates(branch: $branch) {
      ok
      error
      data {
        _id
        branch
        name
        type
        template
        isdefault
        bgimages
        size
        header
        footer
        userId
        note

        createdAt
        updatedAt
      }
    }
  }
`;
