import { Button, Paper, Box, Grid, Typography } from '@material-ui/core';
import { useState } from 'react';
import UploadJson, { downloadJSONFile } from '../components/UploadJson';
import PopupLandingBuilder from './PopupLandingBuilder';
import { tempoptions } from './renders';

function EmployeeTemplate({
  isRTL,
  theme,
  template,
  setTemplate,
  company,
  words,
  name,
}) {
  const [open, setOpen]: any = useState(false);
  return (
    <Paper elevation={4} style={{ marginBottom: 20 }}>
      <Box style={{ padding: 20 }}>
        <Typography variant="h6">{name}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <UploadJson setValue={setTemplate}></UploadJson>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              onClick={() => downloadJSONFile(template, name)}
              fullWidth
            >
              Download
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <PopupLandingBuilder
        options={tempoptions}
        open={open}
        onClose={() => setOpen(false)}
        template={template}
        setTemplate={setTemplate}
        isRTL={isRTL}
        theme={theme}
        title="Edit"
        company={company}
        words={words}
      ></PopupLandingBuilder>
    </Paper>
  );
}

export default EmployeeTemplate;
