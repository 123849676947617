import { Box } from '@material-ui/core';
import { userroles } from '../../../constants';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import MonthsEmpChart from '../MonthsEmpChart';

function MonthDepartSales({ isRTL, theme, systemcolor, color, height }) {
  const { salesMonth } = useLandingChart(null);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {salesMonth && userroles?.appointments?.admin && (
        <MonthsEmpChart
          data={salesMonth}
          isRTL={isRTL}
          color={clr}
          department={true}
          title={
            isRTL ? 'مبيعات هذا الشهر بحسب القسم' : 'Month Sales - Department'
          }
          height={height}
        ></MonthsEmpChart>
      )}
    </Box>
  );
}

export default MonthDepartSales;
