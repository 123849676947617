import { Box, Button, Typography } from '@material-ui/core';
const SaveButton = ({ onExecute }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        textAlign: 'center',
      }}
    >
      <Box m={1}>
        <Button
          style={{ width: 85, height: 36 }}
          variant="contained"
          onClick={onExecute}
          color="primary"
        >
          <Typography>{'Save'}</Typography>
        </Button>
      </Box>
    </div>
  );
};

const CancelButton = () => <div></div>;
const DeleteButton = () => <div></div>;

const commandComponents: any = {
  saveButton: SaveButton,
  deleteButton: DeleteButton,
  cancelButton: CancelButton,
};

const CommandAppointment = ({ id, onExecute }: any) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

export default CommandAppointment;
