/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { CalenderLocal } from '../components';
import { getDateDayTimeFormat } from '../Shared/colorFormat';

const PopupExtendDate = ({
  open,
  onClose,
  theme,
  isRTL,
  addNewEvent,
  title,
  evtime,
  setEvtime,
}: any) => {
  const onFormClose = () => {
    onClose();
  };

  const onSubmit = async () => {
    await addNewEvent();
    onFormClose();
  };

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onFormClose}
      title={title}
      onSubmit={onSubmit}
      theme={theme}
      alrt={{}}
      savetitle={isRTL ? 'متابعة' : 'Proceed'}
      maxWidth="xl"
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            display="flex"
            style={{
              backgroundColor: '#fff',
              minWidth: 400,
              flexDirection: 'column',
            }}
          >
            <Typography
              style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}
            >
              {getDateDayTimeFormat(evtime, isRTL)}
            </Typography>

            <Box display={'flex'}>
              <CalenderLocal
                isRTL={isRTL}
                label={isRTL ? 'التاريخ' : 'Date'}
                value={evtime}
                onChange={setEvtime}
                format="dd/MM/yyyy - hh:mm"
                time
                width={160}
              ></CalenderLocal>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PopupLayout>
  );
};
export default PopupExtendDate;
