import { Grid, Paper } from '@material-ui/core';
import RolesItemView from './RolesItemView';

export default function NewRolesView({
  isRTL,
  newroles,
  setItemValue,
  isSuperAdmin,
}: any) {
  return (
    <Paper
      elevation={3}
      style={{
        height: 400,
        overflow: 'auto',
        padding: 15,
        marginTop: 15,
      }}
    >
      <Grid container spacing={0}>
        {newroles &&
          newroles?.length > 0 &&
          newroles
            ?.filter((nr: any) => (isSuperAdmin ? true : !nr.hide))
            ?.map((item: any, index: any) => {
              return (
                <RolesItemView
                  setItemValue={setItemValue}
                  index={index}
                  isRTL={isRTL}
                  item={item}
                  bgcolor={index % 2 === 0 ? '#eee' : undefined}
                ></RolesItemView>
              );
            })}
      </Grid>
    </Paper>
  );
}
