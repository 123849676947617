import DatePicker from 'react-datepicker';
import { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../common/style.css';
import { Box, Button, Typography } from '@material-ui/core';
import { forwardRef } from 'react';
import en from 'date-fns/locale/en-GB';
import ar from 'date-fns/locale/ar-SA';

const CalenderLocal = ({
  isRTL,
  label,
  period,
  value,
  onChange,
  time,
  minDate,
  maxDate,
  minTime,
  maxTime,
  timeIntervals,
  mt = 15,
  height = 34,
  fontSize = 14,
  fontWeight,
}: any) => {
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    );
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <Button
      style={{
        height,
        marginLeft: 5,
        marginRight: 5,
      }}
      variant="outlined"
      onClick={onClick}
      ref={ref}
    >
      <Typography style={{ fontSize, fontWeight }}>
        {period ? period : value ? value : 'dd/mm/yyyy'}
      </Typography>
    </Button>
  ));
  return (
    <Box
      style={{
        marginTop: mt,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography style={{ fontSize, fontWeight }}>{label}</Typography>
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={onChange}
        locale={isRTL ? ar : en}
        showTimeSelect={time}
        timeIntervals={timeIntervals ? timeIntervals : 30}
        calendarContainer={MyContainer}
        customInput={<ExampleCustomInput />}
        dateFormat={time ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        calendarStartDay={6}
      />
    </Box>
  );
};

export default CalenderLocal;
