import { gql } from '@apollo/client';

export default gql`
  mutation createMSGTemplate(
    $name: String
    $nameAr: String
    $msgtype: Int
    $subject: String
    $body: String
  ) {
    createMSGTemplate(
      name: $name
      nameAr: $nameAr
      msgtype: $msgtype
      subject: $subject
      body: $body
    ) {
      ok
      message
      data
      error
    }
  }
`;
