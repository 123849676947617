import { gql } from '@apollo/client';

export default gql`
  mutation updateFormDoc(
    $_id: String
    $branch: String
    $name: String
    $nameAr: String
    $pname: String
    $pnameAr: String
    $disabled: Boolean
    $additionId: String
    $showOnCal: Boolean
    $opType: Int
    $autoNoType: String
    $autoNoPrefix: String
    $order: Int
    $withitems: Boolean
    $itemTypes: [Int]
    $withkaids: Boolean
    $debitAcc: Int
    $creditAcc: Int
    $fields: String
    $procTemp: String
    $printTempDoc: String
    $formTempDoc: String
    $formTempValues: String
    $printTempReport: String
    $dcategoryId: String
    $dcategoryName: String
    $dcategoryNameAr: String
    $gref: String
    $options: String
    $note: String
  ) {
    updateFormDoc(
      _id: $_id
      branch: $branch
      name: $name
      nameAr: $nameAr
      pname: $pname
      pnameAr: $pnameAr
      disabled: $disabled
      additionId: $additionId
      showOnCal: $showOnCal
      opType: $opType
      autoNoType: $autoNoType
      autoNoPrefix: $autoNoPrefix
      order: $order
      withitems: $withitems
      itemTypes: $itemTypes
      withkaids: $withkaids
      debitAcc: $debitAcc
      creditAcc: $creditAcc
      fields: $fields
      procTemp: $procTemp
      printTempDoc: $printTempDoc
      formTempDoc: $formTempDoc
      formTempValues: $formTempValues
      printTempReport: $printTempReport
      dcategoryId: $dcategoryId
      dcategoryName: $dcategoryName
      dcategoryNameAr: $dcategoryNameAr
      gref: $gref
      options: $options
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
