/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { palettes } from '../../../themes';

export const SelectTheme = ({ isRTL, themeId, onChange }: any) => {
  return (
    <Box
      display="flex"
      style={{
        flexDirection: isRTL ? 'row-reverse' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 40,
        marginRight: 40,
      }}
    >
      <Grid container spacing={2}>
        {palettes.map((item: any, index: any) => {
          const isActive = themeId === index;
          return (
            <Grid item xs={3}>
              <Box
                key={index}
                display="flex"
                style={{
                  flexDirection: isRTL ? 'row-reverse' : 'row',
                  justifyContent: isRTL ? 'flex-end' : 'flex-start',
                  backgroundColor: isActive ? '#7fffd4' : undefined,
                  padding: 3,
                  cursor: 'pointer',
                }}
                onClick={() => onChange(index)}
              >
                <Box
                  display="flex"
                  style={{
                    flexDirection: isRTL ? 'row-reverse' : 'row',
                    height: 22,
                    width: 66,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    display="flex"
                    style={{
                      height: 22,
                      width: 22,
                      backgroundColor: item?.palette?.primary?.main,
                    }}
                  ></Box>
                  <Box
                    style={{
                      height: 22,
                      width: 22,
                      backgroundColor: item?.palette?.secondary?.main,
                    }}
                  ></Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
