/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const initExpensesprodContext = {
  currentViewName: 'Month',
  currentDate: new Date(),
  endDate: new Date(),
  sort: [{ columnName: 'time', direction: 'desc' }],
  hiddenColumnNames: ['userId'],
};

export const expensesprodReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setCurrentViewName':
      return { ...state, currentViewName: action.payload };
    case 'setCurrentDate':
      return { ...state, currentDate: action.payload };
    case 'setEndDate':
      return { ...state, endDate: action.payload };
    case 'setSort':
      return { ...state, sort: action.payload };
    case 'setHiddenColumnNames':
      return { ...state, hiddenColumnNames: action.payload };
    default:
      throw new Error('Unexpected action');
  }
};
