/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import PopupLayout from '../pages/main/PopupLayout';
import { Grid } from '@material-ui/core';
import { TextFieldLocal } from '../components';
import { messageAlert } from '../Shared';
import AutoFieldLocal from '../components/fields/AutoFieldLocal';
import useStepItems from '../hooks/useStepItems';

const PopupStep = ({
  open,
  onClose,
  isRTL,
  words,
  addStep,
  theme,
  saving,
  setSaving,
  procId,
}: any) => {
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [stepvalue, setStepvalue] = useState<any>(null);
  const [desc, setDesc] = useState(null);
  const { stepitems } = useStepItems();

  const onHandleSubmit = async () => {
    if (!stepvalue) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب اختيار نوع الخظوة' : 'Step Item Required'
      );
      return;
    }
    setSaving(true);
    const variables = {
      procId,
      stepId: stepvalue?.id,
      stepName: stepvalue?.name,
      stepNameAr: stepvalue?.nameAr,
      stepColor: stepvalue?.color,
      stepDesc: desc,
    };
    try {
      await addStep({ variables });
      reset();
      onClose();
    } catch (error) {
      console.log('error', error);
      setSaving(false);
    }
  };

  const reset = () => {
    setStepvalue(null);
    setDesc('');
    setSaving(false);
  };

  const onCloseForem = () => {
    reset();
    onClose();
  };

  const title = isRTL ? 'اضافة خطوة' : 'New Step';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForem}
      title={title}
      saving={saving}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
    >
      <Grid container spacing={2} style={{ minWidth: 500 }}>
        <Grid item xs={8}>
          <AutoFieldLocal
            Required
            name="procitem"
            title={isRTL ? 'الخطوة' : 'Step'}
            words={words}
            options={stepitems.filter((st: any) => !st.auto)}
            value={stepvalue}
            setSelectValue={setStepvalue}
            isRTL={isRTL}
            fullWidth
            nosort
            mb={-7}
          ></AutoFieldLocal>
        </Grid>
        <Grid item xs={12}>
          <TextFieldLocal
            name="desc"
            label={isRTL ? 'ملاحظات' : 'Notes'}
            value={desc}
            onChange={(e: any) => {
              const value = e.target.value;
              setDesc(value);
            }}
            multiline
            rowsMax={3}
            rows={3}
            fullWidth
            mb={0}
          />
        </Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupStep;
