import { Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTemplate, useWindowDimensions } from '../../../../hooks';
import useLandingChart from '../../../../hooks/useLandingChart';
import { userroles } from '../../../constants';
import { qtyFormat, getColor } from '../../common/helpers';
import InfoBox from '../InfoBox';

function TodayTotalEvents({
  isRTL,
  theme,
  dark,
  color,
  systemcolor,
  height,
  employeeId = null,
  isEmployee = false,
}) {
  const { eventsTodayCount } = useLandingChart(employeeId);
  const clr = getColor({ theme, color, systemcolor });
  const { tempwords } = useTemplate();
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  return (
    <Box>
      {(isEmployee || userroles?.appointments?.admin) && (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(isMobile ? '/mobcalendar' : '/calendar')}
        >
          <InfoBox
            title={
              isRTL
                ? `${tempwords?.appointments} اليوم`
                : `${tempwords?.appointments} Today`
            }
            desc={''}
            value={qtyFormat(eventsTodayCount)}
            icon="sales"
            color={clr}
            dark={dark}
            height={height}
          ></InfoBox>
        </Box>
      )}
    </Box>
  );
}

export default TodayTotalEvents;
