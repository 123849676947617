import { useCategories, useProducts, useServices } from '../../../hooks';
import ProductsBody from './ProductsBody';

export default function ProductsView({
  height,
  width,
  tempoptions,
  isRTL,
  theme,
  selected,
}) {
  const { prodcats, servcats } = useCategories();
  const { products } = useProducts();
  const { services } = useServices();
  const categories = [...prodcats, ...servcats].filter(
    (cat: any) => cat?.sectionId === selected?._id
  );
  return (
    <ProductsBody
      prodcats={categories}
      height={height}
      width={width}
      products={[...products, ...services]}
      isRTL={isRTL}
      theme={theme}
      selected={selected}
    ></ProductsBody>
  );
}
