import { Box, Typography } from '@material-ui/core';
import { appversion } from '../../constants';

function LandingFooter() {
  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box style={{ margin: 30 }}>
        <Typography component="span" style={{ fontSize: 22, color: '#999' }}>
          JADWAL
        </Typography>
        <Typography
          component="span"
          style={{
            fontSize: 12,
            color: '#999',
            marginLeft: 2,
            marginRight: 2,
            marginTop: 6,
          }}
        >
          {appversion}
        </Typography>
      </Box>
    </Box>
  );
}

export default LandingFooter;
