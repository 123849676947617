/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  GroupingState,
  SummaryState,
  IntegratedGrouping,
  IntegratedSummary,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
  ExportPanel,
  TableColumnVisibility,
  ColumnChooser,
  TableGroupRow,
  TableSummaryRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, userroles } from '../../common';
import {
  absentFormatter,
  covertToTimeDateDigit,
  createdAtFormatter,
  currencyFormatter,
  dateTimeFormatter,
  generalStatusFormatter,
  moneyFormat,
} from '../../Shared/colorFormat';
import { Box, fade, Typography, withStyles } from '@material-ui/core';
import { getReportEvents } from '../../graphql';
import { useLazyQuery } from '@apollo/client';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import { getColumns } from '../../common/columns';
import { groupList } from '../../constants/reports';
import EventsReportContext from '../../contexts/eventsreport';
import { useTemplate } from '../../hooks';
import useEmployees from '../../hooks/useEmployees';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { SearchTable } from '../../components';
import FilterSelectMulti from '../../Shared/FilterSelectMulti';
import _ from 'lodash';
import useTasks from '../../hooks/useTasks';
import { ReportGroupBySwitcher } from '../components/ReportGroupBySwitcher';

const styles = (theme) => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);
export const TableComponent2 = withStyles(
  {},
  { name: 'TableComponent' }
)(TableComponentBase);

export default function EventsReport({
  isRTL,
  words,
  theme,
  start,
  end,
  drawerWidth,
}: any) {
  const [rows, setRows] = useState([]);
  const [total, setTotal]: any = useState(null);

  const col = getColumns({ isRTL, words });

  const { tempoptions, eventoptions } = useTemplate();
  const [columns] = useState(
    [
      col.startDate,
      col.docNo,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      tempoptions?.retype && col.retype,
      tempoptions?.retype2 && col.retype2,
      col.status,
      tempoptions?.contract && col.contract,
      col.customer,
      eventoptions?.bonos && col.bonos,
      eventoptions?.overtime && col.overtime,
      eventoptions?.absent && col.absent,
      userroles?.calreports?.view && col.amount,
    ].filter((x) => x)
  );

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.opTime.name, togglingEnabled: false },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: col.startDate.name, width: 220 },
    { columnName: col.docNo.name, width: 100 },
    { columnName: col.customer.name, width: 180 },
    { columnName: col.contract.name, width: 180 },
    { columnName: col.employee.name, width: 180 },
    { columnName: col.department.name, width: 180 },
    { columnName: col.retype.name, width: 180 },
    { columnName: col.retype2.name, width: 180 },
    { columnName: col.status.name, width: 100 },
    { columnName: col.bonos.name, width: 100 },
    { columnName: col.overtime.name, width: 100 },
    { columnName: col.absent.name, width: 100 },
    { columnName: col.amount.name, width: 100 },
  ]);

  const [getSummary, summaryData]: any = useLazyQuery(getReportEvents, {
    fetchPolicy: 'cache-and-network',
  });
  const { employees } = useEmployees();
  const { tasks } = useTasks();

  const {
    state: {
      servicevalue,
      departvalue,
      projvalue,
      resovalue,
      emplvalue,
      custvalue,
      taskvalue,
      retypevalue,
      retype2value,
      group,
      groupby,
      sumcolumn,
      status,
      sort,
    },
    dispatch,
  } = useContext(EventsReportContext);
  const { height } = useWindowDimensions();

  const setEmplvalueDispatch = (value: any) => {
    dispatch({ type: 'setEmplvalue', payload: value });
  };
  const setGroupbyDispatch = (value: any) => {
    dispatch({ type: 'setGroupby', payload: value });
  };
  const setGroupDispatch = (value: any) => {
    dispatch({ type: 'setGroup', payload: value });
  };
  const setSumcolumnDispatch = (value: any) => {
    dispatch({ type: 'setSumcolumn', payload: value });
  };
  const setTaskvalueDispatch = (value: any) => {
    dispatch({ type: 'setTaskvalue', payload: value });
  };

  useEffect(() => {
    const slsData = summaryData?.data?.['getReportEvents']?.data || [];
    setRows(slsData);
    setTotal(_.sumBy(slsData, 'amount'));
  }, [summaryData]);

  const getIds = (list: any) =>
    list && list?.length > 0 ? list.map((sv: any) => sv._id) : undefined;
  const fetchData = () => {
    const variables = {
      serviceIds: getIds(servicevalue),
      departmentIds: getIds(departvalue),
      employeeIds: getIds(emplvalue),
      resourseIds: getIds(resovalue),
      customerIds: getIds(custvalue),
      contractIds: getIds(taskvalue),
      retypeIds: getIds(retypevalue),
      retype2Ids: getIds(retype2value),
      projectIds: getIds(projvalue),
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end
        ? end.setHours(23, 59, 59, 999)
        : new Date().setHours(23, 59, 59, 999),
      status: status ? status.id : undefined,
    };
    if (start) {
      getSummary({ variables });
    }
  };

  useEffect(() => {
    if (start && end) {
      fetchData();
    }
  }, [
    start,
    end,
    group,
    groupby,
    sumcolumn,
    status,
    departvalue,
    projvalue,
    resovalue,
    emplvalue,
    custvalue,
    taskvalue,
    retypevalue,
    retype2value,
  ]);

  const exporterRef: any = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const now = new Date();
      const name = `events-report-${covertToTimeDateDigit(now)}`;
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${name}.xlsx`
      );
    });
  };

  const onSwitcherChange = (e: any) => {
    if (e.target.value === 'none') {
      setGroupDispatch(false);
      setGroupbyDispatch('none');
    } else {
      setGroupDispatch(true);
      setGroupbyDispatch(e.target.value);
      setSumcolumnDispatch(col?.[e.target.value]?.name);
    }
  };

  const setSortDispatch = (value: any) => {
    dispatch({ type: 'setSort', payload: value });
  };

  const totalSummaryItems = [
    { columnName: col.docNo.name, type: 'count' },
    { columnName: 'amount', type: 'sum' },
  ];

  const grouping = [{ columnName: sumcolumn }];
  const groupSummaryItems = [
    {
      columnName: col.docNo.name,
      type: 'count',
      alignByColumn: true,
    },
    {
      columnName: 'amount',
      type: 'sum',
      alignByColumn: true,
    },
  ];

  const disgroup = [];
  if (!tempoptions?.project) disgroup.push(10);
  if (!tempoptions?.contract) disgroup.push(8);
  if (!tempoptions?.resourse) disgroup.push(11);
  if (!tempoptions?.retype) disgroup.push(12);
  if (!tempoptions?.retype2) disgroup.push(13);
  disgroup.push(4, 5, 5.5, 6, 9);
  const groupOptions = groupList(isRTL).filter(
    (item: any) => !disgroup.includes(item.id)
  );

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          top: 48,
          left: 400,
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <ReportGroupBySwitcher
          options={groupOptions}
          value={groupby}
          onChange={onSwitcherChange}
          isRTL={isRTL}
        ></ReportGroupBySwitcher>
      </Box>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'flex-end',
          top: 5,
          left: 400,
          width: '50%',
        }}
      >
        {tempoptions?.contract && (
          <FilterSelectMulti
            options={tasks}
            value={taskvalue}
            setValue={setTaskvalueDispatch}
            words={words}
            isRTL={isRTL}
            name="task"
            width={240}
          ></FilterSelectMulti>
        )}
        <Box style={{ width: 10 }}></Box>
        <FilterSelectMulti
          options={employees}
          value={emplvalue}
          setValue={setEmplvalueDispatch}
          words={words}
          isRTL={isRTL}
          name="employee"
          width={250}
          fullwidth
        ></FilterSelectMulti>
      </Box>
      {userroles?.calreports?.view && (
        <Box
          style={{
            position: 'absolute',
            zIndex: 111,
            left: isRTL ? undefined : drawerWidth + 225,
            right: isRTL ? drawerWidth + 225 : undefined,
            bottom: 2,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          display="flex"
        >
          <Typography
            style={{
              fontWeight: 'bold',
            }}
          >
            {isRTL ? 'المجموع' : 'Total'}: {moneyFormat(total)}
          </Typography>
        </Box>
      )}
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState
          defaultSorting={sort}
          onSortingChange={(srt: any) => setSortDispatch(srt)}
        />
        <SearchState />
        {group && <GroupingState grouping={grouping} />}
        <SummaryState
          totalItems={totalSummaryItems}
          groupItems={groupSummaryItems}
        />
        {group && <IntegratedGrouping />}
        {group && <IntegratedSummary />}
        <IntegratedSorting />
        <IntegratedFiltering />
        <VirtualTable
          height={height - 100}
          tableComponent={!group ? TableComponent : TableComponent2}
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          estimatedRowHeight={30}
          columnExtensions={tableColumnExtensions}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          onHiddenColumnNamesChange={(hcs: string[]) => {
            const all = [...columns];
            const newcol = all.filter((a: any) => !hcs.includes(a.name));
            newcol.sort((a: any, b: any) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );
            // setActivecolumns(newcol);
          }}
          defaultHiddenColumnNames={[
            col.docNo.name,
            col.contract.name,
            col.customer.name,
          ]}
        />
        <DataTypeProvider
          for={['startDate']}
          formatterComponent={createdAtFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['time']}
          formatterComponent={dateTimeFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['status']}
          formatterComponent={generalStatusFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['amount']}
          formatterComponent={currencyFormatter}
        ></DataTypeProvider>
        <DataTypeProvider
          for={['absent']}
          formatterComponent={absentFormatter}
        ></DataTypeProvider>
        <Toolbar
          rootComponent={(props: any) => (
            <Toolbar.Root
              style={{
                position: 'absolute',
                left: isRTL ? 40 : undefined,
                right: isRTL ? undefined : 40,
                top: 62,
                zIndex: 115,
              }}
              {...props}
            ></Toolbar.Root>
          )}
        />
        <ColumnChooser />
        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />
        <ExportPanel startExport={startExport} />
        {group && (
          <TableGroupRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
              sumOf: isRTL ? 'المجموع' : 'Total',
              countOf: isRTL ? 'العدد' : 'Count',
            }}
            showColumnsWhenGrouped
          />
        )}
        {group && (
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </>
  );
}
