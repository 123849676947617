/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  fade,
} from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { client } from '../../graphql';

import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from './Menu';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import UserBoxMobile from '../../Shared/UserBoxMobile';
import MyIcon from '../../Shared/MyIcon';

const innerDrawer = 270;

function AppDrawer(props: any): any {
  const {
    window,
    scrollbar,
    drawerWidth,
    open,
    setOpen,
    setDrawerWidth,
    newin,
  } = props;
  const drawerClasses = makeStyles((theme: Theme) =>
    createStyles({
      txtrtl: {
        textAlign: 'start',
        '& span, & svg': {
          fontSize: '1rem',
          // fontWeight: "500",
        },
        color: '#fff',
      },
      txtltr: {
        textAlign: 'start',
        '& span, & svg': {
          fontSize: '1rem',
          // fontWeight: "bold",
        },
        color: '#fff',
      },
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        [theme.breakpoints.down('md')]: {
          width: drawerWidth,
        },
        backgroundColor: theme.palette.primary.main,
      },
      drawerContainer: {
        overflow: 'auto',
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
        backgroundColor: theme.palette.primary.main,
      },
      child: {
        fontSize: '1.3em',
        paddingTop: 12,
        paddingBottom: 12,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      child2: {
        padding: 7,
        paddingLeft: open ? 55 : 10,
        backgroundColor: 'theme.palette.primary.main',
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      hide: {
        display: 'none',
      },
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: '#21d19f',
      },
      appBar: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },

      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.palette.primary.main,
      },
      drawerClose: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.primary.main,
      },

      '@global': !scrollbar
        ? {
            '*::-webkit-scrollbar': {
              height: '0.6em',
              width: '0.4em',
            },
            '*::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.3)',
              // outline: "1px solid #333",
            },
          }
        : {},
    })
  );

  const navigate = useNavigate();
  const classes = drawerClasses();
  const { isMobile } = useWindowDimensions();

  const { menuitem, setMenuitem, menu, isRTL, user, logout } = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const togleDrawer = () => {
    setDrawerWidth(open ? 80 : 280);
    setOpen(!open);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {isMobile && (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Box style={{ flex: 1 }}>
              <UserBoxMobile isRTL={isRTL} theme={theme}></UserBoxMobile>
            </Box>
          </Toolbar>
        </AppBar>
      )}

      <nav className={clsx(classes.drawer)} aria-label="mailbox folders">
        <Hidden smUp implementation="js">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Menu
              menu={menu}
              menuitem={menuitem}
              setMenuitem={setMenuitem}
              classes={classes}
              isRTL={isRTL}
              theme={theme}
              user={user}
              setMobileOpen={setMobileOpen}
              logout={logout}
              client={client}
              navigate={navigate}
            ></Menu>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="js">
          <Drawer
            variant="permanent"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <Box
              style={{
                position: 'absolute',
                zIndex: 111,
                top: 0,
                right: 0,
                height: 50,
                width: '100%',
                backgroundColor: '#eee',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  height: 50,
                  backgroundColor: fade(theme.palette.primary.main, 0.1),
                  alignItems: 'center',
                  justifyContent: open ? 'space-between' : 'center',
                }}
              >
                {open && (
                  <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Typography
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: 22,
                        letterSpacing: 3,
                      }}
                    >
                      JADWAL
                    </Typography>
                  </Box>
                )}
                <IconButton
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  onClick={togleDrawer}
                >
                  {open && (
                    <MyIcon
                      color={theme.palette.primary.main}
                      size={18}
                      icon={isRTL ? 'right' : 'left'}
                    ></MyIcon>
                  )}
                  {!open && (
                    <MyIcon
                      color={theme.palette.primary.main}
                      size={18}
                      icon={isRTL ? 'left' : 'right'}
                    ></MyIcon>
                  )}
                </IconButton>
              </Box>
            </Box>

            <Menu
              menu={menu}
              menuitem={menuitem}
              setMenuitem={setMenuitem}
              classes={classes}
              isRTL={isRTL}
              theme={theme}
              user={user}
              logout={logout}
              client={client}
              navigate={navigate}
              innerDrawer={innerDrawer}
              open={open}
              newin={newin}
            ></Menu>
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

export default AppDrawer;
