/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  successAlert,
  dublicateAlert,
  errorAlert,
  getReturnItem,
  messageAlert,
} from '../Shared';
import { GContextTypes } from '../types';
import { GlobalContext } from '../contexts';
import { Grid, TextField } from '@material-ui/core';
import PopupLayout from '../pages/main/PopupLayout';
import { CalenderLocal, TextFieldLocal } from '../components';
import { getPopupTitle } from '../constants/menu';

const PopupTask = ({
  open,
  onClose,
  row,
  isNew,
  setNewValue,
  addAction,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });

  const [start, setStart]: any = useState(null);
  const [end, setEnd]: any = useState(null);
  const [amount, setAmount]: any = useState(0);

  const { register, handleSubmit, errors, reset } = useForm();

  const {
    translate: { words, isRTL },
    store: { user },
  }: GContextTypes = useContext(GlobalContext);

  const onSubmit = async (data: any) => {
    if (!start) {
      await messageAlert(
        setAlrt,
        isRTL ? 'يجب تعديل التاريخ' : 'Date should be change'
      );
      return;
    }
    setSaving(true);
    const title = data.title;
    const variables: any = {
      start,
      end,
      title,
      amount,
      userId: user._id,
    };
    await apply(addAction, 'createTask', variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      const nitem = getReturnItem(res, mutateName);
      if (setNewValue && nitem) setNewValue(nitem, 'task');
      setSaving(false);
      await successAlert(setAlrt, isRTL);
      onCloseForm();
    } catch (error) {
      onError(error);
    }
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const onCloseForm = () => {
    onClose();
    reset();
    setSaving(false);
    setStart(null);
    setEnd(null);
    setAmount(0);
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const title = getPopupTitle('task', isNew);

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={onCloseForm}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      saving={saving}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextFieldLocal
                required
                name="title"
                label={words.title}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={3}>
              <CalenderLocal
                label={words.start}
                value={start}
                onChange={(d: any) => {
                  setStart(d);
                }}
                format={'dd/MM/yyyy'}
                isRTL={isRTL}
                mb={0}
                style={{
                  marginTop: 0,
                  width: 180,
                }}
              ></CalenderLocal>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <CalenderLocal
                value={end}
                label={words.end}
                onChange={(d: any) => {
                  setEnd(d);
                }}
                isRTL={isRTL}
                format={'dd/MM/yyyy'}
                style={{
                  marginTop: 0,
                  width: 180,
                }}
                mb={0}
              ></CalenderLocal>
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="amount"
                onChange={(e: any) => setAmount(Number(e.target.value))}
                value={amount}
                label={words.amount}
                variant="outlined"
                type="number"
                fullWidth
                margin="dense"
                onFocus={(e) => e.target.select()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupTask;
