/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { Box, IconButton, Button } from '@material-ui/core';

const AddButton = ({ onExecute }) => {
  return (
    <Box m={1}>
      <Button
        color="primary"
        variant="contained"
        onClick={onExecute}
        title="Create new row"
        style={{ width: 40, borderRadius: 5 }}
      >
        <AddIcon style={{ fontSize: 22 }} />
      </Button>
    </Box>
  );
};

const EditButton = ({ onExecute }) => {
  return (
    <IconButton style={{ padding: 5 }} onClick={onExecute} title="Edit row">
      <EditOutlinedIcon style={{ fontSize: 20, color: '#729aaf' }} />
    </IconButton>
  );
};

const DeleteButton = ({ onExecute }) => {
  return (
    <IconButton
      onClick={() => {
        if (window.confirm('Are you sure you want to delete this row?')) {
          onExecute();
        }
      }}
      title="Delete row"
      style={{ padding: 5 }}
    >
      <DeleteOutlinedIcon style={{ fontSize: 20, color: '#a76f9a' }} />
    </IconButton>
  );
};

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
};

export const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};
