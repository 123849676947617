import { gql } from '@apollo/client';

export default gql`
  mutation deletePCategory($_id: String) {
    deletePCategory(_id: $_id) {
      ok
      message
      error
    }
  }
`;
