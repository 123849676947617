/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Paper, Typography } from '@material-ui/core';
import { DataTypeProvider, EditingState } from '@devexpress/dx-react-grid';
import { Getter } from '@devexpress/dx-react-core';
import _ from 'lodash';
import { CommandSmall } from '../components/CommandSmall';
import { TableComponent } from '../components/TableComponent';
import { booleanFormatter } from '../common/formater';
import PopupEditing from '../components/PopupEditing';
import PopupField from './PopupField';

export const getRowId = (row: any) => row.field;

export default function FieldsTable({
  rows,
  setFields,
  isRTL,
  words,
  height = 250,
  theme,
}: any) {
  const [columns] = useState([
    { name: 'field', title: isRTL ? 'الحقل' : 'field' },
    { name: 'nameAr', title: words.nameAr },
    { name: 'name', title: words.nameEn },
    { name: 'order', title: isRTL ? 'ترتيب' : 'Order' },
    { name: 'show', title: isRTL ? 'اظهار' : 'Show' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'field', width: 140 },
    { columnName: 'nameAr', width: 140 },
    { columnName: 'name', width: 140 },
    { columnName: 'order', width: 80 },
    { columnName: 'show', width: 80 },
  ]);

  const addItemToList = (item: any) => {
    const it = rows.filter((i: any) => i.field === item.field);
    if (it?.length > 0) return;
    const newArray = [...rows, { ...item }];
    setFields(newArray);
  };
  const editItemInList = (item: any) => {
    const newArray = rows.map((it: any) =>
      it.field === item.field ? item : it
    );
    setFields(newArray);
  };

  const removeItemFromList = (field: any) => {
    const newList = rows.filter((it: any) => it.field !== field);
    setFields(newList);
  };

  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const field = deleted[0];
      removeItemFromList(field);
    }
  };

  const renderEmpty = () => {
    return <Box display="flex"></Box>;
  };

  return (
    <Paper
      style={{
        maxHeight: height,
        overflow: 'auto',
        margin: 10,
        minHeight: height,
      }}
    >
      {rows && (
        <Grid
          rows={_.sortBy(rows, 'order')}
          columns={columns}
          getRowId={getRowId}
        >
          <EditingState onCommitChanges={commitChanges} />
          <Table
            noDataCellComponent={renderEmpty}
            columnExtensions={tableColumnExtensions}
            tableComponent={TableComponent}
          />
          <TableHeaderRow
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <TableEditColumn
            showAddCommand
            showDeleteCommand
            showEditCommand
            commandComponent={CommandSmall}
          ></TableEditColumn>
          <DataTypeProvider
            for={['show']}
            formatterComponent={booleanFormatter}
          ></DataTypeProvider>
          <PopupEditing
            theme={theme}
            addAction={addItemToList}
            editAction={editItemInList}
          >
            <PopupField
              setFields={setFields}
              isRTL={isRTL}
              words={words}
            ></PopupField>
          </PopupEditing>
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                {
                  key: 'editCommand',
                  type: TableEditColumn.COLUMN_TYPE,
                  width: 120,
                },
                ...tableColumns.filter(
                  (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
              ];
              return result;
            }}
          />
        </Grid>
      )}
    </Paper>
  );
}
