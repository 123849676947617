/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableSummaryRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { getRowId, updateOpDocRefNumbers } from '../common';
import { useLazyQuery } from '@apollo/client';
import {
  createdAtFormatter,
  currencyFormatterEmpty,
  opTypeFormatter,
} from './colorFormat';
import { getColumns } from '../common/columns';
import { Box, Typography } from '@material-ui/core';
import RefetchBox from './RefetchBox';
import getItemslist from '../graphql/query/getItemslist';

export default function EventsItemsCustomer({
  isRTL,
  words,
  theme,
  name,
  id,
  width,
  height,
  start,
  end,
  tempoptions,
}: any) {
  const col = getColumns({ isRTL, words });

  const [columns] = useState(
    [
      col.opTime,
      col.opDocNo,
      tempoptions?.contract && col.contract,
      col.customer,
      tempoptions?.employee && col.employee,
      tempoptions?.department && col.department,
      { name: 'qty', title: words.qty },
      { name: 'itemPrice', title: words.theprice },
      { name: 'total', title: words.total },
    ].filter((x) => x)
  );

  const [rows, setRows] = useState([]);

  const [loadItems, itemsData]: any = useLazyQuery(getItemslist, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const variables = {
      [name]: id,
      type: 80,
      start: start ? start.setHours(0, 0, 0, 0) : undefined,
      end: end ? end.setHours(23, 59, 59, 999) : undefined,
    };
    loadItems({
      variables,
    });
  }, [id, start, end]);

  useEffect(() => {
    if (itemsData?.data?.getItemslist?.data) {
      const { data } = itemsData.data.getItemslist;
      const rdata = updateOpDocRefNumbers(data);
      setRows(rdata);
    }
  }, [itemsData]);

  const totalSummaryItems = [
    { columnName: 'credit', type: 'sum' },
    { columnName: 'debit', type: 'sum' },
  ];
  const refresh = () => itemsData?.refetch();
  const loading = itemsData.loading;
  return (
    <Box
      style={{
        height: height - 250,
        width: width - 300,
        margin: 10,
      }}
    >
      {' '}
      <Box
        style={{
          position: 'absolute',
          width: 50,
          height: 50,
          left: isRTL ? 220 : undefined,
          right: isRTL ? undefined : 220,
          zIndex: 111,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 55,
        }}
      >
        <RefetchBox
          isRTL={isRTL}
          theme={theme}
          refresh={refresh}
          loading={loading}
        ></RefetchBox>
      </Box>
      <Paper
        style={{
          height: height - 260,
          width: width - 320,
        }}
      >
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSorting />
          <IntegratedSummary />
          <VirtualTable
            height={680}
            messages={{
              noData: isRTL ? 'لا يوجد بيانات' : 'no data',
            }}
            estimatedRowHeight={40}
          />
          <TableHeaderRow
            showSortingControls
            titleComponent={({ children }) => {
              return (
                <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {children}
                </Typography>
              );
            }}
          />
          <DataTypeProvider
            for={['opTime']}
            formatterComponent={createdAtFormatter}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['credit', 'debit']}
            formatterComponent={currencyFormatterEmpty}
          ></DataTypeProvider>
          <DataTypeProvider
            for={['opType']}
            formatterComponent={opTypeFormatter}
          ></DataTypeProvider>
          <TableSummaryRow
            messages={{
              sum: isRTL ? 'المجموع' : 'Total',
              count: isRTL ? 'العدد' : 'Count',
            }}
          ></TableSummaryRow>
        </Grid>
      </Paper>
    </Box>
  );
}
