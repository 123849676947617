/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as check from './roles';
import { getStoreItem } from '../store';
import {
  badmintemplate,
  employeetemplate,
  userrolesTypes,
} from '../constants/roles';

const store = getStoreItem('store');
const user = store?.user;

const convertRoles = () => {
  const objroles: any = {};
  const isSuperAdmin = user?.isSuperAdmin;
  const isBranchsAdmin = user?.isBranchsAdmin;
  const isBranchAdmin = user?.isBranchAdmin;
  const isEmployee = user?.isEmployee;
  objroles.isSuperAdmin = isSuperAdmin;
  objroles.isBranchsAdmin = isBranchsAdmin;
  objroles.isBranchAdmin = isBranchAdmin;
  objroles.isEmployee = isEmployee;
  try {
    const nroles: any =
      isSuperAdmin || isBranchAdmin || isBranchsAdmin
        ? badmintemplate
        : isEmployee
        ? employeetemplate
        : user?.newroles
        ? JSON.parse(user?.newroles)
        : null;
    if (nroles && nroles?.length > 0) {
      for (const role of nroles) {
        objroles[role?.name] = role;
      }
    }
  } catch (error) {
    console.log('error', error);
    return objroles;
  }

  return objroles;
};

const userroles: typeof userrolesTypes = convertRoles();

export { userroles };

export const roles = {
  isSuperAdmin: () => check.isSuperAdmin(user),

  isBranchAdmin: () => check.isBranchAdmin(user),
  isBranchsAdmin: () => check.isBranchsAdmin(user),

  isOperateAdmin: () => check.isOperateAdmin(user),
  isOperateEditor: () => check.isOperateEditor(user),
  isOperateWriter: () => check.isOperateWriter(user),
  isOperateViewer: () => check.isOperateViewer(user),
  isPurchaseAdmin: () => check.isPurchaseAdmin(user),
  isPurchaseEditor: () => check.isPurchaseEditor(user),
  isPurchaseWriter: () => check.isPurchaseWriter(user),
  isPurchaseViewer: () => check.isPurchaseViewer(user),
  isFinanceAdmin: () => check.isFinanceAdmin(user),
  isFinanceEditor: () => check.isFinanceEditor(user),
  isFinanceWriter: () => check.isFinanceWriter(user),
  isFinanceViewer: () => check.isFinanceViewer(user),

  isEmployee: () => check.isEmployee(user),
  isFinance: () => check.isFinance(user),
  isOperate: () => check.isOperate(user),
  isPurchase: () => check.isPurchase(user),
  isAdmin: () => check.isAdmin(user),
  isEditor: () => check.isEditor(user),
  isWriter: () => check.isWriter(user),
  isViewer: () => check.isViewer(user),
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
