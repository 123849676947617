import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { useState } from 'react';
import { POS_BACKGROUND } from '../MainPOS';

export default function Categories({
  height,
  bgcolor,
  categories,
  cat,
  setCat,
  width,
  isRTL,
  theme,
}) {
  const [value, setvalue] = useState(null);
  const handleChange = (_: any, newValue: any) => {
    setvalue(newValue);
    setCat(categories?.[newValue]);
  };
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height,
        width,
        backgroundColor: bgcolor,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ style: { display: 'none' } }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        style={{ marginLeft: 20, marginRight: 20 }}
      >
        {categories?.map((c: any) => {
          const isActive = c?._id === cat?._id;
          return (
            <Tab
              style={{ margin: 0, padding: 0 }}
              disableRipple
              label={
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 15,
                    overflow: 'hidden',
                    backgroundColor: isActive
                      ? theme.palette.primary.main
                      : POS_BACKGROUND,
                    height: 50,
                    // paddingLeft: 5,
                    // paddingRight: 5,
                    // minWidth: 150,
                  }}
                >
                  {/* <Box
                    style={{
                      width: 36,
                      height: 36,
                      borderRadius: 10,
                      margin: 7,
                      backgroundColor: c?.color,
                    }}
                  >
                    {c?.avatar && (
                      <img
                        style={{
                          borderRadius: 10,
                          overflow: 'hidden',
                          objectFit: 'cover',
                        }}
                        alt={c?.name}
                        width={36}
                        height={36}
                        src={c?.avatar}
                      />
                    )}
                  </Box> */}
                  <Typography
                    style={{
                      color: isActive ? '#eee' : undefined,
                      fontWeight: 'bold',
                      fontSize: 18,
                      marginLeft: 20,
                      marginRight: 20,
                    }}
                  >
                    {isRTL ? c?.nameAr : c?.name}
                  </Typography>
                </Box>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
