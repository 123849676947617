import { Box } from '@material-ui/core';
import useLandingChart from '../../../../hooks/useLandingChart';
import { getColor } from '../../common/helpers';
import { userroles } from '../../../constants';
import PercentChart from '../PercentChart';

function PercentTodayBox({
  isRTL,
  theme,
  color,
  systemcolor,
  height,
  employeeId = null,
  isEmployee = false,
}) {
  const { todayEvents } = useLandingChart(employeeId);
  const clr = getColor({ theme, color, systemcolor });

  return (
    <Box>
      {(isEmployee || (todayEvents && userroles?.appointments?.edit)) && (
        <PercentChart
          pricolor={theme.palette.primary.main}
          seccolor={theme.palette.secondary.main}
          data={todayEvents || []}
          height={height}
          color={clr}
          isRTL={isRTL}
        />
      )}
    </Box>
  );
}

export default PercentTodayBox;
