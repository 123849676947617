/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  createDCategory,
  deleteDCategory,
  updateDCategory,
} from '../graphql/mutation';
import { getFormDocs } from '../graphql/query';
import getDCategories from '../graphql/query/getDCategories';

export default () => {
  const [getDeparts, catpData]: any = useLazyQuery(getDCategories);

  const [addDCategory] = useMutation(createDCategory, {
    refetchQueries: [{ query: getDCategories }, { query: getFormDocs }],
  });
  const [editDCategory] = useMutation(updateDCategory, {
    refetchQueries: [{ query: getDCategories }, { query: getFormDocs }],
  });
  const [removeDCategory] = useMutation(deleteDCategory, {
    refetchQueries: [{ query: getDCategories }, { query: getFormDocs }],
  });

  useEffect(() => {
    getDeparts();
  }, [getDeparts]);

  const dcategories = catpData?.data?.['getDCategories']?.data || [];
  const refreshdcategories = () => catpData?.refetch();
  return {
    dcategories: dcategories.filter((fd: any) => !fd.disabled),
    alldcategories: dcategories,
    refreshdcategories,
    addDCategory,
    editDCategory,
    removeDCategory,
    loading: catpData?.loading,
  };
};
