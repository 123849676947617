import { Box, Grid, Typography } from '@material-ui/core';

export default function PrintParagraphs({
  value,
  classnames,
  classes,
  style,
  rows,
  data,
  isRTL,
}: any) {
  const clss = classnames?.map((cn: any) => classes?.cn);
  const className = clss.join(' ');
  const fontSize = style?.fontSize ? style?.fontSize : 18;
  return (
    <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <Box className={className} style={style}>
        <Grid container spacing={0}>
          <Grid xs={12}>
            <Box>
              {rows?.map((item: any) => {
                return (
                  <Box style={{ breakInside: 'avoid' }}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize,
                        fontFamily: 'sans-serif',
                        marginBottom: -10,
                      }}
                    >
                      {item?.title}
                    </Typography>
                    {item?.subitems?.length > 0 && (
                      <ul>
                        {item?.subitems?.map((it: any) => {
                          return (
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <li>
                                <Typography
                                  style={{
                                    fontFamily: 'sans-serif',
                                    fontSize: fontSize - 2,
                                  }}
                                >
                                  {it?.title}
                                </Typography>
                                {it?.desc && it?.desc?.length > 0 && (
                                  <Typography
                                    style={{
                                      fontFamily: 'sans-serif',
                                      fontSize: fontSize - 3,
                                    }}
                                  >
                                    {it?.desc}
                                  </Typography>
                                )}
                              </li>
                            </Box>
                          );
                        })}
                      </ul>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
