/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { GContextTypes } from '../../types';
import { GlobalContext } from '../../contexts';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { dublicateAlert, errorAlert, successAlert } from '../common/funcs';
import PopupLayout from '../shared/PopupLayout';
import TextFieldLocal from '../shared/TextFieldLocal';

const PopupDCategory = ({
  open,
  onClose,
  row,
  isNew,
  addAction,
  editAction,
  theme,
}: any) => {
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const { register, handleSubmit, errors, reset } = useForm();
  const {
    translate: { words, isRTL },
  }: GContextTypes = useContext(GlobalContext);

  useEffect(() => {
    if (!isNew && row?._id) {
      if (row?.disabled === true || row?.disabled === false) {
        setDisabled(row?.disabled);
      }
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    const name = data.name;
    const nameAr = data.nameAr;
    const ref = data.ref;
    const perfix = data.perfix;
    const variables: any = {
      _id: row._id ? row._id : undefined, // is it new or edit
      name,
      nameAr,
      ref,
      perfix,
      disabled,
    };
    const mutate = isNew ? addAction : editAction;
    const mutateName = isNew ? 'createFormDoc' : 'updateFormDoc';
    await apply(mutate, mutateName, variables);
  };

  const apply = async (mutate: any, mutateName: string, variables: any) => {
    try {
      const res = await mutate({ variables });
      if (res?.data?.[mutateName]?.ok === false) {
        await errorAlert(setAlrt, isRTL);
        setSaving(false);
        return;
      }
      closeModal();
      await successAlert(setAlrt, isRTL);
    } catch (error) {
      onError(error);
    }
  };

  const onHandleSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const closeModal = () => {
    onClose();
    setSaving(false);
    setDisabled(false);
    reset();
  };

  const onError = async (error: any) => {
    if (error.message.includes('duplicate')) {
      await dublicateAlert(setAlrt, isRTL);
    } else {
      await errorAlert(setAlrt, isRTL);
      reset();
      console.log(error);
    }
  };

  const title = isRTL
    ? isNew
      ? 'اضافة تصنيف نموذج'
      : 'تعديل تصنيف نموذج'
    : isNew
    ? 'New Form Category'
    : 'Edit Form Category';

  return (
    <PopupLayout
      isRTL={isRTL}
      open={open}
      onClose={closeModal}
      title={title}
      onSubmit={onHandleSubmit}
      theme={theme}
      alrt={alrt}
      maxWidth={'md'}
      saving={saving}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextFieldLocal
                autoFocus
                required
                name="name"
                label={words.name}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                required
                name="nameAr"
                label={words.nameAr}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                required
                name="ref"
                label={isRTL ? 'رمز التصنيف' : ' Category code'}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                disabled={!isNew}
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLocal
                required
                name="perfix"
                label={isRTL ? 'لاحقة' : 'Prefix'}
                register={register}
                errors={errors}
                row={row}
                fullWidth
                disabled={!isNew}
                mb={0}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disabled}
                    onChange={() => setDisabled(!disabled)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={isRTL ? 'متوقف' : 'Disabled'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </PopupLayout>
  );
};

export default PopupDCategory;
