import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#f4f6f8',
    },
    '& tbody tr:hover': { backgroundColor: '#eee !Important' },
    '& tbody tr:nth-of-type(even)': { backgroundColor: '#fff' },
  },
  tableStripedEmail: {
    '& tbody tr:hover': { backgroundColor: '#eee !Important' },
    '& tbody tr td': {
      fontWeight: 'normal !Important',
      fontSize: '14px !Important',
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);
const TableComponentEmail = ({ classes, ...restProps }) => (
  <VirtualTable.Table {...restProps} className={classes.tableStripedEmail} />
);
export const TableComponent = withStyles(styles, { name: 'TableComponent' })(
  TableComponentBase
);
export const TableComponentEM = withStyles(styles, {
  name: 'TableComponent',
})(TableComponentEmail);
