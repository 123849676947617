import { gql } from '@apollo/client';

export default gql`
  mutation createDocUpload(
    $branch: String
    $opType: Int
    $title: String
    $desc: String
    $contractId: String
    $contractName: String
    $contractNameAr: String
    $customerId: String
    $customerName: String
    $customerNameAr: String
    $supplierId: String
    $supplierName: String
    $supplierNameAr: String
    $departmentId: String
    $departmentName: String
    $departmentNameAr: String
    $employeeId: String
    $employeeName: String
    $employeeNameAr: String
    $employeeColor: String
    $resourseId: String
    $resourseName: String
    $resourseNameAr: String
    $resourseColor: String
    $amount: Float
    $debit: Float
    $debitAcc: Int
    $debitAccName: String
    $debitAccNameAr: String
    $credit: Float
    $creditAcc: Int
    $creditAccName: String
    $creditAccNameAr: String
    $attachments: String
    $note: String
  ) {
    createDocUpload(
      branch: $branch
      opType: $opType
      title: $title
      desc: $desc
      contractId: $contractId
      contractName: $contractName
      contractNameAr: $contractNameAr
      customerId: $customerId
      customerName: $customerName
      customerNameAr: $customerNameAr
      supplierId: $supplierId
      supplierName: $supplierName
      supplierNameAr: $supplierNameAr
      departmentId: $departmentId
      departmentName: $departmentName
      departmentNameAr: $departmentNameAr
      employeeId: $employeeId
      employeeName: $employeeName
      employeeNameAr: $employeeNameAr
      employeeColor: $employeeColor
      resourseId: $resourseId
      resourseName: $resourseName
      resourseNameAr: $resourseNameAr
      resourseColor: $resourseColor
      amount: $amount
      debit: $debit
      debitAcc: $debitAcc
      debitAccName: $debitAccName
      debitAccNameAr: $debitAccNameAr
      credit: $credit
      creditAcc: $creditAcc
      creditAccName: $creditAccName
      creditAccNameAr: $creditAccNameAr
      attachments: $attachments
      note: $note
    ) {
      ok
      message
      data
      error
    }
  }
`;
