import { gql } from '@apollo/client';

export default gql`
  mutation updateBranch(
    $_id: String
    $foundationId: String
    $temp: String
    $fronttemp: String
    $custemp: String
    $suptemp: String
    $restemp: String
    $emptemp: String
    $deptemp: String
    $tsktemp: String
    $en: String
    $ar: String
    $users: Int
    $smss: Int
    $emails: Int
    $name: String
    $nameAr: String
    $logo: String
    $tel1: String
    $tel2: String
    $fax: String
    $mob: String
    $email: String
    $website: String
    $address: String
    $poBox: String
    $CR: String
    $city: String
    $country: String
    $location: LocationInput
    $locationLink: String
    $photos: String
    $note: String
    $packId: Int
    $packName: String
    $packCost: Float
    $packDiscount: Float
  ) {
    updateBranch(
      _id: $_id
      foundationId: $foundationId
      temp: $temp
      fronttemp: $fronttemp
      custemp: $custemp
      suptemp: $suptemp
      restemp: $restemp
      emptemp: $emptemp
      deptemp: $deptemp
      tsktemp: $tsktemp
      en: $en
      ar: $ar
      users: $users
      smss: $smss
      emails: $emails
      name: $name
      nameAr: $nameAr
      logo: $logo
      tel1: $tel1
      tel2: $tel2
      fax: $fax
      mob: $mob
      email: $email
      website: $website
      address: $address
      poBox: $poBox
      CR: $CR
      city: $city
      country: $country
      location: $location
      locationLink: $locationLink
      photos: $photos
      note: $note
      packId: $packId
      packName: $packName
      packCost: $packCost
      packDiscount: $packDiscount
    ) {
      ok
      message
      data
      error
    }
  }
`;
