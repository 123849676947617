/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useReducer, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Box, fade } from '@material-ui/core';

import DateNavigatorReports from '../../components/filters/DateNavigatorReports';

import { bgimage } from '../constants';
import PageLayout from '../shared/PageLayout';

import PopupFormOperationView from '../shared/PopupFormOperationView';
import TabsList from '../components/TabsList';
import _ from 'lodash';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import PresalesDocument from './PresalesDocument';
import { getStoreItem, setStoreItem } from '../../store';
import {
  CustomerContext,
  customerReducer,
  initCustomerContext,
} from '../../contexts/managment';
import Customers from '../shared/Customers';

export default function PresalesOperations({
  isRTL,
  words,
  user,
  theme,
  company,
  menuitem,
  formgroup,
  drawerWidth,
}: any) {
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const [item, setItem] = useState(null);
  const [openItem, setOpenItem] = useState(false);

  const [currentViewName, setCurrentViewName] = useState('Day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { height, width } = useWindowDimensions();

  const customerref: any = useRef();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const fgroup = formgroup ? _.orderBy(formgroup, 'order') : [];
  const formdoc = fgroup?.filter((fg: any) =>
    pathname?.includes(fg?.path)
  )?.[0];

  const customerStoreState = getStoreItem('customerStore', initCustomerContext);
  const [customerStore, customerDispatch] = useReducer(
    customerReducer,
    customerStoreState ? customerStoreState : initCustomerContext
  );
  useEffect(() => {
    setStoreItem('customerStore', customerStore);
  }, [customerStore]);

  const currentViewNameChange = (e: any) => {
    setCurrentViewName(e.target.value);
  };
  const currentDateChange = (curDate: any) => {
    setCurrentDate(curDate);
  };

  const endDateChange = (curDate: any) => {
    setEndDate(curDate);
  };

  const onCloseItem = () => {
    setOpenItem(false);
    setItem(null);
  };

  const handleSelect = (item: any) => {
    navigate(item?.path);
  };

  const refresh = () => {};
  const bgcolor = '#ea211211';

  return (
    <PageLayout
      menuitem={menuitem}
      isRTL={isRTL}
      words={words}
      theme={theme}
      refresh={refresh}
      bgcolor={bgcolor}
      bgimage={bgimage}
      width={width - drawerWidth}
    >
      <Box
        style={{
          height: height - 50,
          overflow: 'auto',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 50,
            marginLeft: 50,
            marginTop: 5,
          }}
        >
          <DateNavigatorReports
            setStart={setStart}
            setEnd={setEnd}
            currentDate={currentDate}
            currentDateChange={currentDateChange}
            currentViewName={currentViewName}
            currentViewNameChange={currentViewNameChange}
            endDate={endDate}
            endDateChange={endDateChange}
            views={[1, 7, 30, 365, 1000]}
            isRTL={isRTL}
            words={words}
            theme={theme}
          ></DateNavigatorReports>
        </Box>
        <Paper
          elevation={5}
          style={{
            borderRadius: 25,
            margin: 25,
            marginTop: 5,
            width: width - drawerWidth - 50,
            height: height - 135,
          }}
        >
          <TabsList
            handleSelect={handleSelect}
            isRTL={isRTL}
            list={fgroup}
            width={width - drawerWidth - 50}
            color={fade(theme?.palette?.secondary?.main, 0.2)}
            theme={theme}
            words={words}
            pathname={pathname}
          ></TabsList>
          <Paper
            style={{
              display: 'flex',
              height: height - 185,
              overflow: 'auto',
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
            }}
          >
            <Routes>
              <Route
                index
                element={<Navigate to={fgroup?.[0]?.path} replace />}
              />
              <Route
                path={'customers'}
                element={
                  <CustomerContext.Provider
                    value={{ state: customerStore, dispatch: customerDispatch }}
                  >
                    <Customers
                      ref={customerref}
                      isRTL={isRTL}
                      words={words}
                      theme={theme}
                      company={company}
                    ></Customers>
                  </CustomerContext.Provider>
                }
              />
              <Route
                path={formdoc?.path}
                element={
                  <PresalesDocument
                    isRTL={isRTL}
                    words={words}
                    user={user}
                    theme={theme}
                    start={start}
                    end={end}
                    formdoc={formdoc}
                  ></PresalesDocument>
                }
              />
            </Routes>
          </Paper>
        </Paper>
        <PopupFormOperationView
          open={openItem}
          onClose={onCloseItem}
          row={item}
          theme={theme}
          isRTL={isRTL}
          words={words}
          user={user}
        ></PopupFormOperationView>
      </Box>
    </PageLayout>
  );
}
