/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-no-undef */
import { useMutation } from '@apollo/client';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../contexts';
import { client } from '../../graphql';
import updateUserTheme from '../../graphql/mutation/updateUserTheme';
import { useUsers } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import PopupUserProfile from '../../pubups/PopupUserProfile';
import OptionsSwitch from '../../Shared/OptionsSwitch';
import MyIcon from '../components/MyIcon';
import Avatar from './Avatar';
import LanguageSwitch from './LanguageSwitch';
import NotificationIcvon from './NotificationIcvon';

export default function UserBox(props: any) {
  const [open, setOpen] = useState(false);

  const { isRTL, refresh, loading, theme } = props;
  const { isMobile } = useWindowDimensions();
  const {
    store,
    store: { lang, user, notify },
    translate: { words },
    dispatch,
  } = useContext(GlobalContext);
  const [addUserTheme] = useMutation(updateUserTheme);
  const { editUser, editPasswordQuick } = useUsers();

  const navigate = useNavigate();
  const logout = () => {
    dispatch({ type: 'logout' });
  };

  const handleLogout = async () => {
    logout();
    await client.resetStore();
    navigate('/');
  };

  const handleLanguage = (ln: any) => {
    dispatch({
      type: 'setLang',
      payload: ln === 'ar' ? 'ar' : 'en',
    });
    window.location.reload();
  };

  return (
    <Box
      style={{
        display: 'flex',
        width: isMobile ? undefined : 250,
        height: 50,
        zIndex: 114,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {user && (
        <React.Fragment>
          <Box
            display="flex"
            alignItems="center"
            style={{
              flexDirection: 'row-reverse',
              flex: 1,
            }}
          >
            <Box
              style={{
                marginRight: isRTL ? 20 : undefined,
                marginLeft: isRTL ? undefined : 20,
              }}
            >
              <Avatar
                username={user.username}
                name={user.name}
                email={user.email}
                size={40}
                header
                isRTL={isRTL}
                logout={handleLogout}
                navigate={navigate}
                setOpen={setOpen}
              ></Avatar>
            </Box>
            <Box style={{ marginLeft: 10, marginRight: 10 }}>
              <LanguageSwitch
                lang={lang}
                isRTL={isRTL}
                handleLanguage={handleLanguage}
              ></LanguageSwitch>
            </Box>
            <Box style={{}}>
              <NotificationIcvon
                navigate={navigate}
                isRTL={isRTL}
                dispatch={dispatch}
                user={user}
                notify={notify}
              ></NotificationIcvon>
            </Box>
            <Box style={{ marginLeft: 5 }}>
              <OptionsSwitch
                store={store}
                dispatch={dispatch}
                words={words}
                isRTL={isRTL}
                addUserTheme={addUserTheme}
              ></OptionsSwitch>
            </Box>
            {refresh && (
              <IconButton
                style={{
                  padding: 10,
                }}
                onClick={refresh}
              >
                {!loading && (
                  <MyIcon icon="sync" size={25} color="#555"></MyIcon>
                )}
                {loading && (
                  <CircularProgress style={{ color: '#555' }} size={20} />
                )}
              </IconButton>
            )}
          </Box>
        </React.Fragment>
      )}
      <PopupUserProfile
        open={open}
        onClose={() => setOpen(false)}
        editAction={editUser}
        editPassword={editPasswordQuick}
        theme={theme}
      ></PopupUserProfile>
    </Box>
  );
}
