import { Box, Typography } from '@material-ui/core';
import React from 'react';

export default function WorkerLable({ worker, isRTL }: any) {
  const color = worker.color;
  return (
    <Box
      style={{
        margin: 3,
        padding: 3,
        backgroundColor: color,
        borderRadius: 5,
      }}
    >
      <Typography
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {isRTL ? worker?.nameAr : worker?.name}
      </Typography>
    </Box>
  );
}
