/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  IntegratedPaging,
  PagingState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableEditColumn,
  Toolbar,
  SearchPanel,
  TableColumnVisibility,
  ColumnChooser,
  DragDropProvider,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { Command, errorAlert, PopupEditing } from '../../Shared';
import { getRowId, userroles } from '../../common';
import { AlertLocal, SearchTable } from '../../components';
import { errorDeleteAlert } from '../../Shared/helpers';
import { getColumns } from '../../common/columns';
import { Box, Button, Typography } from '@material-ui/core';
import { TableComponent } from '../../Shared/TableComponent';
import { retypeTypes } from '../../constants/datatypes';
import PopupResourseType from '../../pubups/PopupResourseType';
import {
  avatarColorFormatter,
  departmentTypeFormat,
} from '../../Shared/colorFormat';
import useRetypes from '../../hooks/useRetypes';
import { useTemplate } from '../../hooks';

const ResourseTypes = forwardRef((props: any, ref: any) => {
  const { isRTL, words, theme } = props;

  const [retype, setRetype] = useState(retypeTypes?.[0]);

  const [alrt, setAlrt] = useState({ show: false, msg: '', type: undefined });
  const col = getColumns({ isRTL, words });
  const { tempoptions } = useTemplate();

  const [columns] = useState([
    { name: 'avatar', title: ' ' },
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'reType', title: isRTL ? 'الفئة' : 'Category' },
  ]);

  const [tableColumnExtensions]: any = useState([
    { columnName: 'avatar', width: 30 },
    { columnName: col.name.name, width: 250 },
    { columnName: 'reType', width: 250 },
  ]);

  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: col.name.name, togglingEnabled: false },
  ]);
  const [columnsViewer] = useState([
    { name: isRTL ? 'nameAr' : 'name', title: words.name },
    { name: 'avatar', title: words.color },
  ]);

  const { retypes, addRetype, editRetype, removeRetype, refreshretype } =
    useRetypes();

  const rows = retype
    ? retypes.filter((rt: any) => rt.reType === retype.id)
    : retypes;
  const commitChanges = async ({ deleted }) => {
    if (deleted) {
      const _id = deleted[0];
      const res = await removeRetype({ variables: { _id } });
      if (res?.data?.deleteRetype?.ok === false) {
        if (res?.data?.deleteRetype?.error.includes('related')) {
          await errorDeleteAlert(setAlrt, isRTL);
        } else {
          await errorAlert(setAlrt, isRTL);
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    refresh() {
      if (refreshretype) {
        refreshretype();
      }
    },
  }));

  const distypes = [];
  if (!tempoptions?.retype2) distypes.push(5);
  if (!tempoptions?.resourse) distypes.push(2);
  const rt = retypeTypes.filter((rt: any) => !distypes.includes(rt.id));

  return (
    <>
      <Box
        display="flex"
        style={{
          position: 'absolute',
          zIndex: 111,
          flexDirection: 'row',
          alignItems: 'center',
          top: 72,
          right: isRTL ? undefined : 400,
          left: isRTL ? 400 : undefined,
        }}
      >
        {rt?.map((rt: any) => {
          return (
            <Button
              key={rt.id}
              color="primary"
              variant={rt === retype ? 'contained' : 'outlined'}
              onClick={() => setRetype(rt)}
              style={{ margin: 10, padding: 5, minWidth: 150 }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {isRTL ? rt?.nameAr : rt?.name}
              </Typography>
            </Button>
          );
        })}
      </Box>

      <Grid
        rows={rows}
        columns={userroles?.resoursetypes?.edit ? columns : columnsViewer}
        getRowId={getRowId}
      >
        <SortingState />
        <EditingState onCommitChanges={commitChanges} />
        <SearchState />
        <PagingState defaultCurrentPage={0} defaultPageSize={20} />

        <IntegratedSorting />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DragDropProvider />
        <Table
          messages={{
            noData: isRTL ? 'لا يوجد بيانات' : 'no data',
          }}
          tableComponent={TableComponent}
          rowComponent={(props: any) => (
            <Table.Row {...props} style={{ height: 68 }}></Table.Row>
          )}
          columnExtensions={tableColumnExtensions}
        />
        <TableColumnReordering
          defaultOrder={['avatar', col.name.name, 'reType']}
        />
        <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
        <DataTypeProvider
          for={['avatar']}
          formatterComponent={(props: any) =>
            avatarColorFormatter({
              ...props,
              height: 45,
            })
          }
        ></DataTypeProvider>
        <TableHeaderRow
          showSortingControls
          titleComponent={({ children }) => {
            return (
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
                {children}
              </Typography>
            );
          }}
        />
        <TableColumnVisibility
          columnExtensions={tableColumnVisibilityColumnExtensions}
          defaultHiddenColumnNames={[]}
        />
        <DataTypeProvider
          for={['reType']}
          formatterComponent={(props: any) =>
            departmentTypeFormat({ ...props, isRTL })
          }
        ></DataTypeProvider>
        <TableEditColumn
          showEditCommand={userroles?.sales?.edit}
          showDeleteCommand={userroles?.sales?.delete}
          showAddCommand={userroles?.sales?.add}
          commandComponent={Command}
        ></TableEditColumn>

        <Toolbar />
        <ColumnChooser />
        <PagingPanel
          pageSizes={
            tempoptions?.pageall ? [5, 6, 10, 20, 50, 0] : [5, 6, 10, 20, 50]
          }
        />

        <SearchPanel
          inputComponent={(props: any) => {
            return <SearchTable isRTL={isRTL} {...props}></SearchTable>;
          }}
        />

        <PopupEditing
          theme={theme}
          addAction={addRetype}
          editAction={editRetype}
        >
          <PopupResourseType
            retypeTypes={rt}
            reType={retype}
          ></PopupResourseType>
        </PopupEditing>
      </Grid>
      {alrt.show && (
        <AlertLocal
          isRTL={isRTL}
          type={alrt?.type}
          msg={alrt?.msg}
          top
        ></AlertLocal>
      )}
    </>
  );
});

export default ResourseTypes;
