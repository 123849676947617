import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { covertToTimeDateDigit } from '../../Shared/colorFormat';
import { Box, Divider } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function TimelineSingleProc({ row, isRTL, setOpenstep }: any) {
  const classes = useStyles();

  const steps = row?.steps ? JSON.parse(row?.steps) : [];
  const outboxName = row?.outboxName;
  const inboxName = row?.inboxName;

  return (
    <Timeline align={isRTL ? 'left' : 'right'}>
      <TimelineItem>
        <TimelineOppositeContent style={{ maxWidth: 150 }}>
          <Typography variant="body2">
            {covertToTimeDateDigit(row?.start)}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }} variant="body1">
            {outboxName}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <Box
            style={{
              width: 14,
              height: 14,
              backgroundColor: row?.stepColor || 'gray',
              borderRadius: 7,
              marginBottom: 7,
              marginTop: 7,
            }}
          ></Box>
          <TimelineConnector style={{ backgroundColor: row?.stepColor }} />
        </TimelineSeparator>
        <TimelineContent>
          <Box
            border={2}
            borderColor={row?.stepColor ? `${row?.stepColor}88` : '#ccc'}
            borderRadius={10}
          >
            <Paper
              elevation={0}
              style={{
                borderRadius: 10,
              }}
              className={classes.paper}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: -15,
                }}
              >
                <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                  {isRTL ? row?.pitemNameAr : row?.pitemName}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }} variant="body2">
                  {inboxName}
                </Typography>
              </Box>
              <Box p={3}>
                <Typography>{row?.desc}</Typography>
                {row?.end && (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      Due Date: {covertToTimeDateDigit(row?.end)}
                    </Typography>
                  </Box>
                )}
                <Divider style={{ marginTop: 10 }}></Divider>
              </Box>

              <Timeline align={isRTL ? 'left' : 'right'}>
                <TimelineItem style={{ marginTop: -15, marginBottom: -25 }}>
                  <TimelineOppositeContent
                    style={{ maxWidth: 140 }}
                  ></TimelineOppositeContent>
                  {row?.stepId !== 10 && (
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <Box
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: '#777',
                          }}
                          onClick={() => {
                            setOpenstep(true);
                          }}
                        >
                          <Add style={{ fontSize: 20, color: '#eee' }} />
                        </Box>
                      </TimelineDot>
                      {steps?.length > 0 && (
                        <TimelineConnector
                          style={{
                            height: 20,
                            marginBottom: 25,
                          }}
                        />
                      )}
                    </TimelineSeparator>
                  )}
                  <TimelineContent>
                    <Box
                      style={{ cursor: 'pointer', width: 100 }}
                      onClick={() => {
                        setOpenstep(true);
                      }}
                    >
                      <Typography
                        style={{
                          position: 'relative',
                          fontWeight: 'bold',
                        }}
                      >
                        {isRTL ? 'انشاء خطوة' : 'Create Step'}
                      </Typography>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                {steps?.length > 0 &&
                  steps?.map((step: any, i: number) => {
                    return (
                      <TimelineItem>
                        <TimelineOppositeContent style={{ maxWidth: 150 }}>
                          <Typography variant="body2" color="textSecondary">
                            {covertToTimeDateDigit(step?.stepTime)}
                          </Typography>
                          <Typography variant="body2">
                            {step?.stepUserName}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <Box
                            style={{
                              width: 14,
                              height: 14,
                              backgroundColor: step?.stepColor || 'gray',
                              borderRadius: 7,
                              marginBottom: 7,
                              marginTop: 7,
                            }}
                          ></Box>
                          {i < steps?.length - 1 && (
                            <TimelineConnector
                              style={{
                                backgroundColor: step?.stepColor,
                              }}
                            />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Paper
                            elevation={0}
                            style={{
                              borderRadius: 10,
                              backgroundColor: step?.stepColor
                                ? `${step?.stepColor}33`
                                : '#f5f5f5',
                            }}
                            className={classes.paper}
                          >
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                marginBottom: 5,
                              }}
                            >
                              {isRTL ? step?.stepNameAr : step?.stepName}
                            </Typography>
                            <Typography style={{ paddingBottom: 5 }}>
                              {step?.stepDesc}
                            </Typography>
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
              </Timeline>
            </Paper>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
